// URL data like: s.hcp=15-17&s.h=0-3&n.s=4-
// http://localhost:63342/navigator/www/neobridge.html?neobridge.html?s=K109832.872.72.Q4&v=n&e.hcp=15-17

let win, activeSide, globalParams, globalData;

function getMinMax( str ) {
	return str?.split( '-' ) || [ '', '' ];
}

// Convert .min and .max to 'min-max'
function collapseParams( params ) {
	if( typeof params!=='object' ) return;
	for( let [k,v] of Object.entries( params ) ) {
		if( !v || ( v==='0' && k.endsWith( '.min' ) ) ) {
			delete params[k];
			continue;
		}
		let m = k.match( /^(.*)\.(min|max)$/ );
		if( !m ) continue;
		let max = params[m[1]+'.max'],
			min = params[m[1]+'.min'];
		if( !min && !max ) continue;
		params[m[1]] = `${min}-${max}`;
		delete params[m[1]+'.max'];
		delete params[m[1]+'.min'];
	}
}

function fillOptions( data, def ) {
	let s = '';
	for( let val of data ) {
		let [ k, v ] = typeof val==='object'? val : [ val, val ];
		s += `<option value='${k}' ${k===def? 'selected':''}>${v}</option>`;
	}
	return s;
}

export async function setupDistribution( data, params ) {
	globalParams = { ...params};
	globalData = data;
	collapseParams( data );
	if( data && typeof data==='string' ) {
		let url = new URLSearchParams( data ),
			data = {};
		for( let [k,v] of url.entries() ) {
			let m = k.match( /^([nesw])\.(.*)$/ );
			if( m ) {
				data[m[1]] ||= {};
				data[m[1]][m[2]] = v;
			}
		}
	}
	if( !win ) {
		win = makeBigWindow( {
			id: 'distribution',
			title: 'Distribution'
		} );
		win.addEventListener( 'click', winClick );
		win.addEventListener( 'input', oninput );
	}

	win.$( '.title' ).setContent( globalData.desc || 'Distribution' );
	let str = `<div class="selectside column center" style='gap: 0.2em'>
			<button name='__switchside' data-side='n' style='margin: 0;'>N</button>
			<div class='grid' style='grid-template: "l r" 1fr / 1fr 1fr;'>
			 <button name='__switchside' data-side='e' style='margin: 0; grid-area: r; justify-self: start'>E</button>
			 <button name='__switchside' data-side='w' style='margin: 0; grid-area: l; justify-self: end'>S</button>
			</div>
			<button name='__switchside' data-side='s' style='margin: 0; justify-self: left'>W</button>
		</div><div style='border-bottom: 1px solid lightgray; margin: 0.5em 0;'></div>
		<div class='gridone'>`;
	for( let side of 'nesw' ) {
		let pbn = typeof data?.[side]==='string' && data[side] || data?.[side]?.PBN;
		if( pbn ) {
			// Show diagram or if this is 'chance' analyzer, then show DD table
			let desc = globalData?.desc? `<span style='margin-bottom: 1em' data-name='desc' data-value='${globalData?.desc}'>${globalData?.desc}</span>` : '';
			str += `<div class='fade column' data-name='${side}' data-value=${pbn} data-fixed='1' data-side='${side}' 
				style='align-self: start; align-items: center'>
				${desc}${diagramPBN( pbn )}</div>`;
		} else {
			str += `<div class='fade grid columns2' data-side='${side}'
			style='gap: 0.5em 1em;'>`;

			let hcp = getMinMax( data?.[`${side}.hcp`] );
			str += `<label style='justify-self: right'>Distribution</label>
			<select style='justify-self: start' name='${side}.distribution'>
			${fillOptions( [ '',
				[ 'bal', 'balanced' ],
				[ 'unbal', 'unbalanced' ]
			], data?.[`${side}.distribution`] ) }
			</select>
			<label style='justify-self: right'>HCP</label>
			<div class='flexline nowrap'>
				  <input name='${side}.hcp.min' type='number' min='1' max='30' value='${hcp[0]}'>
				  <span style='color: lightgray; padding: 0 0.2em'>-</span>
				  <input name='${side}.hcp.max' type='number' min='0' max='39' value='${hcp[1]||''}'>
			</div>`;
			for( let suit of 'shdc' ) {
				let cnt = getMinMax( data?.[`${side}.${suit}`] );
				str += `<label  style='justify-self: right' class='emoji'>${htmlsuits[suit]}</label>
				<div class='flexline nowrap'>
				  <input type='number' name='${side}.${suit}.min' min='0' max='13' value='${cnt[0]}'/>
				  <span style='color: lightgray; padding: 0 0.2em'>-</span>
				  <input type='number' name='${side}.${suit}.max' min='0' max='13' value='${cnt[1]||''}'/>
				</div>`;
			}
			str += '</div>';
		}
	}
	str += '</div>';
	str += `<button data-closeselect='ok' class='default importantsize'>${params?.button || 'OK'}</button>`;
	win.body( str );
	// Set active to anchor hand (for what we make a solution)
	activeSide = win.$( `[data-fixed]` )?.dataset.side || 'n';
	checkSide();
	oninput();
	let res = await win.promiseShow();
	if( res==='ok' ) {
		let pp = collectMine();
		if( !globalParams?.changeonly || !iseq( pp, globalData ) )
			return pp;
	}
}

function checkSide() {
	for( let el of win.$$( 'div[data-side]' ) )
		el.makeVisible( el.dataset.side===activeSide );
	for( let el of win.$$( 'button[data-side]' ) )
		el.style.background = el.dataset.side===activeSide? 'lightgreen' : 'initial';
}

function winClick( e ) {
	let rtarget = e.target.closest( '[name]' );
	if( rtarget?.name==='__switchside' ) {
		activeSide = rtarget.dataset.side;
		checkSide();
		return;
	}
}

function diagramPBN( pbn ) {
	return `<div class='column' style='align-items: start'>${pbn.toUpperCase().split( '.' ).map( ( x, idx ) => {
		return `<span class='flexline nowrap'><span class='emoji' style='padding-right: 0.2em'>${htmlsuits['shdc'[idx]]}</span>${x.replace( 'T', '10' )}</span>`;
	}).join( '' )}</div>`;
}

function collectMine() {
	let p = collectParams( win );
	collapseParams( p );
	return p;
}

function iseq( o1, o2 ) {
	return JSON.stringify( o1 )===JSON.stringify( o2 );
}

function oninput() {
	if( globalParams?.changeonly ) {
		let collected = collectMine();
		let j1 = JSON.stringify( globalData ),
			j2 = JSON.stringify( collected );
		win.$( "button[data-closeselect=ok]" ).disabled = j1===j2;
	}
	checkButtons();
}

function checkButtons() {
	let p = collectMine();
	for( let side of 'nesw' ) {
		let btn = win.$( `button[data-side='${side}']` ),
			data = win.$( `div[data-side='${side}']` );
		if( !data ) return;

		let add = '';
		if( data.dataset.fixed )
			add = 'fixed';
		else {
			// Points
			add = p[`${side}.hcp`] || '';

			// Distribution
			let di = p[`${side}.distribution`];
			if( di ) add += ' ' + di;

			let suitadd = '';
			for( let s of 'shdc' ) {
				let desc = p[`${side}.${s}`];
				if( desc ) {
					suitadd += ` <span class='emoji'>${htmlsuits[s]}</span>${desc}`;
				}
			}
			if( suitadd.replaceAll( /<.*?>/g, '' ).length>10 )
				add += ' **';
			else
				add += suitadd;
		}
		let content = side.toUpperCase();
		if( add==='fixed' )
			content = `<span class="emoji">⚓</span>`;
		else if( add )
			content = `<span style='font-size: 1rem; color: gray'>${add}</span>`;
		btn.setContent( content );
	}
}

function describeminmax( min, max ) {
	min = +min;
	max = +max;
	if( !min && !max ) return '';
	if( min===max ) return '=' + min;
	if( +max && +min > +max ) return '';
	if( !max ) return min + '+';
	if( !min ) return `0-${max}`;
	return `${min}-${max}`;
}