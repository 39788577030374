import createBoardPlayers from "./boardplayer.js";

cssInject( 'bg' );
cssInject( 'board' );

// Эксперимент с
// window.ResizeObserver пока не удался, процентный размер доски по ширине плохо работает.
// стараемся покрыть все ситуации в @media css

const KILLED = 24;
const OUT = 26;

const baseCells = {
	short: [13, 14, 15, 16, 17, 18, 20, 21, 22, 23, 24, 25, 12, 11, 10, 9, 8, 7, 5, 4, 3, 2, 1, 0, 6, 19 ],
	long: [12, 11, 10, 9, 8, 7, 5, 4, 3, 2, 1, 0, 13, 14, 15, 16, 17, 18, 20, 21, 22, 23, 24, 25, 6, 19]
};

const diceSymbol = ' ⚀⚁⚂⚃⚄⚅',
	acode = 'a'.charCodeAt( 0 ),
	Acode = 'A'.charCodeAt( 0 );

function getbgcell( char ) {
	if( char==='@' ) return 26;
	if( char==='^' ) return 27;
	return char.charCodeAt( 0 ) - Acode;
}

export default class Gammon {
	constructor( game ) {
		this.game = game;
		this.bgtype = game.vgame.gameChain && game.vgame.gameChain[1];
		game.module = 'bg';
		game.setWideModel();

		this.board = construct( '.bgboard', game.playZone, this.boardClick.bind( this ) );
		// elephCore.track( game.playZone, 'bg' );
		// game.playZone.classList.add( 'use_bg' );
		this.cells = [];
		this.freePips = new Set;
		this.bottom = 0;
		this.diceHolder = construct( '.bgdices.dicespos.fade.flexline.nowrap.emoji', this.board, this.dicesClick.bind( this ) );
		this.dices = [ construct( '.bgdice', this.diceHolder ),
			construct( '.bgdice', this.diceHolder ) ];
		this.rollBack = construct( 'button.rollback.fade.emoji 🔙', this.board, this.doRollBack.bind( this ) );
		this.goButton = construct( 'button.default.domove.fade OK', this.board, this.sendMove.bind( this ) );
		this.dropButton = construct( 'button.dodrop.emoji.fade 🎲', this.board, this.doDrop.bind( this ) );

		this.noconfirmBox = html( '<span class="control flexline fade checkboxicon confirmmove">✈️</span>', this.game.gameButtonsBar );
		this.autorollBox = html( '<span class="control flexline checkboxicon emoji fade autoroll">🎲</span>', this.game.gameButtonsBar );


		// if( LOCALTEST ) this.crawfordClick();

		this.noconfirmBox.classList.toggle( 'checked', +(elephCore?.getStorage( 'gammonnoconfirmmove' ) ?? 0) );
		this.noconfirmBox.onclick = this.moveConfirm.bind( this );

		this.autorollBox.classList.toggle( 'checked', +(elephCore?.getStorage( 'gammonautoroll' ) ?? 0) );
		this.autorollBox.onclick = this.autoRollClick.bind( this );
		this.checkAutorollVisible();

		// this.tableTitle = construct( '.tabletitle', this.game.playZone );
		// setInterval( () => animator( this.dropButton ), 4000 );
		for( let i = 0; i<28; i++ ) {
			let cellholder = construct( `.bgcell[data-no=${i}]`, this.board );
			// cellholder.style.order = i * 10;
			// cellholder.dataset['no'] = i;
			this.cells[i] = {
				no: i,
				holder: cellholder,
				// columnpos: baseCells[i>11?23-i:i],
				// up: i>11 && i!==24,
				pips: []
			};
			if( i<26 ) this.cells[i].quantity =	construct( '.display_none.quantity', cellholder );

			this.setCellPos( this.cells[i] );
			cellholder.cell = this.cells[i];
			cellholder.onmouseleave = cellholder.onmouseenter = this.cellMouseEnterLeave.bind( this );
			// cellholder.dataset['column'] = this.cells[i].columnpos;
		}
		this.cellsKilled = [this.cells[24], this.cells[25]];
		this.cellsKilled.forEach( x => x.columnpos = 6 );
		this.cellsOut = [this.cells[26], this.cells[27]];
		this.cells[26].holder.dataset.color = 'white';
		this.cells[27].holder.dataset.color = 'black';
		this.cellsOut.forEach( x => x.holder.classList.add( 'out' ) );

		this.textPips = [
			construct( '.bgpips', this.cellsOut[0].holder ),
			construct( '.bgpips', this.cellsOut[1].holder )
		];
		this.textPips[0].style.color = 'white';
		this.textPips[1].style.color = 'black';

		this.board.style.backgroundImage = `url( ${IMGEMBEDPATH}/svg/boards/gammon.svg )`;

		// let routes = [ 'bgpos', 'bgmove' ].map( x => {})

		let r = [ 'bgfirstmove', 'bgpos', 'bgdices', 'bgmove', 'bgpips', 'event', 'bgcurrent', 'move', 'placechanged'].reduce( ( acc, one ) => {
			acc[one] = this[one].bind( this );
			return acc;
		}, {} );
		r.game = this.gameInfo.bind( this );
		r.cube = this.checkCube.bind( this );
		r.gaveup = this.stopMove.bind( this );
		game.addRoute( r );

		game.navi.rotate.show();
		game.watcher.watch( 'checkbottom', this.checkBottom.bind( this ) );
		// game.watcher.watch( 'whiteside', this.checkBottom.bind( this ) ) ;

		game.setMaxPlayers( 2 );
		game.playZone.classList.add( 'onboardplayers' );
		createBoardPlayers( game, game.playZone );

		// Проверка Тапы
/*
		if( LOCALTEST ) {
			this.bgpos( '?ccc...Bc.DB.c.b..bFC.?c?cc....' );
			this.move( { plno: 0, dices: '42', mtree: 'w42sUGKjL[0[1]2[1]1[02]]', resign: 'koks' } );
			this.bgcurrent( '6-10' );
			// this.move( { mtree: '66XFMSEK[0[0[0[012]1[012]2[012]]1[0[012]1[02]2[012]]2[0[012]1[012]2[012]]]1[0[0[012]1[02]2[012]]1[0[02]2[02]]2[0[012]1[02]2[012]]]2[0[0[012]1[012]2[012]]1[0[012]1[02]2[012]]2[0[012]1[012]2[01]]]]' } )
		}
*/

		this.checkBottom( 'constructor' );
		this.board.appendChild( this.game.cube.button );
		this.game.playZone.appendChild( this.game.moveControls );
		this.game.playArea.dataset.ruler = 'bg';
		this.game.playArea.classList.add( 'gammon' );

		dispatch( 'onresume', this.checkMouseResume.bind( this ) );

		// Перекроем makeSnapshot
		this.game.parseProto = async proto => {
			(await import( './bglogic.js' )).parse( this, proto );
		};

		this.game.makeSnapshot = this.makeSnapshot.bind( this );
	}

	makeSnapshot( shot ) {
		this.game.baseSnapshot( shot );
		// Добавляем позицию
		let str = '', Acode = 'A'.charCodeAt( 0 );
		for( let i=0; i<28; i++ ) {
			let cell = this.cells[i],
				pips = cell.pips;
			if( !pips.length ) str += '.';
			else {
				let fp = pips[0],
					code = String.fromCharCode( Acode + pips.length );
				if( fp.color==='b' ) code = code.toLowerCase();
				str += code;
			}
		}
		shot.bgpos = str;
		shot.bgdices = this.bgdicesstr;
		return shot;
	}

	gameInfo( o ) {
		if( 'bgtype' in o ) this.bgtype = o.bgtype;
		if( this.board.dataset.bgtype===this.bgtype ) return;
		this.board.dataset.bgtype = this.bgtype;
		this.placechanged();
		// this.tableTitle.setContent( o.title.replace( 'DEMO', currency( 'DEMO' ) ) );
		delay( this.checkCells.bind( this ) );
		if( this.game.whiteSide===undefined ) this.game.route_whiteside( 0 );
	}

	checkAutomove() {
		// let plays = this.game.isPlayer;
		this.noconfirmBox.makeVisible( this.myMoveNow || this.noconfirmBox.classList.contains( 'checked') );
	}

	checkAutorollVisible() {
		let vis = this.game.inprogress && this.game.isRealPlayer
			&& this.game.cube?.value && !this.game.cube.state || false;
		// Если нет быстрого хода и не наша очередь делать бросок, не показываем (облегчаем интерфейс)
		if( vis ) {

		}
		this.autorollBox.makeVisible( vis );
	}

	placechanged() {
		let plays = this.game.isPlayer;
		this.checkAutorollVisible();
		// if( !plays ) this.stopMove();
		this.move( this.movenow );
		this.game.gameButtonsBar.makeVisible( plays );
		this.checkAutomove();
	}

	checkDicesPos() {
		// this.diceHolder.dataset['position'] = this.diceHolder.dataset['color']===this.bottomColor ? 'right' : 'left';
	 	if( !this.bgdicesstr )
			this.dropButton.dataset.position = this.diceHolder.dataset.position = this.movenow?.plno===this.game.getpov ? 'right' : 'left';
	}

	bgdices( str ) {
		this.bgdicesstr = str;
		if( !str ) {
			return this.diceHolder.hide();
		}
		let [_,color,v1,g1,v2,g2] = str.match( /(.)(\d)([fh]?)(\d)([fh]?)/ );
		let plno = color==='w'? 0 : 1;
		this.diceHolder.dataset.color = color;
		this.dices[0].textContent = diceSymbol[+v1];
		this.dices[0].dataset.used = { f: 100, h: 50 }[g1] || '';
		this.dices[1].textContent = diceSymbol[+v2];
		this.dices[1].dataset.used = { f: 100, h: 50 }[g2] || '';
		this.diceHolder.dataset.position = plno===this.game.getpov ? 'right' : 'left';
		this.diceHolder.show();
	}

	bgpos( pos ) {
		// Производим расстановку шашек в ячейки
		// if( this.bgpos===pos ) return;  // Не проверяем, а устанавливаем позицию заново
/*
		if( this.myMoveNow ) {
			// TODO: just check position while moving
			log( 'Skip bgpos due to my move' );
			return;
		}
*/
		// this.bgposnow = pos;
		let decoded = [];
		for( let i = 0; i<pos.length; i++ ) {
			if( pos[i]==='.' ) {
				decoded.push( [0, 0] );
				continue;
			}
			let code = pos.charCodeAt( i ),
				[color, base] = code>=acode ? ['b', acode] : ['w', Acode],
				count = code - base;
			// if( !count ) continue;
			// Проверим не так ли оно уже есть
			if( this.compareCell( this.cells[i], color, count ) ) {
				// Совпало
				decoded.push( null );
			} else {
				// if( count ) log( i + ': ' + count + ' of ' + color );
				decoded.push( [color, count] );
			}
		}
		for( let i = pos.length; i--; )
			decoded[i] && this.releaseCell( this.cells[i], decoded[i][0], decoded[i][1] );
		for( let i = pos.length; i--; )
			decoded[i] && this.fillCell( this.cells[i], decoded[i][0], decoded[i][1] );
		this.hideFreePips();
	}

	hideFreePips() {
		this.freePips.forEach( pip => pip.hide() );
	}

	compareCell( cell, color, count ) {
		if( !cell ) return;
		let pips = cell.pips, i = 0;
		if( pips.length!==count ) return;
		for( ; i<count && i<count; i++ )
			if( pips[i].color!==color ) return false;
		return true;
	}

	releaseCell( cell, color, count ) {
		if( !cell ) return;
		let pips = cell.pips, i = 0;
		for( ; i<count && i<pips.length && pips[i].color===color; ) i++;
		if( !pips[i] ) return;
		// Убираем неподошедшие шашки
		while( pips[i] ) this.freePips.add( pips.pop() );
		this.checkCellShowCount( cell );
	}

	fillCell( cell, color, count ) {
		if( !cell ) return;
		let pips = cell.pips;
		while( pips.length<count ) {
			let pip = this.createPip( color, cell.columnpos );
			this.movePipTo( pip, cell );
		}
	}

	bgmove( str ) {
		let [from,to] = str.split( ' ' );
		let cell = this.cells[from], cellto = this.cells[to];
		if( !cell || !cellto )
			return;
		let pip = cell.pips.pop();
		if( !pip ) return;
		if( this.isshort && cellto.pips.length===1 && cellto.pips[0].color!==pip.color ) {
			// выбивание
			this.kill( cellto.pips[0] );
		}
		// if( !cellto )
		// 	cellto = OUT + (pip.color==='w'? 0:1);
		this.checkCellShowCount( cell );
		this.movePipTo( pip, cellto );
	}

	bgfirstmove( pos ) {
		// Показ первого хода в партии луком со стрелой
		if( this.game.inprogress && +pos>=0 ) {
			this.firstMoveMark ||= html( `<span class='emoji' style='grid-area: firstmove'>🏹</span>` );
			this.game.boardPlayers[+pos].holder.appendChild( this.firstMoveMark );
		} else {
			this.firstMoveMark?.parent?.removeChild( this.firstMoveMark );
		}
	}

	kill( pip ) {
		let cell = this.cells[KILLED + ( pip.color==='w'? 0 : 1 )];
		this.movePipTo( pip, cell );
	}

	movePipTo( pip, cell ) {
		if( !cell || !pip ) return;
		let pipcell = pip.cell?.pips;
		if( pipcell?.length && pipcell[pipcell.length-1]===pip )
			pipcell.pop();		// Удалим его из другого списка (коррекция)
		pip.cell = cell;
		pip.order = cell.pips.length;
		cell.pips.push( pip );
		pip.classList.toggle( 'out', cell.no>=26 );
		pip.classList.toggle( 'killed', cell.no>=24 && cell.no<=25 );
		/*if( !pip.visible ) */pip.show();
		this.movePip( pip );
		pip.dataset['cell'] = cell.no;
		this.checkCellShowCount( cell );
	}

	checkCellShowCount( cell, count ) {
		if( !cell.quantity ) return;
		let c = count || cell.pips.length;
		cell.holder.dataset.quantity = c>5 ? c - 4 : '';
		cell.quantity.makeVisible( c>5 );
		if( c>5 ) {
			cell.holder.style.color = cell.pips[0].color==='w' ? 'black' : 'white';
			cell.quantity.textContent = c - 4;
			// cell.quantity.style.color = cell.pips[0].color==='w'? 'black' : 'white';
		}
	}

	movePip( pip ) {
		let cell = pip.cell,
			x = cell.columnpos * 100,
			y = Math.min( 4, pip.order ) * 99,
			yoff = cell.up ? y : 1100 - y;

		if( cell.no>=26 ) {
			y = pip.order * 99;
			yoff = cell.up? y-60 : 3400 - y;
			pip.style.transform = `scale(1,.33) translate3d(${x}%,${yoff}%,0)`;
		} else
			pip.style.transform = `translate3d(${x}%,${yoff}%,0)`;
	}

	findFreePip( color /*, nearestColumn*/ ) {
		for( let p of this.freePips ) {
			if( !p ) {
				bugReport( 'null pip' );
				this.freePips.delete( p );
				continue;
			}
			if( p.color!==color ) continue;
			this.freePips.delete( p );
			return p;
		}
	}

	createPip( color, nearestColumn ) {
		let pip = this.findFreePip( color, nearestColumn );
		if( pip ) return pip;
		pip = construct( 'img.bgpip.display_none' );
		pip.dataset.color = color;
		pip.color = color;
		// pip.style.backgroundImage = 'url(' + IMGEMBEDPATH + `/svg/figures/${color}g.svg )`;
		pip.src = IMGEMBEDPATH + `/svg/figures/${color}g.svg`;
		this.board.appendChild( pip );
		return pip;
	}

	getHomeSide( place ) {
		if( this.isshort ) return this.bottomColor==='white'? 'right' : 'left';
		return place===this.bottomPlace? 'right' : 'left';
	}

	setCellPos( cell ) {
		let n = cell.no,
			s = this.isshort && 'short' || 'long',
			bs = baseCells[s],
			pos = bs[n];
		if( this.bottomColor==='black' ) {
			pos = 25 - pos;
		}
		if( n>=26 ) {
			let same = this.bottomColor===cell.holder.dataset.color;
			if( this.isshort ) {
				cell.holder.dataset.side = this.bottomColor==='white' ?
					'right' : 'left';

			} else {
				cell.holder.dataset.side = same ? 'right' : 'left';
			}
			cell.columnpos = cell.holder.dataset.side==='left'? -1 : 13;
			cell.up = !same;
		} else {
			cell.holder.dataset.column = cell.columnpos = pos % 13;
			cell.up = pos<13 /*&& n!==24 &&*/ && n!==26;
		}

		// if( this.isshort && this.bottomColor==='black' ) {
		// 	cell.up = !cell.up;
		// 	cell.columnpos = 12 - cell.columnpos;
		// }
		cell.holder.dataset.vside = cell.up ? 'up' : 'bottom';
		cell.holder.style.order = /*(cell.up?0:13) +*/ pos;
		cell.pips.forEach( pip => this.movePip( pip ) );
	}

	checkBottom( reason ) {
		log( 'gammon checkbottom ' + reason );
		let c = this.game.whiteSide ?? 0;
		if( this.game.getpov===1 ) c = 1 - c;
		if( this.game.manualRotate ) c = 1 - c;

		// let c = this.game.getpov;
		// if( this.game.manualRotate ) c = 1-c;

		this.setBottom( c );
	}

	checkCube() {
		let cube = this.game.cube;
		if( !cube ) return;
		let ds = cube.button.dataset;
		if( cube.who>=0 ) {
			// Показываем на стороне того, кто может удвоить, в противоположной от дома
			ds.vside = this.bottomPlace===cube.who? 'bottom' : 'top';
			ds.side = this.getHomeSide( cube.who )==='right'?
				'left' : 'right';
		} else
			ds.vside = ds.side = 'center';

		// this.crawfordInfo.makeVisible( this.game.cube.state==='crawford' );
		this.checkAutorollVisible();

		// this.doublemaxInfo.makeVisible( this.game.cube.state==='max' );
	}

	checkCells() {
		this.cells.forEach( cell => this.setCellPos( cell ) );
		this.checkDicesPos();
	}

	setBottom( color ) {
		if( this.bottom===color ) return;
		this.bottom = color;
		this.checkCells();
		this.board.dataset.bottomcolor = this.bottomColor;
		this.checkCube();
	}

	getDir( who ) {
		if( !this.isshort ) return 1;
		return who? -1 : 1;
	}

	getDist( l1, l2, c ) {
		let who = c==='w'? 0 : 1;
		if( l1===(KILLED+who) && l2===(OUT+who) ) return 25;

		let sl = l1===(KILLED+who)? 11+who : l1,
			dl = l2===(OUT+who)? ( (this.isshort? 12-who : 12+(1-who)*12) ) : l2,
			dist = (dl+24-sl)%24;
		if( this.getDir( who )===-1 ) dist = 24-dist;
		if( !dist && l2===(OUT+who) ) dist = 24;
		return dist;
	}

	stopMove() {
		this.movenow = null;
		this.myMoveNow = false;
		this.current = this.moveTree = null;
		this.diceHolder.classList.remove( 'clickable' );
		this.goButton.hide();
		this.rollBack.hide();
		this.dropButton.hide();
		this.dropButton.classList.remove( 'dropped' );
		this.checkAutomove();
		// Пусть зарики остаются на доске, чтобы было видно что было и сколько отходили
		// this.diceHolder.hide();
	}

	event( str ) {
		if( str==='newgame' ) {
			this.stopMove();
			this.diceHolder.hide();
		}
	}
	bgcurrent( str ) {
		if( !this.moveTree ) return;			// Нет дерева
		str ??= '';
		log( 'Current: ' + this.getNodeId( this.current ) );
		if( str===this.getNodeId( this.current ) ) {
			if( LOCALTEST ) console.warn( 'Hit position' );
			return;
		}
		this.movePath = []; // Сбрасываем свою историю ходов, будем в случае чего откатывать по 1
		this.mouseOverHide();
		for( ; this.current.parent; ) {
			let s = this.getNodeId( this.current );
			if( str.startsWith( s ) ) {
				break;
			}
			this.stepUp();
			log( 'SteppedUp: ' + this.getNodeId( this.current ) );
		}
		let s = this.getNodeId( this.current );
		if( str!==s ) {
			let left = str.slice( s.length );
			if( left[0]==='-' ) left = left.slice( 1 );
			let bits = left.split( '-' );
			for( let i = 0; i<bits.length; i += 2 ) {
				if( !this.stepDown( +bits[i], +bits[i + 1] ) ) break;
			}
		}
		this.prepareMove();
		this.mouseOverRestore();
	}

	stepDown( source, dest ) {
		if( !this.current?.moves ) return false;
		for( let o of this.current.moves ) {
			if( o.prevmove.source===source && o.prevmove.dest===dest ) {
				this.doMove( o );
				return true;
			}
		}
		// Если не нашли короткого хода, проверим длинные
		let fast = this.getFastMoves( this.current );
		if( fast ) for( let o of fast ) {
			if( o[0]===source && o[1].prevmove.dest===dest ) {
				this.doMove( o[1] );
				return true;
			}
		}
	}

	async move( o ) {
		// Parsing move tree in gammon move format:
		// NN - move numbers
		// alphabet symbols (2x)
		// tree
		let oldmove = this.movenow;
			// waitedForDrop = this.movenow?.waitdrop;
		this.stopMove();
		this.movenow = o;
		if( !o ) return;
		let who = o.plno,
			color = 'wb'[who],
			mymove = who===this.game.myPlace,
			mtree = o.mtree,
			dicestr = o.dices || ( mtree && mtree[1]+mtree[2] );

		if( dicestr && dicestr[0]<dicestr[1] ) dicestr = dicestr[1] + dicestr[0];
		this.moveWho = o.plno;

		// Позиция и цвет зар
		this.checkDicesPos();
		this.diceHolder.dataset.color = color;
		if( dicestr ) {
			this.bgdicesstr = null;
			this.dices[0].textContent = diceSymbol[+dicestr[0]];
			this.dices[1].textContent = diceSymbol[+dicestr[1]];
		}

		if( o.waitdrop ) {
			this.dropButton.classList.toggle( 'disabled', !mymove );
			this.dropButton.show();
			this.diceHolder.hide();
			if( !mymove ) {
				return;
			}
			if( this.autorollBox.classList.contains( 'checked' ) ) {
				this.doDrop();
				this.stopMove();
				return;
			}
			return;
		}
		if( !o.mtree ) {
			// Нет дерева. Был пропуск хода или просто пауза. Покажем только зарики
			this.dices[0].dataset.used = this.dices[1].dataset.used = '100';
			this.diceHolder.show();
			return;
		}
		this.myMoveNow = mymove;

		let
			idx = mtree.indexOf( '[' ),
			alphabet = mtree.slice( 3, idx ),
			tree = mtree.slice( idx );
		if( dicestr[0]===dicestr[1] ) dicestr += dicestr;
		this.alphabet = [];
		let Alphabet = alphabet.toUpperCase();
		for( let i=0; i<alphabet.length; i+=2 ) {
			let x = {
				source: getbgcell( Alphabet[i] ),
				dest: getbgcell( Alphabet[i+1] )
			};
			x.dist = this.getDist( x.source, x.dest, mtree[0] );
			if( x.dest<24 && alphabet[i+1]===alphabet[i+1].toLowerCase() ) x.kill = true;
			this.alphabet.push( x );
		}
		this.moveColor = mtree[0];
		this.moveTree = { dices: dicestr };
		this.diceStr = dicestr;
		this.diceHolder.classList.remove( 'switch' );
		this.diceSorted = [ +dicestr[0], +dicestr[1] ];
		this.parseTree( tree, 0, this.moveTree );
		this.current = this.moveTree;
		this.movePath = [];
		this.dices[0].dataset.used = this.dices[1].dataset.used = '0';

		if( oldmove!==undefined && !Object.is( oldmove, this.movenow ) )
			elephCore?.playSound( dicestr[0]===dicestr[1]? 'roll1' : 'roll' );

		if( !mymove && oldmove!==undefined ) {
			// Если ход не мой, сделаем небольшую анимацию
			this.dropButton.classList.add( 'dropped' );
			this.dropButton.classList.add( 'disabled' );
			this.dropButton.show();
			this.diceHolder.hide();
			this.diceHolder.style.display = 'none';
			setTimeout( () => this.diceHolder.style.display = 'initial', 200 );
			setTimeout( () => {
				this.dropButton.hide();
				// this.dropButton.classList.remove( 'dropped' );
				this.diceHolder.show();
			}, 500 );
			return;
		}

		this.diceHolder.show();

		// Только если ходим мы
		if( !this.myMoveNow ) return;

		this.diceHolder.classList.toggle( 'clickable', dicestr.length===2 );
		// this.moveCode = '#' + Math.floor( Math.random()*1000000 );

		// Force auto moves
		this.forceAutoMoves();

		if( !this.current.moves )
		{
			// Ход уже завершен
			this.sendMove();
			return;
		}

		this.prepareMove();

		this.checkMouseResume();
		this.checkAutomove();
	}

	forceAutoMoves() {
		if( !this.myMoveNow ) return;
		let deep=this.current;
		for( ; deep.moves?.length===1; ) {
			deep = deep.moves[0];
			// Only one legal move, force it
		}
		if( deep!==this.current )
			this.doMove( deep, true );
	}

	parseTree( str, pos, tree ) {
		// Разберем рекурсивно дерево с созданием JSON-страктуры
		if( !tree.moves ) tree.moves = [];
		for( ; str[pos]; pos++ ) {
			switch( str[pos] ) {
				case '[':
					continue;
				case ']':
					return pos;
				default:
					let code = str[pos]>='A'? str.charCodeAt(pos)-Acode+10 : +str[pos];
					let j = {
						parent: tree,
						prevmove: this.alphabet[code]
					};
					if( str[pos+1]==='[' ) {
						// При выбросе ход большим кубом может давать меньшее расстояние
						let dp = -1;
						for( let d = this.alphabet[code].dist; dp===-1 && d<=6; d++ ) {
							dp = tree.dices.indexOf( d.toString() );
						}
						if( dp===-1 ) {
							bugReport( 'Failed to parse tree ' + str );
						} else {
							j.dices = tree.dices.slice( 0, dp ) + tree.dices.slice( dp + 1 );
						}
						pos = this.parseTree( str, pos+1, j );
					}

					tree.moves.push( j );
			}
		}
		return pos;
	}

	addFastMovesFrom( root, leaf, source, set ) {
		if( !leaf.moves ) return;
		for( let o of leaf.moves ) {
			if( o.prevmove.source!==source ) continue;
			if( o.prevmove.source!==root )
				set.add( [ root, o ] );
			if( !o.prevmove.kill )
				this.addFastMovesFrom( root, o, o.prevmove.dest, set );
		}
	}

	getFastMoves( leaf ) {
		// Добавление быстрых ходов, чтобы отрабатывать от сервера длинные возвраты
		if( !leaf.fastMoves ) {
			leaf.fastMoves = new Set;
			for( let o of leaf.moves ) {
				this.addFastMovesFrom( o.prevmove.source, o, o.prevmove.dest, leaf.fastMoves );
			}
		}
		return leaf.fastMoves;
	}

	get bottomColor() {
		return this.bottom===1 ? 'black' : 'white';
	}

	get bottomPlace() {
		return (this.bottom + (this.game.whiteSide ?? 0)) % 2;
	}

	get isshort() {
		return this.bgtype?.includes( 'short' ) || false;
	}

	// Ходы
	boardClick( e ) {
		if( !this.myMoveNow ) return;
		if( !this.moveTree || !this.current.moves ) return;
		let t = e.target;
		if( !t.classList.contains( 'bgcell' ) ) return;
		// Клик на колонку
		let no = +t.dataset.no;
		if( !(no>=0) ) return;
		let cell = this.cells[no];
/*
		if( !cell.pips.length || cell.pips[0].dataset.color!==this.moveColor ) {
			// Не наша колонка. Рассмотрим возможность хода в неё. Можно ходить, если пустая, или в коротких - одна шашка врага
			return;
		}
*/
		if( LOCALTEST ) /*if( !cell.legalMove )*/ this.prepareCellMove( cell );
		console.log( 'Clicker ' + no );
		if( !cell.legalMove ) return;
		let best = cell.legalMove.from || cell.legalMove.to;
		if( !best ) {
			// Direct move not found
			return;
		}

		this.mouseOverHide();

		this.doMove( best, true );

		// Форсируем автоходы, если они есть, откатываются они отдельно
		this.forceAutoMoves();

		// Ход мог быть уже отправлен автоматически
		if( !this.myMoveNow ) return;

		this.prepareMove();

		this.mouseOverRestore();
	}

	doMove( best, toserver ) {
		// Восстановим деревце ходов
		let domoves = [];
		for( ; best!==this.current; best = best.parent ) {
			domoves.push( best );
		}

		this.movePath.push( this.current );

		for( ; domoves.length; ) {
			best = domoves.pop();
			let cto = best.prevmove.dest,
				cellto = this.cells[cto],
				cellfrom = this.cells[best.prevmove.source],
				pip = cellfrom.pips.pop();

			if( best.prevmove.kill ) {
				// Ход с выбиванием. Единственную вражескую шашку надо убрать и отправить в выбитые
				let killpip = cellto.pips.pop();
				if( killpip ) {
					this.movePipTo( killpip, this.cells[KILLED+1-this.moveWho] );
				}
			}

			this.checkCellShowCount( cellfrom );

			this.movePipTo( pip, cellto );
			this.current = best;
			best = null;
		}

		// this.game.sendMove( strmove );
		if( toserver ) {
			this.sendNode();

			if( !this.current.moves && this.noconfirmBox.classList.contains( 'checked' ) ) {
				this.diceHolder.hide();
				this.sendMove();
			}
		}
	}

	getNodeId( leaf ) {
		if( !leaf?.prevmove ) return "";
		if( leaf.strid ) return leaf.strid;
		leaf.strid = '';
		if( leaf.parent ) leaf.strid += this.getNodeId( leaf.parent );
		if( leaf.strid ) leaf.strid += '-';
		leaf.strid += `${leaf.prevmove.source}-${leaf.prevmove.dest}`;
		return leaf.strid;
	}

	// подготовка хода:
	// загреиваем остатки зариков, проставляем холдерам признаки куда ходить
	reprepareMove() {
		this.mouseOverHide();
		this.prepareMove();
		this.mouseOverRestore();
	}

	prepareMove() {
		let dstr = this.current.dices || '';

		if( this.diceStr.length===2 ) {
			// Обычный бросок
			this.dices[0].dataset.used = dstr.includes( this.diceStr[0] )? 0 : '100';
			this.dices[1].dataset.used = dstr.includes( this.diceStr[1] )? 0 : '100';
		} else {
			// Куш
			this.dices[0].dataset.used = [ 100, 100, 100, 50, 0][dstr.length].toString();
			this.dices[1].dataset.used = [ 100, 50, 0, 0, 0][dstr.length].toString();
		}

		if( !this.myMoveNow ) return;

		if( this.current.parent )
			setTimeout( () => {
				if( this.myMoveNow ) this.rollBack.show()
			}, 390 );
		else
			this.rollBack.hide();

		// Сразу определим из каких холдеров можно ходить, и куда. Что делать по клику
		for( let cell of this.cells ) {
			cell.legalMove = null;
			this.prepareCellMove( cell );
			cell.holder.classList.toggle( 'legal', !!cell.legalMove );
			// cell.holder.dataset.markcell = cell.legalMove?.from?.dest || cell.legalMove?.to?.source || '';
		}

		this.goButton.makeVisible( !this.current.moves?.length )
	}

	findMoveTo( cell, fromleaf, source ) {
		if( !fromleaf?.moves ) return;
		let best;
		for( let o of fromleaf.moves ) {
			let move = o.prevmove;
			if( source && move.source!==source ) continue;
			if( move.dest===cell.no ) {
				// Found move
				best = o;
				break; // if( !source && move.dist===this.diceSorted[0] ) break;
			}
		}
		if( best ) return best;
		// Попробуем найти ходы глубже по дереву, если ходит одна и та же шашка
		for( let o of fromleaf.moves ) {
			if( source && o.prevmove.source!==source ) continue;
			best = this.findMoveTo( cell, o, o.prevmove.dest );
			if( best ) return best;
		}
	}

	prepareCellMove( cell ) {
		cell.legalMove = null;
		if( !this.current.moves ) return;
		let no = cell.no, best;

		if( no===OUT+this.moveWho || !cell.pips.length || cell.pips[0].dataset.color!==this.moveColor ) {
			// Не наша колонка. Рассмотрим возможность хода в неё. Можно ходить, если пустая, или в коротких - одна шашка врага

			// Определим сколько шашек должно пойти сюда
			let n = 1;
			if( this.isshort ) n = 2;
			if( no>=OUT ) n = 4;		//  Выбрасываем по максимуму

			for( ; n--; ) {
				let b = this.findMoveTo( cell, best || this.current );
				if( !b ) break;			// Дальше хода нет
				best = b;
			}

/*
			if( n ) {
				// Прямой ход не найден. Однако в дереве могут быть ходы в эту ячейку длиной более 1
				// попытаемся найти их, если это ход одной (!) шашкой
				best = this.findLongMoveTo( cell );
			}
*/
/*
			for( let o of this.current.moves ) {
				let word = o.prevmove;
				if( word.dest===no ) {
					// Found move
					best = o;
					if( word.dist===this.diceSorted[0] ) break;
				}
			}
*/
			if( !best ) return;
			cell.legalMove = {
				to: best
			};
			return;
		}
		// Наша колонка. Можно ли ходить?
		for( let o of this.current.moves ) {
			let word = o.prevmove;
			if( word.source===no ) {
				// Found move
				best = o;
				if( word.dist===this.diceSorted[0] ) break;
			}
		}
		if( !best ) return;
		cell.legalMove = {
			from: best
		}
	}

	doRollBack() {
		if( !this.stepUp() ) return;
		this.prepareMove();
		this.sendNode();
	}

	stepUp() {
		if( !this.current?.parent ) return;
		let prev = this.movePath?.pop() || this.current.parent;
		// Откатываем ходы из дерева, пока не попадем на предыдущий из movePath
		for( ; this.current!==prev; ) {
			if( !this.current.parent ) break;
			let move = this.current.prevmove,
				cell = this.cells[move.dest],
				pip = cell.pips.pop();
			this.checkCellShowCount( cell );
			this.movePipTo( pip, this.cells[move.source] );
			if( move.kill ) {
				let kpip = this.cells[KILLED+1-this.moveWho].pips.pop();
				this.movePipTo( kpip, cell );
			}
			this.current = this.current.parent;
			// this.game.sendMove(  'rollback ' + this.moveCode );
		}
		return true;
	}

	cellMouseCheck( cell ) {
		if( !this.myMoveNow ) return;
		let move = cell?.legalMove;
		if( !move ) return;
		let no = move.from?.prevmove.dest;
		if( no>=0 ) {
			// Ход из этой ячейки в другую. Помечаем другую, а в этой помечаем топ-фишку
			if( !cell.pips.length ) return;
			this.setMouseMark( [ this.cells[+no].holder, cell.pips[cell.pips.length-1] ] );
			return;
		}
		no = move.to?.prevmove.source;
		if( no>=0 ) {
			// Ход в эту ячейку. Может сходить несколько шашек сразу, их надо пометить
			let mark = [ cell.holder ], pipfrom = [];
			for( let l = move.to; l!==this.current; l = l.parent ) {
				// l.prevmove - ход, который должен быть сделан при ходе в эту клетку.
				// найдем и пометим шашку, которая ходит "отсюда"
				let source = l.prevmove.source, dest = l.prevmove.dest;
				if( pipfrom.includes( dest ) )
					pipfrom[pipfrom.indexOf( dest )] = source;	// Этот зарик уже помечен (длинный ход)
				else
					pipfrom.push( source );
			}
			for( let c of pipfrom ) {
				let cell = this.cells[c];
				for( let i=1; ; i++ ) {
					let pip = cell.pips[cell.pips.length - i];
					if( !pip ) break;
					if( mark.includes( pip ) ) continue;
					mark.push( pip );
					break;
				}
			}
			this.setMouseMark( mark );
		}
	}

	setMouseMark( array ) {
		let last = this.lastMouseMark;
		if( array ) for( let o of array ) {
			if( !o ) {
				if( LOCALTEST ) debugger;
				bugReport( 'Empty gammon mark' );
				continue;
			}
			if( last?.includes( o ) ) continue;
			o.classList.add( "marked" );
		}
		if( last ) for( let o of last ) {
			if( array?.includes( o ) ) continue;
			o.classList.remove( "marked" );
 		}
		this.lastMouseMark = array;
		// if( this.lastMouseMark===cell ) return;
		// this.lastMouseMark?.holder.classList.remove( "marked" );
		// this.lastMouseMark = cell;
		// this.lastMouseMark?.holder.classList.add( "marked" );
	}

	cellMouseEnterLeave( e ) {
		let t = e.target,
			cell = t.cell,
			entered = e.type==="mouseenter";
		if( entered ) {
			this.lastMouseEnter = cell;
			if( !cell.legalMove ) return this.setMouseMark( null );
			this.cellMouseCheck( cell );
		} else {
			if( this.lastMouseEnter===cell ) {
				this.setMouseMark( null );
				this.lastMouseEnter = null;
			}
		}
	}

	mouseOverHide() {
		this.setMouseMark( null );
	}

	mouseOverRestore() {
		if( !this.myMoveNow ) return;
		if( this.lastMouseEnter ) {
			this.cellMouseCheck( this.lastMouseEnter );
		}
	}

	dicesClick() {
		if( !this.myMoveNow ) return;
		if( !this.moveTree || this.diceStr.length!==2 ) return;
		// Меняем местами зарики
		this.diceSorted.reverse();
		this.diceHolder.classList.toggle( 'switch' );
		this.reprepareMove();
	}

	checkMouseResume() {
		if( !this.myMoveNow ) return;
		let holder = this.board.$( '.bgcell:hover' );
		if( holder?.cell ) {
			log( 'Found cell under mouse' );
			this.mouseOverHide();
			this.lastMouseEnter = holder.cell;
			this.mouseOverRestore();
		}
	}

	sendMove() {
		if( !this.myMoveNow || this.current.moves?.length ) return;
		this.stopMove();
		this.game.sendMove( 'domove' );
	}

	sendNode() {
		if( !this.myMoveNow ) return;
		this.game.sendMove( 'movenode ' + this.getNodeId( this.current ) );
	}

	doDrop() {
		if( !this.movenow?.waitdrop ) return;
		this.stopMove();
		this.game.sendMove( 'dropit' );
	}

	moveConfirm() {
		// Сохраняем настройку подтверждения хода навсегда
		this.noconfirmBox.classList.toggle( 'checked' );
		let noconfirm = +this.noconfirmBox.classList.contains( 'checked' );
		elephCore?.setStorage( 'gammonnoconfirmmove', noconfirm );
		if( this.myMoveNow && !this.current.moves && noconfirm ) {
			this.sendMove();
		}
		toast( '{Moveconfirmation}: ' + ( noconfirm? '{NO}' : '{YES}') );
	}

	autoRollClick() {
		// Сохраняем настройку подтверждения хода навсегда
		this.autorollBox.classList.toggle( 'checked' );
		let on = this.autorollBox.classList.contains( 'checked' );
		elephCore?.setStorage( 'gammonautoroll', on );
		if( on && this.movenow?.waitdrop ) {
			this.doDrop();
		}
		toast( '{Autoroll}: ' + ( on? '{YES}' : '{NO}' ) );
	}

	bgpips( str ) {
		let p = str?.split( ' ' ) || [ '', '' ];
		this.textPips[0].textContent = p[0];
		this.textPips[1].textContent = p[1];
	}
}