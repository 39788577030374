//
// Логика для просмотра клеточных партий
// Пример:
// game=chess-classic&p=e2-e4.e7-e5.f1-c4.g8-f6.b1-c3.f8-c5.g1-f3.d7-d6.0-0.0-0.d2-d4.e5-d4.f3-d4.c5-d4.d1-d4.b8-c6.d4-d1.c8-e6.c1-g5.e6-c4&nn=1440435:иоан3&sn=1450390:ht1010&

const WB = '⚪⚫',
	chessfigs = { w: '♔♕♖♗♘♙', b: '' };

export async function parse( board, proto, params ) {
	window.skipAnimation = true;
	let
		ar = proto.split( '.' ),
		plno = 0,
		positions = {
			chess: 'RNBQKBNRPPPPPPPP................................pppppppprnbqkbnr',
			draughts: 'S.S.S.S..S.S.S.SS.S.S.S..................s.s.s.ss.s.s.s..s.s.s.s',
			'draughts-100': 'S.S.S.S.S..S.S.S.S.SS.S.S.S.S..S.S.S.S.S....................s.s.s.s.s..s.s.s.s.ss.s.s.s.s..s.s.s.s.s',
			ugolki: 'SSS.....SSS.....SSS..........................sss.....sss.....sss',
		},
		chess = board.game.gameType==='chess',
		draughts = board.game.gameType==='draughts',
		pos = positions[board.game.vgame.chainId] || positions[board.game.gameType] || '';
	let ugoltype = params.ugoltype;
	if( ugoltype ) {
		ugoltype = ugoltype.replace( 'x', '' );
		if( board.width===10 ) ugoltype = '10-' + ugoltype;
		let uposes = {
			'33': 'SSS.....SSS.....SSS..........................sss.....sss.....sss',
			'34': 'SSSS....SSSS....SSSS........................ssss....ssss....ssss',
			'44': 'SSSS....SSS.....SS......S..............s......ss.....sss....ssss',
			'10-34': 'SSSS......SSSS......SSSS....................................................ssss......ssss......ssss',
			'10-44': 'SSSS......SSSS......SSSS......SSSS................................ssss......ssss......ssss......ssss',
			'10-12345': 'SSSSS.....SSSS......SSS.......SS........S..................s........ss.......sss......ssss.....sssss'
		};
		if( uposes[ugoltype] )
			pos = uposes[ugoltype];
	}

	let ppos = params.position;
	if( ppos ) {
		let apos = ppos.split( '!' );
		pos = apos[0];
		if( apos[1]?.includes( 'B' ) ) plno = 1;		// Ход черных
	}

/*
	let debut = params.get?.( 'debut' ) || params.get?.( 'position' );
	if( debut ) {
		// Изеним строчку позиции в соответвтствии с заданной жеребьевкой типа
		// ab4+fg5+ba3+ef6+ed4
	}
*/

	board.game.nosound = true;

	await board.game.addFrame( {
		snapshot: `position ${pos}\nmarkers`
	} );
	// board.game.protocol.addLog( plno );

	for( let f of ar ) {
		let play = '', prot = f;
		if( chess ) {
			let far = f.match( /(.{2})[-:](.{2})([QRBN]?)(\+?)/ ),
				prot = '&nbsp;' + f;
			play = 'figmove ' + f;
			if( f==='O-O' || f==='0-0' )
				play = plno ? 'figmove e8-g8\nfigmove h8-f8\nmarkers e8,g8' : 'figmove e1-g1\nfigmove h1-f1\nmarkers e1,g1';
			else if( f==='O-O-O' || f==='0-0-0' )
				play = plno ? 'figmove e8-c8\nfigmove a8-d8\nmarkers e8,c8' : 'figmove e1-c1\nfigmove a1-d1\nmarkers e1,c1';
			else {
				let from = board.getCellFromNotation( far[1] ),
					to = board.getCellFromNotation( far[2] ),
					capture = board.figs[to] ? ':' : '-',
					promote = far[3] || '',
					check = far[4] || '',
					fr = board.figs[from],
					fignotation = fr?.type.toUpperCase();
				if( fignotation==='P' ) fignotation = '&nbsp;';
				if( !chess ) fignotation = '';
				play = `figmove ${from}-${to}`;
				prot = `${fignotation}${far[1]}${capture}${far[2]}${promote}${check}`;
				if( promote ) {
					// Во избежание ошибок с превращением черной пешки в белого ферзя
					// если пешка пришла на первую линию, значит черная. фигура - прописью
					promote = +to<=7? promote.toLowerCase() : promote.toUpperCase();
					play += '-' + promote;
				}
				// Проверим взятие на проходе
				if( fr?.type==='p' && from % 8!==to % 8 && !board.figs[to] ) {
					// Пешка сходила наискосок, но фигуры не было. Значит срубила пешку на проходе
					play += `\nfigmove ${from - from % 8 + to % 8}`;
				}
			}
		} else {
			// Все игры кроме шахмат
			let far = f.split( '-' ),
				cellstart = board.getCellFromNotation( far[0] ),
				// str = far[0],
				str = cellstart.toString(),
				fig = board.figs[cellstart],
				lastline = fig?.type==='s'? fig.color==='w' && board.width-1 || 0 : -1,
				promoted = false;
			for( let i=1; i<far.length; i++ ) {
				let w = board.width,
					cell = board.getCellFromNotation( far[i] ),
					line = (cell-cell%w)/w;
				str += '-' + cell;
				// str += '-' + far[i];
				// В шашках отследим превращение в дамку (при попадании на 1/8/10 линию)
				if( draughts && !promoted ) {
					if( line===lastline ) {
						str += line? '-D' : '-d';
						promoted = true;
					}
				}
			}
			play = 'figmove ' + str;

		}
		board.game.addFrame( {
			play: play,
			name: prot
		} );
		board.game.protocol.addLog( plno );
		plno = (plno+1)%2;
	}
	board.game.nosound = null;
	window.skipAnimation = false;
}