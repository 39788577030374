//
// Onesignal
//
let OneSignal;

function notificationReceivedCallback( json ) {
	log( 'OneSignal Notification Received: ' + JSON.stringify( json ) );
	if( json.collapseId==='invitation' ) {
		toast( json.payload.title + '; ' + json.payload.body );
	} else if( json.collapseId==='confirmonesignal' ) {
		try {
			API( '/confirmonesignal', {
				onesignalid: OneSignal.id,
				code: json.payload.body.code
			} );
		} catch( e ) {
		}
	}
}

function listener( event ) {
	log( `OneSignal event ${event.type}: ${JSON.stringify( event.notification )}` );
	// по-умолчанию не показываем уведомление, думая что речь про ход, а мы уже в приложении
	event.preventDefault();
	let notification = event.notification,
		notificationId = notification.notificationId,
		collapse = notification.collapseId,
		origin = collapse || '',
		hide = true;
	if( origin.startsWith( 'chat_' ) ) {
		let chatid = origin.split( 'chat_' )[1];
		// Если сейчас этот чат не открыт на экране, не проглатываем уведомление
		hide = modules.chat?.getBychatid( chatid )?.isVisible();
		if( !hide && origin ) addWaiting( origin, notificationId );
	}
	// Display notification if should
	if( !hide )
		event.notification.display();
	// event.complete( hide? null : event.getNotification() );
}

document.addEventListener( 'deviceready', () => {
	OneSignal = window.plugins.OneSignal;
	if( !OneSignal ) {
		log( 'No OneSignal plugin' );
		return;
	}
	log( 'OneSignal init' );
	// OneSignal.setLogLevel({logLevel: 6, visualLevel: DEBUG? 6:0});

	OneSignal.initialize( coreParams['onesignalappid'] || "29d7c2d5-abe6-4c0d-8ffc-c1be465e814e" );

/*
	OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
		log( "User accepted notifications: " + accepted );
	});

*/
	OneSignal.Notifications.addEventListener( "foregroundWillDisplay", listener );
	OneSignal.Notifications.addEventListener( "click", listener );

	// OneSignal.addEventListener?.( '*', listener );

	// OneSignal.Notifications.setNotificationWillShowInForegroundHandler?.( listener );

	OneSignal.setNotificationOpenedHandler?.(function( opened ) {
		log( 'OneSignal Opened ' + JSON.stringify( opened ) );
		let collapse = opened.notification.collapseId;
		if( collapse?.startsWith( 'chat_' ) ) {
			let chatid = collapse.split( 'chat_' )[1];
			log( 'Onesignal trying to jump to chat ' + chatid );
			import( './userinfo.js' ).then( mod => mod.default( chatid, null, 'showchat' ) );
		}
	});

/*
	OneSignal.getDeviceState(function(stateChanges) {
		log('OneSignal getDeviceState: ' + JSON.stringify(stateChanges) );
	});
*/

	// For IOS confirmation https://documentation.onesignal.com/docs/ios-push-opt-in-prompt
	// window.plugins.OneSignal.addTrigger("prompt_ios", "true");

	/*
		let	infd = /!* DEBUG ? OneSignal.OSInFocusDisplayOption.InAppAlert : *!/ OneSignal.OSInFocusDisplayOption.None;
		OneSignal
			.startInit( coreParams['onesignalappid'] || "29d7c2d5-abe6-4c0d-8ffc-c1be465e814e" )
			.handleNotificationReceived( notificationReceivedCallback )
			.handleNotificationOpened( notificationOpenedCallback )
			.inFocusDisplaying( infd )
			.endInit();
	*/

	// No geolocations
	OneSignal.Location.setShared( false );

	// Clear all notifications to me
	OneSignal.Notifications.clearAll();

	if( window.ONESIGNAL_EXTERNALUSERID )
		setExternalUserId( window.ONESIGNAL_EXTERNALUSERID );
} );

function onsuccess( e ) {
	log( 'OneSignal succcesed ' + JSON.stringify( e ) );
}

dispatch( 'onesignal_externaluserid', setExternalUserId );

function setExternalUserId( data ) {
	if( !OneSignal ) return;
	if( data )
		OneSignal.login( data, results => {
			// The results will contain push and email success statuses
			log( 'Onesignal Results of setting external user id:' );
			log( JSON.stringify( results ) );
		} );
	else
		OneSignal.logout();
}

let waitingmap = new Map;
function addWaiting( origin, notificationid ) {
	log( `Onesignal adding waiting notification for ${origin}: ${notificationid}` );
	if( !origin ) return;
	let set = waitingmap.get( origin );
	if( !set ) waitingmap.set( origin, (set = new Set) );
	set.add( notificationid );
}

dispatch( 'loggedout', () => setExternalUserId() );

dispatch( 'originopened', origin => {
	log( `Onesignal opened origin ${origin}` );
	let set = waitingmap.get( origin );
	if( set ) {
		for( let n of set ) {
			log( `Onesignal clear notification ${n}` );
			OneSignal?.removeNotification( n );
		}
		set.clear();
	}
});