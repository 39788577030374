/**
 * Facebook Login based on https://developers.facebook.com/docs/facebook-login/web
 */
"use strict";
import Auth from './auth.js';

let Core = elephCore,
	fbAuth;

function haveResponse( response ) {
	fbAuth = response.authResponse;
	if( !fbAuth ) return;
	log( 'FB logged as ' + fbAuth.userID );
	if( fbAuth.userID===Core.auth.extid ) {
		log( 'Already logged (signed_request)' );
	} else {
		checkLogin();
	}
	if( !Core.auth.info || !Core.auth.info.showname ) {
		MYNAME = Core.showName = fbAuth.userID;
		FB.api( '/me', function( response ) {
			Core.showName = response.name;
			// Show we store new auth information?
		} );
	}

	// FB.api( '/me/picture?redirect=false', function( response ) {
	// 	console.log( 'Me: ' + response );
	// } );

	// Попытаемся вытащить приложение
	checkInvitationJump();
}

async function checkInvitationJump() {
	log( 'Get all my requests' );
	let r = await fetch( `https://graph.facebook.com/me/apprequests?access_token=${fbAuth.accessToken}`);
	if( r ) r = await r.json();
	if( r ) {
		log( 'My invitations: ' + JSON5.stringify( r ) );
		let jumpto;
		// Ищем приглашение с data
		for( let inv of r.data ) {
			let requestID = inv.id;
			if( !inv.data || !jumpto ) {
				FB.api( requestID, 'delete', function( response ) {
					log( `Deleting ${requestID}: ${JSON.stringify( response )}` );
				} );
			}
			if( !inv.data || jumpto ) continue;
			jumpto = inv.data;
		}
		if( jumpto ) {
			log( 'Delete req and jumping to ' + jumpto );
			return goLocation( jumpto );
		}

	}

	let url = document.referrer || location.search;
	log( 'Check invitation from: ' + url );
	let sp = new URLSearchParams( url ),
		rq = sp && sp.get( 'request_ids' );
	if( !rq ) return;

	log( `Checking request ${rq}, try http://graph.facebook.com/${rq}?access_token=${fbAuth.accessToken}` );

	// Разберем запрос и отправимся на нужный стол
	r = await fetch(
		'http://graph.facebook.com/${rq}?access_token=${fbAuth.accessToken}' );
	if( !r ) return;
	let j = await r.json();
	if( !j ) return;
	log( 'Got invitation ' + JSON5.stringify( j ) );
	if( j.data )
		goLocation( j.data );
}

function checkLogin() {
	if( !fbAuth ) return;
	if( fbAuth.userID===Core.auth.extid ) {
		log( 'FB ' + Core.auth.extid + ' is aleady logged in as ' + Core.auth.uid );
		return;
	}
	if( Auth.failedLoginTypes && Auth.failedLoginTypes.has( 'fb' ) ) return;
	// Dont do login request while another logged user is active

	Auth.fetch( {
		type: 'fb',
		extid: fbAuth.userID,
		access_token: fbAuth.accessToken
	}).then( res => {
		if( res.error ) {
			log( 'FB error: ' + res.error );
		} else {
			log( 'FBauth res: ' + JSON.stringify( res ) );
		}

	} );
}

// dispatch( 'checklogin', checkLogin );
Core.login = checkLogin;

dispatch( 'invite', ( event ) => {
	if( !window['FB'] ) return;
	FB.ui( {
		method: 'apprequests',
		message: event.msg,
		data: event.data
	}, function( response ) {
		log( 'Invite res: ' + JSON.stringify( response ) );
		// TODO: игроков, которых пригласил, зарезервировать места (самое простое пока)
		// поскольку известны только Facebook ID , придется так и сохранять их,
		// если зайдет новый пользователь, придется помнить, что этого фейсбучника пригласили сюда
		// либо он должен разобраться-вытащить приглашение
	} );
} );

function loginFB() {
	if( !window['FB'] ) return;
	FB.getLoginStatus( function( response ) {
		if( response.status==="connected" ) {
			console.log( 'FB already connected' ); // + JSON.stringify( response ));
			haveResponse( response );
		} else {
			log( 'FB try login' );
			FB.login( haveResponse );
		}
	} );
}

function statusChangeCallback( response ) {
	console.log( 'statusChangeCallback' );
	console.log( response );
	// The response object is returned with a status field that lets the
	// app know the current login status of the person.
	// Full docs on the response object can be found in the documentation
	// for FB.getLoginStatus().
	if( response.status==='connected' ) {
		// Logged into your app and Facebook.
		testAPI();
	} else if( response.status==='not_authorized' ) {
		// The person is logged into Facebook, but not your app.
		console.log( 'Please log into this app.' );
	} else {
		// The person is not logged into Facebook, so we're not sure if
		// they are logged into this app or not.
		console.log( 'Please log into Facebook.' );
	}
}

window.fbAsyncInit = function() {
	FB.init( {
		appId: Core.params['fbappid'] || document.body.dataset['fbappid'] || 169243399822471,
		cookie: true,  // enable cookies to allow the server to access
		// the session
		xfbml: false,  // parse social plugins on this page
		version: 'v13.0' // use latest graph api version
	} );

	// Now that we've initialized the JavaScript SDK, we call
	// FB.getLoginStatus().  This function gets the state of the
	// person visiting this page and can return one of three states to
	// the callback you provide.  They can be:
	//
	// 1. Logged into your app ('connected')
	// 2. Logged into Facebook, but not your app ('not_authorized')
	// 3. Not logged into Facebook and can't tell if they are logged into
	//    your app or not.
	//
	// These three cases are handled in the callback function.

	loginFB();
};

// Load the SDK asynchronously
if( window.facebookConnectPlugin ) {
	log( 'fb connect try login' );
	facebookConnectPlugin.login( [],
		function fbLoginSuccess( userData ) {
			log( "fb UserInfo: " + JSON.stringify( userData ) );
			facebookConnectPlugin.getAccessToken( function( token ) {
				log( " fb Token: " + token );
				Auth.setLoginStr( 'LOGIN -tag=fb type=fb uuid=' + Auth.UUID + ' extid=' + fbAuth.userID + ' access_token=' + fbAuth.accessToken );
			}, function( err ) {
				alert( "Could not get access token: " + err );
			} );
		},
		function( error ) {
			log( "fb plugin error: " + JSON.stringify( error ) );
		}
	);
} else {
	// Web-site
	(function( d, s, id ) {
		let js, fjs = d.getElementsByTagName( s )[0];
		if( d.getElementById( id ) ) return;
		js = d.createElement( s );
		js.id = id;
		js.src = "https://connect.facebook.net/en_US/sdk.js";
		fjs.parentNode.insertBefore( js, fjs );
	}( document, 'script', 'facebook-jssdk' ));
}

// Here we run a very simple test of the Graph API after login is
// successful.  See statusChangeCallback() for when this call is made.
window.testAPI = function() {
	console.log( 'Welcome!  Fetching your information.... ' );
	FB.api( '/me', { fields: 'name' }, function( response ) {
		console.log( 'Successful login for: ' + response.name );
	} );
};

