import Cardholder from "./cardholder.js";

cssInject( 'cards' );

let
	mediaPointer = window.matchMedia( '(pointer:fine)' );

window.pointerFine = mediaPointer.matches;

mediaPointer.addListener( media => {
	log( 'We have pointer: ' + (media.matches ? 'FINE' : 'not fine') );
	window.pointerFine = media.matches;
} );

class Battle {
	constructor( game ) {
		this.game = game;
		this.holder = construct( '.battlefield.centered_all.column[data-sizebycard=x2]', game.playHolder );
		this.lines = [];
		game.addRoute( {
			placechanged: this.setExcept.bind( this )
		});
	}

	setExcept( e ) {
		if( e!==undefined ) {
			if( this.except===e ) return;
			this.except = e;
		}
		let pov = this.game.getpov;
		this.holder.style.flexDirection = +e!==pov ? 'column-reverse' : 'column';
		requestAnimationFrame( () => this.lines.forEach( h => h.sizeControl( 'setExcept' ) ) );
	}

	parse( o ) {
		this.setExcept( o?.except );
		if( o?.lines ) {
			let lines = o.lines;
			for( let l = 0; l<4; l++ ) {
				if( lines[l]!==undefined && !this.lines[l] ) {
					this.lines[l] = new Cardholder( this.game, 'battle' + l, {
						scale: 1,
						align: 'c',
						// prefix: `battle${l}_`,
						keepSpot: true,
						zAdd: l * 100
					} );
					this.holder.appendChild( this.lines[l].holder );
					if( l===0 )
						this.lines[0].onChange = this.sync.bind( this );
				}
				if( lines[l]!==undefined ) {
					let old = this.lines[l].makeStr(),
						added = this.lines[l].setStr( lines[l] );
					if( added ) {
						// Battle изменен
						log( `Battle line ${l} changed ${old}=>${lines[l]}. Added ${added}` )
					}
				}
			}
		} else this.clear();
	}

	clear() {
		for( let k in this.lines ) this.lines[k].clear();
	}

	sync() {
		let l = this.lines[0].count;
		for( let k = this.lines.length; k--; ) {
			if( !k ) continue;
			let ch = this.lines[k];
			if( ch.count && l>ch.count )
				ch.add( '0'.repeat( l - ch.count ) );
		}
	}

	getCards() {
		let set = new Set;
		for( let k = this.lines.length; k--; ) {
			let ar = this.lines[k].getCards();
			for( let c of ar ) set.add( c );
		}
		return set;
	}
}

export class Cards {
	#handParserBind = this.handParser.bind( this );
	#handClickBind = this.#handClick.bind( this );
	#checkTricksBind = this.#checkTricks.bind( this );
	#makingPremove;
	#premove;
	#findHolderPos;
	#dealTricks; #dealPoints; #useDealPoints;
	#dealSeeHand = new Set;
	#launched;
	static #lastMoveTime;
	static #hideLivePoints = elephCore?.globalAttr.hidelivepoints;

	constructor( game, options ) {
		this.game = game;
		game.cardsModule = this;
		this.options = options || {};
		game.setModule( 'cards' );
		game.setWideModel();

		this.cardHolder = [];
		this.cardMove = [];
		this.openHand = [];
		// this.roseleaf = [];
		this.lastMove = [];
		this.cardMoveIndex = 0;	// card index for center move (addition of new cards increases it)

		// this.panel = construct( '.use_bg.cardspanel.gridone.abs100' );
		this.panel = html( `<div class='use_bg cardspanel gridone' 
			style='align-self: stretch; flex-basis: 100%; position: relative'></div>` );
		elephCore?.track( this.panel, 'bg' );
		// elephCore?.track( game.playArea, 'bg' );

		this.trickBox = construct( '.gridone.card_trickbox', this.panel );

		this.trickLast = construct( '.fade.lightborder.hidewhenminifyed.gridone.card_tricklast[data-track=bottomwide]', this.lastTrickClick.bind( this ) );
		this.panel.appendChild( this.trickLast );
		this.lastMove[4] = new Cardholder( game, 'tricklastcenter', {
			className: 'centerline',
			prefix: 'last_',
			scale: 'holder',
			align: 'c',
			// suitsplit: 2,
			zAdd: 1000
		} );
		this.trickLast.appendChild( this.lastMove[4].holder );

		game.talon = this.talon = new Cardholder( game, 'talon', {
			className: 'talon',
			align: 'c',
			scale: 1,
			prefix: 'talon_',
			capacity: 3
		} );
		this.panel.appendChild( this.talon.holder );

		// let packCentered = game.isbelot;
		game.pack = new Cardholder( game, 'pack', {
			className: 'pack',
			// classes: packCentered &&' centered',
			align: 'c',
			scale: 1,
			sort: 'none',
			prefix: 'talon_',
			capacity: 1.7
		} );
		game.pack.holder.dataset.position = 'pack';
		this.specButtons = {
			change7: construct( '.display_none.button.change7 ⇅7', this.dochange7.bind( this ), game.pack.holder ),
			doezd: construct( '.display_none.button.doezd 🎯', game.doezd.bind( game ), game.moveControls ),
			redeal: construct( '.display_none.mybuttonplace.button.redeal {Redeal}', () => game.sendMove( 'bid redeal' ), game.moveControls ),
			beru: construct( '.display_none.mybuttonplace.button.beru {Itake}', () => game.dropMove( 'bid beru' ), game.moveControls ),
			hvatit: construct( '.display_none.mybuttonplace.button.beru {Hvatit}', () => game.dropMove( 'bid hva' ), game.moveControls ),
			four8: construct( '.display_none.mybuttonplace.button.four8 {Four} 8', () => game.sendMosendMove( 'bid four8' ), game.moveControls )
		};

		if( game.solo?.isbuilder || (!game.solo && LOCALTEST) || LOCALTEST ) game.navi.rotate.show();
		game.afterLayout.add( this.cardsLayout.bind( this ) );

		this.panel.appendChild( game.pack.holder );

		game.addResizeListener( this.onresize.bind( this ) );
		// centerSquare = construct( '.centersquare.centered_all', panel );
		// centerObserver = new IntersectionObserver( observer, {
		// 	root: centerSquare,
		// 	threshold: [ 0, 0.01 ]
		// })

		game.cardHolder = [];
		game.cardMove = [];

		// this.rose = construct( '.fade.roseside_new', game.playZone );
		// this.roseCenter = construct( '.rem[data-position=center]', this.rose );

		// Проверяем компоненты для игровых рук
		for( let i = 0; i<4; i++ ) {
			// Side names for rose
			// game.requireLayout( i, this.roseleaf[i] = construct( '.roseleaf ' + 'NESW'[i], this.rose ) );

			// Players info (avatar, nick..)
			let plr = game.checkPlayer( i );

			let ch = new Cardholder( game, i, {
				plno: i,
				className: 'hand',
				sort: 'always',
				// useplayerholder: true,
				multiLine: true,
				resizeIfSide: true,
				suitsplit: !game.isdomino,
				stepPercent: window.pointerFine ? 40 : undefined,
				scale: 1
			} );
			ch.onclick = this.#handClickBind;
			// game.usePlayerHolder = true;
			game.cardHolder[i] = this.cardHolder[i] = ch;
			// mh.appendChild( ch.holder );
			game.requireLayout( i, ch );
			// centerObserver.observe( ch.holder );
			if( game.maxPlayers===3 && i===3 ) {
				// Fixed hand on top. No player
				ch.holder.classList.add( 'noplayer' );
			}

			// Players move
			let move = new Cardholder( game, i + 'move', {
				plno: i,
				sortType: 'none',
				className: 'move',
				group: 'trick',
				classes: 'basealign CENTERMOVE',
				scale: 1,
				zAdd: 1000,
				narrowScale: game.isbridge ? 1.5 : null
				/*align: 'c'*/
			} );
			this.trickBox.appendChild( move.holder );
			game.requireLayout( i, move );
			game.cardMove[i] = this.cardMove[i] = move;

			// Last trick cards, special prefix 'last' because
			// the same cards could be placed in some open hand and last little trick at one time

			let limove = new Cardholder( game, i + 'limove', {
				plno: i,
				classes: 'basealign LASTTRICK',
				group: 'trick',
				prefix: 'last_',
				scale: 'holder',
				zAdd: 1000
				// align: 'auto'
			} );
			this.trickLast.appendChild( limove.holder );
			game.requireLayout( i, limove );
			this.lastMove[i] = limove;

			plr.putAllTo( plr.elbox, ch.holder );
			plr.elbox.appendChild( plr.elnick );
			plr.elbox.appendChild( plr.elavatar );
			plr.elbox.appendChild( plr.elmsg );
			plr.elbox.appendChild( plr.elarrow );
			// Пока карт нет elbox находятся у основного родителя playZone
			this.panel.appendChild( plr.elbox );
			game.requireLayout( i, plr.elbox );
			// ch.holder.appendChild( plr.elbox ); // comment 060624
			// Added 28/04/24. For beaty
			plr.elbox.appendChild( plr.elsitin );
			// }
			// plr.elavatar.appendChild( plr.elscore );

			this.panel.appendChild( ch.holder );
		}

		this.doDropButton = construct( '.fade.mybuttonplace.dodropbutton {Todropout}', 'button', this.dropClick.bind( this ), game.moveControls );

		game.playZone.appendChild( this.panel );
		game.playHolder = this.panel;
		game.playCards = this.panel; // game.playZone;
		game.playArea.classList.add( 'cardgamearea' );

		game.kibiButton = html( `<button class='display_none hidereplay mybutton m' data-action='kibiall'>{Observetable}</button>`,
			game.controlZone );
		game.checkKibiAll();
		// game.watcher.watch( 'myplace', game.checkKibiAll );

		// Players moves
		let route = {};			// move parameters
		this._route = route;

		route.battle = route.bbattle = o => {
			if( !o ) return battle.clear();
			this.game.battle ||= new Battle( this.game );
			this.game.battle.parse( o );
		}

		this.game.sideMovesVisible = () => {
			let res = this.cardMove[this.game.bypos.left]?.count ||
				this.cardMove[this.game.bypos.right]?.count || false;
			return res;
		}

		// Last trick
		route['tricklast'] = str => {
			this.trickFormat( this.lastMove, str );
			this.trickLast.classList.toggle( 'visible', !!str );
		};

		// Pack (side info)
		route['pack'] = str => {
			if( !str ) return game.pack.clear();
			let ar = str.split( ',' ),
				ps = ar[0];
			if( ar[1]?.[0]==='?' )
				ps += ar[1];
			else if( +ar[1] )
				ps += '?' + ar[1];
			game.pack.setStr( ps ); // TODO: надо как-то добавлять закрытые карты+ (ar[1]&&'0'||'') );
			game.checkTrumpVisible();
			game.checkPackPosition();
		};

		game.checkPackPosition = () => {
			if( !game.pack ) return;
			game.pack.toggleClass( 'centered', game.isbelot && game.gameState==='bidding' );
		}

		route['state'] = o => {
			this.#checkPremove();
			game.checkPackPosition;
		}

		// Talon (add-buy)
		// Format: <cards>[:poscode[:prevposcode]]
		route['talon'] = str => {
			if( !str ) return this.talon.clear();
			let parts = str.split( ':' );
			if( parts[1]==='l' ) {
				this.trickFormat( this.lastMove, parts[0] );
				this.talon.clear();
				return;
			}

			this.talon.setStr( parts[0] );
			let target = parts[1] || (this.talon.count && 'c');
			if( parts[2] ) {
				// Where the talon should be moved from
				this.talon.moveTo( this.findHolder( parts[2] ), 'virtual' );
			}
			if( target ) {
				this.talon.moveTo( this.findHolder( target ) );
			} else
				this.talon.clear();
		};

		route['hands'] = str => {
			// All hands in PBN format
			let hands = str?.split( ',' );
			for( let i = 0; i<this.game.maxPlayers; i++ )
				this.handParser( hands?.[i] || '', i );
		}

		route.ddsolution = o => {
			// Prepare table of ddsolution
			// s: '♠️️', c: '♣', d: '🔶', h: '❤️'
			if( !o || o.error ) return;
			let str = '';
			if( o ) {
				str = `<table style='border-spacing: 0.7em 0.2em; text-align: center'><tr><td></td><td>NT</td><td>♠️️</td><td>❤️️</td><td>🔶</td><td style='color: green'>♣</td></tr>`;
				for( let side of 'NSEW' ) {
					let plno = 'NESW'.indexOf( side );
					str += `<tr><td>${side}</td>`;
					for( let suit of 'NSHDC' ) {
						let n = +o[suit]?.[side] || '';
						// До 6 взяток пока не показываем (возможно, по клику)
						// Гейм-зеленым - шлемик - оранжевым, оба - жирным
						if( o.waiting ) {
							if( suit==='N' )
								str += `<td colspan=5 rowspan=4 class='spinner'></td>`;
						} else {
							let s = n>=6 ? n : '', style = '';
							if( n>=13 ) style = 'color: red; font-weight: bold';
							else if( n>=12 ) style = 'color: orange; font-weight: bold';
							else if( n>={
								N: 9,
								S: 10,
								H: 10,
								C: 11,
								D: 11
							}[suit] ) style = 'color: green; font-weight: bold';
							else if( n===6 ) style = 'color: gray';
							if( s )
								str += `<td style='${style}' data-tricks='${n}' data-action='dd_${plno}${suit}${Math.max( 1, n - 6 )}'>${s}</td>`;
							else
								str += '<td></td>';
						}
					}
					str += '</tr>';
				}
			}
			this.game.ddSolution ||= html(
				`<div class='ddsolution display_none'
					data-onlyonevisible='center' 
					style='background: var( --light_white ); overflow: hidden;
					z-index: 10000; grid-area: 1/1; padding: 0.5em; border-radius: 10px;	 
					align-self: center; justify-self: center; position: relative;'>
					   <div class='column center' >
					   <div class='tableplace'></div>
					   <button class='default display_none' data-action='setauction'>{Auction}</button>
					   </div> 
					</div>`,
				this.panel );

			this.checkDD();
			// window._DEBUG_DDICON = this.game.ddIcon;

			/*
						this.game.ddCaption ||= dds => {
							// Покажем временное решение
							let cap = this.game.dd.icon; //.$( '.caption' );
							if( !cap ) return;
							cap.setContent( dds==='?'? '' : dds || '' );
							cap.setSpinner( !dds || dds==='?' );
							if( !dds || dds==='?' ) return;
							let clr = dds && (dds==='=' || +dds>0 ? 'green' : 'red') || '';
							cap.style.border = dds? '' : '2px dashed ' + clr;
							cap.style.color = dds ? clr : '';
						}
			*/
			this.game.ddSolution.$( '.tableplace' ).innerHTML = str;
			this.game.ddSolution.$( 'button' ).makeVisible( !!o.allowSetAuction );

			this.game.dd.icon.setSpinner( o.waiting );
			this.game.dd.icon.setContent( o.waiting ? '' : 'DD' );
		}

		for( let i = 10; i--; ) {
			route[i + '_cardhand'] = this.#handParserBind;
			route[i + '_hand'] = this.#handParserBind;
			route[i + '_shand'] = this.#handParserBind;
		}

		// route['game'] = () => {
		// }

		/*
				route.contrat = () => {
					this.dropSolutions();
				}
		*/

		if( !this.options.nonstdmove ) {
			route.floptrick = this.#flopTrick.bind( this );
			route.cardmove = this.#cardmove.bind( this );
			route.undocardmove = this.#undoCardmove.bind( this );
		}

		// В портретном режиме карты отдельно, а playerholder - в центре
		this.checkPlayerHolders();
		narrowPortraitMedia.addEventListener( 'change', this.checkPlayerHolders.bind( this ) );

		game.setTopPanel( this.panel );

		game.addRoute( this );

		// Перекроем makeSnapshot
		game.parseProto = async proto => {
			if( !LOCALTEST ) return;
			(await import( './logic.js' )).parse( this, proto );
		};

		let cb = this.#trickClick.bind( this );
		for( let p of this.game.players ) {
			p.eltricks.onclick = cb;
		}
	}

	updateInnerRect() {
		delay( this.#checkInnerRectBind );
	}

	// Fix inner rectangle between playing hands
	#checkInnerRectBind = this.#checkInnerRect.bind(this);
	#checkInnerRect() {
		let rect = {};
		for( let ch of this.cardHolder ) {
			const ar = [ 'left', 'top', 'right', 'bottom' ];
			let idx = ar.indexOf( ch.position );
			if( idx===-1 ) continue;
			let f = ar[(idx+2)%4];
			let or = ch.holder.offsetParent;
			if( !ch.boundingRect && LOCALTEST ) debugger;
			rect[ch.position] = ch.boundingRect[f];
		}
		if( rect.top===rect.bottom ) return;
		this.panel.style.setProperty( '--innerwidth', (rect.right-rect.left)+'px' );
		this.panel.style.setProperty( '--innerheight', (rect.bottom-rect.top)+'px' );
		let mr = this.panel.getBoundingClientRect(),
			btop = this.cardHolder[this.game.bypos.bottom].holder.offsetTop;
		this.panel.style.setProperty( '--bottomhand_height', (mr.height - btop)+'px' );
	}

	route_cardmoves( o ) {
		// cardMoveIndex - глобальный индекс текущего хода.
		// умноженный на 10 он дает zIndex для выкладываемой в ходу карты
		// чтобы карты во взятке ложились друг на друга
		// Меняется при cardmoves и cardmove (по одной)
		// используется в dragMove
		let tf = this.trickFormat( this.cardMove, o );
		this.setCardMoveIndex( tf.index );
		this.game.needCheckObjects();
		this.#checkPremove( tf.first )
	}

	route_deal( o ) {
		let game = this.game;
		if( game.maxPlayers>4 ) return;
		let vuln = game.getVuln;
		// for( let i = 0; i<game.maxPlayers; i++ )
		// 	this.roseleaf[i].classList.toggle( 'vulnerable', vuln.includes( i ) );
		// this.rose.makeVisible( game.isbridge );
		let num = +game.dealInfo['tournumber'];
		if( !num || num<0 ) num = +game.dealInfo['number'];
		if( !num || num<0 ) num = '';
		// this.roseCenter.textContent = num;
		if( game.isbridge ) {
			// o.undertitle? this.bridgeRose.dataset.undertitle = o.undertitle : delete this.bridgeRose.dataset.undertitle;
			let dealer = num>0 && game.dealInfo.dealer;
			if( +game.dealInfo['tournumber']>0 ) dealer = (+game.dealInfo['tournumber'] - 1) % 4;
			/*
								for( let i=4; i--; ) {
									this.roseleaf[i].textContent = /!*dealer===i? 'D' :*!/ 'NESW'[i];
									this.roseleaf[i].classList.toggle( 'dealer', dealer===i );
								}
			*/
			// Новая компактная роза
			let scoring = o?.scoring || '';
			this.bridgeRoseIcon.$( '.dealno' ).textContent = num || scoring || '';
			this.game.fullBoardInfo.$( '.dealno' ).textContent = num || '';
			this.game.fullBoardInfo.$( '.scoring' ).textContent = scoring || '';
			// this.bridgeRoseIcon.style.fontSize = num>=100? '15px' : '20px';
			this.cardsLayout();
			this.bridgeRoseIcon.makeVisible( o && (!!num || ('vuln' in o)) );
		}
	}

	route_game( o ) {
		this.gameInfo( o );
		if( !this.game.isbridge ) return;
		for( let i = 4; i--; )
			this.cardHolder[i].holder.classList.add( 'hideclosedside' );
	}

	route_battleexcept( str ) {
		this.game.battle?.setExcept( str );
	}

	route_solution( o ) {
		this.dropSolutions();
		// if( this.solvedCards )
		// 	for( let c of this.solvedCards ) c.removeAttribute( 'data-solution' );
		this.solvedCards = this.game.cards.getCards( o.cards );
		if( !this.solvedCards ) return;
		// Проверим надо ли помечать хорошие-плохие решения
		// Общая логика: помечаем зеленым единственный лучший ход, если всего ходов более 3.
		// Иначе помечаем красным все не лучшие ходы
		let marks = o['marks'], tricks = o['tricks'], best = o['best'];
		if( best!==undefined ) {
			let bestcount = 0, badcount = 0;
			for( let i = tricks.length; i--; ) tricks[i]===best ? bestcount++ : badcount++;
			let markgood = bestcount===1 && tricks.length>3, i = 0;
			for( let c of this.solvedCards ) {
				c.dataset.solution =
					(markgood && tricks[i]===best && '!') ||
					(!markgood && tricks[i]!==best && '?') || '';
				i++;
			}
		} else {
			let i = 0;
			for( let c of this.solvedCards ) c.dataset['solution'] = marks[i++];
		}
	}

	route_event( str ) {
		if( str==='newdeal' ) {
			for( let o of this.cardMove ) o.clear();
			for( let o of this.lastMove ) o.clear();
			for( let o of this.cardHolder ) o.clear();
			this.#dealPoints = null;
			this.#dealSeeHand.clear();
			this.#clearPremoves();
			for( let ch of this.panel.$$( '.solid_card' ) )
				ch.onScreen = null;
			this.talon.clear();
			this.game.pack.clear();
			if( this.game.battle ) this.game.battle.clear();
		} else if( str==='findeal' )
			this.#clearPremoves();

		// if( str==='startplay' ) {
		// 	if( navigator.vibrate ) navigator.vibrate( 500 );
		// }
	}

	route_ddprognosis( o ) {
		this.game.dd.setPrognosis( o );
	}

	route_ddnextplay( o ) {
		// if( LOCALTEST ) console.warn( 'dd ' + JSON.stringify( o ) );
		this.dropSolutions();
		if( !o || !this.game.contract ) return;
		this.checkDD();
		if( !o.plays ) {
			// let p = this.game.dd?.prognosis;
			// Calculating spinner
			// if( o==='??' && p?.textContent ) return;
			this.game.dd.setPrognosis( o.prognosis );
			this.game.dd.setSpinner( o.next );
			return;
		}
		let scores = [], min, max, diffscores = 0, prognosis = -1, prognosis_str,
			declarerplays = this.game.contract.declarer % 2===o.next % 2;
		for( let c of o.plays ) {
			if( max===undefined || c.score>max ) max = c.score;
			if( min==undefined || c.score<min ) min = c.score;
			if( !scores[c.score] ) {
				scores[c.score] = [];
				diffscores++;
			}
			let card = this.game.cards.getCard( c.suit + c.rank );
			scores[c.score].push( card );
			for( let r of c.equals )
				scores[c.score].push( this.game.cards.getCard( c.suit + r ) );
			if( prognosis=== -1 || c.score>prognosis ) prognosis = c.score;
		}
		let best = max; //  this.game.contract.declarer%2===o.next? max : min;
		let markgood = scores[best].length===1 && diffscores>3,
			inhand = o.inhand || this.cardHolder[o.next].count;
		// It seems always good idea to gray bad moves. Looks nice
		markgood = false;
		for( let kk in scores ) {
			let k = +kk;
			let tricks = declarerplays ? o.trickstaken + k : inhand - k + o.trickstaken,
				targettricks = tricks - o.level;
			if( targettricks>0 ) targettricks = '+' + targettricks;
			targettricks ||= '=';
			if( prognosis===k ) prognosis_str = targettricks;
			for( let card of scores[k] ) {
				card.dataset.solution =
					(markgood && k===best && '!') ||
					(!markgood && k!==best && '?') || '';
				card.dataset.ddstricks = targettricks;
				this.solvedCards.push( card );
			}
		}
		if( prognosis_str )
			this.game.dd.setPrognosis( prognosis_str );
		this.game.dd.spinner.hide();
	}

	#trickClick( e ) {
		// Explain live points if it. Why showing it now (first deal)
		// Offer to buy package to show it always
		if( !this.#useDealPoints ) return;		// No dealpoints
		if( this.game.isPayer ) {
			// Switch live points on/off
			Cards.#hideLivePoints = !Cards.#hideLivePoints;
			elephCore.setAttr( 'hidelivepoints', Cards.#hideLivePoints );
			this.#checkTricks();
		} else {
			// Can't see dealpoints
			let str = '{Livepoints_info}';
			if( +e.currentTarget.dataset.points>0 ) {
				// Demonstration of the points
				str += '<br><span style="font-size:1rem; font-style: italic">{Livepoints_firstboarddemo}</span>';
			}
			let w = makeBigWindow( {
				repeatid: 'livepoints',
				title: '{Livepoints}',
				html: `<div class='column'>${str}<button default class='importantsize' data-closeselect='buy' style='margin-top: 1em'>{Buy}</button></div>`
			});
			w.promiseShow().then( res => {
				if( res==='buy' ) elephCore.shopping( 'premium', 'livepoints' );
			});
		}
	}

	route_dealpoints( o ) {
		this.#dealPoints = o.split( ',' );
		this.#useDealPoints = true;
		delay( this.#checkTricksBind );
	}

	route_tricks( o ) {
		this.#dealTricks = o.split( ',' ).map( x => x.split( ':' )[0] );
		if( o.includes( ':' ) ) {
			let ar = o.split( ',' ).map( x => x.split( ':' ) );
			// Format N[:N/REQUIRED][:points]
			// this.#dealTricks = ar.map( x => x[0] );
			// this.#dealPoints = ar.map( x => x[1] );
			// this.#useDealPoints = true;
		}
		delay( this.#checkTricksBind );
	}

	#checkTricks() {
		const
			game = this.game,
			players = game.players,
			mp = game.maxPlayers;
		// Tricks count only in preferans or bridge or
		let showCount = game.ispref || game.isbridge,
			showPoints = ( game.isPayer ) && this.#useDealPoints && true || false,
			points = ( showPoints && this.#dealPoints ) || this.#dealPoints?.map( x => '?' );
		if( this.#useDealPoints && !showPoints ) {
			// Block by payment. Show our hand only in first deal
			if( this.game.dealInfo?.number===1 && points && this.game.myPlace>=0 )
				points = this.#dealPoints; // [this.game.myPlace] = this.#dealPoints[this.game.myPlace];
		}
		// Плательщик может отключить livepoints (для тренировки)
		if( this.game.isPayer && Cards.#hideLivePoints ) points = ''.repeat( 4 );
		if( game.gameInfo.pairs ) {
			let pov = game.getpov, opp = (pov + 1) % mp;
			if( game.contract?.declarer%2===pov%2 ) pov = game.contract.declarer;
			if( game.contract?.declarer%2===opp%2 ) opp = game.contract.declarer;
			players[pov].setTricks( this.#dealTricks[pov%2], points?.[pov % 2] );
			players[opp].setTricks( this.#dealTricks[1 - pov % 2], points?.[1 - pov % 2] );
			players[(pov + 2) % mp].setTricks( '' );
			players[(opp + 2) % mp].setTricks( '' );
		} else {
			for( let i = mp; i--; )
				players[i].setTricks( this.#dealTricks[i], points?.[i] ?? points );
		}
	}

	#clearPremoves( one ) {
		for( let ch of this.panel.$$( `.solid_card[data-premove${one&&'="one"'||''}]` ) )
			delete ch.dataset.premove;
	}

	#moveCardsToHand( set, hand ) {
		if( hand.countVisible )
			hand.add( set );
		else {
			// Move back to closed hand
			hand.placeToCenter( set, { remove: true } );
		}
	}

	#undoCardmove( source ) {
		if( !source ) return;
		let plno = +source[0],
			str = source.slice( 1 ),
			card = this.getCards( str )?.[0],
			cm = this.cardMove[plno],
			ch = this.cardHolder[plno];
		if( !cm || !card || card.owner!==cm ) {
			log( `Undo card ${str} is not in cardmove ${plno}` );
			return;
		}
		this.#moveCardsToHand( [ card ], ch );
	}

	#flopTrick( o ) {
		this.#premove = null;
		this.cardMoveIndex = 0;
		let plno = o?.plno || +o;
		if( plno>=0 ) {
			for( let c of this.cardMove ) {
				this.cardHolder[plno].placeToCenter( c.getCards(), {
					remove: true
				} ); //, 'hide' );
			}
		} else {
			for( let i in this.cardMove ) {
				// h.clear( true );
				this.lastMove[i].setStr( this.cardMove[i].getCards() );
			}
			for( let el of this.cardMove ) el.clear();
		}
		this.game.needCheckObjects();
		// Flop last trick to owner
	}

	#cardmove( source ) {
		if( !source ) {
			// trickBox.holder.classList.add( 'nodisplay' );
			return;
		}
		// Detect holder-receiver
		let ar = source.split( ':' ),
			origin = ar[0],
			holder = this.findHolder( origin[0] ),
			plno = +origin[0],
			cards = origin.slice( 1 ),
			set = this.getCards( cards, holder ),
			target = this.cardMove[plno],
			add = false, fade = false, pos = undefined;
		// Clear premove marker
		if( set )
			for( let card of set ) if( card ) delete card.dataset.premove;
		// Detect correct target
		try {
			if( ar[1] ) {
				target = this.findHolder( ar[1] );
				pos = this.#findHolderPos;
				if( Number.isNaN( pos ) ) pos = undefined;
				add = true;
				fade = ar[1]==='trash';
			}
			if( !target ) return;
			if( !set ) return target.clear();
			// First check if card is already there
			if( target.has( set ) ) return;
			// game.cards.translateFromTo( set, cardHolder[plno], cardMove[plno] );
			// if( document.hasFocus() ) {
			// Set positions of new cards to center of players hand for smooth moving
			if( plno>=0 && !this.cardHolder[plno].str ) {
				// Если исходный объект - невидимая рука, то анимируем из её центра
				log( `Animating from invisible hand ${plno}` );
				this.cardHolder[plno].placeToCenter( set, {
					resizeTo: this.cardMove[plno],
					mode: 'virtual'
				} );
			}
			if( fade ) {
				target.placeToCenter( set, { remove: true } );
			} else {
				if( target.isHand )
					this.#moveCardsToHand( set, target );
				else if( add )
					target.add( set, false, this.cardMoveIndex, pos );
				else
					target.setStr( set, false, this.cardMoveIndex, pos );
			}
			this.cardMoveIndex++;
			log( 'cardMoveIndex=' + this.cardMoveIndex );
			// If there is a move from this holder with only one card count
			// then STOP this move
			if( this.onParams ) {
				if( !this.onParams.multi && this.onParams.cards.includes( cards ) ) {
					log( 'Canceling move because done from other device' );
					this.stopCardMove();
				}
			}
			this.game.needCheckObjects();
		} finally {
			// Premove
			let cv = this.cardMove.reduce( ( acc, cur ) => acc + (!!cur.countVisible), 0 )
			if( cv===1 ) this.#checkPremove( plno );
		}
	}

	#handClick( e ) {
		let card = e.target;
		if( !card.classList.contains( 'solid_card' ) ) return;

		if( this.#premove ) {
			let tdiff = Date.now() - Cards.#lastMoveTime;
			if( tdiff < 500 ) {
				log( `Premove ${card.str} blocked by time after move ${tdiff}ms` );
				return;
			}
			if( card.dataset.premove )
				delete card.dataset.premove;
			else {
				if( this.#canPremove( card ) ) {
					let ch = card.owner;
					card.dataset.premove = '1';
					log( `Set premove card ${card.str}. After move ${tdiff}ms `)
					for( let c of ch.getCards() )
						if( c!==card && c.dataset.premove )
							delete c.dataset.premove;
				} else {
					log( `NO premove for ${card.str} (hand ${card.dataset.owner}:  this.#premove.suit=${this.#premove.suit} contract=${JSON.stringify(this.game.contract)}` );
				}
			}
		}
	}

	#canHandle( plno ) {
		if( !this.game.isRealPlayer ) return;
		// Bridge: declarer plays himself and dummy
		let myplace = this.game.myPlace,
			contract = this.game.contract;
		if( this.game.isbridge ) {
			if( contract.declarer % 2===plno % 2 ) return myplace===contract.declarer;
			return plno===myplace;
		}
		return plno===myplace;
	}

	#checkPremove( plno ) {
		if( !this.game.isRealPlayer ) return;
		// if( this.game.solo ) return;
		if( !elephCore.globalAttr.premove ) return;
		this.#premove = null;
		if( ( !this.game.isbridge && !this.game.ispref ) || this.game.gameState!=='cardplay' ) {
			return;
		}
		if( plno===undefined ) {
			let cc = 0;
			this.cardMove.forEach( ( cm, no ) => {
				if( cm.str ) {
					cc++;
					plno = +no;
				}
			} );
			if( cc!==1 ) plno = undefined;
		}

		/*
				for( let i=this.game.maxplayers; i--; ) {
					if( !this.#canHandle( i ) ) continue;
					if( this.cardMove[i]?.str ) continue; // Move from this hand already done
				}
		*/
		if( plno>=0 )
			this.#premove = {
				lead: plno,
				suit: this.cardMove[plno]?.str?.[0]
			};
		this.#checkPremoved();
	}

	#checkPremoved() {
		for( let c of this.panel.$$( '.solid_card[data-premove]' ) )
			if( !this.#canPremove( c ) ) delete c.dataset.premove;
	}

	#canPremove( card ) {
		if( !this.#premove ) return false;
		let ch = card.owner,
			plno = +card.dataset.owner;
		if( plno>=0 && this.#canHandle( plno ) && !this.cardMove[plno].str ) {
			// Check legal card
			if( this.#premove.suit && card.str[0]!==this.#premove.suit ) {
				if( ch.countInSuit[this.#premove.suit] ) return false;
				if( this.game.ispref &&
					( !this.game.contract
					 || ch.countInSuit[this.game.contract.bid[0]] ) ) return false;
			}
			return true;
			// log( `Premove ${card.str} allowed` );
		}
		return false;
	}

	route_move( omove ) {
		if( this.options.noparsemove ) return;
		let spec = omove?.special,
			small = spec?.toLowerCase() || '';
		// ch7button.classList.toggle( 'visible',
		// 	spec && spec.includes( 'change7' ) || false );
		// doezdButton.classList.toggle( 'visible',
		// 	spec && spec.includes( 'doezd' ) || false );
		// redealButton.classList.toggle( 'visible',
		// 	spec && spec.includes( 'redeal' ) || false );
		for( let k in this.specButtons ) {
			this.specButtons[k].makeVisible( small.includes( k ) );
		}
		if( !omove ) {
			// Stop moving
			// parse_off();
			this.stopCardMove();
			return;
		}
// if( omove.type==='domino' ) return;
		import( './dragmaster_old.js' ).then( module => {
			let o = omove['on'];
			this.onParams = o;
			// let onHand = 0; // o['hand'];
			// if( onHand<0 || onHand>=4 ) return;
			// let ON = true;
			this.dragInitMove?.();
			this.game.dragInfo.noGoalClick ??= true;
			this.game.dragInfo.cb = this;
			this.game.dragInfo.canDrop = ( element, holder ) => {
				// TODO: better way is to keep ident for each move
				return !!this.onParams;
			}
			this.game.dragInfo.onDrop = ( element, holder ) => {
				// element.style.zIndex = ++cardMoveIndex*10;
				// element.style.zIndex = element.dataset['z'] =
				// 	element.dataset['z'] + (++cardMoveIndex)*10;
				this.onDrop( element, holder );
			};
			this.game.dragInfo.onSelect = this.onSelect.bind( this );
			this.game.dragInfo.type = 'card';

			if( o['text'] )
				this.doDropButton.setContent( o['text'] );

			if( o.cards ) {
				let moveplno = o.plno,
					allcards = o.cards.split( ',' ),
					automove,
					oneOnlyMove,
					count = 0;
				for( let i = allcards.length; i--; ) {
					let str = allcards[i];
					if( !str ) continue;
					let parts = str.split( ':' ),
						plno = o.plno, cards, whereto;
					if( plno>=0 )
						[cards, whereto] = parts;
					else
						[plno, cards, whereto] = parts;
					let
						moveholders = this.findMoveHolders( whereto ) || [this.cardMove[+plno].holder],
						legalboxes = moveholders || [],
						legalCards = new Set( this.game.cards.getCards( cards ) );
					if( !legalCards.size ) {
						log( `Not found cards ${cards}` );
						continue;
					}
					if( moveplno===undefined ) moveplno = plno;
					// Check automove now
					automove ||= [...legalCards].find( x => x.dataset.premove );
					this.game.makeDraggable( legalCards, legalboxes );
					count += legalCards.size;
					if( count===1 ) oneOnlyMove = [...legalCards][0];
				}
				// Clear all premoves in this hand
				let ch = this.cardHolder[moveplno];
				if( ch ) for( let c of ch.getCards() )
					delete c.dataset.premove;
				if( !count ) {
					// Not found any cards to move. Looks like a bug
					log( `Send forceautomove to server` );
				}
				if( !this.game.solo && !automove && count===1 && oneOnlyMove ) {
					let g = this.game;
					if( ( g.isdomino || g.isbridge || g.ispref ) ) {
						log( `Premove ${oneOnlyMove.str} 1 only` );
						oneOnlyMove.dataset.premove = 'one';
					}
				}

				let params = {
					type: 'card',
					moveZ: this.cardMoveIndex,
					selectonly: o.multi,
					fastmove: !o.multi
				};
				this.doDropButton.makeVisible( !!o.multi );
				this.onSelect();
				this.game.startMove( params );

				this.#makingPremove = false;
				if( automove && !o.multi ) {
					log( `Trying premove ${automove.str}` );
					this.#makingPremove = true;
					let ok = modules.dragMaster.doMove( automove, automove.dropHolders?.[0] );
					this.#makingPremove = false;
					if( ok ) {
						log( `Premove succeed` );
						return;
					}
				}

				this.game.wantsAction( 'move' );
			}
		} );
	}

	onDrop( element ) {
		this.setCardMoveIndex( this.cardMoveIndex + 1 );
		let plno = element.owner?.plno;
		if( plno>=0 ) this.game.players[plno].showArrow( false );
		this.game.sendMove( ( this.#makingPremove? 'premoved ' : '') + element.str );
		let dds = element.dataset.ddstricks;
		if( !this.game.isSolo )
			this.game.dd?.setPrognosis( dds );
		this.#checkPremove( plno );
		if( !this.#makingPremove )
			Cards.#lastMoveTime = Date.now();
	}

	checkDD() {
		// 2 objects for prognosis and calculating spinner
		// Prognosis goes to declarer
		// calculating goes to nextmove
		this.game.dd ||= {
			prognosis: html( `<div class='display_none flexline center hideinpreview' 
				style='color: white; font-family: monospace; padding: 0.05em 0.2em; 
				min-width: 32px; min-height: 32px; box-sizing: border-box; border-radius: 5px; grid-area: prognosis'></div>` ),
			spinner: html( `<div class='display_none spinner w32 centered_all' style='background-size: 100%'></div>`, this.panel ),
			icon: html(
				`<div class='_display_none flexline center grayhover ddicon'
					data-clickexpandto='.playarea^.ddsolution' _data-transformbacktimeout='10'
					style='order:20; z-index: 1; _background: var( --light_white ); max-width: 42px; overflow: hidden; font-size: 20px; 
					align-self: center'></div>`,
				this.game.littleIcons ),
			// this.game.ddSpinner ||= html( `<div class='w32' style='order: 12'></div>`, this.game.littleIcons );
			setPrognosis: cap => {
				let p = this.game.dd.prognosis;
				if( !cap ) return p.hide();
				if( cap==='??' && p.textContent ) return;
				if( cap[0]==='?' ) cap = '';
				p.setContent( cap );
				let clr = cap && (cap==='=' || +cap>0 ? 'green' : 'red') || '';
				p.style.backgroundColor = clr;
				p.setSpinner( !cap );
				let d = this.game.contract?.declarer;
				if( d>=0 ) {
					if( p.parentElement!==this.game.players[d].elbox )
						this.game.players[d].elbox.appendChild( p );
					p.show();

				} else {
					p.hide();
				}
			},
			setSpinner: next => {
				let s = this.game.dd.spinner;
				s.makeVisible( next>=0 );
				/*
								if( next>=0 ) {
									if( s.parentElement!==this.game.players[next].elbox )
										this.game.players[next].elbox.appendChild( s );
									s.show();
								} else {
									s.hide();
								}
				*/
			}
		}
		this.game.checkLittles();
	}

	get bottomHand() {
		return this.game.cardHolder[this.game.getpov];
		// for( let h of this.game.cardHolder )
		// 	if( h.holder.dataset.position==='bottom' ) return h;
	}

	checkPlayerHolders() {
		/*
				this.usePlayerHolder = !narrowPortraitMedia.matches;
				if( this.usePlayerHolder && this.bottomHand ) {
					// Попробуем без PLAYERHOLDER
					// game.playerHolders.bottom ||= construct( '.playerholder.bottom', this.panel );
					// game.playerHolders.bottom.appendChild( game.moveControls );
					this.bottomHand?.holder.appendChild( this.game.moveControls );
				} else {
					this.panel.appendChild( this.game.moveControls );
				}
		*/
		this.panel.appendChild( this.game.moveControls );
	}

	dropSolutions() {
		this.solvedCards?.forEach( c => c.removeAttribute( 'data-solution' ) );
		this.solvedCards = [];
		if( this.game.dd ) {
			this.game.dd.prognosis.setContent( '' );
			this.game.dd.prognosis.hide();
			this.game.dd.spinner.hide();
		}
	}

	lastTrickClick() {
		this.trickLast.classList.toggle( 'clicked' );
		requestAnimationFrame( () => this.lastMove.forEach( x => x.sizeControl() ) );
	}

	dochange7() {
		this.game.sendMove( 'change7' );
	}

	async onresize( entry ) {
		await cssInject( 'cards' );
		let p = /* mainArea || */ this.panel.parentElement;
		if( !p ) return;
		let hh = p.clientHeight / 3.5;	// Четверть высоты на панель
		// let hw = p.clientWidth * 2 / 3;

		// trickBox.style.width = hh + 'px';
		let coeff = 2.6;
		if( this.game.isbridge && this.game.cards.unifiedWidth * 8>p.clientWidth ) {
			coeff = 2;
			hh *= 1.25;
		}
		if( this.game.cards.unifiedWidth<30 ) {
			// Узкий экран, карты будут уменьшаться довольно сильно, для хода будут слишком мелкие
			// пробуем увеличить карты хода
			coeff = 3;
			hh = this.game.cards.unifiedHeight * 3;
		}
		this.trickBox.style.width = this.game.cards.unifiedWidth * coeff + 'px';
		this.trickBox.style.height = hh + 'px';
	}

// Универсальный размер карт определяется игрой и исопльзуемой колодой

	async changeTour() {
		// Надо выбрать турнир
		let mod = await import( './selecttour.js' ),
			tour = await mod.selectTour( this.game );
		if( !tour ) return;
		// Выбрали турнир, который будем играть, отправим запрос на сервер
		this.game.send( 'setconv', 'tourid=' + tour, 'User choice' );
	}

	cardsLayout() {
		let vuln = this.game.getVuln;
		for( let rose of this.game.playArea.$$( '.bridgerose' ) ) {
			let style = rose.$( '.inner' ).style,
				vcolor = vuln.includes( this.game.bypos.bottom ) ? 'red' : 'green',
				hcolor = vuln.includes( this.game.bypos.left ) ? 'red' : 'green';
			style.borderTopColor = style.borderBottomColor = vcolor;
			style.borderLeftColor = style.borderRightColor = hcolor;
			let sides = rose.$( '.sides' );
			if( sides ) {
				for( let p = 0; p<4; p++ ) {
					let no = (p + this.game.getpov) % 4,
						side = sides.children[p],
						isdealer = this.game.dealInfo?.dealer===no;
					side.textContent = this.game.sideNames[no];
					side.style.textDecoration = isdealer ? 'underline' : 'initial';
					side.style.fontWeight = isdealer ? 'bold' : 'initial';
					side.style.fontSize = isdealer ? '125%' : 'unset';
				}
			} else {
				// Icon. Set dashed style for dealer side. No, looks bad
				/*
								let s = '';
								for( let p = 0; p<4; p++ ) {
									let no = (p + 2 + this.game.getpov) % 4;
									s += ' ' + (this.game.dealInfo?.dealer===no ? 'dashed' : 'solid');
								}
								style.borderStyle = s;
				*/
			}
		}

		// if( this.usePlayerHolder )
		// 	this.bottomHand?.holder.appendChild( this.game.moveControls );
	}

	gameInfo( o ) {
		// при изменении игры некоторые объекты могут измениться
		if( this.game.isbridge ) {
			this.game.fullBoardInfo ||= html(
				`<div class='display_none fullboardinfo bridgerose'
					data-onlyonevisible='center' 
					style='border-radius: 10px; z-index: 10000; overflow: hidden;
					width: 10em; height: 10em; grid-area: 1/1;  
					align-self: center; justify-self: center; position: relative'>
					  <div class='column center spacearound inner' 
					   style='width: 100%; height: 100%; box-sizing: border-box;
					   border: 1.5em solid; background: var( --light_white )' >
					  
					   <div class='flexline spacearound' style='align-self: stretch'>
					     <span class='fade'>←</span>
 					     <span class='flexline center dealno' 
					       style='text-align: center; font-size: 3rem'></span>
					     <span class='fade'>→</span>
					   </div>
					   <span class='scoring'></span>
					   
					   </div>
					   <div class='gridone sides' 
					   	style='position: absolute; top: 0; width: 100%; height: 100%; 
					   		font-family: monospace; padding: 0 0.4em; box-sizing: border-box; color: white'>
					   <span class='bottom'>S</span>
					   <span class='left'>W</span>
					   <span class='top'>N</span>
					   <span class='right'>E</span>
					   </div>
					</div>`,
				this.panel );

			this.bridgeRoseIcon ||= html(
				`<div class='display_none bridgerose gridone'
					_data-clickexpandto='.playarea^.fullboardinfo' _data-transformbacktimeout='10'
					style='order:10; z-index: 1; overflow: hidden;  
					position: relative'>
					   <span class='flexline center dealno inner'
					   style='width: 100%; height: 100%; box-sizing: border-box; text-align: center;
					   border: 7px solid'></span>
					</div>`, this.onroseclick.bind( this ),
				this.game.littleIcons );
		}

		if( this.game.isbelot ) {
			this.game.pack.holder.classList.add( 'centered' );
			this.game.pack.options.align = 'c';
			this.game.pack.sizeControl( 'cards-belot' );
		}
		if( o.selectboards ) {
			this.tourInfoLine ||= this.game.playArea.$( '.tourinfoline' );
			this.tourInfoLine.onclick = this.changeTour.bind( this );
			this.tourInfoLine.makeVisible( !this.game.inprogress );

			this.tourInfoLine.setContent( o.tour?.name || '{Randomboards}' );

			if( this.game.isFounder && !o.tour ) {
				this.changeTour();
			}
		}
		if( this.game.isbridge ) {
			// Покажем "на макс" или "на импы"
			// Вместе с информацией о турнире
			// if( o.undertitle==='IMP' )
		}
	}

	onroseclick( e ) {
		if( this.game.vgame.solo?.onroseclick?.( e ) ) return;
		e.target.$up( '.playarea^.ddsolution' )?.toggleVisible();
	}

	trickFormat( holders, str ) {
		let parts = str.split( ',' ),
			index = 0,
			firstplno,
			holderSet = new Set( holders );
		for( let p of parts ) {
			let pos = 0;
			if( p[0]==='*' ) {
				pos++;
			}
			let plno = +p[pos];
			if( plno>=0 ) pos++;
			else plno = 4;
			firstplno ??= plno;
			let holder = holders[plno];
			if( holder ) {
				holder.baseZ = index;
				holder.setStr( p.slice( pos ), false, index );
				holderSet.delete( holder );
				index++;
			}
		}
		// Clear not assigned
		for( let h of holderSet ) h.clear();
		return { index: index, first: firstplno };
	}

	setCardMoveIndex( value ) {
		if( this.cardMoveIndex===value ) return;
		this.cardMoveIndex = value;
		// log( 'cardMoveIndex=' + cardMoveIndex );
	}

	findMoveHolders( code ) {
		if( !code ) return;
		let ar = code.split( '+' );
		let res = ar.map( x => this.findHolder( x, 'move' ) ).filter( x => !!x ).map( x => x.holder || x );
		if( res.length ) return res;
	}

	findHolder( code ) {
		this.#findHolderPos = undefined;
		if( code[0]==='-' ) code = code.slice( 1 );
		if( code==='c' ) return this.trickBox;
		// if( code=='l' ) return this.trickLast;
		if( +code>=0 ) return this.cardHolder[+code];
		if( code==='B' ) return this.game.battle;

		if( code[0]==='B' && code[1]>='0' && code[1]<='9' ) {
			this.#findHolderPos = parseInt( code.slice( 2 ) );
			return game.battle.lines[+code[1]];
		}
		if( code.startsWith( 'battle' ) ) {
			this.#findHolderPos = parseInt( code.slice( 7 ) );
			return this.game.battle.lines[+code[6]];
		}

		if( code==='trash' ) code = 'pack';
		return this.game[code];
	}

	handParser( str, key, secret ) {
		// if( secret && +secret!==this.game.myPlace ) return;
		if( secret==='kibi' && !this.game.watchKibi ) {
			this.game.checkKibiAll( true );
		}
		let plno = +key;
		if( isNaN( plno ) ) {
			plno = -1;
			if( key[0]>='0' && key[0]<='9' ) {
				plno = key[0] - '0';
				key = key.slice( 2 );
			}
		} else {
			key = '';
		}
		if( plno<0 ) return;
		let hand = this.cardHolder[plno];
		if( !hand ) return; // Gate sends excess line
		let conditional = false, wasOpened = hand.isOpened();
		if( str[0]==='?' ) {
			conditional = true;
			str = str.slice( 1 );
		}
		let mySecret = secret && secret!=='s';
		if( mySecret ) {
			if( this.game.getMyPlace()!==plno )
				log( `Fix see hand ${plno} with secret ${secret}, myplace ${this.game.getMyPlace()}` )
			if( str ) {
				// Это просто блокировка лишних перерисовок, если видим чужую руку
				// однако для подавления ошибок лучше не сохранять "видимость" руки, если
				// реально данные про неё не приходили
				this.#dealSeeHand.add( plno );
			}
		} else {
			this.openHand[plno] = { cards: str, cond: conditional };
			if( conditional && ( this.#dealSeeHand?.has( plno ) || this.game.getMyPlace()===plno ) ) {
				if( this.game.getMyPlace()!==plno && this.#dealSeeHand?.has( plno ) )
					log( `Seen hand ${plno} in this deal`)
				return;
			}
		}
		let op = this.openHand[plno];
		// For secret field empty value is "no own value, use common"
		if( mySecret && op && (!str || !op.cond) ) str = op.cards;
		hand.setStr( str );
		if( !wasOpened && hand.isOpened( 10 ) && hand.position==='top' ) {
			// Открылись верхние карты, не надо ли убрать littleInfo
			if( !mediaSuperWide.matches ) this.game.hideLittle();
		}
		this.checkOpenedHands();
		if( this.#showClosedCountPlayer ) {
			this.game.players[plno]?.setClosedCount( hand.countClosed );
		}
		if( this.#launched ) {
			this.checkPlayerHolder( plno );
		} else {
			if( str ) {
				this.#launched = true;
				this.game.playArea.dataset.launched = true;
				for( let i = this.game.maxPlayers; i--; ) {
					this.checkPlayerHolder( i );
				}
			}
		}
	}

	checkPlayerHolder( plno ) {
		// if game launched and cards of player are visible then parent is cardHolder
		let ch = this.cardHolder[plno],
			plr = this.game.players[plno],
			cardVis = this.#launched && !ch.isInvisible,
			parent = cardVis? ch?.holder : this.panel;
		if( !parent ) return;
		if( cardVis ) {
			ch.holder.appendChild( plr.elbox );
			ch.holder.appendChild( plr.elarrow );
			ch.holder.appendChild( plr.elbid );
		} else {
			this.panel.appendChild( plr.elbox );
			plr.elbox.appendChild( plr.elarrow );
			plr.elbox.appendChild( plr.elbid );
		}
	}

	get #showClosedCountPlayer() {
		return this.game.isdomino;
	}

	checkOpenedHands() {
		let opened = 0;
		for( let i = 4; i--; )
			if( this.cardHolder[i]?.isOpened() ) opened++;
		let tightly = opened>2 && (this.game.isbridge || this.game.ispref );
		this.game.setTightly( tightly );
	}

	getCards( str, cardholder ) {
		if( str!=='*' )
			return this.game.cards.getCards( str );
		return cardholder.getCards();
	}

	onSelect( set ) {
		let allow = set && this.onParams.multi===set.size;
		if( allow )
			this.doDropButton.removeAttribute( 'disabled' );
		else
			this.doDropButton.setAttribute( 'disabled', '' );
	}

	stopCardMove() {
		this.game.initMove();
		this.onParams = null;
		this.doDropButton.hide();
		this.#clearPremoves( true );
		this.onSelect();
	}

	dropClick() {
		if( !this.game.dragInfo.selected || this.game.dragInfo.selected.size!==this.onParams.multi ) return;		// Incorrect
		let s = '';
		for( let el of this.game.dragInfo.selected ) s += el.str + ',';
		this.stopCardMove();
		this.game.sendMove( s );
		// TODO: обработать ответ сервера. Если ход не принят, необходимо
		// откатить CARDMOVE, либо, предпочтительнее, восстановить руки
		// еще вариант - на сервере отправлять игроку команду "Освежись",
		// а клиент по ней обрабатывает еще раз все сохраненные SET игры
	}

// function observer( entries, observer ) {
// 	log( 'Observing' );
// }
}

export default Cards;