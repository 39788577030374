export default function createBoardPlayers( game, parent ) {
	if( !game.boardPlayers ) game.boardPlayers = [];
	for( let i = game.maxPlayers; i--; ) {
		// p.timer.setOptions( { visibility: 'always' } );	// Always show timer
		game.boardPlayers[i] ||= {
			holder: construct('.boardplayer', parent )
		};
		let bp = game.boardPlayers[i],
			holder = bp.holder,
			p = game.getPlayer( i );
		p.timer.setOptions( { visibility: 'always' } );	// Always show timer
		holder.appendChild( p.elements.avatar );
		holder.appendChild( p.eltimer );
		holder.appendChild( p.elements.matchscore );
		holder.appendChild( p.elements.sitin );
		bp.nblock ||= construct( '.column.rem.nick2lines', bp.holder );
		bp.nblock.appendChild( p.elnick );
		bp.sub ||= construct( '.flexline.start.nowrap', bp.nblock );
		bp.sub.appendChild( p.elregion );
		bp.sub.appendChild( p.elements.colorbox );
		bp.sub.appendChild( p.elements.rating );
		p.elements.colorbox.show();
// sub.appendChild( p.elcount );
// Avatar children
		p.elavatar.appendChild( p.elbubble );
	}
	game.checkboardplayerspos();
}