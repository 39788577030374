cssInject( 'game' );

import Watcher from './watcher.js';
import Player from './player.js';
import Lang from './lang.js';
import Subscribe from "./subscribe.js";
import Timer from "./timer.js";

// preloadSound( 'move' );
window.myCounter ||= 1;

let gameResizeObserver = window.ResizeObserver && new ResizeObserver( entries => {
	for( let entry of entries )
		entry.target.classGame?.resizeObserve( entry );
} );

window.mediaWideChat = window.matchMedia( '(min-aspect-ratio: 15/10) and (min-height: 500px)' );

let total = 0,
	posclasses = [[], ['bottom'], ['bottom', 'top'], ['bottom', 'left', 'right', 'top'],
		['bottom', 'left', 'top', 'right']],
	arrowCode = { bottom: '↓', left: '←', top: '↑', right: '→' },
	mediaNarrow = window.matchMedia( '(max-aspect-ratio: 6/10)' ),
	mediaBottomChat = window.matchMedia( '(max-aspect-ratio: 6/10) && (min-height: 1000px)' ),
	crawblinker = 0;

function appendRoute( routes, key, func, prime ) {
	if( !routes[key] )
		routes[key] = func;
	else {
		let ar = Array.isArray( routes[key] ) ? routes[key] : (routes[key] = [routes[key]]);
		prime ? ar.unshift( func ) : ar.push( func );
	}
}

function getAllProperties( obj ) {
	if( !obj ) return []; // recursive approach
	return [ ...Object.getOwnPropertyNames( obj ), ...getAllProperties( Object.getPrototypeOf( obj ) ) ];
}

export default class Game {
	#resizeCount = 0;
	#resizeCallCount = 0;
	#reger;
	#tableName = null;
	#attrs = {};
	#routes = new Set;
	#needlayfor = new Set;
	#myplace;
	#inviteWindow;
	#elContract;
	#elTrump;
	#waitResize;
	#playMore;
	#wantClose = false;
	#wantLittle;
	#needlay = [];
	#toLay = new Set;
	#objectLayout = new Map;
	#topPanelFragment = document.createDocumentFragment();
	#onresizeBind = this.#onresize.bind( this );
	#winResizeBind = this.#winResize.bind( this );
	// #players = [];
	#arrowsymbol = [];
	#arrows = '';
	#matchScore;
	#resizeListeners = [];
	#maximized;
	// #bypos;
	#littleBox;
	#lastLittle = null;
	#littles = new Map;
	#playMoreHolder;
	#dialog;
	#inquiry;
	#bigstate;
	#elphase; #elbigstate;
	#playZoneSize;
	#checkSizeCount;

	constructor( vgame ) {
		this.vgame = vgame;
		this._route = {};
		window.myCounter++;
		this.AAA;
		this.item = vgame.id;
		this.positions = [];
		this.dealInfo = {};
		this.gameInfo = {};
		this.score = [];
		this.watcher = new Watcher;
		this.ext = {}, 						// External modules (require;
		this.onlayout = new Set;
		this.afterLayout = new Set;
		this.moveElements = [];
		this.playerHolders = {};
		this.chatMessages = [];
		this.elements = {};
		this.players = [];
		this.sitinClickBind = this.sitinClick.bind( this );

		let backbutton = !this.isSolo && !vgame?.solo;
		this.playArea = html( `<div class='playarea'>
				<div class='hidewhenminifyed table_statusbar display_none visible' style='order: 1'>
					<div class='table_gameico'></div>
					<div class='table_bet bet'></div>
					<div class='display_none little_box hidewhenminifyed gridone phone_fullwide'></div>
					<div class='tableinfoline' style='_padding: 0.2em;'>
					  <span class='_tablename'></span>
					  <span class='hideempty score' style='float: right'></span>
					</div>
				</div>
				<div class='commandzone column'>
			    <div class='display_none visible tabletitle hideinpreview blackonwhite flexline nowrap' style='align-self: stretch'>
			    	<div class='w48 game_navigation grayhover ${backbutton ? "" : "display_none"} invertdark' title='{Leavetable}'
			    		style='background-image: url( ${IMGPATH}/svg/icons/ic_arrow_back_black_24px.svg )' data-action='goback'></div>
					<div class='track_disconnected' style='flex-grow: 2; padding-left: 2em'>{Connecting}...</div>
			    	<span class='tablename hide_disconnected' 
			    		style='${backbutton ? "" : "padding-left: 0.2em; "}flex-grow: 2; overflow: hidden; text-overflow: ellipsis; font-size: 1rem; oldsize: min(1rem,5vmin)'></span>
			    	<div class='w48 grayhover invertdark game_navigation' data-action='menu'
			    		style='background-image: url( ${IMGPATH}/svg/icons/menu_black_24dp.svg )'></div>
			    </div>
				<div class='playzone'>
				  <div class='fade hidewhenminifyed ingame inquiry column center importantsize'>
				  	<div class='caption hideempty goodbad'></div>
				  	<div class='buttons flexline center'></div>
				  </div>
				  <div class='fade table_phase'></div>
				  <div class='centeredbuttons'>
					<div class='fade bigstate goodbad hideempty smallinportrait' style='position: relative'></div>
					<div class='display_none tourinfoline sheet'></div>
					<div class='display_none gamebrief hideempty lightborder column center rem'
						style='max-width: min( 20rem, 70% )'>
					  <span class='hideempty title' style='font-weight: bold; padding: 0.5em 1em;'></span>	
					  <span class='conventions hideempty column display_none visible' 
					  	style='padding: 0.5em 1em;'></span>
					  <button class='display_none default importantsize' name='invite'>{Invite}</button>
					  <button class='display_none default importantsize' name='playmore' data-action='playmore'>{Playagain}</button>
					  <button class='display_none default importantsize' data-action='say' data-saycode='TFG' name='say'>{Thanks}</button>
					</div>
				  </div>
				  <button class='display_none mybutton iamback'>👋 {Backtogame}</button>
				</div>
				<div class='display_none gamebuttons flexline center nowrap'>
					<span class='gamename' style='order:10'></span>
					<button class='giveup emoji fade rem2'>🏳️</button>
				</div>
				<div class='hideforplayers grossinfo display_none flexline spacebetween' style='align-self: stretch'>
				   <div class='flexline history'>{Nogameplayed}</div>
				   <div>
				     <span class='queue' data-origin='game_${this.AAA}_queueinfo'></span>
				     <button class='display_none' data-name='wantplay' data-origin='game_${this.AAA}_wantplay'>{Iwanttochallenge}</button>
				   </div>
			    </div>
				<div class='controlzone flexline center display_none visible'>
					<div class='tvinfo display_none flexline center'><span class='emoji rem2'>🎥</span>&nbsp;<span class='subtitle'></span></div>
					<div class='adjustpanel display_none flexline whitewide'><span></span>
						<input name='adjustment' size='6' style='font-size: 1.2rem'><button data-action='doadjust'>Ok</button><button data-action='cancel'>{Cancel}</button></div>
					<span class='display_none tdpanel'>
						<button class='display_none control hidereplay' data-action='skipboard'>Skip Board</button>
						<button class='display_none control' data-action='adjust'>Adjust</button>
					</span>
				</div>
				</div>
				</div>
				`, this.#clickPlayArea.bind( this ) );

		this.holder = this.playArea; // For swiper compatibility
		this.scoreLine = this.playArea.$( '.tableinfoline .score' );
		this.#littleBox = this.playArea.$( '.little_box' );
		this.centeredButtons = this.playArea.$( '.centeredbuttons' );

		this.statusBar = this.playArea.$( '.table_statusbar' );
		this.littleIcons = construct( '.display_none.visible.iconset.little_icons', this.statusBar );
		this.showingControls = true;

		// elTableName.onclick = rentClick;
		for( let o of this.playArea.$$( '.queue' ) ) o.onclick = this.#rentClick.bind( this );

		this.naviIcons = createappend( 'iconset navi_icons', this.statusBar );
		// if( !window.SOLO ) this.leaveButton = construct( '.grayhover.game_navigation.fade.closebutton.visible.invertdark @{Leavetable}', lobbyClick, this.naviIcons );
		// scoreLine;
		// this.settingsButton = construct( '.grayhover.invertdark.game_navigation.settingsbutton @{Settings}', () => window.showSettings?.( this ), this.naviIcons );
		this.navi = {
			rotate: construct( '.grayhover.invertdark.flexline.center.display_none.rotate ⇅', this.naviIcons, this.#rotateClick.bind( this ) ),
		};

		this.inButton = this.playArea.$( '.iamback' );
		this.inButton.onclick = this.iamhere.bind( this );

		this.moveControls = construct( '.movecontrols' );
		// let dialog = construct( '.fade.ingame', this.moveControls );
		this.#dialog = html( `<div class='fade ingame column center importantsize'>
		<span class='caption hideempty goodbad'></span>
		<div class='buttons flexline center'></div>
		</div>`, this.moveControls );
		this.#dialog.buttons = {};

		this.holderConventions = this.playArea.$( '.conventions' );
		this.holderConventions.onclick = this.convClick.bind( this );

		this.commandZone = this.playArea.$( '.commandzone' );
		this.controlZone = this.playArea.$( '.controlzone' );
		this.gameButtonsBar = this.playArea.$( '.gamebuttons' );
		this.giveupButton = this.gameButtonsBar.$( '.giveup' );
		this.giveupButton.onclick = this.giveupClick.bind( this );
		this.tvInfo = this.controlZone.$( '.tvinfo' );

		this.adjustPanel = this.playArea.$( '.adjustpanel' );
		this.tdPanel = this.playArea.$( '.tdpanel' );
		this.controlZone.onclick = this.opClick.bind( this );

		// Информация об игре на столе
		this.#elphase = this.playArea.$( '.table_phase' );
		this.#elbigstate = this.playArea.$( '.bigstate' );
		// elTableBet = this.playArea.getElementsByClassName( 'table_bet' )[0];

		this.playZone = this.playArea.$( '.playzone' );
		this.gameType = null;
		this.playArea.classGame = this;
		this.playZone.classGame = this;

		this.buttons = {
			undo: html( `<button class='hideinpreview graybutton icon w32 icon-undo undo display_none hidewhenpreview'
			style='z-index: 10000; margin: 0; padding: 0;'></button>`,
				this.playZone, this.undo.bind( this ) ),
			say: construct( 'button.needsay.claimposition.mybutton.display_none.hidewhenpreview',
				this.playZone, this.#needsay.bind( this ) )
		};

		this.buttons.undo.oncontextmenu = () => {
			if( this.isSolo ) this.#send( 'undo_start' );
		}

		elephCore?.track( this.playArea, 'deck' );

		this.#inquiry = this.playZone.querySelector( '.inquiry' );
		this.#inquiry.buttons = [];
		this.#inquiry.caption = this.#inquiry.firstChild;

		this.cube = {
			button: construct( '.flexline.center.pointer.display_none.cube.hideempty',
				this.playZone, this.#cubeClick.bind( this ) )
		};

		this.#playMoreHolder = construct( '.flexline.fade.mybutton.playmore',
			this.centeredButtons, this.#playMoreClick.bind( this ) );
		construct( '.hidewhenregistered {Continue}', this.#playMoreHolder );
		construct( '.showwhenregistered {Searching}...', this.#playMoreHolder );
		construct( '.showwhenregistered.spinner', this.#playMoreHolder );

		// let replayButton = construct( '.fade.mybutton.icon-replay {Replay}', centeredButtons );

		this.#matchScore = createtree( 'matchscore', 'score0, score1' );
		this.#matchScore.holder.classList.add( 'fade' );
		// this.playArea.appendChild( matchScore.holder );

		gameResizeObserver?.observe( this.playZone );

		let mytimer = construct( '.timer.play_timer.running.mygametimer' );

		mytimer.hidden = true;
		// this.picHolder.appendChild( mytimer );
		this.myGameTimer = mytimer;

		// -- Drag-drop moves block --
		this.dragInfo = {
			game: this
		};
		
		window.mediaSuperWide.addListener( this.checkWide.bind( this ) );

		for( let i = 0; i<10; i++ ) {
			this.addParser( 'bubble' + i, str => this.players[i]?.setObject( 'bubble', str ) );
			this.addParser( 'plrstate' + i, str => {
				if( str[0]==='!' ) str = str.slice( 1 );
				let plr = this.players[i];
				if( !plr ) return;
				plr.setObject( 'state', str );
				plr.elstate.classList.toggle( 'contract', this.contract?.declarer===i && this.contract?.bid.toLowerCase()===str );
			} );
		}

		if( LOCALTEST )
			window.GLP = () => this.#needsay( 'GLP' );

		dispatch( 'regerschanged', this.#checkPlayMoreReg.bind( this ) );

		dispatch( 'onresume', () => {
			// Checking card boxes on resume
			this.cardHolder?.forEach( x => x.sizeControl() );
			// if( this.cardHolder ) for( let h of this.cardHolder ) h.sizeControl();
		} );

		dispatch( 'loggedin', () => {
			// Check if we need realign
			this.#recheckMyplace();
			this.#checkPlayers();
		} );

		this.#extendOnRequest( 'pool' );
		this.#extendOnRequest( 'claim' );
		this.#extendOnRequest( 'bidbox' );
		// Малый auction вообще не нужен, должен открываться в центре по запросу
		if( !vgame.solo )
			this.#extendOnRequest( 'auction', { type: 'little' } );
		this.#extendOnRequest( 'auction', { type: 'center', nogame: 'belot4' } );
		this.#extendOnRequest( 'lastdeal', { module: 'handrecord', nogame: 'poker' } );

		this.playArea.onRelocate = () => {
			if( !this.isPlayer && !this.isSolo )
				this.#makeObsolete();
		}

		addEventListener( 'winresize', this.#winResizeBind );

		this.playArea.$( '[name="invite"]' ).onclick = () => this.invite();

		this.playArea.onSwiperChange = ( v, swiper ) => {
			this.#maximized = v;
			if( v ) {
				this.checkLocation();
				// Core.setLocation( this.getLocation(), this.gameInfo?.title );
				this.chat?.onMaximize();
				elephCore?.setBack( this.#lobbyClick.bind( this ) );
				sessionStorage.view = this.module + ':' + this.item;
				localStorage['lastitem'] = this.item;
				swiper.currentGame = this;
			} else {
				swiper.currentGame = null;
			}
		};

		this.playArea.addEventListener( 'dragend', () => {
			this.dragged = null;
		} );

		this.playArea.addEventListener( 'drop', e => {
			let toplace = e.target.closest( '[data-plno]' )?.dataset.plno,
				fromplace = this.dragged?.place;
			if( toplace>=0 && fromplace>=0 ) {
				this.#send( 'dragplayer', `placefrom=${fromplace} placeto=${toplace}` );
			}
		} );

		window.addEventListener( 'keydown', ( e ) => {
				if( !this.#maximized ) return;

				if( e.key==='Escape' ) {
					// If it is not superwide mode hide little windows
					if( this.#lastLittle && !this.wideBarMode ) { // TODO: проверить, находимся ли мы в режиме "superwide" ( тогда не закрывать )
						this.hideLittle();		// Hide current opened little
						e.stopPropagation();
						return false;
					}
				}

				if( document.activeElement===document.body && !this.isPlayer && e.key==='Enter' ) {
					if( !this.chat || this.chat.isEmpty ) {
						// Можно ли сесть на свободное место
						if( this.sitAny() ) return;
					}
				}

				// AUTO FOCUS CHAT
				if( this.chat && !getTopBigWindow() ) {
					if( e.key?.length>1 ) return; // || e.ctrlKey && e.altKey && e.metaKey ) return;		// Special keys
					let focused = this.chat.hasFocus();
					// Check for Bug Reporting
					if( document.activeElement && !focused ) {
						let tag = document.activeElement.tagName;
						if( tag==="TEXTAREA" || tag==="INPUT" ) return;
					}
					// Если есть хоть один символ не делаем. Возможно, препятствуем ошибке
					// https://www.fantgames.com/petition/details.php?pid=391427
					let visible = this.chat.isVisible();
					if( !this.chat.isEmpty && visible ) return;

					if( !this.keyCaptured && (!visible || !focused) ) {
						if( e.code && !e.ctrlKey && !e.altKey && !e.metaKey && !e.target.getAttribute( 'contenteditable' ) ) {
							// Jump to chat!
							this.showLittle( 'chat' );
							this.chat.focus( e );
							this.chat.initiatedWithKey?.( e.key );
							// if( /\s/.test( e.key ) ) {
							e.stopPropagation();
							e.preventDefault();
							return false;
							// }
						}
					}
				}
			}
		);

		mediaNarrow.addListener( () => {
			// Если фокус на вводе чата (появилась экранная клавиатура),
			log( `Mediaportrait listener ${this.AAA}: matches=${mediaNarrow.matches}, chatfocused=${window.chatInputFocused ? 'yes' : 'no'}` );
			if( window.chatInputFocused ) return;
			this.#checkBottomChat();
			this.needCheckObjects();
			// this.checkLittles();
		} );

		this.chatParent = this.statusBar;

		if( !window.NOCHAT && !vgame.solo ) {
			let mod = import( './chat.js' )
				.then( mod => {
					this.chat = new mod.Chat( {
						id: this.item,
						notitle: true,
						classname: 'table_chat',
						game: this,
						readOnly: this.vgame.isReplay
					} );
					if( this.chatMessages.length ) {
						log( 'Resending chat messages' );
						for( let m of this.chatMessages )
							this.chat.parse_message( m, true );
						this.chatMessages = [];
					}
					this.#checkBottomChat();
				} ).catch( e => {
					log( 'Catched ' + JSON.stringify( e ) );
				} );
		}

		this.instanceNo = ++total;
		this.addRoute( this );

		// this.addMoveElement( dialog );
		this.addMoveElement( this.#inquiry );

		fire( 'gamecreated', this );
	}

	arrowSymbol( plno ) {
		return this.#arrowsymbol[plno];
	}

	get isSolo() {
		return window.SOLO || !!this.gameInfo?.solo || !!this.vgame?.solo || !!this.vgame?.options.replay;
	}

	#clickPlayArea( e ) {
		if( e.target.dataset.action==='playmore' ) {
			this.#playMoreClick();
			return;
		}

		if( e.target.dataset.action==='say' )
			return this.#needsay( e.target.dataset.saycode );

		if( e.target.dataset.action==='goback' )
			return this.#lobbyClick();

		if( e.target.dataset.action==='menu' ) {
			let str = '';
			str += this.solo?.fillMenu() || '';
			str += `
					<span class='icontext invertdark' data-action='showsettings'
					  style='background-image: url( ${IMGPATH}/svg/icons/ic_settings_black_24px.svg)'>{Settings}
					</span>`;
			import( './dropdown.js' ).then( mod => {
				mod.dropDown( str, e, {
					target: e.target,
					origin: this.item
				} )
			} );
			return;
		}

		// Check all objects until playArea. isTrusted==false if this event fired by cards.js (click on card)
		if( this.isbridge && e.isTrusted && Date.now()>(+window.lastClickHandledTime || 0) + 500 ) {
			let t = e.target;
			for( ; t && t!==this.playArea; t = t.parentElement )
				if( t.dataset.action || t.onclick
					|| t.classList.contains( 'solid_card' ) || t.classList.contains( 'window' )
					|| t.classList.contains( 'cardholder_hand' ) || t.classList.contains( 'table_statusbar' ) )
					break;
			if( t===this.playArea ) {
				// if( e.target===this.playArea || e.target===this.topPanel ) {
				// Emptyclick. Click on empty area. For small screens hide/show
				// avatars, names, controlZone
				/*
								if( window.narrowMedia.matches ) {
									if( !this.solo?.isonemove )
										this.toggleControls();
								}
				*/
			}
		}
	}
	

// <div class='table_getready getready_object'>Get Ready!</div>
// 	<span className='queue' data-origin='game_${this.AAA}_queueinfo'></span>

/*
	this.toggleControls = value => {
		if( value===undefined ) value = !this.showingControls;
		this.showingControls = value;
		this.setNoNames( !value );
		this.controlZone.makeVisible( value && this.controlZone.$( ':scope>.visible' ) || false );
		this.statusBar.makeVisible( value );
		this.commandZone.$( '.tabletitle' ).makeVisible( value );
	}
*/
	//		<span style='color:#777'>Table chat</span></div>

		// bugButton = this.playArea.querySelector( '.bugreporticon' ),

	initMove() {
		modules.dragMaster?.clear( this.dragInfo );
	}
	
	makeDraggable( c, holders ) {
		modules.dragMaster.makeDraggable( this.dragInfo, c, holders );
	}
	startMove( params ) {
		modules.dragMaster.startMove( this.dragInfo, params );
	}

	externalAction( action, e ) {
		this.solo?.externalAction( action, e );

	}

	checkWide() {
		if( !this.useWideBar ) return;
		this.wideBarMode = mediaSuperWide.matches && !this.vgame.options.bigwindow;
		log( 'Superwide 15/10 media ' + (this.wideBarMode ? 'MATCHES' : 'NO') );
		if( this.chatAlways && (!this.#lastLittle || this.#lastLittle.name==='chat') )
			this.showLittle( this.littleRecommend || 'auction' );
		else if( this.wideBarMode && (!this.#lastLittle || this.#lastLittle.name==='chat') )
			this.showLittle( this.littleRecommend || 'any' );
		this.checkLittles();
		// statusBar.classList.toggle( 'wide', this.wideBarMode );
	}

	initialize( vgame ) {
		if( this.vgame===vgame ) return;
		this.vgame = vgame;
		this.item = vgame.id;
		this.id = this.item.split( '_' )[1];
		vgame.addParser( this.parse );
		for( let route of this.#routes ) {
			vgame.addParser( route );
		}
		this.#wantClose = false;
		this.reuse?.();
		this.#matchScore.holder.title = '';
		this.invited?.clear();

		this.chat?.setId( this.item );
		if( !this.#wantLittle ) this.#wantLittle = sessionStorage[this.item + '_little'];
		this.#checkWantLittle();

		// let parent = document.querySelector( '[data-playelephant-land]' );
		// if( !parent ) parent = document.body;
		// Core.swiper.addPage( this.playArea );
		// requestAnimationFrame( winResize );

		// Сообщим серверу, что мы открыли этот стол
		if( !this.obsolete )
			elephCore?.do( 'type=come in=' + this.item );

		this.playArea.splayArea = this.item;
		this.playArea.splayArea = this;
		this.playArea.dataset.item = this.item;

		this.inviteOffered = false;
	}

	setTopPanel( panel ) {
		if( this.topPanel===panel ) return;
		panel.classList.add( 'toppanel' );
		this.topPanel = panel;
		if( this.#topPanelFragment )
			this.topPanel.appendChild( this.#topPanelFragment );
		this.#topPanelFragment = null;
		delay( onresize );
	};

	#checkWantLittle( wants ) {
		if( wants!==undefined ) this.#wantLittle = wants;
		log( 'Want little: ' + this.#wantLittle );
		if( this.#wantLittle && this.#littles.has( this.#wantLittle ) )
			this.showLittle( this.#wantLittle );
		else if( this.littleRecommend && this.chatAlways )
			this.showLittle( this.littleRecommend );
	}

	#rentClick( e ) {
		// Необходимо выдать информацию об аренде
		import( './fants.js' ).then( mod => {
			mod.rentClick( this );
		} );
	}

	async undo( e ) {
		if( !this.inprogress ) return this.buttons.undo.hide();
		if( this.isSolo || await askConfirm( '{Undo}?' ) )
			this.send( 'undo' );
	}

	#needsay( e ) {
		let code = e.target?.dataset.code || e.toString();
		if( !code ) return;
		this.buttons.say.hide();
		this.mySay = null;
		this.send( 'say', code );
		// Confetti if possible
		this.needCheckObjects();
		if( code==='GLP' ) {
			window.makeCool?.( {
				code: code,
				game: this,
				plno: this.#myplace,
				dealno: this.dealInfo?.number
			} );
		}
	}

	checkPlayer( i ) {
		this.players[i] ||= new Player( this, i );
		return this.players[i];
	}

	setMaxPlayers( mp ) {
		if( mp===this.maxPlayers || mp===undefined ) return;
		if( this.maxPlayers ) {
			this.playArea.classList.remove( "players_" + this.maxPlayers );
			for( let p = mp; p<this.maxPlayers; p++ ) {
				this.players[p].hide();
				this.cardHolder?.[p]?.clear();
			}

		}
		this.maxPlayers = mp;
		if( this.maxPlayers )
			this.playArea.classList.add( "players_" + this.maxPlayers );
		this.sideNames = ['', 'S', 'NS', 'SWE', 'NESW'][this.maxPlayers] || '0123456789';
		for( let i = 0; i<this.maxPlayers; i++ ) {
			this.checkPlayer( i ).sidename = this.sideNames[i];
		}
	}

	getDefaultAvatarName( plno ) {
		// let sidenames = [ '', 'S', 'SN', 'SWE', 'SWNE' ][this.maxPlayers] || '0123456789';
		return this.players[plno]?.sidename || '';
	};
	
	iamhere() {
		this.inButton.removeAttribute( 'action' );
		this.send( 'iamback' )
	}

	async giveupClick() {
		let can = this.canGiveup;
		if( !can ) return;
		let add = can==='koks' || can==='mars' ? ' ({' + can + '})' : '',
			str = '{Resign}' + add;
		if( await askConfirm( str + '?' ) )
			this.doGiveup( str );
	}

	doGiveup( str ) {
		Subscribe.delayRoute( this.#routes, 'gaveup' );
		this.send( 'resign' );
		toast( str, {
			chat: true,
			duration: 'short',
			bottom: true
		} );
	}

	async convClick( e ) {
		// Можем ли поменять конвенции
		if( e.target.dataset.convname==='rent' )
			return this.#rentClick( e );
		if( this.inprogress || !this.gameInfo.founderconv || !this.isFounder ) return;
		let res = await send( 'convchange' );
		log( 'Received conv ' + JSON.stringify( res ) );
	}

	async opClick( e ) {
		let t = e.target, action = t.dataset.action || t.name;
		if( !action ) return;
		log( 'OPERATE: ' + action );
		switch( action ) {
			case 'kibiall':
				await elephCore?.checkAuth( 'complete' );
				this.send( 'kibi', null, 'kibi' );
				return;

			case 'backskip':
				this.send( 'operator', 'backskip' );
				break;
			case 'backmove':
				this.send( 'operator', 'backmove' );
				break;
			case 'skipboard':
				if( await askConfirm( '{Skipdeal} ' + (this.dealInfo && (this.dealInfo.tournumber || this.dealInfo.number || '')) ) )
					this.send( 'operator', 'skipboard' );
				break;
			case 'adjust':
				if( !this.dealInfo ) return;
				let dealno = this.dealInfo.tournumber || this.dealInfo.number;
				this.adjustPanel.firstElementChild.setContent( '{Adjustment} {brboard} ' + dealno + ' ' );
				// Подскажем присуждение
				let input = this.adjustPanel.$( 'input' ), def = '';
				if( this.contract?.bid ) {
					let s = this.contract.bid[0];
					input.value = def = this.contract.bid[1] + (emoSuits[s] || s)
						+ (this.contract.redoubled && 'xx' || this.contract.doubled && 'x' || '')
						+ 'NESW'[this.contract.declarer];
				}
				this.adjustPanel.show();
				input.focus();
				log( `Start adjust board ${dealno}, def: ${def}` );
				break;
			case 'doadjust':
				doAdjust();
				break;
			case 'cancel':
				if( t.parentElement.classList.contains( 'whitewide' ) )
					t.parentElement.hide();
				break;
			// case 'playpause':
			// 	this.#send( 'operator', 'playpause' );
			// 	break;
		}
	}

	async doAdjust() {
		let adj = this.adjustPanel.$( 'input' ).value;
		if( !adj ) return;
		// adj = adj.replace( /\p{Emoji}/ug, suitFromEmo ) ;
		let protuuid = this.dealInfo.protuuid || '',
			onlycheck = true, // !protuuid
			json = {
				tourid: this.getTourid,
				board: this.dealInfo.tournumber || this.dealInfo.number,
				adj: adj,
				check: true
			};
		if( protuuid ) json.protuuid = protuuid;
		let res = await API( '/adjust', json );
		if( !res && LOCALTEST ) res = { ok: true };
		if( !res || res.error ) {
			toast( 'Adjustment failed: ' + (res && res.error || 'internal') );
		} else {
			this.adjustPanel.hide();
			if( onlycheck ) {
				// Прошли проверку на присуждение, теперь завершаем сдачу
				this.send( 'adjust', `board=${json.board} adj="${adj}" protuuid="${protuuid}"` );
			}
		}
	}

	isonehandonly() {
		if( !this.cardHolder ) return false;
		let countopened = 0, countclosed = 0;
		for( let hand of this.cardHolder ) {
			if( !hand.str ) continue;
			if( +hand.str ) countclosed++; else countopened++;
		}
		return countopened<=1 && (countclosed===0 || this.playArea.classList.includes( 'noclosedcards' ));
	}

	get isPlayable() {
		if( !this.gameInfo ) return false;
		// if( window.DEBUG ) return true;
		if( ['bg', 'board'].includes( this.gameInfo.group ) ) return true;
		return !['burkozel'].includes( this.gameInfo.type );
	};

	canPlayfor( plno ) {
		return this.#myplace=== +plno || +this.gameInfo.manage=== +plno || this.gameInfo.manage==='*';
	}

	get getTopPanel() {
		return this.topPanel || this.#topPanelFragment;
	}
	
	get isFounder() {
		return UIN && this.gameInfo.founder?.toString()===UIN || window.SOLO;
	}
	
	get isTeacher() {
		return UIN && this.gameInfo.teacher?.toString()===UIN || window.SOLO;
	}
	
	getUser( place ) {
		return this.players[place]?.user;
	}
	
	getUserName( place ) {
		let u = this.getUser( place );
		return u?.getShowName || null;
	}
	
	getPlayer( place ) {
		return this.players[place];
	}
	
	get getMyScore() {
		return this.score?.[this.getMyPlace()] || '';
	}
	
	get getTourid() {
		return this.gameInfo.tour?.id;
	}
	
	get countEmptyPlaces() {
		return this.players.reduce( ( acc, p ) => {
			return acc + ((p.sitavail && !p.reserved) ? 1 : 0)
		}, 0 );
	}

	setNoNames( val ) {
		if( this.noNames===val ) return;
		this.noNames = val;
		// if( this.playArea.classList.contains( 'nonames' )===val ) return;
		this.playArea.classList.toggle( 'nonames', val );
		for( let plr of this.players )
			plr.checkAvatarSize();
	}

	#setLay( h, position ) {
		this.#toLay.delete( h );
		let v = h.holder || h;
		let orient = position==='top' || position==='bottom' ? 'central' : 'side';
		v.dataset.position = position;
		v.dataset.orientation = orient;
		// v.show();
		/*if( 'onlayout' in h ) */
		h.onlayout?.( position, orient );
		// ( (this.usePlayerHolder && this.playerHolders[position]) || this.topPanel || this.holder).appendChild( v );
	}

	setPositions( objects, mypos, players ) {
		if( players===undefined ) players = objects.length;
		if( mypos===undefined ) mypos = this.getpov;
		let poses = posclasses[players];
		for( let i = 0, plno = mypos; i<poses.length; i++, plno = (plno + 1) % players ) {
			let h = objects[plno];
			if( !h ) continue;
			let v = h['holder'] || h;
			let position = poses[i],
				orient = position==='top' || position==='bottom' ? 'central' : 'side';
			v.dataset.position = position;
			v.dataset.orientation = orient;
		}
	}

	getPositionOf( plno ) {
		return this.players[plno]?.position || (plno===this.#myplace && 'bottom');
	}
	
	get getpov() {
		if( this.pov!==undefined ) return this.pov;
		if( this.#myplace>=0 ) return this.#myplace;
		let myreserv = this.getMyPlace( true );
		if( myreserv>=0 ) return myreserv;
		if( this.maxPlayers===4 ) return 2;
		if( this.maxPlayers===2 && this.isboard ) {
			// Если стол заполнен, внизу должны быть белые (0)
			this.pov = this.players[1].uid ? 0 : 1;
			return this.pov;
		}
		return 0;
	}

	layoutOne( plno, position ) {
		// this.positions[plno] = position;
		if( !this.players[plno] ) {
			// bugReport( `Layout no player plno=${plno} position=${position}` );
			return;
		}
		this.players[plno].position = position;
		this.bypos[position] = plno;
		this.#arrowsymbol[plno] = arrowCode[position];
		this.players[plno].checkAvatarSize();

		if( this.#needlay[plno] ) {
			for( let h of this.#needlay[plno] )
				this.#setLay( h, position );
		}

		this.players[plno].setPosAttr( position );
	};

	layoutHands() {
		// if( !needlay.length ) return;
		if( this.customLayout ) return this.customLayout();
		let gi = this.gameInfo;
		if( !gi ) return;
		let mp = gi.sides;
		if( !mp || !posclasses[mp] ) return;

		for( let i = 0, plno = +this.getpov; i<mp; i++ ) {
			let vpos = posclasses[mp][i];
			this.layoutOne( plno, vpos );
			plno = (plno + 1) % mp;
		}

		// Clear absent players
		for( let i = mp; i<this.players.length; i++ ) {
			this.players[i].setBid();
		}

		// Virtual hand 3 (prikup) for pref-style 3-players games
		if( mp===3 ) {
			this.checkPlayer( 3 );
			this.layoutOne( 3, 'top' );
		}

		this.onlayout.forEach( f => f() );
		this.#needlayfor.forEach( o => this.setPositions( o ) );
		this.playArea.dataset['bottomplr'] = this.getpov;
		this.afterLayout.forEach( f => f() );

		for( let el of this.moveControls.$$( '[data-arrowsymbol]' ) )
			el.textContent = this.#arrowsymbol[+el.dataset.arrowsymbol];
		delay( this.#onresizeBind );
	};

	#recheckMyplace() {
		let wasplace = this.#myplace; //!==undefined;
		if( !this.#checkmyplace() ) return;
		// my place changed. Has to check pov
		this.pov = undefined;
		this.watcher.delay( 'checkbottom' );
		this.checkMyGame();
		delay( this.layoutHands.bind( this ) );
		this.#checkCenterAuction();
		this.checkRent();
		if( !this.isPlayer )
			this.clearControls();
		else
			this.inviteOffered = true;	// Если уже за столом, значит приглашения показывать не нужно
		// this.opSlider.makeVisible( window.TESTER && !this.isPlayer );
		if( wasplace!==undefined ) {
			Subscribe.delayRoute( this.#routes, 'placechanged' );
			// Закроем все окна кроме рекламы
			if( this.isPlayer ) closeBigWindows();
		}
	}

	#checkPlayers() {
		for( let i = this.maxPlayers; i--; ) this.players[i].check();
	}

	addParser( key, val ) {
		appendRoute( this._route, key, val );
	}

	addRoute( route, comment ) {
		if( this.#routes.has( route ) ) return;
		if( route._route ) {
			// This is instance of class with route object and route functions
			let r = route._route,
				props = getAllProperties( route );
			for( let k of props ) {
				if( LOCALTEST )
					console.log( `Field ${k}` );
				if( k.startsWith( 'route_' ) )
					appendRoute( r, k.split( 'route_' )[1], route[k].bind( route ) );
				if( k.startsWith( 'primeroute_' ) )
					appendRoute( r, k.split( 'primeroute_' )[1], route[k].bind( route ), true );
			}
			this.addRoute( r, comment );
			return;
		}
		this.#routes.add( route );
		this.vgame?.addParser( route, comment );
	};

	get( key ) {
		if( !this.vgame ) {
			log( 'WARNING: no vgame in game.js, maybe error of earlier parsing' );
			return;
		}
		return this.vgame.sub?.get( key );
	};

	// primeParser( key, func ) {
	// 	return this.addParser( key, func, true );
	// }

	#extendOnRequest( name, params ) {
		let module = params?.module || name,
			instance;
		this.addParser( name, ( o, minor ) => {
			if( params?.nogame && this.gameInfo.chainid?.toLowerCase().includes( params.nogame ) ) return;
			if( instance ) return instance.parse( o );
			let prom = promiseGameExtend( module );
			prom?.then( Module => {
				if( !instance ) {
					instance = new Module.default( this, params );
					this.ext[name + (params?.type ? '_' + params.type : '')] = instance;
				}
				instance.parse( o );
			} );
		} );
	}

	addMoveElement( el ) {
		this.moveControls.appendChild( el );
	}

	installLittle( name, object, enabled ) {
		log( 'Little install: ' + name );
		this.#littles.set( name, object );
		object.name = name;
		let parent = this.#littleBox;
		if( name==='chat' || name==='editor' ) parent = this.statusBar;
		// if( name==='pool' ) parent = this.topPanel || parent;
		parent.appendChild( object.holder );
		this.littleIcons.appendChild( object.icon );
		if( !this.firstLittle && name!=='chat' ) this.firstLittle = name;
		// holder.classList.add( 'little_box', 'hidewhenminifyed' );
		object.icon.classList.add( 'icon', 'little_icon' );
		object.icon.onclick = e => {
			log( 'Little clicked: ' + name );
			this.toggleLittle( name );
			sessionStorage[this.item + '_little'] = this.#lastLittle?.name || '';
		};
		object.holder.onclick = e => this.#littleClick( e, object );
		if( enabled ) this.enableLittle( name, true );
		if( this.#wantLittle===name ) {
			log( 'Show wanted little ' + wantLittle );
			this.showLittle( name )
		}
		if( name==='pool' ) this.#checkPoolIconBadge();
		this.checkLittles();
	};

	setTightly( val ) {
		if( this.tightly===val ) return;
		this.tightly = val;
		// Устанавливаем/убираем класс для бидов игроков "прятать в портрете"
		for( let i = 4; i--; ) {
			let classlist = this.players[i]?.elbid.classList;
			if( !classlist ) continue;
			if( this.islongauction ) classlist.toggle( 'nodisplay', val );
			else classlist.toggle( 'hideinportrait', val );
		}
		this.#checkCenterAuction();
	}

	tourInfoClick( e ) {
		// Открываем таблицу турнира
		// Если это матч, то ограничимся показом/убиранием скоринга
		if( this.teamMatch?.canShow() ) {
			this.teamMatch.toggleVisible();
		} else {
			if( !this.gameInfo.tour ) return;
			localBrowser( '/tour/' + this.gameInfo.tour.id );
		}
	}

	#littleClick( e, little ) {
		if( little!==this.#lastLittle ) return;
		let t = e.target;
		if( t.tagName==='TEXTAREA' || t.tagName==='INPUT' ) return;
		// Убираем малое окно, но только если мы в узком режиме
		if( !mediaSuperWide.matches )
			this.hideLittle();
	}

	hideLittle( keepBox ) {
		if( !this.#lastLittle ) return;
		this.#lastLittle.holder.hide()
		this.#lastLittle.icon.classList.remove( 'selected' );
		this.#lastLittle = null;
		this.#wantLittle = null;
		if( !keepBox ) this.#littleBox.hide();
		sessionStorage[this.item + '_little'] = '';
	}

	toggleLittle( name ) {
		if( name==='chat' && this.chatBottom ) {
			this.chat.toggle();
			localStorage.bottomchathidden = !this.chat.holder.isVisible();
			return;
		}
		if( this.#lastLittle?.name===name ) {
			// Убираем только в узкой модели
			if( this.wideBarMode ) return;
			this.hideLittle();
		} else
			this.showLittle( name );
	}

	showLittle( name, param ) {
		log( 'Showlittle: ' + name + ', param=' + JSON.stringify( param ) );
		if( name==='lastdeal' ) name = 'handrecord';
		if( name==='any' ) name = this.firstLittle;
		let l = name && this.#littles.get( name );
		if( name && !l ) {
			// Object not ready yet
			this.#wantLittle = name;
			return;
		}
		this.#wantLittle = null;
		if( name!=='chat' ) this.littleRecommend = null;
		if( this.#lastLittle===l ) return;
		if( name==='chat' && (this.chatAlways || this.chatBottom) ) {
			// Chat is always visible there
			this.#lastLittle ||= l;
			this.chat.show();
		} else {
			if( l?.enabled===false ) return;

			this.hideLittle( true );

			sessionStorage[this.item + '_little'] = name;
			this.#lastLittle = l;
			if( l ) {
				// Dont make other way because you can lose this
				if( l.show ) l.show();
				else l.holder.show?.();
				l.icon.classList.add( 'selected' );
				l.onShow?.();
			}
			this.#littleBox.makeVisible( !!l );
			// log( 'Little: ' )
		}
	}

	littleHideIfShowed( name ) {
		let l = this.#littles.get( name );
		if( !l || this.#lastLittle!==l ) return;
		this.showLittle();
	}

	enableLittle( name, val ) {
		let l = this.#littles.get( name );
		if( !l ) return;
		l.enabled = val;
		if( !val ) l.holder.hide();
		// l.icon.classList.toggle( 'clickable', val );
	}

	showLittleIcon( name, val ) {
		let l = this.#littles.get( name );
		if( !l ) return;
		if( !val ) l.holder.hide();
		l.icon.makeVisible( val );
	}

	/*			function parse_getready( dom ) {
		var s = dom.getAttribute( 'seconds' );
		var t = dom.getAttribute( 'type' );
		var g = divplay.getElementsByClassName( 'table_getready' )[0];
		divplay.setAttribute( 'getreadytype', t );
		g.display = t=='gamestart' ? 'block' : 'none';
		divplay.classList.add( 'getready' );
		setTimeout( function() {
			divplay.classList.remove( 'getready' )
		}, 3600 )
	} */

	checkBidExplain( plno ) {
		let auction = this.#littles.get( 'auction' );
		if( !auction ) return;
		auction.setLastBidExplain( plno, true );
	}

	#checkSwipeOn() {
		this.playArea.noswipe = this.#myplace>=0 && this.inprogress;
	}

	#checkPlayMoreVisible() {
		let fin = this.gameState==='finished' || this.gameState==='notstarted', // !this.inprogress,
			more = false;
		if( fin ) {
// if( this.gameInfo['solo'] ) more = true; else
			if( this.isPlayer && this.#reger && this.maxPlayers===2 ) more = true;
		}
		if( more!==this.#playMore ) {
			this.#playMore = more;
			this.#playMoreHolder.makeVisible( this.#playMore );
			log( 'Play more now: ' + this.#playMore );
		}

		// let visinvite = this.canInvite && !this.inprogress && this.countEmptyPlaces;
		// this.playArea.$( '[name="invite"]' ).makeVisible( visinvite );
	}

	#checkPlayMoreReg() {
		if( !this.#reger ) return;
		let reged = elephCore?.isReged( this.#reger );
		this.#playMoreHolder.classList.toggle( 'registered', reged );
	}

	#periodic( force ) {
		const counts = [200, 500, 2000];
		let changed = false;
		if( this.cardHolder )
			for( let h of this.cardHolder )
				changed ||= h.sizeControl?.();
		this.#checkSizeCount ||= 0;
		if( changed || force ) this.#checkSizeCount = 0; else this.#checkSizeCount++;
		this.checkSizeTimeout = setTimeout( counts[this.#checkSizeCount] || 30000, this.#periodic.bind( this ) );
	}

	sizeCheck() {
		if( this.checkSizeTimeout ) clearTimeout( this.checkSizeTimeout );
		this.#periodic( true );
	}

	#checkCenterAuction() {
		this.ext.auction_center?.checkShow(); 
	}

	plrStateClick( place, e ) {
		let t = e.currentTarget;
		if( t?.classList.contains( 'contract' ) )
			this.centerAuction?.toggleShow();
		// this.toggleLittle( 'auction' );
	}

	route_lastgame( o ) {
		this.lastGame = o;
		this.checkGameBrief();
	}

	route_playsound( o ) {
		if( !o || typeof o!=='string' ) return;
		playSound( o );
	}

	route_ratings( o ) {
		if( !o ) return;
		for( let i = this.maxPlayers; i--; )
			if( o[i] ) this.players[i].setRating( this.gameInfo.id, o[i] );
	}

	route_outs( num ) {
		this.outs = num;
		this.inButton.makeVisible( this.isout );
	}

	route_undo( num ) {
		// Bit array
		let v = num==='yes';
		if( !v && this.#myplace>=0 ) {
			// let v = +num===this.#myplace;
			v = (+num >>> this.#myplace) & 1 || false;
			log( `Undo visible=${v} myplace=${this.#myplace}` );
			if( v ) {
				// Где должен быть undo. На заявке?
				// if( this.cardHolder[+num] )
				// 	this.cardHolder[+num].appendChild( this.buttons.undo );
			}
		}
		if( this.#myplace>=0 || this.isSolo ) {
			this.buttons.undo.makeVisible( v );
			this.#checkClaimVisible();
		}
	}

	route_toast( o ) {
		toast( o );
	}

	route_whiteside( val ) {
		if( this.whiteSide=== +val ) return;
		let pos = +val;
		this.whiteSide = pos;
		this.players[pos].setAttribute( 'color', 'w' );
		this.players[1 - pos].setAttribute( 'color', 'b' );
		this.checkboardplayerspos();
		this.watcher.delay( 'checkbottom' );
	}

	route_bigstate( o ) {
		this.gameResult = null;
		this.bigState = o;
		let str = typeof o==='string' && o || o.text || '', style;
		str = str.replace( 'DEMO', currency( 'DEMO' ) );
		if( o.type==='waiting' && this.getMyPlace().toString()===o.places ) {
			return this.#elbigstate.hide();
			// Попробуем пока не показывать кого ждем в центре экрана
		} else if( o.type==='endofgame' ) {
			if( o.winner>=0 && this.getMyPlace()===o.winner ) {
				str = "{Victory}! " + o.reason;
				style = 'good';
			} else {
				if( o.winner===-1 ) {
					str = '{Draw}';
					if( o.reason ) str += ' (' + o.reason + ')';
				} else if( o.winner>=0 ) {
					let u = this.getUser( o.winner ),
						part = this.gameInfo.pairs ? this.getUser( o.winner + 2 ) : '';
					if( u && part )
						str = `{Winners}: ${u.getShowName} & ${part.getShowName} (${o.reason})`;
					else if( u )
						str = `{Victor}: ${u.getShowName} ${o.reason && '(' + o.reason + ')'}`;
					else str = '{Gamecompleted}: ' + o.reason;
				} else {
					str = '{Gamecompleted}';
					if( o.reason && o.reason!=='-' ) str += ': ' + o.reason;
				}
				this.gameResult = {
					text: str,
					style: style
				}
			}
		} else if( o.type==='endofmatch' ) {
			if( o.winner>=0 && this.getMyPlace()===o.winner ) {
				str = "{Victory}!";
				style = 'good';
				window.makeCool?.( {
					game: this,
					code: 'win'
				});
				if( navigator.notification ) {
					// try native notification
					navigator.notification.alert( localize( '{Victory}!' ),
						null, localize( '{Matchcompleted}' ) );
					this.#elbigstate.hide();
					return;
				}
			} else {
				if( o.winner=== -1 ) str = '{Draw}';
				else if( o.winner>=0 ) {
					let u = this.getUser( o.winner );
					if( u ) str = '{Victor}: ' + u.getShowName;
					else str = '';
				}
			}
			let prefix = '{Matchcompleted}. ';
			if( o.timeout>=0 )
				prefix = `{Timeout}: ${this.getUser( o.timeout )?.getShowName || o.timeOut}. `;

			str = prefix + str;
			this.gameResult = {
				text: str,
				style: style
			}
		} else {
			if( o.places ) {
				str = '';
				// Покажем игроков, к которым относится сообщение
				for( let p of o.places ) {
					let u = this.getUser( p );
					if( u ) str += (str && '; ' || '') + u.getShowName;
				}
				str += (str && ': ' || '') + o.text;
			}
		}
		if( !str || this.gameResult ) {
			this.#elbigstate.hide();
			return;
		}
		let period = o.period || 0;
		if( o.hidetime ) period = (o.hidetime + window.TIMESERVERDIFF - Date.now()) || -1;
		if( period<0 ) return;
		this.#elbigstate.setContent( str, this );
		this.#elbigstate.show();
		this.#elbigstate.dataset.textstyle = style || '';
		if( o.timerto ) {
			html( `<progress is='neo-progresstimer' data-to='${o.timerto}' data-from='${o.timerfrom || ''}' style='position: absolute; left: 0; top: 100%; width: 100%;'></progress>`,
				this.#elbigstate );
		}
		if( period )
			setTimeout( () => this.#elbigstate.hide(), period );
	}

	checkMyGame() {
		fire( 'checkmygame', this );
	}

	done() {
		log( 'The game is done' );
		this.DONE = true;
		this.#makeObsolete( true );
	}

	checkKibiAll( value ) {
		if( value!==undefined ) {
			if( this.watchKibi===value ) return;
			this.watchKibi = value;
			delay( onresize );
		}
		if( this.kibiButton ) {
			let vis = !this.istv && !this.watchKibi && this.gameInfo['cankibi'] && this.#myplace=== -1 && this.inprogress || false;
			this.kibiButton.makeVisible( vis );
			if( vis )
				this.kibiButton.classList.toggle( 'premium', this.gameInfo['cankibi']==='premium' );
		}
	}

	checkResize( ms ) {
		if( ms )
			setTimeout( this.#winResizeBind, ms );
		else
			delay( this.#winResizeBind );
	}

	parse( data, minor, secret ) {
		// if( LOCALTEST ) log( '$$$ ' + minor );
		if( data==='DONE' || (!minor && data==='LEAVED') ) {
			if( !secret ) {
				log( 'Lost game item ' + this.item );

				this.#makeObsolete( true );
			}
			return;
		}
		if( secret && data==='UNSUBSCRIBED' ) {
			if( secret==='kibi' ) this.checkKibiAll( false );
			this.#dialog.hide();
			return;
		}

		if( this.#waitResize )
			this.checkResize();
	}

	// -------- END of parsing block -----------
	/*
		function refreshdealno() {
			const bd = gameliinfo.getElementsByClassName( 'table_dealno' )[0];
			if( bd ) bd.textContent = gamestate==='finished' ? 'Completed' : dealnostr
		}

	*/
	#checkPoolIconBadge() {
		let myplace = this.getMyPlace();
		if( !(myplace>=0) ) return;		// not playing
		let pool = this.#littles.get( 'pool' );
		if( !pool ) return;
		let value = this.players[myplace].getValue( 'score' );
		if( value ) {
			pool.icon.dataset.badge = value;
		} else
			pool.icon.removeAttribute( 'data-badge' );
	}

	rent( str ) {
		this.rent = str?.split( ',' ).map( x => +x );
		if( this.#myplace>=0 ) {
			this.checkRent();
			this.#checkCenterConventions();
		}
	}

	get myRent() {
		return this.rent?.[this.#myplace] ?? this.gameInfo.rent?.value ?? 0;
	}

	disbanded( o ) {
		if( o.reason==='gameover' )
			toast( '{Gameover}' );
		else if( this.gameInfo )
			toast( '{Tabledisbanded}' );
		goLocation( o.goto || o.room );
	}

	route_giveup( str ) {
		let pl = this.#myplace;
		this.canGiveup = null;
		if( pl>=0 ) {
			let ar = str.split( ',' );
			let can = this.canGiveup = ar[pl];
			this.giveupButton?.makeVisible( can /*&& !this.current?.parent*/ );
		}
	}

	route_titlescore( data ) {
		this.scoreLine.setContent( data && `{Score}: ${data}` || '' );
	}

	route_cube( data ) {
		data ??= '';
		let cube = this.cube;
		if( cube.str===data ) return;
		cube.str = data;
		let [cubedata, state] = data.split( ' ' ),
			s = cubedata.split( ':' );
		cube.value = s[0];
		cube.button.dataset['who'] = s[1] || '';
		cube.who = parseInt( s[1] );
		cube.button.classList.toggle( 'enabled', this.#myplace>=0 && cube.who===this.#myplace );
		cube.button.dataset.who = s[1] || '';
		cube.button.dataset.asked = s[2]==='?' && 1 || 0;
		cube.state = state;
		let t = s[0] || '';
		if( state==='crawford' ) t = '🧙';
		cube.button.setContent( t );
		if( cube.value ) this.cube.button.makeVisible( true );
	}

	route_secretplayer( data, _, secret ) {
		if( +secret>=0 && data ) {
			if( this.players[+secret].setSecret( data ) )
				this.#recheckMyplace();
			return;
		}
		if( +secret>=0 && this.getMyPlace()!== +secret ) {
			log( 'CHECKSECRET ' + secret + ', my place is ' + this.getMyPlace() );
			
		}
	}

	chatMessage( o, loading ) {
		if( o.type==='kibi' && this.isPlayer ) return;
		if( this.chat )
			this.chat.parse_message( o, loading );
		else
			this.chatMessages.push( o );
	}

	route_saycode( o ) {
		if( o.code==='GLP' ) {
			window.makeCool?.( {
				game: this,
				dealno: this.dealInfo?.number,
				type: 'say',
				...o
			} );
		}
	}

	route_chat( o ) {
		this.chatMessage( o );
	}

	route_allchat( chats ) {
		for( let o of chats ) {
			this.chatMessage( o, true );
		}
	}

	route_matchscore( o ) {
		if( !o ) {
			// Это не матч
			this.playArea.classList.remove( "match" );
			// players.forEach( function( o ) {
			// 	o.setmatchscore( 3 )
			// } );
			this.#matchScore.holder.hide();
			return;
		}
		this.playArea.classList.add( "match" );
		let scores = o.split( ":" );
		for( let i = this.maxPlayers; i--; )
			this.players[i].setMatchScore( scores[i] );

		this.#matchScore.score0.textContent = scores[0];
		this.#matchScore.score1.textContent = scores[1];
		this.#matchScore.holder.show();
	}

	#mkserver( type, data ) {
		return 'type=' + (type || 'move') + ' item=' + this.item + (data && (' data="' + encodeURIComponent( data ) + '"') || '');
	}
	
	#send( type, data ) {
		if( this.DONE ) return { ok: false, errcode: 'gamenotfound' };
		let s = this.#mkserver( type, data );
		return elephCore?.sendPlay( s )
	}

	#ingameAction( e ) {
		let t = e.target;
		if( t.dataset.action ) {
			this.send( 'dialog', t.dataset.action );
			this.#dialog.hide();
			this.#inquiry.hide();
			this.#clearAction();
		}
	}

	setIngameButton( ingame, action, caption, order ) {
		let n = ingame.countbuttons, b = ingame.buttons[action],
			holder = ingame.$( '.buttons' ) || b;
		if( !b ) {
			b = construct( 'button.display_none.suitcode.clickable.likeabutton.ingame_button',
				this.#ingameAction.bind( this ), holder );
			// b = construct( '.control.mybutton.ingame_button', ingameaction );
			b.dataset.action = action;
			ingame.buttons[action] = b;
		}
		let plno = caption.split( 'arrow_' )[1];
		if( plno ) {
			b.textContent = this.#arrowsymbol[+plno];
			b.dataset.arrowsymbol = plno;
		} else {
			this.setContract( b, caption );
		}
		b.classList.toggle( 'default', action==='start' );
		b.show();
		b.style.order = order;
		ingame.countbuttons++;
	}

	#setInGame( ingame, title, data, params ) {
		title = title || data.title || data._title;
		let caption = ingame.$( '.caption' );
		if( title ) {
			if( params.offerer>=0 ) {
				let ar = this.#arrowsymbol[params.offerer];
				if( !ar ) {
					ar = this.getUserName( params.offerer );
					if( ar ) ar += ':'
				}
				if( ar ) title = ar + ' ' + title;
			}
			caption.setContent( title );
		} else if( data._showscore && this.getMyScore!=='' ) {
			caption.setContent( '{Score}: ' + this.getMyScore );
		} else {
			caption.textContent = '';
		}
		ingame.dataset.textstyle = params?.style || null;
		if( params?.type )
			ingame.setAttribute( 'type', params.type );
		else
			ingame.removeAttribute( 'type' );
		ingame.countbuttons = 0;
		let order = 0,
			buttons = data.buttons || data;
		for( let k in buttons ) {
			if( k!=='title' && k[0]!=='_' )
				this.setIngameButton( ingame, k, buttons[k], order++ );
		}
		for( let k in ingame.buttons )
			if( !buttons[k] ) ingame.buttons[k].hide();
		ingame.setAttribute( 'buttons', ingame.countbuttons );
		ingame.show();
		//		ingame.style.height = 35*buttons.length + 'px'
	}

	clearControls() {
		this.#dialog.hide();
		this.#inquiry.hide();
		this.inButton.hide();
	}

	makecut( o ) {
		this.cutHolder ||= html(
			`<div class='display_none column center sheet' style='gap: 0.5em; padding: 1em'>
			<span>{Cut}</span>
			<input type='range' min='0' max='100'></input>
			<button class='default'></button>
			</div>`,
			this.moveControls );
		let h = this.cutHolder;
		h.oninput ||= e => {
			this.cutHolder.$( 'button' ).setContent( `{Cut} ${this.cutHolder.$( 'input' ).value}` )
		}
		h.onclick = e => {
			if( e.target.tagName==='BUTTON' ) {
				this.cutHolder.hide();
				this.sendMove( `cut ${this.cutHolder.$( 'input' ).value}` );
			}
		}

		let input = h.$( 'input' );
		input.value = o.data.default==='random' && Math.random() * (o.data.max + 1)
			|| +o.data.default || 0;
		input.max = o.data.max || 100;
		input.min = o.data.min || 0;
		// h.$( '.progress' ).max = o.data.till;
		h.oninput();
		h.show();
	}

	route_dialog( o, _, secret ) {
		if( +secret!==this.getMyPlace() ) return;
		this.currentDialog = o;
		let code = typeof o==='object' ? o.code : o;
		if( code==='makecut' ) {
			this.#dialog.hide();
			return this.makecut( o );
		}
		let dialogs = {
			start: { start: '{Startgame}' },
			replay: { replay: '{Replay}' },
			continuereplay: { continue: '{Continue}', replay: '{Replay}' },
			completereplay: { complete: '{Complete}', replay: '{Replay}' },
			onemore: { start: '{Playagain}', leave: '{Nothanx}' },
			continue: { start: '{Continue}' },
			continuecomplete: { complete: '{Complete}', start: '{Continue}', _showscore: true },
			waitmoreorcalc: { waitmore: '{Waitmore}', calcgame: '{Calculategame}' },
			whistpass: { whist: 'whist', pass: 'pass' },
			whisthalf: { whist: 'whist', half: 'halfwhist' },
			openclosedpass: { whisto: 'whistopened', whistc: 'whistclosed', pass: 'pass' },
			openclosedhalf: { whisto: 'whistopened', whistc: 'whistclosed', half: 'halfwhist' },
			keepordrop: { dokeep: '✅ {talon_take}', doface: '{talon_drop}' }, // "{Widowaction}"
			confirmplay: { doreturn: '{widowback}', doplay: '{iplay}' }, // Contract in title
			selectwhister: { doself: 'doself', dotrust: 'dotrust' }, // Contract in title
			selectwhister0: { doself: 'doself', dotrust: 'dotrust', dotrust0: 'miser0tr' }, // With "Harmless miser"
			selectwhisttype: { light: '{prefopened}', dark: '{prefclosed}' }, // Contract in title
			selectwhisthand: { title: '{Whichhandtoopen}', _hidebigstate: true }, // Select hand to show
			selecttargetgame: { wrpool: '{incpool}', wrdump: '{decdump}' }, // {Selectgamewrite}
			selecttargetpass: { wrpool: '{incpool}', wrdump: '{decdump}' }, // {Selectpasswrite}
			belayesno: { belayes: '{bela}!', belano: '{nodeclare}' }, // {Selectpasswrite}
			beatortransfer: { beat: '🛡️{durak_beat}', perevod: '🗡️{durak_transfer}' }, // {Selectpasswrite}
		};

		if( code==='selectwhister' && o.data==='use0' )
			code = 'selectwhister0';
		if( code ) {
			let mix = dialogs[code];
			if( !mix ) {
				mix = {};
				for( let c of code.split( ',' ) )
					mix[c] = localize( c );
			}
			if( code==='selectwhisthand' ) {
				let plno = o.data.split( ',' );
				mix.buttons = {
					lefthand: `arrow_${plno[0]}`,
					righthand: `arrow_${plno[1]}`
				}
			}
			this.#setInGame( this.#dialog, null, mix, o );
			this.#dialog.dataset['code'] = code;
			// dialog.show();
			this.wantsAction( code==='start' ? 'start' : 'action' );
			if( mix._hidebigstate )
				this.#elbigstate.hide();
		} else {
			this.#dialog.hide();
			this.cutHolder?.hide();
		}
		this.checkMyBubbleAndBid();
		this.checkGameBrief();
	}

	route_msg( str ) {
		if( str[0]!=='-' ) toast( str );
	}

	route_inquiry( o ) {
		let dialogs = {
			acceptconventions: {
				// caption: '{Confirmconventions}',
				buttons: {
					agree: '{Accept}'
				}
			},
			double: {
				caption: '{Double}!',
				buttons: {
					agree: '{Accept}',
					disagree: '{Resign}'
				}
			}
		};

		let code = o?.code,
			dialog = code && dialogs[code] || o;
		if( code==='double' )
			dialog.buttons.agree = '{Accept} ' + o.cube;
		if( dialog )
			this.#setInGame( this.#inquiry, dialog.caption /*|| o.caption*/,
				dialog['buttons'] || { agree: '{yes}', disagree: '{no}' }, dialog );
		else
			this.#inquiry.hide();

		this.checkGameBrief();
	}

	#checkDealState() {
		if( this.is1000 || this.isbridge ) return;
		if( !this.#elContract && (this.contract || this.dealInitial) )
			this.#elContract = html( "<div class='contract column center textoverbg'><div class='hideempty bid suitcode clickable hidenocontrols' style='order: 10'></div>" +
				"<div class='who'></div></div>", this.getTopPanel,
				this.#contractClick.bind( this ) );

		let bid = this.#elContract?.$( '.bid' );
		if( !bid ) return;
		bid.hide();

		if( this.contract /* && this.contract.declarer>=0 */ ) {
			if( this.ispref && this.contract.declarer>=0 )
				this.setContract( bid, '' );
			else
				this.setContract( bid, this.contract.bid );
		} else if( this.dealInitial && !this.isbridge ) {
			this.setContract( bid, this.dealInitial );
		} else
			this.setContract( bid, '' );
		bid.style.fontSize = bid.dataset.code?.length===1 ? '2rem' : 'unset';
	}

	baseSnapshot( o ) {
		// Сохраним основное: стрелки, таймеры
		if( this.cube ) o.cube = this.cube.str;
	}

	route_event( str ) {
		if( str==='newdeal' || str==='clearstates' ) {
			for( let i = this.maxPlayers; i--; )
				this.players[i].setObject( 'state', '' );
		} else if( str==='startplay' ) {
			navigator.vibrate?.( 500 );
			// Зафиксируем баннер в начале игры
		} else if( str==='newgame' ) {
			this.needSGB = true;
		}
		if( str==='newdeal' ) {
			this.cardHolder?.forEach( x => x.clear() );
		}
	}

	#checkClaimVisibleBind = this.#checkClaimVisible.bind( this );
	#checkGameBriefBind = this.checkGameBrief.bind( this );
	needCheckObjects() {
		delay( this.#checkClaimVisibleBind );
		delay( this.#checkGameBriefBind );
	}

	#checkClaimVisible() {
		let claimVisible = this.claim?.holder.isVisible();
		if( !this.isbridge )
			this.buttons.say.classList.toggle( 'nodisplay', !!claimVisible );

		if( !claimVisible ) return;
		let hide = false;
		if( this.isbridge ) {
			if( this.sideMovesVisible?.() && mediaNarrow.matches ) hide = true;
			hide ||= this.buttons.say.isVisible();
		}

		if( mediaNarrow.matches && this.centerAuction.holder.isVisible() )
			hide = true;

		this.claim.holder.classList.toggle( 'nodisplay', hide );
		// this.claim.holder.classList.toggle( 'nodisplay', !!this.buttons.say.isVisible() );

		let un = this.buttons.undo?.isVisible();
		for( let el of this.playArea.$$( '.playzone > .claimposition' ) )
			el.style.transform = un? 'translateX(-50px)' : '';
	}

	route_needsay( str ) {
		// Что-то должны сказать игроки (привет, удачи и т.п.) вычленим, надо ли что-то нам
		if( !this.isPlayer ) return;
		let code = str.split( ',' )[this.getMyPlace()],
			btn = this.buttons.say;
		this.mySay = code;
		if( !code ) {
			btn.dataset['code'] = '';
			btn.hide();
			this.needCheckObjects();
			return;
		}
		btn.dataset['code'] = code;
		btn.setContent( '{' + capitalize( code ) + '}' );
		btn.show();
		// (this.iscards && this.playZone || this.moveControls).appendChild( btn );
		this.playZone.appendChild( btn );
		this.needCheckObjects();
	}

	route_lastevent( str ) {
		this.lastEvent = str;
		this.#checkCenterAuction();
	};

	route_dealinitial( str ) {
		this.dealInitial = str;
		this.#checkDealState();
	}

	route_contract( str ) {
		if( this.contract?.str===str ) return;
		this.contract = str && {
			declarer: +str[0],
			bid: str.slice( 1 ),
			doubled: str.includes( '*' ) || str.includes( 'X' ),
			redoubled: str.includes( '**' ) || str.includes( 'XX' ),
			str: str
		} || null;
		if( this.contract ) this.contract.level = +this.contract.bid[1] + (this.isbridge ? 6 : 0);

		this.#checkDealState();
		this.checkTrumpVisible();

		if( this.isbridge && this.cardHolder ) {
			// Проверим сортировку в руках
			for( let h of this.cardHolder ) h.resort();
		}
	}

	checkGameBrief() {
		let gb = this.playZone.$( '.gamebrief' );
		if( !gb ) return;
		let canInvite = this.canInvite && !this.inprogress && this.countEmptyPlaces>0,
			gbvis = !this.inprogress && this.countEmptyPlaces>0 && (canInvite || this.holderConventions.textContent.length>0);
		gb.makeVisible( gbvis );
		let canAgain = this.currentDialog?.code==='onemore',
			btn;
		if( canInvite ) btn = 'invite';
		if( canAgain ) btn = 'playmore';
		if( this.mySay==='TFG' ) btn = 'say';
		for( let b of gb.$$( 'button' ) )
			b.makeVisible( b.name===btn );
		gb.$( '.title' ).setContent( this.gameState==='finished'? (this.lastGame?.reason || `{Gamecompleted}`) : '' );
		if( gbvis ) {
			// Hide exceed buttons
			if( canAgain )
				this.#dialog.hide();
			// if( this.mySay==='TFG' )
			// 	this.buttons.say.hide();
		}
	}

	#checkCenterConventions() {
		let html = this.conventions?.split( /[;\.]/ )
			.map( (x, idx) => `<span ${idx?'':'style="font-weight: bold; margin-bottom:0.5em"'}>${localize( x )}</span>` )
			.join( '' ) || '';
		if( this.gameInfo.rent ) {
			if( html ) html += '<br>';
			let rent = this.gameInfo.rent,
				myrent = this.myRent,
				rentadd = '';
			if( myrent>rent?.value ) rentadd = ' ({youpayforall})';
			else if( rent?.value && !myrent ) {
				rentadd = '<span> {foryou}</span>';
			}
			if( rent?.value )
				html += `<span class='subtitle' data-convname='rent' style='cursor: pointer'>{Rent}: <span data-convname='rent' class='fants'>${this.myRent}</span>${rentadd}</span>`;
			else if( rent?.clubvalue )
				html += `<span class='subtitle' data-convname='rent' style='cursor: pointer'>{Rent}: <span data-convname='rent' class='money'>${showBalance( rent.clubvalue, User.getTeam( rent.clubmoney )?.currency )}</span>${rentadd}</span>`;
		}
		this.holderConventions.html( html );
		this.checkGameBrief();
	}

	route_conventions( str ) {
		this.conventions = str.replace( 'DEMO', currency( 'DEMO' ) );
		this.#checkCenterConventions();
	}

	checkTrump() {
		if( !this.#elTrump ) return;
		this.#elTrump.dataset['position'] = this.gameInfo.type==='durak' ? 'pack' : 'trump';
	}

	checkBuyRent() {
		this.checkBuyRentId = 0;
		if( this.rentChecked ) return;
		// Showing window of fants while game is not started
		// If we are at the table or there are empty places
		if( this.inprogress || this.vgame.DONE ) return;
		if( !this.isPlayer && !this.countEmptyPlaces ) return;
		let rent = this.myRent;
		if( window.MYFANTS.value>=rent ) return;
		log( `Rent is not enough ${MYFANTS.value}<${rent}` );
		// Начнем не сразу с магазина, а с мягкого текста
		this.rentChecked = true;
		this.rentNotenough = true;
		import('./pay.js').then( mod => mod.askBuy( {
			what: this.gameInfo.rent || { fants: rent },
			picture: this.gameInfo.icon,
			title: '{Rent}',
			descr: '{Rent_fullinfo}',
			reason: '{Forplay} {necessary}'
		} ) );
		/*
						elephCore.shopping( {
							ids: 'fants',
							reason: '{Rent}. {Necessary}: ' + rent,
							needfants: rent
						} );
		*/
	}

	checkRent() {
		if( this.#myplace>=0 && !this.rent ) return;
		let r = this.gameInfo.rent,
			my = this.myRent,
			rento = typeof r==='object' && r,
			rent = rento?.fants,
			payids = rento?.payids,
			freemium = rento?.freemium;

		if( freemium && elephCore?.isHallPayer( this.gameInfo.hall ) ) rent = 0;
		if( rento?.freeclub && elephCore?.isClubPayer() ) rent = 0;
		if( this.gameInfo.gross=== +UIN ) rent = 0;
		if( elephCore?.canpayfor( rento?.payids ) ) rent = 0;
		if( my>=0 ) rent = my;
		this.rentNotenough = false;
		if( rent && !this.rentChecked ) {
			// Поскольку происходят накладки при загрузке и показ не вовремя, сделаем паузу - секунду.
			// Пусть всё прогрузится
			this.checkBuyRentId ||= setTimeout( this.checkBuyRent.bind( this ) );
		}
		// this.buttons.rent.makeVisible( rent>0 );
		if( rent>0 ) {
			this.navi.rent ||= construct( '.grayhover.flexline.center.emoji.display_none.renticon 💰', this.naviIcons, this.#rentClick.bind( this ) );
			this.navi.rent.title = rent;
			this.navi.rent.show();
			// this.buttons.rent.firstElementChild.setContent( '{Rent} ' + rent );
			// this.buttons.rent.lastElementChild.makeVisible( this.gameState==='notstarted' && this.isFounder() && !this.gameInfo.gross );
		} else {
			this.navi.rent?.hide();
		}
		for( let o of $$( `[data-origin='game_${this.AAA}_myrent']` ) )
			o.setContent( my );
	}

	checkTrumpVisible() {
		if( !this.#elTrump ) return;
		if( this.gameInfo.type==='durak' ) {
			this.#elTrump.makeVisible( this.trump && this.pack?.count===0 || false );
		}
		/*
				else if( this.gameInfo.type==='king' ) {
					elTrump.makeVisible( this.trump && this.trump!=='n' );
				}
		*/

		if( this.#elContract?.$( '.bid' ).isVisible() )
			this.#elTrump.hide();
	}

	route_trump( str ) {
		if( this.trump===str ) return;
		this.trump = str;
		if( !this.#elTrump ) {
			this.#elTrump = construct( '.fade.bid.suitcode.trump', this.getTopPanel );
			this.checkTrump();
		}
		this.setContract( this.#elTrump, str );
		this.checkTrumpVisible();
	}

	get myPlace() {
		return this.getMyPlace();
	}

	getMyPlace( checkreserved ) {
		// if( !elephCore?.auth || !elephCore?.auth.uid ) return -1;
		let place = this.solo?.myplace ?? this.#myplace ?? -1;
		if( place===-1 && checkreserved )
			place = this.getPlace( UIN || GUESTUIN, true );
		return place;
	}

	get #getMyPlayer() {
		let pl = this.getMyPlace();
		return pl>=0 && this.players[pl] || null;
	};

	get isPlayer() {
		return this.getMyPlace()>=0 || this.isSolo;
	}

	get isRealPlayer() {
		return this.getMyPlace()>=0;
	}

	getPlace( id, checkreserved ) {
		if( !id ) return -1;
		for( let i = this.maxPlayers; i--; ) {
			if( !this.players[i] ) continue;
			if( this.players[i].secret===id ) return i;
			if( this.players[i].reserved && !checkreserved ) continue;
			if( this.players[i].uid===id ) return i;
		}
		return -1;
	}

	#checkmyplace() {
		let m = this.solo?.myplace ?? this.getPlace( UIN || GUESTUIN ),
			r = this.solo?.myplace ?? this.getPlace( UIN || GUESTUIN, true );

		if( this.#myplace===m && this.myreservation===r ) return false;
		if( this.#myplace>=0 ) this.players[this.#myplace].elavatar.classList.remove( 'mine' );
		this.#myplace = m;
		this.myreservation = r;
		if( this.#myplace>=0 ) {
			this.playArea.setAttribute( 'sit', this.#myplace );
			if( this.#myplace>=0 )
				this.players[this.#myplace].elavatar.classList.add( 'mine' );
		} else
			this.playArea.removeAttribute( 'sit' );
		this.checkKibiAll();

		// this.watcher.delay( 'myplace', myplace );

		this.#checkSwipeOn();
		this.#checkPlayMoreVisible();
		return true;
	}

	/*
		function recheckcontrols() {
			needcheckcontrols = true;
		}

	*/
	#contractClick() {
		// Покажем торговлю, если она есть
		let auction = this.ext.auction_center;
		if( !auction || auction.isEmpty ) return;
		auction.toggleShow();
		// this.toggleLittle( 'auction' );
	}

	async #cubeClick() {
		if( this.cube.state==='crawford' ) {
			// Информация о кроуфорда
			crawblinker++;
			if( crawblinker % 2 )
				bigInfo( {
					picture: '538a04d2d2efbe9f779843e18d42af66',
					text: '{Crawfordrule_fullinfo}'
				} );
			else
				toast( '{Crawfordgame}' );
			return;
		}
		if( !this.isPlayer || !this.inprogress ) return;
		if( await askConfirm( '{Doubleto} ' + ((this.cube?.value || 1) * 2) + '?' ) )
			this.send( 'cube' );
	}

	#playMoreClick() {
		if( !elephCore ) return;
		if( this.isSolo )
			return this.soloMove( 'playmore' );

		// Register to this reger again
		if( this.#reger ) {
			let reged = elephCore.isReged( this.#reger );
			elephCore.do( 'type=register reger=' + this.#reger + (reged ? ' on=0' : '') );
		} else {
			this.send( 'dialog', 'start' );
		}
	}

	get isActive() {
		return modules.swiper?.current===this.playArea;
	}

	get isout() {
		return (this.#myplace>=0 && (+this.outs >>> this.#myplace) & 1) || false;
	}

	get getSwiper() {
		return this.playArea.parentElement?.swiper;
	}

	#rotateClick() {
		this.manualRotate = !this.manualRotate;
		this.navi.rotate.classList.toggle( 'rotated', this.manualRotate );
		// Отключим анимацию
		for( let e of document.querySelectorAll( '*' ) ) e.style.transition = 'none';
		setTimeout( () => {
			for( let e of document.querySelectorAll( '*' ) ) e.style.transition = null;
		}, 100 );
		if( this.boardPlayers ) {
			this.checkboardplayerspos();
			this.watcher.call( 'checkbottom' );
		} else {
			this.pov = (this.getpov + 1) % this.maxPlayers;
			delay( this.layoutHands.bind( this ) );
		}
	}

	setpov( pov ) {
		this.pov = (+pov) % this.maxPlayers;
		if( isNaN( this.pov ) ) this.pov = undefined;
		delay( this.layoutHands.bind( this ) );
	}

	async #lobbyClick( e ) {
		if( this.vgame?.solo )
			return this.vgame.solo.clickBack( e );

		let serv = UIN ? await this.send( 'check' ) : {};
		if( serv.errcode ) {
			log( 'Obsolete game' );
			this.#makeObsolete( true );
		} else {
			if( this.playArea.closest( '.bigwindow.visible' ) )
				return this.playArea.closest( '.bigwindow.visible' ).hide();

			if( !this.isPlayer && !this.isSolo ) {
				// Если мы не играем, подписку отменяем, устареваем
				// иначе просто переключаемся в зал
				log( 'Make obsolete this game: ' + this.item );
				this.#makeObsolete();
			} else {
				if( this.gameInfo.mayfounderstop && this.isFounder && this.inprogress ) {
					if( await askConfirm( '{Complete}?' ) )
						this.send( 'stopandsitout' );
					return;
				}
				// leaveButton.hide();
				// Если игра не завершена, попросим подтверждения покинуть стол
				let res = 'leave';
				if( !this.viewProtocol && this.inprogress && !this.gameInfo.tourcompleted && !this.isFastSitout?.() ) {
					// await askConfirm( '{Leavetable}?' );
					// else
					res = await (await import( './gametools.js' )).wantsLeave( this.gameInfo.escape );
				}
				if( res==='leave' ) {
					this.send( 'leave' );
					if( !this.isSolo ) {
						this.#wantClose = true;
						this.getSwiper?.close( this.playArea );
					}
				} else if( res=='escape' ) {
					this.send( 'escape' );
				}
			}
		}
		if( this.gameInfo.room )
			fire( 'goroom', this.gameInfo.room.toString() );
	}

	#makeObsolete( hard ) {
		log( 'Making obsolete ' + this.item );
		if( !hard ) {
			this.vgame?.release();
			elephCore?.do( 'type=come out=' + this.item );
		}
		this.roomChat?.release();
		this.roomChat = null;
		this.subTour?.release();
		this.subTour = null;
		hideWindowsByOrigin( this.item );
		// if( this.gameInfo?.room ) goLocation( this.gameInfo.room );
		this.getSwiper?.removePage( this.playArea );
	}

	get inprogress() {
		/*this.gameState &&*/
		return this.gameState!=='finished' && this.gameState!=='notstarted';
	}

	#winResize() {
		if( !this.topPanel ) return;
		if( window.ResizeObserver ) return;
		this.#waitResize = true;

		this.#onresize();
	}

	#onresize( entry ) {
		log( 'Game onresize ' + (++this.#resizeCallCount) );
		if( window.Swiper?.currentGame!==this ) {
			log( 'Game is inactive' );
			return;
		}
		if( !this.topPanel ) return;
		if( this.waitRecalc ) return;
		if( this.isPreview ) return;
		let p = this.topPanel.parentElement;
		if( !p || !p.clientHeight ) return setTimeout( this.#onresize.bind( this ), 500 );
		let slog = 'body: ' + document.body.clientWidth + ' : ' + document.body.clientHeight + '. '
			+ 'playzone: ' + this.playZone.clientWidth + ' : ' + this.playZone.clientHeight;
		if( this.playZone.clientWidth>document.body.clientWidth
			|| this.playZone.clientHeight>document.body.clientHeight ) {
			log( 'Not ready to recalc, wait 1s: ' + slog );
			this.waitRecalc = setTimeout( () => {
				this.waitRecalc = null;
				this.#onresize();
			}, 1000 );
			return;
		}
		this.#waitResize = false;
		if( this.#playZoneSize && this.#playZoneSize.width===this.playZone.clientWidth
			&& this.#playZoneSize.height===this.playZone.clientHeight ) {
			log( 'Size was not changed (' + this.vgame.id + '):' + slog );
			return;
		}
		log( 'Resize for ' + this.vgame.id );
		let fs = +(getComputedStyle( this.playArea ).fontSize.replace( 'px', '' ));
		let maxLines = this.playArea.clientHeight / fs;
		this.tiny = maxLines<25;
		if( this.cards ) {
			this.cards.onresize( entry );
			if( this.playZone.clientWidth<this.cards['unifiedWidth'] * 8 ) this.tiny = true;
		}
		this.playZone.classList.toggle( 'playsize_tiny', this.tiny );
		for( let f of this.#resizeListeners ) f( entry );
		this.#playZoneSize = { width: this.playZone.clientWidth, height: this.playZone.clientHeight };
		this.#resizeCount++;
		setTimeout( this.#onresize.bind( this ), 500 );		// Just for check
	}

	getLocation() {
		if( this.vgame.solo?.location ) return this.vgame.solo.location;
		if( this.gameInfo.location ) return this.gameInfo.location;
		if( !window.PLAYELEPHANT ) return this.item.split( '_' )[1];
		return this.item.replace( '_', '.' );
	};

	async doezd() {
		if( await askConfirm( '🎯 {Doezd}?' ) )
			this.sendMove( 'doezd' );
	}

	async invite( plno ) {
		if( !Number.isInteger( plno ) ) plno = -1;
		if( window.FB ) {
			FB.ui( {
				method: 'apprequests',
				message: localize( `{Letsplay} {${capitalize( this.gameInfo.id )}}` ),
				data: this.item
			}, function( response ) {
				log( 'FB invite: ' + JSON.stringify( response ) );
				// if( response && response.to && response.to.length )
				// 	this.#send( 'inviterequest', response.request );
			} );
			return;
		}

		// В обычном режиме просто укажем ссылку на эту партию
		// let loc = window.location.href;
		let loc = PLAYURL + this.getLocation(),
			max = 0;
		// if( plno>=0 ) loc += '#inviteplace_' + plno;
		log( 'Invite link ' + loc );
		// Если приглашаем по никам (в клубе), то максимум приглашаемых - по числу мест
		if( this.team ) {
			for( let p = this.maxPlayers; p--; )
				if( this.players[p].sitavail && !this.players[p].reserved ) max++;
			if( !max ) {
				toast( '{Unfortunatelly}, {noseats}' );
				return;
			}
		}

		let set = await (await import( './inv.js' )).invite( {
			title: '{Letsplay}',
			text: '{Invitetoplay} ' + this.gameInfo['title'],
			team: this.team,
			apiOptions: {
				needplayers: this.maxPlayers - 1
			},
			max: max,
			url: loc,
			byorigin: this.item,
			skip: [...this.players.map( x => x.user?.itemid )]
		} );
		if( set ) {
			// this.invited ||= new Set;
			// set.forEach( x => this.invited.add( x ) );
			this.#send( 'gameinvite', `place='${plno}' items='${[...set].map( x => x.itemid ).join( ',' )}'` );
		}
	}

	dispatchEvent( e ) {
		this.playArea.dispatchEvent( e );
	}
	
	addResizeListener( func ) {
		this.#resizeListeners.push( func );
		if( this.#resizeCount ) delay( func );
	}
	
	bugReportData( form ) {
		if( this.allCardHolders )
			for( let ch of this.allCardHolders )
				form.append( `ch_${ch.id}`, ch.getDebugStr() );
	}

	#clearAction() {
		if( !this.waitAction ) return;
		this.waitAction = false;
		delay( 'actiondrops' );
	}

	wantsAction( action ) {
		if( this.waitAction ) return;
		// Попробуем издать звук, если мы не активны (переключена вкладка)
		if( document.visibilityState==='hidden' ) {
			log( 'Trying play move sound because hidden' );
			playSound( 'move' );
			if( LOCALTEST ) document.title = '🟢';
		}
		import( './notificator.js' ).then(
			module => module.default.notify( this, action ) );
		this.waitAction = true;
		log( 'WantsAction: ' + action );
		fire( 'wantsaction', this );
		// Проверим не надо ли показать стрелку, которую мы убрали
		this.checkArrow( this.getMyPlace() );
	};

	// == Public members
	get isPayer() {
		return elephCore?.isHallPayer( this.gameInfo.hall );
	}
	
	// == Public methods
	get getName() {
		return 'TBL ' + this.item + '. ' + tableName;
	}
	
	checkLocation() {
		if( this.#maximized )
			elephCore?.setLocation( this.getLocation(), this.gameInfo.title );
	}

	soloMove( type, data ) {
		this.vgame.solo?.move( type, data );
		// fire( 'sendmove', { name: this.item, move: data, type: type || 'move' } );
	}

	send( type, data, reason, title ) {
		let res;
		if( this.isSolo )
			this.soloMove( type, data );
		else {
			if( data?.serverstr ) data = data.serverstr;
			if( typeof data==='object' ) {
				let str = '';
				for( let k in data ) str += ` ${k}=\`${data[k].replaceAll( '`', '' )}\``;
				data = str;
			}
			res = this.#send( type || 'move', data );
			if( (!type || type==='move') ) {
				let me = this.#getMyPlayer;
				if( me ) me.showArrow( false );
			}
		}
		// Сбрасываем ожидание хода
		if( !type || type==='move' )
			this.#clearAction();
		return res;
	}
	
	sendMove( data ) {
		return this.send( 'move', data );
	}
	
	dropMove( data ) {
		elephSubscribe.set( `${this.vgame.id}.move`, '' );
		this.sendMove( data );
	}

	/*
		this.availSits = () => {
			let cc = 0;
			for( let i = 0; i<this.maxPlayers; i++ )
				if( players[i].sitavail ) cc++;
			return cc;
		};
	*/

	sitAny() {
		if( this.isPlayer || !this.isPlayable ) return false;
		let res = this.getMyPlace( true );
		if( res>=0 ) return this.sitRequest( res );
		for( let i = 0; i<this.maxPlayers; i++ ) {
			if( this.players[i].sitavail ) {
				this.sitRequest( i );
				return true;
			}
		}
	}

	sitinClick( e ) {
		let plno = (+e)>=0 ? +e :
			+(e.target.closest( '[data-plno]' )?.dataset.plno);
		if( !this.players[plno] ) return;
		if( !this.players[plno].sitavail ) {
			// Need to show information about player
			import( './userinfo.js' ).then( mod => {
				mod.default( this.players[plno].user, this.gameInfo?.id, {
					chainid: this.gameInfo?.chainid,
					club_id: this.gameInfo?.club_id
				} );
			} );
			return;
		}
		if( this.isPlayer ) {
			return this.invite( plno );
		}
		// Is this game ready for play?
		if( !this.isPlayable ) {
			log( 'Try sit but not playable: ' + JSON.stringify( this.gameInfo ) );
			if( bowser.windows || bowser.mac || bowser.linux ) {
				Window.message( 'offerdownload', 'Sorry not ready yet' );
			}
			return;
		}
		this.sitRequest( plno );
	}

	async sitRequest( plno, reason ) {
		if( !elephCore ) return;
		// Позволим садиться гостям на закрытые столы, где это разрешено
		if( this.gameInfo.allowguest ) {
			// Запрос может сделать и гость, но гостем еще надо стать
			await modules.Auth.checkAuthGuest( {
				game: this.id,
				founder: this.gameInfo.founder,
				force: true
			} );
		} else {
			await elephCore.checkAuth();
		}
		return this.send( reason==='random'? 'sitrandom' : 'sit', plno );
	}

	needLayFor( objects, value ) {
		if( !value ) {
			this.#needlayfor.delete( objects );
		} else {
			if( this.#needlayfor.has( objects ) ) return;
			this.#needlayfor.add( objects );
			delay( () => this.setPositions( objects ) )
		}
	}

	requireLayout( plno, o ) {
		this.#needlay[plno] ||= new Set;
		this.#needlay[plno].add( o );
		this.#objectLayout.set( o, plno );
		this.#toLay.add( o );
		delay( this.#checkToLay.bind( this ) );
	};

	#checkToLay() {
		for( let o of this.#toLay ) {
			let plno = this.#objectLayout.get( o );
			if( plno!==undefined && this.players[plno]?.position )
				this.#setLay( o, this.players[plno].position );
		}
		this.#toLay.clear();
	}

	get getVuln() {
		return this.dealInfo.vuln || '';
	}

	// this.toPicture		= () => this.setHolder( picHolder, false );

	setContract( holder, str, shorty ) {
		if( !holder ) return;
		if( str==='p' ) str = 'pass';
		const gameCodes = {
			t1000: { remiz: '{remiz1000}' },
			king: {
				jacks: '{king_boysjacks}',
				kingsjacks: '{king_boysall}',
				girls: '{girls}',
				tricks: '{tricks}',
				hearts: '{_hearts}',
				king: '👑{_king}',
				last2: '{last2tricks}',
				eralash: '🥧{eralash}'
			}
		};
		const htmlcodes = {
				halfwhist: '½', // '{halfwhist}',
				miser: '{misere}',
				miserbp: '{misere} {nw}',
				pass: '{pass}',
				remiz: '{remiz3}',
				whist: '{whist}',
				double: 'X',
				redouble: 'XX',
				at: '{alltrumps}',
				doreturn: '{widowback}',
				timeout: '⏱',
				'{timeout}': '⏱{timeout}'
			},
			recodes = {
				dotrust: 'pass', plays: 'whist', doself: 'whist',
				x: 'double',
				xx: 'redouble'
			},
			htmlcodes_compact = {
				halfwhist: '{half}',
				at: '{alltrumps_}',
				whistopened: '{whistopened}',
				whistclosed: '{whistclosed}'
			},
			htmlcodes_short = {
				pass: 'p'
			};

		let contentBody = holder.contentBody || holder;

		if( !str ) {
			// holder.hide();
			// holder.textContent = '';
			// holder.removeAttribute( 'code' );
			holder.code = null;
			holder.hide();
			if( !holder.clearTimeoutId ) {
				holder.clearTimeoutId = setTimeout( () => {
					contentBody.textContent = '';
					holder.removeAttribute( 'data-suit' );
					holder.removeAttribute( 'data-code' );
					holder.removeAttribute( 'data-doubled' );
				}, 200 );
			}
			return false;
		}
		if( holder.clearTimeoutId ) clearTimeout( holder.clearTimeoutId );
		holder.clearTimeoutId = null;
		if( str[0]==='!' ) str = str.slice( 1 );
		let prefix = '';
		if( str[0]==='-' || str[0]==='+' ) {
			prefix = str[0];
			str = str.slice( 1 );
		}
		let gCodes = gameCodes[this.gameInfo.type],
			htmlCode = gCodes && gCodes[str] || htmlcodes[str],
			suit,
			body = (shorty || !htmlCode) && htmlcodes_compact[str] || htmlCode,
			bodyNarrow,
			passreg = /pass\/(\d)/.exec( str ),
			html = false,
			doubled = '';
		if( (shorty===true || shorty==='short') && htmlcodes_short[str] ) body = htmlcodes_short[str];
		if( passreg ) {
			body = (shorty ? '{pass}' : '{Allpassby} ') + passreg[1];
			bodyNarrow = `P/${passreg[1]}`;
		}
		if( !body && Lang.hasPhrase( str ) ) body = Lang.getPhrase( str );
		if( !body && str ) {
			if( str.length>1 && str!=='XX' ) str = str.toLowerCase();
			let suitLow = str.charAt( 0 ).toLowerCase();
			if( str.endsWith( '**' ) ) doubled = '**';
			else if( str.endsWith( '*' ) ) doubled = '*';
			if( doubled ) str = str.slice( 0, -(doubled.length) );
			if( htmlsuits[suitLow] && (!str[1] || "0123456789k".includes( str[1] )) ) {
				// Parse max digits after suit
				let level = '', pos = 1;
				for( ; pos<str.length && "0123456789k".includes( str[pos] ); pos++ ) {
					if( str[pos]==='k' ) level = shorty ? 'K' : localize( '{kapo}+' );
					else level += str[pos];
				}
				if( level==='0' ) level = '10';
				if( !shorty && level.slice( -1 )==='+' ) level = level.slice( 0, -1 );
				let txt = localize( atnt[suitLow] || '' );
				// let txt = localize( atnt[suitLow] || '&nbsp;' );
				// body = level + htmlsuits[suitLow] + str.slice( pos );
				body = `<span>${level}</span><span class="suit" data-suit="${suitLow}">${txt}</span>`;
				if( str.slice( pos ) ) body += `<span>${str.slice( pos )}</span>`;
				html = true;
				suit = suitLow;
			} else body = str;
		}
		if( bodyNarrow ) {
			contentBody.html( `<span class='hideinportrait'>${body}</span><span class='portraitonly'>${bodyNarrow}</span>` );
		} else if( html ) contentBody.innerHTML = body;
		else contentBody.setContent( body, this );
		let codeAttr = recodes[str.toLowerCase()] || str;
		if( codeAttr[0]==='&' ) codeAttr = '';
		holder.dataset['code'] = codeAttr;
		if( suit ) holder.dataset.suit = suit;
		else holder.removeAttribute( 'data-suit' );
		if( doubled ) holder.dataset['doubled'] = doubled;
		else holder.removeAttribute( 'data-doubled' );
		holder.show();
		return true;
	};

	setAttr( key, value ) {
		if( this.#attrs[key]===value ) return;
		this.#attrs[key] = value;
		let tracks = [].slice.call( this.playArea.querySelectorAll( '[data-track~="' + key + '"]' ) );
		for( let t of tracks )
			value ? t.setAttribute( key, value ) : t.removeAttribute( key );
		return true;
	};

	littleShowWide( name ) {
		if( this.wideBarMode ) {
			log( 'Switching with always chat' );
			this.showLittle( name );
		} else
			this.littleRecommend = name;
	}

	setChatAlways( val ) {
		if( val===undefined ) {
			// val = this.chat?.holder.parentElement===playArea || ( this.useWideBar && mediaWideChat.matches );
			val = this.useWideBar && mediaWideChat.matches;
		}
		// if( !this.useWideBar ) return;
		if( this.chatAlways===val ) return;
		log( 'Setting alwayschat to ' + val );
		this.chatAlways = val;
		// if( !val && !mediaWideChat.matches ) this.chat?.hide();
		let mchat = this.chat || this.#littles.get( 'editor' );
		mchat?.checkAlwaysVisible();
		this.checkWide();
	}

	floatme() {
		this.getSwiper?.act( this.playArea );
	}

//
// Parser block {
//
	primeroute_game( o ) {
		log( 'game.js parsing game {}' );
		if( typeof o!=='object' ) {
			log( 'Gameinfo bad ' + JSON.stringify( o ) );
			return;
		}
		this.gameInfo = o;
		if( o.id ) this.playArea.dataset.gameid = o.id.toLowerCase();
		if( o.group ) this.playArea.dataset.gamegroup = o.group.toLowerCase();
		this.ext.littleinfo?.icon.hide();
		if( 'title' in o ) this.gameInfo.title = localize( o.title ).replace( 'DEMO', currency( 'DEMO' ) );
		if( 'sides' in o ) this.setMaxPlayers( o.sides );
		// this.positions = [];
		this.bypos = {};
		this.#arrowsymbol = [];
		if( o.type==='domino' ) this.suits = '0123456789ABCDEFGHIJ';
		this.setHandCount( this.solo?.handCount || (o.type==='bridge' ? 13 : 10) );
		let teamid = this.gameInfo.team_id;
		this.team = User.setTeam( teamid );
		this.playZone.dataset.insideclub = teamid || '';
		this.founder = User.set( o.founder );
		// Если нужно изменить размер карт
		this.cube.button.makeVisible( !!o.cube );
		this.cube.str = undefined;
		this.cards?.delayCheckBaseSize();
		this.#reger = o.reger?.toString();
		delay( this.layoutHands.bind( this ) );
		this.playZone.classList.toggle( 'viewproto', !!o.viewproto );
		this.gameType = o.chainid?.split( '-' )[0] || o.group;
		this.#checkPlayers();
		this.#checkPlayMoreReg();

		if( !this.module && !this.modulename && o.group ) {
			this.modulename = o.group;
			this.vgame.setType( o.group );
		}

		if( o.withrobots || this.viewProtocol ) this.extlittleinfo?.icon.hide();

		if( o.baseno ) petitionUrls.set( 'proto', `/protocol/${o['baseno']}` );

		if( o.tour && !o.tour.offline ) {
			petitionUrls.set( 'tour', `/tour/${o.tour.id}` );
			if( !this.tourInfo ) {
				this.tourInfo = construct( '.gametourinfo.control.flexline.center', this.littleIcons, this.tourInfoClick.bind( this ) );
				let emo = o.tour.match && '⚔️' || '🛡️';
				this.tourPlace = html( `<div class='tourplace badge infobadge'><span class='emoji rem2'>${emo}</span><span></span></div>`, null, this.tourInfo );
			}
			this.#checkMyTour();
			this.tourInfo.title = localize( o.tour.name );
			dispatch( 'tourplacingchanged', this.#checkMyTour.bind( this ) );
			// Подпишемся на этот турнир
			this.subTour ||= Subscribe.add( 'event_' + o.tour.id );
			// this.tourPlace
		}
		this.#checkTD();
		dispatch( 'mytdchanged', this.#checkTD.bind( this ) );

		if( this.chat && (o['roomchatid'] || o['roomchatchannel']) )
			this.chat.subscribeItem( o['roomchatchannel'] || o['roomchatid'] );

		this.tvInfo.makeVisible( this.vgame && this.vgame.item.startsWith( 'tv-' ) );

		this.checkKibiAll();
		this.checkTrump();
		this.checkRent();
		this.checkTableName();
		this.checkFirstInvite();
		this.checkFirstGrossInfo();
		this.checkLocation();
		this.#checkBottomChat();
		this.checkLittles();

		/*
				if( !this.viewProtocol && !this.gameInfowithrobots && !this.isbridge && !this.isboard && !this.#littles.has( 'info' ) && !this.ispoker ) {
					promiseGameExtend( 'littleinfo' )?.then( mod => mod.default( this ) );
				}
		*/

		// Subscribe.delayRoute( routes, 'gamechanged' );

		// Если наблюдаем турнир, то автоматически перебрасываем на лучший оставшийся стол
		if( o.tour && !o.tour.offline && this.isboard && !o.tour.match && !this.isPlayer ) {
			if( o.tourcompleted ) {
				log( `Jumping to top table of ${o.tour.id} after 2 seconds` );
				elephCore?.setAutoJump( {
					event: o.tour.id,
					request: 'type=gettoptable data=' + o.tour.id
				} );
			}
		}

		// if( LOCALTEST ) o.teams = [ { tid:1 , title: '{Team} А', icon: '' }, { tid:1 , title: 'Команда Б', icon: '429b250823a84982287470ab13228ef7' } ]
		if( o.teams ) {
			// Cтол командного матча, внутри командного турнира или отдельный. Покажем общий текущий счет
			import( './teammatch.js' ).then( module => {
				if( !this.teamMatch )
					this.teamMatch = new module.default( this );
				this.teamMatch.fillTeams();
			} );
		}

		if( !this.#wantLittle && !this.#lastLittle && mediaWideChat.matches )
			this.#checkWantLittle( 'score' );

		// Check if I've been invited to this table
		this.checkMeInvited();

		fire( 'setroomnow', o.room );

		this.#checkmyplayer();
	}

	setHandCount( count ) {
		if( this.handCount===count ) return;
		this.handCount = count;
		for( let hand of (this.topPanel?.$$( '.cardholder_hand' ) || []) )
			hand.dataset.maxcount = count;
	}

	async #checkmyplayer() {
		if( !this.isSolo && this.isPlayer && !elephCore?.plays[this.item] ) {
			let serv = UIN && await this.send( 'check' ) || {};
			if( serv.errcode /*==='gamenotfound'*/ ) {
				makeObsolete( true );
			}
		}
	}

	checkMeInvited() {
		let place = this.getMyPlace( true );
		if( this.gameInfo.founder!==UIN && this.#myplace<0 && !this.inviteOffered && this.gameInfo?.title && place>=0 ) {
			if( this.players[place].invited )
				import( './inv.js' ).then( mod => mod.inviteGame( this ) );
		}
	};

	route_tv( o ) {
		let names = {
			wait: '{Waiting}', stream: '{Delayedbroadcast}', finished: '{Broadcastisover}'
		};
		this.tvInfo.$( '.subtitle' ).setContent( names[o.phase] || '{Delayedbroadcast}' );
	}

	route_tour( o ) {
		if( o.time ) {
			if( !this.tourTimer ) {
				this.tourTimer = new Timer( {
					stopwatch: true,
					visibility: 'runningdown',
					parent: this.tourInfo
				} )
			}
			this.tourTimer.set( o.time );
		}

	}

	checkTableName() {
		let str = this.gameInfo.title;
		str = str && (str + '. ') || '';
		if( this.dealInfo?.undertitle ) str += this.dealInfo?.undertitle;
		this.playArea.querySelector( '.tablename' )?.setContent( str );
	}

	route_title( str ) {
		this.gameInfo.title = localize( str ).replace( 'DEMO', currency( 'DEMO' ) );
		this.checkTableName();
		document.title = this.gameInfo.title;
	}

	route_inforecommend( str ) {
		this.littleShowWide( str );
	}

	route_infoshow( str ) {
		this.showLittle( str );
	}

	checkArrow( plno ) {
		if( !(+plno>=0 && !+plno<10) ) return;
		// If showing bidbox then no arrow for bidbox
		let vis = this.#arrows.indexOf( plno )>=0;
		if( plno===this.getMyPlace() && this.bidbox?.container.isVisible() )
			vis = false;
		this.players[plno]?.showArrow( vis );
	};

	showCash( plno, value ) {
		this.players[plno].setCash( value );
	}

	route_cash( str ) {
		this.cash = str?.split( ',' );
		for( let i = this.maxPlayers; i--; )
			this.showCash( i, this.cash[i] );
	}

	route_reward( str ) {
		let r = str?.split( ',' );
		for( let i = this.maxPlayers; i--; )
			this.players[i]?.setReward( r?.[i] );
	}

	/*
		this.addParser( 'reentry', str => {
			this.reentry = str;
			if( this.reentry?.includes( this.#myplace ) ) {
				// Можно сделать ребай (реентри). Играть нельзя, поэтому кнопка посередине экрана
				this.reentryButton ||= construct( 'button.default.importantsize.display_none {Reentry}', playZone.$( '.centeredbuttons' ), this.doReentry );
				this.reentryButton.show();
			} else
				this.reentryButton?.hide();
		});
	*/

	doReentry() {
		// Восстановление в турнире
		this.sendMove( 'reentry' );
	}

	route_arrows( str ) {
		this.#arrows = str || '';
		for( let i = this.maxPlayers; i--; )
			this.players[i].showArrow( str.includes( i ) );
	}

// this.addParser( 'wait', parseWait );

	route_waiting( str ) {
		if( !this.isPlayer ) return;
		let waiting = str.includes( this.getMyPlace() );
		if( waiting ) this.wantsAction( 'move' );
		else this.#clearAction();
	}

	/*
		function parseTour( data, minor ) {
			if( minor==='placing' && UIN ) {
				// Определим своё место из формата u[,u]... u[,u]... (блоки одного места разделены пробелом, внутри блока игроки - запятой)
				let s = `[, ]${UIN}[, ]`;
				if( new RegExp( s ).test( ' ' + data + ' ' ) ) {
					// Мы участвуем
				}
			}
		}
	*/
	#checkTD() {
		let tourid = this.getTourid,
			td = tourid && elephCore?.myTD.has( tourid.toString() ) || false,
			op = UIN && this.gameInfo.Operator===UIN,
			canoperate = op || (!this.isPlayer && (td || elephCore?.ADMIN)) || false;
		if( LOCALTEST ) canoperate = true;
		this.canOperate = canoperate;
		this.opPanel?.makeVisible( canoperate );
		this.tdPanel.makeVisible( this.module==='cards' && (td /*|| LOCALTEST*/) );
		this.#checkOpActions();
	}

	#checkOpActions() {
		if( !this.canOperate ) return;
		this.tdPanel.$( '[data-action="skipboard"]' ).makeVisible( !window.SOLO && !['finished', 'notstarted'].includes( this.gameState ) );
		this.tdPanel.$( '[data-action="adjust"]' ).makeVisible( !window.SOLO && !!this.dealInfo );
	}

	#checkMyTour( id ) {
		// Показ места в турнире на иконке
		if( !this.gameInfo?.tour ) return;
		let tourid = this.gameInfo.tour.id;
		if( id && id!==tourid ) return;
		let placestr = elephCore?.tourPlacing.get( tourid.toString() ),
			ar = placestr?.match( /(.*)([\+-].*)/ );
		this.tourPlace.dataset.badge = ar?.[1] || '';
		this.tourPlace.dataset.badgestyle = ar?.[2]?.[0]==='-' && 'good' || '';
	}

	route_move() {
		this.onmove();
	}

	route_cardmove() {
		this.onmove();
	}

	onmove() {
		// Проверим надо ли показать рекламу
		if( !this.needSGB ) return;
		// Перед последней рекламой не менее 10 минут
		// Если короткий контроль, то реклама на 5 секунд
		this.needSGB = false;
		// Если в этой партии это первая реклама, то всегда показываем
		let mininterval = !this.bShowed ? 1 : undefined;
		this.bShowed = true;
		window.gameStartCheckBanner?.( {
			force: true,
			mininterval: mininterval,			// 10min
			maxperiod: this.players[this.#myplace]?.timer.getValue( true )<60000 ? 5000 : 10000
		} );
	}

	route_players( str ) {
		let real = 0,
			uids = str.split( ',' );
		this.setMaxPlayers( uids.length );
		for( let i = this.maxPlayers; i--; ) {
			this.players[i].setUid( uids[i] );
			if( this.players[i].uid ) real++;
		}
		this.realPlayers = real;

		this.#recheckMyplace();
		this.#checkPlayers();

		// Не добавляем эту игру в elephCore.plays 4/04/2023
		// if( this.isPlayer && elephCore && !elephCore.plays[this.item] )
		// 	elephCore.plays[this.item] = {};

		if( this.#wantClose ) {
			if( !real || !this.isPlayer ) {
				this.#makeObsolete();
			}
		}

		if( real===this.maxPlayers )
			this.#inviteWindow?.hide();

		this.chat?.checkInit( 'players' );

		this.checkboardplayerspos();

		// TODO: переделать playZone на Grid, в котором определяются
		// области bottom/left/right/top/, тогда объектам достаточно задавать
		// placeme='bottom' (grid-area: bottom)
		/*
				if( !this.gameInfo.id ) {
					for( let i = this.maxPlayers; i--; ) {
						if( !players[i].elavatar.parentElement ) {
							playZone.appendChild( players[i].elavatar );
							this.requireLayout( i, players[i].elavatar );
						}
					}
				}
		*/
		// При первом открытии игры основателю предлагаем пригласить
		this.checkFirstInvite();
		this.checkMeInvited();
		this.checkGameBrief();
	}

	checkFirstGrossInfo() {
		if( this.gameInfo.gross && !this.firstGrossInfo && this.gameInfo.gross.uin!==UIN && !this.isPlayer ) {
			this.firstGrossInfo = true;
			this.playArea.$( '.grossinfo' ).show();
			import( './fants.js' ).then( mod => {
				mod.rentClick( this );
			} )
		}
	}

	get canInvite() {
		return !window.SOLO && !this.viewProtocol && this.realPlayers<this.maxPlayers && this.isFounder && !this.#wantClose || false;
	}

	checkFirstInvite() {
		if( this.firstInvited ) return;
		if( this.rentNotenough || (this.gameInfo.rent || !this.rentChecked) ) return;			// Пока не хватает аренды не приглашаем
		if( this.canInvite && !this.firstInvited ) {
			this.firstInvited = true;
			this.invite();
		}
	}

	async route_waitinglist( o ) {
		this.playArea.$( '.grossinfo' ).show();
		let mod = await import( './fants.js' );
		mod.waitinglist( this, o );
	}

	async route_grosshistory( o ) {
		(await import( './fants.js' )).grosshistory( this, o );
	}

	route_deal( o ) {
		this.dealInfo = o;
		this.watcher.call( 'dealinfo' );
		this.#checkOpActions();
		// this.ext['auction_center'] && this.ext['auction_center'].checkVulnerability();
		// this.ext['auction_little'] && this.ext['auction_little'].checkVulnerability();
	}

	route_timers( str ) {
		let timers = str.split( ',' );
		for( let i = this.maxPlayers; i--; ) {
			this.players[i].setTimer( timers[i] );
		}
	}

	route_bids( str ) {
		if( !str )
			for( let p of this.players ) p.setBid();
		else {
			let part = str.split( ',' );
			for( let i = 0; i<part.length; i++ ) {
				if( this.players[i]?.setBid( part[i] ) ) {
					this.checkBidExplain( i );
				}
			}
		}
		this.checkMyBubbleAndBid();
	}

	/*
		this.addParser( 'video', o => {
			if( !o && !this.videoHolder ) return;
			if( !this.videoHolder ) {
				this.videoHolder = document.createElement( 'iframe' );
			} 'Video', 'video', playZone );
			this.videoHolder.src = o;
		} );
	*/

	get isClosedHandsInvisible() { return this.isbridge };
	get istv() { return this.vgame && this.vgame.item && this.vgame.item.startsWith( 'tv-' ); }
	get is1000() { return this.gameInfo.type==='t1000' };
	get isbridge() { return this.gameInfo.type==='bridge'; }
	get isbelot() { return this.gameInfo.type==='belot'; }
	get isbazar() { return this.gameInfo.chainid?.includes( 'bazar' ); }
	get ispref() { return this.gameInfo.type?.includes( 'pref' ) || false; }
	get islongauction() { return this.isbridge || this.isbazar; }
	get ispoker() { return this.gameInfo.type?.includes( 'poker' ) || false; }
	get isboard() { return this.gameInfo.group==='board'; }
	get isgammon() { return this.gameInfo.group==='bg'; }
	get iscards() { return this.gameInfo.group==='cards' || this.gameInfo.group==='poker'; }
	get isdomino() { return this.gameInfo.type==='domino'; }
	get isDraughts() { return this.gameInfo.chainid?.includes( 'draughts-' ); }
	get isCorners() { return this.gameInfo.chainid?.includes( 'ugolki-' ); }

	route_state( str ) {
		if( this.gameState===str ) return;
		let oldstate = this.gameState;
		this.gameState = str;
		// this.buttons.rent.makeVisible( str==='notstarted' );
		// if( str==='bidding' && this.isPlayer && this.isbridge ) {
		// Bidding: show centered if kibi, or side if plays
		// this.showLittle( 'auction' );
		// }
		this.#checkCenterAuction();
		this.#checkSwipeOn();
		let phase = { 'whist': '{Whisting}' };
		this.setContract( this.#elphase, phase[str] );

		this.playArea.dataset.phase = str;

		this.#checkPlayMoreVisible();
		this.checkGameBrief();

		// Оператор проверяет кнопки
		this.#checkOpActions();
		this.checkRent();
		this.#checkPlayers();
		// this.playArea.style.setProperty( '--avatar-offset', str==='notstarted'? '1em' : '1px' );
		this.playArea.style.setProperty( '--avatar-offset', '1px' );
		// Интеллектуальные действия
		if( str!=='bidding' && str!=='bidfinal' ) this.bidbox?.hide();
		this.ddSolution?.cancelTransformation();

		// Если демо-рум, и есть свободные места, сразу сядем.
		if( this.gameInfo.room==='demo' && !this.isPlayer && this.gameState==='notstarted' && !oldstate ) {
			log( 'Trying to sit at any place' );
			this.sitAny();
		}
	}

	route_prevfinalphrase( o ) {
		this.#matchScore.holder.title = localize( o, { game: this } );
	}

	route_scoreupdate( o ) {
		this.score = o.split( ',' );
		if( !(this.#myplace>=0) ) return;
		let val = this.score[this.#myplace],
			good = '';
		if( this.ispref ) good = +val>20 && 'good' || +val<-30 && 'bad';
		else return;
		this.players[this.#myplace].updateScore( val, good );
	}

	route_score( o ) {
		this.score = o.split( ',' );
		// If all scores are zero, hide it
		let allZero = this.score.every( el => (+el)===0 ),
			showavtscore = !['bridge','pref'].includes( this.gameInfo['type'] );
		if( showavtscore ) {
			let mshide = this.ispref && 5000;
			for( let i = this.maxPlayers; i--; ) {
				if( this.ispref && this.#myplace!==i ) continue;
				this.players[i].setScore( allZero ? '' : this.score[i], mshide );
			}
		}
		if( this.isbridge ) {
			// Покажем счет в строчке счета
			let val = this.getMyScore;
			if( !this.isPlayer ) val = this.score[0]; // Зрителям в бридже покажем счет NS
			this.scoreLine.setContent( val && `{Score}: ${val}` || '' );
		}
		this.#checkPoolIconBadge();
	}


	// this.addRoute( parser, "main parser" );

	setModule( mod ){
		this.module = mod;
		this.#checkTD();
	}

	setWideModel() {
		this.useWideBar = true;
		mediaWideChat.addListener( () => {
			if( this.chat && !this.chat.input.value ) {
				this.chat?.input.blur();
			}
			log( 'Mediawidechat matches=' + mediaWideChat.matches + ' chatfocused=' + (window.chatInputFocused ? 'yes' : 'no') );
			this.setChatAlways();
			// this.checkLittles();
		} );
		this.setChatAlways();
		this.checkWide();
		this.checkLittles();
	}

	checkLittles() {
		let showLittleIcons = !mediaWideChat.matches || this.#littles.size>=2;
		if( this.isbridge || this.ispoker ) showLittleIcons = true;		// Всегда иконка сдачи
		this.littleIcons.makeVisible( showLittleIcons );
		if( !showLittleIcons && this.wideBarMode ) this.showLittle( 'any' );
	}

	checkboardplayerspos() {
		// boardplayer[inverse ? 1 : 0].setAttribute( 'plno', 'bottom' );
		// boardplayer[inverse ? 0 : 1].setAttribute( 'plno', 'top' )
		// let mp = game.getMyPlace(),
		// 	bottom = mp!==-1? mp : (inverse?1:0);
		if( !this.boardPlayers ) return;
		let bottom = this.getpov;
		if( this.manualRotate ) bottom += this.manualRotate;
		if( this.boardPlayers ) {
			this.boardPlayers[bottom % 2].holder.setAttribute( 'pos', 'bottom' );
			this.boardPlayers[1 - bottom % 2].holder.setAttribute( 'pos', 'top' );
		}
		this.#matchScore.holder.dataset['bottom'] = bottom;
	}

	playerChat( place, msg, hidems ) {
		// if( !this.players[place] ) return;
		this.players[place]?.showChat( msg, hidems );
	}

	async addFrame( frame ) {
		if( !this.protocol )
			(await import( './gameplay.js' )).default( this );
		this.protocol?.addFrame( frame );
	}

	#checkBottomChat() {
		// if( !this.chatBottom ) return;
		// let ratio = this.contentRect.width/this.contentRect.height,
		// 	chatParent = ratio<0.6? this.playArea : statusBar;
		let bottom = mediaBottomChat.matches && !this.gameInfo.withrobots && (this.isgammon() || this.iscards );
		// Если не авторизован, то не показываем нижний чат. Потому что GooglePlay
		// считает, что это UGC, который должен модерироваться
		if( window.mayReview() ) bottom = false;
		let chatParent = bottom ? this.playArea : this.statusBar;
		if( this.chat && chatParent!==this.chat.holder.parentElement ) {
			log( 'Replacing chat, content rect is ' + JSON.stringify( this.contentRect ) );
			chatParent.appendChild( this.chat.holder );
			this.setChatAlways();
			this.chat.holder.classList.toggle( 'display_none', bottom )
			this.chatBottom = bottom;
			if( !bottom ) this.chat.holder.hide();
			else {
				// Сохраним параметр "виден ли нижний чат"
				this.chat[!localStorage.bottomchathidden ? 'show' : 'hide']();
			}
		}
	}

	resizeObserve( entry ) {
		// Если экранная клавиатура видна, никаких пересчетов размеров
		log( 'Resizeobserver chatfocused=' + (window.chatInputFocused ? 'yes' : 'no') );
		// if( window.chatInputFocused ) return;
		this.#onresize( entry );
		this.contentRect = entry.contentRect;
		// Нельзя управлять нижним чатом, так как не можем отследить наличие
		// экранной клавиатуры, которая ломает дизайн (не должна)
		// Observer всегда получает сообщение первым
		// this.checkBottomChat();
	}

	checkMyBubbleAndBid() {
		let place = this.getMyPlace();
		if( place>=0 && (this.#dialog.isVisible() || this.bidbox?.container.isVisible()) ) {
			this.players[place].elbubble.hide();
			this.players[place].elbid.hide();
			this.players[place].elarrow.hide();
		}
	}

	get currency() {
		currency( this.gameInfo.currsymbol );
	}
}

log( 'LOADED: game' );