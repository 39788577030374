cssInject( 'customize' );
cssInject( 'cards' );

import Card from './card.js';
import Lang from './lang.js';

let
	self = {},
	bigwin = makeBigWindow( {
		closeonclick: 'empty'
	} ),
	language = elephLang.language || 'en',
	win = construct( '.customize_window.column', bigwin, onclick ),
	selCards = construct( '.flexline.display_none', win ),
	selBg = construct( '.flexline.display_none', win ),
	selDraughts,
	checkHolder = construct( '.checkholder', win ),
	dayNight = construct( 'span.daynight {Day}/{Night}', checkHolder, changeTheme ),
	soundsCheck = construct( 'span.checkable {Sounds}', checkHolder, soundcheck ),
	autoQueenCheck = construct( 'span.checkable.display_none[data-name=askpromote][data-inverse=1] {Autoqueen}', checkHolder, checkboxclick ),
	boardAutoMove = construct( 'span.checkable.display_none[data-name=noboardautomove][data-inverse=1] {Automove}', checkHolder, checkboxclick ),
	cardOpts = {
		confirmMove: construct( 'span.checkable.display_none[data-name=confirmcardmove] {Confirmcardmove}', checkHolder, checkboxclick ),
		premove: construct( 'span.checkable.display_none[data-name=premove] Premove', checkHolder, checkboxclick )
	},
	trumpLeft = construct( 'span.checkable.display_none[data-name=trumpleft] {Trumponleft}', checkHolder, checkboxclick ),
	selLang = html( `<span class='graybutton emoji' style='align-self: start'>${languageEmo[language]}${language}</span>`, win, langClick ),
	selected = {},
	props = { 'deck': {}, 'bg': {}, figset: {} };


/*
	if( !window.coreParams?.nobugreporticon ) {
		let bug = construct( 'span.checkable[data-name=bugreporticonvisible] Show bugreport icon', checkHolder, checkboxclick );
	}
*/


html( `<span class='control grayhover closebutton icon righttop'
				  style='width: 2rem; height: 2rem; background-image: url(${IMGEMBEDPATH}/svg/icons/highlight_off_black_24dp.svg)' data-closeselect='close'></span>`, win );
	setThemeCaption();

	if( window.NOLOCALSTORAGE ) {
		html( `<span style='color: red; font-weight: bold; padding: 0.4em'>{Localstorageunavailable}</span>`, win );
	}

// Lang.fillSelect( selLang, 'collapse' );

for( let type of Card.deckNames ) {
	let card = Card.makeCard( 'dk', type );
	card.classList.remove( 'fade' );
	props['deck'][type] = card;
	Card.setFixedHeight( card, 100 );
	card.classList.add( 'visible' );
	let cardHolder = construct( selCards/*, onclick*/ );
	cardHolder.dataset['deck'] = type;
	cardHolder.appendChild( card );
}

let bgTypes = ['gray', 'billiard', 'default77', 'canva', 'galaxy', 'aqua'];

for( let type of bgTypes ) {
	let demo = construct( '.use_bg.background_demo', selBg/*, onclick*/ );
	props.bg.type = demo;
	demo.dataset.bg = type;
}

async function langClick() {
	// Выбор языка по клику
	let mod = await import( './usersview.js' );
	let [ win, view ] = mod.Usersview.makeSelectWindow( {
		id: 'selectlanguage',
		title: '🌐 {Language}',
		filterplaceholder: '{Search}...'
	});
	if( !view.count ) {
		let ar = [...allLanguages].map( x => {
			return {
				id: x,
				name: `${x}`,
				picture: `${IMGPATH}/flags/svg/${elephLang.getCountry(x)}.svg`
			}
		} )
		view.setUsers( ar );
	}
	await cssInject( 'customize' );
	let res = await win.promiseShow();
	elephLang.setLanguage( res );
}

function check( group, value ) {
	let element = props[group][value];
	if( selected[group] && selected[group]!==element ) {
		selected[group].classList.remove( 'selected' );
		selected[group] = null;
	}
	if( !selected[group] ) {
		selected[group] = props[group][value];
		if( selected[group] ) selected[group].classList.add( 'selected' );
	}
}

function checkSelected() {
	check( 'deck', elephCore.globalAttr['deck'] );
	check( 'bg', elephCore.globalAttr['bg'] );
	// check( 'figset', elephCore.globalAttr['figset'] );
}

function onclick( e ) {
	let t = e.target;
	let fix = t.dataset.deck;
	if( fix ) {
		if( !elephCore.setAttr( 'deck', fix ) ) return;
		Card.setDefaultDeck( fix );
		fire( 'newdeck' );
		checkSelected();
		// При клике на колоду сразу закроем окно, чтобы посмотреть
		bigwin.hide();
		return;
	}
	let bg = t.dataset.bg;
	if( bg ) {
		if( !elephCore.setAttr( 'bg', bg ) ) return;
		checkSelected();
		// При клике на фон сразу закроем окно, чтобы посмотреть
		bigwin.hide();
		return;
	}
	let figset = t.dataset.figset;
	if( figset ) {
		if( !elephCore.setAttr( 'figset', figset ) ) return;
		checkSelected();
		// При клике на фон сразу закроем окно, чтобы посмотреть
		bigwin.hide();
		return;
	}
}

function setThemeCaption() {
	let fro = {
		light: ['☀️', '{Day}' ],
		dark: [ '🌚', '{Night}' ],
		auto: [ '🌓', '{Day}/{night}: {auto}' ]
	}[localStorage.screentheme || 'auto'];
	dayNight.setContent( fro[1] );
	dayNight.dataset.before = fro[0];
}

function changeTheme( e ) {
	const themes = [ 'light', 'dark', 'auto' ],
		themenow = localStorage.screentheme || 'auto',
		next = themes[(themes.indexOf( themenow )+1)%themes.length];
	localStorage.screentheme = next;
	document.body.dataset.theme = next;
	setThemeCaption();
}

function soundcheck( e ) {
	let soundsOn = !elephCore.globalAttr.sounds;
	elephCore.setAttr( 'sounds', soundsOn );
	soundsCheck.classList.toggle( 'checked', elephCore.globalAttr.sounds );
	elephCore.playSound( 'move' );
}

function checkboxclick( e ) {
	let name = e.target.dataset.name;
	if( !name ) return;
	// let global = elephCore.globalAttr[name];
	// if( global===undefined ) global = e.target.dataset['default'];
	// let val = !global;
	let newchecked = !e.target.classList.contains( 'checked' ), val = newchecked;
	if( e.target.dataset['inverse'] ) val = !val;
	elephCore.setAttr( name, val );
	e.target.classList.toggle( 'checked', newchecked );
	fire( 'optionchanged', { name: name, value: val } );

/*
	if( name==='bugreporticonvisible' ) {
		$( '#bugreporticon' )?.makeVisible( val );
	}
*/
}

function setInitial( el ) {
	let name = el.dataset['name'],
		val = !!elephCore.globalAttr[name];
	if( el.dataset.inverse ) val = !val;
	el.classList.toggle( 'checked', val );
}

export default function show( game ) {
	checkSelected();
	soundsCheck.classList.toggle( 'checked', !!elephCore.globalAttr.sounds );
	trumpLeft.classList.toggle( 'checked', !!elephCore.globalAttr.trumpleft )
	for( let o of bigwin.$$( '[data-name]' ) )
		setInitial( o );
	// o.classList.toggle( 'checked', !!elephCore.globalAttr[o.dataset.name] );
	if( !NEOBRIDGE && !FANTGAMES ) {
		let boardOpts = !game || game.gameInfo['group']==='board';
		autoQueenCheck.makeVisible( boardOpts );
		boardAutoMove.makeVisible( boardOpts );
	}
	let cards = 'cards poker'.includes( game?.gameInfo.group ),
		bg = game?.playZone.$( '.use_bg' );
	selCards.makeVisible( cards );
	for( let k in cardOpts )
		cardOpts[k].makeVisible( cards && game.gameInfo.group==='cards' );
	trumpLeft.makeVisible( game?.isbridge || false );
	bigwin.show();
	selBg.makeVisible( !!bg );

	if( game?.isDraughts || game?.isCorners /*|| game?.isgammon()*/ ) {
		cssInject( 'fig_simple' );
		// Настройки для шашек: сложная SVG картинка или упрощенная на CSS
		selDraughts ||= html( `<div class='flexline start display_none' style='gap: 0.5em'>
			<div class='control' data-figset='default'><div data-figset='default' data-figcode='ws' style='width: 3em; height: 3em;'></div></div>
			<div class='control' data-figset='simple'><div data-figset='simple' data-figcode='ws' style='width: 3em; height: 3em'></div></div>
			</div>`, win );
		selDraughts.show();

	} else selDraughts?.hide();
}
