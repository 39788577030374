import Invitations from './invitation.js';

let myinvitation;

export default class Myinvitation {
	constructor() {
		this.panel = makeBigWindow(
			`<div class='column' data-request='invitation'>
			<div class='rem subtitle'></div>
			<div class='flexline'>
			<input class='font150' autofocus placeholder='{Selectnick}' autocomplete='off' list='lastpartners' name='username'>
			<button class='font150' data-name='inputuser'>Ok</button>
			</div>
			<datalist id='lastpartners'></datalist>
			<div class='margintop2em subtitle recent column'>
			</div>
			<div class='margintop2em subtitle revoke column'>
			</div>
			<button class='fade getshare'>Share</button>
			</div>` );
		this.panel.dataset.hideonrequest = true;
		this.title = this.panel.$( '.subtitle:first-of-type' );
		this.input = this.panel.$( 'input' );
		this.share = this.panel.$( '.getshare' );
		this.form = this.panel.$( '[data-request]' );
		this.datalist = this.panel.$( 'datalist' );
		this.recent = this.panel.$( '.recent' );
		this.revoke = this.panel.$( '.revoke' );
		this.share.onclick = this.shareClick.bind( this );
		this.form.onclick = this.click.bind( this );
		this.form.onkeydown = this.keydown.bind( this );
		this.revoke.onclick = this.revokeInvite.bind( this );
		// this.panel.beforeSubmit = this.beforeSubmit.bind( this );
		// this.panel.onsubmit = this.submit.bind( this );
	}

	getSubmitUser( el ) {
		// Корректируем value на INPUT
		let inputval = this.input.value,
			val = this.fast && this.fast.get( inputval ) || inputval;
		return el.dataset.value || val;
	}

	keydown( e ) {
		if( e.key!=='Enter' || e.target.tagName!=='INPUT' ) return;
		this.invite( e );
	}

	click( e ) {
		let t = e.target;
		if( t.tagName!=='BUTTON' ) return;
		this.invite( e );
	}

	async invite( e ) {
		let t = e.target;
		let val = this.getSubmitUser( t );
		if( val ) {
			e.stopPropagation();
			if( window.WEBSOCKET ) {
				let jres = await window.elephCore.do( `type=invite invtype=${this.type} tourid=${this.tourid} user="${encodeURI(val)}" action=make` );
				if( !jres || !jres.error )
					this.panel.hide();
			} else {
				let jres = await API( '/invitation', {
					origin: this.type,
					user: val
				} );
				if( !jres.error ) this.panel.hide();
				if( t.dataset.name==='inputuser' ) this.input.value = '';
				if( jres.invitations ) {
					setInvitations( jres.invitations );
					this.fillFast();
				}
			}
		}
	}

	shareClick() {
		if( this.shareURL ) {
			if( navigator.clipboard )
				navigator.clipboard.writeText( this.shareURL )
					.then( () => toast( 'Copied to clipboard' ),
						() => {
						} );
		}
	}

	init( type ) {
		this.type = type;
		let inv = Invitations.findAccepted( type );
		if( inv ) return;
		let ar = type.split( '.' );
		let kind = ar[0];
		this.tourid = ar[1];
		this.panel.dataset['origin'] = type;
		this.datalist.innerHTML = '';
		this.input.value = '';
		this.recent.hide();
		// let title = element.dataset['makeinvitetitle'];
		this.title.setContent( kind.includes( 'pair' )?
			'{Chooseplayertopair}' : '{Invite}' );

		this.shareURL = null;
		this.fast = null;
		this.fastnames = null;
		this.share.hide();
		this.panel.show();
		this.prepare();
		if( !this.fast ) 
			this.input.focus();
	}

	async prepare() {
		if( !this.prepared ) {
			let jres = await API( '/inviteprepare', {
				origin: this.type
			} );
			if( !jres || jres.error ) return;
			this.prepared = jres;
		}
		let jres = this.prepared;
		if( jres.share ) {
			this.shareUrl = DOMAIN + jres.share;
			this.share.show();
		}
		this.fillFast();
	}

	fillFast() {
		let str = '', fast = 0, faststr = '';
		this.fast = this.fastnames = null;

		if( this.prepared?.fast ) {
			this.fast = new Map;
			this.fastnames = new Map;
			for( let k in this.prepared.fast ) {
				// Может быть такое приглашение от меня уже есть
				let f = this.prepared.fast[k],
					fname = f.name || '',
					showval = `${f.nick} ${fname}`,
					strid = this.type + '.' + UIN + '.' + f.uin;
				fast++;
				if( Invitations.find( strid ) ) {
					// Приглашение от меня есть
					continue;
				}
				str += `<option value='${showval}'>`;
				this.fast.set( showval, f.uin );
				this.fastnames.set( f.uin, `${f.name} (${f.nick})` );
				let user = User.set( f.uin );

				if( fast<3 ) {
					faststr += `<div class='sheet flexline spacebetween invitation' data-name='fastuser'>
				${fillPlayerHTML( user )}
				<button data-name="fastuser" data-value="${f.uin}">Invite</button>
				 </div>`;
				}
			}
		}
		this.datalist.innerHTML = str;
		if( faststr ) {
			this.recent.innerHTML = `
				<span>Recent partners</span>
				${faststr}`;
		} else {
			this.recent.innerHTML = '';
		}
		// this.recent.show();
		// Отменить отправленные приглашения
		let invs = Invitations.getOfType( this.type ), invstr = '';
		if( invs ) {
			for( let inv of invs ) {
				if( !inv.mine ) continue;
				if( !invstr ) invstr = '<span>{Revokeinvitations}</span>';
				invstr += `<button data-cancelid="${inv.id}" class="graybutton">{Revoke} ${inv.recipient.nick}</button>`;
			}
		}
		this.revoke.innerHTML = localize( invstr );
		// this.revoke.classList.toggle( 'visible', this.revoke.children.length>1 );
	}

	async revokeInvite( e ) {
		let id = e.target.dataset['cancelid'];
		if( !id ) return;
		e.stopPropagation();
		Invitations.drop( id );
		this.input.focus();
		if( WEBSOCKET ) {
			window.elephCore.do( `type=invite id=${id} action=revoke` );
			this.fillFast();
		} else {
			let jres = await API( '/invitecancel', { id: id } );
			if( jres.error ) return;
			e.target.parentElement.removeChild( e.target );
			if( jres.invitations ) setInvitations( jres.invitations );
			this.fillFast();
		}
	}

	static async concedeClick( e ) {
		if( e.target.tagName!=='BUTTON' ) return;
		e.stopPropagation();
		this.concede.hide();
		let jres = await API( '/concede', this.concedeReq );
		if( jres && jres.invitation )
			setInvitations( jres.invitations );
	}

	static make( type ) {
		// Возможно, зарегистрироаны, тогда остается сняться
		let reg = window.Invites?.findAccepted( type );
		if( reg ) {
			if( !this.concede ) {
				this.concede = makeBigWindow();
				this.concede.onclick = this.concedeClick.bind( this );
			}
			this.concede.innerHTML = `<div class='column'>
				<span>You are registered with ${reg.partNick}</span>
				<button class="margintop2em">{Concede}</button>
				 </div>`;
			this.concedeReq = { tourid: reg.dataid, part: reg.partuin };
			this.concede.show();
			return;
		}
		myinvitation ||= new Myinvitation();
		myinvitation.init( type );
	}
}

dispatch( 'invitationschanged', () => {
	myinvitation?.fillFast();
});
