//
// Модуль обрабатывает JSON-протокол партии и управляет игрой
// эмуляция игры без сервера
//

/*
 Предложение по протоколу:
 	[ <TYPE> <ACTION> <DATA>
 	; this is comment
 	hand 1 s7s8h3
 	move 1 a7
 	+356 floptrick
 	bid 2 pass
 	fork alter
 	state bidding
 	move 2 oo
 	stop
 	#alter
 */
import './support.js';
import Vgame from "./vgame.js";
import Subscribe from "./subscribe.js";

let jsonMap = {};
for( let i=10; i--; ) jsonMap[ 'hand'+i ] = 'hand ' + i;
let map0 = [ 'hand' ],
	gameDef = { 'bridge': { solo: true, sides: 4, type: "bridge", group: "cards", id: "BRIDGE", icon: "bridge", pairs: 1, title: "{_Bridge}" } };

/*
function json2str( data ) {
	let str = '; Parsed from JSON\n';
	for( let d of data ) {
		let t = d[0];
		if( t===-1 ) continue;		// Общие слова
		if( typeof t==='number' ) {
			str += d[3] + ' ' + t + ' ' + d[1] + '\n';
			continue;
		}
		let otype = jsonMap[t] || t;
		if( otype )
			str += otype + ' ' + d[1] + '\n';
		// if( t===this.playerPos ) break;
	}
	return str;
}
*/

function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

dispatch( 'fetched', res => {
	let r = Ruler.find( res.name );
	if( r )
		r.goData( res.data );
	else
		r = new Ruler( res.name, res.data );

});

let all = new Map;
export default class Ruler {
	static find( name ) { return all.get( name ); }

	static set( name ) {
		return all.get( name ) || new Ruler( name );
	}

	get countBoards() { return this.boards.length; }

	get boardNumber() {
		if( this._boardNumber ) return this._boardNumber;
		this._boardNumber = +(localStorage[ this.name+'_board' ]) || 1;
		return this._boardNumber;
	}

	set boardNumber( value ) {
		if( this._boardNumber===value ) return;
		this._boardNumber = value;
		localStorage[ this.name + '_board' ] = value;
	}

	constructor( name, data ) {
		this.name = name;
		all.set( name, this );
		this.initSend();
		this.vgame = new Vgame(
			{ id: name, type: 'cards', solo: true, autoshow: true } );
		this.goData( data )
	}

	async goData( data ) {
		if( !data ) {
			let result = await fetch( bridgeAPI + '/memget/' + this.name );
			if( !result.ok ) {
				log( 'Failed fetch data for ruler ' + this.name );
				return
			}
			data = await result.text()
		}
		if( !data ) return;
		this.inprogress = false;
		this.precompile( data );
		this.goBoard( this.boardNumber );
		if( this.countBoards ) {
			// Add icon of this quest to home screen
			this.shortCut = construct( '.shortcut ' + data.title );
			Subscribe.add( '_addshortcut', this );
		}
	}

	precompile( data ) {
		this.gameInfo = gameDef['bridge'];
		let lines = data.split( '\n' ).map( f => f.trim() );
		this.boards = [];
		this.strict = true;
		let board;
		for( let i=0; i<lines.length; i++ ) {
			let line = lines[i];
			while( i+1<lines.length && lines[i+1][0]==='/' ) {
				line += ' ' + lines[i + 1].slice( 1 );
				i++;
			}
			if( !line || line[0]===';' ) continue;
			if( line[0]==='#' || line[0]==='?' )
				line = line.toLowerCase().replace( /(\d)([scdhn])/g, '$2$1');
			let cmd = line.split( ' ', 1 )[0];
			if( cmd==='title' ) {
				[ , this.title ] = line.match( /\S+\s*(.*)/ );
				continue;
			}
			if( !board || cmd==='board' ) {
				board = [];
				this.boards.push( board );
			}
			if( board ) board.push( line );
		}
	}

	getTitle() {
		let ttl = this.title || '';
		if( this.boardNumber && this.boards.length>1 ) {
			if( ttl ) ttl += '. ';
			ttl += '{Deal} ' + this.boardNumber + '/' + this.boards.length;
		} else {
			if( !ttl ) ttl = '{Problem}';
		}
		return ttl;
	}

	async goBoard( n ) {
		if( n-1>=this.boards.length ) return;
		this.boardNumber = n;
		let stor = localStorage[this.name+'_board_'+n];
		this.boardInfo =  stor && JSON.parse( stor ) || {
			attempts: 0,
			fails: 0,
			misses: 0,
			done: false
		};
		let lines = this.boards[n-1];
		this.auctions = new Map;
		this.hand = [];
		this.main = { name: null, lines: [], data: {} };
		let lastAuction = this.main, questAuctionLine = '';
		for( let i=0; i<lines.length; i++ ) {
			let line = lines[i];
			if( !line || line[0]===';' ) continue;
			if( line[0]==='?' ) line = questAuctionLine + ' ' + line.slice( 1 ).trim();
			if( line[0]==='#' ) {
				lastAuction = { name: line.slice( 1 ), lines: [], data: {} };
				this.auctions.set( lastAuction.name, lastAuction );
				if( line.slice(-1)==='?' ) questAuctionLine = line.slice( 0, -1 ).trim();
			} else {
				lastAuction.lines.push( line );
				if( lastAuction===this.main ) this.doline( line, true );
			}
		}
		let dealInfo = JSON.stringify( this.gameInfo );
		this.send( `.game ${dealInfo}` );
		this.clearTable();
		this.dealCards();
		await this.teacherNotice( this.getTitle(), 2000 );
		this.godeal();
		// Check auctions. Whole biddings should be the same from same
	}

	nextBoard() {
		if( !this.haveMoreBoards ) {
			this.boardNumber = this.boardNumber + 1;
			return;
		}
		this.goBoard( this.boardNumber+1 )
	}

	get haveMoreBoards() {
		return this.boardNumber<this.boards.length;
	}

	clearTable() {
		this.send( `.bids ,,,
			.bigstate
			.dialog
			.auction
			.video
			.bidbox` );
	}

	godeal() {
		this.bids = ['', '', '', ''];
		this.countBids = 0;
		this.bidLine = '';
		this.position = 0;
		this.fork = this.main;
		this.mymove = [];
		this.hand = [];
		this.minbid = 'c1';
		this.leftPasses = 4;
		this.next = this.opener;
		this.inprogress = true;
		this.auction = '';
		let dealInfo = JSON.stringify( this.gameInfo );
		this.send( `.state started` );
		this.clearTable();
		this.sendBids();
		this.dealCards();
		this.increaseStat( 'attempts' );
		this.resume( 1000 );
	}

	dealCards() {
		for( let i=4; i--; ) {
			this.send( '.' + i + '_hand 13' );
		}
	}

	setHand( plno, cards ) {
		this.hand[plno] = cards;
		if( this.inprogress )
			this.send( '.' + plno + '_hand ' + cards );
	}

	async teacherNotice( text, waitms ) {
		this.send( `.bigstate { text: '${text}', author: 'teacher' }` );
		if( waitms ) {
			await sleep( waitms );
			this.send( '.bigstate' );
		}
	}

	async doBid( bid ) {
		const bidmap = {
			pass: 'p', x: 'double', dbl: 'double'
		};
		bid = bidmap[bid.toLowerCase()] || bid;

		if( this.next===this.me && !this.getNextBids().includes( bid ) ) {
			this.increaseStat( 'misses' );
			await this.teacherNotice( 'Заявка не по системе. Попробуйте еще раз', 1000 );
			this.goBid();
			return;
		}

		this.auction += this.next + bid + ',';

		if( this.countBids===4 ) this.send( '.infoshow auction' );
		const bidShow = { p: 'pass' };
		if( bid==='p' ) this.leftPasses--; else this.leftPasses = 3;
		this.bids[this.next] = bidShow[bid] || bid;
		this.bidLine += (this.bidLine? ' ' : '' ) + bid;
		this.countBids++;
		if( bid!=='p' ) {
			this.lastBidPos = this.next;
			this.lastBid = bid;
		}
		// Switch to next
		this.next = (this.next+1)%4;
		if( this.leftPasses ) this.bids[this.next] = '';
		this.sendBids();
		this.flush();
		let spos = 'cdhs'.indexOf( bid[0] );
		if( spos>=0 ) {
			this.minbid = 'dhsn'[spos] + bid[1];
		}
		else if( bid[0]==='n' ) this.minbid = 'c' + (+bid[1]+1);
		await sleep( 1000 );
		this.resume();
	}

	stop( msg, ok ) {
		this.inprogress = false;
		let dialog = 'replay';
		this.increaseStat( ok? 'done' : 'fails' );
		if( ok ) {
			dialog = this.haveMoreBoards? 'continuereplay' : 'completereplay';
		}
		this.send( `.state finished
		 	.dialog ${dialog}` );
		if( msg ) this.send( '.bigstate ' + (ok? 'OK. ':'') + msg );
		log( 'Stopped: ' + (msg||'no reason'));
		log( JSON5.stringify( this.boardInfo ) );
	}

	getNextBids() {
		let len = this.bidLine.length;
		this.nextBids = [];
		for( let [name, _] of this.auctions ) {
			if( !name.startsWith( this.bidLine ) ) continue;
			let bid = name.slice( len ).trim().split( ' ', 1 )[0];
			if( !this.nextBids.includes( bid ) )
				this.nextBids.push( bid );
		}
		return this.nextBids;
	}

	goBid() {
		if( !this.inprogress ) return;
		if( !this.leftPasses ) {
			log( 'Auction is complete, goto bidline ' + this.bidLine );
			this.goto( this.bidLine );
			return;
		}
		if( this.auctions.has( this.bidLine ) ) {
			log( 'Auction is incomplete but found stops ' + this.bidLine );
			this.goto( this.bidLine );
			return;
		}
		if( !(this.next>=0) ) return;
		if( this.next===this.me ) {
			this.playFor = this.me;
			let nextbids = this.getNextBids(),
				only = nextbids.join(',');
			let spec = '';
			if( this.strict ) {
				if( nextbids.includes('p') ) spec += 'pass,';
				if( nextbids.includes('x') ) spec += 'double,';
				if( nextbids.includes('xx') ) spec += 'redouble,';
			} else {
				spec = 'pass';
				if( this.lastBid && this.lastBidPos%2!==this.playFor%2 && this.lastBid!=='xx' )
					spec += ',' + (this.lastBid==='x'? 'redouble' : 'double' );
			}
			this.send( `.bidbox { only: '${only}', type: 'bridge', skipexplain: true, first: '${this.minbid}', spec: '${spec}' }` );
			return;
		}
		// Find nearest bid. It should be only one choice
		let len = this.bidLine.length,
			bids = this.getNextBids();
		if( bids.length>1 ) {
			log( 'Different bids: ' + currentLine + ' =!= ' + name );
			// this.stop( 'Error' );
			return;
		}
		if( !bids.length ) {
			// log( 'Not found any possible bids: ' + this.bidLine );
			if( this.bidLine ) 	return this.goto( this.bidLine );
			this.stop( 'Торговля зашла в тупик' );
			return;
		}

		this.doBid( bids[0] );
	}

	goto( forkname ) {
		let fork = this.auctions.get( forkname );
		if( !fork ) {
			this.stop( 'Fork ' + forkname + ' not found' );
			return;
		}
		if( this.fork===fork ) {
			this.stop( 'Торговля завершена' );
			return;
		}
		this.fork = fork;
		this.position = 0;
		log( 'Switched to fork ' + forkname );
		this.resume();
	}

	async doeval( str ) {
		let fork = this.goto.bind( this ),
			mybid = this.myLastBid;

		let func = new Function( 'fork, bid, move', str );
		func( fork, this.mymove['bid'], this.mymove['move'] );
	}

	async resume( ms ) {
		// Split data into lines
		for( ; this.position<this.fork.lines.length; ) {
			let line = this.fork.lines[this.position++];
			let n = this.doline( line );
			// if( n==='break' ) break;
			// if( n>0 ) await this.sleep( n );
		}
		this.flush();
		if( ms ) await this.sleep( ms );
		this.goBid();
		// if( this.position>=this.lines.length ) {
		// 	log( 'Function ' + this.fork.name + ' is done' );
		// }
	}


	doline( line, constants ) {
		if( !line || line[0]===';' ) return;
		log( 'Parsing line ' + line );
		if( line[0]==='.' ) {
			// Lets eval it!
			this.doeval( line.slice( 1 ) );
			return;
		}
		let [ full, type, _,params, param1, param2 ] =
			line.match( /(\S+)(\s+((\S+)\s*(.*)))?/ );
		type = type.toLowerCase();
		if( !constants ) {
			switch( type ) {
				case 'fail': this.stop( params ); break;
				case 'ok': this.stop( params, true ); break;
				case 'message':
					this.send( `.bigstate ${params}` );
					return;
				case 'video':
					this.send( `.video ${params}` );
					return;
			}
		}
		let plno = +param1;
		if( param1 && param1.length===1 ) {
			let pos = 'neswNESW'.indexOf( param1 );
			if( pos>=0 ) plno = pos%4;
		}

		if( constants ) {
			if( type==='opener' ) {
				this.opener = plno;
				return;
			}
			if( type==='board' ) {
				this.opener = plno;
				if( param2 ) {
					this.gameInfo['vuln'] = { 'ns': '02', 'ew': '13', 'all': '0123' }[param2.toLowerCase()];
				}
				return;
			}
			if( type==='me' ) {
				this.me = plno;
				if( param2 ) this.setHand( plno, param2 );
				return;
			}
			return;
		}

		if( type==='me' ) {
			if( param2 ) this.setHand( plno, param2 );
			return;
		}
		if( type==='hand' ) {
			this.setHand( plno, param2 );
			return;
		}
		if( type==='bid' ) {
			this.bids[plno] = param2;
			this.sendBids();
			return 1000;
		}
	}

	move( str ) {
		log( 'Move received: ' + str );
		let ar = str.split( ' ' ),
			type = ar[0];
		this.mymove[type] = ar[1];
		if( type==='bid' && this.playFor>=0 ) {
			this.doBid( ar[1] );
			// this.doline( 'bid ' + this.playFor + ' ' + ar[1] );
			// this.bidLine += ar[1] + ' ';
			return;
		}
		if( type==='replay' ) {
			this.godeal();
			return;
		}
		if( type==='continue' ) {
			this.nextBoard();
			return;
		}
		this.next = (this.next+1)%4;
		this.resume();
	}

	initSend() {
		this.str = 'SET ' + this.name + '.\n'; // + ".game { type: 'cards', sides: 4 }\n";
	}

	sendBids() {
		this.send( `.bids ${this.bids.join( ',' )}
					.auction ${this.auction}\n` );
	}

	send( line ) {
		this.str += line + '\n';
		this.flush();
	}

	sleep( ms ) {
		this.flush();
		return sleep( ms );
	}

	flush() {
		if( !this.str ) return;
		fire( 'fromserver', this.str );
		this.str = '';
	}

	storeBoard() {
		localStorage[ this.name + '_board_' + this.boardNumber ] = JSON.stringify( this.boardInfo );
	}

	increaseStat( key ) {
		if( this.boardInfo.done ) return;
		this.boardInfo[key]++;
		this.storeBoard();
	}
}

dispatch( 'sendmove', data => {
	if( !data.move ) return;
	let ruler = Ruler.find( data.name );
	if( ruler ) ruler.move( data.move );
});