/*
	Selecting tournament for playing bridge with friends
	Constant options: 'Random boards'
 */

export async function selectTour( game ) {
	let mod = await import( './usersview.js' );
	let [ win, view ] = mod.Usersview.makeSelectWindow( {
		id: 'selecttournament',
		title: '{Whatareweplaying}',
		filterplaceholder: '{Search}...',
		filterapi: '/getreplaytourlist'
	});
	view.add( {
		id: 'tour_random',
		name: '{Randomboards}',
		picture: '-',
		popular: 10
	} );
	API( '/getreplaytourlist', {
		game: 'bridge'
	} ).then( firstset => {
		firstset?.ok && view.append( firstset.data );
	});
	let res = await win.promiseShow();
	if( res==='cancel' || res==='close' ) return;
	return res;
}