/***
 * Auction. Shows little auction table
 */
/*
let
	resizeObserver = window.ResizeObserver && new ResizeObserver( entries => {
		for( let entry of entries )
			entry.target.resizeObserve( entry );
	});
*/


export default class AuctionView {
	constructor( game, params ) {
		this.game = game;
		this.holder = construct( '.display_none.hidewhenminifyed.auction_box', this.clickMe.bind( this ) );
		this.auctionTable = createappend( 'auction_table', this.holder );
		params = params || {};
		if( params.type==='little' ) {
			this.icon = construct( '.control.grayhover.invertdark.auction_icon.wideonly @{Auction}' );
			game.installLittle( 'auction', this, true );
			// lang.setText( auctionHead, '{Auction}' );
		} else {
			game.getTopPanel.appendChild( this.holder );
			this.holder.classList.add( 'centered' );
			this.holder.classList.add( 'lightborder' );
			// this.holder.classList.toggle( 'visible', game.gameState==='bidding' );
			this.centered = true;
			if( window.narrowPortraitMedia.addEventListener )
				window.narrowPortraitMedia.addEventListener( 'change', this.checkMicro.bind( this ) );
			else
				window.narrowPortraitMedia.addListener( this.checkMicro.bind( this ) );
			game.centerAuction = this;
			// this.holder.resizeObserve = this.resizeObserve.bind( this );
			// resizeObserver?.observe( this.holder );
			this.holder.style.transaction = 'transform 0.2s';
		}
		// this.holder.setAttribute( 'columns', this.columns );
		// this.auctionTable.setAttribute( 'columns', this.columns );

		this.game.watcher.watch( 'dealinfo', this.checkVulnerability.bind( this ) );

		this._route = {};
		this.game.addRoute( this );

		this.clear();
		this.game.onlayout.add( this.onLayout.bind( this ) );
		this.checkShow();
	}

	route_bidexplain( o ) {
		// Пояснения заявок в бидбоксе
		for( let i = 1; i<o.length; i += 2 ) {
			o[i] = localize( o[i] );
		}

		this.bidExplain = o;
		this.checkBiddingExplain();
	}

	route_onebidexplain( o ) {
		let b = this.allBids[o.bidno];
		if( !b ) return log( 'Bid in auction not found' );
		b.explanation = o.explanation.replaceAll( '//', '\n' );
		this.setExplain( b.element, b.explanation );
		if( this.lastBids[b.plno]===b )
			this.setLastBidExplain( b.plno, true );
	}

	route_auctionpreview() {
		if( this.centered )
			this.show( true, 5000 );
	}

// resizeObserve( entry ) {
		// If center auction intersects with bottom cardhand (portrait-bridge)
		// translate the auction upper
		// if( !entry.contentRect.width ) return;
/*
		let myrect = this.holder.getBoundingClientRect(),
			bottomhand = this.game.cardHolder?.[this.game.getpov].holder,
			handrect = bottomhand?.getBoundingClientRect();
		if( myrect.width && handrect ) {
			let y_over = myrect.bottom - handrect.top;
			if( y_over>0 ) {
				this.holder.style.transform = `translateY( ${-y_over}px )`;
			} else {
				this.holder.style.transform = ``;
			}
		}
*/

	// }

	clickMe( e ) {
		let bid = e.target.closest( '.bid' );
		if( bid ) {
			// В бридже игрок может добавить объяснение для любой своей заявки
			if( this.game.isbridge ) {
				// Может задать описание для заявки
				this.showExplain( +bid.dataset.bidno );
			}
		}
		if( !this.centered ) this.game.hideLittle();
	}

	bidClick( plno, code ) {
		// First find this bid
		let bid = this.lastBids[plno];
		if( code && bid?.bid!==code ) {
			log( `Wrong code for last bid. TODO: show bids natural style from auction` );
			return;
		}
		if( bid )
			this.showExplain( bid );
	}

	clear() {
		// allBids = [];
		if( this.columns!==this.game.maxPlayers ) {
			this.columns = this.game.maxPlayers;
			this.holder.setAttribute( 'columns', this.columns );
			this.auctionTable.setAttribute( 'columns', this.columns );
		}
		this.allBids = [];
		this.lastBids = [];
		this.bidCount = 0;
		this.bidPlr = 0;
		this.rounds = 0;
		this.waitBid = false;
		this.manualVisible = false;
		// nextPos = game.getpov;
		this.nextPos = this.game.isbridge? (this.game.getpov+1)%this.columns : this.game.getpov;
		this.lastStr = '';
		this.auctionTable.innerHTML = '';
		this.auctionTable.classList.add( 'empty' );
		this.lastElement = null;
		this.roundStarter = null;
		// nicks = [];
		this.titles = [];
		this.counts = [];
		let winds = ["", "N", "NS", "SWE", "NESW"][this.columns] || "1234567890";
		construct( '.gridheadline', this.auctionTable );
		for( let i = 0; i<this.columns; i++ ) {
			// nicks.push( addBid( winds[nextPos], 'side' ) );
			this.titles[this.nextPos] = this.addBid( this.game.arrowSymbol(this.nextPos) || winds[this.nextPos], 'side', true );
			// titles[nextPos] = bid;
			// nicks.push( bid );
		}
		this.checkVulnerability();
		this.checkShow();

		this.roundStarter = null;
	}

	get isEmpty() {
		return !this.lastStr;
	}

	onLayout() {
		// Позиция нижнего игрока всегда справа. Нужно не менять стрелочки,
		// а менять содержимое таблицы
		// for( let idx in this.titles )
		// 	this.titles[idx].textContent = this.game.arrowSymbol(idx);
		let str = this.lastStr;
		this.clear();
		this.parse( str );
	}

	addBid( bid, classes, title ) {
		if( bid==='' ) return;	// Dont show spinner
		this.waitBid = false;
		let el = this.lastElement?.nextSibling;
		if( !el )
			el = construct( /*'.fade' +*/ ( classes && '.'+classes || '.suitcode.bid'), this.auctionTable );
		else {
			if( classes ) el.classList.add( classes );
		}
		if( this.nextPos===this.roundStarter ) {
			this.rounds++;
			if( this.rounds===2 ) {
				// Switch on to auction when second round
				if( this.game.isPlayer )
					this.game.littleShowWide( 'auction' );
			}
		}
		if( bid ) {
			if( title )
				el.setContent( bid );
			else
				this.game.setContract( el, bid, true );
			// el.classList.add( 'visible' );
			el.classList.remove( 'waiting' );
			this.auctionTable.classList.remove( 'empty' );
			if( this.counts[this.nextPos] )
				this.counts[this.nextPos]++;
			else
				this.counts[this.nextPos] = 1;
		}
		// allBids.push( [ nextPos, bid ] );
		el.dataset.plno = this.nextPos;
		this.nextPos = (this.nextPos + 1) % this.columns;
		this.lastElement = el;
		this.bidCount++;
		return el;
	}

	checkVulnerability() {
		let vuln = this.game.getVuln;
		// if( nicks.length<columns ) {
		// 	return;
		// }
		for( let i = 0; i<this.columns; i++ ) {
			// nicks[i].classList.toggle( 'vulnerable', vuln.includes( pos ) );
			this.titles[i]?.classList.toggle( 'vulnerable', vuln.includes( i ) );
		}
	}

	realBid( bid, plr ) {
		if( plr!==undefined ) this.bidPlr = plr;
		let newbid = { index: this.allBids.length, element: this.addBid( bid ), plno: this.bidPlr, bid: bid };
		newbid.element.dataset.bidno = this.allBids.length;
		this.allBids.push( newbid );
		this.lastBids[this.bidPlr] = newbid;
		this.bidPlr = (this.bidPlr + 1) % this.columns;
	}

	parseCompact( str ) {
		for( let mc = 10; this.nextPos!==this.bidPlr && mc--; ) this.addBid();
		for( let i = 0; i<str.length; i++ ) {
			let bid = {
				p: 'pass',
				d: 'double',
				r: 'redouble'
			}[str[i]];
			if( bid ) {
				this.realBid( bid );
				continue;
			}
			if( !isNaN( str[i] ) ) {
				this.realBid( str[i + 1] + str[i] );
				i++;
				continue;
			}
		}
	}

	parse( str ) {
		// game.showLittleIcon( 'auction', !!str );
		if( !str ) {
			this.clear();
			return;
		}
		if( str[0]==='!' ) {
			this.parseCompact( str.slice( 1 ) );
		} else {
			let source = str;
			if( str.startsWith( this.lastStr ) ) {
				str = str.slice( this.lastStr.length );
			} else
				this.clear();
			this.lastStr = source;
			// if( !isNaN(parseInt(lastStr.slice(-1))) ) lastStr += lastStr.slice( 0,-1 );
			let bids = str.split( ',' );
			for( let b = 0; b<bids.length; b++ ) {
				let bid = bids[b];
				if( bid[0]>='0' && bid[0]<='9' ) {
					this.bidPlr = +bid[0];
					this.waitBid = true;
					bid = bid.slice( 1 );
				}
				if( !bid ) continue;
				this.waitBid = false;
				if( this.roundStarter===null ) {
					this.rounds = 0;
					this.roundStarter = this.bidPlr;
				}
				for( let mc = 10; this.nextPos!==this.bidPlr && mc--; ) this.addBid();

				// if( !bid && bidPlr==game.getpov ) continue;
				this.realBid( bid, this.bidPlr );
			}
			if( str.slice( -1 )===',' ) this.waitBid = true;
		}
		if( this.lastElement ) {
			if( this.lastElement.scrollIntoViewIfNeeded )
				this.lastElement.scrollIntoViewIfNeeded();
			else
				this.lastElement.scrollIntoView( { behavior: 'smooth', block: 'end' } );
		}
		this.checkBiddingExplain();
		this.checkShow();
	}

	hide() {
		if( !this.holder.hide() ) return;
		// Close children (explanation) windows
		window.$( `#bidexplain.visible` )?.hide();
		if( this.centered )
			this.game.playZone.dataset.centerauctionvisible = '';
		if( this.#timeoutId )
			clearTimeout( this.#timeoutId );
		this.#timeoutId = null;
	}

	#timeoutId;
	show( value, hidetimeout ) {
		if( value===undefined ) value = true;
		if( !value ) return this.hide();
		if( value && cssMustWait( 'cards', this.show.bind( this ) ) ) return;
		this.holder.show();
		if( this.centered )
			this.game.playZone.dataset.centerauctionvisible = value? '1' : '';

		if( hidetimeout ) {
			this.#timeoutId = setTimeout( this.hide.bind( this ), hidetimeout );
		}
	}

	toggleShow() {
		// Manual toggle show by user click
		this.manualVisible = !this.manualVisible;
		this.checkShow();
	}

	checkShow() {
		if( this.centered /* && !game.isPlayer */ ) {
			let s = ( this.game.gameState==='bidding' ) &&
				( ( this.game.tightly && this.allBids.length>0 && this.game.isbridge )
					|| this.rounds>=2		// Второй и далее круг торговли
					|| (this.rounds===1 && this.waitBid && !!this.lastBids[this.bidPlr]) );
			// Если показывать бидбокс не надо, а он показан, то проверим надо ли убрать его именно сейчас
			if( !s && this.game.islongauction && this.game.lastEvent==='auctiondone' ) {
				// Если нет ни одной карты, не делаем ничего. до первого хода торговля не повредит
				s = true;
			}
			if( !this.game.inprogress ) s = false;
			if( this.manualVisible ) s = true;
			// if( this.game.gameState==='bidding' && ( this.game.isPreview ) ) s = true;
			if( this.holder.isVisible()!==s ) {
				this.show( s );
				this.game.needCheckObjects();
			}
			this.checkMicro();
			// Если показан центральный блок, то не надо показывать play_bid (лишняя информация, которая может еще и наложиться)
			// Однако проблема присутствует только в узких режимах и при открытых руках
			// for( let el of this.game.playArea.$$( '.play_bid' ) )
			// 	el.classList.toggle( 'nodisplay', s );
		}
	}

	checkMicro() {
		this.holder.classList.toggle( 'micro', narrowPortraitMedia.matches && this.game.tightly || false );
	}

	checkBiddingExplain() {
		this.bidExplain ||= [];
		let myplace = this.game.getMyPlace();
		for( let i = 0; i<this.bidExplain.length - 1; i += 2 ) {
			let bidNo = this.bidExplain[i],
				expl = this.bidExplain[i + 1],
				bid = this.allBids[bidNo];
			if( !bid ) break;
			if( myplace>=0 && (myplace + 2) % 4===bid.plno ) {
				// Partner's explanation
				expl = undefined;
			}
			if( bid.explanation===expl ) continue;
			bid.explanation = expl;
			if( !bid.element ) break;
			this.setExplain( bid.element, expl );
		}
		for( let p = this.columns; p--; )
			this.setLastBidExplain( p, true );
	}

	setExplain( bidel, expl, explel ) {
		if( !bidel ) return;
		let alert = false;
		if( expl && expl[0]==='!' ) {
			expl = expl.slice( 1 );
			alert = true;
		}
		bidel.classList.toggle( 'alert', alert );
		bidel.classList.toggle( 'hasexplanation', !!expl );
		if( expl ) {
			// bidel.dataset['explain'] = expl;
			if( explel ) {
				explel.setChat( expl );
				explel.classList.toggle( 'long', expl.length>10 );
			}
			else
				bidel.title = expl;
		} else {
			if( explel )
				explel.textContent = '';
			else
				delete bidel.title;
		}
	}

	setLastBidExplain( plno, force ) {
		let plr = this.game.players[plno];
		if( !plr ) return;
		let bid = plr.bid,
			el = plr.bidBody;
		if( !el || !bid ) return;
		let expl = this.lastBids[plno]?.bid===bid
			&& this.allBids[this.lastBids[plno].index]?.explanation;
		if( !expl && !force ) return;
		this.setExplain( plr.elbid, expl, plr.bidExplain )
	}

	async showExplain( data ) {
		// Open window for explanation bid, appending alerts, etc.
		// Alert is not applyable
		// Explanation is always expanding
		// in SOLO mode everything always changing
		// this.#askExplainPanel ||= html( `<div></div>` );
		let bid = data;
		if( typeof data==='number' )
			bid = this.allBids[data];
		if( !bid ) {
			log( `Not found bid ${data} for explanation` );
			return;
		}
		let current = bid.explanation?.replace( /^!/, '' ) || '',
			canchange = this.game.canPlayfor( bid.plno ) && !this.game.solo?.ispuzzle;

		let body;
		if( canchange ) {
			// Remove ${current} from value. Only adding
			body = `<pre style='font-size: 1rem; margin: 0; color: var(--light_555)'>${current}</pre><input required style='font-size: 1.5rem; padding: 0.3em' 
			placeholder='${current&&'{Additional}'||'{Explanation}'}' data-name='explanation' value=''></input>
				<button class='default importantsize' data-checkvalidity='1' style='align-self: center' data-closeselect='ok'>Ok</button>`;
		} else {
			body = `<pre>${current}</pre>`;
			// If web can detect explanation of the bid then explain it
			if( !current && this.game.isRealPlayer && !this.game.isSolo )
				body += `<button class='default importantsize' data-closeselect='pleaseexplain'>{Pleaseexplain}</button>`;
		}

		let win = makeBigWindow( {
			repeatid: 'bidexplain',
			parent: this.game.playZone,
			html: `<div class='column' data-validityform='1' style='position: relative; align-items: stretch; padding: 0.5em 1em; gap: 0.5em; min-width: 7em'>
				<-close->
				<span class='title flexline center'><span class='suitcode bid' style='font-size: 2rem'></span></span>
				<input type='hidden' name='bid' value='${bid.bid}' />
				<input type='hidden' name='bidno' value='${bid.index}' />
				${body} 
				</div>`
		});
		this.game.setContract( win.$( '.bid' ), bid?.bid );
		let res = await win.promiseShow(),
			expl = collectParams( win, { serverstr: true } );
		if( res==='ok' ) {
			// Задано пояснение к заявке
			this.game.send( 'addexplain', expl )
		} else if( res==='pleaseexplain' ) {
			this.game.send( 'pleaseexplain', expl )
		}
	}

}

