import createBoardPlayers from "./boardplayer.js";

// http://localhost:63342/navigator/www/gambler.html?game=draughts-100&gameno=467237571&tour=1015243&p=33-28.19-23.28-19.14-23.35-30.10-14.32-28.23-32.37-28.4-10.34-29.20-24.30-19.13-24-33-22&nn=868941:%D0%9C%D0%B0%D1%80%D0%BA_%D0%90%D0%BD%D1%82%D0%BE%D0%BD%D0%B8%D0%B9:::RU&rating0=1580&sn=1342417:Gleb2009:a5b59e30298d1a4c49754d404eacdff7::RU&rating1=1571

cssInject( 'board' );
cssInject( 'fig_simple' );

if( !window.ResizeObserver )
	log( 'NO ResizeObserver' );

let nFigsCreated = 0,
	adjusts = 0,
	boardResizeObserver = window.ResizeObserver && new ResizeObserver( entries => {
		if( adjusts>=300 ) {
			return;
		}
		adjusts++;
		for( let entry of entries ) {
			if( entry.contentRect.width!==entry.contentRect.height ) {
				log( `${adjusts} Adjusting board width (${entry.contentRect.width}) to height = ${entry.contentRect.height}`, 'warn' );
				setTimeout( () => {
					let el = entry.target;
					if( getComputedStyle( el ).getPropertyValue( 'aspect-ratio' )==='1 / 1' ) return;	// Объект сам себя масштабирует
					// if( !LOCALTEST )
					// entry.target.style.width = entry.contentRect.height + 'px'
				}, 0 );
			}
		}
	} );

class Fig {
	constructor( game, classname, onclick ) {
		this.game = game;
		if( !classname ) classname = 'moving';
		this.holder = construct( 'div.fig.' + classname, game.mainboard, onclick );
		this.holder.fig = this;
		this.color = this.type = '';
	}

	hide() {
		this.holder.classList.add( 'display_none' );
	}

	setType( type ) {
		this.type = type;
		this.check();
	}

	setColor( color ) {
		this.color = color;
		this.check();
	}

	invertColor() {
		this.setColor( this.color==='w'? 'b' : 'w' );
	}

	setSrc( ft ) {
		if( this.symbol===ft ) return;
		this.color = ft===ft.toUpperCase() ? 'w' : 'b';
		this.type = ft.toLowerCase()
		if( this.game.boardType!=='s' && this.type==='s' ) this.type = 'o';
		this.check();
	}

	check() {
		let str = this.color + this.type;
		if( this.str===str ) return;
		this.str = str;
		this.symbol = this.color==='w' ? this.type.toUpperCase() : this.type;
		// this.holder.style.backgroundImage =
/*
		if( !LOCALTEST ) {
			this.holder.src =
				str?.length===2 ? IMGEMBEDPATH + `/svg/figures/${str}.svg` : '';
		}
*/
		this.holder.dataset.figcode = this.str;
	}

	// Adding style for simple figures (svg-inplace). Imported from lidraughts
	insertStyle() {
		/*
		.man_white {background-image:url('data:image/svg+xml;base64,PHN2ZyB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjEwIiBoZWlnaHQ9IjIxMCI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJsZyI+PHN0b3Agc3R5bGU9InN0b3AtY29sb3I6IzdmN2Y3ZjtzdG9wLW9wYWNpdHk6MSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3R5bGU9InN0b3AtY29sb3I6I2ZmZjtzdG9wLW9wYWNpdHk6MSIgb2Zmc2V0PSIuNSIvPjxzdG9wIHN0eWxlPSJzdG9wLWNvbG9yOiNmZmY7c3RvcC1vcGFjaXR5OjEiIG9mZnNldD0iMSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxwYXRoIGQ9Ik0xMCAxNDBjMCA2MCAxOTAgNjAgMTkwIDB2LTM1SDEweiIgc3R5bGU9ImZpbGw6dXJsKCNsZykgI2ZmZjtzdHJva2U6IzAwMDtzdHJva2Utd2lkdGg6MztzdHJva2Utb3BhY2l0eTouNjUiLz48cGF0aCBkPSJNMTAgMTA1YzAgNjAgMTkwIDYwIDE5MCAwIDAtNjAtMTkwLTYwLTE5MCAweiIgc3R5bGU9ImZpbGw6I2ZmZjtzdHJva2U6IzAwMDtzdHJva2Utd2lkdGg6MztzdHJva2Utb3BhY2l0eTouNjUiLz48L3N2Zz4=')}
		.king_white {background-image:url('data:image/svg+xml;base64,PHN2ZyB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjEwIiBoZWlnaHQ9IjIxMCI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJsZyI+PHN0b3Agc3R5bGU9InN0b3AtY29sb3I6IzdmN2Y3ZjtzdG9wLW9wYWNpdHk6MSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3R5bGU9InN0b3AtY29sb3I6I2ZmZjtzdG9wLW9wYWNpdHk6MSIgb2Zmc2V0PSIuNSIvPjxzdG9wIHN0eWxlPSJzdG9wLWNvbG9yOiNmZmY7c3RvcC1vcGFjaXR5OjEiIG9mZnNldD0iMSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxwYXRoIGQ9Ik0xMCAxNDBjMCA2MCAxOTAgNjAgMTkwIDB2LTM1SDEweiIgc3R5bGU9ImZpbGw6dXJsKCNsZykgI2ZmZjtzdHJva2U6IzAwMDtzdHJva2Utd2lkdGg6MztzdHJva2Utb3BhY2l0eTouNjUiLz48cGF0aCBkPSJNMTAgMTA1YzAgNjAgMTkwIDYwIDE5MCAwVjcwSDEweiIgc3R5bGU9ImZpbGw6dXJsKCNsZykgI2ZmZjtzdHJva2U6IzAwMDtzdHJva2Utd2lkdGg6MztzdHJva2Utb3BhY2l0eTouNjUiLz48cGF0aCBkPSJNMTAgNzBjMCA2MCAxOTAgNjAgMTkwIDAgMC02MC0xOTAtNjAtMTkwIDB6IiBzdHlsZT0iZmlsbDojZmZmO3N0cm9rZTojMDAwO3N0cm9rZS13aWR0aDozO3N0cm9rZS1vcGFjaXR5Oi42NSIvPjxwYXRoIGQ9Ik01OCA0NSA3MSA4NWg2OGwxMy00MC0yNSAyNS0yMi0zNUw4MyA3MHoiIGZpbGw9IiNiZjhjMTYiLz48cGF0aCBkPSJNNzQgOTYgNzEuNiA4N0gxMzguNEwxMzYgOTZ6IiBmaWxsPSIjYmY4YzE2Ii8+PGVsbGlwc2UgZmlsbD0iI2ZmZiIgY3g9IjEwNSIgY3k9IjY4IiByeD0iNSIgcnk9IjEwIi8+PC9zdmc+')}
		.man_black {background-image:url('data:image/svg+xml;base64,PHN2ZyB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjEwIiBoZWlnaHQ9IjIxMCI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJsZyI+PHN0b3Agc3R5bGU9InN0b3AtY29sb3I6IzAwMDtzdG9wLW9wYWNpdHk6MSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3R5bGU9InN0b3AtY29sb3I6IzAwMDtzdG9wLW9wYWNpdHk6MSIgb2Zmc2V0PSIuNSIvPjxzdG9wIHN0eWxlPSJzdG9wLWNvbG9yOiM5ZjlmOWY7c3RvcC1vcGFjaXR5OjEiIG9mZnNldD0iMSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxwYXRoIGQ9Ik0xMCAxNDBjMCA2MCAxOTAgNjAgMTkwIDB2LTM1SDEweiIgc3R5bGU9ImZpbGw6dXJsKCNsZykgIzAwMDtzdHJva2U6I2RmZGZkZjtzdHJva2Utd2lkdGg6MztzdHJva2Utb3BhY2l0eTouNSIvPjxwYXRoIGQ9Ik0xMCAxMDVjMCA2MCAxOTAgNjAgMTkwIDAgMC02MC0xOTAtNjAtMTkwIDAiIHN0eWxlPSJmaWxsOiMwMDA7c3Ryb2tlOiNkZmRmZGY7c3Ryb2tlLXdpZHRoOjM7c3Ryb2tlLW9wYWNpdHk6LjUiLz48L3N2Zz4=')}
		.king_black {background-image:url('data:image/svg+xml;base64,PHN2ZyB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjEwIiBoZWlnaHQ9IjIxMCI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJsZyI+PHN0b3Agc3R5bGU9InN0b3AtY29sb3I6IzAwMDtzdG9wLW9wYWNpdHk6MSIgb2Zmc2V0PSIwIi8+PHN0b3Agc3R5bGU9InN0b3AtY29sb3I6IzAwMDtzdG9wLW9wYWNpdHk6MSIgb2Zmc2V0PSIuNSIvPjxzdG9wIHN0eWxlPSJzdG9wLWNvbG9yOiM5ZjlmOWY7c3RvcC1vcGFjaXR5OjEiIG9mZnNldD0iMSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxwYXRoIGQ9Ik0xMCAxNDBjMCA2MCAxOTAgNjAgMTkwIDB2LTM1SDEweiIgc3R5bGU9ImZpbGw6dXJsKCNsZykgIzAwMDtzdHJva2U6I2RmZGZkZjtzdHJva2Utd2lkdGg6MztzdHJva2Utb3BhY2l0eTouNSIvPjxwYXRoIGQ9Ik0xMCAxMDVjMCA2MCAxOTAgNjAgMTkwIDBWNzBIMTB6IiBzdHlsZT0iZmlsbDp1cmwoI2xnKSAjMDAwO3N0cm9rZTojZGZkZmRmO3N0cm9rZS13aWR0aDozO3N0cm9rZS1vcGFjaXR5Oi41Ii8+PHBhdGggZD0iTTEwIDcwYzAgNjAgMTkwIDYwIDE5MCAwIDAtNjAtMTkwLTYwLTE5MCAweiIgc3R5bGU9ImZpbGw6IzAwMDtzdHJva2U6I2RmZGZkZjtzdHJva2Utd2lkdGg6MztzdHJva2Utb3BhY2l0eTouNSIvPjxwYXRoIGQ9Ik01OCA0NSA3MSA4NWg2OGwxMy00MC0yNSAyNS0yMi0zNUw4MyA3MHoiIGZpbGw9IiNiZjhjMTYiLz48cGF0aCBkPSJNNzQgOTYgNzEuNiA4N0gxMzguNEwxMzYgOTZ6IiBmaWxsPSIjYmY4YzE2Ii8+PGVsbGlwc2UgZmlsbD0iIzAwMCIgY3g9IjEwNSIgY3k9IjY4IiByeD0iNSIgcnk9IjEwIi8+PC9zdmc+')}
		*/
	}
}

function figCheck( fig ) {

}

function figCreate( game, wantedsym ) {
	if( !game.boardFigs ) game.boardFigs = [];
	let f;
	if( wantedsym ) {
		let idx = game.boardFigs.findIndex( x => x.symbol===wantedsym );
		if( idx>=0 ) {
			f = game.boardFigs[idx];
			game.boardFigs.splice( idx, 1 );
		}
	}
	if( !f ) f = game.boardFigs.pop();
	if( !f ) {
		f = new Fig( game, 'moving' );
		f.holder.style.display = 'none';
		f.myfig = true;
		f.cellNumber = -1;
		nFigsCreated++;
	}
	return f;
}

class Promote {
	constructor( game ) {
		log( 'Creating upgrade' );
		this.figs = {};
		this.game = game;
		this.cover = construct( '.upgradeglass', this.click.bind( this ) );
		for( let k of ["q", "n", "b", "r"] ) {
			let f = new Fig( game, 'upgrade', this.click.bind( this ) );
			f.setType( k );
			this.figs[k] = f;
		}
		game.mainboard.insertBefore( this.cover, game.mainboard.firstChild );
		this.waiting = false;
		/*
				let s = 'Promote construct: ';
				for( let k in this.figs )
					s += ` ${k}=${this.figs[k].dataset['type']}`;
				log( s );
		*/

	}

	setColor( color ) {
		for( let k in this.figs )
			this.figs[k].setColor( color );
	}

	start( ok, cancel ) {
		log( 'Starting promote' );
		this.minClickMs = Date.now() + 300;
		this.waiting = true;
		// for( let k in this.figs )
		// 	this.figs[k].style.display = 'initial';
		this.game.mainboard.classList.add( 'upgradenow' );
		this.onPromote = ok;
		this.onCancel = cancel;
	}

	click( e ) {
		log( 'Promote click: ' + (Date.now() - this.minClickMs) + 'ms' );
		if( Date.now()<this.minClickMs ) {
			log( 'Repeated click skipped ' );
			return;
		}
		let fig = e.target?.fig;
		this.end();
		if( fig ) {
			log( 'Promoting into ' + fig.type );
			if( this.onPromote ) this.onPromote( fig.type );
		} else {
			log( 'Promote canceled' );
			if( this.onCancel ) this.onCancel();
		}
		return false;
	}

	end() {
		if( !this.waiting ) return;
		log( 'End of upgrade' );
		this.waiting = false;
		this.game.mainboard.classList.remove( 'upgradenow' );
	}
}

class Board {
	#onresizeBind = this.onresize.bind(this);
	#cellclickBind = this.#cellclick.bind(this);
	#setlefttopnowBind = this.setlefttopNow.bind(this);
	#last;
	#markerLast;
	#markers = new Set;
	#boardbuttons;
	
	constructor( game ) {
		game.module = 'board';
		game.dragInfo.type = 'fig';
		//	var chessfigtables = { 'n':9822,'k':9818, 'b':9821, 'r':9820, 'q':9819, 'p':9823 }
		//	var boardletters = "abcdefgh"
		this.startPos = [];
		this.type = null;
		this.width = 0;
		this.cells = [];
		this.lastCheck;
		this.cellWidth = 0;
		this.inverse = false;
		this.ONcellclick = false;
		this.passAvailable = false;
		this.useAutoCompletion = false;
		this.useFastMove = false;
		this.autoCompletion = false;
		this.waitResize = false;
		this.moveData;
		this.allowCells;
		this.denyCells;
		this.animationQueue = [];
		this.promote;
		this.game = game;
		this.figs = [];
		game.board = this;
		game.setWideModel();
		this.panel = construct( '.boardarea.squareboard' );
		elephCore?.track( this.panel, 'figset' );
		//panel.style.display = 'none';
		this.panel.innerHTML = localize(
			`<div class='board'>
			<svg xmlns="http://www.w3.org/2000/svg">
			   <defs>
			     <marker id="triangle" orient="auto" markerUnits="strokeWidth" markerWidth="4" markerHeight="8" refX="2.05" refY="2.01">
			     <path d="M0,0 V4 L3,2 Z" fill="#15781B"></path>
			     </marker>
			   </defs>
			 </svg>
			 <div class='boardsignature maxvmin100'></div>
			 <div class='mainboard maxvmin100'></div>
			 </div>
			 <div class='boardbuttons'>
			 <div class='display_none passbutton'>{Pass}</div>
			 <div class='drawbutton' title='{Draw}'>
			  <button class='yes' title='{Yes} {draw}'>✅</button>
			  <button class='no' title='{No}'>❌</button>
			 </div>
			 <div class='resignbutton emoji' title='{Resign}'>🏳️</div>
			 <label class='display_none autocomplete'><input type='checkbox' />{Autocomplete}</label>
			 </div>
			` );

		game.playZone.appendChild( this.panel );
		this.board = this.panel.$( '.board' );
		boardResizeObserver?.observe( this.board );
		this.mainboard = this.panel.$( '.mainboard' );
		game.mainboard = this.mainboard;
		this.mainboard.onclick = () => { /* Empty function to avoid empty clicks on board */ }
		let usenotation = localStorage['usenotation'];
		if( usenotation ) this.mainboard.classList.add( 'notation' );
		this.svg = this.board.$( 'svg' );
		this.mainboard.addEventListener( 'mousemove', this.#boardMouseMove.bind( this ) );
		this.mainboard.addEventListener( 'mouseup', this.#boardMouseUp.bind( this ) );
		document.addEventListener( 'keyup', this.#boardKeyup.bind( this ) );
		document.addEventListener( 'keydown', this.#boardKeydown.bind( this ) );
		this.mainboard.addEventListener( 'contextmenu', this.#boardContextMenu.bind( this ), true );
		// this.underboard = this.panel.$( '.underboard' );
		this.#boardbuttons = this.panel.$( '.boardbuttons' );
		this.buttons = {
			pass: this.panel.$( '.passbutton' ),
			draw: this.panel.$( '.drawbutton' ),
			resign: this.panel.$( '.resignbutton' )
		}
		this.autocompleteBox = this.panel.querySelector( '.autocomplete>input' );
		this.buttons.pass.onclick = this.#passclick.bind( this );
		this.buttons.draw.onclick = this.#drawclick.bind( this );
		this.buttons.resign.onclick = this.resignclick.bind( this );
		this.autocompleteBox.onclick = this.autocompleteclick.bind( this );
		game.playZone.classList.add( 'allowmicroview' );
		game.holder.classList.add( 'boardgamearea' );
		game.addResizeListener( this.onresize.bind( this ) );
		this.board.appendChild( game.moveControls );

		// Кнопку Invite перенесем в центр экрана
		// game.playZone.$( '.centeredbuttons' ).appendChild( game.playArea.$( 'button[name="invite"]' ) );

		// game.playZone.appendChild( game.playArea.$( '.needsay' ) );

		let centeredButtons = game.playZone.querySelector( '.centeredbuttons' );
		if( centeredButtons ) this.board.appendChild( centeredButtons );

		game.navi.notation = construct( `button.rotate${usenotation && '.rotated' || ''} A`, game.naviIcons, () => {
			this.mainboard.classList.toggle( 'notation' );
			let usenotation = this.mainboard.classList.contains( 'notation' );
			localStorage['usenotation'] = usenotation ? '1' : '';
			game.navi.notation.classList.toggle( 'rotated', usenotation )
		} );

		this.#parse_off();
		game.setMaxPlayers( 2 );
		createBoardPlayers( game, this.panel );
		window.matchMedia( '(min-aspect-ratio: 17/20)' ).addListener( media => {
			this.panel.classList.toggle( 'onboardplayers', media.matches );
		} )

		narrowPortraitMedia.addEventListener( 'change', this.#checkNarrow.bind( this ) );
		this.#checkNarrow();

		// Перекроем makeSnapshot
		game.parseProto = async ( proto, params ) => {
			(await import( './boardlogic.js' )).parse( this, proto, params );
		};
		game.makeSnapshot = this.#makeBoardSnapshot.bind( this );

		game.reuse = () => {
			// Unset all animations
			this.figs.forEach( function( f ) {
				f?.classList.remove( 'animated' )
			} );
		};

		game.navi.rotate.show();
		game.watcher.watch( 'checkbottom', () => {
			window.skipAnimation = true;
			this.checkInverse();
			window.skipAnimation = false;
		} );

		game.dragInfo.onDrop = ( element, data ) => {
			let fig = element.fig,
				c = data.cellNumber;
			if( !fig || c===undefined ) return;
			if( this.figs[c]===fig ) return;			// Фигура уже здесь
			let from = fig.cellNumber, oldfigsymbol = this.figs[c]?.symbol,
				lineto = (c - c % this.width ) / this.width , promote = '';
			this.moveanimate( { cfrom: from, cto: c } );
			if( this.isChess &&
				fig.type==='p' && (lineto===0 || lineto===7) ) {
				// log( 'Ask promote = ' + elephCore.globalAttr['askpromote'] );
				if( this.game.gameInfo.id.includes( 'GIVE' ) || ( elephCore?.globalAttr['askpromote'] ?? true ) ) {
					// Тестируем превращение
					this.#askUpgrade( fig, type => {
							fig.setType( type );
							this.game.sendMove( from + ':' + c + '!' + type );
							this.#parse_off();
						},
						() => {
							// Отмена хода
							this.moveanimate( { cfrom: c, cto: from } );
							if( oldfigsymbol ) {
								this.setfigat( c, oldfigsymbol );
							}
						} );
					// parse_off();
					return;
				}
				log( 'Autopromote to queen' );
				promote = '!q';
				fig.setType( 'q' );
			}
			let findot = this.useAutoCompletion && this.autoCompletion ? '.' : '';

			this.game.sendMove( from + '-' + c + promote + findot );
			if( !this.useAutoCompletion || this.autoCompletion )
				this.#parse_off()
		};

		dispatch( 'optionchanged', o => {
			if( o.name==='noboardautomove' && this.game.isPlayer ) this.sendOptions( true );
		} );

		this._route = {
			draw: this.setBoardButton.bind( this ),
			resign: this.setBoardButton.bind( this ),
			check: this.setCheck.bind( this ),
			whiteside: this.checkInverse.bind( this )
		};

		game.setTopPanel( this.panel );

		log( 'Board.js adding parser' );
		this.game.addRoute( this, "board parser" );
	}

	#checkNarrow( e ) {
		if( ( e || narrowPortraitMedia ).matches )
			this.game.statusBar.append( this.#boardbuttons );
		else
			this.panel.append( this.#boardbuttons );
	}

	#makeBoardSnapshot( shot ) {
		this.game.baseSnapshot( shot );
		// Добавляем позицию
		let str = '', Acode = 'A'.charCodeAt( 0 );
		for( let i=0; i<this.width*this.width; i++ ) {
			let fig = this.figs[i];
			if( !fig ) str += '.';
			else str += fig.symbol;
		}
		shot.position = str;
		shot.markers = [...this.#markers].join( ',' );
		// shot.bgdices = this.bgdicesstr;
		return shot;
	}

	// Рисование стрелок (может быть в дальнейшем перенесено в модуль игры
	#cancelArrow( reason ) {
		if( reason ) log( 'Cancel arrow. ' + reason );
		if( !this.dragging ) return;
		this.dragging.line.parentElement.removeChild( this.dragging.line );
		this.dragging = null;
	}

	#boardContextMenu( e ) {
		// if( e.button===2 ) {
		let c = 0;
		for( ; ; ) {
			let line = this.svg.$( 'line' );
			if( !line ) break;
			line.remove();
			// line.parentElement.removeChild( line );
			c++;
		}
		this.dragging = null;
		if( c ) {
			e.preventDefault();
			e.stopPropagation();
			return false;
		}
	}

	arrowDone( target ) {
		if( target?.classList.contains( 'cell' ) ) {
			if( target===this.dragging?.start ) return this.#cancelArrow( 'same' );
			log( 'Fix arrow' );
			let r = this.svg.getBoundingClientRect(),
				rt = target.getBoundingClientRect();
			this.dragging.line.setAttributes( {
				stroke: '#15781B',
				x2: ((rt.left + rt.right) / 2 - r.left) * 100 / r.width + '%',
				y2: ((rt.top + rt.bottom) / 2 - r.top) * 100 / r.height + '%'
			} );
			this.dragging = null;
			return;
		}
	}

	#boardMouseUp( e ) {
		if( !this.dragging || e.button!==0 ) return;
		this.arrowDone( e.target );
		if( e.target===e.currentTarget ) {
			// No cell on the way, delete line
			this.cancelDrag( 'Unknown drop' );
			return;
		}
	}

	#boardKeydown( e ) {
		if( e.key==='Backspace' ) {
			let arrow = this.svg.$( 'line:last-child' );
			if( !arrow ) return;
			e.preventDefault();
			e.stopPropagation();
			for( ; arrow; ) {
				this.svg.removeChild( arrow );
				if( !e.metaKey ) break;
				arrow = this.svg.$( 'line' );
			}
			this.dragging = null;
			log( 'Removed last arrow' );
		}
	}

	#boardKeyup( e ) {
		if( this.dragging && (e.key==='Meta')===this.dragging.meta ) {
			log( 'Unpressed META' );
			this.arrowDone( this.dragging.lastCell );
			e.stopPropagation();
			e.preventDefault();
			return;
		}
	}

	#boardMouseMove( e ) {
		// Во время игры должна быть зажата кнопка Ctrl/CMD
		if( this.game.isPlayer && !e.metaKey ) return;
		let nowdrag = e.metaKey || e.buttons===1;		// Либо CTRL либо кнопка мыши
		if( this.dragging && e.metaKey!==this.dragging.meta ) nowdrag = false;
		if( this.dragging && !nowdrag ) {
			// Gone out and came back. Cancel
			return this.#cancelArrow( 'move without button' );
		}
		if( !nowdrag ) return;
		let target = e.target;
		if( !target.className.includes( 'cell' ) ) return;
		e.preventDefault();
		e.stopPropagation();
		let r = this.svg.getBoundingClientRect();
		if( !this.dragging ) {
			this.dragging = {
				start: e.target,
				meta: e.metaKey,
				line: document.createElementNS( 'http://www.w3.org/2000/svg', 'line' )
			};
			let rt = target.getBoundingClientRect();
			this.dragging.line.setAttributes( {
				'stroke': "#15781B",
				'stroke-width': 10,
				'stroke-linecap': 'round',
				'marker-end': "url(#triangle)",
				x1: ((rt.left + rt.right) / 2 - r.left) * 100 / r.width + '%',
				y1: ((rt.top + rt.bottom) / 2 - r.top) * 100 / r.height + '%'
			} );
			this.svg.appendChild( this.dragging.line );
		}
		this.dragging.line.setAttribute( 'x2', e.clientX - r.left );
		this.dragging.line.setAttribute( 'y2', e.clientY - r.top );
		if( target.classList.contains( 'cell' ) ) this.dragging.lastCell = e.target;
	}

	//
	// Methods
	//
	#getfig( wantedsym ) {
		return figCreate( this.game, wantedsym );
	}

	releasecell( cell, animation ) {
		if( !Number.isInteger( cell ) ) {
			bugReport( 'releasecell ' + cell );
			return;
		}
		if( cell<0 ) return;
		let fig = this.figs[cell];
		if( !fig ) return;
		let img = fig.holder;
		if( !fig.myfig ) {
			bugReport( 'Not my fig at cell ' + cell );
			return;
		} else {
			// log( 'pushed fig ' + fig.str );
			this.game.boardFigs.push( fig );
		}
		fig.cellNumber = -1;
		fig.lastTransform = '';
		img.classList.add( animation? 'eated' : 'display_none' );
		img.classList.remove( 'dragged' );
		this.figs[cell] = null;
		this.cells[cell]?.classList.remove( 'hasfig' );
	}

	onresize() {
		this.waitResize = true;
		// log( 'board resize 1 mainboard=' + mainboard );
		if( !this.mainboard || !this.panel.parentElement ) return;
		// log( 'board resize 2 width=' + this.Width );
		if( !this.width ) return;
		log( 'board resize' );
		let p = this.panel.parentElement,
			ph = p.clientHeight,
			pw = p.clientWidth,
			last = this.#last;
		if( !pw || !ph ) return;
		if( last && ph===last.ph && pw===last.pw && this.width===last.width ) {
			log( 'same board size, skipping' );
			// if( LOCALTEST )
			// 	board.style.width = board.clientHeight + 'px';
			return;
		}
		if( LOCALTEST ) {
			/*
						if( board.clientWidth!==board.clientHeight ) {
							log( 'Parent: ' + panel.clientWidth + ' x ' + panel.clientHeight );
							log( 'Board: ' + board.clientWidth + ' x ' + board.clientHeight );
							log( 'Adjusting board width to ' + board.clientHeight );
							board.style.width = board.clientHeight + 'px';
						}
			*/
		}
		this.#last = {
			ph: ph,
			pw: pw,
			width: this.width
		}
		// var h = ph;
		let mind = Math.min( ph, pw );
		let fdy, dy = mind;
		dy -= dy % this.width;
		fdy = dy;
		// var fwstr = fdy + "px", wstr = dy + "px";
		let dy1 = dy / this.width;
		this.cellWidth = dy1;
		// var wstr1 = dy1 + "px";
		let space = ph - fdy;
		// panel.style.width = fdy + 'px'; //fdy / pw * 100 + '%'; // fwstr
		// panel.style.height = fdy + 'px'; //fdy / ph * 100 + '%'; // fwstr
		this.panel.style.fontSize = ((dy1 - dy1 % 5) / 5 * 2) + "px";
		// panel.style.top = space/2 + 'px';
		// panel.style.left = space/2 + 'px';
		this.panel.style.top = 0;
		this.panel.style.left = 0;
		this.panel.classList.remove( 'signed' );
		// let mleft = (fdy-dy)/2 + 'px';
		// mainboard.style.left = mleft;
		// mainboard.style.top = mleft;
		// mainboard.style.width = dy + 'px';
		// mainboard.style.height = dy + 'px';

		// game.chat.holder.style.left = fdy + 10 + 10 + 'px';
		//		game.divChat.style.top = '30px'

		let pz = this.game.playZone.$( '.playzone' );
		pz && (pz.style.width = fdy + 10 + 10 + 'px');

		this.waitResize = false;

//				figures.forEach( setlefttopfig )
	}

	setType( t, w ) {
		if( this.type===t && this.width===w ) return;
		if( this.type || this.width ) log( 'New type ' + (this.type || '-') + (this.width || '-') + '==>' + t + w );
		this.game.boardType = t;
		this.type = t;
		this.width = +w;

		// Fest board resize
		if( this.waitResize ) delay( this.#onresizeBind );

		this.panel.setAttribute( 'type', this.type + this.width );
		this.mainboard.setAttribute( 'width', this.width );

		// Release figures
		for( let i in this.figs )
			if( this.figs[i] ) this.releasecell( i );

		this.reinitCells();
	}

	reinitCells() {
		// Здесь нужно проверять не общий dragMaster, а то, что у нас dragInfo (?)
		// if( modules.dragMaster )
		this.initCells();
	}

	initCells() {
		// var step = 100 / this.width;
		let c = 0;
		for( let iy = 0; iy<this.width; iy++ ) {
			for( let ix = 0; ix<this.width; ix++, c++ ) {
				let clr = this.type==='s' || this.type==='o' ? this.type + (ix % 2===iy % 2 ? 'b' : 'w') : '';
				//				str += "<rect class='cell "+clr+"' x='"+ix+"' y='"+iy+"' width='1' height='1' />"
				let el = this.cells[c];
				if( !el ) {
					el = document.createElement( 'div' );
					el.className = 'cell' + (this.figs[c] ? ' hasfig' : '') + (this.#markers.has( c ) ? ' marked' : '');
					el.onclick = this.#cellclickBind;
				}
				this.setlefttopNow( el, c );
				if( this.width===8 ) {
					if( !((ix + iy) % 2) || !this.isCheckers )
						el.dataset.notation = 'abcdefgh'[ix] + (iy + 1);
				}
				el.setAttribute( 'x', ix );
				el.setAttribute( 'y', iy );
				el.setAttribute( 'color', clr );
				el.dataset.color = clr;
				el.cellNumber = c;
				el.dragInfo = this.game.dragInfo;
				if( ['renju', 'othello', 'go'].includes( this.game.gameInfo.type ) ) el.classList.add( 'circle' );
				if( !this.cells[c] ) {
					this.mainboard.appendChild( el );
					this.cells[c] = el;
					if( this.figs[c] )
						this.figs[c].myParent = el;
				}
			}
		}
		this.setCheck( this.lastCheck, true );
	}

	// ---------------
	setlefttopfig( fig, cell, onfinish, noanim ) {
		if( !fig || cell<0 ) return;
		let oldCell = fig.cellNumber, f = fig.holder;
		if( oldCell>=0 && this.cells[oldCell] ) {
			this.cells[oldCell].classList.remove( 'hasfig' );
		}
		let x = cell % this.width;
		if( Number.isNaN( x ) ) {
			log( `NAN oldCell=${oldCell} cell=${cell} w=${this.width}` );
			bugReport( 'NAN fig' );
			return;
		}
		let y = this.width - 1 - (cell - x) / this.width;
		if( this.inverse ) {
			x = this.width - 1 - x;
			y = this.width - 1 - y
		}
		//setTranslatePosition( f, x * cellWidth, y * cellWidth )
		// setTranslatePosition( f, x * 100 + '%', y * 100 + '%' );
		if( !noanim && this.isCheckers ) f.style.zIndex = 1000;		// Чтобы пролетало над остальными фигурами
		doAnimate( f, x * 100 + '%', y * 100 + '%',
			{ noanimation: !!noanim, onfinish: () => {
					if( !noanim && this.isCheckers ) f.style.zIndex = 0;		// Чтобы больше не пролетало
					onfinish?.();
			} } )
		let p = this.cells[cell];
		if( p ) {
			p.classList.add( 'hasfig' );
			f.myParent = p;
		}
	}

	setlefttop( fig, cell, onfinish, noanimation ) {
		if( !fig || cell<0 ) return;
		if( fig.myfig ) return this.setlefttopfig( fig, cell, onfinish, noanimation );
		let f = fig.holder || fig;
		let x = cell % this.width;
		let y = (cell - x) / this.width;
		if( this.inverse ) {
			x = this.width - 1 - x;
			y = this.width - 1 - y
		}
		x = x * 100 / this.width + '%';
		y = (this.width - 1 - y) * 100 / this.width + '%';
		f.style.left = x;
		f.style.top = y
	}

	setlefttopNow( f, cell ) {
		this.setlefttop( f, cell );
	}

	movefig( cfrom, cto, onfinish, noanimation ) {
		let fig = this.figs[cfrom];
		if( !fig ) return;
		let f = fig.holder;
		f.classList.add( 'animated' );
		if( cto<0 ) {
			// Simple fig deleting
			this.releasecell( cfrom );
			this.finAnimate();
			return
		}
		f.style.transition = null;
		this.figs[cfrom] = null;
		if( this.figs[cto] ) this.releasecell( cto, true );
		this.setlefttop( fig, cto, onfinish, noanimation );
		this.figs[cto] = fig;
		fig.cellNumber = cto;
	}

	checkMarkerPath( cell ) {
		if( this.#markerLast>=0 && this.#markerLast!==cell ) {
			this.clearMarkers();
		}
		this.addMarker( cell );
	}

	addMarker( cell, str ) {
		if( !this.game.inprogress ) return;
		if( cell===this.#markerLast ) return;
		if( this.#markers.size>10 )
			log( `Add to markers (${this.#markers.size}) ${cell}` );
		this.#markers.add( str? cell + ':' + str : cell );
		this.#markerLast = cell;
	}

	clearMarkers() {
		this.#markers.forEach( cell => {
			let c = this.cells[+cell.toString().split(':')[0]];
			if( !c ) return;
			c.classList.remove( 'marked', 'eatplace' );
			c.style.background = '';
		} );
		this.#markers.clear();
		this.#markerLast = null;
	}

	setMarkers( value ) {
		// log( 'setMarkers ' + value );
		this.#markers.forEach( cell => {
			if( !cell ) return;
			let ar = cell.toString().split( ':' ),
				c = this.cells[+ar[0]];
			if( !c ) return;
			c.classList.add( 'marked' );
			if( ar[1] )
				c.style.background = `center/30% no-repeat url(${IMGEMBEDPATH}/svg/figures/${ar[1]}.svg)`;
		} );
	}

	finAnimate() {
		if( !this.animationQueue.length ) {
			this.setMarkers();
			return;
		}
		this.moveanimate( this.animationQueue.shift() );
	}

	moveanimate( p ) {
		if( !p ) return;
		if( this.animationQueue.length ) {
			return this.animationQueue.push( p );
		}
		if( p.cto>=0 && p.cfrom!==p.cto ) {
			this.movefig( p.cfrom, p.cto, () => {
				if( p.newtype )
					this.setfigat( p.cto, p.newtype );
				// p.fig
				this.finAnimate();
			} );
		} else {
			if( p.newtype )
				this.setfigat( p.cfrom, p.newtype )
			this.finAnimate();
		}
	}

	// ----------- Parser of board
	get isChess() {
		let gi = this.game.gameInfo;
		return gi && ( gi.type==='chess' || (gi.chainid || gi.id)?.includes( 'chess' ) );
	}

	get isCheckers() {
		return this.game.gameInfo.type==='checkers' || this.game.gameInfo.chainid?.includes( 'draughts-' );
	}

	permutateFigures( options ) {
		import( './permutation.js' ).then( mod => {
			if( window.SOLO ) {
				if( this.dragSetpos ) return;
			} else
				if( this.game.gameState!=='notstarted' ) return;
			let figswitch = 'sS';
			if( this.isCheckers ) figswitch = 'SsDd';
			if( this.isChess ) figswitch = 'PNBRQKpnbrqk';
			if( this.game.boardType==='o' ) figswitch = 'Oo';
			// makeyourpositition_full - раз за партию
			if( options?.toast && !this.modposToast ) {
				this.modposToast = true;
				toast( options.toast );
			}
			mod.figures( this, this.isCheckers ? '2n' : '*', figswitch );
		} );
	}

	route_event( str ) {
		if( str==='newgame' ) {
			this.clearMarkers();
		}
	}

	route_players() {
		if( this.game.isPlayer ) this.sendOptions();
		this.checkInverse();
	}

	route_figcount( o ) {
		if( typeof o==='object' )
			for( let k in o ) this.game.players[k].setFigCount( o[k] );
	}

	route_state( state ) {
		// НЕ в режиме обучения НЕ даем выбирать позицию
		if( state!=='notstarted' )
			modules.dragMaster?.clear( this.dragSetpos );
	}

	route_position( o ) {
		// if( LOCALTEST ) log( "##position## " + o );
		let dots = o.length,
			step = 1;
		/*
				if( dots===this.width *this.width /2 ) {
					// Компактный формат для шашек (через 1).
					// Не используется и не реализован во избежание путаницы
					step = 2;
				}
		*/
		for( let i = 0; i<dots; i += step ) {
			let type = o[i];
			let f = this.setfigat( i, type );
			if( f ) this.startPos[i] = f;
		}

		if( window.SOLO || ( this.game.gameInfo.founder===UIN && !this.game.gameInfo.tour && /*!isChess && */this.game.gameState==='notstarted' ) ) {
			this.permutateFigures( {
				toast: window.SOLO? '' : '{Makeyourpositition_full}'
			} );
		}
	}

	route_markers( value ) {
		this.clearMarkers();
		if( value ) {
			if( typeof value==='object' )
				this.#markers = new Set( value );
			else {
				let ar = value.split( ',' );
				ar.forEach( v => {
					let ar = v.split( ':' ),
						str = this.#getCellFromServer( ar[0] );
					if( ar[1] ) str += ':' + ar[1];
					this.#markers.add( str )
				} );
			}
		}
		this.setMarkers();
	}

	route_game( o ) {
		// log( 'board.js parsing game {}' );
		let gi = /*game.gameInfo ||*/ o || this.game.gameInfo;
		let b = gi.board;
		if( !b ) {
			// Определим тип доски по chainid
			if( (o.chainid || o.id).match( /chess|draughts|ugolki/ ) ) b = 's8';
			if( o.chainid?.includes( '10' ) ) b = b.replace( '8', '10' );
		}
		if( !b ) return;
		this.setType( b[0], +(b.slice( 1 )) );
		this.buttons.pass.setContent( this.type==='s' ? '✅' : '{Pass}' );
		this.buttons.pass.classList.toggle( 'visible', ['go', 'ugolki'].includes( gi.type ) );
		this.autocompleteBox.classList.toggle( 'visible', ['ugolki'].includes( gi.type ) );
		if( this.game.whiteSide===undefined ) this.game.route_whiteside( 0 );
		// useAutoCompletion = game.gameInfo['type']==='ugolki';
		this.useFastMove = gi.type==='checkers';
		let ac = this.#boardbuttons.$( '.autocomplete' );
		if( this.useAutoCompletion ) {
			ac.setAttribute( 'yes', 1 );
			this.autoCompletion = +localStorage.getItem( 'ugolkiautocomplete' );
			this.autocompleteBox.checked = this.autoCompletion;
		} else
			ac.removeAttribute( 'yes' );
	}

	#getCellFromServer( notation ) {
		let num = +notation;
		if( num>=0 ) return num;		// Server sends just number
		return this.getCellFromNotation( notation );
	}

	getCellFromNotation( notation ) {
		// let num = +notation;

		if( this.width===8 ) {
			// if( num>=0 ) return num;
			return (+notation[1] - 1) * 8 + notation.toLowerCase().charCodeAt( 0 ) - 'a'.charCodeAt( 0 );
		}
		if( this.width===10 ) {
			let ch = notation.toLowerCase()[0],
				sym = ch.charCodeAt( 0 );
			if( ch>='a' && ch<='z' )
				return ((+notation[1]||10) - 1) * 10 + sym - 'a'.charCodeAt( 0 );
			else {
				// 100-checkers notation
				let num = +notation,
					a = ( num - 1 )*2,
					x = a%10,
					y = 9 - (a - x)/10;
				if( y%2 ) x++;
				return y*10 + x;
			}
		}
		// if( notation.length===1 ) return undefined;
		// if( num>=0 ) return num;
		return +notation;
	}

	route_figmove( str ) {
		// Format NN[-NN][-NN]...[*];...
		// При этом поддерживаем строчку position
		if( !this.width ) return;			// Пока не поддерживается
		this.setCheck();						// Drop check after move
		let f = str.split( ';' ), lastcell;
		for( let fi of f ) {
			let mov = fi.split( '-' );
			if( mov.length<2 ) continue;
			let cell = this.#getCellFromServer( mov[0] );
			for( let i = 1; i<mov.length; i++ ) {
				let newcell = mov[i].replace( '*', '' );
				if( newcell==='' ) {
					this.releasecell( cell, true );
					playSound( 'capture' );
				} else {
					let cellno = this.#getCellFromServer( newcell );
					if( cellno===undefined || isNaN( cellno ) ) {
						// Превращение, а не перемежение
						this.checkMarkerPath( cell );
						this.moveanimate( { cfrom: cell, newtype: newcell } );
						this.setMarkers();
					} else {
						this.moveanimate( { cfrom: cell, cto: cellno } );
						this.checkMarkerPath( cell );
						if( !this.game.nosound )
							playSound( 'move' );
						// В шашечных играх не бывает перескакивания, поэтому все фигуры на пути должны исчезнуть
						if( this.game.isSolo && this.isCheckers ) {
							let dx = cellno%this.width - cell%this.width,
								dy = (cellno-cellno%this.width)/this.width -
									(cell-cell%this.width)/this.width;
							if( Math.abs( dx ) !== Math.abs( dy ) ) {
								log( `Failed checkers protocol: ${str}`)
								return;
							}
							if( dx ) dx = dx / Math.abs( dx );
							if( dy ) dy = dy / Math.abs( dy );
							for( let c = cell; c!==cellno; c += dy*this.width + dx ) {
								if( c===cell ) continue;
								this.addMarker( c, this.figs[c]?.str );
								this.releasecell( c, true );
							}
						}
						lastcell = cell = cellno;
					}
				}
			}
		}
	}

	setCheck( cell, force ) {
		if( cell==='w' || cell==='b' ) {
			// Automatically find white/black king
			let king = this.figs.findIndex( x => x.color===cell && x.type==='k' );
			if( king>=0 ) cell = king;
			else return;
		}
		if( !force && cell===this.lastCheck ) return;
		if( this.lastCheck>=0 && this.cells[this.lastCheck] ) {
			this.cells[this.lastCheck].classList.remove( 'check' );
		}
		this.lastCheck = cell;
		if( this.lastCheck>=0 && this.cells[this.lastCheck] ) {
			this.cells[this.lastCheck].classList.add( 'check' );
		}
	}

	setfigat = ( cell, figtype ) => {
		if( !Number.isInteger( cell ) ) {
			bugReport( 'setfigat ' + cell + ': ' + figtype );
			return;
		}
		if( !figtype || figtype==='.' ) {
			this.releasecell( cell );
			return;
		}
		let s = this.figs[cell];
		if( s?.symbol===figtype ) return s;
		if( !s ) {
			s = this.#getfig( figtype );
			//			s.style.transition = 'none'
			this.setlefttopfig( s, cell, null, true );
			s.holder.style.display = 'initial';
			//			s.style.zIndex = 10
			this.figs[cell] = s;
			s.cellNumber = cell;
		}
		// Нашли объект, который содержит нужную клетку. Установим туда фигуру или удалим
		s.setSrc( figtype );
		s.holder.classList.remove( 'eated', 'display_none' )
		return s;
	}

	/*
	 MOVING
	 */

	#cellclick( e ) {
		if( !this.ONcellclick ) return;
		let n = e.target.cellNumber;
		if( this.figs[n] ) return false;
		if( this.allowCells && !this.allowCells.includes( n ) ) return;
		if( this.denyCells?.includes( n ) ) return;
		// Do click on this cell
		this.game.sendMove( n );
		return false;
	}

	#passclick() {
		this.#parse_off();
		this.game.sendMove( 'pass' );
		//		Socket.Send( 'move data=pass' )
	}

	autocompleteclick( e ) {
		this.autoCompletion = e.target.checked;
		localStorage.setItem( 'ugolkiautocomplete', this.autoCompletion ? 1 : 0 );
		if( this.autoCompletion && this.passAvailable && modules.dragMaster?.isActive( this.game.dragInfo ) )
			this.#passclick();
	}

	async resignclick() {
		if( await askConfirm( '{Resign}?' ) )
			this.doResign();
	}

	doResign() {
		this.#parse_off();
		this.game.send( 'resign' );
		toast( '{Resign}', {
			chat: true,
			duration: 'short',
			bottom: true
		} );
	}

	#drawclick( e ) {
		if( e.target.classList.contains( 'no' ) )
			this.game.send( 'draw', 'no' );
		else
			this.game.send( 'draw' )
	}

	#askUpgrade( fig, ok, cancel ) {
		// Показываем 4 фигуры для превращения. Остальное загреиваем
		this.promote ||= new Promote( this.game );
		let cell = fig.cellNumber, color = fig.color,
			dir = color==='w' ? -1 : 1;
		let promote = this.promote;
		promote.setColor( color );
		this.setlefttop( promote.figs.q, fig.cellNumber );
		this.setlefttop( promote.figs.n, fig.cellNumber + this.width * dir );
		this.setlefttop( promote.figs.r, fig.cellNumber + this.width * 2 * dir );
		this.setlefttop( promote.figs.b, fig.cellNumber + this.width * 3 * dir );
		this.promote.start( ok, cancel );
	}

	#parse_off() {
		this.ONcellclick = false;
		this.passAvailable = false;
		this.panel.removeAttribute( 'on' );
		this.buttons.pass.removeAttribute( 'action' );
		this.buttons.draw.removeAttribute( 'action' );
		this.buttons.reverse?.hide();
		this.promote?.end();
		this.figs.forEach( x => {
			if( !x ) return;
			x.holder.classList.remove( 'legalmove' )
		} );
		modules.dragMaster?.clear( this.game.dragInfo );
	}

	checkInverse() {
		// По-умолчанию, белые внизу. Если мы - 1й игрок, то инверсия
		if( this.type!=='s' && this.game.gameType!=='infection' ) return;
		let inv = this.game.whiteSide===1,
			mp = this.game.getpov;
		if( mp>=0 ) inv = mp!==this.game.whiteSide;
		if( this.game.manualRotate ) inv = !inv;
		if( this.inverse===inv ) return;
		this.inverse = inv;
		// game.pov = inv ? 1 : 0;
		delay( this.game.layoutHands.bind( this.game ) );
		this.panel.classList.toggle( 'inverted', this.inverse );
		this.cells.forEach( this.#setlefttopnowBind );
		this.figs.forEach( this.#setlefttopnowBind );
		this.game.checkboardplayerspos();
	}

	prepareclickmove() {
		// Ход реализуется кликом на клетку
		this.ONcellclick = true;
	}

	askRevers() {
		if( !this.buttons.reverse ) return;
		this.buttons.reverse.hide();
		this.#parse_off();
		this.game.sendMove( 'revers' );
	}

	checkRevers( can ) {
		if( !can ) {
			return this.buttons.reverse?.hide();
		}
		this.buttons.reverse ||= construct( 'button.display_none.revers[data-action=revers]', this.board, this.askRevers.bind( this ) );
		this.buttons.reverse.setContent( { w: '⚪', b: '⚫' }[can] + ' {Revers}' );
		this.buttons.reverse.show();
	}

	setBoardButton( val, name ) {
		let b = this.#boardbuttons.$$( `.${name}button` );
		if( !b ) return;
		for( let i = b.length; i--; )
			if( val )
				b[i].setAttribute( "action", val );
			else
				b[i].removeAttribute( "action" );
	}

	route_move( o ) {
		if( !this.cells.length ) this.initCells();
		this.moveData = o;
		import( './dragmaster_old.js' ).then( () => this.parse_on() );
	}

	sendOptions( force ) {
		let noauto = elephCore?.globalAttr.noboardautomove;
		if( noauto || force ) this.game.send( 'options', noauto ? 'noboardautomove' : '' );
	}

	parse_on() {
		modules.dragMaster.clear( this.dragSetpos );
		this.game.moveFlag = false;
		if( !this.moveData ) {
			this.#parse_off();
			// panel.removeAttribute( 'on' );
			return;
		}
		let o = this.moveData.on;
		this.ONcellclick = false;
		this.allowCells = o['cells'];
		this.denyCells = o['denycells'];
		modules.dragMaster.clear( this.game.dragInfo );
		this.setBoardButton( o['pass'], 'pass' );
		this.checkRevers( this.moveData.canrevers );
		let moves = o['moves'];
		if( this.allowCells || this.denyCells || moves==='*' || moves==='empty' ) {
			this.panel.setAttribute( 'on', 'click' );
			this.panel.dataset.movecolor = o.color;
			this.game.wantsAction( 'move' );
			modules.dragMaster.clear( this.game.dragInfo );
			return this.prepareclickmove()
		}
		if( moves ) {
			this.panel.setAttribute( 'on', 'drag' );
			this.mainboard.mydraggable = this.game.dragInfo;
			let blocks = moves.split( /],|\[|]/ ),
				fc = 0;
			for( let i = 0; i<blocks.length; i += 2 ) {
				let c = blocks[i],
					fig = this.figs[c];
				if( !fig ) continue;
				let legals = blocks[i + 1].split( ',' );
				let drops = legals.map( idx => {
					let d = idx.split( '!' );
					return [this.cells[d[0]], d[1]]
				} );
				modules.dragMaster.makeDraggable( this.game.dragInfo, fig.holder, drops );
				fc++;
			}
			modules.dragMaster.startMove( this.game.dragInfo,
				{ dragging: true, /* inverse: inverse, */ fastmove: this.useFastMove } );

			if( fc>0 )
				this.game.wantsAction( 'move' );

			/*		if( fc==1 ) {
			 // Единственный ход. Сразу пометим шашку
			 modules.dragMaster.Start( f )
			 } */
		}
	}
}

preloadSound( ['move', 'capture'] );

log( 'LOADED: board' );

export default Board;
