// Создаем набор frame'ов для прокрутки вперед и назад из протокола
//
// ?view=bg:short.b52-13-8-13-11.double-2.42-8-4-6-4.54-24-20-20-15.31-13-10-24-23.54-25-20-24-20.21-13-11-11-10.52-20-18-18-13.65-24-18-23-18.44-13-9-13-9-6-2-6-2.43-18-14-8-5.41-25-24.21-6-5-10-8.32-25-22-13-11.43-25-22-18-14.43-25-22-13-9.54-22-18-18-13.51-24-23-8-3.63-13-7-10-7.62-9-3-8-6.61-14-8-13-12.62-8-2-6-4.52-12-7-7-5.21-23-22-6-4.64-8-2-6-2.51-9-4-9-8.21-5-4-4-2.63-22-16-16-13.64-8-2-8-4.62-13-7-7-5.54-13-8-8-4.52-8-3-5-3.41-6-2-6-5.54-22-17-17-13.64-13-7-5-1.42-13-9-8-6.54-7-2-5-1.21-22-20-20-19.65-25-20-20-14.43-9-5-6-3.42-7-3-7-5.53-19-14-14-11.64.54-11-6-4-0.32.54-5-0-6-2.55-25-20-20-15-15-10-10-5.44-4-0-4-0-6-2-6-2.43-3-0-4-0.11-2-1-2-1-1-0-1-0.22-2-0-2-0-2-0-2-0.52-2-0-3-0.63-5-0-5-2.64-3-0-3-0.63-4-0-4-1.double-4.22-2-0-2-0-2-0-3-1.41-4-0-1-0.43-3-0-1-0
// ?game=bg-short&nn=tasiko&sn=%D0%9C%D1%8F%D0%A7%D0%B8%D0%BA17&p=w63-24-15.44-13-9-13-9-24-20-24-20.32-6-3-15-13.33-6-3-6-3-8-5-8-5.31-6-3-24-23.22-8-6-6-4-6-4-13-11.32-13-8.double-2.32-11-8-8-6.43-13-6.43-9-5-5-2.63.53-9-4-6-3.64.42-4-2-13-9.54.61-13-7-7-6.66.33-9-6-6-3-4-1-4-1.55.44-20-16-20-16-5-1-5-1.52-25-20-8-6.32-6-3-3-1.64-6-2-8-2.31-6-3-6-5.61-25-19-8-7.51-16-11-16-15.42-6-4-8-4.42-15-11-5-3.53-13-8-13-10.62-11-5-5-3.42-13-9-8-6.42-11-7-7-5.44-13-9-9-5-9-5-10-6.51-5-0-1-0.43-19-15-15-12.52-3-0-2-0.61-12-6-7-6.53-3-0-3-0.44-4-0-4-0-5-1-6-2.65-3-0-3-0.61-1-0-6-0.54-3-0-3-0.31-Drops
// const WB = '⚪⚫';

function proto2real( loose, short, plno ) {
	if( !loose )
		return 26+plno;
	if( loose===25 ) return 24+plno;
	if( short )
		return plno===0? (loose<=12? 12-loose : 36-loose) : (loose+11)%24;
	else
		return plno===0? 24-loose : (loose<=12? 12-loose : 36-loose);
}

function calcleftdices( dices, left ) {
	let d0 = +dices[0], d1 = +dices[1],
		m = d0===d1? 2 : 1;
	let str = dices[0];
	if( left[d0]<m ) str += 'fh'[left[d0]] || '';
	let keep = left[d0];
	left[d0] -= m;
	if( left[d0]<0 ) left[d0] = 0;
	str += dices[1];
	if( left[d1]<m ) str += 'fh'[left[d1]] || '';
	left[d0] = keep;
	return str;
}

function adddicecounter( d0, d1, dist, leftdices, add ) {
	// Если не куб, то нельзя два раза использовать один зарик
	let usedValue = add>0? 1 : 0;
	if( d0===d1 ) usedValue *= 4;
	if( ( dist===d0 || dist===d1 ) && leftdices[dist]!==usedValue ) {
		// Обычный ход одним из зариков
		leftdices[dist] += add;
	} else if( dist>d0 && dist>d1 ) {
		// Cмешанный ход - сумма зариков
		if( d0===d1 )
			leftdices[d0] += add * ( dist / d0 );
		else {
			leftdices[d0] += add;
			leftdices[d1] += add;
		}
		// Если дубль, то ход может быть тройным или четверным
	} else {
		// Ход на выбросе, когда используется минимально возможный зарик
		if( dist<d1 && (leftdices[d1]+add>=0 && leftdices[d1]+add<2) ) leftdices[d1] += add;
		else leftdices[d0] += add;
	}
}

export async function parse( bgmodule, params ) {
	let proto = typeof params==='string'? params : params.get( 'p' ),
		ar = proto.split( '.' ),
		plno = 0,
		cube = 1,
		short = bgmodule.isshort;
	// Initialization
	const poses = {
		short: 'f...D.F....cC....f.d...F....',
		short_hyper: '.........bbbBBB.............',
		short_nack: 'e...D.E...ccCC...e.d...E....',
		long: 'P...........p...............'
	};
	let initpos = poses[bgmodule.bgtype];
	if( !initpos )
		initpos = short? 'f...D.F....cC....f.d...F....' : initpos = 'P...........p...............';
	let firstFrame = {
		snapshot: `bgdices\n
			   bgpos ${initpos}\n`
	};

	for( let f of ar ) {
		let far = f.split( '-' ),
			dices = far[0];
		if( far[0].toLowerCase().startsWith( 'drop' ) ) {
			// Сдался
			bgmodule.game.protocol.addLog( plno, 'Drops' );
			continue;
		}
		if( far[0]==='double' ) {
			// Doubling
			let cubesym = [ '', '🟡','🟠','🟤','🔴'][cube] || '🔴';
			bgmodule.game.addFrame( {
				play: `cube ${cube*2}:${plno}:?`,
				duration: 500,
				name: cubesym+' Double'
			} );
			bgmodule.game.protocol.addLog( plno, cubesym+' x ' + (cube*2) );
			if( +far[1]===cube*2 ) {
				cube *= 2;
				bgmodule.game.addFrame( {
					play: `cube ${far[1]}:${1 - plno}`,
					duration: 500,
					name: cubesym + '✔'
				} );
			} else {
				// Drop
				bgmodule.game.addFrame( {
					play: `state finished`,
					duration: 500,
					name: cubesym + ' Drop'
				} );
			}
			bgmodule.game.protocol.addLog( 1-plno );
			continue;
		}
		if( far.length<1 ) continue;
		if( dices[0]==='b' || dices[0]==='w' ) {
			plno = dices[0]==='b'? 1 : 0;
			dices = dices.slice( 1 );
		}
		if( !dices ) break;			// Ошибочный протокол
		let color = 'wb'[plno];
		// Сколько реально будет сделано ходов? так и пометим кости
		let leftdices = Array(7 ).fill( 0 ),
			d0 = +dices[0], d1 = +dices[1];
		for( let i=1; i<far.length; i+=2 ) {
			let dist = +far[i]-(+far[i+1]);
			adddicecounter( d0, d1, dist, leftdices, 1 );
		}
		let ld = calcleftdices(dices,leftdices),
			nomoves = far.length===1,
			str = `arrows ${plno}\n
				   bgdices ${color}${ld}\n`;
		if( firstFrame )
			firstFrame.snapshot += str;
		// Фрейм броска. Показываются зарики. Пауза 0.5s
		let sym = nomoves? '⭕' : '⚪⚫'[plno],
			frame = firstFrame || {
			play: str,
			duration: 500
		};
		frame.name = `${sym}${dices}`;
		// let blockName = sym + dices;

		await bgmodule.game.addFrame( frame );

		bgmodule.game.protocol.addLog( plno );
		firstFrame = null;
		// Фреймы ходов, по одному неделимому блоку
		for( let i=1; i<far.length; i+=2 ) {
			if( far[i]==='Drops' ) {
				// Сдался
				bgmodule.game.protocol.addLog( plno, 'Drops' );
				break;
			}

			let from = proto2real( +far[i], short, plno ),
				to = proto2real( +far[i+1], short, plno );
			let dist = +far[i]-(+far[i+1]);
			adddicecounter( d0, d1, dist, leftdices, -1 );
			let bgdices = `bgdices ${color}${calcleftdices(dices,leftdices)}\n`,
				play = `bgmove ${from} ${to}\n${bgdices}`;

			bgmodule.game.addFrame( {
				play: play,
				duration: 500,
				name: frame.name/* + ':' + dist*/
			} );
		}

		// bgmodule.game.protocol.addLog( plno, frame.name );

		// Следующий игрок
		plno = 1 - plno;
	}

	// bgmodule.game.protocol.goLast();
}

