'use strict';

const rakepercent = [ [ '0', '0%'], [3, '3%'], [4, '4%'], [5, '5%'],
		[7, '7%'], [10, '10%']],
	rentOrRake = [ [ 'rent', '{rent}' ], /*[ 'self', '{player}' ], */...rakepercent ],
	pokerrake = [[0, '0%'], [1, '1%'], [2, '2%'], [3, '3%'], [4, '4%'], [5, '5%']];

function checkvisiblegame( res, obj, el ) {
	if( !res.games ) return true;
	let game = obj.__game || el.dataset.labelfor?.split( '.' )[1];
	return res.games.includes( game );
}

export async function teamSettings( team, sheet ) {
	let tools = import( './tools.js' );
	let jres = await API( '/teamadmin_startedit', {
		team_id: team.numericid
	}, 'internal' );
	if( !jres.ok ) {
		log( 'Failed to start teamadmin edit' );
		if( !LOCALTEST ) return;
	}
	let curr = team.currency || jres.currsymbol || '';

	let structure = {
		games: {
			datatype: 'gameset'
		}
	};

	if( FANTGAMES ) {
		function stakes( min, max, name ) {
			return {
				__caption: '{Stakes}',
				__postfix: curr,
				__info: '{Biginfo_fg_stakes}',
				__name: name,
				type: 'range',
				min: min,
				max: max,
			}
		}
		function prefstakes( name ) {
			return stakes( 0.5, 100, name );
		}
		let rentinfo = {
			__visibleif: data => data['rake.prefgame.comission']==='rent',
			type: 'comment',
			default: `{Rentcomissionexplanation,${team.currency}}`
		};
		structure = {
			games: {
				datatype: 'gameset',
				disabled: 'poker'
			},
			/*
					currsymbol: {
						type: 'input',
						size: 6,
						maxlength: 3,
						default: '$',
						options: [ '$', '€', '₤', '₴', '₺', '₩', '₽' ]
					},
			*/
			gamesetting: {
				__title: '{Games}',
				// __info: '{Biginfo_clubrake}',
				prefgroup: {
					__caption: '{Pref}',
					__game: 'pref',
					__visibleif: checkvisiblegame,
					pref: {
						__caption: '{Pref}, {plain}',
						stakes: prefstakes( 'stakes.prefgame' ),
						rake: {
							__info: '{Biginfo_clubrake}',
							__caption: '{Rake}',
							default: 'rent',
							options: [ ...rentOrRake, [11, '11%'], [12, '12%']],
							__name: 'rake.prefgame',
							__alias: 'rake.prefgame.comission'
						}
					},
					skak: {
						__caption: '{Skak_}',
						stakes: prefstakes( 'stakes.skak' ),
						rake: {
							__info: '{Biginfo_clubrake}',
							__name: 'rake.skak',
							default: 10,
							options: [ ...rentOrRake, [11, '11%'], [12, '12%']]
						}
					},
					pref_kz: {
						__caption: '{Pref_kz}',
						stakes: prefstakes( 'stakes.pref_kz' ),
						rake: {
							__info: '{Biginfo_clubrake}',
							__name: 'rake.pref_kz',
							default: 10,
							options: [ ...rentOrRake, [11, '11%'], [12, '12%']]
						}
					}
				},
				poker: {
					__visibleif: checkvisiblegame,
					__title: '{Poker}',
					rake: {
						__info: '{Biginfo_clubrake}',
						__name: 'rake.poker',
						default: '0club', options: pokerrake
					},
					stakes: {
						...stakes( 0.5, 100, 'stakes.poker' ),
						__caption: 'BB'
					}
				},
				bg: {
					__caption: '{Gammongame}',
					__visibleif: checkvisiblegame,
					stakes: stakes( 1, 100000, 'stakes.bg' ),
					game: {
						__info: '{Biginfo_clubrake}',
						__name: 'rake.bg.game',
						title: '{Game}, {rake}',
						default: 3, options: rentOrRake, rentinfo: rentinfo,
					},
					match: {
						__info: '{Biginfo_clubrake}',
						__name: 'rake.bg.match',
						title: '{Match}, {rake}', default: 8, options: rentOrRake, rentinfo: rentinfo
					}
				},
				chio: {
					__visibleif: checkvisiblegame,
					__game: 'chipoker',
					__title: '{Chipoker}',
					stakes: stakes( 0.1, 100, 'stakes.chipoker' ),
					rake: {
						__info: '{Biginfo_clubrake}',
						__name: 'rake.chipoker',
						default: 8, options: rakepercent
					}
				},
				belot: {
					__visibleif: checkvisiblegame,
					__title: '{Belot}',
					rake: {
						__info: '{Biginfo_clubrake}',
						__name: 'rake.belot',
						default: 5,
						options: rentOrRake
					},
					debercgame: {
						__title: '{Deberc}, {stakes}',
						__name: 'stakes.debercgame',
						__postfix: curr,
						type: 'range',
						min: 1,
						max: 100000
					},
					belot: {
						__title: '{Belot}, {stakes}',
						__postfix: curr,
						__name: 'stakes.belot',
						type: 'range',
						min: 1,
						max: 100000
					}
				},
					// text: '{Biginfo_fg_stakes}'
			},
			accounts: {
				__title: '{Accounts}',
				transparency: {
					__info: '{Fg_biginfo_transparentaccounts}',
					default: false
				},
				maxlimit: {
					__title: '{Maximumminus}',
					__info: '{Fg_biginfo_maximumminus}',
					type: 'input',
					default: '0',
					numeric: true,
					size: 6
				},
			},
			misc: {
				__title: '{Misc}',
				background: true
			}
		}
	}
	if( !team.isDemoclub ) {
		structure.rakeback = {
			__title: 'Rakeback',
			__info: '{Biginfo_clubrakeback}',
			period: {
				__caption: '{Auto}-{cashout}',
				default: '1w',
				options: [ [ '1d', '1 {day}' ], [ '1w', '1 {week}' ], [ '1m', '1 {month}' ] ]
			}
		}
		structure.exchange = {
			__title: `{Exchangerate}`,
			rate: {
				__caption: '{Exchangerate}',
				type: 'int',
				default: 0,
				min: 0,
				max: 1000
			},
			buyclubmoney: {
				__title: '{Purchase}',
				active: false,
				fant_max: {
					__title: "Maximum 💰 for club rent",
					type: 'numeric',
					size: 4,
					min: 0,
					default: 0
				}
			},
			info: {
				type: 'comment',
				default: '{Clubmoneyexchangerateinfo}'
			}
		};

		structure.charge = {
			__title: '{Gettingclubmoney}',
			__info: '{Fg_biginfo_clubcharge}',
			message: {
				__title: '{Message}',
				__placeholder: '{anyyourinstructions_deposit}',
				rows: 10,
				type: 'text'
			},
		};

		structure.autorefill = {
			__title: '{Autorefill}',
			ownaccount: {
				__title: 'From own account',
				__type: 'checkbox',
				value: false
			}
		};

		structure.domain = {
			__title: '🌐 {Domain}',
			host: {
				type: 'input',
				__title: 'Web-address',
				__pattern: FANTGAMES ? '^[\\w\\d_-]+\\.[\\w\\d_-]+\\.\\w{2,5}$' : '^[\\w\\d_-]+\\.[\\w\\d_-]+\\.ru$'
			},
			state: {
				type: 'info',
				text: ''
			},
			info: {
				type: 'info',
				text: '{Biginfo_club_owndomain}'
			}
		}
	}

	// Фильтр игр
	if( jres.result==='error' ) delete jres.result;
	if( jres.result ) delete jres.result.currsymbol;
	// structure.charge.__title = 'How players get ' + curr;
	// structure.charge.message.__placeholder += curr;
	// structure.stakes.__title = `{Stakes}, ${curr}`;
	// structure.rake.__title = `{Rake}, ${curr}`;
	// structure.accmaxlimit.__title = `{Limit}, ${curr}`;
	let dd = jres.domain_data;
	if( !dd && ( LOCALTEST || team.numericid===1 ) ) dd = { ip: '0.0.0.0' };
	if( structure.domain ) {
		structure.domain.__hidden = !jres.domain_data;
		if( dd?.state )
			structure.domain.state.text = [ dd.state.emo, dd.state.text ].join( ' ' );
		structure.domain.info.text = `{Biginfo_clubsetdomain_ip,${dd?.ip}}`;

		// Значение host из jres.domain перекрывает значение из jres.result
		if( dd?.host && jres.result )
			jres.result['domain.host'] = dd.host;

		if( jres.result?.['domain.host'] )
			structure.domain.__title = `${dd?.state?.emo || ''} ${jres.result['domain.host']}`;

		if( dd?.state?.confirmed ) {
			structure.domain.info.text = '{Biginfo_clubsetdomain_confirmed}';
			structure.domain.host.type = 'hidden';
		}
	}
	await tools;
	let res = await modules.tools.edit( structure, {
		values: jres.result || { games: 'pref poker', accmaxlimit: '10000' },
		picture: team.getPicture,
		title: team.getShowName,
		okbutton: '{Store}',
		startsheet: sheet,
		noteditable: jres.noteditable,
		onvalidate: win => {
			let rate = win.$( 'input[name="exchange.rate"]' )?.value || '';
			if( rate ) rate = `${rate}${team.currency} / 1💰`;
			win.$( `[data-sheetdata='exchange']` )?.setContent( rate );
		}
	}, {
		// disable: jres.result?.currsymbol? [ 'currsymbol' ] : null
	} );
	if( !res ) return;
	delete res.submit;
	// Сохраним параметры

	let editres = await API( '/teamadmin_edit', {
		team_id: team.numericid,
		params: res
	} );

	if( editres?.ok )
		toast( '{Success}' );
}

/*
export async function teamMembersAdmin( team ) {
	team ||= window.defaultClub;
	let mod = await import( './usersview.js' );
	let [win, usersView] = Usersview.makeSelectWindow( { team: team } );
	win.show();
	let res = await API( '/teamadmin_getmembers', {
		team_id: team.numericid
	} );
	if( !res?.ok ) return;
	let undername = {};
	for( let k of res.users ) {
		let u = res.users[k];
		undername[k] = (u.balance || '0 ') + (u.comment || '');
	}
	usersView.setUsers( res.users, {
		popular: res.popular,
		undername: undername
	} );
}

*/
export async function teamRakeAdmin( team ) {
	let jres = await API( '/teamadmin_rakestat', {
		team_id: team.numericid
	}, 'internal' );
	if( !jres?.ok && LOCALTEST ) jres = {
		"ok": true,
		"balance": "0.45",
		"total": "0.45",
		"sum": {
			"rake": { "total": "0.45", "month": "0.45" },
			"fee": { "total": "2" },
			"rent_prognosis": { "total": "11.5" }
		},
		"poker": { "rake": { "total": "0.3", "month": "0.3" } },
		"belot": { "rake": { "total": "0.06", "month": "0.06" }, "fee_prognosis": { "total": "0.5" } },
		"bg": {
			"rake": { "total": "0.09", "month": "0.09" },
			"fee": { "total": "2" },
			"rent_prognosis": { "total": "11" }
		}
	};
	if( !jres?.ok ) return;
	jres = jres.result || jres;
	// Окно
	let win = makeBigWindow( {
		id: 'rakestat_' + team.id,
		title: '🎁 {Income}',
		picture: team.getPicture,
		html: '<div class="mainholder"></div>'
	} );

	let bal = +jres.total || +jres.balance ? `${showBalance( jres.balance, team )} ${team.currency}` :
			`<div class='column'><span style='font-size: 5rem'>😢</span><span>{Commisionhasnotbeencharged}</span></div>`,
		str = `<div class='vertical_form' origin='${team.id}'>
			<span class='money' data-name='amount' style='font-size: 150%'>${bal}</span>`;

	function fillStat( stat, title ) {
		if( !stat ) return '';
		// games.total ||= stat.sum;
		// let games = stat.rake.keys().concat( stat.rent.keys(), stat.rent_prognosis?.keys() );
		let tmp = `<table style='width: 100%; text-align: left; border-top: black 0.5px solid; padding: 0 0.5em'>
			<th><tr><td colspan='3' style='text-align: center; color: gray;'>${title}</td></tr>
			<tr><td></td><td>{Income}</td><td>{Expense}</td></tr></th>`;

		let prev = {};
		for( let k in stat ) {
			let f = stat[k],
				rake = f.rake || '',
				fee = f.fee || '';
			if( k==='total' && prev.rake===f.rake && prev.fee===f.fee ) continue;
			prev = f;
			if( !+f.fee && +f.fee_prognosis ) {
				fee = `<span style='background: green; color: white'>${f.fee_prognosis}</span>`;
			}
			tmp += `<tr><td>{${k.capitalize()}}</td><td style='color: var(--light_black)'>${rake && `${f.rake} ${team.currency}` || ''}</td>
			<td style='color: orange'>${fee && `${fee}💰` || ''}</td></tr>`;
		}
		tmp += '</table>';
		return tmp;
	}

	if( +jres.total || +jres.balance>0 ) {
		str += `<div class='buttons'>
			<button class='default' data-action='withdraw' ${+jres.balance<=0 && 'disabled' || ''}>{Withdraw}</button>
			<button data-action='archive'>{History}</button></div>`;
	} else {
		str += `<button style='margin: 1em;' data-action='customizerake'>{Rake}: {settings}</button>`;
	}

	// Статистика по играм, покажем пока общей таблицей
	if( jres.periods ) {
		let strs = {
			_checkmonth: fillStat( jres.periods.month, '{Allthetime}' ),
			month: fillStat( jres.periods.month, '{Currentmonth}' ),
			prev_month: fillStat( jres.periods.prev_month, '{Previousmonth}' ),
			alltime: fillStat( jres.periods.alltime, '{Allthetime}' )
		};

		if( strs._checkmonth===strs.alltime )
			str += fillStat( jres.periods.month, '' );
		else
			str += strs.month + strs.prev_month + strs.alltime;

		str += `<span class='flexline' style='color: var( --light_gray ); text-decoration: underline; text-decoration-style: dashed' data-popupinfo='{Fg_biginfo_yourclubincomeandexpenses}'>️<span class='w24 info_icon'></span>{Yourclubincomeandexpenses}</span>`;
	}

	str += `</div>`;

	win.$( '.mainholder' ).html( str );

	win.show();
	win.onclick = async e => {
		let btn = e.target.closest( '[data-action]' );
		switch( btn?.dataset.action ) {
			case 'customizerake':
				win.hide();
				teamSettings( team, 'gamesetting' );
				break;

			case 'withdraw':
				// Выводим рейк на счет капитана
				e.target.disabled = true;
				let res = await API( '/team_withdrawrake', {
					team_id: team.numericid
				}, 'internal' );
				if( res.ok ) {
					win.$( "[data-name='amount']" ).setContent( showBalance( res.balance || 0, team.currency ) );
					// win.hide();
				}
				break;

			case 'archive':
				fire( 'showteamtransactions', {
					team: team,
					user: 'rake',
					title: '🎁 {Rake}. {Archive}'
				} );
		}
	}
}

async function memberClick( e, team ) {
	let origin = e.target.closest( '[data-origin]' )?.dataset.origin;
	if( !origin ) return;
	let user = User.set( origin ),
		admin = team.isHelper;

	let mod = await import( './team.js' );
	let opts = {
		admin: team.isHelper
	};
	if( admin )
		opts.onHide = ( e, win ) => {
			let p = collectParams( win, { valid: true } );
			API( '/teamadmin_updateuser', {
				team_id: team.numericid,
				user_id: user.id,
				data: p
			} );
		};
	mod.teamMember( team, user, opts );
}

export async function teamInvite( team ) {
	if( team.team_id ) team = User.get( team.team_id );
	else if( team.execute && team.objectid )
		team = User.get( team.objectid );
	if( !team?.isHelper ) return;
	// Сначала уточним ссылку на приглашение
	let rescode = await API( '/teamadmin_getinvitationcode', {
		team_id: team.numericid
	}, 'internal' );
	if( LOCALTEST ) rescode = { ok: true, invitationcode: '123' };
	if( !rescode?.ok ) return;
	execute( null, null, {
		execute: 'inv.invite',
		url: rescode.url || location + '?' + rescode.invitationcode,
		title: `{Invitein} ${team.getShowName}`,
		hint: 'team_invite_' + team.numericid
	} );
}

export async function teamMembers( team, options ) {
	let admin = team.isHelper;
	let mod = await import( './usersview.js' );
	let [win, usersView] = mod.Usersview.makeSelectWindow( {
		// team_id: team.numericid,
		id: 'teamusers_' + team.id,
		picture: team.getPicture,
		title: '{Players}',
		keep: true,
		withme: true,
		// listholderstyle: 'margin: 0.3em 0.3em 0 0.3em',
		// multiselect: options.multiselect,
		filters: { sort: true, nulls: true },
		onclick: e => memberClick( e, team ),
		...options
	} );

	async function refill() {
		let prefix = admin ? 'teamadmin' : 'team',
			res = await API( prefix + '_getuserlist', {
				team_id: team.numericid,
				admin: true
			}, 'internal' );

		// Получили список игроков, заполним usersView, если эта форма еще актуальна
		if( res?.ok ) {
			res = res.result || res;
			if( res.users ) for( let o of res.users ) if( o.comment ) o.undername = o.comment;
			usersView.setUsers( res.users, {
				popular: res.popular,
				mode: 'graceful',
				showvalue: 'balance'
			} );
		} else if( LOCALTEST ) {
			let serv = await elephCore.do( `type=getteammembers team=${team.numericid}` );
			if( serv ) {
				usersView.setUsers( serv.users.map( x => {
					return { id: x.toString(), undername: 'comment', balance: Math.round( Math.random() * 1000 ) - 500 }
				} ), {
					mode: 'graceful',
					showvalue: 'balance'
				} );
			}
		} else {
			toast( 'Sorry, something goes wrong. Please try again later' );
			return;
		}
	}

	await refill();

	win.show();

	win.onreturn = refill;
}

export function admin( event ) {
	// Admin default command if no tid
	let tid = event.target.dataset.tid,
		team = User.get( tid ) || window.defaultClub;
	if( !team ) return;
	teamAdmin( team );
}

export async function teamAdmin( team ) {
	// Новый показ режима настроек команд
	let democlub = team.strid==='demo';
	let win = makeBigWindow( {
		repeatid: `baseteamoptions_${team.numericid}`,
		// picture: team.getPicture,
		title: '{Club} {information}', // team.getShowName,
		html: `<div class="payload column" style='align-items: start; min-width: min(20em,100%); box-sizing: border-box; padding: 0 0.5em; overflow-y: auto'></div>`
	} );
	// Фото с основной инфой и баланс
	let rakeblock = '';
	if( FANTGAMES ) {
		rakeblock = `<div data-block='rake'><button data-action='rake'>🎁 {Income}</button></div>`;
	}
	let str = `<div class='flexline spacebetween' style='align-self: stretch; padding-bottom: 0.5em; border-bottom: 1px solid lightgray'>
		${fillPlayerHTML( team, {
		size: 64,
		imgoptions: {
			editable: team.isCaptain? 'always' : false,
		},
		undername: `<button style='margin: 0.5em 0' data-execute='teamadmin.baseTeamOptions' data-origin='${team.id}'>{Edit}</button>`,
		dataset: {
			execute: 'teamadmin.baseTeamOptions'
		}
	} )}
		<!-- <div class='flexline' style='margin-right: 0.5em'>
			<div class='column' style='align-self: start'>
				<span class='fants' data-origin='${team.itemid}' data-field='fant_balance' style='font-size: 2rem'>${showBalance( team.fant_balance || '0' )}</span>
				<button data-action='refill' class='default'>{Deposit}</button>
			</div>
			<span class='display_none visible w32 grayhover icon question' data-popupinfo='{Biginfo_club_balance}'>
		</div> -->
		</div>`;

	// TODO: Shall club amount be deposited?

	// if( !team.isFreetrial ) {
/*
		str +=
			`<span style='margin-top: 1em'>⏰ {Completedemomodetocontinue}</span>
			<button class='default importantsize' data-action='completedemo' style='margin-top: 1em'>{Complete} {demomode}</button>
				</div>`;
*/
	// } else {
		str += `
		<div class='flexline center' style='align-self: stretch; margin-top: 0.5em'>
		  <button class='flexline' data-action='invite'>
		  	<img src='${IMGEMBEDPATH}/svg/icons/person_add_black_24dp.svg' style='padding-right: 5px'>
		  	<span>{Invite}</span>
		  </button>
		</div>
		<div class='flexline center' style='gap: 10px; margin-top: 1rem; 
			padding: 0.5em 0; align-self: stretch;
			justify-content: start; 
			_border-top: 1px solid lightgray'>
			<div><button data-action='players'>😎 {Players}</button></div>
			${rakeblock}
			<div><button data-action='settings'>⚙️ {Settings}</button></div>
		</div>
		<div class='flexline wrap'>
			<button class='display_none' data-action='resetaccounts'>🔥️ {Resetaccounts}</button>
			<button class='display_none' data-action='remove'>️❌ {Deleteclub}</button>
		</div>`;

		/*
		<fieldset data-block='trial' class='column display_none' style='align-self: stretch'><legend>Trial period</legend>
		</fieldset>
*/

		// Показ баланса клуба.
		if( !democlub ) {
			str += `<div class='column balanceinfo' style='padding: 1rem; border-top: 1px solid lightgray; align-self: stretch'></div>`;

		if( team.isFreetrial ) {
			let left = +team.freetrial - Date.now()/1000;
			log( 'team: trial: left ' + left );
			str += `<div class='flexline center' 
				style='align-self: stretch; border-top: 1px solid lightgray; margin-top: 0.5em; padding-top: 0.5em;'>
				<span style='padding: 0.2em 0.5em;'>🟢 {Trialperiod}</span>`;
			if( +left>0 )
				str += `<span style='padding-left: 0.5em'>{daysleft}: ${Math.round( left / (60 * 60 * 24) )}</span>`;
			str +=
				`</div>`;
		}
	}
	win.$( '.payload' ).html( str );
	win.show();

	let jres = await API( '/teamadmin_getinfo', {
		team_id: team.numericid
	}, 'internal' );
	if( !jres?.ok && LOCALTEST ) jres = {
		ok: true,
		fant_balance: 3.5,
		trial: {
			bg: 'free',
			pref: Date.now() / 1000 + 60 * 60 * 24 * 5
		},
		fant: {
			balance: -10,
			available: 90,
			limit: {
				value: 0, // 100,
				till: '1'
			}
		}
	};
	let balance = +( jres?.fant.balance ?? 0 );
	if( jres?.ok ) {
		updateOrigin( team.itemid, { fant_balance: balance }, win );
		team.fant_balance = balance;
		team.options = jres;
	} else {
		// toast( 'Update failed' );
		return win.hide();
	}

	if( jres.allowed_actions?.resetaccounts===true ) {
		// Доступно обнуление всех счетов клуба
		win.$( '[data-action="resetaccounts"]' )?.show();
	}
	if( jres.allowed_actions?.remove===true || team.isDemoclub ) {
		// Доступно обнуление всех счетов клуба
		win.$( '[data-action="remove"]' ).show();
	}

	// Заполним триалы
	let show = !team.isDemoclub && ( !team.isFreetrial || jres?.options.games.includes( 'pref' ) );
	// Если по выбранным играм доступен freetrial, то не показываем
	// Пока на примере преферанса (он всегда платный)
	if( show ) {
		let s = '';
		if( team.isFreetrial ) {
			s += `<span><b>{Fg_prefpays_only}</b>.</span>`;
		}
		let urgent = balance<10 ? 'default' : '',
			lstr = '',
			avail = balance,
			limval = 0;
		if( jres.fant?.limit ) {
			let lim = jres.fant.limit,
				till = lim.till || '1',
				date = new Date( lim.till );
			limval = +lim.value;
			if( limval<0 ) limval = 0;
			if( till==='1' ) {
				date = new Date();
				date.setMonth( date.getMonth() + 1, 1 );
				date.setHours( 12, 0 );
			}
			if( limval>0 ) {
				avail += limval;
				// lstr = `<br><span>{Available}: <span class='money' data-limit='${limval}'>${showBalance( avail )}💰</span> ({credit} <span class='money'>${limval}💰</span> {till} ${date.toLocaleDateString()})</span>`;
				lstr = `<br><span>({credit} <span class='money'>${limval}💰</span> {till} ${date.toLocaleDateString()})</span>`;
			}
		}
		if( jres.fant?.balance<0 && !limval ) {
			lstr += `<br><span>🔴 {Needtotopupaccount}</span>`;
		}
		s += `<div class='flexline'>
						<span><span>{Clubrent}. {Balance}: </span><span data-name='${team.id}.fant_balance' class='money'>${showBalance( team.fant_balance || 0 )} 💰</span>${lstr}</span>
						<button data-action='refill' class='${urgent}'>{Deposit}</button>
					</div>
					<span style='text-align: left; font-size: 1rem; color: var( --light_gray )'>
						<span>{Fg_clubfantbalancefor}</span>
						<span data-popupinfo='{${projectID}_biginfo_clubrentdetails}' style='border-bottom: 1px dashed; color: black'>{Details}</span></span>
					
				</div>
				`;
		win.$( '.balanceinfo' )?.setContent( s );
	}


	/*
		if( jres.trial && !team.isDemomode ) {
			let tools = await import( './tools.js' );
			// console.warn(  'TRIALS: ' + JSON.stringify(jres.trials) );
			// for( let k in jres.trials )
			// 	console.warn( k + ': ' + jres.trials[k]);

			let str = '<table style="text-align: left"><tr><td></td><td></td><td></td></tr>';
			for( let k in jres.trial ) {
				let tw = 60*60*24*14,
					v = jres.trial[k],
					val = +v - Date.now()/1000 || tw,
					// d = new Date( Math.round( +v*1000 ) ),
					until = v==='free'? '' : `{daysleft}: ${Math.round( val/(60*60*24) )}`,
					progress = v!=='free'? `<progress min=0 max='${tw}' value='${tw-val}' style='max-width:5em'>` : '<span>🟢 {free}</span>';
				str += `<tr><td>${tools.getGamePhrase( k )}</td>
						<td>${progress}</td>
						<td>${until}</td></tr>`;
			}
			str += '</table><span style="margin-top: 1em; font-size: 1rem; color: gray">{Shortinfo_fg_clubtrialperiod}</span>';

			let trial = win.$( 'fieldset[data-block="trial"]' );
			if( trial ) {
				html( str, trial );
				trial.show();
			}

			// Комиссия (рейк)
	/!*
			if( FANTGAMES || LOCALTEST ) {
				let rake = win.$( '[data-block="rake"]' );
				html( `<button data-action='rake'>🎁 {Rake}</button>`, rake );
				rake.show();
			}
	*!/
		}
	*/

	win.onclick = async e => {
		let btn = e.target.closest( '[data-action]' );
		switch( btn?.dataset.action ) {
			case 'rake':
				win.hide();
				teamRakeAdmin( team );
				break;
			case 'settings':
				win.hide();
				teamSettings( team );
				break;
			case 'refill':
				(await import( './team.js' )).deposit( team );
				break;
			case 'players':
				win.hide();
				teamMembers( team, { admin: true } );
				break;
			case 'invite':
				teamInvite( team );
				break;
			case 'resetaccounts':
				await askConfirm( `${team.getShowName}. {Players}: ${team.members}. {Resetaccounts}?` );
				let res = API( '/teamadmin_resetaccounts', {
					team_id: team.numericid
				}, 'internal' );
				if( res?.ok ) {
					toast( '{Done}: {resetaccounts}' );
					win.$( '[data-action="resetaccounts"]' ).hide();
				}
				break;

			case 'remove':
				if( team.isDemoclub ) {
					await askConfirm( `{Сonfirmremovedemoclub}?` );
					toast( '{Iobey}...' );
					window.DEMOCLUBDELETED = true;
					win.hide();
					goLocation( '' );
					for( let el of $$( "[data-execute='golocation.demo']" ) )
						el.style.display = 'none';
					return;

				}
				await askConfirm( `${team.getShowName}. {Players} ${team.members}. {Delete}?` );
				if( +team.fant_balance )
					await askConfirm( `${team.getShowName}<br>${team.fant_balance}💰 {balancelostifdeletion}. {Continue}?` );
				let delres = await API( '/teamadmin_remove', {
					team_id: team.numericid
				}, 'internal' );
				if( delres?.ok ) {
					toast( team.getShowName + ' {deleted}.' );
					win.hide();
					goLocation( '' );
				}
				break;
/*
			case 'completedemo':
				win.hide();
				completeDemo( team );
				break;
*/
		}
	}
}

export async function baseTeamOptions( team ) {
	if( team.origin ) team = User.get( team.origin );
	if( !team ) return;
	let winteam = makeBigWindow( {
		repeatid: 'teamstakes_' + team.id,
		title: '{Editing}',
	}, `<div class='vertical_form'>
			<div style='position: relative'>
			<img data-magictype='logo' data-editable='always' data-name='picture' width='128'>
			<img width=64 height=64 style='position: absolute; left: calc( 50% - 32px ); top: calc( 50% - 32px ); background: rgba( 255, 255, 255, 0.5 ); pointer-events: none' 
				src='${IMGPATH}/svg/icons/photo_camera-24px.svg'>
			</div>
			<input name='team_id' type='hidden'>
			<input name='name' style='margin: 1em 0; padding: 0.5em; width: 80%; text-align: center'>
			<button class='default' data-closeselect='ok'>{Store}</button>
			</div>` );

	winteam.$( 'img[data-name="picture"]' ).setMagic( team.picture );
	winteam.$( 'input[name="team_id"]' ).value = team.numericid;
	winteam.$( 'input[name="name"]' ).value = team.getShowName;
	let res = await winteam.promiseShow();
	if( res==='ok' ) {
		// Отправим запрос на изменения
		let r = await API( '/teamadmin_setbaseoptions', collectParams( winteam ), 'internal' );
		if( r?.ok )
			team.fetch();	// Получим все данные о клубе заново
	}
}

/*
export async function activateAccounts( team ) {
	let winselect = makeBigWindow( {
		id: 'activateaccounts_' + team.id,
		title: team.getShowName,
		picture: team.getPicture,
		html: `<div class='vertical_form' data-validityform='1'>
				<span>{Load_selectcurrencyforclub}</span>
				<input type='hidden' name='team_id' value='${team.numericid}'>
				<input name='currsymbol' required size='6' maxlength='3' list='clubcurrencylist_${team.id}'
				style='font-size: 150%; outline: none; margin: 1em 0; padding: 0.2em 0.5em; text-align: center'>
				<button class='default' data-checkvalidity='1' data-closeselect='ok' style='padding: 0.5em 2em'>Ok</button>
				<datalist id='clubcurrencylist_${team.id}'>
					<option value='$'>
					<option value='€'>
					<option value='₴'>
					<option value='₺'>
					<option value='₩'>
					<option value='₽'>
				</datalist>
			   </div>`
	} );
	let res = await winselect.promiseShow();
	if( res==='ok' ) {
		let res = await API( '/teamadmin_setcurrsymbol', collectParams( winselect ) );
		if( res.ok )
			toast( '{Success}' );
	}
}
*/

export async function renameCurrency( data ) {
	let team = User.get( data?.origin ) || window.defaultClub;
	//
	if( !team ) return;

	if( !team.isCaptain ) {
		toast( 'Only manager can change currency name' );
		return;
	}
/*
	let beforewin = makeBigWindow( {
		id: 'enddemo_before',
		title: '🚢 {Demomodecompletion}',
		html: `
			<span class='biginfo' style='max-height: 50vw; overflow-y: auto'>{Fg_biginfo_beforeclubenddemo}</span>
			<button class='default' data-closeselect='create' style='margin: 0.5em; padding: 0.5em 1em; '>{Continue}</button>
			`
	} );
	let f = await beforewin.promiseShow();
	if( f!=='create' ) return;
*/
	let value = team.currency;
	if( team.currsymbol==='DEMO' ) value = '';
	let win = makeBigWindow( {
		repeatid: 'changecurrency',
		picture: team.getPicture,
		title: team.getShowName + '. {Currency}',
		html: `<div class='column center' style='padding: 1em'>
			<span style='_font-size: 1rem; color: var(--color_light_gray)'>{Enddemo_selectcurrsymbol}</span>
			<input name='currsymbol' required size='6' maxlength='3' name='currsymbol' placeholder=''
			value='${value}' list='clubcurrencylist'
				style='font-size: 2rem; outline: none; margin: 1em 0; padding: 0.2em 0.5em; text-align: center'>
			<button data-closeselect='ok'  data-checkvalidity='1' class='default' style='padding: 0.5em 2em'>{Change}</button>				
			<input type='hidden' name='team_id' value='${team.numericid}'>
				<datalist id='clubcurrencylist'>
					<option value='$'>
					<option value='€'>
					<option value='₴'>
					<option value='₺'>
					<option value='₩'>
					<option value='₽'>
				</datalist>
		</div>`
	} );

	let res = await win.promiseShow();
	if( res!=='ok' ) return;
	res = await API( '/teamadmin_renamecurrency', collectParams( win ), 'internal' );
	if( res.ok ) {
		toast( '{Success}' );

		// Окно с поздравлениями
/*
		let afterwin = makeBigWindow( {
			id: 'enddemo_after',
			title: '🚢 {Congratulations}!',
			html: `<div class="vertical_form">
			<span class='biginfo' style='max-height: 50vw; overflow-y: auto'>{Biginfo_afterenddemomode}</span>
			<div class='flexline'>
				<button class='default' data-closeselect='create' style='margin: 0.5em; padding: 0.5em 1em; '>Ok</button>
				<button data-closeselect='options' style='margin: 0.5em; padding: 0.5em 1em; '>⚙️ {Settings}</button>
			</div>
			</div>`
		} );
		let res = await afterwin.promiseShow();
		if( res==='options' )
			teamSettings( team );
*/
	}
}

let limitWin;

export async function checkMaxLimit( team ) {
	// Важная для понимания функция проверки максимального лимита
	let value = 0;
	limitWin = makeBigWindow( {
		repeatid: 'checkmaxlimit',
		title: team.getShowName + '. {Maximumminus}',
		picture: team.getPicture,
		html: `
			<div class='column' style='padding: 1em'>
			<span class=''>{Fg_setmaxlimit_before}</span>
			<span class='flexline' style='font-size: 3rem; margin: 0.5em 0; justify-content: center'>
					<input name='amount' class='noborder' autocomplete="off" inputmode='numeric' size='${value.length || 1}' value='${value}' 
					data-autosize='1' style='align-self: center; text-align: center; font-size: 3rem' /> 
					<span>${team.currency}</span>
			</span>
			<PRE class='explain' style='color: var( --light_gray )'></PRE>
			<button class='importantsize default' data-closeselect='ok'>OK</button>
			</div>
			`
	} );
	limitWin.show();
	let input = limitWin.$( 'input' );

	limitWin.oninput = () => {
		let val = +input.value,
			newval = val || 0;
		if( newval<0 ) newval = 0;
		if( newval!==val ) input.value = newval;
		let explain = '{Fg_maximumminus_0}';
		if( newval>0 ) explain = `{Fg_maximumminus_positive,-${newval}${team.currency}}`;
		limitWin.$( '.explain' ).setContent( explain );
		let b = limitWin.$( 'button' );
		b.setContent( +newval===(+input.dataset.firstvalue || 0) ? 'OK' : '{Change}' );
	};
	limitWin.onHide = ( res, win ) => {
		if( res==='ok' ) {
			let val = +win.$( 'input' ).value || 0;
			if( val<0 ) return toast( '{Maximumminus} >=0' );
			hint.done( 'club_maximumminus_cap' + (team.isFreetrial ? '_trial' : '') );
			hint.update();
			API( '/teamadmin_setmaxlimit', {
				team_id: team.numericid,
				maxlimit: val
			}, 'internal' );
		}
	};
	let res = await API( '/teamadmin_getmaxlimit', {
			team_id: team.numericid
		}, 'internal' ),
		limit = res?.maxlimit || 0;

	input.dataset.firstvalue = +limit || 0;
	input.value = limit;
	input.size = limit.toString().length || 1;
	limitWin.dispatchEvent( new Event( 'input' ) );
}