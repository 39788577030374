"use strict";

cssInject( 'poker' );

import Cardholder from './cardholder.js';
import Gamecards from './gamecards.js';
import Vgame from "./vgame.js";

let allbypanels = new Map,
	pokerResizeObserver = window.ResizeObserver && new ResizeObserver( entries => {
		for( let entry of entries ) {
			allbypanels.get( entry.target )?.layout( entry );
		}
	} );

export default class Poker {
	#lastbets;
	#semafore;
	#lastSelectedValue = {};
	#buyPanel;
	#isplayer;
	#layoutMode;
	#autoBuy;
	#myCash;
	#rules;
	#cashClickBind = this.#cashClick.bind( this );

	constructor( game ) {
		this.game = game;
		game.setWideModel();
		game.module = 'poker';

		this.panel = construct( '.poker.use_bg.cardspanel.column.abs100.display_none' );
		this.panel.ontrack = ( name, value, element ) => {
			if( name==='bg' && value==='canva' )
				element.dataset.bg = 'galaxy';
		};
		elephCore?.track( this.panel, 'bg' );
		game.setTopPanel( this.panel );

		this.viewPanel = construct( '.poker-view', this.panel );
		this.controlPanel = html(
			`<div class='poker-control display_none' style='position: relative'>
					<div class='flexline nowrap baseline' style='justify-self: start; justify-content: space-around; gap: 1em; 
						align-content: stretch; font-size: 1rem; z-index: -1; background: none'>
						<div class='column spaceevenly display_none' data-name='playercolumn' style='align-self: stretch'>
						 <label class='flexline nowrap' style='color: black'><input name='sitoutnexthand' type='checkbox'>{Notingame}</label>
						 <button class='display_none' name='askbuy'>{Buy}</button>
						</div>
						<span class='deallog hideempty' data-action='deallog' 
							style='order: 1; align-self: stretch; max-width: 50vw; white-space: initial; color: black; border: 1px solid black; padding: 0.2em 0.5em; margin: 3px;
								max-height: 4em; overflow-y: auto'></span>
						<button name='invite' class='default display_none' style='order: 10; font-size: 1.5rem'>{Invite}</button>
						<button name='register' class='default display_none' style='order: 10; font-size: 1.5rem'>{Register}</button>
						<button name='tofight' class='default display_none importantsize' style='background: green; color: white; order: 10'>{Tofight}!</button>
						<button name='chooseaplace' class='display_none' style='order: 10; font-size: 1rem'>{Chooseaplace}</button>
						<button name='backtotable' class='display_none' style='order: 10; font-size: 1rem'>{Backtogame}</button>
					</div>

					<div class='fade poker-prebuttons flexline spaceevenly' style='justify-content: space-evenly;'>
					  <label class='flexline' data-pokercolor='fold' data-name='pre_check'><input type='checkbox' name='pre_check'><span>Fold</span></label>
					  <label class='flexline' data-pokercolor='call' data-name='pre_call'><input type='checkbox' name='pre_call'><span>Call</span></label>
					  <label class='flexline' data-pokercolor='raise' data-name='pre_bet'><input type='checkbox' name='pre_bet'><span>Bet pot</span></label>
					</div>

					<div class='fade poker-buy flexline'>
					<span class='control grayhover icon closebutton' 
					style='position: absolute; left: 1px; top: 1px; width: 2rem; height: 2rem;'
					 data-action='closebuy'></span>
					 <div class='display_none poker-buyenough flexline'
						 style='width: 100%; height: 100%; align-content: space-evenly; justify-content: space-evenly;'>
						 <span class='poker-buyavail display_none' style='position: absolute; bottom: 2px; left: 2px; font-size: 0.8rem; color: gray'></span>
						 <div class='column'>
						  <div>
						   <input required name='buy_amount' type='number' inputmode='numeric' step='0.01' style='width: 4em; text-align: right; color: gray; font-size: 1rem; padding: 0.2em 0.5em' />
						   <input name='buy_slider' type='range' step='0.01' />
						  </div>
						 <label style='display:none; color: black'><input name='buy_autorefill' type='checkbox'>{Autorebuy_max}</label>
						 </div>
						 <div style='min-width: 7em;'>
						 <button class='default' name='buy_go' style='grid-area: go'>Buy</button>
						 </div>
					 </div>
					 <div class='display_none poker-buynotenough flexline center'>
					 	<button name='nomoney' class='default'>Not enough credits to buy</button>
					 </div>
					</div>
					
					<div class='display_none poker-selectbet flexline'>
					 <button name='fold' class='fade'>Fold</button>
					 <button name='call'>Call</button>
					 <button name='bet_go' data-pokercolor='raise' style='min-width: 5em'>Raise</button>
					 <div class='display_none betselection spaceevenly column' style='align-self: stretch; '>
					  <div class='betbuttons flexline display_none'></div>
					  <div class='flexline'>
 					  	<input class='hideinportrait display_none' name='bet_amount' type='number' inputmode='numeric' step='0.01' style='width: 3em; text-align: right; color: gray; font-size: 1rem; padding: 0.2em 0.5em' />
					    <input class='display_none' style='flex-grow: 1;' name='bet_slider' type='range' step='0.01' />
					  </div>
					 </div>
					</div>
					
					<div class='display_none poker-dealerchoice flexline nowrap' style='justify-content: space-evenly'>
					  <div class='column' style='font-size: 1rem'>
						  <div class='flexline'>
							<button name='selectrule_FL-H'>FLH</button>
							<button name='selectrule_NL-H'>NLH</button>
							<button name='selectrule_O'>PLO</button>
							<button name='selectrule_O-HILO'>Hi/Lo</button>
							<button name='selectrule_O5'>PLO-5</button>
							<button name='selectrule_SD'>SD</button>
						  	<label style='color: black; margin: 0.5em'><input type='checkbox' name='select_twoflops' />{Twoflops}</label>
						  </div>
						  <div class='flexline'>
						  </div>
					  </div>
					  <div class='column' style='min-width: 8em'>
					    <button class='default column center' style='white-space: normal' name='select_go'></button>
					  </div>
					</div>
					</div>`, this.panel, this.controlClick.bind( this ) );
		this.controlPanel.makeVisible( !this.game.vgame.isReplay );
		this.controlPanel.oninput = this.input.bind( this );
		this.controls = {};
		for( let o of this.controlPanel.$$( '[name], [data-name]' ) )
			this.controls[o.name || o.dataset.name] = o;
		this.labels = {};
		for( let o of this.controlPanel.$$( 'label[data-name]' ) )
			this.labels[o.dataset.name] = o;

		game.cardHolder = [];

		for( let i = 0; i<10; i++ ) {
			// Side names for rose
			// game.requireLayout( i, this.roseleaf[i] = construct( '.roseleaf ' + 'NESW'[i], this.rose ) );

			// Players info (avatar, nick..)
			let plr = game.checkPlayer( i );
			plr.elarrow.style.display = 'none';		// Стрелки не нужны, пусть не мешают
			plr.elavatarImg.width = 48;
			plr.elavatarImg.height = 48;

			let box = plr.box = construct( `.display_none.poker-player[data-plno=${i}]`, this.viewPanel );
			plr.elcash = construct( `span.clickable.play_cash[data-plno=${i}] ${plr.cash || ''}`, this.#cashClickBind );
			plr.elbet = construct( '.display_none.play_bet' );
			plr.timer.options.stopwatch = false;
			plr.timer.options.visibility = 'running';
			plr.timer.checkVisible();
			box.addEventListener( 'transitionend', e => {
				let cardholder = this.game.cardHolder[i];
				cardholder.options.waituntilready = null;
				cardholder.sizeControl( 'pokerlayout' );
			});
			// plr.elout = construct( '.display_none.play_out OUT' );
			let closedholder = construct( '.play_closed', box );
			let ch = new Cardholder( game, i, {
				plno: i,
				className: '',
				// classes: 'nopointerevents',
				// sort: 'always',
				sortType: 'poker',
				mincardstep: 1,
				align: 'c',
				closedHolder: closedholder,
				hideClosed: true,

				// useplayerholder: true,
				// multiLine: false,
				// resizeIfSide: true,
				// suitsplit: true,
				stepPercent: 100, // undefined,
				nooverlap: true,
				waituntilready: true,
				scale: 'holder'
			} );
			game.cardHolder[i] = ch;

			box.appendChild( plr.elavatar );
			box.appendChild( plr.elsitin );
			box.appendChild( plr.elnick );
			box.appendChild( plr.elbubble );
			box.appendChild( plr.elcash );
			box.appendChild( plr.elregion );
			box.appendChild( plr.elbet );
			box.appendChild( plr.timer.holder );
			box.appendChild( ch.holder );

			plr.elcombine = construct( '.poker-combine.fade', box );
		}
		this.#lastbets = [];

		this.table = html( `<div class='column center poker-table' style='justify-content: center'></div>`, this.viewPanel, this.controlClick.bind( this ) );
		// Background for poker-table
		this.flopsHolder = html( `<div class='poker-flops display_none' style='align-self: stretch'></div>`, this.table );
		this.potHolder = construct( '.poker-pot.hideempty.clickable', this.table );
		this.buttonElement = construct( '.display_none.poker-button D', this.viewPanel );
		this.gamenameElement = html( `<span class='display_none sheet gamename' style='font-size: 1.2rem; 
			    background: rgba( 0,0,0,0.5 ); color: white; border: 0.5px solid gray; align-self: center'></span>`,
			this.table );
		this.logHolder = this.controlPanel.$( '.deallog' );
		/*
				if( LOCALTEST ) {
					this.button = 0;
					setInterval( () => {
						this.button = (this.button + 1) % 10;
						this.layoutButton();
					}, 3000 );
				}
		*/

		this.data = {};
		this.flop = [];
		for( let i = 0; i<2; i++ ) {
			this.flop[i] = new Cardholder( game, 'flop_' + i, {
				className: 'flop column',
				sort: 'none',
				multiLine: false,
				stepPercent: 100,
				scale: 'holder'
			} );
			this.flopsHolder.appendChild( this.flop[i].holder );
		}

		allbypanels.set( this.viewPanel, this );
		if( pokerResizeObserver )
			pokerResizeObserver.observe( this.viewPanel );
		else {
			game.addResizeListener( this.layout.bind( this ) );
		}

		// this.controlPanel.appendChild( game.playZone.$( '.iamback' ) );

		game.playZone.appendChild( this.panel );
		game.playHolder = this.panel;
		game.playCards = this.panel;
		game.holder.classList.add( 'cardgamearea' );

		game.gameCards = new Gamecards( game );

		this._route = {};

		let hparser = this.handParser.bind( this );
		for( let i = 10; i--; ) {
			this._route[i + '_cardhand'] = hparser;
			this.game.showCash = () => {};

			// pl.elTextonpicture = construct( '.textonpicture.column', pl.elavatar );
			// pl.elTextonpicture.appendChild( pl.elnick );
			// pl.elTextonpicture.appendChild( pl.elcash );
		}

		game.addRoute( this );

		game.customLayout = this.layout.bind( this );

		document.addEventListener( 'wheel', this.wheel.bind( this ) );
		this.game.playZone.addEventListener( 'click', this.onclick.bind( this ) );

		this.showingMode = localStorage.pokershowingmode;
/*
		game.navi.bbscale = construct( `.grayhover.flexline.center BB`, game.naviIcons, () => {
			this.#toggleBlindScale();
		} );
		this.game.navi.bbscale.style.color = this.blindScale ? 'blue' : 'initial';
*/

		game.parseProto = this.parseProto.bind( this );
		game.makeSnapshot = this.makeSnapshot.bind( this );

		game.isFastSitout = () => {
			// Fast sitout allowed in cash game when only one player at table
			return this.isCash && this.game.realPlayers===1;
		};

		dispatch( 'regerschanged', minor => {
			log( 'checking reentry button for tour ' + minor );
			if( 'event_' + this.game.gameInfo?.tour?.id===minor ) {
				log( 'dive in' );
				this.checkReentryButton();
			}
		} );

		mediaWideChat.addListener( this.wideListener.bind( this ) );
		this.wideListener();
		this.game.littleIcons.style.order = -1;

		this.waitCssShow();

		if( DEBUG ) {
			window.setflop = this.route_flops.bind( this );
		}
	}

	wideListener() {
		let iconsparent = this.game.statusBar,
			chatparent = this.game.statusBar;
		if( mediaWideChat.matches ) {
			this.game.statusBar.show();
		} else {
			this.game.statusBar.hide();
			iconsparent = this.controlPanel.$( '.baseline' );
			chatparent = this.controlPanel;
		}
		iconsparent.appendChild( this.game.littleIcons );
		this.game.chatParent = chatparent;
		this.game.chat?.setParent( chatparent );
	}

	async waitCssShow() {
		await cssInject( 'poker' );
		this.layout();
		// if( LOCALTEST ) await sleep( 10000 );
		this.panel.show();
	}

	makeSnapshot( shot ) {
		shot.bets = this.arrbets?.join( ',' ) || '';
		shot.cash = this.game.cash?.join( ',' ) || '';
		shot.flops = this.flop.map( x => x.makeStr() );
		shot.pot = this.data.pot && Object.assign( this.data.pot ) || '';
		shot.combines = this.data.combines && Object.assign( this.data.combines ) || '';
	}

	route_game() {
		log( `POKER GAMEINFO sides=${this.game.gameInfo.sides}` );
		this.layout();
		this.checkGamename();
		this.checkTableBackground();

		// if( this.game.cash )
		// 	for( let i = this.game.maxPlayers; i--; )
		// 		this.showCash( i, this.game.cash[i] );
	}

	route_poker( o ) {
		this.panel.dataset.poker_phase = o.phase;
	}

	route_rules( o ) {
		this.#rules = o;
	}

	route_pokerstate( state ) {

		// Table logo should be grayed when playing
		this.pokerState = state;
		this.checkTableBackground();
	}

	checkTableBackground() {
		let team = this.game.team;
		if( team?.getPicture ) {
			this.tableLogo ||= html( `<div class='display_none table_logo' " +
				"style='pointer-events: none; position: absolute; left: 5%; width: 90%; top: 5%; height: 90%;
				transition: filter 1s, transform 1s;'></div>`, this.table );
			this.tableLogo.style.background = `url( '${STORAGE}/images/origin/${team.getPicture}' ) center / contain no-repeat`;
			let state = this.pokerState;
			let pause = state==='pausedeal' || state==='collect';
			this.tableLogo?.classList.toggle( 'playing', !pause );
			this.tableLogo.show();
		} else
			this.tableLogo?.hide();
	}

	route_playeracts( o ) {
		// Single action for one player
		this.route_bet( o );
		if( o.value ) {
			let b = this.arrbets[o.plno],
				ar = b?.split( ' ' ) || [ '', 0 ],
				v = +ar[1] || +ar[0] || 0,
				vnew = v + o.value;
			this.arrbets[o.plno] = o.text.toLowerCase() + ' ' + vnew;
			this.game.cash[o.plno] = (+this.game.cash[o.plno] || 0) - o.value;

			this.game.vgame.route( `bets ${this.arrbets.join( ',' )}\ncash  ${this.game.cash.join( ',' )}` );
		}
	}

	route_bets( str ) {
		let ar = this.arrbets = str?.split( ',' ) || [];
		// if( !str ) return;
		for( let i = this.game.maxPlayers; i--; ) {
			if( this.#lastbets[i]===ar[i] ) continue;
			this.#lastbets[i] = ar[i];
			let plr = this.game.players[i],
				el = plr.elbet;
			if( !ar[i] ) {
				el.hide();
				continue;
			}
			// Конструкция вида "Raise Allin 50" пока будет видна в виде  50 где ставка, и AllIn
			let a = ar[i].split( ' ' ),
				value = a.slice( -1 ).join( ' ' );
			if( ar[i].includes( 'All-In' ) ) value = 'All-In ' + value;
			el.textContent = this.getShow( value );
			el.dataset.pokeraction = a[0] || 'bet';
			el.dataset.scalablevalue = value;
			el.title = value;
			el.show();
		}
		this.checkGamename();
	}

	get blindScale() {
		return this.showingMode==='BB';
	}

	refreshValues() {
		// Переделаем все элементы, в которых присутствует сумма
		// for( let i = 10; i--; )
		// 	this.showCash( i, this.game.cash[i] );
		if( this.#buyPanel?.isVisible() )
			this.checkBuy( true );
		for( let o of this.panel.$$( '[data-scalablevalue]' ) )
			o.setContent( this.getShow( o.dataset.scalablevalue ) );

		this.checkBetSlider();
	}

	route_blinds( o ) {
		this.blind = o;
		// Перепоказать все, если режим - binds
		if( this.blindScale )
			this.refreshValues();
	}

	clearBubbles() {
		for( let i=10; i--; )
			this.game.players[i].elbubble.hide();
	}

	route_bet( o ) {
		if( typeof o==='string' ) {
			let ar = o.split( ' ', 2 );
			o = {
				plno: +ar[0],
				text: ar[1],
			};
		}
		let type = { bet: 'raise', check: 'call', f: 'fold' }[o.text.toLowerCase()] || o.text.toLowerCase(),
			el = this.game.players[o.plno].elbubble;
		el.setContent( o.text );
		el.dataset.pokercolor = type;
		el.show();
		if( !this.isReplay ) {
			setTimeout( () => {
				el.hide();
				// this.game.cardHolder[plno].show();
			}, 2000 );
		}
	}

	get isReplay() { return this.game.vgame.isReplay; }

	route_event( str ) {
		if( str==='newdeal' ) {
			for( let i = 10; i--; ) {
				this.game.players[i].elcombine.hide();
				this.game.players[i].elbubble.hide();
			}
		}
	}

	route_combines( o ) {
		this.data.combines = o;
		let ar = o?.split( ',' );
		for( let i=0; i<this.game.maxPlayers; i++ ) {
			let s = ar?.[i],
				combs = s?.match( /([^\[\]]*)(\[(.*)\])?$/ );

			this.combine( {
				plno: i,
				win: decodeURIComponent( combs?.[1] || '' ),
				lose: decodeURIComponent( combs?.[3] || '' )
			} );
		}
	}

	combine( o ) {
		let
			plno = o.plno,
			plr = this.game.players[plno],
			// type = { bet: 'raise', check: 'call' }[txt.toLowerCase()] || txt.toLowerCase(),
			el = plr.elcombine;
		// this.data.combines ||= [];
		// this.data.combines[plno] = o.name;
		let str = '';
		if( o.win ) str += `<span class='nowrap' style='background: green; color: white'>${o.win}</span>`;
		if( o.lose ) str += `<span class='nowrap' style='font-size: 0.8rem'>${o.lose}</span>`;
		el.setContent( str );
		// el.dataset.pokercolor = type;
		str.length? el.show() : el.hide();
		// if( !LOCALTEST )
		plr.elbubble.hide();
		if( !this.isReplay ) setTimeout( () => el.hide(), 30000 );
	}

	checkZeroCash() {
		if( !this.indeals || !this.game.cash ) return;
		for( let i = this.game.maxPlayers; i--; ) {
			this.showCash( i, this.game.cash[i] );
/*
			let cash = this.game.cash[i];
			if( cash!=='0' ) continue;
			let playingdeal = +this.indeals && ((+this.indeals >>> i) & 1);
			this.game.players[i].elcash.setContent( playingdeal ? 'All-in' : '0' );
*/
		}
	}

	#toggleBlindScale() {
		this.showingMode = this.blindScale ? '' : 'BB';
		localStorage.pokershowingmode = this.showingMode;
		this.game.navi.bbscale && ( this.game.navi.bbscale.style.color = this.blindScale ? 'blue' : 'initial' );
		this.refreshValues();
	}

	getShow( value, returnAsNumber ) {
		// Number can be converted to N BB
		// "All-in Number" as well
		if( value==='All-In' ) return 'All-In';
		if( !value || !this.blind ) return value;
		let prefix = '';
		if( value.slice?.(-1)===' ' ) {
			value = value.slice( 0, -1 );
			returnAsNumber = true;
		}
		if( !+value ) {
			let a = value.split( ' ' );
			if( !+a[1] ) return value;
			prefix = a[0] + ' ';
			value = a[1];
		}
		if( this.blindScale ) {
			// if( +value<this.blind.big ) return "0.5" + (nobb ? '' : ' BB');
			let val = +value / this.blind.big,
				str = val.toFixed();
			if( val<10 )
				str = Math.round( val*100 )/100;
			if( returnAsNumber ) return +str;
			return prefix + str.toString() + ' BB';
		}
		return returnAsNumber? +value : prefix + prettyFants( value );
	}

	showCash( plno, value ) {
		// Depends on showing mode shows cash or bb's
		if( value==='0' ) {
			let playingdeal = +this.indeals && ((+this.indeals >>> plno) & 1);
			if( playingdeal ) value = "All-In";
		}
		let str = this.getShow( value );
		let plr = this.game.players[plno];
		plr.elcash.dataset.scalablevalue = value;
		plr.elcash.title = value;
		plr.setCash( str );
	}

	route_cash() {
		let mynewcash = this.game.cash[this.game.myPlace];
		if( !this.game.isPlayer ) {
			this.buyinfo = null;
		} else {
			this.checkBuy( mynewcash!==this.#myCash && this.#buyPanel?.isVisible() );
		}
		this.#myCash = mynewcash;

		for( let i = this.game.maxPlayers; i--; )
			this.showCash( i, this.game.cash[i] );
		// this.checkZeroCash();
	}

	route_placechanged() {
		this.checkBuy();
		this.#isplayer = this.game.isPlayer;
		this.checkControlButtons();
	}

	route_players() {
		for( let i = this.game.maxPlayers; i--; ) {
			let plr = this.game.players[i];
			plr.box.makeVisible( plr.user || ( !this.game.isPlayer && this.#layoutMode==='all' ) );
		}
		if( this.realPlayers!==this.game.realPlayers ) {
			this.realPlayers = this.game.realPlayers;
			this.layout();
		}
	}

	route_outs( num ) {
		for( let i = this.game.maxPlayers; i--; ) {
			let p = this.game.players[i],
				out = (+num >>> i) & 1;
			p.box.classList.toggle( 'playerout', out );
			// p.elout?.makeVisible( out );
		}
		if( this.game.myPlace>=0 ) {
			this.controls.sitoutnexthand.checked = this.game.isout;
			this.checkControlButtons();
		}
	}

	get iamindeal() {
		return (+this.indeals >>> this.game.myPlace) & 1;
	}

	get mycash() {
		return this.game.cash?.[this.game.myPlace] || 0;
	}

	route_indeal( num ) {
		this.indeals = num;
		for( let i = 10; i--; ) {
			let p = this.game.players[i],
				indeal = !+num || (+num >>> i) & 1;
			p.box.classList.toggle( 'outdeal', !indeal );
			this.game.cardHolder[i].shadow( !indeal );
			// p.elout?.makeVisible( out );
		}
		this.checkZeroCash();
		this.checkControlButtons();
	}

	checkControlButtons( hideall ) {
		let player = this.game.isPlayer,
			iam = player && this.iamindeal;
		this.controls.playercolumn.makeVisible( player );
		if( this.isCash ) this.controls.askbuy.makeVisible( !iam || !this.mycash );
		this.controls.invite.makeVisible( player && (this.isCash || this.game.gameState==='notstarted') &&
			(!iam && this.controls.sitoutnexthand.checked || this.game.realPlayers===1) );
		// If this is tournament: we can register if we are not participated, or we can reentry if we're out
		// we have to get information from tournir (event_) subscription
		let myplace = this.game.getMyPlace( true ),
			cansit = myplace===-1 && this.#countFreePlaces>0;
		this.controls.tofight.makeVisible( cansit && !hideall );
		let fightParent = this.realPlayers===1? this.table : this.controlPanel.$( '.baseline' );
		fightParent.appendChild( this.controls.tofight );
		this.controls.chooseaplace.makeVisible( cansit && !hideall );
		this.controls.backtotable.makeVisible( myplace>=0 && this.game.myPlace===-1 && !hideall );
		this.checkReentryButton();
	}

	checkReentryButton() {
		let tourid = this.game.gameInfo?.tour?.id;
		let regvis = false, regtext = '{Register}';
		if( tourid ) {
			let me = elephSubscribe.get( 'me.event_' + tourid ),
				canreg = +this.game.subTour?.get( 'canreg' ) || false;
			regvis = canreg && ( !me || me.state==='out' );
			log( `canreg me=${me} canreg=${canreg} regvis=${regvis} tourid=${tourid}` );
			if( me?.state==='out' ) regtext = 'Re-entry';
		}
		// if( ...tourFillReg( this.game.gameInfo?.tourid )
		this.controls.register.makeVisible( regvis );
		if( regvis ) this.controls.register.setContent( regtext );
		// this.controls.reg?.hide();
	}

	route_flops( o ) {
		let filled = 0;
		if( o?.length ) {
			for( let f = 0; f<o.length; f++ ) {
				if( o[f] ) filled++;
				this.flop[f].setStr( o[f] );
			}
			this.flopsHolder.dataset.count = filled || 1; //o.length;
		} else
			for( let f = 0; f<2; f++ )
				this.flop[f].clear();

		this.checkGamename();
	}

	route_addflops( o ) {
		for( let f = 0; f<o.length; f++ )
			this.flop[f].add( o[f] );

		this.flopsHolder.dataset.count = o.length;
		if( this.isReplay ) this.clearBubbles();
	}

	route_pot( o ) {
		let str = '';
		this.data.pot = o;
		// o.total holds unraked total amount of bets
		if( o.bank ) {
			let val = o.bank;
			str = `<span style='color: lightgray'>{Pot}: </span><span class='cashstyle' style='pointer-events: none' data-scalablevalue='${val}'>${this.getShow( val )}</span>`;
			/*
						if( !o.bank && o.total ) str += o.total;
						else {
							str += o.bank;
							if( o.total>o.bank ) str += `+${o.total-o.bank}`;
						}
			*/
		}
		this.potHolder.setContent( str );
		if( this.isReplay ) this.clearBubbles();
	}

	get isCash() {
		return this.game.gameInfo.pokermoney==='cash';
	}

	async checkBuy( force ) {
		if( !this.game.isPlayer || !this.isCash ) {
			this.buyinfo = null;
			return;
		}
		let mycash = this.mycash;
		// if( !this.game.isout ) return;
		if( mycash>0 && !force ) return;

		if( !this.buyinfo || (!this.buyinfo.res && Date.now()>=this.buyinfo.waituntil) ) {
			this.buyinfo = { waituntil: Date.now() + 2000 };
			let res = await this.game.sendMove( `webaskbuy` );
			this.buyinfo = { res: res, nextrequesttime: Date.now() + 60 * 1000 };
		}

		this.#buyPanel ||= this.controlPanel.$( '.poker-buy' );
		let p = this.#buyPanel;

		if( !this.buyinfo.res?.max ) {
			this.buyinfo = null;
			p.hide();
			return;
		}

		let r = this.buyinfo.res,
			amount = this.controls.buy_amount,
			slider = this.controls.buy_slider,
			go = this.controls.buy_go,
			val = this.getShow( this.#lastSelectedValue.buy, true ) || r.value || (r.min + r.max) / 2;
		if( val<r.min ) val = r.min;
		if( val>r.max ) val = r.max;
		amount.min = slider.min = this.getShow( r.min, true );
		amount.max = slider.max = this.getShow( r.max, true );
		slider.step = this.blindScale ? '1' : '0.01';
		go.dataset.value = amount.value = slider.value = this.getShow( val, true );
		let what = this.blindScale ? this.getShow( val ) : `${val} ${this.game.currency || ''}`;
		go.setContent( `{Buy} ${what}` );
		// step удобно задавать для работы стрелочек (не по 1 фишке).
		// однако, если задан step, любые некратные значения приводят к
		// validity.valid===false, например, при движении слайдером
		// amount.step = r.precision;
		// this.controls.buy_go.textContent = val;

		// Установим шаг для покупки. Зависит от допустимого диапазона
		// slider.step = r.precision || 0.01;
		// amount.step = 0.01 чтобы ниогда не краснел
		slider.dataset.precision = this.blindScale ? 1 : r.precision;

		this.#buyPanel.$( '.poker-buyenough' ).makeVisible( r.avail>0 && r.max>0 );
		this.#buyPanel.$( '.poker-buynotenough' ).makeVisible( r.avail===0 );

		let availh = this.#buyPanel.$( '.poker-buyavail' );
		availh.setContent( `{Available}: ${r.avail} ${currency( r.currsymbol )}` );
		availh.makeVisible( r.avail>0 );

		p.show();
		this.buyinfo = null;

		// Auto buy for ToFight! button
		if( Date.now() < this.#autoBuy )
			this.#buyGo();
	}

	route_prebuttons( str ) {
		// Поставим нужные галочки
		let holder = this.controlPanel.$( '.poker-prebuttons' );
		holder.makeVisible( !!str && this.iamindeal );
		let check = str.match( /[kK]/ ),
			fold = str.match( /[fF]/ );
		this.labels.pre_check.toggleAttribute( 'disabled', !check && !fold );
		this.controls.pre_check.checked = str.match( /[KF]/ );
		this.labels.pre_check.$( 'span' ).textContent = check ? 'Check/Fold' : 'Fold';
		let calldisabled = !str.match( /[cCbB]/ );
		this.controls.pre_call.checked = str.match( /[CB]/ );
		this.labels.pre_call.toggleAttribute( 'disabled', calldisabled );
		let raisedisabled = !str.match( /[pP]/ );
		this.controls.pre_bet.checked = str.match( /P/ );
		this.labels.pre_bet.toggleAttribute( 'disabled', raisedisabled );
	}

	static #limitnames = { P: 'Pot', N: 'No', H: 'Half Pot', F: 'Fixed' };
	static #ptypenames = { H: "Hold'em", O: 'Omaha', O5: '5 cards Omaha', SD: 'Short Deck' };

	checkChoice( o, getcheckbox ) {
		this.choice ||= {};
		const parts = [ 'H', 'O', 'O5', 'SD' ];
		if( o ) {
			this.choice.hilow = false;
			for( let part of o.toUpperCase().split( ' ' ) ) {
				if( [ 'PL', 'NL', 'FL', 'HL' ].includes( part ) ) this.choice.limit = part;
				else if( part==='X2' ) this.choice.twoflops = true;
				else if( part==='COURCH' ) this.choice.courch = true;
				else if( part==='HILO' || part==='HI/LO' ) this.choice.hilow = true;
				else if( parts.includes( part ) ) this.choice.ptype = part;
			}
			this.choice.ptype ||= 'H';
			this.choice.limit ||= 'NL';
			// [this.choice.ptype, this.choice.limit] = o.split( ' ' );
			// this.choice.twoflops = o.includes( 'twoflops' ) || o.includes( 'X2' );
			// this.choice.courch = o.includes( 'courch' );
			if( getcheckbox )
				this.controls.select_twoflops.checked = this.choice.twoflops;
			else
				this.choice.twoflops = this.controls.select_twoflops.checked;
		}
		let ptype = this.choice.ptype.toLowerCase(),
			texas = ptype==='h',
			omaha = ptype.startsWith( 'o' ),
			shortdeck = ptype==='sd';
		/*
				this.controls.selectlimit_fl.disabled = !texas;
				this.controls.selectlimit_pl.disabled = !omaha;
				this.controls.selectlimit_nl.disabled = omaha;
		*/
		if( omaha ) this.choice.limit = 'PL';
		if( shortdeck ) this.choice.limit = 'NL';
		if( texas && this.choice.limit==='PL' ) this.choice.limit = 'NL';
		let gamename = this.choice.ptype,
			str = this.choice.limit + ' ' + gamename,
			name = this.choice.limit + gamename,
			fullname = Poker.#limitnames[this.choice.limit[0]] + ' Limit ';
		if( this.choice.twoflops )
			fullname = this.choice.limit;
		fullname += ' ' + Poker.#ptypenames[gamename];
		if( this.choice.hilow ) {
			str += ' Hi/Lo';
			name += ' Hi/Lo';
			fullname += ' Hi/Low';
		}
		if( this.choice.twoflops ) {
			str += ' x2';
			name += ' x2';
			fullname += ' {twoflops}';
		}
		this.controls.select_go.setContent( `<span style='pointer-events: none; '>${str}</span>
			<span style='pointer-events: none; font-size: 50%; color: lightgray'>${fullname}</span>` );
		this.game.sendMove( 'prechoice ' + str );
		localStorage.poker_dealerchoice = str;
		sessionStorage.poker_dealerchoice = str;
	}

	wheel( e ) {
		if( !this.move || !this.move.slider ) return;
		e.stopPropagation();
		// e.preventDefault();
		let slider = this.move.slider,
			d = e.deltaY,
			val = +this.controls.bet_slider.value,
			shift = (slider.max - slider.min) / 1000 * d,
			newval = val + shift;

		newval = Math.round( newval * 100 ) / 100;
		if( newval<slider.min ) newval = slider.min;
		if( newval>slider.max ) newval = slider.max;

		this.setAmount( { value: newval } );
	}

	checkBetSlider() {
		let o = this.move;
		if( !o?.slider ) return;
		let raise = this.controls.bet_go,
			slider = this.controls.bet_slider,
			val = this.#lastSelectedValue.bet || o.slider.value;
		slider.min = this.getShow( o.slider.min, true );
		slider.max = this.getShow( o.slider.max, true );
		slider.value = this.getShow( val, true );
		slider.step = o.slider?.step || ( this.blindScale ? '1' : '0.01' );
		raise.dataset.value = this.controls.bet_amount.value = slider.value = this.getShow( val, true );
		raise.textContent = this.getShow( val );
	}

	route_move( o ) {
		this.move = o;
		this.betPanel ||= this.controlPanel.$( '.poker-selectbet' );
		this.choicePanel ||= this.controlPanel.$( '.poker-dealerchoice' );
		this.#lastSelectedValue.bet = null;

		if( !o ) {
			this.betPanel.hide();
			this.choicePanel.hide();
			return;
		}

		if( o.dochoice ) {
			this.checkChoice( sessionStorage.poker_dealerchoice || o.lastchoice || localStorage.poker_dealerchoice || 'Holdem NL twoflops' );
			this.choicePanel.show();
			return;
		}

		let raise = this.controls.bet_go,
			call = this.controls.call,
			fold = this.controls.fold;
		this.betPanel.makeVisible( o.fold || o.callcheck || o.raise );
		if( o.raise ) {
			raise.dataset.value = o.raise;
			raise.dataset.scalablevalue = o.raise;
			raise.textContent = this.getShow( o.raise );
			raise.disabled = false;
		} else
			raise.disabled = true;

		if( o.callcheck ) {
			call.textContent = this.getShow( o.callcheck );
			call.dataset.scalablevalue = o.callcheck;
			call.disabled = false;
		} else {
			call.disabled = true;
			call.dataset.scalablevalue = '';
		}

		if( o.slider || o.buttons ) {
			this.betPanel.$( '.betselection' ).show();

			if( o.slider ) {
				this.checkBetSlider();
				raise.disabled = false;

				this.controls.bet_amount.show();
				this.controls.bet_slider.show();
				this.controls.bet_fast?.show();
				this.controls.bet_go.show();
			} else {
				this.controls.bet_amount.hide();
				this.controls.bet_slider.hide();
				this.controls.bet_fast?.hide();
			}

			let buttons = this.betPanel.$( '.betbuttons' ),
				bb = [...o.buttons];
			if( o.slider ) bb.push( [o.slider.max, 'max'] );
			bb.sort( ( x, y ) => {
				if( x[0]!==y[0] ) return x[0] - y[0];
				// Вначале P (pot), потом B (blind). На случай, если совпадут
				// if( !/\d/.test( x[1] ) ) return -1;
				// if( !/\d/.test( y[1] ) ) return 1;
				if( x[1].includes( 'P' ) ) return -1;
				if( y[1].includes( 'P' ) ) return 1;
				return 0;
			} );
			if( bb ) {
				let str = '', lastval;
				for( let k of bb ) {
					if( k[0]===lastval ) continue;
					let n = k[1]; // .replace( /P$/, '' );
					if( this.narrow && n==='All-In' ) n = 'max';
					str += `<button name='bet_fast' data-betname='${k[1]}' data-chip='1' value='${k[0]}'>${n}</button>`;
					lastval = k[0];
				}
				buttons.setContent( str );
				buttons.show();
			} else {
				buttons.hide();
			}
		} else {
			this.betPanel.$( '.betselection' ).hide();
		}

		fold.makeVisible( o.fold || false );
		this.betPanel.classList.toggle( 'withslider', !!o.slider );
		this.#checkBetButtons();
	}

	#checkBetButtons() {
		if( !this.move || !this.betPanel ) return;
		let short = this.move.slider && this.narrow;
		this.controls.fold.setContent( short ? "F" : "Fold" );
		let allin = this.betPanel.$( '[data-betname="All-In"]' );
		if( allin ) allin.setContent( this.narrow ? '↟' : 'All-in' );
		let minbet = this.betPanel.$( '[data-betname="min"]' );
		if( minbet ) minbet.setContent( this.narrow ? '↡' : 'min' );
	}

	setAmount( t ) {
		let val, slider, prefix = 'bet';
		if( t instanceof HTMLElement ) {
			prefix = t.name.split( '_' )[0];
			if( !t.validity.valid ) return;
			val = t.value;
			slider = this.controls[`${prefix}_slider`];
		} else {
			val = t.value;
			slider = this.controls.bet_slider;
		}
		this.#semafore = true;
		// Если мы работаем в размерности BB, а пришли chips, конвертируем
		if( this.blindScale && t.dataset?.chip )
			val = +this.getShow( val, true );
		if( val==='max' ) val = slider.max;
		if( val > slider.max ) val = slider.max;
		if( t===slider && val!==slider.max && val!==slider.min && slider.dataset.precision ) {
			val = val - ((val * 100) % (+slider.dataset.precision * 100)) / 100;
		}
		if( t!==this.controls[`${prefix}_amount`] ) this.controls[`${prefix}_amount`].value = val;
		if( t!==slider ) this.controls[`${prefix}_slider`].value = val;
		this.controls[`${prefix}_go`].dataset.value = val;
		let what = this.blindScale ? 'BB' : this.game.currency,
			chipval = this.blindScale? val*this.blind.big : val;
		this.#lastSelectedValue[prefix] = chipval; // this.blindScale ? val * this.blind.big : val;
		if( prefix==='buy' )
			this.controls.buy_go.setContent( `{Buy} ${val} ${what}` );
		else {
			if( !this.blindScale ) what = '';
			this.controls[`${prefix}_go`].setContent( `${val} ${what}` );
		}
		this.#semafore = false;
	}

	input( e ) {
		if( this.#semafore ) return;
		let t = e.target;
		if( /_amount|_slider/.test( t.name ) ) {
			// Синхронизируем выбранные значения
			return this.setAmount( t );
		}

		if( t.name?.startsWith( 'pre_' ) ) {
			// Кнопки предварительных действий
			this.game.sendMove( `pre.${t.name.split( '_' )[1]}.${+t.checked}` );
			return;
		}

		if( t.name==='select_twoflops' ) {
			this.choice.twoflops = t.checked;
			return this.checkChoice();
		}

		if( t.name==='sitoutnexthand' ) {
			this.sitoutnexthand = t.checked;
			// Send to server this selection
			this.game.sendMove( 'sitoutnexthand ' + (t.checked ? 1 : 0) );

		}
	}

	hideBuy() {
		this.buyinfo = null;
		this.#buyPanel.hide();
	}

	onclick( e ) {
		let t = e.target,
			cl = t.classList;
		if( cl.contains( 'play_cash' ) || cl.contains( 'poker-pot' ) || cl.contains( 'play_bet' ) ) {
			this.#toggleBlindScale();
		}
	}

	async controlClick( e ) {
		let t = e.target;
		if( t.dataset.action==='closebuy' ) {
			return this.hideBuy();
		}

		if( t.name==='register' ) {
			// Отправляем запрос на регистрацию в турнир
			if( !this.game.gameInfo?.tour?.id ) return;
			t.disabled = true;
			let res = await elephCore.do( `type=register event=${this.game.gameInfo.tour.id} on=1` );
			log( `reg respond is ${JSON.stringify( res ) }` );
			t.disabled = false;
			t.hide();
			return;
		}

		if( t.dataset.action==='deallog' ) {
			// Клик на протоколе сдаче, откроем preview
			this.game.preview ||= new Vgame( {
				id: this.game.item + '_proto',
				game: 'poker',
				bigwindow: true,
				autoshow: true,
				setproto: this.dealProto
			} );
			this.game.preview.show();
		}

		if( t.name==='invite' ) {
			return this.game.invite();
		}
		if( t.name==='askbuy' ) {
			return this.checkBuy( true );
		}
		if( t.name==='tofight' )
			// Auto sit any place and request to buy chips
			return this.#toFight();
		if( t.name==='backtotable' ) {
			// Sit on my reserved place
			if( this.game.myPlace>=0 ) return;
			this.game.sitAny();
			return;
		}
		if( t.name==='chooseaplace' ) {
			// Switch to layout mode "all places"
			this.#layoutMode = this.#layoutMode==='all'? '' : 'all';
			this.layout();
			return;
		}

		if( !t.name ) return;
		if( t.name==='fold' || t.name==='call' ) {
			this.game.sendMove( t.name );
			return;
		}
		if( t.name==='bet_go' ) {
			let val = t.dataset.value;
			if( this.blindScale ) val = +val * this.blind.big;
			this.game.sendMove( 'bet ' + val );
			return;
		}
		if( t.name==='bet_fast' ) {
			this.setAmount( t );
			return;
		}
		// Блок покупки
		if( t.name==='buy_go' ) {
			this.#buyGo();
			return;
		}

		if( t.name.startsWith( 'selectrule_' ) ) {
			let rule = t.name.split( '_' )[1],
				ar = rule.split( '-' );
/*
			if( ar.length>1 ) {
				this.choice.limit = ar[0];
				this.choice.ptype = ar[1];
			} else
				this.choice.ptype = ar[0];
*/
			return this.checkChoice( rule.replaceAll( '-', ' ' ), true );
		}
		if( t.name.startsWith( 'selectlimit_' ) ) {
			this.choice.limit = t.name.split( '_' )[1].toUpperCase();
			return this.checkChoice();
		}
		if( t.name==='select_go' ) {
			return this.game.sendMove( 'gochoice' );
		}
	}

	async #buyGo() {
		this.#buyPanel?.classList.add( 'disabled' );
		setTimeout( () => this.#buyPanel.classList.remove( 'disabled' ), 1000 );
		let val = this.controls.buy_go.dataset.value;
		if( this.blindScale ) val = +val * this.blind.big;
		let res = await this.game.sendMove( 'webbuy ' + val );
		if( res?.ok ) {
			this.hideBuy();
		}
	}

	// Размещение игроков вокруг стола. Зависит от того, где наблюдатель (всегда внизу),
	// сколько всего игроков за столом, надо ли показывать пустые (могу ли сесть)
	// также опираемся на размеры экрана (больше по вертикали сажать или по горизонтали)
	// Варианты мест:
	// landscape lluuurrbbb
	// portrait  blllltrrrr
	// balance   lluuurrbbb
	// Размеры стола тоже гибко
	#tablesizes = { portrait: [0.2, 0.2, 0.6, 0.6] };

	layout( entry ) {
		let gi = this.game.gameInfo;
		if( !gi ) return;
		if( !entry && window.ResizeObserver && !this.observed ) return;
		// this.tableRect = this.table.getBoundingClientRect();

		let rect = entry?.contentRect || this.observed || this.viewPanel.getBoundingClientRect(),
			w = rect.width,
			h = rect.height,
			ratio = w / h,
			orient = (h / w)>1.5 ? 'portrait' : 'landscape',
			tablemodel = this.#tablesizes[orient] || [0.2, 0.2, 0.6, 0.6],
			tablerect = {
				left: rect.width * tablemodel[0],
				top: rect.height * tablemodel[1],
				width: rect.width * tablemodel[2],
				height: rect.height * tablemodel[3],
			},
			orientation = ratio>2 ? 'landscape' : (ratio<0.9 ? 'portrait' : 'balance'),
			narrow = orientation==='portrait' && w<500,
			topsideY = 0,
			models = {
				10: {
					landscape: '323',
					portrait: '141',
					balance: '232'
				},
				2: '101',
				8: {
					landscape: '222',
					portrait: '131',
					balance: '222'
				}
			},
			allmodels = {
				1: '1',
				2: '101',
				3: '110',
				4: '111',
				5: {
					portrait: '120',
					balance: '112',
					landscape: '104',
				},
				6: {
					portrait: '121',
					balance: '121',
					landscape: '113',
				},
				7: {
					portrait: '122',
					balance: '122',
					landscape: '114',
				},
				8: {
					portrait: '131',
					balance: '131',
					landscape: '214',
				},
				9: {
					portrait: '140',
					balance: '140',
					landscape: '314',
				},
			},
			sides = gi.sides || 10,
			sidemodels = models[sides] || models[10],
			model = sidemodels[orientation] || sidemodels;
		if( !w || !h ) return;
		this.viewPanel.style.setProperty('--fontsize', Math.min( w/25, h/25 ) + 'px' );
		this.viewPanel.style.setProperty('--gameareawidth', w + 'px' );
		this.viewPanel.style.setProperty('--gameareaheight', h + 'px' );
		this.viewPanel.style.setProperty('--gameareacx', w/2 + 'px' );
		this.viewPanel.style.setProperty('--gameareacy', h/2 + 'px' );
		this.narrow = narrow;
		log( `POKER LAYOUT: sides=${sides} ${JSON.stringify( rect )}` );
		// Detect how many real players are present if we show only them
		// placesmap - array of showing places starting from my (bottom) place
		let placesmap = [],
			myplace = this.game.getMyPlace( true ),
			startplace = myplace>=0? myplace : 0;
		// Если игроков за столом меньше числа мест, и мы в игре, или просто нельзя сесть
		// изменим показ для красивой симметрии
		let countempty = this.game.countEmptyPlaces;
		if( ( myplace>=0 || !countempty || this.#layoutMode!=='all' ) ) {
			for( let i = 0; i<sides; i++ ) {
				let n = (i + startplace) % sides;
				if( !this.game.players[n].uid ) continue;
				placesmap.push( n );
			}
			log( `LAYOUT myplace=${myplace} mode=${this.#layoutMode} empty=${countempty} places=${placesmap.length}` );
			if( allmodels[placesmap.length] )
				model = allmodels[placesmap.length][orientation] || allmodels[placesmap.length];
			else {
				// Не знаем как проецировать игроков, покажем всех
				log( `layout pretty failed` );
				placesmap = [];
			}
		}
		if( !placesmap.length ) {
			for( let i = 0; i<sides; i++ ) {
				placesmap.push( (startplace + i) % sides );
			}
		}
		if( entry ) this.observed = entry.contentRect;

		this.table.style.left = tablerect.left + 'px';
		this.table.style.top = tablerect.top + 'px';
		this.table.style.width = tablerect.width + 'px';
		this.table.style.height = tablerect.height + 'px';

		// По полученной модели делаем массив позиций
		let places = placesmap.length,
			positions = [];

		function makeLine( c, type ) {
			let side = 'left right'.includes( type ),
				size = side ? tablerect.height : tablerect.width,
				step = size / c,
				line = positions.length;
			for( let pos = step / 2; c--; pos += step ) {
				let b = {
					line: line,
					side: side,
					type: type,
					x: tablerect.left + (side ? (type==='left' ? 0 : tablerect.width) : pos),
					y: tablerect.top + (side ? pos : (type==='top' ? 0 : tablerect.height))
				};
				if( narrow && type==='left' ) {
					b.x = '0px';
					b.buttonTransform = `translate( ${tablerect.left}px , calc( ${b.y}px - 40px ) )`;
				}
				if( narrow && type==='right' ) {
					b.x = `calc( ${w}px - 100% )`;
					b.buttonTransform = `translate( calc( ${tablerect.left + tablerect.width}px - 100% ) , calc( ${b.y}px - 40px ) )`;
				}
				if( side && (!topsideY || b.y<topsideY) ) topsideY = b.y;
				positions.push( b );
			}
		}

		makeLine( +model[0], 'bottom' );
		makeLine( +model[1], 'left' );
		makeLine( +model[2], 'top' );
		makeLine( +model[1], 'right' );

		// Sort the prepared array
		positions.sort( ( a, b ) => {
			if( a.line!==b.line ) return a.line - b.line;
			if( a.type==='bottom' ) return b.x - a.x;
			if( a.type==='left' ) return b.y - a.y;
			if( a.type==='top' ) return a.x - b.x;
			if( a.type==='right' ) return a.y - b.y;
		} );

		// Если на нижнем борту 3 игрока, то первого переставляем в конец массива
		if( +model[0]===3 ) positions.push( positions.splice( 0, 1 ) );

		// Теперь позиционируем боксы в соответствии с полученным массивом
		this.layoutPos = [];
		for( let pos in placesmap ) {
			let plno = placesmap[pos],
				plr = this.game.getPlayer( plno );
			if( !plr ) {
				log( `poker: Failed layout: placesmap=${JSON.stringify( placesmap )} sides=${sides} plno=${plno}` )
			}
			let
				box = plr.box,
				bb = positions[pos];

			this.layoutPos[plno] = bb;

			/*
							y = Math.cos( degree ),
							x = -Math.sin( degree ),
							shiftx = `calc( ${(x*0.35+0.5)*w}px - 50% )`,
							rl = Math.abs( x )>Math.abs( y );
						degree += 3.14*2/10;
						if( narrow && Math.abs( x )>0.1 )
							shiftx = x<0? '0' : `calc( ${w}px - 100% )`;
						let ypos = b.dataset.y = (y*0.42+0.50)*h;
						log( `y=${y}, translate( ${shiftx}, ${ypos}px )` );
			*/
			let shiftx = bb.x, shifty = bb.y,
				addx = { left: '- 100%', right: '- 0%' }[bb.type] || '- 50%',
				addy = { top: '- 100%', bottom: '- 0%' }[bb.type] || '- 50%',
				tr_x = `calc( ${shiftx}px ${addx} )`,
				tr_y = `calc( ${shifty}px ${addy} )`;

			if( typeof bb.x==='string' ) tr_x = bb.x;

			box.style.transform = `translate( ${tr_x}, ${tr_y} )`;
			box.style.maxHeight = (rect.height - tablerect.height)/2 + 'px';
			box.classList.toggle( 'side', bb.side );
			box.dataset.tablepos = bb.type;
			box.classList.toggle( 'overflop', narrow && bb.side && (pos===3 || pos===6) );
			box.classList.toggle( 'myself', this.game.myPlace===plno );

			let al = 'c';
			if( narrow ) {
				if( bb.type==='left' ) al = 'l';
				if( bb.type==='right' ) al = 'r';
			}
			let cardholder = this.game.cardHolder[plno];
			cardholder.options.align = al;

			// Если я уже за столом, показывать пустые боксы не нужно
			if( plr.uid ) box.dataset.uin = plr.uid;
			else delete box.dataset.uin;
			box.makeVisible( LOCALTEST || plr.user || !this.game.isPlayer );
			// Need to move cards. Нет смысла, т.к. срабатывает быстрее чем transform
			/*
						if( LOCALTEST ) {
							plr.elbet.textContent = '100';
							plr.elbet.show();
							plr.elcombine.textContent = 'Hello';
							plr.elcombine.show();
							plr.elbubble.textContent = 'Raise';
							plr.elbubble.show();
						}
			*/
		}
		this.game.sizeCheck();

		// Мы обработали только игроков, которые видны за столом. Остальных надо убрать
		for( let i = sides; i--; )
			if( !placesmap.includes( i ) ) this.game.players[i].box.hide();
		this.layoutButton();
		if( topsideY && places>=6 )
			this.flopsHolder.style.top = topsideY - tablerect.top - tablerect.height/4 + 'px';
		else if( ( LOCALTEST || places>=3 ) && this.#rules?.ptype.startsWith( 'OMAHA' ) )
			this.flopsHolder.style.top = '-30%';
		else
			delete this.flopsHolder.style.top;

		// Вычислим макс размер флоп-панели так, чтобы помещалось 5 карт
		let maxcardw = tablerect.width * 0.9 / 5,
			maxcardh = maxcardw / 0.8;
		if( maxcardh>this.game.cards.unifiedHeight ) maxcardh = this.game.cards.unifiedHeight;
		this.flopsHolder.style.setProperty( '--maxcardheight', maxcardh + 'px' );

		// this.controlPanel.show(); // .makeVisible( this.game.isPlayer );

		this.flop[0].sizeControl();
		this.flop[1].sizeControl();

		this.#checkBetButtons();
		this.#checkSitButtons();
	}

	#checkSitButtons() {
		// for( let p = this.game.maxPlayers; p--; )
		// 	this.game.players[p].box.makeVisible( this.#layoutMode==='all' );
	}

	route_dealerbutton( pos ) {
		this.button = +pos;
		if( LOCALTEST && this.button<0 ) this.button = 0;
		this.layoutButton();
	}

	getdeallogstr( str ) {
		return str.replace( /<b>(.*?)<\/b>/g, ( s, body ) => {
			return `<span class='money' data-scalablevalue='${body}' title='${body}'>${this.getShow(body,true)}</span>`;
		} );
	}

	route_adddeallog( str ) {
		// this.dealLog = (this.dealLog || '') + str + '<br>';
		this.logHolder?.langInsertAdjacentHTML( 'beforeend', this.getdeallogstr( str ) + '<br>' );
	}

	route_deallog( str ) {
		this.logHolder?.setContent( this.getdeallogstr( str ) );
	}

	route_dealproto( str ) {
		this.dealProto = str;
	}

	layoutButton() {
		// Dealer button всегда между игроком и столом
		if( !this.layoutPos ) return;
		if( this.button===undefined || this.button<0 ) return this.buttonElement.hide();
		if( !this.layoutPos[this.buttons] ) return this.buttonElement.hide();
		let box = this.game.players[this.button].box,
			transf = this.layoutPos[this.button].buttonTransform;
		if( !transf ) {
			transf = box.style.transform;
			if( !transf ) return;
			let pos = box.dataset.tablepos,
				add = {
					left: ' 100%, -1em',
					right: '-100%, -1em',
					top: '-2.5em, 100%',
					bottom: '-2.5em, -100%'
				}[pos];
			transf += ` translate(${add})`;
		}

		// let trect = this.table.getBoundingClientRect();
		/*
				if( pos==='left' ) {
					transf = `translate( calc( ${trect.left}px + 50% ), calc( ${box.dataset.y}px - 200% ) )`;
				} else if( pos==='right' ) {
					transf = `translate( calc( ${trect.right}px - 150% ), calc( ${box.dataset.y}px - 200% ) )`;
				}
		*/

		this.buttonElement.dataset.plno = this.button;
		this.buttonElement.style.transform = transf;
		this.buttonElement.show();
	}

	handParser( str, key, secret ) {
		if( !(key[0]>='0' && key[0]<='9') ) return;
		let plno = key[0] - '0',
			ch = this.game.cardHolder[plno];
		let vis = ch.isOpened();
		this.game.players[plno].elnick.makeVisible( !vis );
		if( !vis && this.game.gameState==='dealcompleted' )
			ch.clear();
	}

	route_hands( str ) {
		let ar = str?.split( ',' ) || [];
		for( let p = this.game.maxPlayers; p--; )
			this.game.cardHolder[p]?.setStr( ar[p] || '' );
		this.checkGamename();
	}

	#cashClick( e ) {
		// Клик на кэш. Если это мы, и это кэш-гейм, то спросим о докупке
		// let t = e.target,
		// 	plno = t.dataset.plno;
		// if( +plno===this.game.myPlace && this.isCash ) {
		// 	this.checkBuy( true );
		// }
	}

	static #acts = { B: 'Raise', C: 'Call', H: 'Check', F: 'Fold' };
	static #emos = { B: '🔴', C: '🟢', H: '🟢', F: '🟤' };

	async parseProto( proto, full ) {
		proto = typeof proto==='string' ? proto : proto.get( 'p' );
		let ar = proto.split( '-' );

		this.game.addFrame( {
			name: 'start',
			snapshot: 'make'
		} );

		for( let f of ar ) {
			let b = f.match( /(\d)(\w)(.*)|F(.*)|P(.*)|G(.*)|C(.*)/ );
			if( !b ) continue;
			if( b[7] ) {
				// C - combines
				this.game.addFrame( {
					play: {
						combines: b[7]
					},
					name: '🏁'
				} );
			}
			if( b[6] ) {
				// G - game
				let left = b[6].slice( 4 );
				this.game.addFrame( {
					play: {
						rules: {
							limit: b[6].slice( 0,2 ),
							ptype: b[6].slice( 2,2 ),
							hilow: left.includes( 'l' ),
							flops: left.includes( '2' )? 2 : 1
						},
						hands: full.hands
					},
					name: '🚦'
				} );
				continue;
			}
			if( b[5] ) {
				// P - pot
				this.game.addFrame( {
					play: {
						pot: {
							total: +b[5]
						},
						bets: ''
					},
					name: 'pot'
				} );
				continue;
			}
			if( b[4] ) {
				// F - flop. Add cards to tlop
				this.game.addFrame( {
					play: {
						addflops: b[4].split( ',' )
					},
					name: 'flop'
				} );
				continue;
			}
			if( b[2] ) {
				// Player action
				if( b[2]==='D' ) {
/*
					// Hand combine
					this.game.addFrame( {
						play: {
							combine: {
								plno: +b[1],
								name: b[3]
							}
						},
						name: 'compare'
					} );
*/
					continue;
				}
				this.game.addFrame( {
					play: {
						playeracts: {
							plno: +b[1],
							text: Poker.#acts[b[2]] || b[2],
							value: +b[3]
						}
					},
					name: Poker.#emos[b[2]] || '⚪'
				} );
			}
		}
	}

	checkGamename() {
		// Show game title if no cards, no flop. Or hide it
		let somecards = this.flop[0].count || this.arrbets?.find( x => !!x );
		if( !somecards ) {
			for( let p = this.game.maxPlayers; somecards && p--; )
				if( this.game.cardHolder[p]?.count ) somecards = true;
		}
		if( !somecards )
			this.gamenameElement.setContent( this.game.gameInfo.title || '' );
		this.gamenameElement.makeVisible( !somecards );
		this.flopsHolder.makeVisible( !!somecards );
	}

	get #countFreePlaces() {
		let c = 0;
		for( let i=0; i<this.game.maxPlayers; i++ )
			if( this.game.players[i].sitavail ) c++;
		return c;
	}

	async #toFight() {
		let hasplace = this.game.getMyPlace( true )>=0;
		if( hasplace ) return;
		let allowed = [];
		for( let i=0; i<this.game.maxPlayers; i++ ) {
			let p = this.game.players[i];
			if( p.sitavail ) allowed.push( i );
		}
		if( !allowed.length ) return;
		let place = allowed[Math.floor( Math.random()*allowed.length )];
		this.checkControlButtons( true );
		setTimeout( this.checkControlButtons.bind( this ), 1000 );
		this.#autoBuy = Date.now() + 1000;	// Auto rebuy in one sec
		let res = await this.game.sitRequest( place, 'random' );
	}
}

