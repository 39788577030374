export default async function shopping( params ) {
	// if( window.inapppurchaseOnly() ) {
	if( !window.DEBUG && window.CdvPurchase ) {
		let mod = await import( './purchase.js' );
		mod.buy( params );
		return;
	}

	if( window.coreParams?.easypay && !window.STATIC || LOCALTEST ) {
		import( './easypay.js' ).then( mod => {
			mod.pay( params );
		} );
		return;
	}
	if( NEOBRIDGE ) return;
	params.ids = params.ids?.toLowerCase() || '';
	log( `Lets go shopping: ${params.ids} for ${params.reason}. Title: ${params.title}` );
	// Открываем внутренний магазин, если регистрировались из приложения и не платили по-другому
	if( window.FB ) {
		log( 'No payments in FB yet' );
		return;
	}
	// if( !Core.auth.long_token ) {
		let res = await checkAuth( 'complete' );
		if( res==='error' ) return;
	// }
	let str = `?separate=1&authorization=${encodeURI(AUTH)}`;
	if( !params.ids && FANTGAMES ) params.ids = 'fants';
	if( params.ids ) str += '&ids=' + params.ids.replace( /\s/g, ',' );
	if( params.reason ) str += '&reason=' + (window.cordova ? 'app_' : '') + encodeURI( params.reason );
	if( !params.title && params.reason ) params.title = {
		'kibi': '{Observation}'
	}[params.reason];
	if( params.game ) str += `&game=${params.game}`;
	str += '&referer=' + encodeURIComponent( location.pathname );
	if( params.title ) str += '&title=' + encodeURIComponent( params.title );
	let url = `${CLIENTHOST}/shop/`;
	if( params.ids==='fants' ) url += 'fants';
	url += str;
	if( params.addstr ) url += '&' + params.addstr;
	// if( DEBUG )
	localBrowser( url + '&use_widget=1' );
	// else
	// 	inappBrowser( url, 'width=500,height=400' );
	/*
			if( !window.bigBrowser ) {
				window.bigBrowser = makeBigWindow( `<iframe class='browser'></iframe>` );

				bigBrowser.iframe = bigBrowser.querySelector( 'iframe' );
				// bigBrowser.iframe.csp = "img-src *";
				// bigBrowser.referrerPolicy = "origin";
				bigBrowser.onHide = () => {
					bigBrowser.iframe.src = '';
				};
			}
			bigBrowser.iframe.src = url;
			bigBrowser.show();
		}
	*/
	log( `Shopping: https://${CLIENTHOST}/shop/${str}` );
	// window.open(  ); // , '_system' );
};

