// http://localhost:63342/navigator/www/neobridge.html?v=all&d=s&n=S98HK1092DQJ5C9873&s=SA4HAQ876DA10CA1054&a=1hp2hp2sp3hp4hppp&scoring=IMP&p=CQ&e=-SQ107532H543D76CK2
"use strict";

const REMOVEME = false;		// true for debug, remove when its ok

// BBO handviewer example
// https://www.bridgebase.com/tools/handviewer.html?b=16&v=e&d=W&tbt=y&a=ppp4Hpppppp&n=SAQ76HAT76D2CKJT4&s=ST84H98543DT987CA&e=S532HQJ2DQ54CQ976&w=SKJ9HKDAKJ63C8532&nn=Mehmet Ali̇ İnce&sn=Namik Kökten&en=Bülent Özgür Torun&wn=Tayfun Özbey
// TODO: миниэтюды играются в режиме ddsproblem
//
// http://localhost:63342/navigator/www/neobridge.html?w=AQ5.74.9842.Q753&n=2.J953.J65.K1942&e=KJ876.K86.Ak3.Aj&s=T943.AQ102.Q107.86&a=1Sp2Sp4Sppp&d=e&v=n
// first problem of first move book

// window.SOLO = true;
// window.NOCHAT = true;
import './support.js';

import './json5.js';
import Vgame from './vgame.js';
import Subscribe from "./subscribe.js";
import {makeplno, Cardset, Trick, Auction, Contract} from './logic.js';
import Cardholder from "./cardholder.js";

let soloMap = new Map, seriaMap = new Map, seriaOfferSet = new Set;

/*
window.peConfig = {
	fixedLocation: true
}
*/
// Custom modules
window.promiseModule = modname => {
	// if( modname==='html2canvas' ) return import( '../require/html2canvas.min.js' );
	return Promise.reject();
};


// All game modules start now
/*
window.promiseGameModule = type => {
	if( type==='game' ) return import( './game.js' );
	if( type==='cards' ) return import( './cards.js' );
	if( type==='bg' || type==='gammon' ) return import( './gammon.js' );
	if( type==='board' ) return import( './board.js' );
	return null;
};
*/

window.promiseGameExtend = type => {
	// if( type==='pool' ) return import( './pool.js' );
	// if( type==='claim' ) return import( './claim.js' );
	if( type==='bidbox' ) return import( './bidbox.js' );
	if( type==='auction' ) return import( './auction.js' );
	// if( type==='handrecord' ) return import( './handrecord.js' );
};

window.cardsSolo = null;

class Solo {
	#muted;
	#ddsUniq = 1;
	#ddsReady;
	#countVisibleHands;
	#robotMoveTime;
	#editing;
	#playing;				// User operates like a player (bidding, card selecting..
	#allcardsholder;
	static #UNIQ = 0;
	// Testing board
	// http://www.bridgebase.com/tools/handviewer.html?b=1&d=n&v=-&n=sQ96hAK2dKJ86cAQ7&e=s8754hJ954dQ5c632&s=sKJT32hT763dT2cT4&w=sAhQ8dA9743cKJ985&a=pp4Hppp
	//
	constructor( params ) {
		this.location = 'view';
		this.name = 'solo_game';
		window.cardsSolo ||= this;
		if( params.id ) this.name += '_' + params.id;
		this.name += '_' + (++Solo.#UNIQ)
		this.game = params.game || 'bridge';
		this.contract = new Contract( this );
		this.auction = new Auction( this );
		this.loadSerias();
		soloMap.set( this.name, this );
		this.vgame = new Vgame( {
			id: this.name,
			type: 'cards',
			// nosubscription: true,
			autoshow: true,
			onclick: this.onclick.bind( this ),
			solo: this,
			onready: this.ongameready.bind( this )
		} );
		// this.mode = json.mode || ( location.pathname.includes( 'viewer' ) ? 'view' : 'puzzle' );
/*
		if( !ss ) {
			if( this.ispuzzle && this.ispref ) ss = '?7235';
		}
*/
		let number = +params;
		// if( LOCAL ) ss = 'mode=view&g=pref&h0=.AQ.AK8.&h1=.87.JT7.&h2=&h3=T8.K.Q9.';
		if( number && number<50000 ) {
			// задана серия
			this.seriaReadGo( number );
			return;
		} else if( number ) {
			// ss - число, номер вопроса в базе
			this.readPuzzle( number );
			return;
		} else {
			this.init( params );
		}
	}

	init( json ) {
		if( this.inprogress ) {
			log( 'Repeated inprogress' );
			return;
		}		// Уже в процессе
		if( !json )
			json = Object.assign( {}, this.initData );
		else {
			const rebase = { l: 'lead', g: 'game', b: 'board', c: 'contract', d: 'dealer', a: 'auction', p: 'protocol' };
			for( let k in json )
				if( rebase[k]) json[rebase[k]] = json[k];
			this.initData = Object.assign( {}, json );
			this.#ddsReady = false;
		}
		if( !json ) return;

		this.event = json.myEvent;
		this.hands = [];
		this.players = [];
		// Set dealer to default by board number if isn't set
		let dealer = json.dealer;
		if( !('dealer' in json) ) dealer = 'nesw'[(+(json.boardno||json.no) - 1) % 4];
		this.dealer = makeplno( dealer ) || 0;
		this.maxplayers = 0;
		this.ddsPlays = [];
		this.bids = Array( this.maxplayers ).fill( '' );
		this.phase = null;

		// this.type = json.type;
		this.mode = json.mode;

		this.game = json.game || this.game;

		if( NEOBRIDGE ) {
			this.game = 'bridge';
			this.maxplayers = 4;
		}

		this.next = this.dealer || -1;
		if( 'lead' in json ) this.next = +json.lead;

		// Init cards
		this.#initCards( json );

		// Auto detect mode
		let opened = 0, known = 0, filled = 0;
		for( let i=this.maxplayers; i--; ) {
			opened += this.hands[i].isOpened;
			known += this.hands[i].count;
			filled += this.hands[i].count>0;
		}
		if( opened===this.maxplayers )
			this.mode = 'view';
		else
			if( filled===this.maxplayers ) this.mode = 'puzzle';

		this.goal = undefined;
		if( 'goal' in json ) this.goal = +json.goal;

		let move = json.move;
		if( move && this.trick.parse( move ) ) {
			this.next = this.nextMover( this.trick.lastplno );
		}

		if( this.isbuilder ) {
			for( let i = 0; i<this.maxplayers; i++ ) {
				this.hands[i].closedcount = 13;
			}
		}

		// Какими игроками управляем. viewer - всеми, solver - игроком 0
		let manage = '*';
		if( this.ispuzzle ) manage = 0;
		let loc = json.location;
		if( !loc && json.uniq ) loc = '?' + json.uniq;

		let dno = json.boardno,
			vuln = json.v || json.vulnerable;
		if( this.isbridge && +dno>=0 && !vuln )
			vuln = ['', 'NS', 'EW', 'ALL', 'NS', 'EW', 'ALL', '', 'EW', 'ALL', '', 'NS', 'ALL', '', 'NS', 'EW'][(+dno - 1)%16];

		vuln = { n: '02', e: '13', ns: '02', ew: '13', all: '0123', none: '' }[vuln?.toLowerCase()] ?? vuln;
		this.boardno = dno;
		this.vulnerable = vuln;

		this.auction.setDealer( dealer );
		let auction = json.auction || json.a;
		if( auction ) {
			this.auction.parse( auction );
			if( this.auction.isdone )
			{
				this.contract.parse( this.auction.analyze.contract );
				this.trump = this.auction.trump;
			}
		} else {
			// No auction, check contract from json
			let contract = json.contract;
			this.contract.parse( contract, json.declarer );
		}
		this.protocol = json.protocol;

		// Whosnext detection
		if( this.contract.isvalid ) {
			if( this.isbridge )
				this.next = (this.contract.declarer+1)%4;
			if( json.p ) {
				this.trick.playCardsBBO( json.p );
			}
		} else {
			// auction
			if( this.auction.bids.length )
				this.next = this.auction.getNext;
		}

		this.mode ||= 'view';
		let title = this.event?.data.name || {
			view: '{Analysis}',
			puzzle: `{Dowincontract} ${this.contract.shorttext}`,
			move: '{Move}'
		}[this.mode];

		let club = this.event?.data.club;
		if( club ) {
			let clubname = club.name || club.strid.split( '.' )[1] || club.strid;
			title = clubname.split( ' ' )[0] + '. ' + title;
			let country = club.country || json.country;
			if( country )
				title = (languageEmo[country] + ' ') + title;
		}

		this.playfor = [];
		if( this.ispuzzle ) {
			let iplay = json.me ?? this.next ?? 0;
			this.playfor[iplay] = true;
			if( this.isbridge ) {
				// Play for both declarer and dummy
				if( this.contract.isvalid && this.contract.declarer%2===iplay%2 ) {
					iplay = this.contract.declarer;
					this.playfor[iplay] = this.playfor[(iplay + 2) % 4] = true;
				}
			}
			if( this.game==='pref' && this.contract.declarer!==iplay ) {
				// whister plays for every hand
				this.playfor = [true, true, true, true];
				this.playfor[this.contract.declarer] = false;
			}
			// 17-05-24 robot avatars disabled
			// for( let i = this.maxplayers; i--; )
			// 	if( !this.playfor[i] ) this.players[i] = 'r:R';
			this.myplace = iplay;
		} else {
			// If next hand is opened than rotate to it and play for it
			let cc = 0;
			for( let h of this.hands )  if( !h.isOpened ) cc++;
			if( this.next>=0 && this.hands[this.next].isOpened && cc && this.mode==='move' ) {
				// this.mode = 'move';
				let iplay = this.next;
				if( this.isbridge ) {
					// Play for both declarer and dummy
					if( this.contract.isvalid && this.contract.declarer%2===iplay%2 ) {
						iplay = this.contract.declarer;
						this.playfor[iplay] = this.playfor[(iplay + 2) % 4] = true;
					}
				}
				this.myplace = iplay;
			}
		}
		this.trump = this.contract.trump;
		// if( this.contract ) {
		// 	line += '.contract ' + this.contract.str;
		let line = `SET ${this.name}.game { type: '${this.game}', group: 'cards', sides: ${this.maxplayers}, title: '${title}', solo: ${this.isviewer}, manage: '${manage}', location: '${loc || ''}' }
			.players ${this.players.join( ',' )}
			.event newdeal
			.state
			.bigstate
			.dialog
			.tricks
			.ddnextplay
			.arrows
			.contract
			.bidbox
			.undo
			${this.#fillHands()}
			.cardmoves ${this.trick.web_str}\n`;

		if( dno || this.game==='bridge' ) {
			line += `.deal { number: ${dno || 0}, vuln: '${vuln||''}', dealer: ${this.dealer}, scoring: '${json?.scoring||''}' }\n`;
		}

		line += '.auction ' + this.auction.web_str + '\n';

		// .plrstate${this.contract.declarer} ${this.contract.contract}\n`;
		if( this.ispuzzle && this.contract.declarer>=0 && this.trick.cardsplayed<=1 ) {
			let text = this.declarerplay ? "{Dowincontract}" : "{Dobeatcontract}";
			// line += `.bigstate { text: '${text} {contract}', period: 5000 }\n`;
			line += `.bigstate { text: '${text} ${this.contract.shorttext}', period: 2000 }\n`;
			line += `.auctionpreview\n`;
		}

		this.send( line );
		this.trick.send();

		if( 'next' in json ) this.next = +json.next;

		this.sendTitleScore();
		// this.trick.init( this.next );
		if( this.contract.isvalid ) {
			if( this.trick.cardsplayed ) {
				this.setPhase( 'playing' );
				this.sendContract();
				this.startmove();
				this.vgame.game?.setNoNames( false );
			} else {
				this.startContract();
			}
		} else {
			// While auction is not completed show last bids as a bid object
			this.setPhase( 'bidding' );
/*
			for( let c = this.auction.bids.length; c--; ) {
				let bid = this.auction.bids[c],
					plno = bid.plno;
				if( this.bids[plno] ) break;
				this.bids[plno] = bid.bid;
			}
*/
			this.fillBids();
			if( this.ispuzzle )
				this.#nextBid();
		}
		if( this.ispuzzle ) {
			// Fix state for undo
			this.auction.fixit();
		}
		else {
			this.#checkDDS();
		}

		this.#checkIcons();
		this.#storeSession();
		// this.vgame.game?.ddCaption();
	}

	#initCards( json ) {
		this.trick = new Trick( this );

		let shortesthand = 0,  // У кого меньше всего карт (для определения болвана в преф)
			shortest = 20,
			longest = 0, // сколько карт на руках (макс)
			har = [];

		if( json.hands ) {
			har = json.hands.split( ',' );
		} else if( json.PBN ) {
			har = json.PBN.split( ' ' );
		} else {
			// BBO style, all hands as s=/n=/e=/w=
			// our style h0=/h1=/h2=
			for( let i = 4; i--; ) {
				let player = json.players?.['nesw'[i]],
					h = json[ 'h' + i ] ?? json.hands?.[i] ?? json['nesw'[i]] ?? player?.cards ?? null;
				if( h===null && !har.length ) continue;
				har[i] = h || '';
			}
		}
		if( !this.maxplayers ) {
			this.maxplayers = har.length;
			this.startcards = 0;
			if( !this.maxplayers ) {
				if( this.game ) this.maxplayers = { bridge: 4, pref: 3 }[this.game] || 2;
				else return;
			}
		}

		let knownCards = 0, hidden = 0;
		for( let i = 0; i<this.maxplayers; i++ ) {
			// Cards
			let set = new Cardset( har[i], this );
			this.hands.push( set );
			knownCards += set.count;
			if( !set.isOpened && set.count ) hidden++;
			if( set.count>longest ) longest = set.count;
			if( set.count<shortest ) {
				shortest = set.count;
				shortesthand = i;
			}
			let c = this.hands[i].count;
			if( !this.startcards || c>this.startcards ) this.startcards = c;
			// Names
			let plr = json[ 'n' + i ] || json[ 'nesw'[i] + 'n' ]
				|| json.players?.[i] || json.players?.['nesw'[i]];
			let name = plr?.name || plr;
			if( name && !name.includes( ':' ) ) name = ':' + name;
			this.players.push( name || '' );
		}
		if( json.deal ) {

		}

		this.#checkCardHands();

		if( longest===13 && !this.game ) this.game = 'bridge';
		this.startPBN = this.PBN();

		if( knownCards===3*13 && longest===13 && shortest===0 && this.isbridge ) {
			// Detect fourh hand because all cards are known
			let suits = { s: '', h: '', d: '', c: '' };
			for( let h of this.hands )
				for( let k in h.suits ) suits[k] += h.suits[k];

			let leftstr = hidden? '-' : '';
			for( let k in suits ) {
				leftstr += k + 'akqjt98765432'.split( '' ).filter( x => !suits[k].includes( x ) ).join( '' );
			}
			this.hands[shortesthand].parse( leftstr );
		}

		// Detect pref dummy
		this.prefDummy = undefined;
		if( this.ispref && this.maxplayers===4 ) {
			if( ( 'lead' in json ) && !(shortest<longest - 1) )
				this.prefDummy = (+json.lead + 3) % 4;
			else
				this.prefDummy = shortesthand;
		}
	}

	#checkCardHands() {
		let error = '';
		for( let i=this.maxplayers; i--; ) {
			let isuits = this.hands[i].suits;
			for( let j = i; j--; ) {
				let jsuits = this.hands[j].suits;
				for( let s of 'shdc' ) {
					let result = (isuits[s].match( new RegExp( '[' + jsuits[s] + ']', 'g' ) ) || []).join( '' );
					if( result ) {
						for( let mc of result )
							error += `Multiple card ${s.toUpperCase()}${mc} (${'NESW'[i]} and ${'NESW'[j]})\n`;
					}
				}
			}
		}
		if( error ) alert( error );
		// Fill last hand if all hands of 13 cards
/*
		let filled = this.hands.reduce( ( acc, x ) => acc + (x.count===13?1:0), 0 );
		if( filled===3 ) {
			let known = this.#knownCards,
				notfull = this.hands.findIndex( x => x.count<13 );
			this.hands[notfull].add( known );
		}
*/
	}

	setLocation( loc ) {
		this.location = loc;
		this.vgame?.game?.checkLocation();
	}

	setProtocol( proto ) {
		// Same board but another contract, result, players
		let pp = proto.players;
		this.players = proto.players
			.map( x => x.id && `${x.id}:${x.name}` || ':' + x );
		this.auction.parse( proto.auction );
		this.contract.parse( proto.contract );
		this.send( `.players ${this.players.join( ',' )}\n.auction ${this.auction.web_str}\n` );
		this.startContract();
		this.vgame.game?.setNoNames( false );
		this.#checkIcons();
	}

	onroseclick() {
		// Bridge rose click. If we have protocols, show them
		this.event?.roseClick();
		return true;
	}


	PBN( from ) {
		from ||= 0;
		return this.hands.reduce( ( acc, cur, idx, ar ) => acc + ar[(idx+from)%4].PBN + ' ', '' ).trim();
	}

	get fullPBN() {
		let v = { '02': 'N', '13': 'e', '0123': 'ALL' }[this.vulnerable] || 'NONE',
			d = this.auction.dealer || 0,
			str = `[DEAL "${'NESW'[d]}:${this.PBN(d)}"]\n[VULNERABLE "${v}"]`;
		if( this.auction.dealer>=0 )
			str += `\n[AUCTION "${'NESW'[this.auction.dealer]}"]\n${this.auction.pretty}`;
		return str;
	}

	oneviewPBN( plno ) {
		if( plno==='next' ) plno = this.next;
		if( plno===undefined ) return this.PBN();
		return this.hands.reduce( ( acc, cur, idx ) => acc + (this.canView(plno,idx)?cur.PBN:cur.count) + ' ', '' ).trim();
	}

	canView( from, to ) {
		return from===to;
	}

	ddsPBN( idx ) {
		idx ||= 0;
		idx = this.trick?.current.leader ?? this.next;
		let board = 'NESW'[idx] + ':',
			moves = [];
		if( this.trick?.current )
			for( let card of this.trick.current?.cards )
				moves[card.plno] = card.card;
		for( let i=0; i<4; i++ ) {
			let plno = (idx + i) % 4;
			board += this.hands[plno]?.PBNwithcard( moves[plno] ) + ' ';
		}
		return board.trim();
	}

	async #checkDDS() {
		if( !this.isbridge || !this.vgame.ready ) return;
		if( this.ispuzzle || this.isonemove ) return;
		if( this.trick.cardsplayed ) return;
		// All hands should be filled
		let countopened = this.hands.reduce( ( acc, cur ) => acc + cur.count, 0 );
		if( countopened<52 ) return;
		if( this.#ddsReady ) return;
		let count13 = this.hands.reduce( ( acc, x ) => acc + (x.count===13 ? 1 : 0) , 0 );
		if( count13!==4 ) return;		// All hands by 13 cards
		this.send( `.ddsolution { waiting: true, allowSetAuction: ${this.isviewer} }` );
		let dds = await import( './dds.js' );
		let res = await dds.request( {
			board: 'N:' + this.PBN()
		} );
		if( res.error ) return;
		res.allowSetAuction = this.isviewer;
		this.ddSolution = res;
		this.send( `.ddsolution ${JSON.stringify( res )}` );
		this.checkContractFirstSolution();

		// Если контракта нет, покажем табличку
		// 10/10/23 отключил автопоказ таблички. Не факт, что её надо показывать всегда сразу
		// if( !this.contract.isvalid )
		// 	this.vgame.game.ddSolution?.show();
			// this.vgame.game.ddSolution.transformationClick();
		this.#ddsReady = true;
	}

	async readPuzzle( id ) {
		let res; // localStorage['puzzle_' + id];
		if( res ) res = JSON.parse( res );
		if( !res || res.error ) {
			res = await API( `/puzzle_get/${id}` );
			if( !res.body ) {
				log( 'No body in result' );
				this.showOffers();
				delete localStorage['puzzle_' + id];
				return;
			}
			// localStorage['puzzle_' + id] = JSON.stringify( res );
		}
		this.puzzle = res;
		this.init( res.body.replace( / /g, '&' ) );
	}

	storeSeriaStat() {
		localStorage['pseriastat_' + this.seria.id] = JSON.stringify( this.seria.stat );
	}

	async goSeria( seria ) {
		this.seria = seria;
		if( seria.stat.nowpuzzle ) return this.goSeriaPuzzle( seria.stat.nowpuzzle );
		this.goNextOfSeria( true );
	}

	async seriaReadGo( id ) {
		let seria = await this.readSeria( id );
		if( !seria ) {
			this.showOffers();
			return;
		}
		this.goSeria( seria );
	}

	async loadSeria( id ) {
		let seria = localStorage['pseria_' + id];
		if( !seria ) return null;
		try {
			let json = JSON.parse( seria );
			return this.updateSeria( json );
		} catch( e ) {
		}
	}

	async readSeria( id ) {
		let seria = await this.loadSeria( id );
		if( seria ) return seria;
		log( 'Reading seria ' + id );
		let res = await API( `/puzzle_getseria/${id}` );
		log( 'received ' + JSON.stringify( res ) );
		return this.updateSeria( res, true );
	}

	updateSeria( res, storeLocal ) {
		let id = +res.id;
		if( res.game!==this.game ) {
			log( `Not my game ${res.game} in seria ${id}` );
			return;
		}
		if( !res.list ) {
			log( `No list in seria ${id}` );
			return;
		}
		let seria = seriaMap.get( id );
		if( !seria ) {
			seria = {
				id: id,
				holder: html( `<div class='offerbox'>
				<div class='title'></div><div class='progress'></div>
				<progress class='fade'></progress></div>`, () => {
					elephCore?.hideOffers();
					this.goSeria( seria );
				} )
			}
			seriaMap.set( id, seria );
			this.storeSerias();
			elephCore.addOffer( seria.holder );
		}
		seria.list = res.list.split( ',' );
		seria.comment = res.comment;
		seria.title = res.title;
		if( storeLocal ) localStorage['pseria_' + id] = JSON.stringify( res );
		// Загрузим наши результаты
		if( !seria.stat ) {
			let stat = localStorage['pseriastat_' + id];
			if( stat ) {
				try {
					seria.stat = JSON.parse( stat );
				} catch( e ) {
				}
			}
			if( !seria.stat ) seria.stat = { id: id, q: [] };
			// Clean repeating puzzles
			seria.stat.q = seria.stat.q.filter( ( item, pos, ar ) => ar.indexOf( item )===pos );
			this.fillSeriaProgress( seria );
		}

		seria.holder.$( '.title' ).setContent( res.title );

		return seria;
	}

	async storeSerias() {
		localStorage['allserias_' + this.game] = JSON.stringify( Array.from( seriaMap.keys() ) );
	}

	async loadSerias() {
		let serias = localStorage['allserias_' + this.game];
		if( !serias ) return;
		let ar = JSON.parse( serias );
		for( let seriaid of ar ) {
			this.loadSeria( seriaid );
		}
	}

	async fillSeriaProgress( seria ) {
		if( !seria ) seria = this.seria;
		if( !seria ) return;
		let cap = seria.holder.$( '.title' ),
			progress = seria.holder.$( 'progress' ),
			solved = seria.stat.q.length,
			val = "{Problems_prefix}: " + seria.list.length;
		if( solved ) {
			val = solved + ' / ' + seria.list.length;
		}
		cap.setContent( val );
		cap.classList.toggle( 'done', seria.stat.q.length===seria.list.length );
		progress.max = seria.list.length;
		progress.value = seria.stat.q.length;
		progress.classList.toggle( 'visible', solved || seria.stat.nowpuzzle || false );
	}

	get isPlaying() { return this.phase==='playing' }

	setPhase( phase ) {
		if( this.phase===phase ) return;
		this.phase = phase;
		this.send( '.state ' + phase );
	}

	setSeriaQuestion( id ) {
		if( id===this.seria.stat.nowpuzzle ) return;
		this.seria.stat.nowpuzzle = id;
		this.storeSeriaStat();
	}

	goSeriaPuzzle( id ) {
		if( id ) this.setSeriaQuestion( id );
		this.readPuzzle( this.seria.stat.nowpuzzle );
	}

	goNextOfSeria( replayifdone ) {
		if( !this.seria ) {
			this.showOffers();
		}
		for( let i = 0; i<this.seria.list.length; i++ ) {
			let qid = +this.seria.list[i];
			if( !this.seria.stat.q || !this.seria.stat.q.includes( qid ) ) {
				log( 'Start seria question ' + qid );
				this.goSeriaPuzzle( qid );
				return;
			}
		}
		log( 'Not found free puzzle in seria ' + this.seria.id );
		if( replayifdone ) {
			let nowidx = this.seria.list.indexOf( this.seria.stat.nowpuzzle );
			if( nowidx!== -1 ) nowidx++;
			let q = this.seria.list[nowidx];
			if( !q ) q = +this.seria.list[0];
			this.goSeriaPuzzle( q );
		} else {
			// Покажем предложения, но счетик заданий в серии переставим на начало
			log( 'Dropping to first and then show offers' )
			this.setSeriaQuestion( +this.seria.list[0] );
			this.showOffers();
		}
	}

	sendTitleScore() {
		let stat = this.seria && this.seria.stat;
		if( stat ) {
			this.send( `.titlescore ${stat.q.length}/${this.seria.list.length}` );
		}
	}

/*
	sendBids() {
		this.send( `.bids ${this.bids.join(',')}` );
	}
*/

	get isbridge() {
		return this.game==='bridge';
	}

	get ispref() {
		return this.game==='pref';
	}

	get ispuzzle() {
		return this.mode==='puzzle';
	}

	get isonemove() {
		return this.mode==='move';
	}

	get isbuilder() {
		return this.mode==='build';
	}

	get isviewer() {
		return this.mode==='view';
	}

	get cansharenew() {
		return this.isbuilder || this.isviewer;
	}

	get targettricks() {
		return this.goal!==undefined ? this.goal : this.contract.level;
	}

	get declarerplay() {
		return this.playfor[this.contract.declarer];
	}

	tricksum( p1, p2 ) {
		return this.trick.tricks[p1] + (p2>=0 ? this.trick.tricks[p2] : 0);
	}

	get declarertricks() {
		let c = this.trick.tricks[this.contract.declarer];
		if( this.isbridge )
			c += this.trick.tricks[(this.contract.declarer + 2) % 4];
		return c;
	}

	get whistertricks() {
		if( this.isbridge )
			return this.tricksum( (this.contract.declarer + 1) % 4, (this.contract.declarer + 3) % 4 )
		else
			return this.trick.tricks.reduce( ( acc, val ) => acc + val ) - this.trick.tricks[this.contract.declarer];
	}

	gettop( c1, c2 ) {
		let rangesCard = 'akqjt98765432';
		if( c1[0]===c2[0] ) return rangesCard.indexOf( c2[1] )<rangesCard.indexOf( c1[1] );
		if( c2[0]===this.trump ) return 1;
		return 0;
	}

	#fillHands( line ) {
		let handline = '', countvis = 0;
		for( let i = 0; i<this.maxplayers; i++ ) {
			let h = this.hands[i].web_str;
			handline += `.${i}_hand ${h}\n`;
			if( h && !+h ) countvis++;
		}
		this.#countVisibleHands = countvis;
		return handline;
	}

	sendHands() {
		this.send( this.#fillHands() );
	}

	reinit() {
		for( let i=this.maxplayers; i--; ) this.hands[i].redeal();
		this.trick.init( this.next );
		this.send(
			`.bigstate
			.dialog
			.tricks
			.ddnextplay
			.tricklast
			.cardmoves` );
		this.sendHands();
	}

	startContract() {
		if( !this.contract.isvalid ) return;
		if( this.isbridge ) {
			this.next = (this.contract.declarer + 1) % 4;
		}
		this.trump = this.contract.trump;
		this.vgame.game?.ddSolution?.hide();
		this.reinit();
		this.setPhase( 'playing' );
		this.sendContract();
		this.checkContractFirstSolution();
		this.startmove();
		this.vgame.game?.setNoNames( false );
	}

	checkContractFirstSolution() {
		if( !this.isbridge && this.phase!=='playing' || !this.contract.isvalid ) return;
		if( this.trick.no!==1 || this.trick.count>0 ) return;
		if( this.ddSolution ) {
			// this.vgame.game.ddCaption?.(
			// 	this.contract.getDiffResult( this.ddSolution[this.trump.toUpperCase()]['NESW'[this.contract.declarer]] ) );
			let pro = this.contract.getDiffResult( this.ddSolution[this.trump.toUpperCase()]['NESW'[this.contract.declarer]] );
			this.vgame.game?.cards?.dd?.setPrognosis( pro );
			// Dont use .ddnextplay send while it drops solutions. Just keep this way
			// this.send( `.ddnextplay { prognosis: '${pro}' }` );
		}
	}

	async cardMove( card ) {
		// Ход картой. Если взятка лежит на столе для просмотра, завернем её
		if( this.trick.isdone ) {
			this.trick.flop();
		}
		// Если есть прогноз для этой карты, изменим prognosis
		if( this.isviewer || REMOVEME ) {
			this.#ddPrognosis = this.#ddNextPlay?.[card];
			// this.send( `.ddprognosis ${this.#ddPrognosis || ''}` );
			this.send( `.ddnextplay { prognosis: '${this.#ddPrognosis || ''}' }` );
		}

		this.ddsPlays.push( (card[1]+card[0]).toUpperCase() );
		this.send( `.bigstate\n.cardmove ${this.next}${card}` );
		this.trick.add( this.next, card );
		this.hands[this.next].remove( card );
		if( !this.trick.isdone ) {
			this.next = this.nextMover();
			this.startmove();
			return;
		}
		this.send( `.move\n.premove ${this.trick.owner}` );
		this.#checkIcons();
		// Взятка завершена. после паузы 1-2 секунды перейдем к новой взятке
		// Большая пауза в тесте, чтобы обработать ситуации раннего клика на карту
		// до полноценной подготовки к премувам сбрасываем текущий ход
		// Однако во время этой паузы необходимо начинать расчет следующего хода!
		// let state = this.trick.getState();
		// if( LOCALTEST )
		// 	this.#askSolver();
		await sleep( 1000 );
		// if( !this.trick.isModified( state ) )
		{
			this.trick.flop();
			if( !this.checkDone() ) {
				this.startmove();
			}
		}
		this.#storeSession();
	}

	undoStart() {
		// Roll back to initial position
		log( 'Roll back to start' )
		toast( 'Restart' );
		this.init();
	}

	get canUndo() {
		if( this.#editing ) return false;
		if( this.phase==='bidding' ) return this.auction.canUndo;
		if( this.phase==='playing' ) {
			if( this.trick.canUndo ) return true;
			if( !this.trick.cardsplayed && !this.ispuzzle ) return this.auction.canUndo;
			return false;
		}
		return false;
	}

	get isMyMove() {
		return this.next>=0 && this.playfor[this.next];
	}

	startmove() {
		this.#playing = true;
		let next = this.next,
			line = `.undo ${this.canUndo? 'yes' : ''}\n.arrows ${next}\n`;

		let mymove = this.isviewer || this.playfor[next];
		if( mymove ) {
			// If trick is filled, then think about next trick
			let first = !this.trick.isdone && this.trick.current.cards[0]?.card;
			line +=
				`.move { on: { cards: '${next}:${this.hands[next]?.getLegalMoves( first )}' } }`;
		}
		this.send( line );

		if( this.isviewer || !mymove )
			this.#askSolver();

		this.#checkIcons();
		this.#storeSession();
	}

	// Map to 3 maxplayers, and dummy always on place 3 (pref)
	map3( plno ) {
		let dummy = this.prefDummy;
		if( dummy===undefined || dummy===3 ) return plno;
		if( dummy===plno ) return 3;
		return (plno + 4 - dummy) % 4 - 1;
	}

	#ddNextPlay;
	#ddPrognosis;

	async #askSolver() {
		if( !REMOVEME && this.ispuzzle && this.playfor[this.next] ) return;
		if( this.mode==='build' ) return;
		if( !this.contract.contract ) return;
		let next = this.next;

		if( this.isbridge ) {
			// Bridge we solve by ourselves
			// prepare move cards
			if( this.trick.isdone ) next = this.trick.owner;
			if( !this.hands[next].count ) return;
			// Every hand should have same count of cards including in move
			let cnt = this.hands.map( x => x.count || 0 );
			for( let trcard of this.trick.current.cards )
				cnt[trcard.plno]++;
			let wrong = cnt.find( ( x, idx, ar ) => idx>0 && ar[idx]!==ar[0] );
			if( wrong ) {
				// Calculation impossible, wrong count in hands
				return;
			}
			let board = this.ddsPBN( this.next ); // this.trick.ddsPBN;
			this.#ddNextPlay = null;
			// for( let i=0; i<4; i++ )
			// 	board += this.hands[(this.next+i)%4].PBN + ' ';
			if( this.isviewer || REMOVEME ) {
				this.vgame.game?.ddSpinner?.setSpinner( true );
				// this.vgame.game?.dd?.setPrognosis( '??' );		// ?? - keep current if exists
				this.send( `.ddnextplay { next: ${this.next}, prognosis: '${this.#ddPrognosis || '??'}' }` );
			}
			this.#ddsUniq++;
			this.#robotMoveTime = Date.now() + 600;
			let dds = await import( './dds.js' ),
				res = await dds.request( {
					requestid: this.#ddsUniq,
					board: board,
					trump: this.trump.toUpperCase(),
					plays: this.trick.ddsPlays,
					action: 'nextPlays'
					// legal: this.hands[next]?.getLegalMoves( this.trick.cards[0] )
				} );

			if( res.requestid!==this.#ddsUniq || res.error ) return;		// Respond is not actual

			let trickcorrect = 0;
			if( this.trick.isdone ) trickcorrect = this.trick.owner%2===this.contract.declarer%2? 1 : 0;
			if( this.isviewer )
				this.vgame.game?.dd?.setSpinner( false );
			// if( LOCALTEST ) console.warn( '->dd ' + JSON.stringify( res ) );
			if( this.isviewer || REMOVEME ) {
				// View mode shows everything as it is
				this.send( `.ddnextplay ${JSON.stringify( {
					...res,
					next: next,
					inhand: this.hands[next].count,
					trickstaken: this.declarertricks + trickcorrect,
					level: this.contract.level,
					prognosis: next % 2===this.contract.declarer % 2 ? 'best' : 'worst'
				} )}` );

				let np = {},
					declarer = this.contract.declarer % 2===this.next % 2,
					nowtricks = this.declarertricks + (declarer ? 0 : this.hands[next].count);
				for( let card of res.plays ) {
					let str = (card.suit + card.rank).toLowerCase(),
						tr = +card.score,
						tricks = declarer ? nowtricks + tr : nowtricks - tr,
						prog = tricks - this.contract.level;
					if( prog>0 ) prog = '+' + prog;
					prog ||= '=';
					np[str] = prog;
					for( let eq of card.equals )
						np[(card.suit + eq).toLowerCase()] = prog;
				}
				this.#ddNextPlay = np;
			}

			if( this.ispuzzle && !this.playfor[this.next] ) {
				// Puzzle mode allows robots to play for opp hands
				// Robot makes best move making it random from allowed
				// Try to find maximum score cards
				// if( DEBUG )
				// 	console.error( JSON.stringify( res ) );
				let sorted = res.plays.sort( (x,y) => x.score > y.score ),
					best = sorted.filter( x => x.score===sorted[0].score ),
					allbest = best.reduce( ( acc, x ) => acc.concat( [ x.rank, ...x.equals ].map( r => x.suit + r ) ), [] ),
					rand = Math.floor( Math.random()*allbest.length ),
					onecard = allbest[rand],
					card = onecard.toLowerCase();

				// Do automatic move by robot
				// Check minimum pause before robot move: 1sec
				if( Date.now() < this.#robotMoveTime )
					await sleep( this.#robotMoveTime - Date.now() );
				if( this.#robotMoveTime ) {
					this.#robotMoveTime = null;
					this.cardMove( card );
				}
			}

			return;
		}

		let param = {
			// next: this.map3( next ),
			game: this.game,
			next: next,
			dummy: this.prefDummy,
			contract: this.contract.contract,
			declarer: this.contract.declarer,
			// declarer: this.map3( this.contract.declarer ),
			// trick: this.trick.str.replace( /(\d)/g, substr => this.map3( +substr ) ),
			trick: this.trick.str,
			action: 'card',
			professor: true,
			legal: this.hands[next]?.getLegalMoves( this.trick.current.cards[0] ).cardWideFormat(),
			hands: []
		};
		// let handcount = this.ispref? 3 : this.maxplayers;
		for( let i = 0; i<this.maxplayers; i++ ) {
			// if( i===this.prefDummy ) continue;
			// param.hands[this.map3( i )] = this.hands[i].PBN;
			param.hands[i] = this.hands[i].PBN;
		}
		// param.strhands = param.hands;
		let action = this.mode==='view' ? 'solve' : 'move',
			tm = Date.now();
		let res = await fetch( `${API2URL}/${this.game}/${action}`, {
			method: 'POST',
			mode: 'cors',
			body: JSON.stringify( param )
		} );
		let json = await res.json();
		if( this.mode==='view' ) {
			this.send( `.solution ${JSON.stringify( json )}` );
		} else {
			let move = json['move'];
			if( Date.now() - tm<500 ) await sleep( 500 - (Date.now() - tm) );
			this.move( 'move', move );
		}
	}

	nextMover( plno ) {
		if( plno===undefined ) plno = this.next;
		let next = (plno + 1) % this.maxplayers;
		if( next===this.prefDummy ) next = (next + 1) % this.maxplayers;
		return next;
	}

	prevMover( plno ) {
		if( plno===undefined ) plno = this.next;
		let next = (plno + this.maxplayers - 1) % this.maxplayers;
		if( next===this.prefDummy ) next = (next  + this.maxplayers - 1) % this.maxplayers;
		return next;
	}

	#stopmove() {
		this.send( '.move' );
	}

	#resume() {
		if( !this.#playing ) return;
		if( this.phase==='bidding' ) {
			this.#nextBid();
		} else if( this.phase==='playing' )
			this.startmove();
	}

	undo() {
		if( this.phase==='playing' && !this.trick.cardsplayed ) {
			this.setPhase( 'bidding' );
			this.contract.clear();
			this.sendContract();
			this.send( `.move\n.premove\n.ddnextplay\n` );
		}

		if( this.phase==='bidding' ) {
			this.auction.undo();
			this.next = this.prevMover();
			this.send( `.auction ${this.auction.web_str}` );
			this.#nextBid();
			return;
		}
		if( this.phase==='playing' ) {
			for( ; this.canUndo; ) {
				this.next = this.trick.undo();
				if( this.isbridge ) this.send( '.ddnextplay' );
				if( !this.ispuzzle || this.playfor[this.next] ) break;
			}
			this.startmove();
			return;
		}
	}

	async move( type, data ) {
		log( 'Solo move received: ' + type + ' ' + (data || '') );
		if( type==='leave' ) {
			this.showOffers();
			return;
		}
		if( data==='replay' ) {
			this.init();
			return;
		}
		if( data==='continue' ) {
			if( this.inprogress ) return log( 'move continue when inprogress' );
			if( this.seria )
				return this.goNextOfSeria();
			log( 'What to do when no seria?' );
			return;
		}
		if( type==='undo' ) {
			// Откат.
			this.undo();
			return;
		}
		if( type==='undo_start' ) {
			// Откат на начало
			this.undoStart();
			return;
		}
		if( type==='addexplain' ) {
			this.auction.addExplain( data );
			return;
		}

		let ar = data.split( ' ' ),
			card = ar[0];
		if( ar[0]==='bid' ) {
			if( this.phase!=='bidding' ) return log( 'Bid where no auction' );
			this.makeBid( ar[1] );
			return;
		}
		if( ar[0]==='premoved' ) card = ar[1];
		if( !this.hands[this.next].has( card ) ) {
			log( `Wrong move ${card} from ${this.next}` );
			return;
		}

		this.cardMove( card );
	}

	checkDone() {
		// Проверим, не выполнена ли миссия, или не провалена ли
		if( !this.ispuzzle ) return false;
		// if( LOCALTEST ) return this.success();
		// Для мини-диаграм мы не должны использовать 13 карт
		// this.startcards всегда правильно, но при session #store нужно
		// точно сохранять стартовое количество карт
		let winner = ''.
			startcards = this.isbridge? 13 : this.startcards;
		if( this.declarertricks>=this.targettricks ) winner = 'declarer';
		else if( this.whistertricks>startcards - this.targettricks ) winner = 'defender';
		if( !winner ) return false;
		let me = this.declarerplay ? 'declarer' : 'defender',
			success = me===winner;
		success ? this.#success() : this.fail();
		// Question is done. Is succeded mark it in seria
		return true;
	}

	#success() {
		this.stop( '{Victory}!', true );
		window.makeCool?.( { force: true, code: 'puzzledone' } );
		if( this.seria && this.seria.stat ) {
			// Recheck .q
			let stat = this.seria.stat;
			stat.nowpuzzle = null;
			if( !stat.q.includes( +this.puzzle.id ) ) {
				stat.q.push( +this.puzzle.id );
				this.fillSeriaProgress();
			}
			this.storeSeriaStat();
			this.sendTitleScore();
		}
		return true;
	}

	fail() {
		this.stop( '{Youlose}', false );
	}

	stop( msg, ok ) {
		this.inprogress = false;
		let dialog = 'replay';
		// this.increaseStat( ok? 'done' : 'fails' );
		if( ok ) {
			dialog = 'continuereplay';
		}
		this.send( `.state finished
		 	.dialog ${dialog}` );
		if( msg ) this.send( '.bigstate ' + msg );
		log( 'Stopped: ' + (msg || 'no reason') );
		// log( JSON5.stringify( this.boardInfo ) );
	}

	mute() { this.#muted = true; }
	unmute() { this.#muted = false; }

	send( line ) {
		if( this.#muted ) return;
		line = line + '\n';
		if( line[0]==='.' ) line = `SET ${this.name}${line}`;
		fire( 'fromserver', line );
	}

	async showOffers() {
		if( this.isviewer ) return;
		elephCore.showOffers();
		if( !this.offersLoaded ) {
			this.offersLoaded = true;
			let allserias = await API( '/puzzle_findserias', { game: this.game } );
			for( let seria of allserias ) {
				this.updateSeria( seria, true );
			}
		}
	}

	sendContract() {
		let line = `.event clearstates\n.contract ${this.contract.str}\n`;
		if( this.contract.isvalid )
			line += `.plrstate${this.contract.declarer} ${this.contract.contract}`;

		this.send( line );
	}

	ongameready( game ) {
		if( this.initData?.godmode ) {
			// Работаем с загруженным внешним протоколом. Можно из него делать что угодно
			// game.navi.problem = construct( '.flexline.center.grayhover.game_navigation.fade.visible.invertdark ❓', this.problemCreate.bind( this ), game.naviIcons )
		}
		game.setNoNames( this.ispuzzle || this.players.every( x => !x ) );
		game.playArea.classList.toggle( 'noclosedcards', this.isonemove );
		game.playArea.classList.toggle( 'onehandonly', this.#countVisibleHands===1 );
		game.playArea.classList.toggle( 'puzzle', this.ispuzzle );
		if( this.isonemove ) {
			let title = this.makeTitle();
			this.send( `.title ${title}` );
			// toast( title );
			// В одиночных задачках некоторые иконки показываем просто на игровом столе
			let rose = game.playArea.$( '.iconset>.bridgerose' );
			if( rose )
				game.topPanel.appendChild( rose );
			// Hide exceed
			game.gameButtonsBar.hide();
			game.statusBar.hide();
		}
		this.#checkDDS();

		if( this.initData.calculate ) {
			this.#checkStartCalculate();
		}

		this.icons ||= {
			prevBoard: html( `<span class='control flexline center grayhover display_none' data-action='prevboard' style='order: 1; transform: rotate( 180deg );'>→</span>`, game.littleIcons ),
			nextBoard: html( `<span class='control flexline center grayhover display_none' data-action='nextboard' style='order: 13'>→</span>`, game.littleIcons ),
			dealNumber: html( `<span class='display_none dealnumber control'  style='order: 2; width: unset' data-action='boardlist'></span>`, game.littleIcons ),
			score: html( `<span class='flexline score hideempty control' style='font-size: 1rem; order: 20; width: unset' data-action='score'></span>`, game.littleIcons ),
			calc: html( `<button class='flexline center score hideempty control grayhover display_none' style='font-size: 1rem; margin: 0; order: 30; width: unset' data-action='calc'>Calc</button>`, game.littleIcons ),
			edit: html( `<button class='flexline center score hideempty control grayhover display_none visible' style='font-size: 1rem; margin: 0; order: 31; width: unset' data-action='edit'>Edit</button>`, game.littleIcons )
		}
		this.#checkIcons();
		// if( LOCALTEST ) Object.values( this.icons ) .forEach( x => x.show() );

		// If there is no cards and this is viewer then start editing
		if( this.isviewer && !this.#knownCards.length ) {
			this.#startEdit( true );
		}
	}

	get #canEdit() {
		return !this.ispuzzle;
	}

	get #canCalculate() {
		if( !this.isbridge ) return false;
		if( this.ispuzzle ) return false;
		if( this.next>=0 && this.hands[this.next]?.count===13 ) return true;
		// If there is a hand with 13 cards, thats it
		return this.hands.find( x => x.count===13 );
	}

	async #checkStartCalculate() {
		if( !this.#canCalculate ) return;
		// First check which hand to be calculated
		// Correct next player (should be known hand)
		let buttons = [];
		if( this.contract.isvalid ) {
			// Contract is valid. If we know leader's hand then it could be lead checking
			let leader = (this.contract.declarer+1)%4;
			if( this.hands[leader].count===13 ) {
				buttons.push( [
					'lead', 'Lead against ' + this.contract.shorttext ] );
			}
		}
		let count13 = this.hands.reduce( ( acc, x ) => acc + (x.count===13 ? 1 : 0) , 0 );
		if( count13 ) {
			buttons.push( [ 'chance', 'Analyze chances' ] );
		}
		if( !buttons.length ) {
			this.#checkIcons();
			return;
		}
		let tools = await import( './tools.js' ),
			res = await tools.select( buttons ),
			known = this.next,
			type = res[0];
		if( type==='chance' ) {
			if( count13>1 ) {
				// Need to select anchor hand
				let html = `<div class='gridone' style='height: 10em;'>`;
				for( let i=0; i<4; i++ ) {
					if( this.hands[i].count!==13 ) continue;
					let pos = [ 'top', 'right', 'bottom', 'left' ][i];
					html += `<button data-position='${pos}' data-closeselect='${i}'>${'NESW'[i] } ⚓</button>`;
				}

				let win = makeBigWindow( {
					repeatid: 'selectanchor',
					title: 'Select anchor hand',
					html: html
				});
				let res = await win.promiseShow();
				if( !(+res>=0) ) return;
				known = +res;
			} else {
				known = this.hands.findIndex( x => x.count===13 );
			}
		} else if( type==='card' ) {
			// Trying to find a lead
			known = (this.contract.declarer+1)%4;
		} else {
			return;
		}
		if( !(known>=0) ) return;
		let contracts;

		if( type==='chance' ) {
			// Choose suits and declarers
			let html = `<table><thead class='emoji'><tr><th></th><th>NT</th><th>♠️</th><th>❤️</th><th>🔶</th><th>♣</th></tr></thead><tbody>`
			for( let p = 0; p<4; p++ ) {
				html += `<tr><th>${'NESW'[p]}</th>`;
				for( let s of 'nshdc' ) {
					html += `<td><input data-data='${p}${s}' type='checkbox' /></td>`;
				}
				html += '</tr>';
			}
			html += `</tbody></table><button default data-closeselect='ok'>OK</button>`;
			let chwin = makeBigWindow( {
				repeatid: 'selectkoz',
				title: 'What chances',
				html: html
			});
			let kozres = await chwin.promiseShow();
			if( kozres!=='ok' ) return;
			contracts = [];
			// Collect which suit/declarers
			for( let ch of chwin.$$( 'input' ) ) {
				if( !ch.checked ) continue;
				contracts.push( {
					declarer: +ch.dataset.data[0],
					trump: ch.dataset.data[1]
				});
			}
		}

		// Validate contract
		// if( !this.contract.str )
		// 	this.contract.parse( `${(this.next+3)%3}n3` );
		// Если контракт определен, и играют соперники, анализируем ход.
		// Для этого необходимо иметь 13 карт
/*
		if( this.contract.isvalid && (this.contract?.declarer)%2!==known ) {
			type = 'card';
		} else
			type = 'chance';
*/
		let distParams = {
			...this.initData,
			calctype: type,
			contracts: contracts,
			known: known,
			trump: this.contract.trump,
			callback: this.#onCalculate.bind( this )
			// desc: 'Lead against ' + this.contract?.shorttext
		};
		if( type==='card' ) {
			distParams.declarer = this.contract.declarer;
			distParams.contract = this.contract.shorttext;

		}
		// clear known hands except this.next
		for( let s of 'nesw' ) delete distParams[s];
		// distParams['nesw'[this.next]] = this.initData['nesw'[this.next]];
		distParams['nesw'[known]] = this.hands[known].PBN;
		let ddsmod = await import( './dds.js' );
		ddsmod.initCalculate( distParams );
	}

	#onCalculate( data ) {
		// debugger;
	}

	#checkIcons() {
		if( !this.icons ) return;
		let nav = this.initData?.navigation;
		if( nav ) {
			this.icons.prevBoard.makeVisible( nav.prev );
			this.icons.nextBoard.makeVisible( nav.next );
		} else {
			this.event?.checkIcons?.( this );
		}
		// DD possible if all hands
		// this.game.dd.icon.makeVisible( this.hands.reduce( ))
		this.icons.calc.makeVisible( this.#canCalculate );
		this.icons.edit.makeVisible( this.#canEdit );
		this.send( `.undo ${this.canUndo? 'yes' : ''}` );
	}

	fillMenu() {
		let str = '';
		if( this.isbridge )
			str += `<span class='icontext invertdark' data-gameaction='newboard'
					  >{New}
					</span>`;

		if( !this.isviewer ) return str;

		str += `<span class='icontext invertdark' data-gameaction='share'
					  style='background-image: url( ${IMGPATH}/svg/icons/share_black_24dp.svg)'>{Share}
					</span>`;

		if( this.isbridge ) {
			str += `<span class='icontext invertdark' data-gameaction='makepuzzle'
					  >Make a puzzle</span>`;

			str += `<span class='icontext invertdark' data-gameaction='export'
					  >{Export} PBN</span>`;
		}
		return str;
	}

	externalAction( act, e ) {
		if( act==='share' ) return this.#share();
		if( act==='newboard' ) return this.#newBoard( e );
		if( act==='export' ) return this.#export( e );
		if( act==='makepuzzle' ) return this.#makePuzzle( e );
	}

	async #makePuzzle() {
		// Make export string for puzzle
		this.#share( {
			puzzle: true
		})
	}

	async #share( param ) {
		this.#stopEdit();
		let mod = await import( './problemcreator.js' );
		let options = {
			onehand: this.next,
			...param
			// onehand: param==='onemove'? this.next : undefined
		};
		if( this.isbridge && this.trick.cardsplayed && this.contract.isvalid ) {
			options.opened = [(this.contract.declarer + 2) % 4];
			if( this.next%2===this.contract.declarer%2 ) {
				options.opened.push( this.contract.declarer % 4 );
				options.onehand = this.contract.declarer;
			}
		}
		mod.create( this, options );
	}

	makeShareUrl( options ) {
		// Returns BBO-styled url for current hand
		let v = { '02': 'n', '13': 'e', '0123': 'all' }[this.vulnerable] || '';
		let mode = options?.puzzle? 'puzzle' : this.mode,
			str = `mode=${mode}&v=${v}&d=${'nesw'[this.dealer]}`;
		str += `&me=${options?.me??this.myplace??''}`;
		for( let p=0; p<this.maxplayers; p++ ) {
			str += `&${'nesw'[p]}=${this.hands[p].url_str( mode==='puzzle' )}`;
		}
		if( this.auction.length )
			str += `&a=${this.auction.bbo_str}`;
		else if( this.contract.isvalid )
			str += `&contract=${this.contract.contract}&declarer=${this.contract.declarer}`;
		if( this.trick.cardsplayed ) {
			// All history of playing cards
			str += `&p=${this.trick.history_str}`;
		}
		let scoring = this.initData.scoring || this.event?.data.scoring;
		if( scoring )
			str += `&scoring=${scoring}`;
		if( options?.puzzle ) {
			return `?blin=${btoa( str )}`;
		}
		return '?' + str;
	}

	async #export( e ) {
		let mod = await import( './parser.js' );
		mod.toPBN( this );
	}

	async #newBoard( e ) {
		// Ask new board form: edit, import, or cancel
		let win = makeBigWindow( {
			id: 'newboardform',
			title: '{Newboard}',
			html: `<div class='column center'>
				<textarea style='width: 90%' rows=5 placeholder='{Pasteafileorspecifyurl}'></textarea>
				<input type="file" name="file" accept=".pbn, .lin" style='display: none' />
				<span class='or display_none visible disabled'>{or}</span>
				<button data-action='file' class='display_none visible importantsize'>{Choosefile}</button>
				<button default data-closeselect='ok' class='display_none importantsize'>OK</button>
				<span class='or display_none visible disabled'>{or}</span>
				<button data-closeselect='edit' class='display_none visible importantsize'>{Edit}</button>
				</div>`,
		});
		win.$( '[data-action="file"]' ).onclick ||= e => e.target.parentElement.$( 'input' ).click();
		win.$( 'input[type="file"]' ).onchange ||= e => e.target.closest( '.bigwindow' ).hide( 'file' );
		win.oninput ||= e => {
			let len = win.$( 'textarea' ).value.length;
			win.$( '[data-action="file"]' ).makeVisible( !len );
			win.$( '[data-closeselect="edit"]' ).makeVisible( !len );
			win.$( '[data-closeselect="ok"]' ).makeVisible( len>0 );
			for( let or of win.$$( '.or' ) )
				or.makeVisible( !len );
		}
		let res = await win.promiseShow();
		if( res==='ok' ) {
			this.#import( win.$( 'textarea' ).value );
			return;
		}
		if( res==='edit' ) {
			this.init( {} );
			this.#startEdit();
			return;
		}
		if( res==='file' ) {
			// Process the file (no upload)
			let reader = new FileReader();
			reader.onload = async e => {
				this.#import( e.target.result );
			};
			let input = win.$( 'input[type="file"]' );
			reader.readAsText( input.files[0] );
		}
	}

	async #import( text ) {
		let mod = await import( './parser.js' );
		let event = mod.parser( text );
		this.initEvent( event );
	}

	initEvent( event ) {
		// Start with a first set in the board
		event.setSolo( this );
		this.event = event;
		this.event.goto( 1 );
	}

	#newboardclick( e ) {
		if( e.target.dataset.action==='file' ) {
			e.target.parentElement.$( 'input' ).click();
		}
	}

	async onclick( e ) {
		let action = e.target.dataset.action;
		if( e.target.classList.contains( 'solid_card' ) ) {
			return this.#cardClick( e );
		}
		if( action?.startsWith( 'dd_' ) ) {
			// Установим контракт и разыгрывающего. Делать это можно только в начале сдачи
			// и если не задана никакая торговля. Надо предлагать установить торговлю
			if( this.isPlaying && ( this.trick.cardsplayed ) || this.auction.length ) {
				if( !await askConfirm( 'Are you sure you want to change contract?' ) ) {
					return;
				}
			}
			// this.vgame.game.ddCaption( e.target.dataset.tricks );
			// this.contract ||= new Contract;
			this.contract.parse( action.split( '_' )[1] );
			this.startContract();
			return;
		}

		if( action==='setauction' ) {
			// Задаем торговлю, если она еще не задана
			this.#startAuction();
			return;
		}

		if( action==='calc' ) {
			// Задаем торговлю, если она еще не задана
			this.#checkStartCalculate();
			return;
		}

		if( action==='edit' ) {
			// Start editing [bridge] board
			// Center cardholder with all cards for selecting
			if( !this.#editing )
				this.#startEdit( true );
			else
				this.#stopEdit();
			return;
		}

		if( this.#editing ) {
			return this.#editClick( e );
		}

		if( this.event ) {
			this.event?.onclick?.( e );
			return;
		}

		if( action==='prevboard' ) {
			if( this.initData.navigation?.prev )
				return fire( 'intent', this.initData.navigation?.prev );
		}

		if( action==='nextboard' ) {
			if( this.initData.navigation?.next )
				return fire( 'intent', this.initData.navigation?.next );
		}
	}

	fillBids() {
		// Bids on the table in bridge are shown only while auction and during to first round
		// if( this.isbridge )
		{
			let b = '';
			if( this.auction.length<this.maxplayers ) {
				for( let p = 0; p<4; p++ ) {
					let bid = p===this.next? '' : this.auction.getlastOf( p ) || '';
					b +=  ',' + bid;
				}
			}
			this.send( `.bids ${b.slice(1)}` );
		}
/*
		else {
			if( this.bids[this.next] ) {
				this.bids[this.next] = '';
				this.sendBids();
			}
		}
*/
	}

	#nextBid() {
		this.#playing = true;
		this.send( `.undo ${this.auction.canUndo && 'yes' || ''}
			.arrows ${this.next}
			.bidbox ${this.auction.makeBidBox()}` );
		this.fillBids();
		// Bidbox position
		// this.vgame.game?.setpov( this.next );
	}

	makeBid( bid ) {
		let newbid = this.auction.add( bid );
		// this.bids[newbid.plno] = bid;
		this.fillBids();
		this.send( `.auction ${this.auction.web_str}` );
		if( this.isonemove ) {
			// Задача на "1 ход", на этом игра заканчивается.
			// TODO: Предложим сделать коментарий к этому решению и поделиться им
			return;
		}
		if( this.auction.isdone ) {
			// Переходим к розыгрышу
			this.contract.parse( this.auction.analyze.contract );
			this.startContract();
			return;
		}
		this.next = this.nextMover();
		this.#nextBid();
	}

	async #startAuction() {
		if( this.auction.length ) {
			if( !await askConfirm( 'Clear current auction?' ) ) return;
		}
		this.reinit();
		this.contract.clear();
		this.sendContract();
		this.vgame.game.setNoNames( this.ispuzzle || this.players.every( x => !x ) );
		this.vgame.game.fullBoardInfo?.hide();
		this.vgame.game.ddSolution?.hide();
		this.vgame.game?.dd?.setPrognosis();
		this.auction.clear();
		this.send( '.auction' );
		this.next = this.dealer;
		this.setPhase( 'bidding' );
		this.#nextBid();
	}

	json( options ) {
		// pack all into json for store/export
		let res = {
			game: this.game,
			dealer: this.dealer,
			vulnerable: this.vulnerable,
			auction: this.auction.web_str,
			PBN: this.oneviewPBN( options?.oneview )
		}

		if( !options?.anonymize ) {
			res.players = this.players;
			res.boardno = this.boardno;
		}

		return res;
	}

	makeTitle( action ) {
		let str = action || ''; // this.type;
		if( str==='move' || this.isonemove ) str = this.contract.isvalid? '{Yourmove}' : '{Yourbid}';
		if( this.initData.scoring ) {
			str = `{Scoretype_${this.initData.scoring.toLowerCase()}}. ` + str;
		}
/*
		// Vulnerability
		let code = '';
		if( this.vulnerable.includes( this.next ) ) code += 'we';
		if( this.vulnerable.includes( 1-this.next%2 ) ) code += 'they';
		if( code==='wethey' ) code = 'all';
		code ||= 'none';
		str += `. {Vulnerable_${code}}`;
*/
		if( str[0]==='.' ) str = str.slice( 2 );
		return str;
	}

	wait( on ) {
		let g = this.vgame?.game;
		if( !g ) return;
		g.mirrorPanel ||= html( `<div class='display_none spinner' style='width:50%;height:50%;z-index: 1000'></div>`,
			g.topPanel );
		g.mirrorPanel.makeVisible( on ?? true );
	}

	clickBack() {
		if( this.initData.event?.id )
			goLocation( this.initData.event.id );
	}

	#cardClick( e ) {
		if( !this.#editing ) return;
		e.target.classList.toggle( 'marked' );
	}

	#storeSession() {
		// Store everything for tab refresh
		sessionStorage.soloUrl = this.makeShareUrl();
	}

	#editClick( e ) {
		let target = e.target;
		// We need to check e.isTrusted to avoid artificial click
		// to card
		if( e.isTrusted &&
			( target.classList.contains( 'cardholder_hand' )
			|| target.dataset.cardholderid==='allcards' ) ) {
			// Switch marked cards from this holder with other
			// one-holder-selected-cards
			// Do nothing if two hoolders have marked cards
			let targetHolder = e.target.cardHolder,
				secondHolder,
				marked = 0,
				map = new Map;
			for( let card of this.vgame.game.playArea.$$( '.solid_card.marked' ) ) {
				marked++;
				let holder = card.owner,
					ar = map.get( holder );
				if( !ar ) {
					ar = [];
					map.set( holder, ar );
					if( holder!==targetHolder ) secondHolder = holder;
				}
				ar.push( card );
			}
			if( !marked ) {
				// Check situation that all left cards should be in clicked holder
				if( this.#editClickNoMarked( targetholder ) ) return;
			}
			if( map.size>2 || !map.size ) return;
			if( map.size===2 && !map.get( targetHolder ) ) return;
			for( let [ch, cards] of map ) {
				for( let card of cards ) {
					let tch = ch===targetHolder? secondHolder : targetHolder;
					tch.append( card );
					card.classList.remove( 'marked' );
					// How to put card in right hand in our logic
					if( +tch.id>=0 ) {
						// This is players hand put card in it
						this.hands[+tch.id].add( card.str );
					}
					if( +ch.id>=0 ) {
						// This is players hand where card taken from
						this.hands[+ch.id].remove( card.str );
					}
				}
			}
			this.#recheckMinimax();
			this.#checkEdit();
		}
	}

	#recheckMinimax() {
		this.#ddsReady = false;
		this.send( '.ddsolution' );
		this.#checkDDS();
	}

	#editClickNoMarked( ch ) {
		let filled = this.hands.reduce( ( acc, x ) => acc + (x.count===13?1:0), 0 );
		if( filled===3 && ch.count<13 ) {
			let plno = +ch.dataset.id;
			// Auto fill up to 13 cards to this holder
			for( let card of this.vgame.game.playArea.$$( ".solid_card[data-owner='allcards']" ) ) {
				this.hands[plno].add( card.str );
			}
		}
		this.#checkEdit();
		this.sendHands();
	}

	#checkEdit() {
		this.#checkIcons();
		this.#storeSession();
		// If all hands holds 13 cards, all free cards should be at
		// last hand. And "allcards" holder should be small
		// if( filled===3 ) {
		//
		// }
	}

	#checkEditButton() {
		this.icons.edit.style.background = this.#editing? 'lightblue' : '';
		// this.icons.edit.style.background = this.#editing? 'lightblue' : '';
		let game = this.vgame.game;
		game.playArea.classList.toggle( 'editing', this.#editing );
		for( let card of game.playArea.$$( '.solid_card' ) ) {
			let chid = card.dataset.owner;
			card.style.cursor = +chid>=0 || chid==='allcards'? 'pointer' : '';
		}
	}

	#stopEdit() {
		if( !this.#editing ) return;
		this.#editing = false;
		this.#allcardsholder?.holder.hide();
		this.#checkEditButton();
		this.#checkIcons();
		this.#resume();
	}

	#startEdit( orimport ) {
		let game = this.vgame.game;
		if( !game.playCards ) return;
		if( this.#editing ) return;
		let known = this.#knownCards,
			knownSet = new Set( known );
		if( orimport && !knownSet.size ) {
			this.#newBoard();
			return;
		}
		this.#editing = true;
		// First check "allcardscardholder" in the center
		if( !this.#allcardsholder ) {
			this.#allcardsholder ||= new Cardholder( game, 'allcards', {
				className: 'display_none',
				prefix: 'all_',
				align: 'l',
				sort: 'always',
				multiline: true,
				noresize: true
			} );
			game.playCards.appendChild( this.#allcardsholder.holder );
			this.#allcardsholder.holder.style = 'width: 50%; height: 50%';
		}
		// Put all resting cards into this holder
		for( let s of 'shdc' )
			for( let r of '23456789tjqka' ) {
				let str = s+r;
				if( knownSet.has( str ) ) continue;
				let card = game.cards.getCard( str );
				this.#allcardsholder.append( card );
				// all.push( card );
			}
		// this.#allcardsholder.clear();
		this.#allcardsholder.holder.show();
		this.#checkEditButton();
		this.#checkIcons();
		this.#stopmove();
	}

	get #knownCards() {
		let ar = this.hands.reduce( ( acc, x ) => [ ...acc, ...x.toArray() ], [] );
		ar = [ ...ar, ...this.trick.knownCards ];
		return ar;
	}
}


import( './lang/ru_module.js' );

export async function onstart() {
	if( checkUniq( location.search ) ) {
		// If search string contains uniq address try to fetch neccessary
		let res = await API( '/getbyuniq', {
			uniq: location.search
		}, 'internal' );
		if( res?.ok ) {
			petitionUrls.set( 'viewprotocol', location.href );
			import( './gameplay.js' ).then( mod => {
				mod.makeProtogame( res.result );
			} );
		}
		return;
	}

	let startParams = new URLSearchParams( location.search );
	if( startParams.has( 'p' ) && startParams.has( 'game' ) ) {
		// Просмотр протокола, не открываем соединение
		petitionUrls.set( 'viewprotocol', location.href );
		import( './gameplay.js' ).then( mod => {
			mod.makeProtogame( startParams );
		} );
		return;
	}
	window.SOLOPLAYER = true;

	await import( './core.js' ), import( './card.js');

/*
	dispatch( 'sendmove', data => {
		if( !data.type )
			return;
		let solo = soloMap.get( data.name );
		solo?.move( data.type, data.move );
	} );
*/

// First game detection
	let game = 'pref';
	if( location.pathname.includes( 'pref' ) ) game = 'pref';
	else if( location.pathname.includes( 'bridge' ) ) game = 'bridge';
	game = GETparams.get( 'g' ) || game;
	if( GETparams.has( 'v' ) ) game = 'bridge';

	let json = { game: game };
	const rebase = { l: 'lead', g: 'game', b: 'board', c: 'contract', d: 'dealer', a: 'auction', p: 'protocol' };
	for( let [k,v] of GETparams ) {
		json[rebase[k] || k] = v;
	}

	let number = +location.search.slice( 1 );

	new Solo( number || json );
}

export async function soloGo( params ) {
	// First close other solo pages
	if( params.onload ) {
		if( sessionStorage.soloUrl ) {
			let up = new URLSearchParams( sessionStorage.soloUrl );
			params = { ...params, ...Object.fromEntries( up ) };
		}
	}
	if( window.cardsSolo )
		window.cardsSolo.init( params );
	else
		new Solo( params );
}

