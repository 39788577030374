/*
	Watching team matches. Scoring
 */

export default class Teammatch {
	constructor( game ) {
		this.game = game;
		this.holder = construct( '.display_none.teammatch.flexline', game.controlZone, this.click.bind( this ) );
		this.score = [ '50', '0' ];
		this.teams = [];

		game.addRoute( {
			team_score: this.teamscore.bind( this ),
			// teamscore: this.teamscore.bind( this ),
			team_othertable: this.othertable.bind( this ),
			state: this.checkVisible.bind( this ),
			players: this.checkVisible.bind( this )
		} );
	}

	fillTeams() {
		let teams = this.game.gameInfo.teams || [];
		if( !teams ) return;
		let str = "<div class='scoretable grid'>";
		for( let i=0; i<teams.length; i++ ) {
			let team = teams[i];
			this.teams[i] = team;
			if( !team ) continue;
			let score = this.score[i] || '',
				arrow = teams.length<=2? '↕↔'[i] : '';
			str += `<span class=matchscore>${score}</span>${fillMagicIMG( team.icon, 48 )}<span class='teamname'>${arrow}${team.title}</span>`;
		}
		str += `</div><button class='hideempty othertable rem'>${this.getOtherBrief()}</button>`;
		this.holder.innerHTML = localize( str );

		this.checkVisible();
		// if( LOCALTEST ) {
		// 	this.othertable( 'Ohoho' );
		// }
	}

	canShow() {
		return this.game.players.length && !this.game.isPlayer || !this.game.inprogress;
	}

	checkVisible() {
		let showing = this.canShow() && !this.hidden;
		if( !this.holder.makeVisible( showing ) ) return;
		log( 'Showing teammatch: ' + showing );
		this.game.checkResize();
	}

	toggleVisible() {
		this.hidden = !this.hidden;
		this.checkVisible();
	}

	teamscore( str ) {
		this.score = str && str.split( ',' ) || [];
		let i = 0;
		for( let o of this.holder.querySelectorAll( '.matchscore' ) ) {
			o.textContent = this.score[i++] || '';
		}
	}

	getOtherBrief() {
		return this?.other?.brief &&
			`${' ⚪⚫'[this?.other?.tblno] || '{Anothertable}: '} ${this.other.brief}` || '';
	}

	othertable( o ) {
		this.other = o;
		let btn = this.holder.$( '.othertable' );
		btn && btn.setContent( this.getOtherBrief() );
	}

	click( e ) {
		if( e.target.classList.contains( 'othertable' ) ) {
			if( this.game.isPlayer ) return false; // Игрок не прыгает
			if( !this.other || !this.other.item ) return;
			goLocation( this.other.item );
			return;
		}
		if( e.target.classList.contains( 'matchscore' ) ) {
			let tour = this.game.gameInfo?.tour,
				url = tour?.match && '/tour/' + tour.id + '?nonames=1';
			if( !url ) return;
			if( !this.game.isPlayer ) url += "&player=0";
			localBrowser( url );
		}
	}
}