"use strict";

import {waitLanguage} from "./lang.js";

let core = window.elephCore,
	User = window.User,
	text = '{Bugreport}',
	version, stack,
	bugCanvas, screenshot, bugHTML, bugAudio, bugTimer, forced, screenFile, forcedCount = 0,
	bugWindow, bugForm, textArea,
	cellOverlap,
	sendReport,
	videoBlob,
	darkMode = window.matchMedia( '(prefers-color-scheme: dark)' );


function createWindow() {
	if( bugWindow ) return;
	bugWindow = makeBigWindow( );
	bugWindow.id = 'bugreport';

	bugWindow.dataset['html2canvasIgnore'] = true;
	bugWindow.onHide = formHide;

// Использование setContent некорректно. Следует задавать innerHTML без преобразования,
// затем обходить полученный объект и корректно его переводить, проставляя объектам параметры
	bugWindow.setContent(
		`
	<div class="bugreport_form">
	<div><strong>${text}<version></version></strong><br>
	<span>Any your report will be appreciated</span></div>
	<textarea placeholder="Brief message" mytouch="1" rows="3" required autofocus></textarea>
	<button name="ok" data-nonplay="1" class="mybutton">{Send}</button>
	<button name="cancel" data-nonplay="1" >{Cancel}</button>
	<button name='makevideo' class='display_none'>Make video</button>
	<a href="${WWWHOST}/_privacy">{Privacypolicy}</a>
	</div>` );

	bugForm = bugWindow.querySelector( '.bugreport_form' );
	textArea = bugWindow.querySelector( 'textarea' );
	// bugWindow.$( '[name="makevideo"]' ).makeVisible( FANTGAMES );
	bugForm.onclick = bugForm_button;

	version = window.cordova?.versionNumber;
	if( version ) {
		let o = bugWindow.querySelector( 'version' );
		if( o ) o.textContent = '. ' + version;
	}
}

async function callbugreport( force, error ) {
	if( stream ) return;			// Video recording
	if( force?.includes?.( 'yastatic.net' )  // yandex ad bugs
		|| force?.includes?.( 'Blocked a frame' )
		|| force?.includes?.( 'illegal character U+009E' ) 	// Sorryman
		|| force?.includes?.( '<anonymous>' ) ) return;		// Some external scripts
	if( force && document.currentScript ) {
		// Проверим наш ли это скрипт
		let url = new URL( document.currentScript.url );
		if( /.*\.(.*\.\w*)$/.exec( url.hostname )?.[1]!==DOMAIN ) return;
	}
	if( !force && UIN ) {
		let res = await API( '/can_bugreport', {}, 'internal' );
/*
		if( !res?.can ) {
			$( '#bugreporticon' )?.hide();
			return;
		}
*/
	}
	if( bugCanvas || bugForm?.status ) return;
	if( typeof force!=='string' ) force = error = null;
	if( !force ) {
		createWindow();
		// let Lang = await import( './lang.js' );
		await waitLanguage();
		await cssInject( 'bugreport' );
	}
	if( force && window.cordova?.versionNumber && core?.full.releaseversion ) {
		// проверка минимальной версии bugreport
		try {
			let myval = window.cordova?.versionNumber.split( '.' ).reduce( ( sum, val ) => sum * 1000 + (+val), 0 ),
				minval = core.full.releaseversion.split( '.' ).reduce( ( sum, val ) => sum * 1000 + (+val), 0 );
			if( myval<minval ) return;
		} catch(e) {}
	}
	forced = force;
	sendReport = null;
	cellOverlap = '';
	stack = error?.stack || (new Error()).stack;

	if( !stack && force ) return;

	if( force && LOCALTEST ) {
		debugger;
		return;
	}
	if( !force && core?.myBans.includes( 'bugreport' ) ) return;
	if( force && !UIN ) return;			// Не форсируем от неавторизованных

	if( forced && error ) {
		forced += '. error ' + error.name + ', ' + error.message;
		// if( error.stack ) forced += ': ' + error.stack;
		if( error.filename ) forced += ' at ' + error.filename + ':' + error.lineno + ':' + error.colno;
	}

	if( forced ) {
		if( forcedCount>=1 ) {
			log( 'Maximum forced bugreports reached, canceled' );
			return;
		}
		forcedCount++;
		if( window.NEEDUPDATE ) return; // От устаревшего приложения не принимаем
		sendReport = 'FORCED ' + force;
	}

	if( !force ) {
		// Open comment form immediately
		// if( window.NEEDUPDATE && window.cordova.plugins.inappupdate )
		// 	window.cordova.plugins.inappupdate.update( 'flexible' );	// Просим обновиться, а не отправлять петицию
		bugForm.status = 'ask';
		window.addEventListener( 'keydown', bugForm_key, true );
	}
	screenshot = null;
	log( 'Bug report ' + (forced ? (forcedCount + ' forced: ' + forced ) : 'pressed') );
	// bugHTML = document.body.innerHTML;
	bugHTML = new XMLSerializer().serializeToString( document.body );
	// Hide dropdown menu with "call bug report"
	$( '#dropdownpanel' )?.hide();
	if( forced )
		screenshot = false;
	else if( navigator.screenshot ) {
		log( 'Trying screenshot plugin' );
		navigator.screenshot.URI( function( error, res ) {
			if( error ) {
				log( 'Screenshot error: ' + error );
				screenshot = false;
			} else {
				log( 'Screenshot URI OK!' );
				screenshot = res;
			}
			go();
		} );
		return;
	} else if( !window.cordova ) {
		call_html2canvas();
	} else {
		screenshot = false;
	}
	go();
}

/*
function checkCells() {
	// Checking for overlapped cells
	let cells = 0;
	for( let o of document.body.$$( 'div.cell' ) ) {
		if( o.offsetParent===null ) continue;
		let rect = o.getBoundingClientRect(),
			el = document.elementFromPoint( rect.x + rect.width/2, rect.y + rect.height/2 );
		if( el!==o ) {
			cellOverlap += `Cell ${o.dataset.notation} overlapped by ${el.mydraggable?' DRAGGABLE':''} ${el.tagName}#${el.id}.${el.className}; dataset: ${JSON.stringify(el.dataset)}\n`;
		}
		cells++;
	}
	// Check any visible controls for overlap
	for( let el of $$( '.likeabutton,button,.suitcode.clickable' ) ) {
		if( el.offsetParent===null ) continue;		// Hidden
		let rect = el.getBoundingClientRect(),
			corners = [
				document.elementFromPoint( rect.x, rect.y ),
				document.elementFromPoint( rect.x + rect.width-1, rect.y ),
				document.elementFromPoint( rect.x, rect.y + rect.height - 1 ),
				document.elementFromPoint( rect.x + rect.width - 1, rect.y + rect.height - 1  ) ];
		for( let eltop of corners ) {
			if( !eltop ) continue;
			if( el.contains( eltop ) ) continue;
			// Overlapping!
			cellOverlap += `Element ${el.textContent}(${el.className}) overlapped by ${eltop.textContent}(${eltop.className}\n`;
			cells++;
		}
	}
	cellOverlap += cells + ' elements checked';
}

*/
function go() {
	if( !forced ) {
		// checkCells();
		bugWindow.show();
		textArea.focus();
	}
	else {
		if( screenshot!==null ) send();
	}
}

async function call_html2canvas() {
	// log( 'Trying html2canvas' );
	let func = window.html2canvas;
	if( !func ) {
		try {
			let mod = await promiseModule( 'html2canvas' );
			if( !mod ) {
				log( 'Failed, sending without screenshot' );
				screenshot = false;
				send();
				return;
			}
			func = window.html2canvas || mod.default;
		} catch( e ) {
			log( 'Failed load html2canvas ' + JSON.stringify( e ) );
			screenshot = false;
			send();
			return;
		}
	}
	if( !func ) {
		log( 'Screenshot failed' );
		screenshot = false;
		send();
		return;
	}

	// log( 'Loaded html2canvas module' );
	try {
		let fres = func( document.body, {
			allowTaint: true,
			foreignObjectRendering: true,
			imageTimeout: 500,			// 500ms for imageloading
			ignoreElements: el => {
				if( (el.classList.contains( 'fade' ) || el.classList.contains( 'display_none' )) &&
					!el.classList.contains( 'visible' ) )
					return true;
				return false;
			},
			useCORS: true,
			async: true
			// scale: 1			// Если scale=1 , иногда скриншот получается неполным
		} );
		bugCanvas = await fres;
	} catch( e ) {
		log( 'Html2canvas failed' );
		screenshot = false;
		send();
		return;
	}
/*
	} catch( e ) {
		log( 'Failed HTML2canvas call' );
		console.error( 'Failed HTML2canvas call ' + JSON.stringify( e ) );
		screenshot = false;
	}
*/
	send();
}

function bugForm_key( e ) {
	if( e.key==='Escape' || e.keyCode===27 ) {
		bugForm.status = 'cancel';
		done();
		e.stopPropagation();
		return false;
	}
	if( e.key==='Enter' ) {
		if( !e.shiftKey && textArea.value.length &&  textArea.value.slice( -1 )==='\n' ) {
			formDone( true );
			e.stopPropagation();
			return false;
		}
	}
}

function formHide() {
	if( bugForm.status==='ask' ) {
		// Если закрываем неожиданно в момент ввода, закончим
		done();
	}
}

function formDone( ok ) {
	if( ok && textArea.value.trim().length<10 ) {
		toast( `Please add details` );
		return; // минимум 10 символов текста
	}
	bugForm.classList.remove( 'ask' );
	bugForm.status = ok? 'ok' : 'cancel';
	bugWindow?.hide();			// Чтобы визуально петиция отправилась мгновенно
	if( ok ) {
		sendReport = textArea.value.trim();
//		alert( bugForm.status )
/*
		let bugicon = $( '#bugreporticon' );
		if( !forced && bugicon ) bugicon.style.opacity = 0.2;
*/
		send();
	} else {
		done();
	}
}

function bugForm_button( e ) {
	let b = e.target;
	if( b.name==='makevideo' ) {
		makeScreenshotMedia();
		return;
	}
	if( !b || b.name!=='ok' && b.name!=='cancel' ) return;
	if( b.name==='ok' && !textArea.value ) return;
	formDone( b.name==='ok' );
	return false;
}

function done() {
	if( bugForm ) {
		window.removeEventListener( 'keydown', bugForm_key, true );
		bugWindow.hide();
		if( bugForm.status==='ok' ) {
			// set timeout for clearing final pressing of Shift+ENTER for send
			setTimeout( () => textArea.value = null, 300 );
		}
		bugForm.status = null;
	}
	if( bugCanvas ) bugCanvas.remove();
	bugCanvas = null;
	bugHTML = null;
	screenshot = null;
	sendReport = null;
	let bugicon = $( '#bugreporticon' );
	if( bugicon ) bugicon.style.opacity = 1;
}

async function send() {
	if( !sendReport ) {
		if( bugForm.status==='ask' ) return; // форма пока на экране
		done();
		return;
	}
	if( sendReport==='text' ) {
		// Google play test device. Skip it
		done();
		return;
	}
	// if( navigator.screenshot || window.html2canvas ) {
	if( screenshot===null && !bugCanvas && !videoBlob ) return;		// Ждем готовности медиа
	// }
	textArea?.blur();

	if( FANTGAMES && !UIN ) {
		// Need guest authorization
		await modules.Auth.checkAuthGuest( {
			force: true
		});
	}

	if( window.SPHERENEEDUPDATE ) {
		if( forced ) return;
		if( window.cordova )
			toast( 'Please update your app' );
		else
			toast( 'Please refresh tab to update to latest version' );
		return;
	}

	let res = await API( '/can_bugreport', {}, 'internal' );
	if( !res?.ok ) {
		toast( 'Bugreports are not available now' );
		return;
	}

	let form = new FormData, magicForm = new FormData, imgbin;

	if( window.modules.subscribe ) form.append( 'wg_plays', JSON.stringify( modules.subscribe.get( '_me.plays' ) ) );
	if( window.bowser ) form.append( 'wg_agent', JSON.stringify( bowser ) );
	form.append( 'wg_standalone', STANDALONE );
	form.append( 'wg_darkmode', `${darkMode.matches}. My selection is ${localStorage.screentheme}` );
	if( window.domainData )
		form.append( 'domainroot', window.domainData.root );

	if( window.elephRoom?.viewRoom )
		form.append( 'viewRoom', JSON.stringify( window.elephRoom.viewRoom.full ) );

	if( window.NOLOCALSTORAGE )
		form.append( 'nolocalstorage', true );

	let cssPath = document.querySelector( 'link[href*=".css"]' )?.href,
		npos = cssPath.lastIndexOf( '/css/' ),
		path = cssPath.slice( 0, npos );
	form.append( 'scriptpath', path );

	window.Swiper?.currentGame?.bugReportData( form );

	// if( window.allChats )
	// 	form.append( 'chat', allChats );
	let browser = getBrowserName();

	let ua = navigator.userAgentData?.platform + ' ' + JSON.stringify( navigator.userAgentData?.brands ),
		auth = core?.auth;
	if( window.UIN ) {
		let sname = User?.getNick( UIN ) || document.body.dataset.showname || UIN;
		form.append( 'wg_uname', sname );
		if( !forced ) form.append( 'wg_uid', UIN );
		// if( auth.uin )
		// 	form.append( 'wg_uin', auth.uin );
	} else if( window.myPhoneNumber ) {
		form.append( 'wg_uname', window.myPhoneNumber );
	} else
		form.append( 'wg_uname', LOCALTEST ? 'WEB debug' : (DEBUG ? 'WEB test' : 'WebGuest') );
	if( ua && !browser ) {
		let parts = ua.split( ' ' );
		browser = parts[parts.length - 1];
	}
	// Старые андроиды не отправляют петиций
	if( forced && browser.includes( 'Android/5' ) ) return;
	if( auth ) form.append( 'wg_auth', JSON.stringify( auth ) );
	let ratio = window.devicePixelRatio || 1;
	form.append( 'wg_browser', browser + ' ' + window.innerWidth + "x" + window.innerHeight );
	form.append( 'wg_naviapp', navigator.appName + ' ' + navigator.appVersion );
	if( window.cordova )
		form.append( 'wg_cordovaapp', cordova.versionNumber + ' (min ' + core?.full.releaseversion + ')' );
	form.append( 'wg_useragentdata', JSON.stringify( navigator.userAgentData ) );
	form.append( 'wg_languages', JSON.stringify( navigator.languages ) );
	form.append( 'wg_url', window.location.href );
	form.append( 'wg_screen', screen.width + "x" + screen.height );
	form.append( 'wg_sizewindow', window.innerWidth + "x" + window.innerHeight );
	form.append( 'wg_innerWidth', window.innerWidth );
	form.append( 'wg_innerHeight', window.innerHeight );
	form.append( 'wg_sizebody', document.body.clientWidth + "x" + document.body.clientHeight );
	form.append( 'wg_report', sendReport );
	form.append( 'wg_ratio', ratio );
	form.append( 'domain', DOMAIN );

	// if( cellOverlap )
	// 	form.append( 'wg_celloverlap', cellOverlap );

	if( FANTGAMES || browser.toLowerCase().includes( 'fantgames' ) )
		form.append( 'ns', 4 );

	if( window.device ) form.append( 'wg_deviceuuid', device.uuid );
	// for( let i = localStorage.count; i--; )
	// 	form.append( 'wg_local_' + i, localStorage[i] );
	// for( let i = sessionStorage.count; i--; )
	// 	form.append( 'wg_session_' + i, sessionStorage[i] );

	if( core ) form.append( 'wg_params', JSON.stringify( core.params ) );
	form.append( 'wg_localstorage', JSON.stringify( localStorage ) );
	form.append( 'wg_sessionstorage', JSON.stringify( sessionStorage ) );
	form.append( 'wg_myself', JSON.stringify( window.myself ) );

	// form.append( 'wg_json_bodystyle', JSON.stringify( getComputedStyle( document.body ) ) );
	//	form.append( 'wg_navigator', JSON.stringify( navigator ) )

	if( window.myPhoneNumber )
		form.append( 'wg_phone', window.myPhoneNumber );

	if( window.device ) {
		form.append( 'wg_device', JSON.stringify( device ) );
	}

	if( document.currentScript ) {
		form.append( 'wg_script', document.currentScript.src );
	}

	if( stack )
		form.append( 'wg_stack', stack );

	form.append( 'DOM', bugHTML );
	let html = new XMLSerializer().serializeToString( document );
	html = html.replaceAll( '<script', '<_script' );
	form.append( 'html', html );

	if( core?.petitionData )
		for( let k in core.petitionData ) form.append( 'wg_' + k, core.petitionData[k] );

	if( forced ) {
		form.append( 'assign_to', 6 );
		form.append( 'wg_uid', 6 );
		form.append( 'realuin', window.UIN || auth?.uid || 0 );
	}

	// Ссылка на турнир, если не за столом, а в зале
	if( core ) {
		if( !petitionUrls.has( 'tour' ) && +core.location>10000 )
			petitionUrls.set( 'tour', `/tour/${core.location}` );

		// Suitable params
		for( let [k, v] of petitionUrls )
			form.append( '_url_' + k, v );
		form.append( '_url_pathprefix', core.pathPrefix );
	}

	form.append( '_url_location', location.href );
	form.append( 'allchats', window.allChats );

	// if( import.meta ) form.append( 'currentscript', import.meta.url );
	let scriptpath = new Set;
	for( let s of document.head.$$( 'SCRIPT' ) ) {
		let u = s.src && new URL( s.src );
		if( u ) scriptpath.add( u.href.replace( /[^\/]*\.js$/, '' ) );
	}
	form.append( 'scriptpath', [...scriptpath].join( ', ' ) );

	if( window.SPHEREPATH )
		form.append( 'spherepath', SPHEREPATH );

	if( window.SPHEREVERSION ) form.append( 'wg_sphereversion', SPHEREVERSION );
	if( window.SPHERESTABLEVERSION ) form.append( 'spherestable', window.SPHERESTABLEVERSION );

	// Screenshots
	let img;
	if( bugCanvas ) {
		try {
			let du = bugCanvas.toDataURL( 'image/png' );
			img = du.replace( /data:image\/png;base64,/, '' );
			// imgbin = bugCanvas.toBlob( 'image/png' );
		} catch( e ) {
			form.append( 'wg_noimage', JSON.stringify( e ) );
		}
	}

	if( videoBlob )
		magicForm.append( 'video', videoBlob );

	if( screenshot?.URI ) {
		img = screenshot.URI.replace( /data:image\/.*;base64,/, '' );
	}

	if( img ) {
		// await fetch( 'http://192.168.0.173:8080/magicstorage/upload', {
		magicForm.append( 'type', 'screenshot' );
		magicForm.append( 'image', img );
		// log( 'Try magic image, size=' + img.length );
		let resp = await fetch( 'https://api.playelephant.com/magicstorage/upload', {
			method: 'POST',
			body: magicForm
		});
		// log( 'Magic status: ' + resp.status + ', ' + resp.statusText );
		if( resp.ok ) {
			let json = await resp.json();
			// log( 'Magic json: ' + JSON.stringify( json ) );
			form.append( 'screenshotmagic', json.id );
			form.append( 'screenshotmagic_size', json.size );
			form.append( 'screenshotmagic_resolution', json.resolution );
			img = null;
		}
	}

	if( img ) {
		form.append( 'image', img );
	}

	let prefix = window.location.href.includes( 'www1.' ) && 'www1' || 'www';
		// peturl = `https://${prefix}.${DOMAIN}/php/webpetition.php`;

	if( window.startAttributes )
		form.append( 'startAttributes', window.startAttributes );
	if( window.historyStr ) form.append( 'history', historyStr );
	await fetch( APIURL + '/petition', { method: 'POST', body: form } );
	if( !forced ) toast( '{Bugreportsubmitted}', 'short' );

	done();
}

let stream, mediaRecorder, chunks, stopVideoButton;
window.makeScreenshotMedia = async () => {
	if( stream ) return;
	try {
	stream = await navigator.mediaDevices.getDisplayMedia( {
		preferCurrentTab: true,
		selfBrowserSurface: 'exclude',
		surfaceSwitching: 'exclude',
		displaySurface: 'browser',
		video: {
			cursor: 'always',
			logicalSurface: true,
			surfaceSwitching: 'exclude',
			displaySurface: ['browser'],
		}
/*
		, audio: {
			echoCancellation: true,
			noiseSuppression: true,
			sampleRate: 44100
		}
*/
	});
	} catch( e ) {
		log( 'Permissions for video has not been granted' );
		return;
	}
	stream.addEventListener( 'inactive', canceledVideo );
	mediaRecorder = new MediaRecorder(stream);

	chunks = [];
	mediaRecorder.ondataavailable = function (ev) {
		console.log({ev})
		chunks.push(ev.data)
	}
	// window.clearTimeout(timoutStop);
	bugWindow.hide();
	// stopVideoButton ||= construct( '#stopvideo.display_none STOP', document.body, stopVideo );
	// stopVideoButton.show();
}

function canceledVideo( e ) {
	stopVideoButton?.hide();
	let tracks = stream.getTracks();
	tracks.forEach( ( track ) => track.stop() );
	videoBlob = new Blob( chunks, { type: "video/mp4" } );
	chunks = [];
	// Отправим видео форсированной петицией
	sendReport = 'media';
	send();
	videoBlob = null;
	stream = null;
}

export default callbugreport;
