"use strict";

function suittocode( suit ) {
	return localize( htmlsuits[suit] ) || suit;
}

export class Cardset {
	#hidden;
	constructor( str, solo ) {
		this.solo = solo;
		this.#hidden = false;
		this.clear();
		if( str ) this.parse( str );

		this.initialSuits = Object.assign( {}, this.suits );
	}

	setOpened( val ) {
		this.#hidden = !val;
	}

	#fullPBN = '';
	url_str( showhidden ) {
		if( !showhidden && this.#hidden ) return this.count;
		return (this.#hidden? '?' : '') + this.#fullPBN;
	}

	get web_str() {
		return this.#hidden? this.count : this.PBN;
	}

	toArray() {
		let res = Object.entries( this.suits ).reduce( ( acc, x ) => {
			return [ ...acc, ...x[1].split( '' ).reduce( ( acc, r ) => [ ...acc, x[0] + r ], [] ) ];
		}, [] ).filter( x => !!x );
		return res;
	}

	get isOpened() {
		return this.count && !this.#hidden && !this.closedCount;
	}

	redeal() {
		Object.assign( this.suits, this.initialSuits );
	}

	clear() {
		this.suits = { s: '', h: '', d: '', c: '' };
		this.closedCount = 0;
	}

	has( card ) {
		return this.suits[card[0]].includes( card[1] );
	}

	get count() {
		let c = 0;
		for( let k in this.suits ) c += this.suits[k].length;
		return c;
	}

	add( card ) {
		if( Array.isArray( card ) )
			card.forEach( x => this.add( x ) );
		else {
			let suit = card[0];
			this.suits[suit] += card[1].toLowerCase();
		}
	}

	remove( card ) {
		let suit = card[0];
		this.suits[suit] = this.suits[suit].replace( card[1].toLowerCase(), '' );
	}

	get PBN() {
		if( this.closedCount ) return this.closedCount;
		let s = this.suits;
		return `${s.s}.${s.h}.${s.d}.${s.c}`;
	}

	PBNwithcard( card ) {
		if( !card ) return this.PBN;
		if( this.suits[card[0]].includes( card[1] ) ) return this.PBN;
		let old = this.suits[card[0]];
		this.suits[card[0]] += card[1];
		let res = this.PBN;
		this.suits[card[0]] = old;
		return res;
	}

	getLegalMoves( lead ) {
		if( !lead ) return this.PBN;
		let ls = lead[0];
		if( this.suits[ls] ) return ls + this.suits[ls];
		if( this.solo.game==='pref' ) {
			let trump = this.solo.trump;
			// Если есть козырь, то в преферансе класть только его
			if( trump && trump!==ls && this.suits[trump] ) return trump + this.suits[trump];
		}
		let str = '';
		for( let s in this.suits ) {
			if( s!==ls ) str += s + this.suits[s];
		}
		return str;
	}

	parse( str, suitorder ) {
		this.#hidden = false;
		if( str[0]==='?' || str[0]==='*' || ( str[0]==='-' && str[1]!=='.' ) ) {
			this.#hidden = true;
			str = str.slice( 1 );
		}
		str = str?.toLowerCase().replace( '-', '' );
		this.clear();
		if( +str>=0 ) {
			// Closed hand
			this.closedCount = +str;
			return;
		}
		let suits = suitorder || 'shdc';
		if( str.includes( '.' ) ) {
			let ar = str.split( '.' );
			for( let s = 0; s<ar.length; s++ ) {
				let suit = suits[s];
				if( !suit ) bugReport( 'Zero suit in cardparser' + s );
				this.suits[suit] = ar[s];
			}
		} else {
			let suit = undefined;
			for( let i = 0; i<str.length; i++ ) {
				let s = str[i].toLowerCase();
				if( s==='_' || s===',' ) continue;
				if( 'scdh'.includes( s ) ) {
					suit = s;
					continue;
				}
				if( !suit ) {
					// bugReport( 'Zero suit 1 ' );
					continue;
				}
				this.suits[suit] += s;
			}
		}
		for( let k in this.suits )
			this.suits[k] = this.suits[k].replace( '10', 't' )
				.replace( '1', 't' ).replace( '0', 't' );
		this.#fullPBN = this.PBN;
	}
}

export class Trick {
	#webstr;
	#lastPreloadedCard;

	constructor( solo ) {
		this.solo = solo;
		this.init();
	}

	get count() {
		return this.current.cards.length;
	}

	get lastplno() {
		return this.cards.slice( -1 )?.plno;
	}

	get isdone() {
		return this.count>=this.needCount;
	}

	get ddsPBN() {
		if( this.isdone ) // Trick is fullfilled, give next trick for analysis
			return this.solo.ddsPBN( this.owner );
		else
			return this.current.ddsPBN;
	}

	get ddsPlays() {
		return this.isdone? [] : this.current.ddsPlays;
	}

	get owner() {
		let ownidx = 0,
			cards = this.current.cards;
		for( let i=1; i<cards.length; i++ ) {
			if( this.solo.gettop( cards[ownidx].card, cards[i].card ) ) ownidx = i;
		}
		return cards[ownidx].plno;
	}

	get cardsplayed() {
		let cur = this.current;
		return cur.prevcards + cur.cards.length;
	}

	get playCardsDDS() {
		// Сделанные ходы массивом для DDS анализатора
		return this.cards.map( x => x.toUpperCase() );
	}

	get history_str() {
		let str = '';
		for( let tr of [ ...this.alltricks, this.current ] ) {
			for( let c of tr.cards ) {
				str += c.card;
			}
		}
		return str;
	}

	get web_str() {
		this.#webstr ||= this.current.cards.map( x => `${x.plno}${x.card}` ).join( ',' );
		return this.#webstr;
	}

	get web_prev() {
		return this.alltricks[this.alltricks.length-1]?.cards?.map( x => `${x.plno}${x.card}` ).join( ',' ) || '';
	}

	sendTricks() {
		this.solo.send( `.tricks ${this.web_tricks}` );
	}

	get no() {
		return this.current.no || 1;
	}

	init( leader ) {
		this.alltricks = [];
		this.tricks = [ 0, 0, 0, 0 ];
		this.clear( 1, leader );
	}

	clear( no, leader, prevcards ) {
		this.current = {
			no: no,
			leader: leader,
			cards: [],
			ddsPBN: this.solo.ddsPBN( leader ),
			ddsPlays: [],
			prevcards: prevcards || 0,
		}
	}

	get needCount() {
		return this.solo.ispref? 3 : 4;
	}

	get web_tricks() {
		let str = this.tricks.join( ',' );
		if( this.solo.isbridge ) {
			let decl = this.solo.contract.declarer,
				opp = (decl+1)%4,
				ar = [ 0,0,0,0 ];
			ar[decl] = this.tricks[decl] + this.tricks[(decl+2)%4];
			ar[opp] = this.tricks[opp] + this.tricks[(opp+2)%4];
			str = ar.join( ',' );
		}
		return str;
	}

	undo() {
		// Undo one card move. Returns plno of undone player
		if( !this.cardsplayed ) return this.solo.next;
		if( this.count ) {
			let last = this.current.cards.pop(),
				plno = last.plno,
				hand = this.solo.hands[plno];
			this.current.ddsPlays.pop();
			this.#webstr = '';
			hand.add( last.card );
			this.solo.send( `.undocardmove ${plno}${last.card}\n.cardmoves ${this.web_str}\n.${last.plno}_hand ${hand.web_str}` );
			return last.plno;
		} else {
			// Необходимо откатиться к прошлому ходу и предыдущей взятке
			let cur = this.alltricks.pop();
			this.current = cur;
			this.tricks[cur.owner]--;
			this.solo.send( `.cardmoves ${this.web_str}\n.tricklast ${this.web_prev}` );
			this.sendTricks();
			return this.current.owner;
		}
	}

	send() {
		// Send cardmoves,tricks,last trick
		this.solo.send( `.cardmoves ${this.web_str}\n
			.tricklast ${this.web_prev}\n
			.tricks ${this.web_tricks}` );
	}

	flop( owner, next ) {
		// сохраняем последнюю взятку
		// 1s4,2s9,3sq,*0sk
		owner ??= this.owner;
		let cur = this.current;
		cur.owner = owner;
		this.alltricks.push( cur );
		this.tricks[owner]++;
		let count = this.tricks[owner];
		this.clear( cur.no + 1, next ?? owner, cur.prevcards + cur.cards.length );
		this.sendTricks();
		this.next = this.current.leader;
		this.solo.next = this.current.leader;
		this.solo.send( `.floptrick { plno: ${owner}, count: ${count} }` );
		this.solo.send( `.tricklast ${this.web_prev}` );
		// return this.current.leader;
	}

	get knownCards() {
		// get array of all known cards
		let res = [];
		for( let trick of [ ...this.alltricks, this.current ] )
			for( let card of trick.cards )
				res.push( card.card );
		return res;
	}

	add( plno, card ) {
		card = card.toLowerCase();
		let cur = this.current;
		if( cur.leader===undefined ) {
			cur.leader = plno;
			cur.ddsPBN = this.solo.ddsPBN( plno );
		}
		cur.cards.push( {
			card: card,
			plno: plno
		} );
		// this.str += `${plno}${card}`;
		this.#webstr = '';
		cur.ddsPlays.push( (card[1]+card[0]).toUpperCase() );
		// Если был сделан ход от болвана, значит будет 4 карты во взятке
		if( plno===this.solo.prefDummy ) this.needCount = 4;
	}

	playCardsBBO( proto ) {
		if( !proto ) return;
		proto = proto.toLowerCase();
		this.solo.mute();
		for( let i=0; i<proto.length; i+=2 ) {
			let c = proto.slice( i, i+2 );
			this.#lastPreloadedCard = c;
			this.playCard( c );
		}
		this.solo.unmute();
	}

	get canUndo() {
		if( !this.cardsplayed ) return false;
		let cards = this.current.cards;
		if( this.#lastPreloadedCard===cards[cards.length-1]?.card ) {
			// No undo in puzzles with fixed first move
			return false;
		}
		return true;
	}

	playCard( card ) {
		if( this.isdone ) this.flop();
		let next = this.solo.next;
		this.add( next, card );
		this.solo.hands[next].remove( card );
		if( this.isdone ) {
			this.solo.next = -1;
		} else {
			this.solo.next = this.solo.nextMover();
		}
	}

	parse( str ) {
		let parts = str.split( ',' ), lastplno,
			index = 0;
		for( let p of parts ) {
			let pos = 0;
			if( p[0]==='*' ) pos++;
			let plno = +p[pos];
			if( plno>=0 ) pos++;
			else plno = 4;
			let card = p.slice( pos );
 			this.cards[index] = {
				card: card,
				plno: plno
			};
 			this.solo.hands[plno].remove( card );
 			index++;
		}
		this.str = str;
		return this.count;
	}
}

export function makeplno( plno ) {
	let p = 'NESWnesw0123'.indexOf(plno)%4;
	if( p<0 ) p = 0;
	return p;
}

function bbo( bid ) {
	let res = { double: 'd', redouble: 'r', pass: 'p' }[bid];
	if( res ) return res;
	return bid[1] + bid[0];
}

export class Auction {
	#webstr;

	constructor( solo ) {
		this.solo = solo;
	}

	setDealer( dealer ) {
		this.dealer = 'NESWnesw0123'.indexOf(dealer)%4;
		if( this.dealer<0 ) this.dealer = 0;
		let dpos = 'nesw'.indexOf( dealer );
		if( dpos>=0 ) this.dealer = dpos;
		this.clear();
		// this.parse( str );
	}

	get length() { return this.bids.length; }

	clear() {
		this.trump = undefined;
		this.level = undefined;
		this.tricks = 0;
		this.bids = [];
		this.str = '';
		this.doubled = '';
		this.#analyzed = null;
	}

	get isdone() {
		// В бридже три паса в конце
		if( this.solo.isbridge )
			return this.bids.length>=4 && this.bids.slice( -3 ).every( x => x.bid==='pass' );
	}

	get getNext() {
		if( !this.bids.length ) return this.dealer;
		if( this.isdone ) return this.leader;
		return this.solo.nextMover( this.bids.slice( -1 )[0].plno );
	}

	get canUndo() {
		return this.bids.length > ( this.#fixedLength || 0 );
	}

	#fixedLength;
	fixit() {
		this.#fixedLength = this.bids.length;
	}

	add( bid ) {
		if( !bid ) return;
		if( +bid>=0 ) {
			// Waiting for bid of this player
			return;
		}
		if( bid==='ap' ) {
			this.add( 'p' );
			this.add( 'p' );
			return this.add( 'p' );
		}
		if( +bid[0]>=0 ) bid = bid.split( '' ).reverse().join( '' );

		const codes = {
			p: 'pass',
			d: 'double',
			x: 'double',
			r: 'redouble',
			xx: 'redouble'
		};
		let b = {
			bid: codes[bid] || bid,
			plno: this.getNext
		};
		this.bids.push( b );
		this.#webstr = '';
		return b;
	}

	undo() {
		this.bids.pop();
		this.#webstr = '';
		this.#analyzed = null;
	}

	getlastOf( plno ) {
		for( let i=this.bids.length; i--; )
			if( this.bids[i].plno===plno ) return this.bids[i].bid;
	}

	parse( str ) {
		// BBO-style: 1s2cX (no commas, NX bids), our style: [N]bid,bid,...: dealer
		this.clear();
		this.leader = undefined;
		this.#webstr = '';
		if( !str ) return;
		str = str.toLowerCase();
		// detect style
		let ours = str.includes( ',' ) || ( +str[0]>=0 && ( +str.slice(-1)>=0 || !'shcdn'.includes( str[1] ) ) ),
			plr = this.dealer;
		if( str[1]===':' ) {
			plr = this.dealer = 'NESWnesw0123'.indexOf(str[0])%4;
			str.slice( 2 ).split( /[\s\,]/ ).forEach( x => this.add( x ) );
		} else if( ours ) {
			plr = this.dealer = +str[0];
			str.slice( 1 ).split( ',' ).forEach( x => this.add( x ) );
		} else {
			// BBO style
			for( let i=0; i<str.length; i++ ) {
				if( !isNaN( str[i] ) ) {
					this.add( str[i+1]+str[i] );
					i++;
				} else {
					this.add( str[i] );
				}
				if( this.isdone ) break;
			}
		}
		if( this.solo.ispuzzle ) this.fixit();
		this.analyze;
		// this.analyze;
/*
					let trump = this.trump = str[i+1].toLowerCase();
					this.level = +str[i];
					let declarer = plr;
					if( this.solo.isbridge ) {
						if( trump in pairdeclarers[plr%2] )
							declarer = pairdeclarers[plr%2][trump];
						else
							pairdeclarers[plr%2][trump] = plr;
						this.leader = (declarer+1)%4;
					}
					this.declarer = declarer;
					this.contract = declarer + this.trump+this.level;
					this.doubled = '';
					i++;
					continue;

 */
	}

	#analyzed;
	get analyze() {
		if( this.#analyzed ) return this.#analyzed;
		let an = {},
			plr = this.dealer,
			pairdeclarers = [ {}, {} ],
			passes = 0;
		for( let i=0; i<this.bids.length; i++, plr = (plr+1)%this.solo.maxplayers ) {
			let str = this.bids[i].bid;
			if( str==='pass' ) {
				passes++;
				continue;
			}
			passes = 0;
			if( str==='double' || str==='redouble' ) {
				an.doubled = { double: 'x', redouble: 'xx' }[str];
				continue;
			}
			let trump = an.trump = str[0].toLowerCase();
			an.level = +str[1];
			let declarer = plr;
			if( this.solo.isbridge ) {
				if( trump in pairdeclarers[plr % 2] )
					declarer = pairdeclarers[plr % 2][trump];
				else
					pairdeclarers[plr % 2][trump] = plr;
				an.leader = (declarer + 1) % 4;
			}
			an.declarer = declarer;
			an.doubled = '';
		}
		if( an.declarer>=0 )
			an.contract = an.declarer + an.trump + an.level + (an.doubled || '');
		if( passes===3 ) an.finalContract = an.contract;
		this.#analyzed = an;
		return an;
	}

	get pretty() {
		let str = '';
		for( let n=0; n<this.bids.length; n++ ) {
			str += bbo( this.bids[n].bid ) + (n%4===3? '\n' : ' ' );
		}
		return str;
	}

	get bbo_str() {
		return this.bids.map( x => bbo( x.bid ) ).join( '' );
	}

	get web_str() {
		if( !this.#webstr ) {
			this.#webstr = this.dealer.toString() + this.bids.map( x => x.bid ).join( ',' );
			if( !this.isdone ) this.#webstr += ',' + this.getNext;
		}
		return this.#webstr;
	}

	makeBidBox() {
		// Подготовим строку для выбора заявки (пока только бридж)
		if( !this.solo.isbridge ) return;
		// Анализируем предыдущие заявки от конца, чтобы понять, что можно заявить

		let contra = '', min;
		for( let i=this.bids.length; !min && i--; ) {
			let b = this.bids[i].bid;
			switch( b ) {
				case 'pass':
					continue;
				case 'double':
					contra ||= (this.bids.length-i)%2? 'redouble' : 'no';
					continue;
				case 'redouble':
					contra ||= 'no';
					continue;
				default:
					contra ||= (this.bids.length-i)%2? 'double' : 'no';
					let suitno = 'cdhsn'.indexOf( b[0] );
					min = 'dhsnc'[suitno];
					min += min==='c'? (+b[1]+1) : b[1];
					if( min[1]==='8' ) {
						min = '';
						break;
					}
			}
		}
		contra = contra==='no'? '' : ',' + contra;
		min ||= 'c1';
		return `{ type: 'bridge', playfor: ${this.solo.next}, skipexplain: true, first: '${min}', spec: 'pass${contra}' }`;
	}

	addExplain( json ) {
		let bid = this.bids[json.bidno];
		if( !bid ) return log( 'Not found bid' );
		bid.explain = json.explain;
		this.solo.send( `.onebidexplain ${JSON.stringify( json )}` );
	}
}

export class Protocol {
	constructor( solo, leader, str ) {
		this.solo = solo;
		this.leader = makeplno( leader );
		this.clear();
		this.parse( str );
	}

	parse( str ) {
		// p=cas6c8c2h5h8hah2c5c3s8cthqh3s2h4c9c4stc6h6hts3hkcjcksjcqhjh7d4s5dad2d3d6dqd5djs4
		if( !str ) return;
		for( let pos=0; pos<str.length; pos+=2 ) {
			let move = str.slice( pos, 2 );
			if( move.length!==2 ) break;

		}
	}
}

export class Contract {
	constructor( solo, str ) {
		this.solo = solo;
		this.parse( str );
	}

	get shorttext() {
		return this.levelname + suittocode( this.trump );
	}

	get isvalid() {
		return this.declarer >= 0;
	}

	clear() {
		this.declarer = -1;
		this.contract = this.trump = this.level = this.levelname = this.str = '';
	}

	getDiffResult( tricks ) {
		let tr = tricks - this.level;
		if( tr>0 ) tr = '+' + tr;
		tr ||= '=';
		return tr;
	}

	import( o ) {
		// let [ _, s, l, e ] = o.contract.match(/^(\w)(\d)(.*)?/ ) || [];
		let [ _, d, l, s, e ] = o.match( /^([neswNESW])(\d)(\w)(.*)?/ ) || [];
		if( !s )
			[ _, d, s, l, e ] = o.match( /^(\d)(\w)(\d)(.*)?/ ) || [];
		if( s ) {
			s = s.toLowerCase();
			e = e?.replaceAll( 'X', '*' ) || '';
			this.declarer = 'NESWnesw0123'.indexOf( d )%4;
			this.contract = s + l + e;
			this.str = this.declarer + this.contract;
			this.trump = s;
			this.extra = e;
			this.level = this.levelname = +l;
			if( this.solo.isbridge ) this.level += 6;
		} else {
			this.contract = 'pass';
		}
	}

	parse( str, declarer ) {
		this.declarer = -1;
		this.contract = this.trump = this.level = this.str = '';
		if( !str ) return;
		if( declarer ) str = declarer + str;
		str = str.toLowerCase();
		if( +str[0]>=0 || this.solo.isbridge )
		{
			return this.import( str );
		}
		this.contract = str;
		this.trump = str[0];
		this.level = this.levelname = +str[1];
		if( isNaN( this.level ) ) this.level = this.levelname = '';
		if( this.level===0 ) this.level = this.levelname = 10;	// Pref
		if( this.solo.isbridge ) this.level += 6;
		this.str = (this.declarer>=0? this.declarer : '') + this.trump + this.levelname;
	}

	get humanStr() {
		return this.levelname;
	}
}

String.prototype.cardWideFormat = function() {
	let res = '', suit = 's';
	for( let i=0; i<this.length; i++ ) {
		let c = this[i];
		if( 'shdc'.includes( c ) ) suit = c;
		else if( c==='.' ) suit = 'shdc'['shdc'.indexOf(suit)+1];
		else res += suit+c.toLowerCase();
	}
	return res;
};