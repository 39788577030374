"use strict";

import Timer from "./timer.js";

const callfriendsemo = [ '🥁', '🪗', '🎷', '🪕' ];
function getcallfriendsemo() {
	return callfriendsemo[Math.floor(Math.random()*callfriendsemo.length	 )];
}

export default class Tourline {
	#myTeam; #checkTourRegBounded;
	#elReg; #prizeAdd; #prizeLine; #prizeStr;
	#elMyline; #elMystate; #elMine;
	#teambar; #teambar2; #elParticipants;
	#data;
	#elShortInfo;
	constructor( room ) {
		this.id = 0;
		this.room = room;
		// this.data = {};
		this.full = {};
		this.limits = {};
		this.roundReady = true;

		let parent = room.holder;
		this.elHolder = construct( '.display_none.tourinfo.column', parent );
		this.elLine = construct( '.sheet.flexline.tourline', this.elHolder );

			// construct( 'button.display_none.default.invitebutton[data-share={Invite}] {Invite}', this.elLine, this.invite.bind( this ) );
		// this.elInviteExplain = construct( '.display_none {Participantscannotcomewithoutinvitation}', this.elInvite );
		this.elPhase = construct( '.display_none.phase', this.elLine );
		this.elTime = construct( '.time', this.elLine );
		this.elTimer = new Timer( {
			stopwatch: true,
			visibility: 'running',
			parent: this.elTime
		} );
		this.elTimer.onchange = this.checkTD.bind( this );
		this.elTimer.holder.classList.add( 'display_none' ); // Чтобы таймер не занимал место
		this.elAdd1min = construct( 'button.display_none.admin[data-action=1m] +1m', this.elTime, this.clickAdmin.bind( this ) );
		this.elTD = construct( '.tdblock.importantsize', this.clickAdmin.bind( this ) );
		this.elMute = construct( 'button.display_none.hideinportrait.admin[data-action=muteroom] 🔉', this.elLine );
		this.elClone = construct( 'button.display_none.wideonly.admin[data-action=clone] Clone', this.elTD );
		this.elRefpage = construct( 'button.display_none.wideonly.admin[data-action=refpage] TD', this.elTD );
		this.elDelete = construct( 'button.display_none.hideinportrait.admin.alert[data-action=delete] 🔥', this.elTD );
		this.elEdit = html( `<button class='display_none emoji admin' data-action='edit'>⚙️</button>`, this.elTD );
		this.elPeopletables = construct( '.display_none.column.rem', this.elLine, this.showRanking.bind( this ) );
		this.elTables = construct( '.display_none.hideempty', this.elPeopletables );
		this.elPeople = html( `<div class='flexline display_none'><span style='margin-right: 0.1em'>{Participants}</span><neo-counter></neo-counter></div>`, this.elPeopletables );
		// this.elPeople.title = localize( '{Participants}' );
		this.elOfflineCount = construct( '.hideempty.flexline', this.elPeopletables );
//		this.elExpand = construct( '.display_none.control.info ℹ️', this.elLine, () => this.toggleTourPanel() );
		this.elLink = construct( '.display_none.graybutton.visible {Ranking}', 'button', this.elLine, this.showRanking.bind( this ) );
		this.#elShortInfo = html( `<div class='fadingtext display_none sheet tourshortinfo rem hideempty' style='max-height: 5em'></div>`, this.elHolder );
		// this.elTDline = construct( '.display_none.flexline', )
		this.elAdjustments = construct( 'iframe.display_none.sheet.adjframe.rem', this.elHolder );
		// this.elLineMine = construct( '.display_none.mine ✅', this.elLine, () => this.toggleTourPanel() );
		this.elPrize = construct( '.display_none.prize.control.padding 💰', this.elLine, this.showPrize.bind( this ) );

		this.elPanel = construct( '.tourpanel.display_none', parent );

		this.#elMyline = construct( '.display_none.lightborder.mytourinfo.flexline.spacearound.sheet', room.headersArea );
		if( LOCALTEST ) this.newCounter = html( `<neo-counter></neo-counter>`, this.elLine );
		this.#elMystate = construct( '.flexline.hideempty', this.#elMyline );
		this.elMyreg = construct( '.mybutton.display_none.importantsize[data-action=reg] {Register}', this.#elMyline, this.regTour.bind( this ) );
		this.elMymenu = construct( 'span.icon.grayhover.w32.icon-menu.display_none.menu[data-action=unreg]', this.#elMyline, this.regTour.bind( this ) );

		// this.elControl = construct( '.tourcontrol.flexline.display_none', this.elPanel );
		// this.elLimits = html( `<div class='display_none' style='background: lightgray; order: 9; align-self: stretch;'><span>Model. Max 20</span><button class='default noborder' data-action='upgrade'>Upgrade</button></div>`,
		// 	this.elPanel, this.upgrade.bind( this ) );
		this.elTop = construct( this.elPanel );
		this.elCenter = construct( '.column.centered', this.elPanel );
		this.elBottom = construct( this.elPanel );
		// this.elState = construct( '.display_none', this.elCenter );
		this.#elReg = construct( '.display_none.control.importantsize.mybutton[data-action=reg] ', this.elCenter, this.regTour.bind( this ) );
		this.#elMine = construct( '.display_none.infoline.tourmine {Youareregistered}', this.elCenter );
		// this.elDrop = construct( '.display_none.warn.control.mybutton[data-action=unreg] {Concede}', this.elBottom, this.regTour.bind( this ) );

		this.elInvite = html( `<button class="display_none bigbutton" _data-share='{Invite}' style='position: relative'><span>{Invite}</span>
				<div class='display_none commentcode' style='margin-top: 0.5em; font-size: 50%'>{Theycannotjoinwithoutinvitation}</div>
				<div class='display_none toparticipate' style='margin-top: 0.5em; font-size: 50%'>{Toparticipatewithyou}</div>
				<div class='control grayhover righttop closebutton icon size2rem' data-action='hideinvite'></div>
				`,
			this.elBottom, this.invite.bind( this ) );

		// this.elUpgrade = html( `<button class="display_none default bigbutton" data-action='Upgrade'><span>{Add20players}</span>
		// 		<div class='comment' style='margin-top: 0.5em; font-size: 50%'>{therearenoplacesleft}</div>`,
		// 	this.elCenter, this.upgrade.bind( this ) );

		dispatch( 'regerschanged', this.checkTourReg.bind( this ) );
		dispatch( 'loggedin', this.checkMe.bind( this ) );

		// Обработчик незашифрованного меня
		// и надо добавить в турнир передачу пропускающих тур
		elephSubscribe.addParser( 'me', this.userparse.bind( this ) );
		this.goesToFund = '100%';

		dispatch( 'invitationschanged', this.checkInvites.bind( this ) );

		dispatch( 'checkmyteams', () => {
			// Обновим кнопку Зарегистрироваться, если она была не видна
			if( this.full?.team && !this.full.open )
				this.#elReg.makeVisible( this.canRegNow );
		} );

		this.parser = {
			error: () => {
				elephCore.setBack( '' );
				fire( 'settitle', '{Tourneynotfound}' );
				fire( 'golocation', '' );
			},
			full: o => {
				// if( o['plantime'] ) {
				// 	this.elTimer.setDestination( +o );
				// }
				// if( o['label'] ) eltitle.textContent = timePrefix + localize( o['label'] );
				this.setFull( o );
				// For team tournaments instantly make request about my team
			},
			limits: o => {
				this.limits = o;
				this.fillLimits();
				this.modified();
			},
			roundready: str => {
				this.roundReady = str;
				this.checkIFRAME();
			},

			event: str => {
				if( str==='end_tour' ) {
					// Можно обновить IFRAME
					if( !this.roundReady ) this.refreshPreview();
				}
			},

			muteroom: val => {
				this.elMute.setContent( +val ? '🔇' : '🔉' )
			},

			shortinfo: str => {
				this.shortinfo = str;
				if( !this.brief ) this.#elShortInfo.html( str );
				this.#elShortInfo.dataset.title = this.full.name;
				this.#elShortInfo.dataset.popupinfo = encodeURIComponent( str );
			},

			brief: str => {
				this.brief = str;
				if( this.brief ) this.#elShortInfo.setContent( str )
			},

			timestart: this.checkTimer.bind( this ),
			time: this.checkTimer.bind( this ),

			toast: str => toast( str ),

			td: o => {
				this.td = o;
				if( this.canAdmin ) this.checkAdj();
				if( !this.#prizeStr ) this.setPrize( "0" );
			},

			tables: o => {
				this.tables = +o || 0;
				this.elTables.setContent( o ? '{Tables}: ' + o : '' );
				this.checkIFRAME();
			},

			regtables: o => {
				this.regTables = +o || 0;
				this.checkIFRAME();
			},

			canreg: o => {
				this.canReg = +o;
				this.modified();
			},

			round: o => {
				this.round = o;
				this.checkPhase();
			},

			phase: o => {
				let val = o.id || o;
				if( this.phase===val ) return;
				this.phase = val;
				if( this.isRegistration && !this.isTeams ) this.canReg = true;
				if( this.phase==='play' ) this.werePlaying = true;
				this.elLine.classList.toggle( 'running', val==='play' || val.includes( 'pause' ) );
				this.checkPhase();
				this.modified();
			},

			description: val => {
				this.description = val;
				this.checkPhase();
			},

			sponsored: o => {
				playSound( 'cash' );
				if( o.sum>=1000000 ) playSound( 'appl' );
			},
			prize: val => this.setPrize( val.toString(), true ),
			sponsors: val => (this.sponsors = val) && delay( this.updateSponsors.bind( this ) ),

			c: this.setCount.bind( this ),

			restplayers: this.restPlayers.bind( this ),

			// offliners: this.parseOffliners.bind( this ),

			myregistration: val => this.elLine.classList.add( 'registered' ),

			DONE: () => {
				log( 'DONE tourid ' + this.id );
				// if( this.sub ) this.sub.release();
				this.checkPhase();				// Если не было подписки, на всякий случай обновим
				// Сделаем запрос к базе за последними актуальными
				elephSubscribe.apiGet( '/tour/' + this.id, this.#eventid );
				// this.setId( 0 );
			},
			'*': this.parseDefault.bind( this )
		}
	}

	parseDefault( data, minor ) {
		// Try parse team_NNN in teams tournament
		if( minor?.startsWith( 'participant_' ) )
			this.parseParticipant( data, minor.match( /participant_(.*)/ )[1] );
	}

	parseParticipant( data ) {
		this.participants ||= new Map;
		this.participants.set( data.id, data );
		// Check my team now
		let uins = data.uins.split( ',' ),
			pure = uins.map( x => '?!'.includes( x[0] )? x.slice( 1 ) : x ),
			mine = pure.includes( UIN );

		if( mine ) {
			this.#setMyTeam( data );
		}
	}

	get canRegNow() {
		if( !(+this.id) || !this.canReg || this.full.noreg || !elephCore.checkCanPlay( this.full.gamegroup ) ) return false;
		if( !this.canAdmin && this.full.needinvitationcode && !window.getInvitationCode?.( this.origin ) ) return false;
		if( this.noPlaceByLimits ) return false;
		// Проверяем возможность зарегистрироваться через состояние своей команды в турнире
		if( this.isReged ) {
			if( this.myData?.state==='out' ) {
				// Вылетел. Можно ли reentry?
				if( this.full.reentry ) return true;
				if( this.full.canrestore ) return true;		// TODO: DEPRECATED
				if( this.#data?.get( 'canrestore' ) ) return true;
			}
			return false;
		}
		if( this.#myTeam ) {
			// Моя команда (или пара).
			if( !this.#myTeam.state || this.#myTeam.state==='none' ) return this.#myTeam.canreg || false;
			if( this.#myTeam.cancreate ) return true;
			return false;
		}
		if( this.team && !this.full.open && !this.team.isMember ) {
			// Не член команды-организатора турнира. надо проверить последняя правка 20/01/23 00:00
			// 1. Если заданы allowedteams, то проверять надо в них. но проще вообще не проверять
			// после 10 февраля 2023 убрать || !FANTGAMES, т.к. будет приходить this.full.access
			if( this.full.access?.teams || !FANTGAMES ) return true;
			return false;
		}
		return true;
	}

	get isPairs() { return this.full.pairs; }
	get isTeams() { return this.full.teams; }

	checkInvites() {
		if( !window.Invitation || !this.isPairs ) return;
		let set = Invitation.getOfType( 'tourpair.' + this.id ), elReg = this.#elReg;
		let mystr = '', addname = '', waits = 0; //, theystr = '';
		if( set ) {
			for( let inv of set ) {
				if( !inv.mine ) continue;
				if( !mystr ) {
					mystr = '{Waiting}: ';
					if( inv.recipient.name ) addname = ` (${inv.recipient.name})`;
				} else mystr += ', ';
				waits++;
				mystr += ' ' + inv.recipient.nick;
			}
			if( waits===1 ) mystr += addname;
		}
		if( !mystr ) mystr = '{Invite}';
		elReg.setContent( mystr );
		elReg.dataset.text = waits? 'wait' : 'reg';
	}

	checkPhase() {
		let phase = this.phase, // , description = this.data['description'],
			animation = '', txt = '',
			str; // = phase==='pre_start' && description?.short;
		// if( !str && this.question ) str = this.question['text'].replace( /<br>/gi, '. ' );
		if( phase==='wait' ) str = '{Waitingstartlabel}';
		else if( this.isRegistration ) {
			str = '🟢'; // {Registration}
			txt = '{Registration}';
			let tostart = checkMS( this.checkPoint ) + window.TIMESERVERDIFF - Date.now();
			if( LOCALTEST ) tostart = 1;		// Emulation
			if( tostart>0 ) {
				if( tostart < 2*60*1000 ) animation = 'shining 1s alternate infinite';
/*
				if( tostart < 60*15*1000 ) {
					str = '🟢⚪';
					animation = 'rotating3s';
				}
*/
			}
		}
		else if( phase==='paused' ) str = this.phaseParam && this.phaseParam['comment'] || '';
		else if( this.isFinished ) str = '{Tourneycompleted}';
		else if( this.isPause && this.round ) str = '☕' + this.round + '⇢' + (+this.round+1);
		else if( this.round && this.round!=='0' ) {
			str = '{Tround} ' + this.round;
			if( this.full.rounds ) str += '/' + this.full.rounds;
		}
		// if( lastStr===str ) return;
		// this.lastStr = str;
		this.elPhase.setContent( str || '' );
		this.elPhase.makeVisible( !!str );
		this.elPhase.title = localize( txt );
		this.elPhase.dataset.animation = animation;
		this.elTables.makeVisible( this.isRunning );
		this.elPeopletables.makeVisible( !this.isFinished );
		this.elLink.makeVisible( !this.isWaiting ); // !this.isWaiting || this.canAdmin );
		this.checkTimer();
		this.checkIFRAME();
		this.checkTD();
		if( this.isFinished ) {
			this.room.doorsTableArea?.hide();
			this.#elParticipants?.removeAttribute( 'data-event' );
		}
	}

	showRanking() {
		if( !this.id ) return;
		localBrowser( '/tour/' + this.id );
	}

/*
	toggleTourPanel( val ) {
		this.showBrief = val!==undefined ? val : !this.showBrief;
		this.checkTourReg();
		// setViewStyles();
	}
*/

	setEasyReg( val ) {
		if( this.easyReg===val ) return;
		this.easyReg = val;
		// if( val ) this.toggleTourPanel( true );
		this.modified();
	}

	get isRegistration() {
		return this.phase==='reg';
	}

	get isWaiting() {
		return this.phase==='reg' || this.phase==='wait';
	}

	get isFinished() {
		return this.phase==='finished';
	}

	get isPause() {
		return this.phase==='pause';
	}

	get isRunning() {
		return this.phase==='play' || this.phase==='pause';
	}

	get isReged() {
		if( this.myData ) return this.myData.state!=='deny';
		return this.id && elephCore.isReged( this.id ) || false;
	}

	checkTourReg() {
		if( !this.phase ) return;

		let id = this.id, panalways = id && this.isWaiting || false,
			reged = this.id && elephCore.isReged( this.id ) || false;

		this.elHolder.makeVisible( !!id );
		// this.#elShortInfo.classList.toggle( 'visible', !!id );
		// Большая панель видна всегда, если
		// легкая регистрация доступна, или нет столов. Иначе её включают
		let canreg = this.canReg && !reged && !this.isFinished,
			// showBrief = this.showBrief===undefined? ( this.isWaiting
			//    || ( this.canReg && this.phase!=='play') )  : this.showBrief,
			showBrief = this.isWaiting,
			showpan = panalways || false;
		if( this.isFinished ) showpan = false;
		// if( LOCALTEST ) showpan = true;
		this.elPanel.makeVisible( showpan );
		// this.elExpand.makeVisible( !panalways && !pannever || this.brief || this.shortinfo || false ) ; // && elephC	ore.isReged( tourid ) );
		// this.elExpand.classList.toggle( 'expanded', showBrief || false );
		this.#elShortInfo.makeVisible( this.isWaiting );
		// if( tourid ) tourLink.href = 'https://www.gambler.ru/tour/' + tourid;

		// let id = Core.checkCanPlay( viewRoom['gamegroup'] ) && viewRoom['easyreg'],
		// tourState.textContent = ''
		// this.elLink.makeVisible( !this.isRegistration );
		// let canpay = !window.IOS || !this.full.premium || !Core.isPremium();
		// Временно кнопка "Зарегистрироваться" убрана у командных турниров Клубов. TODO: ИСПРАВИТЬ!
		this.#elReg.makeVisible( this.canRegNow /*&& ( !this.isTeams || !this.full.team )*/ );
		// this.elDrop.makeVisible( reged && !this.isFinished && this.canDrop );

		this.checkInviteUpgrade();

		// this.elLine.classList.toggle( 'center', caninvite );
		// this.#elMine.makeVisible( reged );
		this.checkMe();
		// this.elLineMine.classList.toggle( 'visible', reged );
	}

	checkInviteUpgrade() {
		// if( !this.canAdmin ) return;
		let needcode = this.full.needinvitationcode,
			caninvite = ( !needcode && this.isRegistration && this.isReged && !this.isTeams ) || ( needcode && this.canAdmin && ( this.isRegistration || this.isWaiting ) ) || false;

		if( this.canAdmin ) {
			let noplace = this.noPlaceByLimits;
			// this.elUpgrade.makeVisible( noplace );
			if( noplace ) {
				// this.elUpgrade.$( 'span' ).setContent( this.limits.paymodel==='demo' ? '{Upgrade}' : '{Add20players}' );
				caninvite = false;
			}
		}

		// this.elInvite.makeVisible( ( LOCALTEST || this.canAdmin ) && this.isRegistration );
		if( caninvite ) {
			this.elInvite.show();
			this.elInvite.firstElementChild.setContent( this.canAdmin? '{Invite}' : `${getcallfriendsemo()} {Invitefriends}` );
			// this.elInvite.parentElement!==this.elCenter
			// this.elCenter.appendChild( this.elInvite );
			// this.elInviteExplain.show();
			// this.#elReg.hide();
			// Покажем приглашение сразу же, если в этой сессии еще не показывали
			this.elInvite.$( '.commentcode' ).makeVisible( needcode );
			this.elInvite.$( '.toparticipate' ).makeVisible( !needcode );
			if( !this.waitingInvite && this.canAdmin && !this.noPlaceByLimits && this.full.needinvitationcode && !sessionStorage['admininvited_' + this.origin] ) {
				this.waitingInvite = setTimeout( this.invite.bind( this ), 1000 );
			}
		} else {
			this.elInvite.hide();
		}
	}

	async invite( e ) {
		// Пригласим друзей, либо игроков с кодом
		if( e?.target?.dataset.action==='hideinvite' )
			return this.elInvite.hide();

		this.waitingInvite = null;
		if( !this.canAdmin || !this.full.needinvitationcode )
			return execute( null, null, {
				execute: 'inv.invite',
				title: localize( '{Invite}: 🛡️' + this.full.title )
			});
		// Админ пробует получить invitation code
		let j = await elephCore.do( `type=getinvitationcode origin=${this.origin}` );
		if( !j || !j.ok ) {
			toast( 'Failed to create invitation' );
			return;
		}
		// Запомним
		sessionStorage['admininvited_' + this.origin] = true;
		let url = location.href;
		if( j.invitationcode ) {
			url += url.includes( '?' )? `&inv=${j.invitationcode}`: `?${j.invitationcode}`;
		}
		log( 'Invite url: ' + url + '. j=' + JSON.stringify( j ) );
		return execute( null, null, {
			execute: 'inv.invite',
			url: url,
			text: localize( '🛡️' + this.full.title ),
			origin: 'tour_' + this.id,
			code: j.invitationcode,
			needcode: true
		});
	}

	get canDrop() {
		return !this.myData || this.myData.type!=='teams';
	}

	get #eventid() {
		return 'event_' + this.id;
	}

	userparse( data, minor ) {
		if( minor!==this.#eventid ) return;
		this.checkMe( data );
	}

	async askMyTeam() {
		if( !this.isTeams ) return;
		let res = await elephCore.do( `type=event event=${this.id} action=getme` );
		this.#setMyTeam( res?.participant );
	}

	async #setMyTeam( participant ) {
		if( this.#myTeam?.id===participant?.id ) return;
		this.#myTeam = participant;
		this.checkMe();
		this.checkTourReg();

		if( this.#myTeam ) {
			this.#myTeam.name ||= 'My team';
			if( !this.#myTeam.state ) {
				// Unregistered team. Ask api about this
				// Warmup registration. Get all info and put info to server with
				// state "none"
				let res = await API( `/tour_regteam`, {
					tour_id: this.id,
					team_id: this.#myTeam.id,
					warmup: true,
				});
				if( res?.ok ) {
					log( 'Regteam warmup: ' + JSON.stringify( res ) );
					// We dont use it now hoping we'll receive all needless info through the server
				}
			}
			let teambar = this.elHolder.$( '[is="neo-teambar"]' );
			if( this.isTeams ) {
				this.#teambar ||= html( `<div is='neo-teambar' data-simple='1'></div>`,
				// this.#myTeambar ||= html( `<neo-teambar data-eventid='${this.id}' data-teamid='${this.#myTeam.id}'></neo-teambar>`,
					this.room.headersArea );
				import( './teambar.js' );
				this.#teambar.dataset.participant=`${this.id}_${this.#myTeam.id}`;
			} else {
				this.#teambar?.removeAttribute( 'data-participant' );
				// if( this.#teambar )
				// 	this.#teambar.dataset.participant = '';
			}
		}
	}

	setFull( o ) {
		let oldname = this.full?.name;
		try {
			this.full = o;
			let root = window.domainData?.root;
			if( root ) {
				if( this.full.back!==root ) {
					log( 'Not my tournament' );
					goLocation( '' );
					return;
				}
			}
		} catch( e ) {
			log( `Tourline bugreport 1 ${e.toString()}` );
			bugReport( `Tourline (${this.id})::setFull 1 ${e.toString()}: ${JSON.stringify( o )}` );
		}
		if( oldname && oldname!==this.full.name ) {
			this.#elShortInfo.dataset.title = this.full.name;
		}
		try {
			this.elPeople.$( 'span' ).setContent( localize( this.isTeams && '{Teams}' || this.full.pairs && '👥' || '👤' ) );
			// let td = o.td?.split( ',' );
			this.checkCanTD();
			let back = this.full.lback || this.full.hall || '',
				tid = this.full.team?.tid;

			if( !back && this.full.team ) {
				back = this.full.team.location || 'team.' + tid;
			}
			if( !back ) back = elephCore.aliases.has( this.full.icon ) && this.full.icon;
			if( !back ) back = elephCore.aliases.has( this.full.gamegroup ) && this.full.gamegroup;
			elephCore.setBack( ['goroom', back] );
			this.team = tid ? User.set( 'team_' + tid ) : undefined;
			this.checkTD();
			this.checkAdj();

			this.elLink.classList.toggle( 'hideinportrait', !this.full.championship );
			if( this.full.championship ) this.full.notime = true;
			fire( 'settitle', this.full );
			// this.elInviteFriends.dataset.share = localize( '{Invite}: 🛡️' + this.full.title );
			this.askMyTeam();
			// Show participants
			this.#elParticipants ||= html( `<div is='neo-participants' style='grid-area: participants; overflow: scroll'></div>`,
				this.room.holder );
			// this.room.doorsTableArea );
			import( './participants.js' );
			if( !this.#elParticipants ) {
				log( `#elParticipants is not defined. Iphone?` );
			} else {
				this.#elParticipants.dataset.event = this.id;
				this.#elParticipants.onclick ||= this.#participantClick.bind( this );
			}
		} catch( e ) {
			log( `Tourline bugreport 2 ${e.toString()}` );
			bugReport( `Tourline (${this.id})::setFull ${e.toString()}: ${JSON.stringify( o )}` );
		}
	}

	checkCanTD() {
		let canTD = this.full.td?.split( ',' ).includes( UIN ) || ( DEBUG && window.ADMIN ) || false;
		if( canTD!==this.canAdmin ) {
			this.canAdmin = canTD;
			for( let el of this.elLine.querySelectorAll( '.admin' ) )
				el.hide();
			log( `TD for ${this.id}: ${this.canAdmin}` );
		}
	}

	checkMe( data ) {
		if( !this.id ) return;
		if( data===undefined ) data = elephSubscribe.get( 'me.event_' + this.id );
		let reentry = this.myData?.state==='out' && this.full.reentry;
		let r = this.isTeams && '{Createteam}' || this.isPairs && '{Invite}' || '{Register}';
		if( reentry ) r = '{Reentry}';
		if( this.#myTeam?.name ) {
			r = `{Register} ${this.#myTeam.name}`;
		} else if( this.#myTeam )
			r = `{Registerteam}`;
		let	text = r,
			buyin = this.full.buyin || this.full.fee && { value: this.full.fee };
		if( reentry ) {
			// Чтобы вычислить цену реентри, нужно учесть сколько раз мы уже делали его
			buyin ||= { value: this.full.reentry.price };
		}

		if( buyin ) {
			if( buyin.clubmoney ) {
				text =
					`<span style='font-size: 4vw; color: lightgray'> 
					  <span>${r}</span><span style='padding-left: 0.5em; color: white'><span>${buyin.value}</span><span data-origin="team_${buyin.clubmoney}.currency">${this.team?.currency || ''}</span>
					</span>`;
			}
			else
				text = `${r} 💰 ${buyin.value}`;
		}
		this.#elReg.setSpinner( false );
		this.elMyreg.setSpinner( false );
		this.#elReg.setContent( text );
		this.elMyreg.setContent( text );
		// Следующая строка вроде бы не нужна
		// if( !elephCore.isReged( this.id ) ) data = null;
		// if( !data ) data = { state: 'none' };
		this.myData = data;
		log( `Mytour ${this.id} ${JSON.stringify(data)}` );
		// Моя панель видна либо когда я участник, либо когда я могу дорегистрироваться во время игры
		let myvis = data && !this.isFinished,
			myregvis = this.canRegNow;
		if( !myvis && myregvis && !this.isRegistration && !this.elPanel.isVisible() ) myvis = true;
		// if( !myvis && this.isWaiting && this.noPlaceByLimits ) myvis = true;
		this.#elMyline.makeVisible( myvis );
		if( myvis ) {
			this.elMyreg.makeVisible( myregvis );
			this.elMymenu.makeVisible( this.isReged && ( this.myData?.state!=='out' || this.myData?.place>0 ) );
/*
			this.elNospace ||= html( `<span class='display_none'>{Maximumparticipantsreached}<button><button class='default noborder display_none' data-warn='red' data-action='upgrade'>Upgrade</button></span>`, this.#elMyline );
			this.elNospace.makeVisible( this.noPlaceByLimits );
			this.elNospace.$( 'button' ).makeVisible( this.canAdmin );
*/
		}
		this.#elReg.makeVisible( this.canRegNow );
		this.elPanel.style.justifyContent = myregvis && !myvis? 'center' : '';
		let str = '';
		if( data ) {
			if( data.textstate ) str += data.textstate + '. ';
			if( data.place ) str += '{Place}: ' + data.place;
			if( !str /*&& data.state!=='none'*/ ) {
				// Здесь правильную фразу о регистрации
				str = '✅ {Youareregistered}';
				if( this.isPairs && data.players ) {
					str = '✅ {You} {and} ';
					for( let p of data.players ) {
						if( p.toString()===UIN ) continue;
						str += fillPlayerHTML( User.set( p ), {
							size: 48,
							notextblock: true
						} );
					}
				}
				if( this.isTeams ) {
					let l1 = '✅ ';
					if( data.state==='none' || data.count < this.full.teams_min )
						l1 = ``;
					if( data.count < this.full.teams_min )
						l1 += `{Players}: ${data.count} {fromint} ${this.full.teams_min}`;
					else
						l1 += this.#myTeam?.name || `{Team}`;
/*
					if( data.players ) {
						// Список игроков, покажем аватарами
						for( let p of data.players )
							l2 += fillPlayerHTML( User.set( p ), {
								size: 48,
								notext: true
							} );
					}
*/

					str = `<div class='column center'><span class='rem'>${l1}</span></div>`;
					if( data.count < this.full.teams_min ) {
						let invs = '';
						if( data.invitations ) {
							for( let p of data.invitations ) {
								if( data.players.includes( p ) ) continue;
								invs += fillPlayerHTML( User.set( p ), {
									size: 48,
									notext: true
								} );
							}
						}
						if( invs )
							str += `<div class='column center' style='margin-left: 2rem'><span style='font-size: 1rem'>{Invitations}</span><div>${invs}</div></div>`;
					}
				}
			}
		}
		this.#elMystate.setContent( str );
		// this.#elMystate.makeVisible( !!str );
		this.#elMine.setContent( str );
		this.checkInvites();
		this.checkTD();
		if( !this.elTD.parentElement )
			$( '.myself_panel .tourmine' )?.appendChild( this.elTD );
		this.elTD.parentElement?.show();
	}

	checkTD() {
		// +1m only if timer is going down and 20min or less
		this.checkCanTD();
		this.elAdd1min.makeVisible( this.canAdmin && this.elTimer.dir=== -1
			&& this.elTimer.isVisible() && this.elTimer.getValue()<10000*60 );
		// Tournament editing is possible before 15 min to start
		this.elEdit.makeVisible( this.canAdmin || ADMIN );
		this.elMute.makeVisible( !this.isFinished && this.full.founder && ( this.canAdmin || ADMIN ) );
		this.elDelete.makeVisible( this.canAdmin && this.isWaiting
			&& ( !this.count || this.elTimer.getValue()>10000*60 ) );
		// Admin
		this.elRefpage.makeVisible( !!ADMIN || LOCALTEST );
		this.elClone.makeVisible( this.isFinished && ( this.canAdmin || ADMIN ) || false );
		let lv = this.isLimitsVisible;
		// this.elLimits.makeVisible( lv );
		if( lv ) this.fillLimits();

		if( this.canAdmin && ( this.full?.demo || this.full?.team?.tid===4210 || window.ADMIN ) && this.isRegistration ) {
			this.elRobots ||= construct( '.display_none.visible.control.padding 🤖{Robots}', this.elLine, this.robotsClick.bind( this ) );
			this.elRobots.show();
			this.setRobotsCaption();
		} else
			this.elRobots?.hide();
	}

	get isLimitsVisible() {
		return this.isWaiting && this.canAdmin || LOCALTEST || false;
	}

	get noPlaceByLimits() {
		let max = this.maxParticipants;
		return max>0 && this.count>=max;
	}

	get maxParticipants() {
		let max = this.limits.maxavailable || this.limits.maxreserved,
			model = this.limits.paymodel || '';
		if( !max ) {
			if( model==='demo' ) max = 8;
			else if( model.startsWith( 'max' ) ) max = +model.split( 'max' )[1];
			else if( model==='payby20' ) max = 20;
			// else if( LOCALTEST ) max = 10;
		}
		return max || 0;
	}

	fillLimits() {
		log( 'Limits ' + JSON.stringify( this.limits ) + '. admin=' + this.canAdmin );
		if( !this.isLimitsVisible ) return;
		let max = this.maxParticipants,
			model = this.limits.paymodel || '';
		if( max>0 ) {
			let str = '';
			if( model==='demo' ) str += `<span style='_background: white; color: red; margin: 0 0.5em; border-radius: 5px; '>{Demo}</span>`;
			str += `👤 <span data-field='count'>${this.count || 0}</span>/${max}`;
			// this.elLimits.firstChild.setContent( str );
			let left = max - this.count;
			// let b = this.elLimits.$('button');
			b.style.background = !left && 'red' || left<=3 && 'orange' || left>'';
			b.setContent( this.limits.paymodel==='demo'? '{Upgrade}' : '+20' );
			b.classList.toggle( 'default', left<=40 );
		} else {
			// this.elLimits.hide();
		}
	}

	checkAdj() {
		let v = this.canAdmin && this.td?.adj_need || false;
		this.elAdjustments.makeVisible( v );
		if( v )
			this.elAdjustments.src = makeClientUrl( `/tour/${this.id}/adj` );
	}

	async regSend( on ) {
		let inv = window.getInvitationCode && window.getInvitationCode( 'tour_' + this.id ),
			addcode = (on && inv)? ` invitationcode=${inv}`: '';
		return await elephCore.do( `type=register event=${this.id} on=${on} ${addcode}` );
	}

	async regTour( e ) {
		if( !this.id || !e.target ) return;
		e.stopPropagation();
		let button = e.target.closest( '[data-action]' ),
			action = button?.dataset.action;
		// if( !on && !Core.checkCanPlay( viewRoom['gamegroup'] ) ) return;
		if( action==='unreg' ) {
			if( [ 'out', 'deny' ].includes( this.myData?.state ) ) return;
			if( await askConfirm( '🏃 {Leave} {tournament} ' + ( this.full.title || '' ) + '?' ) )
				this.regSend( 0 );
			return;
			// return this.confirmUnreg();
		}
		if( action==='reg' ) {
			await elephCore.checkAuth();
			if( this.isPairs ) {
				// Invitation mechanic
				let module = await import( './invitepair.js' );
				module?.default.make( 'tourpair.' + this.id );
				return;
			}
			if( this.isTeams ) {
				// Если создается мини-команда, то запрос на сервер
				// Если регистрируется большая команда, то API-запрос
				button.setSpinner( true );
				if( this.#myTeam?.cancreate ) {
					let res = await API( `/tour_createteam`, {
						tour_id: this.id
					} );
					if( res?.ok ) {
						this.#setMyTeam( res.participant || res.team );
					}
					// await elephCore.do( `type=register event=${this.id} on=1` );
				} else {
					let res = await API( `/tour_regteam`, {
						tour_id: this.id,
						team_id: this.#myTeam.id
					} );
					if( res?.ok && res.participant )
						this.#setMyTeam( res.participant );
				}
				this.checkMe();
				// this.createTeam();
				return;
			}
			button.hide();
			let res = await this.regSend( 1 );
			if( res.error || res.confirm )
				e.target.show();
		}
		// setTimeout( () => this.checkTourReg(), 1000 );
	}

	// Проблема: все ушли в WAITING и не видны были
	// Таскать можно, только если моя команда, и я капитан
	// По клику на игрока со счетом, попадать на стол
	// Не давать менять игроков внутри SCORE (замена)


	#participantClick( e ) {
		if( this.isFinished ) return;
		let el = e.target.closest( '[data-participant]' );
		if( !el ) return;
		let p = el.dataset.participant;
		if( !p ) return;
		if( !LOCALTEST && this.#teambar?.team_id===p ) return;	// My team. No doubling
		this.#teambar2 ||= html( `<div is='neo-teambar' data-simple='withcaption' data-closeable='1'></div>`,
			// this.#myTeambar ||= html( `<neo-teambar data-eventid='${this.id}' data-teamid='${this.#myTeam.id}'></neo-teambar>`,
			this.room.headersArea );
		import( './teambar.js' );
		this.#teambar2.dataset.participant=`${this.id}_${p}`;
	}

	async clickAdmin( e ) {
		let act = e.target.dataset['action'];
		if( !act ) return;
		if( act==='delete' ) {
			if( !this.isWaiting ) return;
			if( this.count && this.elTimer.getValue()<10000*60 ) return;
			if( await askConfirm( '🔥 {Tournament_removal}: ' + this.id ) ) {
				let back = this.full.lback,
					jres = await API( `/tourdelete`, { tourid: this.id } );
				if( jres.ok )
					fire( 'golocation', back );
			}
			return;
		}
		if( act==='refpage' ) {
			window.open( `${coreParams.adminHost||CLIENTHOST}/tour/${this.id}/refery` );
			return;
		}
		if( act==='edit' ) {
			// Edit tournament
			e.target.setSpinner( true );
			let mod = await import( './touredit.js' );
			e.target.setSpinner( false );
			mod.Touredit.edit( this.id );
			return;
		}
		if( act==='clone' ) {
			(await import( './touredit.js' )).clone( this.id );
			return;
		}
		elephCore.do( `type=event event=${this.id} action=${act}` );
	}

	modified() {
		delay( ( this.#checkTourRegBounded ||= this.checkTourReg.bind( this ) ) );
	}

	clearContent() {
		this.canReg = false;
		this.elPeople.$( 'span' ).setContent( '' );
		this.phase = '';
		this.description = '';
	}

	setId( _id ) {
		if( this.id===_id ) return;
		log( 'Setting tourline-id ' + _id );
		for( let el of this.elHolder.$$( '.prizeamount' ) ) {
			el.dataset.tourid = _id;
			el.dataset.textContent = '';
		}
		this.sub?.release();
		this.sub = null;
		this.#data = null;
		this.id = _id;
		this.#prizeStr = null;
		this.origin = 'tour_' + _id;
		// this.data = {};
		this.limits = {};
		this.td = null;
		this.elPanel.hide();
		this.elHolder.hide();
		this.#elMyline.hide();
		this.#teambar?.removeAttribute( 'data-participant' );
		this.#teambar2?.removeAttribute( 'data-participant' );
		this.#elParticipants?.removeAttribute( 'data-event' );
		this.#elShortInfo.dataset.popupinfo = '';
		this.elAdjustments.hide();
		this.elAdjustments.src = '';
		this.canAdmin = false;
		this.#robotsCount = 0;
		this.setPreview( false );
		this.checkIFRAME();
		if( this.id ) {
			this.sub = elephSubscribe.add( this.#eventid, this.parser );
			this.#data = this.sub.sub.values;
			this.checkMe();
		} else {
			this.modified();
		}
		this.clearContent();
	}

	checkTimer( o ) {
		if( o!==undefined ) this.checkPoint = +o;
		this.elTimer.set( this.checkPoint, this.isFinished? 0 : 'auto' );
		this.checkTD();
	}

	restPlayers( val ) {
		// Если я отдыхаю, то выдать крупную инфу
		let ar = val?.split( ',' ),
			iskip = ar?.includes( UIN ),
			miss = this.full.missingtourbonus || '';
		if( miss && !miss.includes( '%' ) ) miss = '+' + miss;
		if( iskip ) this.skipInfo ||= html( `<div style="background: oldlace; cursor: pointer" class="display_none sheet">
			<span>🍵 {Youmissround}</span>
			<span class='hideempty' style='color: var(--color_goodgreen); margin-left: 1em; font-weight: bold'>${miss}</span></div>`,
			this.#elMyline, () => this.skipWin.show() );
		if( iskip && !this.skipInfo.isVisible() ) {
			// Большое окно о пропускании тура
				this.skipWin ||= makeBigWindow( `<div class='column' style='gap: 0.5em; max-width: 20em;'>
				<span style='font-size: 1.5rem'>🍵 {Youmissround}</span>
				<span class='subtitle' style='font-size: 1.2rem'>{Youmissround_full}</span>
				<span class='hideempty' style='color: var(--color_goodgreen); padding: 0.5em 1em; font-size: 2rem; font-weight: bold'>${miss}</span></div>` );
			this.skipWin.show();
			this.#elMyline.show();
		}
		this.skipInfo?.makeVisible( iskip );
	}

	setCount( val ) {
		if( this.count===val ) return;
		// Не дергать рефрешем страницу регистраций, если народу много
		if( val && val<5 ) this.refreshPreview();
		this.count = val;
		this.elPeople.$( 'neo-counter' ).setContent( val );
		this.elPeople.makeVisible( !!val );
		this.checkIFRAME();
		this.checkInviteUpgrade();
		if( this.limits?.maxavailable ) {
			this.fillLimits();
			if( Math.abs( this.count-this.limits.maxavailable )<2 ) {
				this.modified();
			}
		}
		if( this.canAdmin )
			for( let o of this.elPanel.$$( '[data-field="count"]' ) )
				o.textContent = val;
	}

	async parseOffliners( val ) {
		if( ( !DEBUG && !this.canAdmin ) ) return;
		if( !val ) {
			this.elOfflineCount.textContent = '';
			if( this.offliners ) this.offliners.hide();
			return
		}
		if( !this.offliners ) {
			this.offliners = new ((await import( './usersview.js' )).Usersview)( this.elHolder, {
				title: 'Offliners',
				style: 'line'
			} );
			this.offliners.holder.classList.add( 'display_none', 'offliners' );
		}

		this.offliners.parse( val );
		this.elOfflineCount.textContent = 'Offline: ' + this.offliners.count;
	}

	checkIFRAME() {
		// log( `Checking IFRAME: ${this.phase}/${this.count}` );
		if( !this.id ) return this.setPreview( false );
		// Покажем таблицу в паузе. Однако, чтобы не показать старую (не готовую после тура) таблицу
		// в случае, если мы наблюдали за игрой, ждем roundReady
		// this.full.championship - если чемпионат проверять неправильно, могут оказаться игровые столы
		let v = this.id && ( this.isFinished || this.isRegistration ||
			( this.isWaiting && this.count ) || ( this.isPause && ( !this.werePlaying || this.roundReady ) ) ) || false;
		// if( this.phase==='play' || ( this.state && this.state!=='finished' && !this.count ) ) v = false;
		if( this.tables || this.regTables ) v = false;
		this.setPreview( v );
	}

	setPreview( v ) {
		if( v===undefined ) v = true;

		let src = '';
		if( v ) {
			let links = this.isFinished? '' : '?nolinks&nothead=1';
			src = `${CLIENTHOST}/tour/${this.id}${links}`;
			if( UIN ) src += `#u${UIN}`;
		}
		if( this.previewSrc===src ) return;
		log( `TOURIFRAME ${this.id}: tables=${this.tables}, count=${this.count} v=${v} src=${src}` );
		/*if( src ) */  // Условие убрано, чтобы не закэшировалось одно и то же значение страницы
		this.room.previewHTML.src = src;
		this.previewSrc = src;
		this.room.previewHTML.makeVisible( v );
		this.room.tablesArea.makeVisible( !v );
	}

	refreshPreview() {
		// Проблема с обновлением содержимого IFRAME. contentDocument не всегда доступен,
		// тогда просто пробуем обновить src
		let p = this.room.previewHTML;
		if( !p.isVisible() || !this.previewSrc ) return;
		log( `TOUR ${this.id} Refreshing preview ${this.previewSrc}` );
		let doc = null;
		try {
			doc = p.contentDocument;
			doc.location.reload();
		} catch( e ) {
			doc = null;
		}
		if( !doc ) {
			p.src = '';
			p.src = this.previewSrc;
		}
	}

	async upgrade( e ) {
		// Пользователь расширяет список доступных участников.
		// Это всегда означает докупку предмета 'participants20", если не задано иное
		if( e ) e.target.disabled = true;
		let res = API( '/tour_startupgrade', {
			tourid: this.id,
			paymodel: this.limits.paymodel,
			maxavailable: this.limits?.maxavailable || 0,
			maxreserved: this.limits?.maxreserved || 0
		})
		if( e ) e.target.disabled = false;
		if( !res?.ok ) {
			// Ответ на запрос не получен. Отправляемся покупать participants20
			shopping( 'participants20' );
			return;
		}
		if( res.purchase ) {
			if( typeof res.purchase==='object' )
				shopping( res.purchase.map( x => x.id ).join( ',' ) );
			else
				shopping( res.purchase );
			return;
		}
		if( res.available ) {
			let mod = await import( './tools.js' );
			/*
					let models = [ 'max500' ];
					if( this.maxParticipants<20 ) models.push( 'max20' );
					if( this.maxParticipants<100 ) models.push( 'max100' );
			*/

			let model = await mod.selectFrom( {
				items: res.models,
				doselect: true
			} );
			log( 'Upgrade model selected ' + model );
		}
	}

	#robotsCount; #addRobots; #requestRobotsTimeout;
	// Нажатие на кнопку роботов добавляет одного робота
	// Добавление кэшируется и через пол-секунды после последнего нажатия отправляется запрос
	robotsClick( event ) {
		let ctrl = event.ctrlKey || event.metaKey;
		this.#addRobots ||= 0;
		this.#addRobots += ctrl? 10 : 1;
		clearTimeout( this.#requestRobotsTimeout );
		this.#requestRobotsTimeout = setTimeout( this.requestRobots.bind( this ), 500 );
		this.setRobotsCaption();
	}

	async requestRobots() {
		this.#requestRobotsTimeout = null;
		if( !this.#addRobots ) return;
		toast( `Trying to add ${this.#addRobots} robots` );
		let add = this.#addRobots;
		this.#addRobots = 0;
		let res = await API( '/tour_regrobots', {
			tourid: this.id,
			add: add
		} );
		if( res?.count ) this.#robotsCount = res.count;
		this.setRobotsCaption();
	}

	setRobotsCaption() {
		let count = this.#robotsCount || 0,
			cap = '🤖';
		if( count || this.#addRobots ) {
			cap += `<b>${count}</b>`;
			if( this.#addRobots ) cap += '+' + this.#addRobots;
		} else
			cap += '+{robot}';
		this.elRobots.setContent( cap );
	}

	async setPrize( val, server ) {
		if( this.#prizeStr===val ) return;

		let ar = val.match( /(\d+)(!?)/ ),
			newsum = +ar[1],
			change = this.prizeServer!==undefined && newsum > this.prizeServer;
		this.#prizeStr = val;
		this.prize = +newsum;
		if( server ) this.prizeServer = this.prize;
		this.prizeFixed = !!ar[2];
		/*await*/ this.checkPrizeLine();
		for( let o of $$( `.prizeamount[data-tourid='${this.id}'`) )
			this.fillPrizeAmount( o );
		this.#prizeAdd?.setContent( this.prize? '➕' : '💰{Found} {prizefund}' );
		this.#prizeAdd?.makeVisible( !this.prizeFixed && !window.inapppurchaseOnly() );
		// this.fillPrizeAmount( this.prizeAmount );
		this.elPrize.makeVisible( !this.full?.buyin?.clubmoney && !this.prize && !this.prizeFixed && !this.isWaiting && !window.inapppurchaseOnly() );
		// if( this.prize>0 ) this.#prizeLine?.show(); //  || ( !this.prizeFixed && this.isWaiting ) );
	}

	async updateSponsors() {
		if( !this.sponsors ) return;
		let res = this.checkPrizeLine();
		this.sponsorsView?.parse( this.sponsors );
	}

	async checkPrizeLine() {
		// Showing/hiding #prizeLine
		this.#prizeLine?.hide();
		let show = this.prize || this.sponsors;
		if( !show && this.prizeFixed ) return;
		if( !show ) {
			// До начала турнира всегда даем возможность добавлять в фонд?
			if( this.full.team && this.full.team.type!=='team' ) {
			} else {
				if( (this.full.team || this.full.founder) && !this.isFinished /*&& this.phase*/ ) show = true;
				if( this.isRegistration ) show = true;
			}
		}

		if( !show ) return;
		if( !this.#prizeLine ) {
			this.#prizeLine = construct( '.sheet.flexline.display_none.#prizeLine', this.elHolder, this.showPrize.bind( this ) );
			this.prizeSum = construct( '.prizesum.flexline', this.#prizeLine );
			this.prizeAmount = construct( '.prizeamount.full', this.prizeSum );
			this.fillPrizeAmount( this.prizeAmount );
			if( !this.isFinished ) this.#prizeAdd = construct( 'button.display_none.add ➕', this.prizeSum );
			this.sponsorsView = new (await import( './usersview.js' )).Usersview( this.#prizeLine, {
				// classes: 'closer',
				maxvisible: LOCALTEST? 2 : 5,
				style: 'line',
				noborder: true,
				notext: true,
				avatar: true,
				defimg: 'slonr'
			} );
			if( this.sponsors )
				this.sponsorsView.parse( this.sponsors );
		}
		this.#prizeLine.show();
	}

	fillPrizeAmount( element ) {
		element.dataset.tourid = this.id;
		let amount = this.prize||0;
		if( !amount && element.classList.contains( 'full' ) )
			element.setContent( '{Prizefund}: {no}' );
		else
			element.textContent = '💰' + amount.toLocaleString( 'en-US' );
		element.dataset.prizelevel = (!amount && '0') || amount>=1000000&&'1M' || amount>=100000&&'100K' || '';
	}

	showPrize( e ) {
		if( window.inapppurchaseOnly() ) return;

		if( e.target.dataset.magictype==='avatar' ) return;
		if( !this.id || this.isFinished ) return;
		if( this.prizeFixed ) return;
		if( !Tourline.prizeForm ) {
			Tourline.prizeForm = makeBigWindow( `
				<div class='vertical_form'>
				<span>{Prizefund} {now}</span>
				<span class='prizeamount'>💰</span>
				<div class='sponsors hideempty'></div>
				<details><summary class='hidesummary mybutton'>{Increase} {prizefund}</summary>
				<div class='column'>
				<select>
				<option value=50000>💰50,000</option>
				<option value=100000>💰100,000</option>
				<option value=500000>💰500,000</option>
				<option value=1000000>💰1,000,000 {trustee}</option>
				</select>
				<br>
				<button class=mybutton data-action='pay'>{Add} 💰50,000</button>
				<span class='small'>${this.goesToFund} {goestoprizefund}</span>
				</div>
				</div>
			` );
			Tourline.prizeForm.onchange = e => {
				let amount = + e.currentTarget.$( 'select' ).value;
				e.currentTarget.$( '[data-action]' )
					.setContent( '{Add} 💰' + amount.toLocaleString('en-US') );
			}
		}
		let form = Tourline.prizeForm;
		this.fillPrizeAmount( form.$( '.prizeamount' ) );
		form.onclick = this.addprizeClick.bind( this );

		// Если кликнули на кнопку добавления, сразу раскрываем details
		form.$( 'details' ).open = !this.prize || e.target.classList.contains( 'add' );

		form.show();
	}

	async addprizeClick( e ) {
		if( e.target && e.target.dataset['action']==='pay' ) {
			let amount = Tourline.prizeForm.$( 'select' ).value;
			log( 'Sponsoring tournament ' + this.id + ' for ' + amount );
			await elephCore.checkAuth( 'complete' );
			Tourline.prizeForm.hide();
			if( elephCore.myfants.value < amount ) {
				shopping( {
					ids: 'fants',
					needfants: amount
				} );
				return;
			}
			API( '/addtourprize', {
				tourid: this.id,
				amount: amount,
				showedpercent: this.goesToFund
			});
		}
	}

	async inviteIntoTeam() {
		// Приглашение друзей для создания команды
		if( !this.#myTeam?.id ) return;		// No team - no invitation
		let mod = await import( './usersview.js' );
		let users = await mod.selectUser( {
			api_request: 'getuserlist_teaminvite',
			multiple: true,
			tour_id: this.id,
			participant_id: this.#myTeam.id,
			team_id: this.#myTeam.team_id,
			picture: this.full.picture,
			title: this.full.name
		});
		// Если игроки выбраны, создаем команду, отправляем им приглашение. Дальше все отлажено?
	}
}

class CounterElement extends HTMLElement {
	constructor() {
		super();
		// this.value = this.textContent;
		// log( `Counter content=${this.textContent} html=${this.innerHTML}` );
		this.style.overflow = 'hidden';
		this.innerHTML = `<div style='display: inline; position: relative; display: flex; flex-flow: column'>
			<div style='visibility: hidden'></div>
			<div style='position: absolute; top: 0; left: 0; width: 100%; height: 100%'></div>
			<div style='position: absolute; top: 100%; left: 0; width: 100%; height: 100%'></div>
			</div>`;

		// Первый div fade - для корректных размеров. Два следующих дива - для анимации
		this.holder = this.children[0];
		this.divs = this.holder.children;
		this.holder.ontransitionend = this.ontransitionend.bind( this );
		this.value = '';
	}

	setContent( str ) {
		str = str?.toString() || '';
		if( str===this.value ) return;
		let up = +str > +this.value;
		if( str.length > this.value.length ) {
			// Add spaces for correct width
			this.divs[0].textContent = str;		// Чтобы размеры дива сразу увеличились
		}
		this.value = str;
		if( this.holder.style.transform ) {
			// Сбой с анимацией, не делаем
			return this.ontransitionend();
		}
		let float = this.divs[2];
		this.divs[2].textContent = str;
		this.holder.style.transition = 'transform 0.15s';
		float.style.top = up? '100%' : 'initial';
		float.style.bottom = up? 'initial' : '100%';
		this.holder.style.transform = `translateY(${up?-100:100}%)`;
	}

	ontransitionend() {
		this.divs[1].textContent = this.value;
		this.divs[0].textContent = this.value;
		this.holder.style.transition = 'none';
		this.holder.style.transform = '';
	}

/*
	setContent( newval ) {

	}
*/
}

customElements.define( 'neo-counter', CounterElement/*, {
	extends: 'div'
}*/ );
