"use strict";

import Auth from './mods/auth.js';

let
	Login = {},
	sentrequest = false,
	appearanceCounter = 0,
	lastfocus,
	resolve;

let box = makeBigWindow();

box.innerHTML = localize( `
        <div><form class="vertical_form login_form">
        <input class='login_inpname' name='id' type='text' autocomplete="username" required maxlength='50' placeholder='{Loginid}' />
        <input class='login_inppsw' type='password' name='psw' autocomplete="current-password" required maxlength='30' placeholder='{Password}' title='Letters, digits, and \"_\", minimum 4' />
        <div class='display_none failmessage'></div>
        <div class='graybutton login_ok control'>{Login}</div>
        </form></div>
        ` );

let inppsw = box.querySelector( '.login_inppsw' ),
	inpname = box.querySelector( '.login_inpname' ),
	form = box.querySelector( 'form' ),
	// fb
	// = box.querySelector( '.facebook' ),
	fail = box.querySelector( '.failmessage' ),
	buttonOk = box.querySelector( '.login_ok' );

inpname.mytouch = true;
inpname.pattern = '^[а-яА-ЯёЁ\\w\\-@]+$';
inppsw.pattern = '^[\\w]{3,}$';
inppsw.mytouch = true;
inpname.onfocus = inppsw.onfocus = onfocus;
buttonOk.onclick = send;
// box.onclick = onclick;

box.onHide = () => {
	document.removeEventListener( 'keydown', loginkey );
	log( 'Login window hided' );
};

box.addEventListener( 'input', onInput );
box.addEventListener( 'change', onInput );
let ll = localStorage['lastlogin'];
if( ll ) inpname.value = ll;

function isvalid( fix ) {
	if( inpname.value.length<3 ) {
		if( fix ) inpname.focus();
		return false;
	}
	if( inppsw.value.length<3 ) {
		if( fix ) inppsw.focus();
		return false;
	}
	return !sentrequest;
}

form.onsubmit = send;

function send( e ) {
	e.preventDefault();
	if( !isvalid( true ) ) return false;

	//	hide()
	sentrequest = Date.now();
	buttonOk.classList.add( 'sentrequest' );
	onInput();
	if( window.SoftKeyboard ) SoftKeyboard.hide();
	elephCore.mySelf.lastpsw = encodeURIComponent( inppsw.value );

	Auth.fetch( 'type=login&login=' + encodeURIComponent( inpname.value ) + '&password=' + fixedEncodeURIComponent( inppsw.value ) )
		.then( json => {
			requestDone();
			// log( 'Login result: ' + JSON.stringify( json ) );
			if( json.error ) {
				fail.textContent = json.error;
				fail.classList.add( 'visible' );
				return;
			}
			if( json.uid && !json.guest )
				toast( '{Welcome} ' + (json.info.showname || json.uid), 'short' );
			box.hide();
			if( resolve ) resolve();
			resolve = null;
			// Store last successful login
			localStorage['lastlogin'] = inpname.value;
		} ).catch( error => {
		requestDone();
		log( 'Old gate login: obsolete. error ' + JSON.stringify( error ) );
		if( LOCALTEST ) {
			fail.textContent = 'Old Gate';
			fail.classList.add( 'visible' );
		}
	} );
}

function loginkey( e ) {
	if( e.key==='Tab' ) {
		if( e.target===inpname ) inppsw.focus();
		else if( e.target===inppsw ) inpname.focus();
		e.stopPropagation();
		e.preventDefault();
		return false;
	}
	if( e.key==='Enter' ) {
		// Перехватим ENTER самостоятельно (для смартов)
		e.stopPropagation();
		e.preventDefault();
		if( !inpname.value.length ) return inpname.focus();
		if( !inppsw.value.length ) return inppsw.focus();
		buttonOk.click();
		return false;
	}
}

function onInput() {
	fail.classList.remove( 'visible' );
	buttonOk.disabled = !isvalid();
}

function onfocus( e ) {
	lastfocus = e.target.name
}

box.onShow = () => {
	log( 'Login show started' );
	onInput();
	document.addEventListener( 'keydown', loginkey );
	let focuspsw = !appearanceCounter && inpname.value.length>0 || lastfocus==='psw';
//		inpname.autofocus = !focuspsw
//		inppsw.autofocus = focuspsw
	setTimeout( function() {
		focuspsw ? inppsw.focus() : inpname.focus();
		window.SoftKeyboard && SoftKeyboard.show();
		onInput();
		log( 'On show login is ' + inpname.value );
	}, 0 );
	appearanceCounter++
};

function requestDone() {
	sentrequest = false;
	buttonOk.classList.remove( 'sentrequest' );
}

elephSubscribe.addParser( 'AUTH', ( o, minor ) => {
	requestDone();
	if( o && o.error ) {
		fail.textContent = o.error;
		fail.classList.add( 'visible' );
	}
} );

Login.show = async res => {
	resolve = res;
	await cssInject( 'login' );
	box.show();
}
Login.holder = box;

export default Login;
