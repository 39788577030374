const UNIQLENGTH = 33;

async function getUniq( uniq ) {
	let res = await API( '/uniq/get', {
		uniq: uniq
	}, 'internal' );
	if( !res?.ok ) {
		return;
	}
	// Solo открывается для type: 'makemove'
	let solo = await import( './solo.js' );
	solo.go( {
		location: uniq,
		...res.data.public
	} );
}

function fromBBOformat( str ) {
	let par = new URLSearchParams( str ),
		res = {
			game: 'bridge',
			boardno: par.get( 'b' ),
			vulnerable: par.get( 'v' ),
			dealer: par.get( 'd' ),
			auction: par.get( 'a' ),
			players: Array( 4 ).fill( '' )
		};

	let hands = [];
	for( let i=0; i<4; i++ ) {
		let s = 'nesw'[i];
		hands[i] = par.get( s );
		res.players[i] = par.get( s+'n' );
	}
	res.hands = hands.join( ',' );
	return res;
}

document.addEventListener( 'paste', event => {
	let f = document.activeElement;
	if( f?.tagName==='INPUT' || f?.tagName==='TEXTAREA' ) return;
	let data = event.clipboardData.getData( 'text' );
	if( !data ) return;
	log( 'Intent paste event: ' + data );
	toast( `Trying ${data}...` );
	intent( data );
} );



export async function intent( params ) {
	if( params.length===UNIQLENGTH && params[0]==='d' ) {
		getUniq( params );
		return;
	}
	let url = params.url || params.data || params.toString?.(),
		data,
		hash = url?.match( /#(.*)$/ )?.[1];

	if( !url ) return;

	// Check BBOstyle handviewer URL (no need to parse)
	if( url.includes( 'handviewer.html?b=' ) ) {
		data = fromBBOformat( url.split( 'handviewer.html?' )[1] );
	} else {
		// Download requested url. Spinner on solo while downloading
		window.cardsSolo?.wait();
		// Sometimes we need number of tries
		let res, tries = 1, html;
		if( window.cordova && url.match( /^https?:/ ) ) {
			try {
				let response = await fetch( url, {
					method: 'GET',
					mode: 'no-cors'
				} );
				if( response.ok )
					html = await response.text();
			} catch( e ) {
				log( `Fetch ${url} failed: ${e.toString()}` );
			}
		}

		if( LOCALTEST ) {
			let _apiurl = APIURL;
			try {
				APIURL = 'http://localhost:9001';
				res = await API( `/parse?${url}`, {
					url: url,
					html: html
				}, 'internal' );
			} catch( e ) {}
			APIURL = _apiurl;
		}
		if( !res?.ok ) {
			res = await API( `/parser/parse?${url}`, {
				url: url,
				html: html
			}, 'internal' );
		}
		window.cardsSolo?.wait( false );
		if( !res?.ok ) return;
		data = res.result;
	}

	if( data.type==='event' ) {
		import( './event.js' ).then( mod => {
			mod.goEvent( data, url, hash );
		});
		return;
	}
	// Successfully decoded board. Show it
	import( './solo.js' ).then( mod => {
		mod.soloGo( {
			...data,
			mode: 'view',
			location: '?' + url,
			godmode: true,
			source: {
				url: url
			}
		} );
	} );
}

export function intentReceived( params ) {
	log( 'Intent action: ' + params.action ); // type of action requested by the user
	log( 'Intent exit: ' + params.exit ); // if true, you should exit the app after processing

	let int = params.items[0];
	if( int ) {
		intent( {
			type: int.type,
			data: int.data
		});
/*
		cordova.openwith.load( intent.items[0], async function( data, item ) {
			intent( {
				data: data
			} );
		} );
*/
	}
}

dispatch( 'intent', params => {
	intent( params );
});
