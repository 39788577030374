export default class Table {
	constructor( options ) {
		this.number = 0;
		this.options = options || {};
		this.holder = construct( '.table.new.display_none.visible' );
		this.holder.table = this;
		this.elCaption = construct( '.display_none.table_caption.visible.flexline.center', this.holder );
		this.icons = construct( '.hideempty.icons.flexline', this.elCaption );
		this.iconsEmo = construct( '.emoji', this.icons );
		if( this.options.numbervisible || LOCALTEST )
			this.elnumber = construct( '.number', this.icons );
		// this.eye = construct( '.kibieye.display_none ✨', this.icons );
		// this.eye = construct( '.kibieye.display_none ✨', this.icons );
		this.elPlayers = construct( '.display_none.players.visible', this.holder );
		this.elPic = construct( 'img.display_none.picture[width=256]', this.holder );
		this.elImg = construct( 'img.hideempty.display_none[width=256]', this.holder );
		this.ico = construct( '.table_ico', this.holder );
		this.anonymous = false;
		// if( Core.global.useBet )
		this.elbet = construct( '.bet.fants.hideempty', this.elCaption );
		this.elcap = construct( '.table_cap', this.elCaption );
		this.player = [];
		this.plrimages = [];
		for( let i = 0; i<4; i++ ) {
			let p = construct( `.table_p${this.anonymous?'.anonymous':''}`, this.elPlayers );
			p.setAttribute( 'plno', i );
			this.player[i] = p;
		}
		this.holder.addEventListener( 'click', this.#click.bind( this ), { capture: true } );
		if( this.options.collecting ) this.holder.classList.add( 'collecting' );
		this.elImg.style.backgroundColor = '#256c4d';
		// if( this.options.nocaption ) this.caption.classList.add( 'display_none' );
	}

/*
	listen( event, handler ) {
		this.holder.addEventListener( event, e => handler( e, this ) );
	}
*/

	#click( e ) {
		// let t = e.currentTarget;
		// if( !t || !this.tableID ) return;
		// log( 'Table clicked ' + this.tableID );
		// if( !this.module ) return;
		if( this.options.onsit && e.target.tagName==='BUTTON' ) {
			this.options.onsit( { target: this, place: +e.target.dataset.place } );
		}

		e.preventDefault();
		e.stopPropagation();
		fire( 'userasktable', this );
	}

/*
	show( val ) {
		this.holder.classList.toggle( 'visible', !!val );
		if( !this.sub ) this.sub = Subscribe.add( 'table_' + this.id, this.parser.bind( this ) );
	}
*/

	subscribe() {
		if( !this.subBrief ) this.subBrief = elephSubscribe.add( 'table_' + this.id, this.parse.bind( this ) );
	}

	unsubscribe() {
		if( this.subBrief ) this.subBrief.release();
		this.subBrief = null;
	}

	setPlayers( data ) {
		if( data===undefined ) data = this.lastPlayers;
		else this.lastPlayers = data;
		const p = data.split( ',' );
		let npl = p.length, mine,
			nfilled = p.reduce( ( count, current ) => count + (/[\?-]/.test( current )? 0 : 1), 0 ),
			nwait = p.reduce( ( count, current ) => current!=='?'? count+1 : count, 0 );
		this.places = npl;
		this.cansit = this.myplace = undefined;
		this.filled = 0;
		this.elPlayers.dataset.places = npl;
		if( npl>4 ) {
			this.holder.classList.toggle( 'bigtable', npl>4 );
			this.player[0].textContent = nwait? `${nfilled}/${npl}` : nfilled;
			for( let i = 1; i<this.player.length; i++ ) this.player[i].textContent = '';
			return;
		}
		let bottom = npl===4 && 2 || /*npl===2 && 1 ||*/ 0;
		this.users = [];
		this.reserv = [];
		for( let i = npl; i--; ) {
			this.users[i] = window.User.set( p[i], { nopicture: true } );
			this.reserv[i] = '[?'.includes( p[i][0] );
			if( this.users[i] ) this.filled++;
			if( this.users[i] && this.users[i].id===window.UIN ) {
				mine = true;
				this.myplace = i;
				if( this.reserv[i] ) this.cansit = i;
				if( !this.options['nordup'] ) bottom = i;
			}
		}
		// this.holder.classList.toggle( 'mytable', !!mine );
		for( let i = 0; i<4; i++ ) {
			let el = this.player[i], ik = i;
			if( !el ) continue;
			if( i>=npl ) {
				el.textContent = '';
				continue;
			}
			let plr, pik;
			if( i<npl ) {
				ik = (bottom + i) % npl;
				// ik = i;
				plr = this.users[ik];
				pik = p[ik];
			}
			if( pik==='-' ) el.textContent = '';
			else if( plr && window.UIN===plr.id && !this.reserv[ik] ) {
				el.innerHTML = '&nbsp;';
				/*if( !this.plrimages[i] ) */this.plrimages[i] = construct( 'img[width=48][height=48]', this.player[i] );
				this.plrimages[i].setMagicUser( UIN );
				// el.style.backgroundImage = User.getAvatarBackgroundImage( UIN );
			} else if( plr?.isrobot() ) {
				// Its a robot
				el.textContent = '';
				el.style.backgroundImage = '';
			} else {
				el.style.backgroundImage = '';
				let html = '';
				if( plr && this.cansit!==ik ) {
					html = plr.getShowName || '';
				} else {
					let winds = ["", "N", "NS", "SWE", "NESW"][npl];
					html = this.options.emptynames==='sides' ? winds[ik] : '○';
					if( this.options.onsit && ( !elephCore.plays[this.options.game] || this.cansit===ik ) ) {
						if( pik==='*' && this.cansit===undefined || this.cansit===ik ) {
							let add = this.options['game'] ? ' data-tablesitgame="' + this.options.game + '"' : '';
							html = localize( '<button ' + add + ' class="control" data-place="' + ik + '">{Sit}</button>' );
						}
					}
				}
				el.innerHTML = html;
			}
			if( plr )
				el.dataset.player = plr.isrobot()? 'robot' : plr.id;
			else
				el.removeAttribute( 'data-player' );
		}
		this.checkCaption();
	}

	checkCaption() {
		if( !this.options['collecting'] ) return;
		let ttl = this.caption || this.users[0] && this.users[0].getShowName
			|| this.options['title'] || '{Team} ' + this.teamNumber;
		if( this.filled<this.places )
			ttl += ` <span class='counter'>${this.filled}/${this.places}</span>`;
		else
			ttl += ` <span class="emoji">${getOkEmoji()}</span>`;
		ttl = ttl.replace( 'DEMO', currency( 'DEMO' ) );
		this.elCaption.innerHTML = localize( ttl );
	}

	setCaption( cap ) {
		this.caption = cap;
		this.checkCaption();
		// this.elCaption.setContent( cap );
	}

	setImg( id, origin ) {
		this.elImg.setMagic( id, origin  )
	}

	parseGame( data, minor ) {
		if( minor==='players' ) {
			this.setPlayers( data );
		} else if( minor==='game' ) {
			this.elCaption.setContent( data.title.replace( 'DEMO', currency( 'DEMO' ) ) );
			this.holder.setAttribute( 'colorgroup', data.color );
		} else if( minor==='waiting' ) {
			let waiting = this.myplace>=0 && data.includes( this.myplace );
			if( this.waiting!==waiting ) {
				this.waiting = waiting;
				this.holder.classList.toggle( 'waiting', waiting );
			}
		}
	}

	parse( o, minor, params ) {
		if( !minor || o.startsWith( 'NOTFOUND' ) ) return;

		switch( minor ) {
			case 'title':
				this.setTitle( o );
				break;
			case 'brief':
				this.checkBrief( o );
				break;
			case 'players':
				this.setPlayers( o );
				break;
			case 'gameitem':
				this.setItem( o );
				break;
			case 'bet':
				this.bet = +o;
				if( this.elbet ) this.elbet.textContent = +o || '';
				break;
			case 'fbet':
				this.fbet = o;
				if( this.elbet ) this.elbet.textContent = this.fbet || '';
				break;
		}
		// t.style.display = null;
		// t.style.visibility = null;

		this.mine = elephCore.plays?.[this.tableID] && true || false;
		// this.holder.classList.toggle( 'mytable', this.mine );
		// t.setAttribute( 'places', t.places ); // t.gamegroup ? t.places : 0 );
	}

	setId( id ) {
		this.id = this.tableID = id;
		this.number = +(id.split( '_' ).slice( -1 ));
		if( this.number && this.elnumber ) this.elnumber.textContent = this.number;
	}

	set anonym( value ) {
		if( this.anonymous===value ) return;
		this.anonymous = value;
		let ar = this.holder.querySelectorAll( '.table_p' );
		for( let o of ar ) o.classList.toggle( 'anonymous', value );
		// if( value ) {
		// 	if( !this.elAn ) this.elAn = construct( '.display_none.anonymous', this.icons );
		// }
		// this.elAn.classList.toggle( 'visible', value );
	}

	setTitle( str ) {
		str = str.replace( / ∫.*/, '' );
		this.setMarked( str[0]==='+' );
		if( str[0]==='+' ) str = str.slice( 1 );
		if( str.startsWith('[A]')) {
			str = str.slice( 3 );
			this.anonym = true;
		} else
			this.anonym = false;
		str = str.replace( 'DEMO', currency( 'DEMO' ) );
		this.elcap.setContent( str );
	}

	checkBrief( str ) {
		if( !str ) return;
		if( str==='-' ) {
			this.v = false;
			return;
		}
		this.brief = str;
		let [full, places, code, sub, color, left, sort, pic] = /^(\d+)([A-Z]*)([a-z]*)(\d)([^#]*)(#\d+)(-[\da-z]{3,32})?/.exec( str );
		this.v = true;
		if( sort ) {
			this.order = sort.slice( 1 );
			this.holder.style.order = this.order;
		}
		if( places>=0 ) {
			// Places
			this.holder.dataset.places = places;
			this.holder.classList.toggle( 'bigtable', places>4 );
		}
		if( color ) {
			this.color = color;
			this.holder.setAttribute( 'colorgroup', color );
		}

		let type = '', subtype = '';
		for( let c of str ) {
			if( 'A'<=c && c<='Z' ) type = c;
			if( 'a'<=c && c<='z' ) subtype = c;
		}

		let module = '';
		if( /* code==='C' || code==='K' || */ code==='XC' || code==='XD' || code==='XO' || code==='XX' || code[0]==='X' ) module = 'board';
		if( code==='D' ) module = 'domino';
		if( code==='CD' ) module = 'cards';
		if( code==='H' ) module = 'chipoker';
		if( code==='P' ) module = 'poker';
		if( code==='RP' || code==='RD' || code==='RB' || code==='R' ) module = 'cards';
		if( code==='MATCH' ) module = 'match';
		if( code==='RF' || code==='RK' || code==='RT' ) module = 'cards'; // durak
		if( code.startsWith( 'G' ) ) module = 'bg';
		let gamegroup = module==='cards' ? 'PREFGAME' : '';
		let gameid = module==='cards' ? 'PREF' : '';
		this.ico.dataset.gameid = gameid;
		this.ico.dataset.gamebase = module;
		this.ico.dataset.gamegroup = gamegroup;
		this.holder.dataset.module = module;
		if( code==='' ) module = 'cards';
		if( LOCALTEST && !module && code==='D' ) module = 'cards';
		this.code = code;
		this.gamegroup = gamegroup;
		this.module = module;
		this.tv = left.includes( '>' );
		this.freeKibi = left.includes( 'k' );
		this.premium = left.includes( 'P' );
		this.askCome = left.includes( '?' );
		this.holder.classList.toggle( 'unknown', !module );
		// this.holder.classList.toggle( 'kibi', this.freeKibi );
		this.holder.classList.toggle( 'tv', this.tv );
		this.iconsEmo.textContent =
			(this.premium&&'💎'||'') + (this.freeKibi && '✨'||'') + (this.tv&&'📺'||'');

		if( pic ) {
			this.elPic.setMagic( pic.slice( 1 ) );
			this.elPic.show();
			this.elPlayers.hide();
			this.elCaption.hide();
		} else {
			this.elPic.hide();
			this.elCaption.show();
			this.elPlayers.show();
		}
		this.setObsolete( false );
	}

	// get isFreeKibi() { return this.freeKibi; }
	get isbridge() { return this.code==='RB'; }

	setObsolete( val ) {
		if( val===undefined ) val = true;
		if( this.obsolete===val ) return;
		this.obsolete = val;
		this.holder.classList.toggle( 'obsolete', val );
		val? this.unsubscribe() : this.subscribe();
	}

	setItem( item, dosubscribe ) {
		let old = this.gameItem;
		if( old===item ) return;
		this.gameItem = item;
		// Если даже ТВ возможно, всегда стараемся просто подойти к столу
		// if( this.tv && this.isbridge && !NEOBRIDGE && !Core.plays[this.gameItem] )
		// 	this.gameItem = 'tv-' + item;
		if( dosubscribe && item && !this.sub )
			this.sub = elephSubscribe.add( item, this.parseGame.bind( this ) );
		this.holder.classList.toggle( 'clickable', !!item );
	}

	release() {
		if( this.removed ) {
			bugReport( `Table ${this.tableID} already removed` );
			return;
		}
		this.removed = true;
		if( this.sub ) this.sub.release();
		this.sub = null;
		this.unsubscribe();
		this.setItem( null );
		this.v = false;
		this.holder.classList.add( 'removed' );
		if( this.holder.parentElement ) this.holder.parentElement.removeChild( this.holder );
	}

	setMarked( val ) {
		if( this.marked===val ) return;
		this.marked = val;
		this.holder.classList.toggle( 'marked', val );
	}

}
