/***
 * Claim for all trick games
 */

export default class Handrecord {
	constructor( game ) {
		this.game = game;
		this.hands = [];
		this.mypos = -1;
		if( game.isdomino ) return;
		this.holder = construct( '.gridone.fade.handrecord_box' );
		this.icon = construct( '.softfade.invertdark.grayhover.handrecord_icon.badge.infobadge' );
		this.elDealInfo = construct( '.dealinfo', this.holder );
		this.elMyresult = construct( '.roundborder.goodbad.hideempty.myresult', this.holder );
		this.elProto = construct( '.sheet.clickable.protocol.flexline.rem', this.holder, this.openProto.bind( this ) );
		this.elDeclarer = construct( '', 'span', this.elProto );
		this.elContract = construct( '.bid.contract', /*'span', */this.elProto );
		this.elResult = construct( '.goodbad.hideempty.result', 'span', this.elProto );

		game.addRoute( {
			prevdealresmap: ( value, _, p1, __, params ) => {
				if( !params ) params = p1;
				let map = params.map || params.data;
				this.checkResMap( map );
			},

			lastprot: value => {
				if( !value ) return;
				if( value.number!==this.number ) return;
				this.setContractRes( value );
			}
		} );

		game.onlayout.add( () => {
			log( `Handrecord onlayout mypos=${this.mypos}` );
			if( this.mypos!==-1 ) return;
			this.game.setPositions( this.hands );
			this.checkResMap();
		});

		game.installLittle( 'handrecord', this );
	}

	show() {
		this.holder.show();
	}

	checkResMap( map ) {
		if( !this.number ) return;
		if( !map ) map = this.game.get( 'prevdealresmap' );
		if( !map || !('get' in map) ) return;
		let mydealres = map.get( this.number.toString() );
		if( !mydealres ) {
			this.setMyResText( '' );
			return;
		}
		this.setImpResult( mydealres );
	}

	setImpResult( result ) {
		log( `Setting IMP result for deal ${this.number}: ${result}. Mypos=${this.mypos}` );
		// Мой результат - результат моей позиции.
		let mypos = this.mypos;
		if( mypos===-1 )
			mypos = this.game.getpov;

		let percent = result.slice(-1)==='%',
			val = percent? +(result.slice( 0, -1 )) : +result,
			negval = percent? +(100-val).toFixed(2) : -val,
			myval = mypos%2==0? val : negval,
			good = percent? myval>=50 : myval>=0,
			mytext = myval+(percent?'%':'');
		this.setMyResText( mytext, good );
	}

	setMyResText( mytext, good ) {
		log( `SetMyRes ${mytext} (${good})` );
		this.elMyresult.setContent( mytext );
		this.elMyresult.dataset['textstyle'] = good? 'good' : 'bad';
		this.icon.dataset['badge'] = mytext;
		if( this.game.isbridge || this.game.ispref ) {
			let percent = mytext.slice( -1 )==='%',
				val = percent ? +(mytext.slice( 0, -1 )) : +mytext;
			this.icon.dataset['badgestyle'] = percent ? val>50 : val>0 ? 'good' : 'normal';
		}
	}

	parseDealPBN( str ) {
		let ar = str.split( ' ' );
		for( let p = 0; p<ar.length; p++ ) {
			if( !this.hands[p] ) {
				this.hands[p] = {
					holder: construct( '.handrecord_hand.display_none.column', this.holder ),
					subholder: [],
					suits: []
				};
				this.game.requireLayout( p, this.hands[p].holder );
			}
			let hand = this.hands[p],
				suits = ar[p].split( '.' ),
				empty = true;
			for( let s = 0; s<4; s++ ) {
				let suit = suits[s];
				if( suit ) empty = false;
				if( hand.suits[s]===suit ) continue;
				hand.suits[s] = suit;
				let suitorder = this.game.ispref? '0321' : '',
					orderstyle = suitorder && `order: ${suitorder[s]}`;
				hand.subholder[s] ||= html( `<div class='suitline' style='${orderstyle}'></div>`, hand.holder );
				const htmlsuits = '♠♥♦♣';
				hand.subholder[s].innerHTML = '<span>' + htmlsuits[s] + '</span><span>' + suit + '</span>';
			}
			hand.holder.classList.toggle( 'visible', !empty );
		}
	}

	parse( o ) {
		// if( LOCALTEST ) o = {"number":10,"initial":"E/ALL","declarer":2,"contract":"2d3*","result":"=","deal":"qt973.94.a62.t64 k8.q7632.kt95.53 a62.kjt.qj874.97 j54.a85.3.akqj82","protuuid":"16DE14CF54E842E2AD9DDA3B12AD0D40","url":"/tour/790568/protocol?dealno=10&key=11581488492a1dfe5c3fff2e70906663"};
		if( this.game.isdomino ) return;
		this.game.showLittleIcon( 'handrecord', !!o );
		if( !o ) return;
		this.data = o;
		this.game.enableLittle( 'handrecord', !!o['deal'] );
		if( o.deal )
			this.parseDealPBN( o.deal );
		this.number = o['number'];
		this.mypos = -1;
		if( o['users'] ) {
			this.mypos = o['users'].indexOf( +window.UIN );
		}
		if( this.mypos>=0 )
			this.game.setPositions( this.hands, this.mypos );
		// if( this.mypos>=0 ) {
		// 	game.needLayFor( this.hands, false );
		// } else {
		// 	game.needLayFor( this.hands, true );
		// }
		this.setContractRes( o );
		let info = this.number + '.';
		if( o.initial ) info += ' ' + o.initial;
		this.elDealInfo.setContent( info );
		this.elMyresult.textContent = '';
		this.checkResMap();
		if( o['url'] )
			petitionUrls.set( 'prevdeal', o['url'] );
		// game.setContract( this.icon, o['contract'] );
	};

	setContractRes( o ) {
		if( this.game.isdomino ) return;
		let decl = o.declarer,
			contract = o.contract || '',
			res = o.result || o.res,
			contracttext = (+contract[0])>=0 && contract.slice( 1 ) || contract;
		this.game.setContract( this.elContract, contracttext, true );
		this.elResult.textContent = res;
		this.elResult.dataset['textstyle'] = res && res[0]==='-'? 'bad' : 'good';
		this.elDeclarer.innerHTML = ( this.game.arrowSymbol && this.game.arrowSymbol( decl ) )
			|| ( this.game.sideNames && this.game.sideNames[decl] ) || 'NESW'[decl] || '';
	}

	openProto( e ) {
		log( '[CLICK] openProto' );
		e.stopPropagation();
		localBrowser( `${this.data?.url}`, true );
	}
}
