// Based on https://habr.com/company/mailru/blog/165213/
//
import './support.js';

let started, detecting,
	myTouch,
	x, y, deltaX,
	currentPage, leftPage, rightPage, newPage,
	holder = construct( '.swipe_holder' ),
	Swiper = { holder: holder };

holder.swiper = Swiper;
holder.addEventListener( 'touchstart', touchStart, { passive: true } );
holder.addEventListener( 'touchmove', touchMove, { passive: true } );
holder.addEventListener( 'touchend', touchEnd, { passive: true }  );
holder.addEventListener( 'touchcancel', touchEnd, { passive: true }  );

/*
if( LOCALTEST ) {
	holder.addEventListener( 'mousedown', touchStart, { passive: true } );
	holder.addEventListener( 'mousemove', touchMove, { passive: true } );
	holder.addEventListener( 'mouseup', touchEnd );
}
*/

Swiper.setParent = parent => {
	parent.appendChild( holder );
	setTimeout( () => Swiper.checkCurrent(), 5000 );
};

document.addEventListener( 'backbutton', () => {
	// Cancel touch if has and force petition
	if( !myTouch ) return;
	log( 'BACKBUTTON: Forceuntouch now' );
	myTouch = null;
	detecting = false;
	started = false;
	moveTo( 0 );
	stopAnimation();
	// bugReport( 'Stalled swipe touch' );
} );

function touchStart( e ) {
	if( window.coreParams?.noSwipe ) return;
	if( !currentPage ) return;
	if( currentPage.noswipe ) return;

	if( e.touches.length!==1 || started ) return;
	// if( e.target?.draggable || e.target.parentElement.draggable ) return;		// Таскательный объект
	detecting = true;
	newPage = null;
	let touch = e.changedTouches[0];
	x = touch.pageX;
	y = touch.pageY;
	myTouch = touch.identifier;
	// log( 'Touch: ' + x + ' : ' + y );
}

function touchMove( e ) {
	// if( !currentPage ) return;
	if( !started && !detecting ) return;
	if( detecting ) detect( e );
	if( started ) draw( e );
}

function isMyTouch( e ) {
	for( let i = e.changedTouches.length; i--; )
		if( e.changedTouches[i].identifier===myTouch ) return e.changedTouches[i];
	// log( 'Is not my touch! ' + e.type );
	return false;
}

function detect( e ) {
	let t = isMyTouch( e );
	if( !t ) return;

	if( Math.abs( x - t.pageX )>=Math.abs( y - t.pageY ) ) {
		// Листает по-горизонтали. Отменяем умолчание (скроллинг страницы)
		// e.preventDefault();
		started = true;
		log( 'Touch started on: ' + (currentPage && currentPage.swipeID) );
	}

	// В любом случае заканчиваем определение, т.к. шанс определить у нас один
	detecting = false;
}

function draw( e ) {
	// e.preventDefault();

	let t = isMyTouch( e );
	if( !t ) return;

	deltaX = t.pageX - x;

	// End-swipe effect
	if( deltaX>0 && !leftPage || deltaX<0 && !rightPage ) {
		deltaX /= 5;
	}

	// Отрисовываем смещение, о чем чуть позже
	moveTo( deltaX );
}

function moveTo( delta ) {
	if( !currentPage ) return;
	let ar;
	if( delta==='left' ) ar = ['-200%', '-100%', 0];
	else if( delta==='right' ) ar = [0, '100%', '200%'];
	else if( delta ) ar = ['calc(-100% + ' + delta + 'px)', delta + 'px', 'calc(100% + ' + delta + 'px)'];

	// log( 'Moving to: ' + delta );
	currentPage.style.transform = ar ? 'translate3d(' + ar[1] + ',0,0)' : '';
	if( leftPage ) leftPage.style.transform = ar ? 'translate3d(' + ar[0] + ',0,0)' : '';
	if( rightPage ) rightPage.style.transform = ar ? 'translate3d(' + ar[2] + ',0,0)' : '';
}

function touchEnd( e ) {
	if( !started ) return;
	let cancel = e==='cancel' || e.type==='touchcancel';
	if( !isMyTouch( e ) ) {
		log( 'Touch end not my touch (touches=' + e.changedTouches.length + ')' );
		if( e.touches.length ) return;
		log( 'This is the last touchend, do it like cancel' );
		cancel = true;
	} else {
		log( 'Mytouch ' + e.type );
	}

	// e.preventDefault();

	swipe( cancel ? 'cancel' : deltaX<0 ? 'left' : 'right' );

	myTouch = null;
	detecting = false;
}

function swipe( dir ) {
	let cancel = dir==='cancel';
	let page = dir==='left' && rightPage || dir==='right' && leftPage || null;
	if( !page ) dir = 0;
	if( !cancel ) {
		currentPage.classList.add( 'animating' );
		if( page ) page.classList.add( 'animating' );
	}

	log( (cancel ? 'Dropback ' : 'Swiping ') + dir + (page ? ' (to ' + page.swipeID + ')' : ' (BACK to ' + currentPage.swipeID + ')') );

	moveTo( dir );

	newPage = page;
	if( cancel ) started = false;		// Immediately cancel all dropping (no animation)
}

dispatch( 'swipeto', str => Swiper.act( str ) );

Swiper.act = page => {
	if( !page ) return;
	Swiper.freezed = false;
	if( typeof page==='string' )
		page = holder.$( `.swipe_page[data-swipeid='${page}']` );
	if( !page || currentPage===page ) return;
	log( 'Swiper activate page: ' + page.swipeID );
	if( page.parentElement!==holder ) {
		addNewPage( page );
	}
	if( currentPage ) {
		currentPage.dataset.html2canvasIgnore = true;
		currentPage.removeAttribute( 'data-swipetop' );
		currentPage.dataset.pos = 'left';
		currentPage.onSwiperChange?.( false, Swiper );
	}
	Swiper.current = currentPage = page;

	currentPage.removeAttribute( 'data-html2canvas-ignore' );
	currentPage.dataset['swipetop'] = true;
	currentPage.dataset.pos = 'center';
	if( !holder.parentElement ) {
		document.body.appendChild( holder );
	}
	currentPage.onSwiperChange?.( true, Swiper );
	detectSidePages();
};

Swiper.close = page => {
	if( !page || page!==currentPage ) return;
	Swiper.act( leftPage || rightPage );
};

function stopAnimation() {
	started = false;
	for( let page of [currentPage, leftPage, rightPage] ) {
		if( !page ) continue;
		page.classList.remove( 'animating' );
		page.style.removeProperty( 'transform' );
		// page.style.removeProperty( 'transition' );
	}
	Swiper.act( newPage );
	newPage = null;
}

function transitionEnd( e ) {
	let page = e.currentTarget;
	if( e.target!==page ) return;
	log( 'Swipe TransitionEnd: ' + page.swipeID + '. pos=' + page.getAttribute( 'pos' ) );
	if( page===currentPage && started ) {
		// log( 'Touch-Swiping done' );
		stopAnimation();
	}
}

function addNewPage( page ) {
	log( 'Adding swipe page ' + page.swipeID );
	holder.appendChild( page );
	page.dataset['swipeid'] = page.swipeID;
	page.classList.add( 'swipe_page' );
	page.dataset.html2canvasIgnore = true;
	page.addEventListener( 'transitionend', transitionEnd );
 }

function detectSidePages() {
	if( !currentPage ) return;
	leftPage = currentPage.previousElementSibling;
	rightPage = currentPage.nextElementSibling;
	if( leftPage ) leftPage.dataset.pos = 'left';
	if( rightPage ) rightPage.dataset.pos = 'right';

	log( 'Detected swipe pages: ' + (leftPage ? leftPage.swipeID : '<NO>') + ' <<< '
		+ (currentPage ? currentPage.swipeID : '<EMPTY>') + ' >>> '
		+ (rightPage ? rightPage.swipeID : '<EMPTY>') );
}

Swiper.addPage = ( page, noactivate ) => {
	addNewPage( page );
	if( !currentPage && !noactivate )
		Swiper.act( page );
	else
		page.dataset.pos = 'left';

	if( !started )
		detectSidePages();
};

Swiper.checkCurrent = () => {
	if( !currentPage && holder.firstElementChild )
		Swiper.act( holder.firstElementChild );
	currentPage?.onSwiperCheckActive?.();
};

Swiper.removePage = async page => {
	if( page.parentElement!==holder ) return;
	holder.removeChild( page );

	// Если в этот момент происходит замена location по какой-то причине,
	// сразу же не удаляемся, так как это повлечет за собой
	if( Swiper.freezed ) await sleep( 1000 );
	if( page===currentPage ) Swiper.act( leftPage || rightPage );
	detectSidePages();
};

Swiper.beforeRelocate = () => {
	Swiper.freezed = true;
};

Swiper.allPages = () => holder.children;
window.Swiper = modules.swiper = Swiper;

export default Swiper;