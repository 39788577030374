//
// Set permutation of figures or cards
//

// HTML5 Drag&Drop в общем-то не так ужасен, можно использовать, но
// 1. Иногда он "залипает", если переключиться в другую вкладку. Надо постоянно контролировать артефакты,
// не очень понятно как.
// 2. Перед DROP всегда пауза, довольно большая 300мс. Для игры это просто невозможно. Для перестановок тоже долго.
//    и это решить невозможно. Использовать этот API не представляется возможным

let store_opacity, lastEntered, dragFig, figs = new Set;

function leave() {
	lastEntered?.classList.remove( 'dragover' );
	lastEntered = null;
}

export function figures( board ) {
	// Permutation
	for( let k in board.figs )
		board.figs[k].holder.draggable = true;

	board.game.mainboard.addEventListener( 'dragstart', e => {
		store_opacity = e.target.style.opacity;
		e.target.style.opacity = 0.5;
		figs.clear();
		for( let k in board.figs ) {
			if( !board.figs[k] ) continue;
			let f = board.figs[k].holder;
			if( f===e.target )
				dragFig = board.figs[k];
			else {
				f.style.pointerEvents = 'none';
				figs.add( f );
			}
		}
		e.dataTransfer.effectAllowed = 'move';
		e.dataTransfer.setData('text/html', 'fig' );
		// e.dataTransfer.setDragImage( e.target, e.screenX-e.clientX, -e.screenY+e.clientY );
	});

	board.game.mainboard.addEventListener( 'dragend', e => {
		e.target.style.opacity = store_opacity;
		dragFig = null;
		leave();
		for( let f of figs ) f.style.pointerEvents = 'initial';
		figs.clear();
		// e.dataTransfer.setDragImage( e.target, e.screenX-e.clientX, -e.screenY+e.clientY );
	});

	// for( let cell of board.cells )
	board.game.mainboard.addEventListener( 'dragenter', e => {
		if( !e.target.classList.contains( 'cell' ) ) return;
		if( e.target===lastEntered ) return;
		leave();
		lastEntered = e.target;
		e.target.classList.toggle( 'dragover' );
	} );

	board.game.mainboard.addEventListener( 'dragleave', e => {
		if( e.target===lastEntered ) leave();
	} );

	board.game.mainboard.addEventListener( 'dragover', e => {
		e.preventDefault();
	}, false );

	board.game.mainboard.addEventListener( 'drop', e => {
		e.preventDefault();
		if( !dragFig ) return;
		// Move figure to this cell

		if( !e.target.classList.contains( 'cell' ) ) return;
		let cell = board.cells.indexOf( e.target );
		if( cell<0 ) return;								// Not my cell
		if( board.figs[cell]===dragFig ) return;			// Same place

		board.movefig( dragFig.cellNumber, cell, null, true );
	} );

}