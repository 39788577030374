"use strict";

export class Usersview {
	constructor( options ) {
		this.options = options = options || {};
		this.mainHolder = construct( '.sheet.usersview.rem.noborder.display_none', options.parent, this.click.bind( this ) );
		if( options.onclick?.includes?.( 'chat' ) ) this.mainHolder.dataset.userclickparams = options.onclick;
		this.holder = construct( '.userlist', this.mainHolder );
		this.separator = html( "<span class='display_none' style='margin: 0.1em 0; border: 0.5px solid lightgray'></span>", this.holder );
		this.hiddenHolder = construct( '.display_none', this.mainHolder );
		this.more = construct( 'button.hideempty.showmore[data-action="showmore"]', this.mainHolder, this.showMore.bind( this ) );
		this.set = new Set;
		this.map = new Map;
		this.setTeams = new Set;
		if( options.usedaystring ) this.daystrings = new Map;
		this.mainHolder.classList.add( this.options.style==='line' ? 'flexline' : 'column' );
		if( this.options.style==='line' ) this.holder.classList.add( 'line' );
		// if( this.options.title )
		// 	construct( `span.title ${options.title}`, this.mainHolder );
		this.options.classes?.split( ' ' ).forEach( x => this.holder.classList.add( x ) );
		// if( this.options.avatar )
		// 	this.holder.classList.add( 'ownimgonly' );
		if( this.options.noborder ) this.mainHolder.classList.add( 'noborder' );
		if( this.options.maxvisible ) this.holder.classList.add( this.options.maxvisible===2 ? 'max2children' : 'max5children' );

		if( this.options.avatar ) {
			this.holder.style.overflow = 'hidden'; // Чтобы избежать скроллера
			document.addEventListener( 'avatarupdated', this.avatarUpdated.bind( this ) );
		}

		if( options.closebutton )
			html( `<span class='control grayhover closebutton icon righttop'
				  style='width: 2rem; height: 2rem' data-closeselect='close'></span>`, this.mainHolder );

		if( this.options.contacts )
			Usersview.contacts = this;

		this.bigID = options.parent?.closest( '.bigwindow' )?.id;

		if( options.filtersHolder ) {
			options.filtersHolder.onclick = this.#filtersClick.bind( this );
			// Сохранение последних выбранных настроек для этого ID (по bigwindow)
			if( this.bigID ) {
				this.setSort( localStorage[`usersview_${this.bigID}_sort`] );
				this.setNulls( +localStorage[`usersview_${this.bigID}_nulls`] || false );
			}
		}

		if( options.show ) void this.mainHolder.show();

		if( options.multiselect ) this.selected = new Set;

		this.win = options.parent?.closest( '.bigwindow' );

		if( options.filterInput ) {
			this.searchInput = options.filterInput;
			this.searchInput.oninput = e => this.setFilter( e.currentTarget.value );
		}
	}

	setSort( s, t ) {
		if( !s ) return;
		t ||= this.options.filtersHolder?.$( '[data-action="sort"]' );
		if( !t ) return;
		t.classList.toggle( 'rotate180', s==='up' );
		t.classList.toggle( 'selected', s!=='none' );
		this.sortMode = s;
	}

	setNulls( s, t ) {
		t ||= this.options.filtersHolder?.$( '[data-action="nulls"]' );
		if( !t ) return;
		t.classList.toggle( 'selected', s );
		t.style.textDecoration = s? 'line-through' : 'initial';
		t.show();
		this.holder.classList.toggle( 'hidenullsortvalue', s );
		if( this.bigID ) localStorage[`usersview_${this.bigID}_nulls`] = +s;
	}

	#filtersClick( e ) {
		let t = e.target;
		if( t.dataset.action==='nulls' ) {
			// Отменяем показ нулевых элементов в таблице
			this.setNulls( !t.classList.contains( 'selected' ) );
			return;
		}
		if( t.dataset.action==='sort' ) {
			// Зададим-отменим сортировку
			let s = this.sortMode || 'none',
				sorts = [ 'none', 'down', 'up' ];
			s = sorts[(sorts.indexOf( s )+1)%sorts.length];
			this.setSort( s, t );
			if( this.bigID ) localStorage[`usersview_${this.bigID}_sort`] = s;
			// Поменяем сортировку
			for( let o of this.holder.$$( '.imagehead' ) ) {
				let sv = Math.floor( +o.dataset.sortvalue ) || 0;
				o.style.order = s==='up' && sv || s==='down' && -sv || 'initial';
			}
		}
	}

	avatarUpdated( e ) {
		let user = e.detail;
		log( 'User ' + user.getShowName + ' updated, ' + user.hasPicture ? 'has avatar' : 'no avatar' );
		if( !user.hasPicture ) return;
		let hidden = this.hiddenHolder.$( `[data-uin='${user.id}']` );
		if( !hidden ) return;
		hidden.parentElement.removeChild( hidden );
		this.holder.appendChild( hidden );
		this.fillShowMore();
	}

	clearSelection() {
		if( this.selected ) {
			this.win.$( '.selectedavatars' )?.html( '' );
			for( let id of this.selected )
				this.holder.$( `.imagehead[data-origin='${id}'] .checkbox` )?.setContent( '◯' );
			this.selected.clear();
			let btn = this.win.$( 'button.doselect' );
			btn.setContent( this.options.selectText || '{Select}' );
			btn.disabled = true;
		}
		if( this.searchInput?.value ) {
			this.searchInput.value = '';
			this.setFilter();
		}
	}

	setSkip( skip ) {
		// Те, что были setfil
		if( (this.skipIds?.length || 0)===0 && (skip?.length || 0)===0 ) return;
		this.skipIds = skip;
		this.filterElements();
	}

	fillShowMore() {
		if( this.showedAll )
			return this.more.textContent = '';
		let more = this.hiddenHolder.children.length;
		if( this.options.maxvisible && this.holder.children.length>this.options.maxvisible )
			more += this.holder.children.length - this.options.maxvisible;
		this.more.textContent = more && ('+' + more) || '';
		this.separator.makeVisible( this.popular?.length>0 || false );

		this.checkOnEmpty();
	}

	updateBalance( el, bal ) {
		// Обновляем показ баланса и сортировку для элемента
		// возможно, правильнее будет так делать и при первом показе (не заполняя HTML)
		let sval = (+bal||0)*100;
		if( +el.dataset.sortvalue===sval ) return;
		el.dataset.sortvalue = sval.toString();
		let order = 'initial';
		if( this.sortMode==='up' ) order = sval;
		else if( this.sortMode==='down' ) order = -sval;
		el.style.order = order;
		let bh = el.$( '.balance' );
		bh?.setContent( showBalance( bal, null, 'no' ) );
	}

	fill( user, options ) {
		options ||= {};
		if( this.map.has( user.itemid || user.id ) ) return '';
		let popularity = this.popular?.indexOf( user.id ),
			order = popularity>=0 ? `order: ${popularity - 100};` : '';

		this.set.add( user.id );
		this.map.set( user.itemid || user.id, user );

		let datatext, dataset;
		// Иногда нужно показать дополнительное поле справа (изменение, баланс и т.п.)
		if( options.mainvalue ) {
			let val = user[options.mainvalue] || '',
				style = '';
			if( options.mainvalue==='amount' ) {
				if( +val>0 ) style = ' color: green;';
				else if( +val<0 ) style = ' color: red;';

			}
			datatext = `<div style='flex-grow: 2'></div>
						<div>
							<span class='balance' style='font-size: 1.2rem; white-space: nowrap;${style}'>${val}</span>
						</div>`;
		}
		else if( options.showvalue==='balance' && typeof options.data==='object') {
			let bal = options.data.balance ?? options.data.account?.balance ?? 0,
				clr = bal<0? ' color: red;' : '';
			datatext = `<div style='flex-grow: 2'></div>
						<div>
							<span class='balance' style='white-space: nowrap;${clr}'>${showBalance( bal, null, 'no' )}</span>
						</div>`;
			// Если сортировка уже переключалась раньше, новые объекты добавляются с правильным order
			let sval = (+bal||0)*100;
			dataset = { sortvalue: sval };
			if( this.sortMode==='up' ) order = `order: ${sval}`;
			else if( this.sortMode==='down' ) order = `order: ${-sval}`;
		}
		if( user.url ) {
			dataset ||= {};
			dataset.openlocalbrowser = user.url;
		}

		return fillPlayerHTML( user, {
			noinfo: options.noinfo,		// This is required to block popup-info when click
			classes: 'display_none visible viewelement',
			noimgorder: !this.options.contacts,
			dataset: dataset,
			control: true,
			// notext: true,
			column: this.options.style==='line',
			style: order,
			defimg: this.options.defimg,
			nofio: true,
			undername: options.data?.undername || options.undername,
			selectbox: this.options.multiselect,
			text: datatext
		} );
	}

	parse( val, sep ) {
		this.setUsers( val.trim().split( sep || ' ' ) );
	}

	setUsers( ar, options ) {
		if( !ar ) return;
		if( ar instanceof Set ) ar = [...ar];
		options ||= {};
		// let clear = false;
		log( 'Set users, length ' + ar.length + JSON.stringify( this.options ) );

		if( options.clear || options.mode==='clear' || this.options.usedaystring ) {
			// clear = true;
			this.map.clear();
			this.holder.innerHTML = '';
			log( 'Cleared. ' + this.holder.children.length );
			this.daystrings?.clear();
		}
		let exist = [],
			map = new Map,
			ids = ar.map( x => (+x) && x  || x.id ),
			sortvalue = this.options.filtersHolder?.$( '[data-action="sort"]' )?.dataset.sortvalue || 'none';
/*
		if( this.skipIds ) {
			// remove ids
			ids = ids.filter( x => !this.skipIds.includes( 'user_' + x ) );
		}
*/
		for( let x of ar )
			map.set( (+x) && x || x.id, x );
		this.popular = typeof options?.popular==='string' && options.popular.split( ',' ) || options?.popular;
		if( options.mode==='graceful' ) {
			for( let el of this.mainHolder.$$( `.viewelement` ) ) {
				let origin = el.dataset.origin.replace( 'user_', '' );
				if( ids.includes( origin ) ) {
					// Найден соответствующий элемент. Однако, если есть дополнительные данные (например, баланс),
					// их тоже нужно сравнить и обновить
					exist.push( origin );
					let a = map.get( origin );
					if( 'balance' in a )
						this.updateBalance( el, a.balance );
				}
				else {
					el.parentElement.removeChild( el );
					this.set.delete( origin );
					this.map.delete( origin );
				}
			}
		}
		let str = '', strHidden = '';
		for( let u of ar ) {
			let uin = +u || u.id;
			if( !this.options.withme && +uin===+UIN ) continue;
			if( options.exclude?.includes( uin ) ) continue;
			// if( this.skipIds?.includes( 'user_' + uin ) ) continue;
			if( uin && !exist.includes( uin ) ) {
				// Строчка показа дня
				if( this.options.usedaystring && u.time ) {
					log( 'Daystring for ' + u.time );
					let dn = ( new Date( u.time*1000 ) ).toLocaleDateString();
					if( !this.daystrings.has( dn ) ) {
						// Добавляем строчку времени
						let dttl = dn===TODAY && "{Today}" || dn===YESTERDAY && "{Yesterday}" || dn;
						str += `<span class='display_none visible listdaytitle' data-daystring='${dn}'>${dttl}</span>`;
						this.daystrings.set( dn, 1 );
					}
				}
				let object = User.set( u ) || u;
				let s = this.fill( object, {
					data: u,
					undername: object.undername || options.undername?.[object.id],
					showvalue: options.showvalue,
					sortvalue: sortvalue,
					...options
				} );
				if( !this.options.avatar || object.hasPicture ) str += s;
				else strHidden += s;
				// 	log( 'Skipped without avatar ' + user.getShowName );
				// if( this.options.maxCount && this.set.size>=this.options.maxCount ) break;
			}
		}

		this.holder.langInsertAdjacentHTML( 'beforeend', str );
		if( strHidden ) this.hiddenHolder.langInsertAdjacentHTML( 'beforeend', strHidden );

		// if( this.options.closer )
		// 	for( let el of this.mainHolder.querySelectorAll( `.imagehead` ) )
		// 		el.style.zIndex = 1000 - ar.indexOf( el.dataset['uin'] );
		this.fillShowMore();
		this.updateFriends();

		// Если менее 10 элементов в списке, отключаем показ nulls (перечеркнутый ноль)
		if( this.count<10 ) {
			this.setNulls( false );
			this.options.filtersHolder?.$( '[data-action="nulls"]' )?.hide();
		}
		void this.mainHolder.show();
	}

	async updateTeamMembers( team, options ) {
		let res = await API( 'team_getuserlist', {
			team_id: team.numericid,
			...options
		}, 'internal' );

		// Получили список игроков, заполним usersView, если эта форма еще актуальна
		if( res?.ok ) {
			res = res.result;
			if( res.users ) {
				for( let o of res.users )
					if( o.comment ) o.undername = o.comment;
					else if( o.captain ) o.undername = '{manager}';
				this.setUsers( res.users, {
					popular: res.popular,
					mode: 'graceful'
				} );
			}
		} else if( LOCALTEST ) {
			let serv = await elephCore.do( `type=getteammembers team=${team.numericid}` );
			if( serv ) {
				this.setUsers( serv.users.map( x => {
					return {
						id: x.toString(),
						undername: 'comment'
					}
				} ), {
					mode: 'graceful'
				} );
			}
		} else {
			// return;
		}
	}

	static prepareTransactions( res ) {
		return res.map( x => {
			if( x==='more' ) return null;
			if( !x.picture && x.name ) x.picture = `/svg/avatars/sign.svg#${x.name[0].toUpperCase()}`;
			if( 'amount' in x ) {
				if( typeof x.amount === 'number' ) x.amount = Number.parseFloat( x.amount.toFixed(2 ) ).toString();
				x.undername = x.comment; // x.name + (x.comment? '. ' + x.comment : '' );
				// x.name = x.amount;
			}
			if( x.type==='transfer' && +x.amount>0 ) {
				// x.undername = '{From}: ' + x.undername;
				x.name = '{From}: ' + x.name;
			}
			if( x.type==='rakewithdraw' ) {
				x.name = '🎁 {Rakewithdraw}';
				x.picture ||= 'f2294ad5076cf81ba6ca43ce0ec3d44e';
			}
			if( x.players )
				x.undername = x.players;
			x.name = x.name?.replace( 'DEMO', currency( 'DEMO' ) );
			return x;
		});
	}

	setTransactions( res ) {
		this.setUsers( Usersview.prepareTransactions( res ), {
			clear: true,
			mainvalue: 'amount'
		} );
	}

	setFilter( filt ) {
		// Оставим в показе только тех, кто подходит
		this.filter = (filt || '').toLowerCase();
		this.filterElements();

		// Если добавлен API-поиск по search
		if( this.options.filterapi ) {
			// Сначала проверим, не загружался ли уже такой фильтр
			if( filt.length<3 ) return;			// Less than 3 symbols skip
			if( this.requestedsearch ) {
				for( let l = filt.length; i--; )
					if( this.requestedsearch?.has( filt.slice( 0, l ) ) ) return;
			}
			API( this.options.filterapi, {
				filter: {
					search: filt
				}
			}, 'internal' ).then( res => {
				if( res.ok ) {
					this.requestedsearch ||= new Set;
					this.requestedsearch.add( filt );
					this.setUsers( res.result );
				}
			} );
		}
	}

	filterElements() {
		let filt = this.filter;
		let days = new Set;
		for( let el of this.mainHolder.$$( `.viewelement` ) ) {
			let origin = el.dataset.origin,
				object = this.map.get( origin );
			if( !object ) continue;
			let v = true;
			if( this.skipIds?.includes( origin ) )
				v = false;
			else if( filt ) {
				if( !object.el ) {
					object.el = {
						name: el.$( '.username' )?.textContent.toLowerCase(),
						undername: el.$( '.undername' )?.textContent.toLowerCase()
					};
					// this.daystrings.get( el.dataset.daystring )?.push( el );
				}
				v = object.el.name?.toLowerCase().includes( filt ) ||
					object.fio?.toLowerCase().includes( filt ) || object.el.undername?.toLowerCase().includes( filt ) || false;
			}
			el.makeVisible( v );
			if( v && el.dataset.daystring ) {
				log( 'Adding day ' + el.dataset.daystring );
				days.add( el.dataset.daystring );
			}
		}
		if( this.options.usedaystring ) {
			for( let dt of this.mainHolder.$$( '.listdaytitle' ) ) {
				log( 'Checkging day ' + dt.dataset.daystring + ' is ' + days.has( dt.dataset.daystring ) );
				dt.makeVisible( days.has( dt.dataset.daystring ) );
			}
		}
		this.checkOnEmpty();
	}

	removeChat( chat ) {
		let holder = this.holder.$( `[data-forchatid='${chat.id}']` );
		if( holder ) holder.parentElement.removeChild( holder );
	}

	add( object ) {
		log( `Chat ${this.id}. Adding object ${object.itemid || object.id}` );
		let h = html( this.fill( object ) );
		h && this.holder.appendChild( h );
	}

	addChat( chat ) {
		let rset = +chat.id ? this.set : this.setTeams;
		if( rset.has( chat.id ) ) return;
		rset.add( chat.id );
		let user = User.set( chat.id );
		log( `Chat. Adding to contacts [${chat.id}] (${rset.size}). user ${JSON.stringify( user )}` );
		let h = html( this.fill( user ) );
		if( !h ) return;
		Usersview.#updateImagehead( h );
		// 	log( 'Skipped without avatar ' + user.getShowName );
		// if( this.options.maxCount && rset.size>=this.options.maxCount ) break;
		this.holder.appendChild( h );
		void this.mainHolder.show();
		// this.holder.insertBefore( h, null );
		// )insertAdjacentHTML( 'beforeend', s );
	}

	show() {
		void this.mainHolder.show();
	}

	static #updateImagehead( h ) {
		if( !h ) return;
		h.classList.add( 'badge', 'chatbadge', 'unreadcounter', 'control' );
		let chatid = h.dataset.origin.replace( 'user_', '' ),
			chat = modules.chat?.getBychatid( chatid ),
			unread = chat?.unread || '';
		h.dataset.forchatid = chatid;
		h.dataset.badge = unread;
		if( unread ) h.style.order = -1;
	}

	updateFriends() {
		if( this.options.showChatCounter || this.options.onclick==='chat' ) {
			for( let h of this.holder.$$( `.viewelement` ) )
				Usersview.#updateImagehead( h );
		}
	}

	get count() {
		return this.set.size + this.setTeams.size;
	}

	mset( data ) {
		if( !this.set.parseMSET( data ) ) {
			return log( 'MSET usersview not changed, size=' + this.set.size );
		}
		let tmp = new Set;
		for( let el of this.holder.$$( `.imagehead[data-origin^=user_]` ) ) {
			let origin = el.dataset.origin.replace( 'user_', '' );
			if( this.set.has( origin ) ) {
				tmp.add( origin );
				continue;
			}
			el.parentElement.removeChild( el );
		}
		let str = '';
		for( let origin of this.set ) {
			if( tmp.has( origin ) ) continue;
			str += this.fill( User.set( origin ) );
		}
		this.holder.langInsertAdjacentHTML( 'beforeend', str );
		this.mainHolder.makeVisible( (this.set.size + this.setTeams.size)>0 );
		this.updateFriends();
		log( 'Showing friends ' + this.set.size );
		// for( let o of document.querySelectorAll( `[data-name='user_8.showname']` ) )
		// 	log( 'TEST 1 found ' + test );
	}

	clear() {
		log( 'Chat. Usersview clear' );
		for( let el of this.holder.querySelectorAll( `.imagehead` ) )
			el.parentElement.removeChild( el );
		this.set.clear();
		this.holder.hide();
	}

	showMore( e ) {
		e.stopPropagation();
		this.holder.classList.remove( 'max5children', 'max2children' );
		this.showedAll = true;
		this.fillShowMore();
	}

	hide() {
		this.holder.hide();
	}

	click( e ) {
		if( this.options.closeselect ) {
			// Осуществлен выбор игрока
			let elorigin = e.target.closest( '[data-origin]' );
			if( elorigin ) {
				let origin = elorigin.dataset.origin;
				e.stopPropagation();
				closeTopBigwindow( this.holder, User.get( origin ) || origin );
				return;
			}
			return;
		}

		if( typeof this.options.onclick==='function' ) {
			let el = e.target.closest( '.viewelement' );
			// Если по клику должен открываться браузер, не выполняем onclick
			if( el?.dataset.openlocalbrowser ) return;
			e.stopPropagation();
			this.options.onclick( e, el, this.map.get( el?.dataset.origin ) );
			return;
		}

		if( this.options.multiselect ) {
			let t = e.target;
			let line = t.closest( '.imagehead' );
			if( line ) {
				e.stopPropagation();
				let id = line.dataset.origin, added = true,
					u = User.get( id );
				if( this.selected.has( id ) ) {
					this.selected.delete( id );
					added = false;
				} else {
					if( this.options.max>0 && this.selected.size===this.options.max ) {
						toast( '{Maximum}: ' + this.options.max );
						return;
					}
					this.selected.add( id );
				}
				line.$( '.checkbox' ).textContent = this.selected.has( id )?'🟢':'◯';
				// line.$( '.checkbox' ).textContent = this.selected.has( id )?'⬤':'◯';
				this.fillSelectButton();
				let win = line.closest( '.bigwindow' );
				if( added ) {
					let img = html( fillMagicIMG( u.getPicture, 48, {
						type: 'avatar'
					} ), win.$( '.selectedavatars' ) );
					img.dataset.origin = id;
				} else {
					let h = win.$( `.selectedavatars img[data-origin='${id}']` );
					if( h ) h.parentElement.removeChild( h );
				}
			}
		}
	}

	fillSelectButton() {
		let btn = this.win.$( 'button.doselect' );
		if( !btn || !this.selected ) return;
		let invtext = this.options.selectText || '{Select}';
		if( !this.selected.size ) {
			btn.setContent( invtext );
			btn.disabled = true;
			return;
		}
		btn.disabled = false;
		let str = `${this.selected.size}👤`;
		if( this.selected.size<4 ) {
			// Чтобы показать ники, придется пошерстить список
			let nicks = [];
			for( let id of this.selected ) {
				let u = User.get( id );
				nicks.push( u.getShowName );
			}
			str = nicks.join( ', ' );
		}
		btn.setContent( `${invtext} ${str}` );
	}

	onEmpty( str ) {
		if( !str ) return;
		this.onemptyholder = html( str );
	}

	checkOnEmpty() {
		if( !this.holder.$( '.visible') && this.onemptyholder )
			this.holder.appendChild( this.onemptyholder );
	}

	static makeSelectWindow( options ) {
		options ||= {};
		let filtersdiv = '';
		if( options.filters ) {
			filtersdiv = `<div class='filters flexline' style='justify-content: center; flex-direction: row-reverse; padding-bottom: 3px; border-bottom: 0.5px solid lightgray; margin: 0 1em;'>`;
			if( options.filters.nulls ) filtersdiv += `<div data-action='nulls' class='flexline w32 icon coloured pointer display_none' style='color: blue; margin: 0.1em 1em; '>0</div>`;
			if( options.filters.sort ) filtersdiv += `<div data-action='sort' class='w32 icon coloured pointer' style='margin: 0.1em 1em; transition: filter 0.2s, transform 0.1s; background-image: url(${IMGEMBEDPATH}/svg/icons/sort.svg)'></div>`;
			filtersdiv += `</div>`;
		}

		let selectButton = '', selectedAvatars = '';
		if( options.multiselect ) {
			selectedAvatars = `<div class='selectedavatars' style='min-height: 52px'></div>`;
			selectButton = `<button class='doselect default' disabled data-closeselect='doselect'>${options.selectText || '{Select}'}</button>`;
		}

		let win = makeBigWindow( {
			id: options.id,
			title: options.title || '{Player}',
			picture: options.picture,
			fullsize: true,
			html: `<input name='filter' class='username' style='margin: 0.1em 1em; padding: 0.5em' placeholder='${options.filterplaceholder||'{Search}...'}'>
				${filtersdiv}
				${selectedAvatars}
				<div class='listholder' style='overflow: hidden auto; margin: 0.3em 0.3em 0 0.3em; ${options.listholderstyle||''}'></div>
				${selectButton}`
		} );
		let usersView = win.usersView;
		if( !usersView ) {
			log( 'Creating Usersview' );
			win.usersView = usersView = new Usersview( {
				parent: win.$( '.listholder' ),
				closeselect: !options.keep,
				filtersHolder: win.$( '.filters' ),
				filterInput: win.$( 'input.username' ),
				...options
			} );
		}
		usersView.show();
		return [ win, usersView ];
	}
}

export class Chatusers {
	constructor( parent ) {
		this.holder = construct( '.chatusers.column', parent );
	}

	setChild( name, object ) {
		let mine = this[name];
		if( mine===object ) return;
		mine?.hide();
		this[name] = object;
		let holder = object.mainHolder || object.holder;
		if( holder.parentElement!==this.holder ) {
			log( 'Reholding of chatUsers' );
			this.holder.appendChild( holder );
			if( LOCALTEST )
			for( let o of $$( `[data-name='user_8.showname']` ) )
				log( 'TEST 2 found ' );
		}
		void holder.show();
	}

	setUsers( users ) {
		this.setChild( 'usersview', users );
	}

	setChat( chat ) {
		this.setChild( 'chat', chat );
	}
}

modules.usersview = Usersview;

let mainView;

export function show() {
	if( !mainView ) {
		mainView = makeBigWindow( {
			title: '{Chats}',
			html: `<input name='filter' class='username' style='margin: 0.1em 1em; padding: 0.5em' placeholder='{Search}...'>
				<div class='listholder' style='overflow: hidden auto; margin: 0.3em 0.3em 0 0.3em;'></div>`,
			fullsize: true
		});
		new Usersview( {
			parent: mainView.$( '.listholder' ),
			contacts: true,
			showall: true,
			show: true,
			filterInput: mainView.$( 'input' ),
			onclick: 'showchatnotempty'
		} );
		import( './chat.js' );
	}
	if( !Usersview.contacts.count )
		modules.chat?.checkContacts();
	void mainView.show();
}

export async function selectUser( options ) {
	// Выбор игрока из списка или ввод ника
	let [ win, usersView ] = Usersview.makeSelectWindow( options );
	usersView.setUsers( [] );

	usersView.clear();
	// API-запрос, который создаст полный список всех предлагаемых игроков.
	// выполняем его, и по его результатам заполняем usersView
	usersView.onEmpty( options.onemptylist );
	let api = options.api_request || options.api;
	if( api ) {
		let team_id = options.team_id,
			json = {};
		for( let k in options ) if( k.endsWith( '_id' ) ) json[k] = options[k];
		API( api, json, 'internal' ).then( async res => {
			// Получили список игроков, заполним usersView, если эта форма еще актуальна
			if( res?.result ) {
				usersView.setUsers( res.result.users, {
					popular: res.result.popular,
					exclude: options.exclude
				} );
			} else {
				let serv = await elephCore.do( `type=getteammembers team=${team_id}` );
				if( serv ) {
					log( 'Received members ' + JSON.stringify( serv ) );
					usersView.setUsers( serv.users );
				}
			}
		} );
	}

	return await win.promiseShow();
}

dispatch( 'loggedout', () => {
	Usersview.contacts?.clear()
});