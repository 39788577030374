'use strict';

import {Usersview} from "./usersview.js";

log( 'team.js loading' );

let oneroom;

export class TeamLine {
	constructor( r ) {
		let room = this.room = oneroom = r;
		let team = room.team;

		this.holder = html( `<div class='display_none teamline flexline spacebetween' style='order: -100; padding: 5px 10px'>
			<div>
			<button data-action='activateaccounts' class='activateaccounts display_none'>💸 Activate accounts</button>
			<div class='flexline account display_none'>
				<span style='margin-left: 0.3em' data-action='teamaccount' class='teambalance rem2 money control hidenextifempty' ></span>
				<button data-action='teamtransfer' class='display_none'>🏹 <span class='hideinportrait'>{Send}</span></button>
			</div>
			</div>
			<div class='adminline display_none flexline' style='justify-self: end'>
				<span class='display_none flexline control grayhover' data-action='teamadmin' style='padding: 0 0.2em'>
				 <span class='hideinportrait'>{Manageclub}</span>
				 <span class='emoji icon w32' style='margin: 0 0.3em; padding: 0.1em; background-image: url(${IMGEMBEDPATH}/svg/icons/menu_black_24dp.svg)'></span>
				</span>
			</div>
			</div>`, this.click.bind( this ), room.headersArea );
		// room.holder.insertBefore( this.holder, room.holder.firstElementChild );

// 		<button data-action='invite' className='emoji'>➕👤</button>

		// <button data-action='members' className='emoji' style='margin: 0'>👥️</button>
//		<button data-action='teamrake' className='display_none emoji' style='margin: 0'>🎁️</button>

		this.update();

		dispatch( 'checkmyteams', () => {
			if( this.holder.isVisible() )
				this.update();
		} );
		dispatch( 'loggedout', this.update.bind( this ) );
		dispatch( 'loggedin', this.update.bind( this ) );
		dispatch( 'checkmyteams', this.update.bind( this ) );

		if( team ) window.hint?.update();
		dispatch( 'hintsloaded', this.update.bind( this ) );
	}

	update() {
		let bal = this.holder.$( '.teambalance' ),
			room = this.room,
			team = room?.team,
			helper = team?.isHelper;
		if( !team ) {
			this.holder.hide();
			return;
		}
		bal.dataset.name = `${this.room.team.itemid}_mybalance`;
		bal.setContent( showBalance( team.mybalance, room.team.currency, 'always' ) );
		let accactive = room.team.currency?.length || false,
			acc = this.holder.$( '.account' );
		log( `Team ${team.id} acc active ${accactive}, isHelper ${team.isHelper}, team ${JSON.stringify( room.team )}` );
		acc.makeVisible( accactive );
		// this.holder.$( '[data-action="teamrake"]' ).makeVisible( accactive );
		this.holder.$( '[data-action="teamadmin"]' ).makeVisible( true );
		this.holder.$( '.adminline' ).makeVisible( team.isHelper );
		// this.holder.$( '.activateaccounts' ).makeVisible( team.isHelper && !team.currency && FANTGAMES );
		// Кнопка перевода видна капитану, который уже узнал, что такое переводы
		this.holder.$( '[data-action="teamtransfer"]' )?.
			makeVisible( team.isHelper && +team.members>1 && window.hint.isDone( 'club_transfers_cap' ) );

		let myteam = elephCore.getMyTeam( team.numericid );
		// this.holder.makeVisible( !!myteam );
		this.holder.hide();

		// Личный блок справа вверху. Показываем там баланс, и, возможно, '{Manageclub}'
		let clubmine = $( '.myself_panel .clubmine' );
		clubmine.dataset.tid = team.id;
		let str = '';
		/*if( team.isFreetrial ) */str +=
			`<span class='demomode hideinportrait display_none ${team.isFreetrial? 'visible' : '' }' 
			data-datavisibility=1 data-name='${team.itemid}.freetrial' data-execute='team.demoClick' 
			 style='font-size: 1.5rem; margin-right: 1em; padding: 0.2em 0.5em; color: white; background: green'>{Trial}</span>`;

/*
		if( team.isHelper && team.isTrial || LOCALTEST ) {
			let all = (+team['trial.days'] || 7 )*24*60*60,
				left = +team.trial - Date.now()/1000;
			str +=
				// `<span class='freetrial' data-execute='team.trialClick'
				// 	 style='font-size: 1.5rem; margin-right: 1em; padding: 0.2em 0.5em; color: white; background: green'>{Trial}</span>`;

				`<div class='column' data-execute='team.trialClick' style='position: relative; height: 3em; justify-content: center; margin-right: 1em' >
					<progress class='freetrial' min='0' max='${all}' value='${left||all/2}' 
						style='position: absolute; width: 100%; height: 100%'></progress> 
					<span style='font-size: 1rem; padding: 0.2em 0.5em; color: green; z-index: 10'>{trial}</span>
				</div>`;

		}

*/
		// My balance
		let hint = team.isDemoclub? "data-hint='democlub_balance'" : '',
			balance = showBalance( team.mybalance, team.currency, 'pretty' );
		str += `<span class='clubbalance importantsize money control '
			data-name='${team.itemid}_user_${UIN||''}.balance'  
			data-execute='team.member' ${hint} data-after='${team.currency||''}'>${balance}</span>
			<span class='portraitonly'></span>`;

		if( team.isHelper )
			str += `<span class='manageclub' data-execute='teamadmin.admin'>
						<button class='hideinportrait wideonly' data-execute='teamadmin.admin'>{Manageclub}</button>
					</span>`;

		/*
				В портретном режим на телефоне не нужно показывать меню капитану. Есть же клик на названии
						<span class='emoji icon w32 control grayhover portraitonly' style='display: inline-block; margin: 0 0.3em; padding: 0.1em; background-image: url(${IMGEMBEDPATH}/svg/icons/menu_black_24dp.svg)'></span>
		*/

		clubmine.setContent( str );
		clubmine.show();
		// For cap/manager we have to download additional information for team
		if( helper && !team.adminLoaded ) this.updateForHelper( team );

		if( FANTGAMES ) {
			window.hint?.check( {
				id: 'democlub_balance',
				type: 'click',
				title: '{Myaccount}',
				biginfo: `{Biginfo_myclubaccount}`
			} );
		}
	}

	async updateForHelper( team ) {
		if( team.adminLoaded==='loading' ) return;
		team.adminLoaded = 'loading';
		let res = await API( '/teamadmin_getinfo', {
			team_id: team.numericid,
			light: true
		}, 'internal' );

		if( !res?.ok ) {
			log( "team: failed teamadmin_getinfo" );
			if( LOCALTEST ) res = { options: { 'accounts.maxlimit': 0 }  };
			else {
				team.adminLoaded = null;
				return;
			}
		}
		team.adminLoaded = 'loaded';
		team.fant_balance = res.fant_balance ?? res.fant?.balance ?? 0;
		team.trial = res.trial;
		team.options = res.options;
		// Подсказки надо обновить, если есть важные сообщения:
		// истек демо-режим, не триал и нулевой баланс
		// if( team.isDemomodeover || team.fant_balance<10 )
			window.hint?.update();
	}

	async click( e ) {
		if( !this.room.team ) return;
		let room = this.room,
			team = room.team;
		switch( e.target.closest( '[data-action]' )?.dataset.action ) {
			case 'teamtransfer':
				teamTransfer( team );
				return;
			case 'activateaccounts':
				(await import( './teamadmin.js' )).activateAccounts( room.team );
				return;
			case 'teamaccount':
				// if( LOCALTEST ) return (await import( './teamadmin.js' )).activateAccounts( team );
				// Сначала покаем нашу информацию о состоянии счета в команде
				teamMember( team, User.set( UIN ) );
				// teamTransactions( team );
				return;
			case 'teamadmin':
				if( !team.isHelper ) return;
				let mod = await import( './teamadmin.js' );
				mod.teamAdmin( team );
				return;
			case 'teamrake':
				if( !team.isCaptain ) return;
				(await import( './teamadmin.js' )).teamRakeAdmin( team );
				return;
			case 'members':
				if( !team.isHelper ) return;
				(await import( './teamadmin.js' )).teamMembers( team, { admin: true } );
				return;
			case 'invite':
				if( !team.isHelper ) return;
				(await import( './teamadmin.js' )).teamInvite( team );
				return;
		}
	}
}

let idempID;
dispatch( 'teamtransferto', o => teamTransferTo );

async function teamTransfer( team ) {
	let tools = await import( './tools.js' ),
	user = await tools.selectUser( {
		api: 'getuserlist_moneytransfer',
		team_id: team.numericid,
		picture: team.picture,
		title: team.getShowName + '. {Intraclub} {transfer}',
		onemptylist: team.isHelper && `<button data-execute='teamadmin.teamInvite' data-objectid='${team.id}'>{Invite}</button>`
	} );
	if( typeof user==='object' )
		teamTransferTo( {
			user: user,
			team: team
		} );
}

export async function teamTransferTo( params ) {
	let user = User.get( params.user ),
		team = User.get( params.team ),
		from = User.get( params.from );
	// let user = User.get( e.target.getDatasetUp( 'origin' ) );
	if( !user ) return;
	if( $( '#transfer.visible' ) ) {
		log( 'team: transfer: twice transfer warning' );
		return;
	}
	idempID = generateUUID(); // Math.random().toString();
	let amount = +params.amount || '0';

/*
	<span class='control grayhoverclassName righttop'
		  style='right: 5px; top: 5px; width: 2rem; height: 2rem;
					background-image: url(${IMGEMBEDPATH}/svg/icons/highlight_off_black_24dp.svg)'
		  data-closeselect='close'></span>
*/
	let title = '{Transfer}',
		isrequest = params.mode?.startsWith( 'request' );
	if( isrequest ) title = `{Transferrequest} {from} ${from.getShowName}`;
	else if( params.mode==='pulloff' ) title = `{Pullof} {from} ${from.getShowName}`;
	else if( params.mode==='transfer' ) title = `{Transfer} {from} ${from.getShowName}`;

	let win = makeBigWindow( {
		repeatid: 'transfer',
		title: title, // from?  : '{Transfer}',
		simple: true,
		closebutton: true,
		html: `<div class='column vertical_form' style='width: 15em; height: 25rem; position: relative'>
		<div class='send amount column spacebetween display_none visible' style='height: 100%; align-items: center;'>
			<div>
				${fillPlayerHTML( user, 48 )}
			</div>
			<div class='column center' style='max-width: 100%; margin: 1em 0 0 0;'>
				<span class='balance display_none visible' style='font-size: 1rem; color: var(--light_555)'>{Balance}: ${team.mybalance||0}</span>
				<div class='flexline' style='max-width: 100%'>
					<input name='amount' data-autosize='1' class='noborder importantsize' size='${amount.toString().length}' style='max-width: 90%; text-align: center; outline: none !important;' 
					data-name='amount' inputmode='numeric' pattern='\d+\.?\d*' value='${amount}'>
					<span class='importantsize'>${team.currency}</span>
				</div>
				<span class='error fade' style='font-size: 1rem; color: red'>Error</span>
				<span class='checkspinner spinner w32 fade'></span>
			</div>
			<div class='column center'>
				<input name='comment' placeholder='{Comment}' size=20 style='padding: 0.3em 0.5em; font-size: 1.1rem;' value='${params.comment?.replace( "'", '"' ) || ''}'/>
				<button disabled data-name='dosend' class='display_none visible default' style='padding: 0.2em 2em; font-size: 2rem'>${isrequest? '{Dorequest}' : '{Send}'}</button>
			</div>
		</div>
		<div class='done column spacebetween display_none' style='position: absolute; width: 100%; height: 100%'>
			<div class='column'>
				<span style='font-size: 5rem;'>👌</span>
				<span data-name='sentinfo' style='font-size: 1.5rem;'>{Transfersent}</span>
			</div>
			<div>
				<span class='display_none visible' data-action='cancel' style='color: var( --light_555 ); cursor: pointer; transition: filter 8s'>{Canceltransfer}</span>
				<button class='default' style='padding: 0.3em 2em; font-size: 2rem'>{Done}</button>
			</div>
		</div>
		</div>
		</div>`
	} );

	let inputAmount = win.$( "input[name='amount']" ),
		inputComment = win.$( "input[name='comment']" ),
		sendPanel = win.$( 'div.send' ),
		sendButton = sendPanel.$( 'button[data-name="dosend"]' ),
		donePanel = win.$( 'div.done' ),
		doneButton = donePanel.$( 'button' ),
		spinner = win.$( '.spinner' ),
		cancelButton = win.$( '[data-action="cancel"]' ),
		error = win.$( '.error' ),
		zeroallowed = team.isCaptain || LOCALTEST,
		transaction_id;

	win.$( '.balance' ).makeVisible( !from );
	checkSendButton();

	function sendRequestPossible() {
		let amount = +(inputAmount.value);
		return (amount>0 || (zeroallowed && inputComment.value.length>5));
	}

	function checkSendButton() {
		sendButton.disabled = !sendRequestPossible();
	}

	inputComment.oninput = checkSendButton;

	inputAmount.oninput = () => {
		// Input change - resizing
		let value = inputAmount.value;
		if( !value ) inputAmount.value = '0';
		else if( value.length>10 ) inputAmount.value = value.slice( 0, 10 );
		else if( value.length && value.startsWith( '0' ) ) {
			for( ; value[0]==='0' && value[1]>='0' && value[1]<='9'; ) value = value.slice( 1 );
			inputAmount.value = value;
		}
		// inp.style.width = (inp.value.length*1.5).toString() + 'em';
		error.hide();
		checkSendButton();
	};

	inputAmount.onkeydown = inputComment.onkeydown = e => {
		if( e.key==='Enter' ) {
			e.stopPropagation();
			trySend();
		}
	};

	function editAmount() {
		sendPanel.show();
		donePanel.hide();
		inputAmount.readOnly = false;
		error.hide();
		checkSendButton();
	}

	async function trySend() {
		let amount = +inputAmount.value;
		if( params.mode?.startsWith( 'request' ) ) {
			win.hide();
			// Выставляется счет, больше ничего не делаем
			let res = await API( '/club_transferrequest', {
				team_id: team.numericid,
				user_id: from.id,
				sender: from.id,
				to: user?.id,
				receiver: user.id,
				amount: amount,
				idemp: idempID,
				comment: inputComment.value
			}, 'internal' );
			if( res?.ok )
				toast( '{Requestsent}' );
			return;
		}
		if( sendRequestPossible() ) {
			// Пытаемся отправить
			sendButton.disabled = true;
			spinner.show();
			inputAmount.readOnly = true;
			let res = await API( '/moneytransfer', {
				idemp: idempID,
				team_id: team.numericid.toString(),
				from_id: params.from?.id,
				to_id: user.id,
				amount: amount,
				comment: inputComment.value
			}, 'internal' );
			spinner.hide();
			inputAmount.readOnly = false;
			if( !res || res?.error ) {
				error.setContent( res?.error || 'Internal error' );
				error.show();
				return;
			}
			if( res.ok || true ) {
				// Перевод отправлен. Покажем кнопку "Готово" (закрывающую окно) и "Отменить перевод"
				sendPanel.hide();
				donePanel.show();
				donePanel.$( '[data-name="sentinfo"]' ).setContent( `${amount} ${team.currency} 🏹 ${user.getShowName}` );
				doneButton.disabled = false;
				cancelButton.style.filter = '';
				cancelButton.makeVisible( res.cancelable );
				setTimeout( () => cancelButton.style.filter = 'opacity(0)', 500 );
				transaction_id = res.transaction_id;
				setTimeout( () => cancelButton.hide(), 10000 );
				return;
			}
			// Какая-то ошибка, покажем её
			inputAmount.readOnly = false;
		}
	}

	cancelButton.onclick = async () => {
		cancelButton.hide();
		doneButton.disabled = true;
		let res = await API( '/moneytransfer_cancel', {
			transaction_id: transaction_id
		}, 'internal' );
		doneButton.disabled = false;
		if( res.error ) {
			toast( res.error );
			return;
		}
		if( res.ok || LOCALTEST ) {
			transaction_id = null;
			editAmount();
		}
	};

	doneButton.onclick = () => {
		// Перевод отправлен, закрываем все окна
		log( `Transfer of ${inputAmount.value} to ${user.getShowName} completed` );
		win.hide();
	};

	inputAmount.select();
	sendButton.onclick = trySend;
	setTimeout( () => inputAmount.focus(), 100 );

	win.show();
}

async function teamTransactions( team, options ) {
	let u = options?.user,
		uid = u ? typeof u==='string' && u || u.numericid : null,
		respromise = API( '/team_gettransactions', {
			team_id: team.numericid,
			user_id: uid,
			withcap: options?.withcap
		}, 'internal' );
	let [win, listview] = Usersview.makeSelectWindow( {
		id: 'transactions_' + team.itemid + (uid ? '_' + uid : ''),
		title: options?.title || team.getShowName + '. {Transactions}',
		picture: team.getPicture,
		filterplaceholder: '{Filter}',
		keep: true,
		usedaystring: true,
		onclick: clickTransaction
	} );
	win.show();
	let res = await respromise;
	if( !res?.result ) return;
	// Round decimals
	for( let o of res.result )
		o.amount = parseFloat( (+o.amount).toFixed( 2 ) );
	listview.setTransactions( res.result );
}

function clickTransaction( e, el, object ) {
	log( 'clck trans: ' + JSON.stringify( object ) );
	let url = object?.url || object?.url_game;
	if( url ) {
		if( DEBUG && url.includes( 'http' ) )
			window.open( url );
		else
			localBrowser( url );
	}
}

dispatch( 'showteamtransactions', o => {
	teamTransactions( o.team, o );
} );

dispatch( 'headclick', async e => {
	let o = e.title;
	if( o.type==='team' ) {
		let team = User.getTeam( o.tid );
		if( !team ) return;
		if( team.canAdmin || LOCALTEST ) {
			e.event.stopPropagation();
			import( './teamadmin.js' ).then( mod => mod.teamAdmin( team ) );
			return true;
		}
		if( !team.isMember ) return;					// Пока про чужие команды инф не показываем
		// Членам клуба покажем игроков и краткую информацию
		e.event.stopPropagation();
		teamInfo( team );
	}
} );

function fillStat( game, rake, name ) {
	if( !game ) return '';
	let str = '';
	for( let k in {...game,...rake} ) {
		let bal = game[k]>0? `<span style='color: green'>+${game[k]}</span>`
			: `<span style='color: red'>${game[k]||''}</span>`;
		str += `<tr style='text-align: left'><td>{${k.capitalize()}}</td><td>${bal}</td>
		<td>${rake?.[k] || ''}</td></tr>`;
	}
	return `<table style='margin: 0.25em 0; padding-top: 0.5em; border-top: 0.5px solid gray'>
		<tbody>
		<tr><td colspan='3' style='color: var( --light_gray )'>${name}</td></tr>
		<tr style='text-align: left; color: var( --light_gray )'><td></td><td>{Result}</td><td>{Rake}</td></tr>
		${str}
		</tbody>
		</table>`;
}

export function member() {
	teamMember();
}

export async function teamMember( team, user, options ) {
// 	if( typeof team==='number' || typeof team==='string' ) team = User.getTeam( team );
	team ||= window.defaultClub;
	user ||= User.set( UIN );
	if( !user ) return;			// Mostly when unlogged in Democlub
	let me = user.isMe,
		winid = me ? (team ? `me_${team.itemid}` : 'user_me') : 'user_team',
		win = $( '#' + winid ),
		showed = false,
		iscap = team?.isCaptain,
		timer;

	if( me && win ) {
		showed = true;
		win.show();
	}

	async function get() {
		let res;
		if( team ) {
			res = await API( '/team_getuser', {
				admin: options?.admin,
				team_id: team.numericid,
				user_id: user.numericid
			}, 'internal' );
			if( !res?.ok )
				if( !LOCALTEST ) return;
				else res = { ok: true, account: { balance: 33.3 } };
		} else {
			res = await API( '/user_getdetails', {
				user_id: user.numericid
			}, 'internal' );
			if( !res?.ok ) {
				if( me ) res = { ok: true, account: { balance: MYFANTS.value } };
				else return;
			}
		}
		res = res?.result ?? res;
		res.account ||= res.fant;
		return res;
	}

	let res = await get();
	if( !res ) {
		win?.hide();
		if( options?.openinfoiferror ) {
			let mod = await import( './userinfo.js' );
			mod.default( user );
		}

		return;
	}
	let ttl = `${user.getShowName}`,
		datateamid = team? `data-team_id="${team.id}"` : '';
	if( team )
		ttl = `${team.getShowName} - ${user.isMe? '{Myprofile}' :user.getShowName}`;
	// if( user.isMe )  ttl = '{Myprofile}';

	win = makeBigWindow( {
		repeatid: winid,
		title: ttl,
		picture: team?.getPicture,
		simple: true,
		html: `<div data-noinfo='1' class="payload column" ${datateamid} style='min-width: 15em; gap: 5px; padding: 0 0.5em; overflow-y: auto'></div>`
	} );

	function fillWindow() {
		// Фото с основной инфой и баланс
		if( !res ) return;
		let account = res.account,
			actions = res.allowed_actions,
			balancedata = '';
		if( !team )
			balancedata = ` data-origin='${user.itemid}' data-field='fant_balance' data-name='${user.itemid}.fant_balance'`;
		// Покажем статус игрока в клубе
		let state = '',
			ismute = +res.mute > Date.now()/1000,
			chat = user.isMe? '' : `<button data-execute='userinfo.openChat' data-id='${user.id}' >💬</button>`;
		if( ismute ) state += `🔇<span class='mutetimer'></span>`;
		if( res.state ) {
			state += { cap: '{Manager}', helper: '{Helper}' }[res.state];
		}
		let candeposit = (!FANTGAMES || +UIN<630000 && +UIN!==27),
			dataname = team? `${user.itemid}_${team.itemid}` : user.itemid,
			// balspan = FANTGAMES && !olduser && !team ? '' : `<span class='${team ? 'money' : 'fants'}' ${balancedata}
			balspan = `<span data-name='${dataname}.balance' data-action='deposit' class='money' ${balancedata} 
			style='font-size: 2rem' data-after='${team?.currency || '💰' }'>${showBalance( account?.balance || 0, team?.currency )}</span>`;
		let shopstr = '';
		if( me && !iscap && (candeposit || team ) )
			shopstr =`<button class='default' data-action='deposit'>{Deposit}</button>`;
		if( !FANTGAMES && me )
			shopstr =`<button class='default' data-action='shop'>{Shop}</button>`;
		let str = `<div class='flexline spacebetween' style='align-self: stretch; /*border-bottom: 0.5px solid gray*/'>
		${fillPlayerHTML( user, { 
			size: 64,
			undername: `${state} ${chat}`,
			editable: user.isMe && 'always' } )
		}
		<div class='column' style='align-self: start; margin-right: 0.5em'>
		${balspan}
		${shopstr}
		</div>
		</div>`;

			// ${me ? '' : "<button data-action='transfer'>🏹 {Send}</button>"}

		/*
			if( me ) {
				if( team )
					str += `<div class='emoji flexline center importantsize' style='padding: 0.5em 0; border-top: 1px solid lightgray'>
						<button data-action='deposit'>🥁 {Deposit}</button>
						</div>`;
			}
		*/
		// Не показываем кнопку "отправить" на своей карточке, так как кажется, что "отправить" этому игроку, и это путает
		// <button data-action='transferout'>🏹 {Send}</button>

		// Блок кнопок строчкой. Отправка перевода и управление
		let sb = '';
		if( team?.currsymbol ) {
			sb += `<span class='fastbuttons flexline' style='gap: 0.5em'>`;
			if( me ) {
				sb += `<button data-action='transferout'>🏹 {Send} ${currency( team.currsymbol )}</button>`;
				if( window.EXTERNALDOMAIN && !window.isGuest() )
					sb += `<button data-execute='auth.signout' data-closeselect=1>{Signout}</button>`;
			}
			else
				sb += `<button data-action='transferto'>🥅 {Send} ${currency( team.currsymbol )}</button>`;
			sb += '</span>';
		}

		if( actions?.manage || options?.admin ) {
			sb += `<button class='display_none visible' data-action='manage'>{Manage}</button>`;
		}
		if( !user.isMe ) sb += `<button class='display_none visible' data-action='chat'>{Message}</button>`;
		if( sb )
			str += `<div class='flexline buttonnomargin' style='border-top: 0.5px solid gray; gap: 1em; padding-top: 0.5em'>${sb}</div>`;

		let sb1 = '';
		if( actions?.request )
			sb1 += `<button class='display_none visible' data-action='request'>Transfer request</button>`;
		if( actions?.pulloff===true )
			sb1 += `<button class='display_none visible' data-action='pulloff'>{Pulloff}</button>`;
		else if( actions?.transfer===true )
			sb1 += `<button class='display_none visible' data-action='transfer'>{Transfer} {from} ${user.getShowName}</button>`;
		if( sb1 )
			str += `<div class='flexline buttonnomargin' style='border-top: 0.5px solid gray; gap: 1em; padding-top: 0.5em'>${sb1}</div>`;

		if( res.actions || options?.admin )
			str += `<div class='manage display_none column buttonnomargin' style='align-items: start; gap: 0.5em; padding-top: 0.5em; margin: 0.5em 0'></div>`;

		// Остальные параметры табличкой
		str += `<div class='transactions display_none visible'>`;
		if( res.lastcaptransfers ) {
			str += `<div class='column' style='margin: 0.25em 0; padding-top: 0.5em; border-top: 0.5px solid gray'>
		<span style='color: var( --light_gray )'>{Manager}-{transactions}</span>
		<div class='lastcaptransfers'></div>`;
			if( res.lastcaptransfers.length>=10 )
				str += "<span class='control' data-action='captransactions' style='color: var( --light_gray )'>{More}...</span>";

			str += '</div>';
		}

		// <span style='color: var( --light_gray )'>{Transactions}</span>
		if( res.lasttransactions ) {
			str += `<div class='column' style='margin: 0.25em 0; padding-top: 0.5em; border-top: 0.5px solid gray'>
		<div class='lasttransactions'></div>`;
			if( res.lasttransactions.length>=5 )
				str += `<span class='control' data-action='transactions' style='color: var( --light_gray )'>{More}...</span>`;
			str += '</div>';
		}
		str += '</div>';

		// if( !res.lastcaptransfers && !res.lasttransactions && team )
		// 	str += '<div style="border-top: 1px solid lightgray; padding: 0.5em 0">{Transactions}: {notyet}</div>';

		str += fillStat( res.gamestat_month || account?.gamestat_month, res.rakestat_month || account?.rakestat_month, 'Month' );

		/*
			let ttl = `${user.getShowName}`;
			if( team ) {
				let img = win.$( '.title IMG' );
				img.setMagicUser( team );
				ttl = `${team.getShowName} - ${user.getShowName}`;
			}
			if( user.isMe ) ttl += ' - {myprofile}';
			win.$( '.title' ).html( ttl );
		*/


		if( me && !team ) {
			// Кнопка создания клуба
			if( FANTGAMES || NEOBRIDGE ) {
				str += `<div class='emoji flexline center' style='padding: 0.5em 0; border-top: 1px solid lightgray'>
				<button data-action='createclub'>🎩 {Createclub}</button>
				</div>`;
			}

			str += `<div class='emoji flexline center' style='padding: 0.5em 0; border-top: 1px solid lightgray; border-top: 1px solid lightgray'>`;
			if( actions?.showgames )
				str += `<button data-action='archive'>📜 {Games}</button>`;
			str += `<button data-action='options'>⚙️ {Settings}</button>`;
			if( window.isGuest() )
				str += `<button data-action='signin'>{Signin}</button>`;
			else
				str += `<button data-action='signout'>🚪 {Signout}</button>`;
			str += `</div>`;
		}

		if( team && !team.isHelper && team.captain ) {
			str += `<div>
				<button data-execute='userinfo.openChat' data-id='${team.captain.id}' data-action='chatwithmanager'>💬 {Chatwithmanager} ${team.captain.getShowName}</button>
				</div>`;
		}

		if( team && (res.canviewotheraccounts && !options?.admin /*|| LOCALTEST*/) ) {
			str += `<div style='padding: 0 0.5em'>
				<button data-action='players'>😎 {Players}</button>
				</div>`;
		}

		win.$( '.payload' ).html( str );

		if( res.lasttransactions ) {
			new Usersview( {
				parent: win.$( '.lasttransactions' ),
				usedaystring: true,
				onclick: transactionClick
			} ).setTransactions( res.lasttransactions );
		}

		if( res.lastcaptransfers ) {
			new Usersview( {
				parent: win.$( '.lastcaptransfers' ),
				usedaystring: true,
				onclick: transactionClick
			} ).setTransactions( res.lastcaptransfers );
		}

		if( !showed ) win.show();
	}

	await fillWindow( res );
	update();

	win.onreturn = async () => {
		log( 'Returning to show user ' + user.getShowName );
		res = await get();
		fillWindow();
	};

	async function update( reload ) {
		// Кнопки управления игроком
		if( reload ) res = await get();
		let state = '',
			ismute = +res.mute > Date.now()/1000;
		if( res.state )
			state += { cap: '{Manager}', helper: '{Helper}' }[res.state];
		if( ismute ) state += `🔇<span class='timer hidempty'></span>`;

		win.$( '.undername' )?.setContent( state );
		if( ismute ) {
			import( './timer.js' ).then( ({ default: Timer }) => {
				timer ||= new Timer(  {
					noholder: true
				} );
				timer.setDestination( +res.mute );
				timer.attach( win.$( '.timer' ) );
			} );
		}

		let str = '',
			actions = res.allowed_actions;
		if( res.account && ( options?.admin || actions?.comment ) )
			str += `<textarea name='comment' style='font-size: 1rem; min-height: 2em; border: 0.5px solid gray; padding: 0.3em' placeholder='{Comment}'>${res.account?.comment || ''}</textarea>`;
		if( actions ) {
			if( actions.mute || actions.unmute ) {
				if( +res.mute > Date.now()/1000 )
					str += `<button data-action='manage_unmute'>🔊 {Enablechat}</button>`;
				else
					str += `<button data-action='manage_mute'>🔇 {Disablechat} {inclub}</button>`;
			}
			if( actions.upgrade_helper ) str += `<button data-action='manage_upgrade_helper'>🆙 {Upgrade}: {helper}</button>`;
			if( actions.downgrade_player ) str += `<button data-action='manage_downgrade_player'>↓ {Player}</button>`;
			if( actions.upgrade_cap ) str +=	`<button data-action='manage_upgrade_cap'>🆙 {Upgrade}: {captain}</button></span>`;
			if( actions.fire ) {
				if( actions.fire===true )
					str += `<button data-action='manage_fire'>🔴 {Remove}</button>`;
				else
					if( actions.fire.includes( 'balance' ) ) {
						let bal = +actions.fire.split( ' ' )[1];
						if( bal>0 )
							str += `<span style='color:var( --color_subtitle ); text-align: left; font-size: 1rem'>🔴 {Impossibletoremovewithnonzerobalance}</span>`;
						else if( bal<0 ) {
							str += `<span>{Needzerobalancetoremove} <button data-execute='team.teamTransferTo data-user='${user.itemid}' data-team='${team.itemid}' data-amount='${-bal}'>{transfer} ${showBalance(-bal,team.currency)}</button></span>`;
						}
					}
			}
		}
		if( res.account && options?.admin && !me ) {
				str += `<label style='align-self: start;' >{Maximumminus}: <input style='margin-left: 0.5em; font-size: 1rem' name='maxlimit' type='number' inputmode='numeric' min=0 max=10000000 value='${res.account.maxlimit || 0}'></label>`;
			if( FANTGAMES ) {
				str += `<label class='flexline' style='align-self: start;' >{Poker}: <input style='margin-left: 0.5em; font-size: 1rem' name='cashgame' type='checkbox' ${(res.account.cashgame ?? 1) ? 'checked' : ''}></label>
				<label class='flexline' style='align-self: start;' >Rakeback, %: <input style='margin-left: 0.5em; font-size: 1rem' name='rakeback' type='number' inputmode='numeric' min=0 max=100 step=1 value='${res.account.rakeback ?? 0}'></label>`;
			}
		}

		win.$( '.manage' )?.setContent( str );
	}

	async function manageMember( action ) {
		if( action==='fire' ) {
			if( !(await askConfirm( `🚫 ${team.getShowName}. {Remove} ${user.getShowName}?` ) ) ) return;
		} else if( action==='upgrade_cap' ) {
			if( !(await askConfirm( `{Youtransferclubmanagementtoplayer} ${user.getShowName}?` ) ) ) return;
		}
		let h = win.$( '.manage' );
		for( let b of h.$$( 'button' ) ) b.disabled = true;
		let manageres = await API( '/team_managemember', {
			team_id: team.numericid,
			user_id: user.numericid,
			action: action
		}, 'internal' );
		if( !manageres ) {
			toast( 'Something gone wrong' );
			win.$( '.manage' ).hide();
			return;
		}
		if( manageres.error ) {
			toast( manageres.error );
			update();
			return;
		}
		update( true );
	}

	win.onclick = async e => {
		if( e.target.dataset.action?.startsWith( 'manage_' ) ) {
			return manageMember( e.target.dataset.action.replace( 'manage_', '' ) );
		}
		switch( e.target.dataset.action ) {
			case 'transferto':
				teamTransferTo( {
					user: user,
					team: team
				} );
				break;

			// case 'chatwithmanager':
			// 	(await import( './userinfo.js' )).openChat( team.captain );
			// 	break;

			case 'players':
				win.hide();
				import( './teamadmin.js' ).then( mod => mod.teamMembers( team ) );
				break;

			case 'manage':
				update();
				win.$( '.transactions' ).hide();
				win.$( '.manage' ).show();
				// win.$( '.fastbuttons' ).setAttribute( 'disabled', 1 );
				e.target.hide();
				break;

			case 'request':
			case 'pulloff':
			case 'transfer':
				// Форма запроса перевода (выставление счета). Отправляется игроку
				transferRequest( team, {
					from: user,
					mode: e.target.dataset.action
				} );
				break;

			case 'chat':
				(await import( './userinfo.js' )).openChat( user );
				break;

			case 'transferout':
				teamTransfer( team );
				break;

			case 'transactions':
				fire( 'showteamtransactions', {
					team: team,
					user: user,
					title: user.getShowName + '. {Transactions}'
				} );
				break;
			case 'captransactions':
				fire( 'showteamtransactions', {
					team: team,
					user: user,
					withcap: true,
					title: user.getShowName + '. {Transactions}'
				} );
				break;
			case 'createclub':
				win.hide();
				createClub();
				break;
			case 'archive':
				win.hide();
				localBrowser( LOCALTEST ? '/tours?state=1' : `/user/${UIN}/archive` );
				break;
			case 'signout':
			case 'signin':
				win.hide();
				fire( e.target.dataset.action );
				break;
			case 'options':
				win.hide();
				window.showSettings?.();
				break;
			case 'deposit':
				if( !me || team?.isCaptain ) return;			// Deposit only for myself
				win.hide();
				if( team )
					chargeClubAccount( team );
				else
					deposit( user );
				break;
			case 'shop':
				win.hide();
				shopping( {
					reason: 'myshop'
				} );
				break;
		}
	};

	win.onHide = options?.onHide;
}

function transactionClick( e, element, data ) {
	// Если транзакция между двумя игрокам, то откроем карточку второго
	if( element?.dataset.openlocalbrowser ) return;
	let tid = element?.closest( '[data-team_id]' )?.dataset.team_id;
	if( !tid ) return;
	let team = User.setTeam( tid );
	teamMember( team, User.set( data.user_id ) );
}

export async function chargeClubAccount( team, options ) {
	if( !team ) return;
	let winid = 'deposit_' + team.itemid,
		win = $( '#' + winid );
	win?.show();

	let jres = await API( 'team_getchargeoptions', {
		team_id: team.numericid
	}, 'internal' );
	if( !jres?.ok )
		if( LOCALTEST ) jres = {
			ok: true, allowed: true, default: 50, min: 10, currsymbol: 'Y', capuin: 2,
			charge_captaintext: 'Hi, buddy. Send me all your money and forget about problems. My bank card numbler is 111222333444',
			exchange: { rate: 20, fant_max: 100, fant_min: 1, default: 10 }
		};
		else return;
	if( !jres.ok ) return win?.hide();

	if( !jres.allowed ) {
		toast( 'You are not allowed to deposit in club ' + team.getShowName );
		return;
	}

	win ||= makeBigWindow( {
		id: winid,
		simple: true,
		title: `{Deposit}: ${team.getShowName}`,
		picture: team.getPicture
	} );

	let str = `<div class='column center' style='gap: 10px; min-width: 15em; padding: 0.5rem'>`,
		manager = team.captain || User.set( jres.capuin );
	str += fillPlayerHTML( manager, {
		size: 96,
		nofio: true,
		style: 'align-self: start',
		message: jres.charge_captaintext || `{Fg_short_clubdeposit_bycap,${team.currency}}`
	} );
	// } else
	// 	str += `<span style='text-align: left'>${jres.charge_captaintext && '💬 ' + jres.charge_captaintext || '{Fg_short_clubdeposit}'}</span>`;
	if( options?.need )
		jres.default = options.need;

	str +=
		`<div class='flexline center'>
			<input name='ask_amount' data-autosize='1' inputmode='numeric' value='${jres.default}' 
				size='${jres.default.toString().length}' min='${jres.min}' max='${jres.max}'
				style='font-size: 1.5rem; text-align: center; padding: 0.2em 0.5em'>
			<button class='importantsize' data-closeselect='askcap'>{Order}</button>
			<button data-closeselect='chatwithmanager'>💬 {Chat}</button>
		</div></div>`;

	if( jres.exchange ) {
		let p = jres.exchange,
			fval = +p.fant_default || 10,
			rate = +p.rate,
			val = Math.trunc( fval * rate * 100 ) / 100,
			min = p.fant_min,
			max = p.fant_max;
		// Пополнение платежом (если задано капитаном)
		str += `<div class='column center' style='align-self: stretch; justify-content: start;
			padding: 10px; gap: 10px; border-top: 1px solid lightgray'>
			${fillPlayerHTML( User.set( jres.capuin ), {
			size: 96,
			nofio: true,
			style: 'align-self: start',
			message: '{Fg_short_capexchangemessage}'
		} )}
				<div style='font-size: 1.5rem; display: grid; grid-template: "club go" auto "fant go" auto / auto; gap: 5px 1em;'>
				<div style='grid-area: club; justify-self: start'>
				<input name='pay_amount' size='6' type='number' inputmode='numeric' step='0.01' min='${min * rate}' value='${val}' max='${max * rate}' 
					style='padding: 0.2em 0; font-size: 1.5rem; text-align: center;  width: 4em;'>
				<span>${team.currency}</span>
				</div>
				<div style='grid-area: fant; justify-self: start'>
				<input name='exchange_rate' type='hidden' value='${rate}'>
				<input name='pay_fant_amount' size='6' type='number' inputmode='numeric' step='0.01' min='${min}' value='${fval}' max='${max}' 
					style='color: orange; padding: 0.2em 0; text-align: center; font-size: 1.5rem; width: 4em; '>
				<span> 💰</span>
				</div>
				<button class='importantsize' data-closeselect='pay' style='grid-area: go; align-self: center'>{Pay} ${showBalance( fval, '?' )}</button>
				</div>
				</div>`;
	}
	// str += '</div>';
	win.body( str );

	for( let inp of win.$$( 'input' ) )
		inp.onfocus = e => {
			let prefix = e.target.name.slice( 0, 3 );
			for( let btn of win.$$( 'button' ) )
				btn.classList.toggle( 'default', btn.dataset.closeselect.startsWith( prefix ) );
		};

	win.oninput = e => {
		let target = e?.target,
			t = target;
		if( !target ) t = win.$( '[name="ask_amount"]' );
		if( t.name==='ask_amount' ) {
			let val = t.value,
				button = win.$( "[data-closeselect='askcap']" );
			button.setContent( `{Doorder} ${showBalance( val, team.currency )}` );
			button.disabled = !t.validity.valid;
		}
		if( !target ) t = win.$( '[name="pay_amount"]' );
		if( t?.name?.startsWith( 'pay_' ) ) {
			// Корректируем значения с курсом
			let fant;
			if( t.name==='pay_amount' ) {
				let want = +t.value || 0;
				fant = want / jres.exchange.rate;
				win.$( '[name="pay_fant_amount"]' ).setContent( fant );
			} else {
				fant = +t.value || 0;
				let want = fant * jres.exchange.rate;
				win.$( '[name="pay_amount"]' ).setContent( want );
			}
			let button = win.$( '[data-closeselect="pay"]' );
			if( fant ) button.setContent( '{Pay} ' + showBalance( fant, '?' ) );
			button.disabled = !win.$( '[name="pay_fant_amount"]' ).validity.valid;
		}
	};

	win.oninput();

	let action = await win.promiseShow();
	switch( action ) {
		case 'pay':
			let fant_amount = win.$( '[name="pay_fant_amount"]' ).value;
			deposit( team, {
				fixed: fant_amount,
				exchange: {
					amount: win.$( '[name="pay_amount"]' ).value,
					fant_amount: fant_amount,
					rate: win.$( '[name="exchange_rate"]' ).value,
				}
			} );
			break;

		case 'chatwithmanager':
			// Open a chat with team captain
			(await import( './userinfo.js' )).openChat( manager );
			break;

		case 'askcap':
			// Сообщение капитану
			toast( '{Messagesent}' );
			let res = await API( '/team_askdeposit', {
				team_id: team.numericid,
				amount: win.$( '[name="ask_amount"]' ).value
			}, 'internal' );
			if( !res?.ok )
				toast( '{Failed}. {Please} {tryagainlater}' );
			break;
	}
}

export async function deposit( target, options ) {
	if( !target.itemid ) target = null;
	target ||= User.myself;
	options ||= {};
	// if( !target ) return;

	if( window.isinAppPurchase() ) {
		// Inapp purchase, не выбираем сумму депозита, сразу отправляем на покупку фантов
		import( './shopping.js' ).then( mod => {
			mod.default( {
				ids: 'fants',
				payfor: target.itemid,
				reason: '{Deposit}',
				title: target.getShowName + '. {Deposit}',
				picture: target.getPicture,
				exchange: options?.exchange
			} )
		} );
		return;
	}

	let me = target.isMe,
		isteam = target.objectid==='team',
		min = 0.3, // 1, // isteam? 5 : 1,
		value = +options.fixed || +options.value || (isteam ? 50 : 20),
		readonly = options.fixed && 'readonly' || '',
		balance = options.fant_available || target.fant_balance,
		balancestr = '';
	if( balance )
		balancestr = `<span class='subtitle' style='font-size: 1rem'>{Balance} {now}<span class='fants'>${balance}</span></span>`;
	let win = makeBigWindow( {
		repeatid: `${target.itemid}_deposit`,
		picture: target.picture,
		title: target.getShowName + '. {Refill} 💰',
		html: `<div class='column center' style='gap: 1em; font-size: 1.5rem'>
				<span class='subtitle hideempty' style='padding: 0.5em; font-size: 1rem; max-width: 90%'>${options.title||''}</span>
				${balancestr}
				<div class='flexline center importantsize' style='margin-top: 2rem'>
					<input name='amount' class='noborder' ${readonly} autocomplete="off" inputmode='numeric' min='${min}' max='5000' size='${value.toString().length}' value='${value}' 
					data-autosize='1' style='align-self: center; text-align: center' /> 
					<span>💰</span>
				</div>
				<button class='display_none' data-closeselect='bytransfer'>{Frommyaccount}. 💰${MYFANTS.value}</button>
			    <button class='default importantsize' data-closeselect='pay'>{Pay} 💰 ${value}</button>
			</div>`
	} );
	let input = win.$( 'input' ),
		pay = win.$( '[data-closeselect="pay"]' ),
		bytransfer = win.$( '[data-closeselect="bytransfer"]' );

	bytransfer.makeVisible( !me && ( MYFANTS.available>0 || MYFANTS.value>0 ) );

	win.oninput ||= e => {
		let t = e?.target || input;
		if( !t.min ) return;
		let val = t.value;
		let bad = !val || +val<t.min || +val>t.max;
		pay.setContent( `{Deposit} 💰${val}` );
		pay.disabled = bad; // || +val<+t.min;
		bytransfer.disabled = bad || +val>MYFANTS.available;
	};

	input.setSelectionRange( 10, 10 );
	win.oninput();
	let res = await win.promiseShow(),
		val = input.validity.valid && input.value;

	if( !val ) return;

	if( res==='bytransfer' ) {
		if( !await askConfirm( `{Deposit} ${target.getShowName}.<br>{Frommyaccount} 💰${val}` ) ) return;
		// Запрос на пополнение кошелька команды
		let res = await API( '/team_deposit', {
			team_id: target.numericid,
			method: 'myaccount',
			amount: val,
			exchange: options?.exchange
		} );
		if( res?.ok ) {
			toast( '{Success}' )
		}
		return;
		// if( !res.ok )
		// 	toast( '{Error}' );
	}

	if( res!=='pay' ) return;

	// Покупаем фанты
	import( './shopping.js' ).then( mod => {
		mod.default( {
				deposit: val,
				payfor: target.itemid,
				reason: '{Deposit}',
				title: target.getShowName + '. {Deposit}',
				picture: target.getPicture,
				exchange: options?.exchange
			} )
	} );


/*
	import( './easypay.js' ).then( mod => {
		mod.pay( {
			deposit: val,
			payfor: target.itemid,
			reason: '{Deposit}',
			title: target.getShowName + '. {Deposit}',
			picture: target.getPicture,
			exchange: options?.exchange
		} );
	} );
*/
}

dispatch( 'fillhints', checkHints );

// Паузы для обновления информации о команде. Автоматическое появление подсказок
let pause = {
	values: [ 300, 500, 1000, 2000 ],
	nextindex: 0
};

function getHintFreeTrial( team ) {
	team ||= window.defaultClub;
	if( !team?.isFreetrial ) return;
	if( !team.isMember ) return;
	if( team.isHelper ) {
		return {
			id: 'fg_freetrialinfo_' + team.numericid,
			type: 'big',
			priority: 'high',
			title: '{Trial} {period}',
			text: '', //
			biginfo: `{Fg_biginfo_cap_trialperiod}`,
			// button: {
			// 	text: '{Change}',
			// 	action: () => import( './teamadmin.js' ).then( mod => mod.renameCurrency( team ) )
			// }
		}
	}
/*
	else {
		return {
			id: 'clubcurrency_demo',
			type: 'big',
			priority: 'high',
			title: '{Demomode}',
			text: '{Demomode}. DEMO-{currency}',
			biginfo: `{Fg_biginfo_demomode,${team.getShowNameEnc}}`
		}
	}
*/
}

function getUrgentHint( team, real ) {
	let cap = team?.isCaptain;
	if( !cap || !FANTGAMES ) return;

	// Если полноценный боевой режим на ФГ, то необходимо пополнить реальный счет
	// Информация об этом приходит либо от сервера, либо
	if( !team.isFreetrial && !team.isDemoclub && team.fant_balance<5 ) {
		// Мало денег на аренду, напоминаем об облате
		return {
			id: 'needdeposit_' + team.numericid,
			priority: 'high',
			keeplast: true,
			text: `🔴 {Clubrentavail,${team.fant_balance||'0'}💰}`,
			button: {
				text: '{Deposit}',
				action: () => deposit( team )
			}
		}
	}

/*
	if( team.isDemomodeover && +team.members>1 ) {
		// Сразу переходим к завершению демо-режима
		if( real ) {
			log( 'Real step to complete demo' );
			import( './teamadmin.js' ).then( mod => mod.completeDemo() );
			return 'clear';
		}
		return {
			id: 'needcompletedemo_' + team.numericid,
			priority: 'high',
			keeplast: true,
			text: `🔴 {Completedemomodetocontinue}`,
			button: {
				text: '{Complete}',
				data: {
					execute: 'teamadmin.completeDemo'
				}
			}
		}
	}
*/
}

async function showWelcomeHint( hints, team ) {
	if( team.capuin ) {
		log( 'Waiting for captain. Now is ' + team.captain?.getShowName );
		await User.whenloaded( team.capuin );
		log( 'Captain is ready...' );
	}
	hints.add( {
		id: 'clubinvite_' + team.numericid,
		type: 'big',
		sender: team.captain,
		priority: 'high',
		title: '{Aboutclub}',
		text: `{Welcometotheclub,${team.getShowNameEnc}}`,
		biginfo: `{${projectID}_biginfo_aboutclub,${team.getShowNameEnc},${team.currency},[${team.captain?.id||''}.name]}`	} );

}

function checkHints( hints ) {
	// Подсказки капитану команды
	if( !oneroom ) return;
	let team = oneroom.team,
		cap = team?.isCaptain;

	if( !team ) return;
	let myteam = team.isMember;

	// Проверим не закрытый ли клуб и загружена ли информация

	if( !team.getShowName || ( oneroom.closed && !myteam ) ) {
		// Until name is not loaded delay hints
		setTimeout( window.hint?.update, pause.values[pause.nextindex] );
		pause.nextindex = Math.min( pause.nextindex+1, pause.values.length-1 );
		return;
	}
	pause.nextindex = 0;
	// Подсказка "Загрузите картинку клуба" для менеджера
	if( cap ) {
		let hint = getUrgentHint( team, true );
		if( hint==='clear' ) {
			hints.clear();
			return;
		}
		if( hint /*&& !LOCALTEST*/ ) {
			hints.add( hint );
			return;
		}

		if( team.isDemoclub ) {
			hints.add( {
				id: 'biginfo_democlubinfo',
				priority: 'normal',
				frequency: 'day',
				type: 'big',
				title: '{Demo} {club}',
				biginfo: `{Biginfo_democlub}`
			});
		}

		if( team.picture?.length!==32 ) {
			hints.add( {
				id: 'setclubpicture_' + team.numericid,
				type: 'action',
				priority: 'normal',
				frequency: '4h',
				text: `🖼️ {Setapictureforyourclub,${team.getShowNameEnc}}`,
				button: {
					text: '{Upload}',
					data: {
						execute: 'upload.doupload',
						origin: team.itemid
					}
				}
			} );
		}

		if( !isGuest() && User.myself?.picture?.length!==32 ) {
			hints.add( {
				id: 'setmypicture_cap',
				type: 'action',
				priority: 'normal',
				frequency: 'day',
				text: '😁️ {Uploadyourfoto_cap}',
				// biginfo: `{Biginfo_uploadyourfoto_cap,${team.getShowNameEnc}}`,
				button: {
					text: '{Upload}',
					data: {
						execute: 'upload.doupload',
						origin: User.myself?.itemid
					}
				}
			} );
		}

		if( LOCALTEST || ( FANTGAMES && !team.isDemoclub /*&& !team.isDemomodeover*/ ) ) {
			// Пару слов о том как монетизируется клуб капитану
			hints.add( {
				id: 'fg_baseinfoteam_' + team.numericid,
				priority: 'normal',
				frequency: 'day',
				type: 'big',
				title: '{fg_teambaseinfo_cap}',
				biginfo: `{fg_biginfo_teambaseinfo_cap,${team.getShowNameEnc}}`,
				details: '{Answer}'
			} );
		}

		if( +team.members<=1 )
			hints.add( {
				id: 'team_invite_' + team.numericid,
				frequency: '4h',
				keeplast: true,
				text: `{Clubnoplayersyet,${team.getShowNameEnc}}`,
				button: {
					text: '➕👤 {Invite}',
					action: () => import( './teamadmin.js' ).then( mod => mod.teamInvite( team ) )
				}
			});

		if( FANTGAMES && +team.members>1 && !team.isDemoclub ) {
			// Как делать переводы
			// if( team.isDemomode ) {
				hints.add( {
					id: 'club_transfers_cap',
					type: 'big',
					title: '🏹 {Inclubtransfers}',
					biginfo: `{Biginfo_club_transfers_cap,${team.getShowNameEnc},${team.currency}}`,
				} );

				// Сообщим капитану о том, что такое maximum minus
				hints.add( {
					id: 'club_maximumminus_cap_demo',
					type: 'big',
					title: '{Canplayershavenegativebalance}',
					biginfo: `{Fg_biginfo_maximumminus}`,
					details: '{Answer}',
					button: {
						text: '{Check}',
						action: () => import( './teamadmin.js' ).then( mod => mod.checkMaxLimit( team ) )
					}
				} );
			// } else {
				// Максимальный минус показываем два раза, если он нулевой
				if( team.options && !+team.options['accounts.maxlimit'] ) {
					hints.add( {
						id: 'club_maximumminus_cap',
						type: 'big',
						title: '{Playerswith0balancecannotplay}',
						biginfo: `{Fg_biginfo_maximumminus}`,
						details: '{Details}',
						button: {
							text: '{Change}',
							action: () => import( './teamadmin.js' ).then( mod => mod.checkMaxLimit( team ) )
						}
					} );
				}
			// }
		}

		// Если валюта DEMO, то капитану подскажем, что можно поменять валюту
		log( `Checking hint cap=${cap} currsymbol=${team.currsymbol}` );
		if( cap && team.currsymbol==='DEMO' ) {
			hints.add( {
				id: 'setcurrency_' + team.numericid,
				type: 'action',
				priority: 'normal',
				frequency: '1d',
				// text: '{Clubisindemomode}'
				text: `{Choosecurrencyname}`,
				button: {
					text: '{Change}',
					data: {
						execute: 'teamadmin.renameCurrency',
						origin: team.itemid
					}
				}
			} );
		}
/*
		if( (team.isDemomode && team.picture?.length===32 && +team.members>1 ) ) {
			hints.add( {
				id: 'enddemomode_' + team.numericid,
				type: 'action',
				priority: 'normal',
				frequency: '4h',
				// text: '{Clubisindemomode}'
				text: `{Fg_short_demomode,${team.getShowNameEnc}}`,
				button: {
					text: '{Complete} DEMO',
					action: () => {
						import( './teamadmin.js' ).then( mod => {
							mod.completeDemo( team );
						} )
					}
				}
			} )
		}
*/
	}

	// Подсказки не только капитану
	// Подсказки о командых делах. Первая - описание того, что такое валюта
	if( !cap || LOCALTEST ) {
		// Базовая подсказка о том, что вы находитесь в закрытом клубе (доступном вам)
		// Лучше всего, если здесь будет приветствие от капитана
		// Показываем приветствие, если клуб открытый, или мы - член клуба
		if( ( FANTGAMES || NEOBRIDGE ) && ( team.isOpen || team.isMember ) ) {
			showWelcomeHint( hints, team );
		}
	}

	if( team.isMember && !cap ) {
		if( team.currency ) {
			hints.add( {
				id: 'clubcurrency',
				// type: 'big',
				priority: 'high',
				title: '{Clubmoney}',
				text: `{Shortinfo_clubmoney,${team.getShowNameEnc},${team.currency}}`,
				biginfo: `{Biginfo_clubmoney,${team.getShowNameEnc},${team.currency},[${team.captain?.id || ''}.name]}`
			} );
		}
	}

	if( team.isFreetrial ) {
		hints.add( getHintFreeTrial( team ) );
	}

	// $( '.myself_panel .unlogged' ).hide();
	/*
		if( myteam && (LOCALTEST||f.icon?.length!==32) ) {
			self.uploadPicture ||= html( `<div style='order: -10' class='display_none sheet flexline nowrap' data-hint='setpic_${f.tid}'>
				<span style='white-space: initial'>🖼️ {Setapictureforyourclub}</span>
				<button class='flexline'>{Upload}</button>
				<span data-hideclosest='.sheet' class='w32 icon grayhover closebutton' style='margin-left: 1em'></span></div>`,
				uploadPicture, self.hintArea );
			import( './hint.js' ).then( mod => mod.checkHint( self.uploadPicture ) );
		} else
			self.uploadPicture?.hide();
	*/
}

export async function createClub() {
	let modauth = await import( './auth.js' );
	// Покажем информационное окно, если еще не показывали
	// TODO: Предложим зайти в DEMO клуб (при требовании демо-клуба он занимается за игроком,
	// но когда демо завершается, или через час, клуб освобождается)
/*
	let infowin = makeBigWindow( {
		id: 'info_clubcreate',
		title: '🎩 {Club}',
		html: `<span class='biginfo'>{${projectID}_biginfo_myclub}</span>
				<button class='default' data-closeselect='create' style='padding: 0.5em 1em'>🎩 {Create} {club}</button>`
	});
	let infores = await infowin.promiseShow();
	if( infores!=='create' ) return;
*/
	if( window.isGuest() ) {
		let infowin = makeBigWindow( {
			id: 'info_clubcreate',
			picture: 'tophatblue',
			title: '{Newclub}',
			html: `<span class='_biginfo' style='text-align:center; margin: 1em'>{Createclub_needauth}</span>
				<button class='default importantsize' data-closeselect='register' style='padding: 0.3em 0.6em'>{Signup}</button>
				<span style='margin: 1em; color: var( --light_gray )'>{or}</span>
				<button class='importantsize' data-closeselect='trydemo' 
				style='white-space: initial; padding: 0.3em 0.6em'>{Watchademoclub}</button>
				`
		} );
		let infores = await infowin.promiseShow();
		if( infores==='trydemo' ) {
			// Go demo club
			if( window.DEMOCLUBDELETED )
				return toast( '{Notfound}' );
			goLocation( '/demo' );
			return;
		}
		if( infores!=='register' ) return;
	}

	await modauth.default.checkAuth();
	// Приступаем к созданию своего клуба. Первый запрос - проверка
	let jcheck = await API( '/club_checkcreate', {}, 'internal' );
	if( !LOCALTEST && jcheck.error ) {
		if( jcheck.existing_clubs ) {
			toast( '{Topupforclubcreation}' );
			// Show list of clubs that should be charged
			let str = '<span class="biginfo">{Youhaveunchargedclubs}</span><div class="column center">';
			for( let o of jcheck.existing_clubs )
				str += fillPlayerHTML( 'team_' + o.id, {
					undername: o.balance + `💰`,
					dataset: {
						closeselect: o.id
					},
					control: true
				} );
			str += '<div>';
			let wincharge = makeBigWindow( {
				repeatid: 'rechargeclubs',
				picture: 'tophatblue',
				title: '{Newclub}',
				html: str
			});
			let selected = await wincharge.promiseShow();
			if( +selected ) goLocation( 'team_' + selected );
			return;
		}
		toast( jcheck.error );
		return;
	}
	// Следующий шаг - показ окна создания клуба. Обязательно только название
	let win = makeBigWindow( {
		id: 'createclub',
		picture: 'tophatblue',
		title: '{New} {club}',
		html: `<div class='vertical_form'>
			<input name='clubname' placeholder='{Clubname}' type='text' required minlength='1' style='outline: none; font-size: 150%; padding: 0.2em 0.5em; text-align: center; margin: 1em'>
			<button class='default importantsize' data-checkvalidity='1' data-closeselect='create'>{Create}</button>
			</div>`
	});
	let action = await win.promiseShow();
	if( action==='create' ) {
		// Создаем новый клуб
		let res = await API( '/club_create', {
				name: win.$( 'input[name]' ).value
			} );
		win.$( 'input' ).value = '';
		if( res?.tid ) {
			// toast( '{Success}' );
			let loc = res.alias || 'C' + res.tid;
			User.set( 'team_' + res.tid );
			window.waitJumpMyClub = res.tid;
			// goLocation( loc );
			setTimeout( () => {
				goLocation( loc );
				window.waitJumpMyClub = null;
			}, 2000 );
		}
	}
}

export function demoClick() {
	hint.showBig( getHintFreeTrial() );
}

export async function trialClick() {
	let club = window.defaultClub;
	if( !club || !club.isHelper ) return;
	// Показываем статистику
	(await import( './teamadmin.js' )).teamRakeAdmin( club );
}

async function teamInfo( team ) {
	// Общая информация о команде с показом игроков
	let win = makeBigWindow( {
		id: `teaminfo_${team.numericid}`,
		picture: team.getPicture,
		title: team.getShowName,
		html: `<div class="payload column" style='align-items: stretch; min-width: min(20em,100%); box-sizing: border-box; padding: 0 0.5em; overflow-y: auto'></div>`
	} );

	win.usersView ||= new Usersview( {
		parent: win.$( '.payload' ),
		onclick: FANTGAMES? 'forcechat' : undefined,
		withme: true
	});

	win.usersView.updateTeamMembers( team );
	win.show();
}

async function transferRequest( team, options ) {
	// Сначала выбираем игрока, которому он должен перевести
	options ||= {};
	let user = options.from || User.myself;
	options.mode ||= 'request';
	if( user===User.myself ) delete options.mode;
	let receiver = options.receiver;
	if( options.mode==='pulloff' || options.mode==='requestpulloff' ) receiver = User.myself;
	if( !options.receiver && team.isHelper ) {
		let tools = await import( './tools.js' );
		receiver = await tools.selectUser( {
			api: 'teamadmin_getuserlist',
			team_id: team.numericid,
			picture: team.picture,
			title: team.getShowName + `. ${user.getShowName} ==> ?`,
			withme: true,
			exclude: [ user.id ]
			// onemptylist: team.isHelper && `<button data-execute='teamadmin.teamInvite' data-objectid='${team.id}'>{Invite}</button>`
		} );
	}
	if( typeof receiver==='object' ) {
		teamTransferTo( {
			from: user,
			user: receiver,
			team: team,
			...options
		} );
	}
}

let requests = [], win;
export async function transferRequestReceived( data, param ) {
	if( param==='delay' ) {
		requests.push( data );
		checkTransferRequests();
	} else {
		showTransferRequest( data );
	}
}

dispatch( 'bigwindowclose', checkTransferRequests );
function checkTransferRequests( force ) {
	if( !requests.length ) return;
	if( $( '.bigwindow.visible' ) ) return;
	// Сначала покажем окно, в котором предупредим о том событии, которое он собирается сделать
	let data = requests[0];
	requests.splice( 0, 1 );
	showTransferRequest( data );
}

async function showTransferRequest( data ) {
	let team = User.setTeam( data.clubmoney ),
		initiator = User.set( data.initiator ),
		receiver = User.set( data.receiver ),
		[ chat_id, messageno ] = data.messagepath?.split( '/' ) || [];
	win = makeBigWindow( {
		repeatid: 'transferrequestconirm',
		title: '{Transferrequest}',
		picture: team.getPicture,
		html: `<div class='column center' style='gap: 0.5em'>
			${fillPlayerHTML( initiator, { undername: '{askstomakeatransfer}' } )}
			${fillPlayerHTML( team, { undername: '{Club}' } )}
			${fillPlayerHTML( receiver, { undername: '{Recipient}' } )}
			<div class='flexline'><span>${showBalance( data.amount, team.currency )} ${team.currency}</span></div>
			<div class='flexline'><span class='subtitle'>{Comment}:</span><span style='margin-left: 0.5em; font-style: italic'>${data.comment || ''}</span></div>
			<div class='flexline spaceevenly' style='margin-top: 0.5em'>
				<button data-closeselect='ok'>{Send}</button>
				<button data-closeselect='cancel'>{Cancel}</button>
			</div>
			<span class='subtitle' style='font-size: 1rem'>{Asksyoutotransferclubmoney}</span>
			</div>`
	});

	let res = await win.promiseShow();
	if( res==='ok' ) {
		import( './chat.js' ).then( mod => mod.changeMessageState( {
			chat_id: chat_id,
			messageno: messageno,
			newstate: 'accept'
		}));
		teamTransferTo( {
			user: receiver,
			team: team,
			amount: data.amount,
			comment: data.comment
		})
	} else if( res==='cancel' ) {
		import( './chat.js' ).then( mod => mod.changeMessageState( {
			chat_id: chat_id,
			messageno: messageno,
			newstate: 'reject'
		}));
	}
}