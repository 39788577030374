/***
 * Pool. Any kind of scoring.
 */

export default class Pool {
	constructor( game ) {
		this.game = game;
		if( this.game.isbridge ) return;
		this.holder = construct( '.fade.pool_box' );
		// this.holder.onmouseenter = this.showDiffs.bind( this );
		this.icon = construct( '.display_none.grayhover.invertdark.control.pool_icon.badge.infobadge', '@{Pool}' );
		this.allRecords = new Set;
		this.lastStr = '';
		this.pool = [];
		this.total = null;
		game.installLittle( 'pool', this, true );
	}

	show() {
		this.holder.show();
	}

	// End of constructor
	parse( str ) {
		// Format: type;PLR0;PLR1;...[;<total>]
		// PLR is the records, separated by ","
		// if PLR starts with '!' then this is total information
		if( this.game.isbridge ) return;
		if( !str ) {
			this.game.littleHideIfShowed( 'pool' );
			this.icon.hide();
			this.lastStr = null;
			return;
		}
		if( this.lastStr===str ) return;		// Same pool
		this.icon.show();
		str = str.replace( ':', ';' );
		let ar = str.split( ';' );
		if( this.type!==ar[0] ) {
			this.type = ar[0];
			this.holder.dataset['type'] = this.type;
			this.icon.dataset['type'] = this.type;
			for( let h of this.allRecords )
				h.holder.textContent = '';
		}

		let plrs = ar.length - 2 || this.game.maxPlayers;
		// this.holder.setAttribute( 'players', plrs );
		let maxdealno = 0;
		for( let p = 0; p<plrs; p++ ) {
			let pos = p;
			if( p===1 && plrs===2 && this.game.maxPlayers===4 ) pos = 2;
			if( p<this.game.maxPlayers ) {
				let par = ar[p + 1].split( ',' );
				if( !this.pool[p] ) {
					let h = construct( '.pool_plr', this.holder );
					this.game.requireLayout( pos, h );
					this.pool[p] = {
						holder: h,
						r: [],
						str: [],
						records: [],
					};
				}
				let pp = this.pool[p];
				for( let rec = 0; rec<par.length; rec++ ) {
					if( !pp.r[rec] ) {
						let o = construct( '.pool_record', pp.holder );
						pp.r[rec] = {
							type: rec,
							holder: o,
							str: null,
							dealno: -1
						};
						this.allRecords.add( pp.r[rec] );
					}
					let ppr = pp.r[rec];
					if( ppr.str===par[rec] ) continue;	// The same record
					ppr.dealno = -1;
					ppr.str = par[rec];
					let source = par[rec]; // ||  '' + p + ' ' + rec;
					let all = source.split( ' ' );
					let str = '';
					let lastNum = 0, num = 0;
					for( let s of all ) {
						if( !s ) continue;
						let sar = s.split( "'" );
						let dealno = +sar[1];
						if( dealno>=0 ) {
							if( dealno>maxdealno ) maxdealno = dealno;
							if( dealno>ppr.dealno ) {
								ppr.dealno = dealno;
								lastNum = num;
							}
						}
						let score = sar[0],
							classes = '',
							dot = '',
							re = /(.*)\((.*)\)/i.exec( score ),
							adddata = '';
						if( re ) {
							score = re[1];
							classes += ' score';
							adddata = ' data-change="' + re[2] + '"';
						}
						if( str ) str += ' ';
						if( score.slice(-1)==='║' ) {
							score = score.slice( 0, -1 );
							classes += ' skakseparator'
						} else {
							dot = rec ? '.' : '';
						}
						if( classes ) classes = ` class='${classes}'`;
						str += `<span${classes}${adddata}>${score}${dot}</span>`;
						num = +sar[0];
					}
					ppr.holder.innerHTML = str;
					ppr.lastDiff = num - lastNum;
				}
			} else {
			}
			// Total pool (center)
			if( !this.total ) this.total = construct( '.pool_total', this.holder );
			this.total.textContent = ar[ar.length - 1];
		}
		// Mark last records
		if( maxdealno )
			for( let rec of this.allRecords ) {
				if( rec.dealno===maxdealno ) {
					let sign = 0, attr = '';
					if( rec.type===1 ) sign = 1;
					if( rec.type===2 ) sign = -1;
					if( sign>0 ) attr = rec.lastDiff>0 ? 'good' : 'bad';
					if( sign<0 ) attr = rec.lastDiff>0 ? 'bad' : 'good';
					rec.holder.setAttribute( 'marked', attr );
				} else
					rec.holder.removeAttribute( 'marked' );
			}

		// if( str.length && lastStr ) {
		// 	game.littleShowWide( 'pool' );
		// }
	};

	onShow() {
		this.showDiffs();
	}

	showDiffs() {
		// При показе вкладки всегда сначла покажем исчезающие последние изменения
		if( this.hideDiffs ) return;
		let all = this.holder.querySelectorAll( '.score[data-change]' );
		if( all.length ) {
			for( let el of all ) el.classList.add( 'fadechange' );

			this.hideDiffs = setTimeout( () => {
				for( let el of all ) el.classList.remove( 'fadechange' );
				this.hideDiffs = null;
				log( 'score: ready for diffs');
			}, 3000 );
		}
	}
}
