import Cards from './cards.js';

const BASESIZE = 48;

export default class Domino extends Cards {
	#tails = [];
	#tailMoves = [];
	#freeBones = new Set;
	#renderBind = this.#render.bind( this );
	#resizePanelBind = this.#resizePanel.bind( this );
	#transitionEndBind = this.#transitionEnd.bind( this );
	#resizePanelTimer;
	#prevInset;
	#waitRender; #waitAnimation;

	constructor( game, options ) {
		super( game, options );
		this.tailHolder = html( `<div class='dominopanel' style='position: absolute; left: 0; right: 0; top: 0; bottom: 0; pointer-events: none'></div>`, this.panel );
		game.addRoute( {
			dominotails: this.#dominotails.bind( this ),
			dominomove: this.#dominomove.bind( this ),
			event: this.#event.bind( this ),
			move: this.#moveparser.bind( this )
		} );
		if( DEBUG ) {
			window.domino = this.#dominotails.bind( this );
			window.dominomove = this.#dominomove.bind( this );
		}
		this.panel.resizeObserve = this.resizeObserve.bind( this );
		window.cardResizeObserver?.observe( this.panel );
		game.playArea.addEventListener( 'cardholder', this.#resizePanelBind );
	}

	#freeTail( tail, idx ) {
		if( tail.length<=idx ) return false;
		for( let i = idx; i<tail.length; i++ ) {
			let bone = tail[i].holder;
			if( bone ) {
				bone.hide();
				this.#freeBones.add( bone );
			}
		}
		tail.splice( idx );
		return true;
	}

	#getBone( value ) {
		for( let b of this.#freeBones ) {
			if( b.dataset.bone===value ) {
				this.#freeBones.delete( b );
				return b;
			}
		}
		let holder = html( `<div class='dominobone display_none' data-bone='${value}'>
			<div class='solid_card flexline center' data-domtile='${value[0]||''}' )'>${dominoContent( value[0] )}</div><div class='solid_card flexline center' data-domtile='${value[1]||''}'>${dominoContent(value[1])}</div></div>`, this.tailHolder );
		holder.addEventListener( 'transitionend', this.#transitionEndBind );
		return holder;
	}

	#checkTail( no ) {
		while( !this.#tails[no] )
			this.#tails.push( [] );
	}

	#dominotails( o ) {
		let tt = o.split( '.' ),
			modified;
		for( let tailno = 0; tailno<tt.length; tailno++ ) {
			this.#checkTail( tailno );
			let t = tt[tailno],
				tail = this.#tails[tailno];
			// Format of string is '235' meaning '23-35'. We need to duplicate all middle bones
			t = t.split( '' ).map( x => x + x ).join( '' ).slice( 1, -1 );
			if( t.length%2 ) {
				log( `Odd size of tail` );
				if( LOCALTEST ) debugger;
				t.splice( -1 );
			}
			for( let j = 0; j<t.length/2; j ++ ) {
				let bone = tail[j],
					values = t.slice( j*2, j*2+2 );
				if( bone ) {
					if( bone.values===values ) continue;
					// Diff! Drop old tail
					this.#freeTail( tail, j );
				}
				this.#addTailBone( tailno, values );
				modified = true;
			}
			if( this.#freeTail( tail, t.length/2 ) )
				modified = true;
		}
		for( let tailno = tt.length; tailno<this.#tails.length; tailno++ ) {
			this.#freeTail( this.#tails[tailno], 0 );
			modified = true;
		}
		this.#tails.splice( tt.length );
		// Check
		if( modified )
			this.#render();
	}

	#event( e ) {
		if( e==='newdeal' ) {
			this.#dominotails( '.'.repeat( this.#tails.length - 1 ) || '.' );
			this.#clearTailMoves();
		}
	}

	async #dominomove( o ) {
		if( !o ) return;
		let all = o.moves || [o];
		all.forEach( move => {
			this.#checkTail( move.tail );
			let tail = this.#tails[move.tail];
			if( move.length && move.length===tail.length*2 ) {
				// TODO: check this bone in the tail!
				if( tail[tail.length-1].value!==move.bone ) {
					// Failed size. Ask for check position
					this.game.sendMove( 'checkposition' );
					return;
				} else {
					console.log( 'Bone already is in tail' );
					return;
				}
			}
			let value = move.bone,
				rvalue = value.split( '' ).reverse().join( '' ),
				linkto = tail[tail.length-1]?.value[1] ?? this.#tails[0][0]?.value[0];
			if( linkto && linkto!==value[0] )
				value = rvalue;
			let bone = this.#addTailBone( move.tail, value ),
				cardholder = this.game.cardHolder[o.plno],
				plr = this.game.players[o.plno],
				card = cardholder?.find( move.bone ) || cardholder?.find( rvalue );
			// Try animation. 1. move bone to hand without animation. Then drop it
			// If we can see this card, fly from exact place
			setStartPosition( bone.holder, card || plr?.getCC || cardholder?.holder,
				this.game.cards.unifiedWidth, this.game.cards.unifiedHeight, o.plno===this.game.myPlace );
			bone.holder.show();
			cardholder?.remove( card );
			card?.hide();
		} );
		// After domino move drop all moves
		this.stopCardMove();
		this.#clearTailMoves();

		await new Promise( requestAnimationFrame );
		// if( LOCALTEST ) await sleep( 1500 );
		this.#waitAnimation = true;
		this.#render();
	}

	#transitionEnd( e ) {
		// Now we're free to make new render if needed
		this.#waitAnimation = false;
		this.#checkShowMoves();
	}

	#addTailBone( tailno, value ) {
		this.#checkTail( tailno );
		let bone = {
			value: value,
			holder: this.#getBone( value )
		};
		bone.holder.dataset.tailno = tailno;
		this.#tails[tailno].push( bone );
		return bone;
	}

	#resizePanel() {
		// Set size for table area according to cardholders
		this.#resizePanelTimer = null;
		let game = this.game,
			players = game.players;
		if( !players[0]?.position ) {
			// Players are not ready
			this.#resizePanelTimer ||= setTimeout( this.#resizePanelBind, 500 );
			return;
		}

		let prect = this.panel.getBoundingClientRect(),
			tailrect = this.tailHolder.getBoundingClientRect(),
			rect = {
				left: prect.left, right: prect.right,
				top: prect.top, bottom: prect.bottom
			};
		for( let p = 0; p<game.cardHolder.length; p++ ) {
			let ch = game.cardHolder[p],
				plr = players[p],
				r = ch.holder.getBoundingClientRect();
			if( !plr ) continue;
			if( plr.position==='right' && rect.right>=r.left )
				rect.right = r.left - 1;
			else if( plr.position==='left' && rect.left<=r.right )
				rect.left = r.right + 1;
			else if( plr.position==='top' && rect.top<r.bottom )
				rect.top = r.bottom + 1;
			else if( plr.position==='bottom' && rect.bottom>=r.top )
				rect.bottom = r.top - 1;
		}
		// if( rect.left===tailrect.left && rect.right===tailrect.right && rect.top===tailrect.top && rect.bottom===tailrect.bottom ) return;
		let inset = `${rect.top - prect.top}px ${prect.right - rect.right}px ${prect.bottom - rect.bottom}px ${rect.left - prect.left}px`;
		if( inset===this.#prevInset ) return;
		this.#prevInset = inset;
		log( `domino resize: ${JSON.stringify( players.map( x => x.position ))}, ${JSON.stringify( game.cardHolder.map( x => x.holder.getBoundingClientRect() ) )}`);
		let s = this.tailHolder.style;
		this.tailHolder.style.inset = inset;
		delay( this.#renderBind );
		this.#resizePanelTimer ||= setTimeout( this.#resizePanelBind, 1000 );
	}

	resizeObserve( entry ) {
		let rect = entry.contentRect;
		if( !rect.width && !rect.height ) return;
		this.#resizePanel();
		this.#render();
	}

	#renderBone( bone ) {

	}

	#render() {
		// Render domino tails on the panel.
		// 1. detect size of each tile. According to maximum length of chain
		// TODO: попробовать поставить дубли вертикально
		// TODO: в случае двусторонней колбасы балансировать её в случае большого перекоса. Не играет
		//       роли какая фишка в центре. Если длина отличается на 6+ фишек, балансируем
		// TODO: кнопка убирания ников и лишней информации
		undelay( this.#renderBind );
		let maxlength = this.#tails.reduce( ( acc, val ) => Math.max( acc, val.length ), 0 ),
			length = this.#tails.reduce( ( acc, val ) => acc + val.length, 0 ),
			width = this.tailHolder.clientWidth,
			height = this.tailHolder.clientHeight,
			minwh = Math.min( width, height ),
			cx = width / 2,
			cy = height / 2,
			w = BASESIZE,
			scale = 0;

		if( !width || !height ) return;

		length *= 2;
		maxlength *= 2;

		// Check first double. If so, move right w/2
		let tail0 = this.#tails[0],
			first = tail0?.[0]?.value,
			isFirstDouble = first && first[0]===first[1];

		function put( bone, x, y, dir, show ) {
			bone.position = [ x, y ];
			let hor = 'lr'.includes( dir );
			bone.rect = hor? { x: x, y: y, width: w*2, height: w }
				: { x: x, y: y, width: w, height: w*2 };
			let trf = `translate( ${x}px,${y}px )`;
			if( scale ) trf += ` scale(${scale})`;
			bone.holder.style.transform = trf;
			bone.holder.style.transition = null;
			bone.holder.style.flexFlow = { r: 'row', l: 'row-reverse', d: 'column', u: 'column-reverse' }[dir];
			bone.holder.dataset.orientation = dir;
			if( show ) bone.holder.show();
		}

		// Check size of bones. Should fit 10 at least one dim
		// And fit 5 for all sides
		let maxsize = Math.min( minwh / 8, this.game.cards.unifiedHeight/2 ),
			minfit = 10;
		if( length>15 ) minfit = 12;
		if( length>25 ) minfit = 15;
		if( maxlength>=20 ) minfit = Math.max( minfit, 20 );
		minfit *= 1.2;
		if( this.#tails.length>2 ) minfit *= 1.5;
		/*
				if( w*minfit>minwh ) {
					w = Math.max( width, height )/(minfit+1);
				}
		*/
		let totalfit = minfit * 1.5;
		if( w * totalfit>width + height )
			w = (width + height) / totalfit;
		if( w>maxsize ) w = maxsize;
		let split = w / 20; // 0.5,
		if( w!==BASESIZE )
			scale = w / BASESIZE;
		if( isFirstDouble )
			cx += w/2;
		log( `Domino fit ${w}: ${width}x${height} uni2=${this.game.cards.unifiedHeight/2} @maxsize=${maxsize} length=${length} maxlength=${maxlength} totalfit=${totalfit}` );

		let lim = this.#tails.length>2 && (width / 2) || undefined,
			tailDir = {
				0: {
					x: cx - w,
					y: cy - w / 2,
					d: 'r',
					top: cy + w * 1.2,
					left: lim + w * 0.6
				},
				1: {
					x: cx - w * 3.05,
					y: cy - w / 2,
					d: 'l',
					bottom: cy - w / 2 - w * 0.1,
					right: lim - w * 0.6
				},
				2: {
					x: cx - w / 2,
					y: cy - w * 1.55,
					d: 'u',
					left: lim,
					bottom: cy - w / 2 - w * 0.1
				},
				3: {
					x: cx - w / 2,
					y: cy + w * 0.55,
					d: 'd',
					right: lim,
					top: cy + w * 1.2,
				},
			};

		for( let tailno = 0; tailno<this.#tails.length; tailno++ ) {
			let dir = tailDir[tailno],
				x = dir.x,
				y = dir.y,
				d = dir.d,
				prevdir = d;

			// Going up - link to start bone
			if( tailno>1 ) {
				let startrect = this.#tails[0][0]?.rect;
				if( startrect )
					[ x, y ] = [ startrect.x + startrect.width - w,
						startrect.y + ( tailno===2? -2*w-split : startrect.height+split )]
			}
			// Add fixion holder for showing move (only for player)
			let runfor = this.#tails[tailno];
			if( this.game.isPlayer )
				runfor = [ ...this.#tails[tailno], this.#getTailMoves( tailno ) ];
			for( let j = 0; j<runfor.length; j++ ) {
				let t = runfor[j];

				// Every start element has block element - container for two bones
				// Try to set vertical double
				if( !t ) continue;
				t.position = null;
				let isDouble = t.value && t.value[0]===t.value[1] && prevdir===d;
				if( isDouble ) {
					if( 'rl'.includes( d ) ) {
						// Found vertical double
						if( d==='l' ) x += w;
						put( t, x, y - w / 2, 'd',true );
						// t.rect = { x: x, y: y, width: w, height: w*2 };
					}
				}
				let oddDouble = !!t.position;
				if( !t.position )
					put( t, x, y, d, !t.ismove );

				// Keep direction
				prevdir = d;

				// Goto next tail
				let rect = t.rect;
				if( d==='r' ) {
					if( rect.x + rect.width + w*2 + split>=(dir.right || width) ) {
						d = 'd';
						if( !oddDouble && rect.x + rect.width + w + split<(dir.right || width) )
							d = 'rd'
					}
				}
				else if( d==='l' ) {
					if( rect.x - w*2 - split<(dir.left || 0) ) {
						d = 'u';
						if( !oddDouble && rect.x - w - split>(dir.right || 0) )
							d = 'lu'
					}
				}
				else if( d==='d' ) {
					if( rect.y + rect.height + w * 2 + split>=(dir.bottom || height) ) {
						d = 'l';
						if( !oddDouble && rect.y + rect.height + w + split<(dir.bottom || height) )
							d = 'dl'
					}
				}
				else if( d==='u' ) {
					if( rect.y - w*2 - split<=(dir.top || 0) ) {
						d = 'r';
						if( !oddDouble && rect.y - w - split > (dir.top || 0) )
							d = 'ur';
					}
				}
				// Moving to next
				let samedir = prevdir===d;

				if( d==='r' )
					x = rect.x + rect.width + split;
				if( d==='d' )
					[ x, y ] = [ rect.x + (rect.width-w), rect.y + rect.height + split ];
				if( d==='rd' )
					[ x, y ] = [ rect.x + rect.width + split, rect.y ];
				if( d==='l' )
					[ x, y ] = [ rect.x - w*2 - split, samedir? y :
						rect.y + rect.height - w ];
				if( d==='dl' )
					[ x, y ] = [ rect.x - w, rect.y + rect.height + split ];
					// x = rect.x - w*2 - split;
				if( d==='u' )
					[ x, y ] = [ rect.x, rect.y - w*2 - split ];
				if( d==='ur' )
					[ x, y ] = [ rect.x, rect.y - w - split ];
					// y -= w*2 + split;
				if( d==='lu' )
					[ x, y ] = [ rect.x - w - split, rect.y - w ];

				// Correct direction
				// AppleWebKit/605.1.15 does not support Array.prototype.at()
				d = d[d.length-1];
			}
		}
	}

	#getTailMoves( tailno ) {
		this.#tailMoves ||= [];
		let tail = this.#tailMoves[tailno];
		if( !tail ) {
			let b = {
				value: '', ismove: true, holder: this.#getBone( '' )
			}
			b.holder.dataset.tailno = tailno;
			b.holder.classList.add( 'tailmove' );
			this.#tailMoves[tailno] = b;
		}
		return this.#tailMoves[tailno];
	}

/*
	findMoveHolders( code ) {
		if( !code ) return;
		let ar = code.split( '+' );
		let bones = ar.map( x => this.#getTailMoves( +x )[0] ),
			res = bones.filter( x => !!x ).map( x => x.holder );
		res.forEach( x => x.show() );
		if( res.length ) return res;
	}
*/

	#checkShowMoves() {
		if( this.#waitAnimation ) return;
		for( let bone of this.#tailMoves ) {
			if( !bone.needShow ) continue;
			bone.holder?.show();
			bone.needShow = false;
		}
	}

	findHolder( code, reason ) {
		if( +code>=0 ) {
			// Tail-end holder. Create it if necessary
			let bone = this.#getTailMoves( +code );
			if( reason==='move' ) {
				// bone.holder.classList.
				this.#setBoneValue( bone, '' );
				bone.needShow = true;
				this.#checkShowMoves();
			}
			return bone;
		}
	}

	onDrop( element, holder ) {
		this.setCardMoveIndex( this.cardMoveIndex + 1 );
		let owner = element.owner,
			plno = owner?.plno;
		if( plno>=0 ) this.game.players[plno].showArrow( false );
		this.#clearTailMoves();
/*
		owner?.remove( element );
		element.hide();
*/
		// Instance move
		let tailno = +holder.dataset.tailno;
		this.#dominomove( {
			plno: plno,
			tail: tailno,
			bone: element.str
		} );

		// if( !LOCALTEST )
			this.game.sendMove( `${element.str} ${tailno}` );
	}

	#clearTailMoves() {
		for( let bone of this.#tailMoves ) {
			if( !bone ) continue;
			bone.holder?.hide();
			bone.holder.classList.remove( 'onlygoal' );
			bone.needShow = false;
			this.#setBoneValue( bone, '' );
			// bone.holder.textContent = '';
		}
	}

	#setBoneValue( bone, value ) {
		bone.value = value;
		bone.holder.firstElementChild.textContent = dominoContent( value[0] );
		bone.holder.firstElementChild.style.color = `var( --domclr_${value[0]} )`;
		bone.holder.lastElementChild.textContent = dominoContent( value[1] );
		bone.holder.lastElementChild.style.color = `var( --domclr_${value[1]} )`;
	}

	#moveparser( o ) {
		if( !o ) {
			this.#clearTailMoves();
		}
	}

	dragInitMove() {
		this.#clearTailMoves();
	}

	dragSetOnlyGoal( target, card ) {
		// Mark only goal like placeholder
		let tailno = +target.dataset.tailno,
			str = card.dataset.str,
			idx = 0,
			tail = this.#tails[tailno],
			lastvalue = tail?.[tail.length-1]?.value[1] || this.#tails[0][0]?.value[0],
			bone = this.#getTailMoves( tailno );
		if( lastvalue && str[0]!==lastvalue ) str = str.split( '' ).reverse().join( '' );
		this.#setBoneValue( bone, str );
		// target.textContent = str[idx];
		// Continue this bone to show exact
		// TODO: #render this one bone
		this.#renderBone( bone );
	}
}

function setStartPosition( card, fromholder, cw, ch, debug ) {
	if( !card || !fromholder ) return;
	let fromrect = fromholder.getBoundingClientRect(),
		cardrect = card.getBoundingClientRect(),
		parentrect = card.parentElement.getBoundingClientRect(),
		scale;
	if( fromrect.width < 48 || fromrect.height < 96 )
		scale = Math.min( fromrect.height / 96, fromrect.width / 48 );
	let trf = `translate( ${fromrect.left+fromrect.width/2 - parentrect.left - cw/2}px, 
		${fromrect.top+fromrect.height/2-parentrect.top - ch/2}px )`;
	if( scale ) trf += ` scale(${scale})`;
	if( LOCALTEST && debug ) {
		if( trf.match( /-/ ) )
			debugger;
	}
	card.style.transform = trf;
	card.style.transition = 'none';
	card.style.zIndex = 1000;
}
