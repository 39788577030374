/* Creating problems mostly for bridge
 - find a bid
 - auction
 - mini-etudes for playing (open cards)
 - cardsplaying (against double dummy, fixed disposition)
*/

export async function create( solo, options ) {
	// First ask for possible problem options
/*
	let mod = await import( './tools.js' );
	let hid = await mod.edit( {
		action: {
			type: 'bigselect',
			values: [ [ 'bid', 'Find a bid' ], [ 'cardplaying', 'Cardplaying' ] ]
		}
	}, {
		id: 'problemtype',
		title: 'Create problem',
		// storeid: 'friendgame-' + (params.team?.itemid || '')
	} );
	if( !hid ) return;
	let type = hid.submit;
*/
	// if( type==='bid' ) {
		// Вопрос игроку, который должен делать заявку (solo.next)
		// Необходимо сделать preview перед публикацией
		preview( solo, options );
	// }
}

let previewSolo, previewBack, whattodo, options;
const field = 'playZone';

function preview( solo, opt ) {
	options = opt || {};
	// Keep only one visible hand (plno)
	if( solo===previewSolo ) return;
	if( previewSolo ) cancelPreview();
	previewSolo = solo;
	let game = solo.vgame.game,
		acenter = game.ext.auction_center?.holder,
		oldauctionparent = acenter && acenter.parentElement!==game.playZone && acenter.parentElement || null;
	game.isPreview = true;
	let myhand = options.onehand;
	let isonemove = options.onehand>=0;
 	whattodo = solo.makeTitle( isonemove? 'move' : null );
	if( options.puzzle && myhand===solo.contract.declarer )
		whattodo = `{Dowincontract} ${solo.contract.shorttext}`;
	previewBack = {
		pov: game.pov,
		nonames: game.playArea.classList.contains( 'nonames' ),
		bigstate: game.bigState,
		auctionParent: oldauctionparent
	};
	if( !(myhand>=0) ) myhand = solo.next;
	if( isonemove ) {
		for( let i = solo.maxplayers; i--; )
			solo.hands[i].setOpened( i===options.onehand || options.opened?.includes( i ) );
		game.setpov( myhand );
		solo.sendHands();
	}
	if( oldauctionparent ) game.playZone.appendChild( acenter );
	// solo.send( '.event clearstates' );
	game.playArea.classList.add( 'preview' );
	game.setNoNames( true );
	game[field].style.transform = 'scale( 0.8 )';
	game[field].style.transition = 'transform 0.2s';
	game.previewTitle ||= html( `<div class='previewtitle previewonly hideempty blackonwhite' 
		style='order: -1; align-self: stretch; text-align: center; padding: 0.2em 0;'></div>`, game.playZone );
	game.previewTitle.setContent( whattodo );
	// game.checkCenterAuction();

	game.previewControl ||= html(
		`<div class='display_none flexline center previewcontrol'
			style='position: absolute; bottom: 0;'>
			<button class='default' data-action='preview_share'>Share</button><button data-action='preview_cancel'>Cancel</button>`,
		game.commandZone, previewClick );
	game.previewControl.show();
	// setTimeout( game.onresize, 500 );
}

async function previewClick( e ) {
	if( !previewSolo ) return;
	let solo = previewSolo,
		action = e.target.closest( '[data-action]' )?.dataset.action,
		url = solo.makeShareUrl( {
			puzzle: options.puzzle,
			me: solo.vgame.game.pov
		} );
	cancelPreview();
	if( action!=='preview_share' ) return;
	// If possible just make URL with problem
	if( !url ) {
		// Create json with problem
		let json = solo.json( {
			oneview: 'next'
		} );
		// Remove extra information
		delete json.players;
		delete json.boardno;
		json.scoring = solo.initData.scoring;
		if( solo.initData.ismatch ) json.ismatch = true;
		json.mode = 'move';
		log( JSON.stringify( json ) );
		let pres = await API( '/uniq/put', {
			public: json
		} );
		log( 'Result of put: ' + pres?.uniq );
		if( pres?.ok ) {
			url = '/' + pres.uniq;
		}
	}
	if( url ) {
		let host = 'https://www.neobridge.eu', // WWWHOST,
			set = await (await import( './inv.js' )).invite( {
			title: whattodo,
			// text: '{Invitetoplay} ' + self.gameInfo['title'],
			// team: self.team,
			// max: max,
			url: `${host}${url}`
			// byorigin: self.item,
			// skip: [ ...players.map( x => x.user?.itemid ) ]
		});
	}
}

function cancelPreview() {
	if( !previewSolo ) return;
	for( let i=previewSolo.maxplayers; i--; )
		previewSolo.hands[i].setOpened( true );
	previewSolo.sendHands();
	let game = previewSolo.vgame.game;
	game.setpov( previewBack.pov );
	game.route_bigstate( previewBack.bigstate );
	game.isPreview = false;
	game[field].style.transform = '';
	game[field].style.transition = '';
	game.previewControl.hide();
	game.playArea.classList.remove( 'preview' );
	game.setNoNames( previewBack.nonames );
	previewBack.auctionParent?.appendChild( game.ext.auction_center.holder );
	previewSolo = null;
}