// Pay & buy module

let askwin;

export async function askBuy( params ) {
	if( !askwin ) {
		askwin = makeBigWindow( {
			head: 'standard'
		}, `<div class='column centered'>
				<pre style='padding: 0 1em; font-family: inherit' class='descr'></pre>
				<span class='subtitle required' style='margin-bottom: 1rem'></span>
				<button class='display_none default' style='font-size: 1.2rem' data-closeselect='premium'>💎{Buy} {Premium}</button>
				<button class='display_none default' style='font-size: 1.2rem' data-closeselect='fants'>💰{Buy} {fants}</button>
			</div>` );
	}
	askwin.hide();

	let ttl = '';
	if( params.picture ) ttl = fillMagicIMG( params.picture, 36, {
		add: 'style="margin-right: 0.5em"'
	} );
	ttl += params.title;
	askwin.$( '.title' ).setContent( ttl );
	askwin.$( '.descr' ).setContent( params.descr?.replace( '  ', '<br>' ) || '' );
	let what = params.what, nes;
	if( what.freemium ) nes = `{Premiumrequired} {or} 💰${what.fants}`;
	else if( what.fants ) nes = `${params.reason || '{Required}'} <span class='fants'>${what.fants}</span>. {Youhave} <span class='fants'>${MYFANTS.value}</span>`;
	else nes = '{Premiumrequired}';
	askwin.$( '.required' ).setContent( nes );
	askwin.$( '[data-closeselect="fants"]' ).makeVisible( what.fants );
	askwin.$( '[data-closeselect="premium"]' ).makeVisible( what.premium || what.freemium );
	let res = await askwin.promiseShow();
	if( res==='cancel' || res==='close' ) return;
	// Выбрана покупка. Пока открываем
	if( res==='fants' ) {
		let jres = await API( '/buy_getshowcase', {
			ids: 'fants',
			needfants: what.fants
		});
		if( jres ) {
			log( 'Start buying ' + JSON.stringify( jres ) );
		}
		{
			shopping( {
				ids: 'fants',
				reason: params.title,
				needfants: what.fants
			} );
		}
	}
	else
		shopping( res );
}