let fixfio;
export async function provideFullname() {
	// Prepare to askfixfio
/*
	let res = await API( '/askfixfio', {
		checkonly: true
	}, 'internal' );
*/
	let me = User.get( UIN ),
		fio = me?.fio?.split( ' ' ),
		name = fio?.[0] || '',
		lastname = fio?.slice( 1 ).join( ' ' ) || '';
	fixfio ||= makeBigWindow( {
		repeatid: 'fixfio',
		noads: true,
		title: '{Specify_authenic_data}',
		html: `<div class='column' style='padding: 0.5em 1em 0 1em; max-width: 20em'>
		<input required minlength=2 value='${name}' name='firstname' placeholder='{Firstname}' style='padding: 0.5em'>
		<input required minlength=2 value='${lastname}' name='lastname' placeholder='{Lastname}' style='padding: 0.5em; margin-top: 1px'>
		<span style='font-size: 1rem; margin: 0.5em 0; color: var(--light_gray)'>{Fixfio_prompt}</span>
		<button default data-api='/askfixfio' data-collectparams='1' data-closeonsuccess='1'>{Store}</button>
		<button data-closeselect='cancel'>{Cancel}</button>
		</div>`
			// <span style='margin-bottom: 0.5em'>{Enter_your_first_and_last_name}</span>
	});
	log( `Provide fullName show` );
	fixfio.show();
/*
	if( res==='ok' ) {
		let apires = await API( '/askfixfio', {
			data: collectParams( fixfio )
		} );
	}
*/
}