// Hints module

const allhintsDB = new Map;

let dbHint,
	dbInitialized,
	dbWaiting,
	dbRequest = indexedDB.open( "hints", 3 );

function dbLoaded() {
	dbInitialized = true;
	if( dbWaiting ) delay( window.hint.update );
}

dbRequest.onsuccess = () => {
	dbHint = dbRequest.result;
	dbLoad();
};

dbRequest.onupgradeneeded = e => {
	let d = e.target.result;

	d.onerror = () => {
		window.log?.( "hints:db: error creating db hints" );
		dbLoaded();
	};
	if( e.oldVersion>0 ) {
		try {
			d.deleteObjectStore( "hints" );
		} catch( e ) {
		}
	}
	try {
		d.createObjectStore( "hints", { keyPath: ['id'] } );
	} catch( err ) {
		window.log?.( 'hints:db: error while upgrading table hints ' + JSON.stringify( err ) );
		dbLoaded();
	}
};

function dbLoad() {
	let tr;
	try {
		tr = dbHint.transaction( 'hints', 'readonly', {
			durability: 'relaxed'
		} ).objectStore( 'hints' );
	} catch( e ) {
		window.log?.( 'hints:db: failed to load local hints' );
		return;
	}
	let all = tr.getAll();
	all.onerror = () => {
		window.log?.( 'hints:db: getAll failed' );
		dbLoaded();
	};
	
	all.onsuccess = e => {
		for( let o of e.target.result )
			allhintsDB.set( o.id, o );
		fire( 'hintsloaded' );
		dbLoaded();
	}
}

function storeHintDB( hint ) {
	if( !UIN ) return;		// No store for non authorized
	dbHint?.transaction( 'hints', 'readwrite' )
		.objectStore( 'hints' )
		.put( {
			id: `${UIN}_${hint.id}`,
			next: hint.next
		} );
}

class Hint {
	static #map = new Map;
	static #all = [];
	static #bytype = {};
	static #last;
	static #base;			// "Наблюдаемый" объект, на базе которого строятся Hints

	/*
		constructor() {
			Hint.holder = document.querySelector( '#hints' );
			// if( Hints.holder ) Hints.update();
		}

	*/
	static clear() {
		Hint.#all = [];
		Hint.#bytype = {};
		Hint.#last = null;
	}

	static loggedout() {
		Hint.#map.clear();
	}

	static update( hintBase ) {
		if( !dbInitialized ) {
			// Delay hints update
			log( 'hints: update before db completed' );
			dbWaiting = true;
			return;
		}
		if( Hint.#base===hintBase ) return;
		Hint.#base = hintBase;
		Hint.clear();
		// Initialize hint list of all current hints
		fire( 'fillhints', Hint );

		Hint.show();
	}

	static gotit( hint ) {
		hint.element?.hide();
		hint.next = 'done';
		storeHintDB( hint );
		// localStorage['hint_' + hint.id] = 'done';
		// sessionStorage['hint_' + hint.id + '_next' ] = 'done';
		Hint.update();
	}

	static async showBig( hint, checkshowed ) {
		// Покажем большую информацию
		if( typeof hint==='string' ) hint = Hint.#map.get( hint );
		if( !hint ) return;
		if( checkshowed && hint.showed ) return;
		log( 'hint: show big ' + hint.biginfo );
		let win = makeBigWindow( {
			repeatid: 'bighint',
			title: '💡 ' + (hint.title || '{Information}'),
			html: `<PRE style='overflow-y: auto; padding: 0 1em; margin: 0'>${hint.biginfo}</PRE>
					<button class='default importantsize' data-closeselect='gotit' style='margin-top: 1rem'>${hint.button?.text || '{Gotit}'}</button>`
		} );
		hint.showed = true;
		if( hint.type==='click' )
			Hint.gotit( hint );			// Immediatly save we got the hint

		if( hint.next==='done' ) {
			// Hint already completed, just remind
			win.show();
			return;
		}
		let res = await win.promiseShow();
		if( res!=='gotit' ) return;
		if( hint.button?.action ) {
			// Выполним action вместо gotit
			hint.button.action();
			return;
		}
		Hint.gotit( hint );
	}

	static showOne( hint, canclose ) {
		let el = $( '#hint_' + hint.id );
		if( !el ) {
			let
				prefix = hint.button ? '' : '💡 ',
				str = `<div id='hint_${hint.id}' style='order: ${hint.order}' class='display_none hint flexline wrapinportrait' data-hint='${hint.id}'>
			<span style='white-space: initial; flex-shrink: 10'>${prefix}${hint.text || hint.title}</span>`;
			if( hint.biginfo ) {
				let popup = hint.action? `data-popupinfo='${hint.biginfo}'` : '';
				str += `<span class='flexline control details' style='border-bottom: 1px dashed; margin: 0 0.5em' ${popup}>${hint.details||'{Details}'}</span>`;
			}
			if( hint.button ) {
				let datastr = '';
				if( hint.button.data )
					for( let k in hint.button.data )
						datastr += ` data-${k}='${hint.button.data[k]}'`;

				str += `<button class='flexline' ${datastr}>${hint.button.text}</button>`;
				if( canclose!==false )
					str += `<span style='flex-grow: 1'></span>
					<span data-hideclosest='.hint' class='w32 icon closebutton control' 
						style=''></span></div>`;
			} else {
				/*
									if( hint.biginfo )
										str += `<button class='flexline'>{Details}...</button>`;
									else
				*/
				if( !hint.biginfo )
					str += `<button class='flexline'>{Gotit}</button>`;
			}
			el = html( str, Hint.holder );
			el.onHide = Hint.onHideHint;
			el.onclick = Hint.onClick;
			hint.element = el;
		}
		el.show();
	}

	static async onClick( e ) {
		let element = e.target.closest( '[data-hint]' ),
			hint = Hint.#map.get( element.dataset.hint );
		if( !hint ) return;

		if( e.target.classList.contains( 'details' ) ) {
			Hint.showBig( hint );
			return;
		}
		if( e.target.tagName==='BUTTON' ) {
			// Maybe action set in structure
			// if( !hint.button ) {
			// Запрос на biginfo?
			// }
			if( hint.button?.action )
				hint.button.action();
		}
	}

	static async show() {
		// if( $( '#bighint.visible' ) ) return;
		let big = Hint.#bytype.big;
		if( big ) {
			if( !big.showed && !$( '.bigwindow.visible' ) ) return Hint.showBig( big );
			// Window have been showed but haven't got.
		}
		Hint.holder ||= document.querySelector( '#hints' );
		if( !Hint.holder ) return;
		let showed = new Set;

		// Зачистка. Можно для экономии делать без неё, но сложнее и менее надежно
		Hint.holder.innerHTML = '';
		for( let [ _, hint ] of Hint.#map ) hint.element = null;

		// for( let hint of Hints.#all ) {
		for( let type of [ 'big', 'action', 'hint' ] ) {
			// Check if hint is already done
			// This hint can be showed, lets do it.
			// Keep objects for any hint
			// if( type==='big' ) continue;
			let hint = Hint.#bytype[type];
			if( !hint ) continue;
			Hint.showOne( hint );
			showed.add( hint );
			if( type==='big' ) break;
			break;
		}
		if( !showed.size && Hint.#last ) {
			Hint.showOne( Hint.#last, false );
		}
		/*
				for( let o of Hint.holder.$$( '.hint' ) ) {
					let h = Hint.#map.get( o.dataset.hint );
					o.makeVisible( showed.has( h ) );
				}
		*/
	}

	static check( def ) {
		if( !def?.id || Hint.#map.get( def.id ) ) return;
		Hint.add( def );
	}

	static add( def ) {
		// Add hints one by one for later showing
		if( !def?.id ) return;
		let id = def.id;

		let hint = Hint.#map.get( id );
		if( !hint ) {
			hint = {
				// next: localStorage['hint_' + id] || sessionStorage['hint_' + id] || 0,
				...allhintsDB.get( `${UIN}_${id}` ),
				...def
			};
			Hint.#map.set( id, hint );
		}
		hint.text = def.text;
		hint.title = def.title;
		hint.biginfo = def.biginfo;

		// Возможно, эта подсказка уже "понята"
		if( hint.next==='done' ) return;
		if( !Hint.#last && hint.keeplast ) this.#last = hint;
		if( +hint.next>Date.now() ) return;		// Next time not arrived
		hint.order = priority[hint.priority] || +hint.priority || 0;
		if( hint.frequency ) hint.interval = getmsbyperiod( hint.frequency );

		// Если для данного типа есть hint с более высоким приоритетом, то этот не добавляем
		let type = hint.type || ( hint.button? 'action' : 'hint' ),
			best = Hint.#bytype[type];

		if( best?.order<=hint.order ) return;

		Hint.#bytype[type] = hint;
		Hint.#all.push( hint );
	}

	static onHideHint( e, element ) {
		if( !e ) return;
		// User hides hint. Block showing for period
		let id = element.id?.replace( 'hint_', '' ) || element.dataset.hint,
			hint = Hint.#map.get( id );
		if( !hint ) return;
		hint.next =
			Date.now() + (hint.interval || dayms);
		storeHintDB( hint );
		// sessionStorage['hint_' + id] = hint.next;
		// toast( 'Ok. We will remind you later' );

		window.hint.update();
	}

	static isDone( id ) {
		return Hint.#map.get( id )?.next==='done' || allhintsDB.get( `${UIN}_${id}` )?.next==='done';
	}

	static done( id ) {
		if( !id ) return;
		let h = Hint.#map.get( id );
		if( h ) h.next = 'done';
		storeHintDB( {
			id: id,
			next: 'done'
		});
	}
}

const tmsyms = {
	s: 1,
	m: 60,
	h: 60*60,
	d: 60*60*24
};

function getmsbyperiod( str ) {
	if( str==='day' ) return dayms;
	let m = /^(\d)(.)$/.exec( str );
	if( m ) {
		return (+m[1])*tmsyms[m[2]]*1000 || 0;
	}
	return 0;
}

const dayms = 60 * 60 * 24 * 1000,
	priority = {
		high: -100,
		normal: 0,
		low: 100
	};

//
// Check if hint should be visible.
// id - identifier of the hint
// options - {
//    frequency - period of hint showing until done or got
// }
/*
export function checkHint( id, options ) {
	let element;
	if( id instanceof HTMLElement ) {
		element = id;
		id = element.dataset.hint;
		if( !id ) return;
	}
	let hint = Hint.#map.get( id );
	if( hint ) {
		if( hint.done ) return false;		// Hint completed
	}
	if( !hint ) {
		hint = {
			nexttime: +sessionStorage['hint_' + id + '_next' ] || 0,
			...options
		};
		Hint.#map.set( id, hint );
	}
	if( hint.nexttime>Date.now() ) return false;		// Next time not arrived
	if( element ) {
		element.show();
		element.onHide = onHideHint;
	}
	return true;
}

*/
/*
export default function fillHints( hints, holder ) {
	// Покажем по одной подсказке каждого типа
	let set = new Set, filtered = [];
	for( let hint of hints ) {
		// Check if hint is already done
		let id = hint.id;
		if( !id ) continue;
		let hintstat = Hint.#map.get( id );
		if( !hintstat ) {
			hintstat = {
				next: localStorage['hint_' + id + '_next'] || sessionStorage['hint_' + id + '_next'] || 0,
				...hint
			};
			Hint.#map.set( id, hintstat );
		}
		if( hintstat.done ) continue;		// Hint completed
		if( hintstat.next==='done' || +hintstat.next>Date.now() ) continue;		// Next time not arrived
		hintstat.order = priority[hint.priority] || +hint.priority || 0;
		filtered.push( hintstat );
	}

	filtered.sort( ( x1, x2 ) => {
		return x1.order - x2.order;
	});

	for( let hint of filtered ) {
		// Check if hint is already done
		// This hint can be showed, lets do it.
		// Keep objects for any hint
		let el = $( '#hint_' + hint.id );
		if( !el ) {
			let
				prefix = hint.button? '' : '💡 ',
				str = `<div id='hint_${hint.id}' style='order: ${hint.order}' class='display_none sheet flexline nowrap' data-hint='${hint.id}'>
			<span style='white-space: initial; flex-shrink: 10'>${prefix}${hint.text}</span>`;
			if( hint.button ) {
				let datastr = '';
				if( hint.button.data )
					for( let k in hint.button.data )
						datastr += ` data-${k}='${hint.button.data[k]}'`;

				str += `<button class='flexline' ${datastr}>${hint.button.text}</button>`;
				str += `<span style='flex-grow: 1'></span>
				<span data-hideclosest='.sheet' class='w32 icon grayhover closebutton' style='margin-left: 1em'></span></div>`;
			} else {
				if( hint.biginfo )
					str += `<button class='flexline'>{Details}...</button>`;
				else
					str += `<button class='flexline'>{Gotit}</button>`;
			}
			el = html( str, holder );
			el.onHide = onHideHint;
			el.onclick = Hint.onClick;
		}
		el.show();
		break;
	}
}
*/

window.hint = Hint;

export default Hint;

dispatch( 'loggedout', Hint.loggedout );
