import User from './user.js';
import Timer from './timer.js';

const
	arrowCode = { bottom: '&darr;', left: '&larr;', top: '&uarr;', right: '&rarr;' },
	elClasses = {
		avatar: '.display_none',
		nick: '.display_none.flexline.nowrap.textoverbg',  // was "fade"
		score: '.fade.lightborder.goodbad',
		colorbox: '.display_none',
		rating: '.nowrap'
	};

export default class Player {
	#elClosedCount;

	constructor( game, _pos ) {
		this.game = game;
		this.place = _pos;
		this.mine = false;
		this.nick = '';
		this.uid = 0;
		this.score = 0;
		this.elements = {};
		// data = [],
		this.msgs = new Set;
		this.objects = [];
		this.timer = new Timer(  {
			stopwatch: true,
			game: game,
			place: _pos,
			visibility: { board: 'always', poker: 'running' }[game.module] || '30sec'
		} );
		this.eltimer = this.timer.holder;
		this.eltimer.classList.add( 'bgborder', 'play_timer', 'hidereplay' );

		this.tricksCount = construct();
		// timerID, timerval, timerdir, fixtimerval, fixmoment,
		this.values = new Map;

		for( let o of 'box avatar nick score mscore colorbox rating'.split(' ') )
			this.create( o );
		this.create( 'arrow', html( `<div class='fade hideinpreview' style='grid-area: arrow'></div>` ) );
		// Bridge pictogram mode - no arrows
		this.elbox.dataset.noinfo = 1;
		this.elscore.classList.add( 'hideempty' );
		this.elavatar.onlongpress = this.avatarLongPress.bind( this );
		this.elavatar.addEventListener( 'dragover', e => {
			let fromplace = e.dataTransfer.getData( 'text' ) || this.game.dragged?.place;
			fromplace = +fromplace;
			if( fromplace>=0 ) {
				// таскаем другого игрока
				if( fromplace===this.place ) return;
				if( this.user && !this.reserved && !this.game.isTeacher() ) return;	// Только учитель может менять игроков
				e.preventDefault();
			}
		});
		this.elavatar.addEventListener( 'dragstart', e => {
			this.game.dragged = {
				element: e.target,
				place: this.place
			};
			e.dataTransfer.clearData();
			e.dataTransfer.setData( 'text/plain', this.place.toString() );
			log( 'Test ' + e.dataTransfer.getData( 'text' ) );
		});3

		this.elregion = construct( '.region.emoji.hideempty.rem2', this.elnick );
		this.elnickText = construct( 'span', this.elnick );
		this.elremovebutton = //construct( 'span.rightbottom.w24.closebutton.grayhover.parenthovervisible.bgcolorwhite', this.elavatar, this.removePlayer.bind( this ) );
			html( "<span class='w24 closebutton grayhover parenthovervisible bgcolorwhite' style='position: absoule; bottom: -12px; right: -12px'></span>",
				this.elavatar, this.removePlayer.bind( this ));

		// this.create( 'box' );
		// this.create( 'avatar display_none' );
		this.elavatarImg = construct( '.nozoom.display_none[data-magictype=avatar]', 'img' );
		this.elavatarImg.onlongpress = this.avatarLongPress.bind( this );
		// this.create( 'nick fade' );
		// this.create( 'score fade lightborder' );
		// this.create( 'mscore' );
		// this.create( 'arrow fade' );
		this.create( 'sitin hidereplay mybutton column display_none hideempty',  '{Sit}' );
		// this.create( 'colorbox display_none' );
		// this.create( 'rating nowrap' );
		this.create( 'bubble fade goodbad lightborder' );
		this.create( 'state display_none suitcode bid' );
		this.create( 'bid bid suitcode fade' );
		this.create( 'msg nowrap fade' );
		this.create( 'tricks display_none' ); // Before was "fade"
		this.create( 'matchscore scoreboard hideempty _getready_object' );

		// this.elements.tricks.dataset.showpoints = !this.game.ispref && !this.game.isbridge && '1' || '';

		this.elbid.onclick = e => {
			// Click on my own bid in bridge asks for explanation
			game.centerAuction?.bidClick( this.place, this.elbid.dataset.code );
		}

		if( game.ispoker ) this.create( 'cash hideempty' );

		this.bidBody = construct( '.flexline.nowrap.hideempty.bidbody', this.elbid );
		this.elbid.contentBody = this.bidBody;
		this.bidExplain = construct( '.hideempty.bidexplain', this.elbid );

		// this.elavatar.onclick = avatarClick;
		this.elstate.onclick = e => this.game.plrStateClick( this, e );
		this.elsitin.onclick = this.game.sitinClickBind;
		this.elnickText.onclick = this.game.sitinClickBind;
		this.elavatarImg.onclick = this.game.sitinClickBind;
		this.elbubble.onclick = e => e.currentTarget.hide();

		this.elavatar.appendChild( this.elavatarImg );
		this.eltricks.appendChild( this.tricksCount );
		this.putAllTo( this.elavatar );
	}

	get canManageSit() {
		return ( this.reserved || !this.user && this.sitavail ) && this.game.isFounder;
	}

	checkAvatarSize() {
		let val = ( this.elbid.textContent || this.sitavail || this.elstate.isVisible() ) && 1 || 0;
		if( !val ) {
			if( !this.game.noNames ) {
				// Show place for avatar if here is some player
				val = (this.showName || this.uid>0)? 1 : 0;
			}
		}
		if( DEBUG )
			log( `avtSize ${this.position}: ${this.showName},${this.elbid.textContent},${this.sitavail},${this.elstate.isVisible()} = ${val}` );
		this.game.playZone.style.setProperty( `--avatar-coeff-${this.position}`, val );
	}

	async removePlayer() {
		if( !this.canManageSit || !this.user ) return;
		// Учителю не надо ничего подтверждать
		// Для быстрого интерфейса пока не подтверждаем никому ничего
		// if( !(await askConfirm( `{Remove} ${this.user.getShowName}?` )) ) return;
		this.game.send( 'removeplayer', `place=${this.place} user=${this.uid}` );
	}

	async avatarLongPress( e ) {
		// Drop down menu for player, common information. removing invitations
		let str = '';
		if( this.canManageSit ) {
			str += `<span data-serveraction='${fixedEncodeURIComponent( this.game.mkserver( 'removeplayer', `place=${this.place} user=${this.uid}` ) )}'>
				{Remove}</span>`;
		}
		let tour = this.game.gameInfo?.tour;
		if( UIN && tour?.teams ) {
			// Командный турнир
			if( elephCore.isReged( tour.id ) ) {
				// Тоже участник этого турнира.
				// TODO: если есть список участников турнира, и известно, что менять нельзя,
				// нет смысла делать запрос
				let mytour = elephSubscribe.get( 'me.event_' + tour.id );
				if( mytour?.players.includes( this.uid ) ) {
					let res = await this.game.send( 'askreplacelist', `place=${this.place}` );
					if( res?.users ) {
						// Add user names to dropdownmenu
						str += `<span style='color: var( --light_gray ); pointer-events: none'>{Substitution}</span>`;
						for( let u of res.users ) {
							str += `<span data-serveraction='${fixedEncodeURIComponent( this.game.mkserver( 'askreplace', `place=${this.place} user=${this.uid} newuser=${u}` ) )}'>
							${fillPlayerHTML( u, {
								noinfo: true,
								nofio: true
							} )}</span>`;
						}
					}
				}
			}

		}
		if( str )
			import( './dropdown.js' ).then( mod => mod.dropDown( str, e ) );
	}

	create( c, t, cap ) {
		let ar = c.split( ' ' ),
			id = ar[0],
			cl = elClasses[id] || '',
			el;
		if( t instanceof HTMLElement ) {
			el = t;
			el.classList.add( `play_${id}` );
		} else {
			el = construct( '.play_' + ar.join( '.' ) + cl, t );
			if( cap ) el.textContent = cap;
		}
		// el.setAttribute( 'plno', pos );
		this.elements[id] = el;
		this['el'+id] = el;
		el.dataset['plno'] = this.place;
		this.objects.push( el );
		return el;
	}

	createInfo( c ) {
		let el = this.create( c + ' display_none' );
		el.dataset['ptype'] = 'info';
		if( this.lastHolder ) this.lastHolder.appendChild( el );
		return el;
	}

	check() {
		let waitme = this.game.myreservation>=0 && UIN===this.reserved;
		this.elsitin.makeVisible( !this.game.istv && ( ( this.sitavail && !this.uid ) || waitme ) );
		this.elsitin.classList.toggle( 'importantsize', this.game.maxPlayers<=2 );
		let playable = this.game.isPlayable, sitable = true,
			text = playable ? '{Sit}' : '{Unavailable}';
		if( waitme ) text = '{Sit}';
		else {
			if( this.game.isFounder && this.game.myreservation>=0 ) {
				if( !this.reserved ) text = '{Invite}';
				else text = this.game.inprogress ? '' : '{Waiting}...';
			} else if( this.game.myreservation>=0 && UIN!==this.reserved ) {
				text = this.game.inprogress ? '' : '{Waiting}...';
				sitable = false;
			}
		}
		// Попробуем заменить все варианты кнопок на аватар
		if( !sitable || text!=='{Sit}' ) {
			text = '';
			// Показываем аватар
			if( !this.user ) {
				this.elavatarImg.src = `${IMGEMBEDPATH}/svg/icons/ic_person_outline_black_18px.svg`;
				this.elavatarImg.dataset.invertable = 1;
				this.elavatarImg.show();
			}
		}

		this.elsitin.setContent( text );
		this.elsitin.classList.toggle( 'disabled', !playable || !sitable );
		this.elsitin.classList.toggle( 'mine', text==='{Sit}' && this.reserved===UIN );
		// if( this.elnick.parentElement.classList.contains( 'play_avatar' ) )
		// 	this.elnick.classList.toggle( 'bottomname', this.game.gameState==='notstarted' );
		// If removing player allowed
		this.elremovebutton.style.display = this.reserved && this.canManageSit? null : 'none';
	}

	setTricks( data, points ) {
		let count = +data;
		if( Array.isArray( data ) ) {
			count = +data[0];
			points = data[1];
		}
		this.values.set( 'tricks', points ?? count );
		let el = this.eltricks;
		el.dataset.points = points || '';
		if( points===undefined || points==='?' || points==='' ) {
			let cpic = count<2 ? count : 2;
			el.style.background = cpic ? `url( ${IMGEMBEDPATH}/svg/cardback/h.svg#${cpic} )` : '';
			el.style.width = cpic && (1 + (cpic - 1) * 0.25 + 'em') || 0;
			el.style.padding = 'unset';
			el.style.height = '100%';
			this.tricksCount.textContent = points ?? ( count>0 && count || '' );
		} else {
			el.style.background = '#56aaff';
			el.style.width = 'initial';
			el.style.padding = '.1em .3em';
			el.style.height = 'unset';
			// el.style.fontSize = '1rem';
			this.tricksCount.textContent = points; // points;
		}
		// el.textContent = (  );
		el.makeVisible( count>0 );
		if( points!==undefined && el.onclick )
			el.style.cursor = 'pointer';
	}

	setObject( name, o ) {
		// if( LOCALTEST && name==='state' ) o = 'ST';
		let b = o || '';
		if( b[0]==='?' && ( name==='bubble' || name==='bid' ) ) b = this.isme? '' : b.slice( 1 );
		let bs = b.toString().split( ':', 2 ),
			cap = bs.length>1 && bs[1] || bs[0],
			style = bs.length>1 && bs[0],
			el = this['el' + name] || this.elements[name] || this.createInfo( name );

		this.values.set( name, cap );
/*
		if( name==='tricks' ) {
			let c = +bs[0];
			let cpic = c<2 ? c : 2;
			el.style.backgroundImage = cpic ? `url( ${IMGEMBEDPATH}/svg/cardback/h.svg#${cpic} )` : '';
			el.style.width = cpic && (1 + (cpic - 1) * 0.25 + 'em') || 0;
			this.tricksCount.textContent = c>0 && c || '';
			// el.textContent = (  );
			el.makeVisible( bs[0] );
		} else {
*/
			if( name==='state' && cap==='halfwhist' && narrowMedia.matches ) cap = '½';
			this.game.setContract( el, cap );
			// log( 'Set contract ' + cap + ' for ' + this.place + '. Check: ' + el.textContent );
			if( cap ) {
				// Skip style changing for smooth hiding empty elements
				el.dataset['textstyle'] = style || '';
			}
			el.dataset.short = el.textContent.length<4 && 1 || 0;
		// }
		this.checkAvatarSize();
	}

	updateScore( s, style ) {
		if( !s ) return;
		this.elscore.innerHTML = s;
		this.elscore.dataset.textstyle = style || '';
		this.elscore.show();
		setTimeout( () => this.elscore.hide(), 4000 );
	}

	setScore( s, msHide ) {
		this.score = s;
		this.elscore.textContent = s!==undefined ? s : '';
		this.elscore.setAttribute( 'sign', (+s)>0 ? 'positive' : (+s)<0 ? 'negative' : '' );
		let show = (s!==undefined) && s && (s!=='+0') || false;
		this.elscore.makeVisible( show );
		if( show && msHide ) {
			setTimeout( () => this.elscore.hide(), msHide );
		}
	}
	
	setMatchScore( s ) {
//					matchscore = s;
		this.elmatchscore.textContent = s;
		this.elmscore.textContent = s
	}

	setCash( v ) {
		this.cash = v;
		if( this.elcash ) this.elcash.textContent = v;
	}

	setReward( v ) {
		this.reward = v;
		if( !this.reward ) {
			this.elReward?.hide();
			return;
		}
		this.elReward ||= construct( '.play_reward', this.elavatar );
		this.elReward.textContent = v;
		this.elReward.show();
	}

	setTimer( value ) {
		if( !value ) {
			// Таймер надо спрятать
			this.timer.hide();
			// this.eltimer.textContent = '';
			// settimerdir( 0 );
			return;
		}
		// [!][addonsec][+|-]currenttimer
		// let matches = value.match( /(\!)?(\[(\d*)\])?(\+|\-)?(\d*)/ );
		// let red = !!matches[1], addon = matches[3], dir = matches[4], current = matches[5];
		let [, red, , addon, dir, current] = value.match( /(!)?(\[(\d*)\])?(\+|\-)?(\d*)/ );
		this.timer.addonSec = addon;
		this.timer.runningAddon = red;
		if( dir ) {
			this.timer.setDestination( +current, { dir: dir } );
			if( this.isme && !this.game.isbridge && !this.game.ispref ) {
				this.timer.setLowTimeAlert();
			}
		} else
			this.timer.setLeft( +current );
		this.timer.setAlarm( !!red );
	};

	get isme() {
		return this.game.getMyPlace()===this.place;
	}

	stopTimer() {
		this.timer.sleep();
	}

	showArrow( val ) {
		this.elarrow.makeVisible(
			val && ((this.game.gameState && this.game.gameState!=='notstarted') || this.uid>0) );

		if( this.game.isbridge )
			this.elarrow.classList.add( 'hideinnarrow' );
	}

	hideArrow() {
		this.showArrow( 0 );
	}

	setPosAttr( vpos ) {
		// elinfo.setAttribute( 'plno', vpos );
		this.elarrow.innerHTML = arrowCode[vpos];
		this.arrow = arrowCode[vpos];
	}
	
	setBid( str ) {
		// if( str=='0' ) str = '';
		if( this.bid===str ) return;
		this.bid = str;
		this.game.setContract( this.elbid, str );
		this.elbid.classList.toggle( 'doublefontsize', str?.length===1 || str==='XX' );
		// game.setContract( this.elbid, str );
		this.checkAvatarSize();
		return true;
	}

	setSecret( uid ) {
		if( this.secret===uid ) return;
		this.secret = uid;
		if( uid ) this.setUid( uid );
		return true;
	}

	setUid( u ) {
		//        var n = dom.getElementsByTagName( 'text' )
		// Empty user
		//nick = p.elnickText.textContent = '';
		//p.elnickText.textContent = nick;
		if( this.secret && u!==this.secret ) return;
		let uid = u, res;
		this.sitavail = false;
		this.user = null;
		this.reserved = false;
		this.invited = false;
		delete this.elavatar.dataset.undertitle;
		uid = this.uid = null;
		if( u==='*' ) {
			this.sitavail = true;
			this.elavatar.show();
			// this.elavatar.style.backgroundImage = 'none';
			// Попробуем показать пустое место
			// this.elavatarImg.src = '';		// Эта строка нужна, если показываем не аватар, а кнопку сесть
			this.elavatarImg.hide();
			this.elrating.textContent = '';
			this.elavatar.dataset.uin = 0;
			if( this.elbox ) delete this.elbox.dataset.origin;
			// elavatarbody.textContent = this.sidename;
			this.elnickText.textContent = '';
			this.elnick.hide();
			this.elregion.textContent = '';
		} else if( u==='-' ) {
			this.elavatar.hide();
			this.elnick.hide();
			if( this.elbox ) delete this.elbox.dataset.origin;
		} else if( u[0]===':' ) {
			this.elavatar.show();
			// Decaps nicks
			// let nick = u.slice( 1 ).trim().split( /\s+/ ).map( x => x.capitalize( true ) ).join( ' ' );
			let nick = u.slice( 1 ).trim().split( /\s+/ )[0].capitalize( true );
			this.showName = nick;
			this.elnickText.setContent( nick );
			this.elnick.show();
			this.elavatar.classList.add( 'empty' );
			this.checkAvatarSize();
		} else {
			let useuid = u;
			if( !+uid ) {
				let res = /\[(.+)\](\??)/.exec( u );
				if( res ) {
					useuid = res[1];
					this.sitavail = useuid===UIN;
					// reserved place
					this.reserved = useuid;
					this.invited = !!res[2];
					// if( this.gameInfo?.gameState!=='notstarted' )
					// 	this.elavatar.dataset.undertitle = '{Invitationsent}';
				}
			}
			// log( 'PARSE ' + useuid );
			let user = User.set( useuid ), isrobo = user?.isrobot();
			this.user = user;
			uid = this.uid = user?.id;
			let official = this.game.gameInfo.official || useuid.search( /^.*:.*:.*:.+:/ )>=0,
				showName;
			if( official && user ) showName = user.officialName;
			if( !showName && user ) showName = user.getShowName;
			this.showName = this.nick = showName || '';
			let me = uid===UIN;
			if( me ) this.timer.setMain();
			// log( 'Official ' + this.uid + ' official ' + user.officialName );
			this.elnickText.setContent( isrobo ? user.getShowName : this.nick );
			this.elnick.show();
			this.elregion.textContent = official && user?.regionEmoji || '';

			// this.elavatar.style.backgroundImage = user && user.getAvatarBg() || null;
			delete this.elavatarImg.dataset.invertable;
			this.elavatarImg.setMagic( user && (user.getPicture || user.id) || null, uid && ('user_' + uid) || null );
			this.elavatarImg.show();
			// this.elavatar.show();
			this.elavatar.makeVisible( !!useuid ); 	// Нет игрока и не может сесть. Не показываем
			this.elavatar.classList.remove( 'empty' );
			this.elavatar.dataset.uin = uid;
			if( this.elbox ) {
				this.elbox.dataset.origin = 'user_' + uid;
			}
			// elinfo.hidden = !nick.length;
			if( me!==this.mine ) {
				this.mine = me;
				if( me )
					this.setAttribute( 'mine', 1 );
				else
					this.removeAttribute( 'mine' )
			}
		}
		this.elavatar.classList.toggle( 'reserved', !!this.reserved );
		this.elnickText.dataset.name = this.user? `${this.user.itemid}.showname` : '';
	}
	setAttribute( name, value ) {
		this.objects.map( o => o.setAttribute( name, value ) );
	}

	removeAttribute( name ) {
		for( let o of this.objects ) o.removeAttribute( name );
	}

	setFigCount( value ) {
		this.elcolorbox.textContent = value;
	}
	// };
//    p.eltimer.style.display = 'none'
	putAllTo( holderInfo, holderActions ) {
		let holder = holderInfo || this.elbox;
		this.lastHolder = holder;
		for( let o of this.objects )
			if( o.dataset.ptype==='info' ) holder.appendChild( o );
		// holder.appendChild( this.elregion );
		holder.appendChild( this.elscore );
		holder.appendChild( this.elcolorbox );
		holder.appendChild( this.elstate );
		holder.appendChild( this.eltricks );
		holder.appendChild( this.eltimer );
		// if( holder!==p.elavatar ) holder.appendChild( p.elavatar );
		this.elavatar.appendChild( this.elnick );
		this.elavatar.appendChild( this.elmsg );
		// this.elavatar.appendChild( this.elinfo );
		holder = holderActions || this.elavatar;
		holder.appendChild( this.elbubble );
		holder.appendChild( this.elbid );
		holder.appendChild( this.elarrow );
		holder.appendChild( this.elsitin );
	};

	showMessages() {
		if( !this.msgs.size ) return this.elmsg.hide();
		// let ar = [...msgs];
		// ar[0] = '💬 ' + ar[0];
		this.elmsg.innerHTML = localize( [...this.msgs].join( '<br>' ) ); // ar.map( x => `<span class="nowrap">${x}</span>` )
		// .join( '<br>' );
		this.elmsg.show();
	}

	showChat( msg, hidems ) {
		this.msgs.add( msg );
		setTimeout( () => {
			this.msgs.delete( msg );
			this.showMessages();
		}, hidems || 3000 );
		this.showMessages();
	}

	setRating( gameid, rating ) {
		let ar = rating?.split( ':' ) || [];
		this.elrating.textContent = ar[1] || ar[0];
		if( this.user ) this.user.setRating( gameid, rating );
	}

	hide() {
		for( let o of Object.values( this.elements ) )
			o.hide();
	}

	getValue( name ) {
		this.values.get( name );
	}

	setClosedCount( count ) {
		// Use eltricks for count of closed (domino)
		this.#elClosedCount ||= html( `<span class='display_none play_cclosed' 
			style='background: antiquewhite; color: black; padding: 0.1em 0.2em; 
			border-radius: 5px; grid-area: avatar; z-index: 1000'></span>`, this.elbox );
		if( !this.#elClosedCount ) return;
		this.#elClosedCount.textContent = count;
		this.#elClosedCount.makeVisible( +count>0 );
	}

	get getCC() {
		return this.#elClosedCount;
	}
}
