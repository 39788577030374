import User from './user.js';

cssInject( 'userinfo' );

const complainMenu = [
	[ 'abuse', '{Abuse}' ],
	[ 'other', '{Otherreason}' ]
];

class Userinfo {
	constructor() {
		this.window = makeBigWindow( {
			// closebutton: true
		},
				`<div class='usercombine' style='position: relative'>
					<div class='userinfowindow display_none visible' style='max-height:100%; overflow: scroll'>
					<span class='control grayhover closebutton icon righttop invertdark' 
					style='right: 0.5em; top: 0.5em; width: 2rem; height: 2rem; z-index: 10000' data-action='close'></span>	
					<div class='userinfo column'></div>
					<div class='userbuttons flexline'></div>
					<div class='userratings column center' 
						style='width: 100%; min-height: 5em; margin-top: 1em;
						max-height: 50vh; overflow: scroll'>
					</div>
					</div>
					<div class='userchats display_none'>
					</div>
				</div>` );
		this.el = {
			ratings: this.window.$( '.userratings' ),
			buttons: this.window.$( '.userbuttons' )
		}
		this.infowindow = this.window.$( '.userinfowindow' );
		this.userInfo = this.window.$( '.userinfo' );
		// this.chatButton = this.window.$( '.openchat' );
		this.chats = this.window.$( '.userchats' );
		this.window.firstElementChild.onclick = this.click.bind( this );
		this.window.onShow = () => {
			this.lastChat?.onShow();
		};
		// this.chatButton.hide();
		dispatch( 'userfull', this.userfull.bind( this ) );
	}

	async setUser( id, game, params ) {
		if( !id ) return;
		let user = id;
		this.el.ratings.setContent( '' );
		this.el.ratings.setSpinner( true );
		if( typeof user!=='object' ) {
			user = User.get( id );
			if( !user ) {
				await User.whenloaded( id );
				user = User.get( id );
			}
		}
		if( !user ) return;
		let sameuser = this.user===user;
		this.user = user;
		this.game = game;

		let openchat = false;
		if( typeof params==='string' ) {
			if( params.includes( 'showchatnotempty' ) ) {
				let chatExist = modules.chat.isexist( this.user.id );
				if( chatExist ) openchat = true;
			} else if( params.includes( 'showchat' ) ) openchat = true;
			else if( params.includes( 'forcechat' ) ) openchat = 'force';
			params = {};
		}
		if( mayReview() ) openchat = false;

		if( sameuser && openchat ) {
			// Возможно, не нужно переключаться между информацией и чатом. Единственная ситуация, это автопоказ чата, если есть новые сообщения
			if( this.lastChat ) return this.window.show();		// был открыт чат, его и покажем
			let chat = modules.chat.getBychatid( this.user.id );
			if( openchat!=='force' && ( !chat || !chat.hasUnread ) ) {
				this.window.show();
				return;
			}
		}
		this.params = params || {};
		this.show();
		this.lastChat = null;

		if( openchat ) {
			this.openChat();
			return;
		}

		// user.fetch( true ); 	// Освежающий запрос к базе (надо будет убрать)
		this.fetchRatings();
	}

	async fetchRatings() {
		let club_id = this.params.club_id;
		this.el.ratings.setSpinner( true );
		this.el.ratings.setContent( '' );
		let api = await API( '/user_getratings', {
			user_id: this.user.id,
			team_id: club_id
		},'internal' );
		this.el.ratings.setSpinner( false );
		// Fill ratings table
		// this.el.ratings.setSpinner( false );
		let str = '';
		if( !api?.ok ) {
			// No ratings available.
			str = `<button data-action='reloadratings'>Reload ratings</button>`;
		} else {
			let res = api.result || api,
				game = this.game?.toLowerCase(),
				root = this.params.chainid?.split( '-' )[0];

			res.gamestat.sort( ( a, b ) => {
				if( game===a.id ) return -1;
				if( game===b.id ) return 1;
				if( root ) {
					if( root && a.chainid?.startsWith( root ) ) return -1;
					if( root && b.chainid?.startsWith( root ) ) return 1;
				}
				return (+b.games) - (+a.games);
			});
			str = `<table style='text-align: right'><th style='text-align: left'>{Game}</th><th>R</th></tr>`;
			for( let r of res.gamestat ) {
				str += `<tr><td style='text-align: left'>{${r.id}}</td><td style='font-family: monospace'>${r.rating}</td></tr>`;
			}
			str += '</table>';
		}
		this.el.ratings.setContent( str );
	}

	checkButtons() {
		let user = this.user,
			isfr = elephCore?.isFriend( user.id ),
			ismute = elephCore?.isMute( user.id ),
			tooltext = ismute && 'Unblock' || '✕';

		if( this.toolButton ) {
			this.toolButton.makeVisible( !!ismute );
			this.toolButton.setContent( tooltext );
			this.chatButton.makeVisible( !ismute );
			this.stateButton.makeVisible( !!isfr );
			this.relButton.makeVisible( elephCore && !ismute && !isfr );
			this.complainButton.makeVisible( window.UIN && !ismute && !isfr && modules.chat?.allSenders.has( user.id ) );
		}
	}

	show() {
		let user = this.user;

/*
		if( data ) {
			if( user.nick!==data.name )
				info += `<span>${data.name}</span>`;
		}
*/

		this.userInfo.innerHTML =
			fillPlayerHTML( user, {
				size: 96,
				nozoom: true
			} );
		this.userInfo.$( '.imagehead' ).dataset.noinfo = 1;
		this.noChat = false;
		// if( DEBUG ) log( '==DEBUGUSER== ' + str );
		// 🔧  кнопка для настроек
		if( UIN && UIN!==user.id && user.objectid==='user' ) {
			let
				isrobot = user.isrobot(),
				chatButton = `<button class='openchat display_none' data-action='openchat'>💬 {Chat}</button>`;

			if( mayReview() || isrobot ) {
				chatButton = `<button data-action='openchat' style='display: none'></button>`; // фиктивная кнопка
				this.noChat = true;
			}

			let bstr = localize(
				`<button class='display_none' data-action='addtofriend'>{Addtofriend}</button>
				<span class='display_none control' style='margin-top: 0.5em; padding: 0 0 0.5em; color: green' data-action='friend'>{Friend}</span>
				<button class='display_none' data-action='usertools'>T</button>
				${chatButton}
				<button class='display_none' data-action='complain'>{Complain}</button>` );
			this.el.buttons.setContent( bstr )
		}
		this.relButton = this.el.buttons.$( '[data-action="addtofriend"]' );
		this.toolButton = this.el.buttons.$( '[data-action="usertools"]' );
		this.chatButton = this.el.buttons.$( '[data-action="openchat"]' );
		this.stateButton = this.el.buttons.$( '[data-action="friend"]' );
		this.complainButton = this.el.buttons.$( '[data-action="complain"]' );
		this.checkButtons();
		this.infowindow.show();
		this.chats.hide();
		this.window.show();
		// this.chatButton.show();
	}

	userfull( o ) {
		if( !this.user && this.user.id!==o.id ) return;
		this.show( this.user );
	}

	async click( e ) {
		// Open chat window
		let action = e.target.dataset.action;
		switch( action ) {
			case 'reloadratings':
				this.fetchRatings();
				return;

			case 'complain':
				let mod = await import( './tools.js' ),
					res = await mod.select( complainMenu, {
					title: this.user.getShowName + ': {complaint}'
				});
				if( res ) {
					// Отправляем запрос-жалобу, добавляем в mute
					API( '/complain', {
						abuser: this.user.id,
						type: res
					},'internal' );
					// toast( '{Thankyou}. {Yourcomplaintreceived}' );
					elephCore?.do( `type=mute data=${this.user.id}` );
					this.complainButton.hide();
				}
				break;
			case 'close':
				this.window.hide();
				break;

			case 'showall':
				this.window.$( '[data-name="ratings1"]' ).show();
				e.target.hide();
				break;

			case 'openchat':
				this.openChat();
				break;

			case 'addtofriend':
				if( elephCore.isMute( this.user.id ) || elephCore.isFriend( this.user.id ) ) return;
				e.target.disabled = true;
				let resp = await elephCore.do( 'type=addtofriend data=' + this.user.id );
				if( resp.ok ) {
					this.relButton.hide(); // setContent( '✔️{Friend}' );
					this.stateButton.show();
					// e.target.setContent( '✔️{Friend}' );
					e.target.disabled = false;
					// this.toolButton.show();
				}
				break;

			case 'usertools':
			case 'friend':
				if( elephCore.isMute( this.user.id ) ) {
					// await askConfirm( `{Remove} ${this.user.getShowName} from {mute}?` );
					let resp = await elephCore.do( 'type=removebad data=' + this.user.id );
					elephCore.myMutes.delete( this.user.id );
					elephCore.myNoplay.delete( this.user.id );
					this.afterRemove( resp );
					break;
				}

				if( elephCore.isFriend( this.user.id ) ) {
					if( !(await askConfirm( '{Remove} ' + this.user.getShowName + '?' ) ) ) return;
					let resp = await elephCore.do( 'type=removefriend data=' + this.user.id );
					this.afterRemove( resp );
				}
				break;

			default:
				return;
		}
		e.stopPropagation();
		// this.window.hide();
	}

	afterRemove( resp ) {
		if( resp.ok ) {
			this.stateButton.hide();
			this.relButton.show();
			this.relButton.setContent( '{Addtofriend}' );
			this.toolButton.hide();
			this.chatButton.makeVisible( !elephCore?.isMute( this.user.id ) );
			this.complainButton.makeVisible( window.UIN && window.modules.chat?.allSenders.has( this.id ) );
		}
	}

	async openChat() {
		let chatid = `${this.user.id}`,
			chat = modules.chat?.getBychatid( chatid );
		if( !chat ) {
			let m = await import( './chat.js' );
			chat = new m.default( {
					id: chatid,
					title: this.user.getShowName,
					parent: this.chats,
					classname: 'display_none'
				});
		}
		if( chat.holder.parentElement!==this.chats )
			this.chats.appendChild( chat.holder );
		for( let o of this.chats.children )
			o!==chat.holder && o.hide();
		this.lastChat = chat;
		this.chats.show();
		chat.show();
		this.infowindow.hide();
	}
}

let userinfo = new Userinfo;

export default function show( id, game, params ) {
	if( FANTGAMES && ( id===`user_${UIN}` || id===User.myself ) ) return;
	userinfo.setUser( id, game, params );
}

export function openChat( id ) {
	if( typeof id==='object' && id.constructor?.name!=='User' ) {
		id = id.id;
	}
	if( FANTGAMES && id===`user_${UIN}` ) return;
	userinfo.setUser( id, null, 'forcechat' );
}



