// Предлагаем подключить свой телеграм для уведомлений

let joinWin, mainWin;

async function tgClick( e ) {
	if( e.target.dataset.action==='test' ) {
		e.target.disabled = true;
		let jres = await API( '/telegram_test' );
		if( jres.ok ) {
			toast( 'Check your telegram' );
		}
		setTimeout( () => e.target.disabled = false, 5000 );
		return;
	}
	if( e.target.dataset.closeselect==='remove' ) {
		API( '/telegram_unlink' );
	}
}


function job() {
	if( !window.UIN ) return;
	if( !mainWin ) {
		mainWin = makeBigWindow( `<div style='width: 25em' class='vertical_form'>
		<span class='title'>Telegram</span>
		<span>
		Вы получаете уведомления в Телеграм. 
		</span>
		<button class="rem2" data-action="test">{Check}</button> 
		<button class="rem2" data-closeselect="remove">{Remove}</button> 
		</div>` );
		mainWin.onclick = tgClick;
	}
	mainWin.show();
}

export async function join() {
	if( !window.UIN ) return;
	if( !joinWin ) {
		joinWin = makeBigWindow( `<div style='width: 25em' class='vertical_form'>
		<span class='title'>Telegram</span>
		<span>
		Чтобы получать уведомления в Telegram, вам необходимо открыть чат с нашим телеграм-ботом,
		и активировать его</span>
		<button class="rem2" data-closeselect="start">{Start}</button> 
		</div>` );

	}
	let jres = await API( '/gettelegram' );
	if( jres.joined ) return job( jres );
	let url = `https://t.me/${LOCALTEST ? 'gamblerdeveloper_bot' : 'PlayelephantBot'}?start=${jres.key}`;
	await joinWin.promiseShow();
	// Нажата кнопка start
	window.open( `https://t.me/${LOCALTEST ? 'gamblerdeveloper_bot' : 'PlayelephantBot'}?start=${jres.key}`, '_system' );
}