let resizeObserver = window.ResizeObserver && new ResizeObserver( function resizeObserver( entries ) {
	for( let entry of entries )
		entry.target.resizeObserve( entry );
} );

class TeambarElement extends HTMLDivElement {
	static #global = 1;
	static observedAttributes = [ 'data-participant' ];
	#currentBar;
	#allPlayers; #bars; #score; #issimple = false;
	#withcaption = false;
	#myTeamSub; #data; #inviteButton; #captionWaiting;
	#plusCount; #isMine;
	#hasPlaces;
	#showingWaiting;
	#seatingRound;
	#event_id;
	#totalElement;
	constructor() {
		super();
		this.id = "teambar_" + (TeambarElement.#global++);
		this.#issimple = !!this.dataset.simple;
		this.#withcaption = this.dataset.simple==='withcaption';
		let template = this.#issimple? `"warn warn warn warn" auto "total score waiting invite" auto/auto` :
			`"caption" auto "score" auto "captionwaiting" auto "waiting" auto "invite" auto "warn" auto/ auto auto`;
		let css = `position: relative; max-width: 100vw; overflow-x: scroll; padding: 2px 0; 
			display: grid; justify-content: ${this.#issimple?'start':'center'}; 
			grid-template: ${template}; overflow: hidden;`;
		if( this.#issimple )
			css += 'margin-left: 2px; gap: 5px;';
		else
			css += `gap: 5px 0;`;
		this.style.cssText = css;
		this.classList.add( 'display_none', 'visible' );

		this.ondragleave = this.#dragLeave.bind( this );
		this.ondragover = this.#dragOver.bind( this );
		this.ondrop = this.#drop.bind( this );
		this.onclick = this.#click.bind( this );

		resizeObserver?.observe( this );
		this.#resubscribe();

		dispatch( 'loggedout', this.#loggedOut.bind( this ) );
	}

	#loggedOut() {
		this.dataset.participant = '';
		this.#resubscribe();
	}

	disconnectedCallback() {
		log( 'Teambar removed from the page' );
		resizeObserver?.unobserve( this );
		elephSubscribe.delete( this.#myTeamSub );
	}

	attributeChangedCallback( name, oldvalue, newvalue ) {
		log( `Teambar changed ${name}=${newvalue}` );
		if( oldvalue===newvalue ) return;
		this.#resubscribe();
	}

	#resubscribe() {
		let part = this.dataset.participant || '';
		if( `${this.#event_id}_${this.team_id}`===part ) return;
		[ this.#event_id, this.team_id ] = part.split( '_' );
		elephSubscribe.delete( this.#myTeamSub );
		this.#myTeamSub = null;
		this.#data = {};
		this.#allPlayers = new Map;

		this.makeVisible( !!this.#event_id );

		this.html( `
			<span class='caption control ${this.#issimple?'display_none':''}' style='grid-area: caption; font-size: 1rem; justify-self: center' data-action='togglebar'></span>
			<span class='captionwaiting ${this.#issimple?'display_none':''}' style='grid-area: captionwaiting; font-size: 1rem; border-top: 1px solid gray; border-radius: 0; padding: 0.5em 0'>{Reserve}</span>
			<div class='display_none flexline nowrap scorebar' 
				style='grid-area: score; background: var( --light_white ); padding: 0 calc( var(--bigavatar)/10 ); gap: calc( var(--bigavatar)/10 ); 
				border-radius: 10px; min-height: var( --bigavatar );'></div>
			<div class='display_none visible flexline nowrap seatingbar ' 
				style='grid-area: score; align-self: start; align-items: start; gap: calc( var(--bigavatar)/10 ); background: var( --light_white ); 
				padding: 2px calc( var(--bigavatar)/10 ); border-radius: 10px; min-height: var( --bigavatar ); '></div>
			<div class='flexline waitingbar ${this.#issimple?'display_none visible':''}' style='grid-area: waiting; align-items: start; gap: calc( var(--bigavatar)/10 ); padding: 0 calc( var(--bigavatar)/10 );'></div>
			<span class='warning hideempty' style='cursor: pointer; grid-area: warn; justify-self: center;' ></span>
			<button class='invite display_none ${this.#issimple?'':'visible'}' 
				style='grid-area: invite; justify-self: center; align-self: center;'
				data-execute='usersview.selectUser' data-api_request='getuserlist_teaminvite'
				data-noinfo='1' data-multiple='1' data-tour_id='${this.#event_id}' data-participant_id='${this.team_id}'
				data-team_id='${this.team_id}'>{Invite}</button>
			<span class='display_none pluscount hideempty control grayhover' 
			style='grid-area: invite; align-self: center; margin-left: .5rem; 
				font-size: calc( var( --bigavatar )/3 ); 
				padding: calc( var( --bigavatar )/7 );
				border: 1px solid lightgray; border-radius: 50%;'></span>
			` );
		this.#bars = Object.fromEntries([ 'score', 'seating', 'waiting' ].map( x => [
			x, {
				holder: this.$( `.${x}bar` ),
				holders: [],
				players: []
			}
		]) );
		this.#bars.score.scores = [];
		this.#inviteButton = this.$( 'button.invite' );
		this.#captionWaiting = this.$( '.captionwaiting' );
		this.#plusCount = this.$( '.pluscount' );

		if( this.#issimple )
			this.#totalElement = html( `<div class='total column center' 
				style='grid-area: total; justify-content: space-evenly; max-width: 7rem; 
				font-size: min( 1rem, calc( var( --bigavatar ) / 4 )'>
				<span class='name hideempty' style='max-width: 7rem; overflow: hidden; text-overflow: ellipsis; text-align: center'></span>
				<span class='score display_none' style='background: var( --light_white ); font-weight: bold; padding: 0.2em 0.5em; font-family: monospace;'></span>
			</div>`, this );

		if( this.dataset.closeable )
		{
			html( `<span class='control grayhover icon invertdark	' 
			style='grid-area: invite; justify-self: end; position: relative; left: 2rem; width: 2rem; height: 2rem;
			background-image: url(${IMGEMBEDPATH}/svg/icons/highlight_off_black_24dp.svg)' data-closeselect='close'></span>`,
				this, this.#closeMe.bind( this ) );
		}

		this.#showingWaiting = true;
		this.#hasPlaces = false;
		this.#currentBar = 'seating';
		this.#isMine = false;

		if( this.#event_id ) {
			this.#myTeamSub = elephSubscribe.add( `event_${this.#event_id}_t_${this.team_id}`,
				this.#parse.bind( this ) );
			if( this.#withcaption )
				this.#totalElement?.$( '.name' ).setContent( '{Loading}' );
		}
	}

	resizeObserve() {
		this.#needRelocate();
	}

	#closeMe( e ) {
		this.dataset.participant = '';
		e?.stopPropagation();
	}

	#openBig() {
		// First try to reuse this big one
		let win = makeBigWindow( {
			id: `bigteambar_${this.#event_id}_${this.team_id}`,
			title: this.#data.full?.name || '{Team}',
			html: `<div is='neo-teambar' data-participant='${this.#event_id}_${this.team_id}' data-teamname='${this.#data.full?.name || ''}'></div>`
		});
		void win.show();
	}

	#click( e ) {
		let dataset = e.target.dataset;
		if( dataset.bar!=='waiting' && dataset.place && !this.#bars[dataset.bar].players[+dataset.place] ) {
			void makeBigWindow( {
				id: 'teamemptyplace',
				title: '{Information}',
				html: `<div class='column' style='padding: 0.5em; gap: 1em'>
					<span>{Team_tour_empty_place_info}</span>
					<button data-closeselect='close'>{Close}</button>
					</div>`
			}).show();
			return;
		}

		let plr = e.target.closest( '[data-scoreplace]' );
		if( this.#currentBar==='score' && +plr?.dataset.scoreplace>=0 ) {
			let game = this.#score[+plr.dataset.scoreplace].game;
			if( game ) {
				// Go to the game table
				e.stopPropagation();
				e.preventDefault();
				goLocation( game );
			}
			return;
		}

		if( e.target.closest( '[data-denyattr]' ) ) {
			// Get info about problem player
			let ihead = e.target.closest( '[data-denyattr]' ),
				deny = ihead.dataset.denyattr,
				user = ihead.dataset.origin.split( '_' )[1];
			TeambarElement.#showDeny( user, deny );
			return;
		}

		if( e.target.classList.contains( 'warning' ) ) {
			// If there is my problem, fix it
			let myattr = this.#me?.attr;
			if( myattr ) {
				// Get info window with my information
				TeambarElement.#showMyDeny( myattr );
				return;
			}
		}

		if( e.target.dataset.action==='togglebar' ) {
			let bar = this.#currentBar==='score'? 'seating' : 'score';
			if( bar==='score' && !this.#score ) return;
			this.#setBar( bar );
			return;
		}

		if( this.#issimple ) {
			// Open big team window
			this.#openBig();
			// return;
		}
	}

	get #me() {
		return this.#allPlayers.get( UIN );
	}

	static async #showDeny( user, deny ) {
		if( user===UIN ) {
			this.#showMyDeny( deny );
			return;
		}
		let html = `<div class='column' style='padding: 0.5em; gap: 1em'>`,
			title = '{Information}';
		if( deny.includes( 'f' ) ) {
			html += `
					<span>{Tour_player_no_fixfio,${User.get( user )?.getShowName}}</span>
					<button default data-closeselect='close'>{Close}</button>
				`;
			title = '🔺 {Nofio}';
			// <button default data-closeselect='askfixfio'>{Ask_the_player_to_provide_name}</button>
		} else if( deny.includes( '$' ) ) {
			html += `
					<span>{Tour-player-no-premium,${User.get( user )?.getShowName}}</span>
					<button default data-closeselect='askbuypremium'>{Offer_to_buy_a_premium}</button>
					<button data-closeselect='payforall'>{Payforeveryone}</button>
				`;
		} else {
			html += `
					<span>{Tour_player_no_play,${User.get( user )?.getShowName}}</span>
					<button default data-closeselect='close'>{Close}</button>`;
		}
		html += '</div>';
		await makeBigWindow( {
			repeatid: 'problemparticipant',
			title: title,
			html: html
		}).show();
	}

	static async #showMyDeny( deny ) {
		let html = `<div class='column' style='padding: 0.5em; gap: 1em'>`,
			title = '{Information}';
		if( deny.includes( 'f' ) ) {
			html += `
					<span>{Need_to_provide_fullname_to_participate}</span>
					<button default data-closeselect='providefullname'>{Doitnow}</button>
				`;
			title = '{Nofio}';
		} else if( deny.includes( '$' ) ) {
			html += `
					<span>{Need_premium_for_team_tournament}</span>
					<button default data-closeselect='buypremium'>{Buy} {now}</button>
				`;
		} else {
			html += `
					<span>{Tour_player_no_play,${User.myself?.getShowName}||''}</span>
					<button default data-closeselect='close'>{Close}</button>`;
		}
		html += '</div>';
		let res = await makeBigWindow( {
			repeatid: 'problemparticipant',
			title: title,
			html: html
		}).promiseShow();
		switch( res ) {
			case 'providefullname':
				import( './fixfio.js' ).then( win => win.provideFullname() );
				break;
			case 'buypremium':
				shopping( {
					ids: 'premium'
				});
				break;
		}
	}

	#setBar( newbar ) {
		if( this.#currentBar===newbar ) return false;
		this.#bars[this.#currentBar]?.holder.hide();
		this.#currentBar = newbar;
		let livescore = newbar==='score';
		this.#bars[this.#currentBar]?.holder.show();
		this.#totalElement?.$( '.score' ).makeVisible( livescore );
		let skipodd = this.#data.full.pairgame;
		for( let i=0; i<this.#bars.score.scores.length; i++ ) {
			if( skipodd && i%2 ) continue;
			let el = this.#bars.score.scores[i];
			el.makeVisible( livescore );
		}
		this.#checkWarnings();
		this.#checkWaiting();
		for( let [ _, p ] of this.#allPlayers )
			this.#checkDraggable( p );
		this.#needRelocate();
		return true;
	}

	#setPlayer( plrid, attr ) {
		if( !plrid ) return undefined;
		let plr = this.#allPlayers.get( plrid );
		if( !plr ) {
			plr =  { id: plrid };
			this.#allPlayers.set( plrid, plr );
		}
		if( plrid===UIN ) this.#isMine = true;
		// if( this.#allPlayers.get(plrid) ) return this.#allPlayers.get[plrid];
		if( !plr.holder ) {
			let ph = html( fillPlayerHTML( plrid, {
				// textonpicture: true,
				imgoptions: {
					draggable: false,
					classes: 'bigavatarsize nozoom',
					style: 'border-radius: 50%; box-sizing: border-box;'
				},
				size: this.size || 96,
				classes: 'column display_none visible',
				style: `font-size: 1rem; gap: 0; position: absolute; left:0; top:0; cursor: grab`,
				draggable: true,
				// fullname:  plr?.score,
				// notext: true, // this.notext
				notext: this.#issimple,
				noinfo: this.#issimple,
				nofio: true,
				textonpicture: true
			} ), this );

			plr.holder = ph;
			ph.ondragend = this.#dragEnd.bind( this );
			ph.ondragstart = this.#dragStart.bind( this );
			// let img = ph.$( 'img' );
			// img.ondragend = this.#dragEnd.bind( this );
			// img.ondragstart = this.#dragStart.bind( this );
		}
		if( attr!==undefined && attr!==plr.attr ) {
			plr.attr = attr;
			const colors = { f: 'red', 'f$': 'red', 'r': 'black', '$': 'orange' };
			let outcolor = colors[attr];
			plr.holder.$( 'img' ).style.border = attr? `${outcolor} 3px solid` : '';
			if( attr )
				plr.holder.dataset.denyattr = attr;
			else
				delete plr.holder.dataset.denyattr;
			plr.holder.dataset.noinfo = attr? 1 : 0;
			let badge = plr.holder.$( '.badge' ).dataset;
			badge.badge = attr? ' ' : '';
			badge.badgestyle = attr==='$'? 'alarm' : ( attr? 'bad' : '' );
			this.#checkDraggable( plr );

/*
			let badge = '';
			if( attr.includes( 'f' ) ) badge = '🚫';
			else if( attr.includes( '$' ) ) badge = '💰';
			else if( attr ) badge = '🛑';
*/
			// plr.holder.$( '.badge' ).dataset.badge = attr? ' ' : '';
		}
		return plr;
	}

	#putPlr( where, place, plr ) {
		let bar = this.#bars[where],
			fp = bar.players,
			plrid = plr?.id || plr.toString();
		let h = bar.holders[place];
		if( !h ) {
			h = html( `<div class='display_none visible' data-bar='${where}' data-place='${place}' style='order: ${place};
				transition: outline 0.1s; min-width: var( --bigavatar ); min-height: var( --bigavatar )'>
				<div class='display_none emptyplace' style='box-sizing: border-box; width: 94px; height: 94px; 
				margin-top: 3px; background: linear-gradient(45deg, #AAA, transparent); 
				border-radius: 50%; border: 5px solid red; pointer-events: none;
				max-width: var( --bigavatar ); max-height: var( --bigavatar )'></div>
				</div>`, bar.holder );
			bar.holders[place] = h;
			if( where!=='waiting' ) {
				h.ondragenter = this.#dragEnter.bind( this );
				h.ondragleave = this.#dragLeave.bind( this );
			}
			if( where==='score' ) {
				// Holder for scorinadg
				bar.scores[place] = html( `<span class='display_none score goodbad hideempty'
					style='position: absolute; top: 0; left: 0; z-index: 100;
					padding: 0.1em 0.4em; font-family: monospace; 
					font-size: min( 1rem, calc( var( --bigavatar ) / 4 );'></span>`, this );
			}
		}
		if( fp[place]===plrid ) return false;

		if( plrid ) {
			this.#setPlayer( plrid );
			// let r =
			// Put player holder into, make it by transform/translate
		} else {
			// No player for neccessary place. Red bordered empty circle
		}
		fp[place] = plrid;
		if( where==='waiting' ) h.makeVisible( !!plrid );
		h.$( '.emptyplace' )?.makeVisible( !plrid );
		let p = this.#allPlayers.get( plrid );
		if( p ) {
			// Impossible to drag within score holder (no change between tables)
			p[where+'place'] = place;
			this.#checkDraggable( p );
		}
		// let plrholder = this.#allPlayers[plrid]?.holder;
		// plrholder?.dataset.noinfo = ''
		if( where===this.#currentBar || ( where==='waiging' && this.#showingWaiting ) )
			this.#needRelocate();
		return true;
	}

	#checkDraggable( plr ) {
		let attr = plr.attr, holder = plr.holder,
			isplaying = this.#currentBar==='score' && this.#bars.score.players.includes( plr.id ),
			candrag = !attr && !isplaying,
			scorebar = this.#bars.score,
			place = isplaying? scorebar.players.indexOf( plr.id ) : '',
			game = this.#score?.[place]?.game ?? false;

		plr.holder.draggable = candrag;
		holder.style.cursor = isplaying && game? 'zoom-in' : ( candrag? 'grab' : (plr.id===UIN? 'pointer' : ( 'help' ) ) );

		holder.dataset.scoreplace = place;
	}

	#relocateBinded = this.#relocate.bind( this );
	#requestId;
	#needRelocate() {
		this.#requestId ||= requestAnimationFrame( this.#relocateBinded );
	}

	#setShowAllPlayers( val ) {
		if( !this.#issimple ) return;
		if( this.#showingWaiting===val ) return;
		this.#showingWaiting = val;
		this.#bars.waiting.holder.makeVisible( val );
		for( let plrid of this.#bars.waiting.players )
			plrid && this.#allPlayers.get( plrid ).holder.makeVisible( val );
		this.#plusCount.makeVisible( !this.#showingWaiting );
	}

	#relocate() {
		// Fill waiting bar
		let main = this.#bars[this.#currentBar].players;

		// Check if all players visible in simple mode
		let showall = main.includes( '' ) || main.length===0;
		this.#setShowAllPlayers( showall );

		// clear here (not earlier) to void repetition call
		this.#requestId = null;
		let baseRect = this.getBoundingClientRect();
		for( let barid of [ this.#currentBar, 'waiting' ] ) {
			let bar = this.#bars[barid];
			for( let [place, id] of Object.entries( bar.players ) ) {
				let rect = bar.holders[place].getBoundingClientRect();
				if( barid==='score' ) {
					if( this.#data.full.pairgame )
						bar.scores[place].style.transform = `translate( ${rect.right - baseRect.left}px, ${rect.bottom - baseRect.top}px ) translate( calc( var( --bigavatar )/20 - 50% ), -100% )`;
						// bar.scores[place].style.transform = `translate( ${rect.right - baseRect.left}px, ${rect.bottom - baseRect.top}px ) translate( calc( var( --bigavatar )/20 - 50% ), calc( -50% - var( --bigavatar )/2 ) )`;
					else
						bar.scores[place].style.transform = `translate( ${rect.left - baseRect.left}px, ${rect.bottom - baseRect.top}px ) translate( 0, -100% )`;
				}
				if( !id ) continue;
				let plr = this.#allPlayers.get(id);
				if( !plr ) {
					if( LOCALTEST ) debugger;
					continue;
				}
				// if( !this.#showingWaiting ) plr.holder.makeVisible( barid!=='waiting' );
				if( barid==='waiting' && !this.#showingWaiting ) {
					plr.holder.hide();
				} else {
					plr.holder.style.transform = `translate( ${rect.left - baseRect.left}px, ${rect.top - baseRect.top}px )`;
					plr.holder.show();
					if( !plr.holder.style.transition )
						requestAnimationFrame( () => plr.holder.style.transition = 'transform 0.3s' );
				}
			}
		}
	}

	#checkWarnings() {
		// Check warning
		for( let b of ['score', 'seating' ] ) {
			let bar = this.#bars[b];
			bar.empties = bar.players.reduce( ( total, current ) => total + (current ? 0 : 1), 0 );
		}
		let mainbar = this.#bars[this.#currentBar],
			waiting = this.#bars.waiting,
			empties = mainbar.empties,
			warn = '',
			warncolor = 'red';

		if( mainbar.empties /*|| this.#bars[this.#currentBar].holder.$( '.emptyplace.visible' )*/ ) {
			// warn = `{Players} `
			warn = `{Not_enough_players}: ${mainbar.empties}`;
		}

		// Check myself. I have to participate
		let me = this.#allPlayers.get(UIN),
			warnElement = this.$( '.warning' );
		if( me ) {
			if( me.attr==='f' )
				warn = '{You_are_not_participating}. {Fixit}';
			else if( me.attr==='$' ) {
				warn = '{You_are_not_participating}. {Needtopay}';
				warncolor = 'orange';
			} else if( me.attr )
				warn = '{You_are_not_participating}';
		}
		let wstr = '';
		if( warn )
			wstr += `<span style='display: inline-block; background: ${warncolor}; color: white; font-weight: bold; padding: 0.2em 0.5em;'>${warn}</span>`;

		warnElement.setContent( wstr );

		// Check caption
		if( this.#issimple ) {
			this.#inviteButton.makeVisible( ( !!empties || !waiting.reserve ) && this.#canInvite );
		} else {
			// Check main caption (full mode)
			let str = '';
			if( this.#currentBar==='score' ) str += '{Livescores}';
			else if( this.#currentBar==='seating' ) {
				if( this.#seatingRound ) str += `{Seating}. {Round} ${this.#seatingRound}. `;
				if( empties ) str += `{Players}: ${mainbar.players.length-empties}/${mainbar.players.length}`
				else str += 'Seating';
			}
			this.$( '.caption' ).setContent( str );

			let wstr = '';
			if( waiting.reserve ) {
				if( waiting.hotreserve ) wstr = `{Reserve}: ${waiting.hotreserve}. `;
				if( waiting.coldreserve ) wstr += `{Notready}: ${waiting.coldreserve}`;
			} else
				wstr = this.#hasPlaces? `{Noreserveplayers}`: '';
			this.#captionWaiting.setContent( wstr );
		}

	}

	get #canInvite() {
		return this.#hasPlaces && this.#isMine;
	}

	#checkWaiting() {
		function cmpattr( attr1, attr2 ) {
			const attrs = { 'r': 4, 'f$': 3, f: 2, '$': 1 };
			return ( attrs[attr1] || 0 ) - ( attrs[attr2] || 0 );
		}
		let main = this.#bars[this.#currentBar].players,
			allwaiting = [],
			all = this.#allPlayers;
		for( let k of all.keys() ) {
			if( !main.includes( k ) )
				allwaiting.push( k );
		}
		let waiting = this.#bars.waiting;
		for( let place=allwaiting.length; place<waiting.players.length; place++ ) {
			waiting.players[place] = null;
			waiting.holders[place]?.hide();
		}
		// Sort allwaiting and put in proper places
		allwaiting.sort( ( a, b ) => cmpattr( all.get(a).attr, all.get(b).attr ) );
		for( let p=0; p<allwaiting.length; p++ )
			this.#putPlr( 'waiting', p, allwaiting[p] );

		waiting.hotreserve = waiting.players.reduce( ( total, current ) => total + (current && !this.#allPlayers.get(current).attr? 1:0), 0 );
		waiting.coldreserve = waiting.players.reduce( ( total, current ) => total + (this.#allPlayers.get(current)?.attr? 1:0), 0 );
		waiting.reserve = waiting.hotreserve + waiting.coldreserve;
		if( this.#issimple ) {
			this.#plusCount.setContent( waiting.reserve? `+${waiting.reserve}` : '' );
		}
		this.#checkWarnings();
	}

	#setScorestr( line ) {
		let scores = line.split( ',' ),
			total = scores.splice( 0, 1 )[0];
		this.#setScore( this.#totalElement?.$( '.score' ), total )

		for( let i=0; i<scores.length; i++ ) {
			if( this.#data.full.pairgame && i%2 ) continue;
			let el = this.#bars.score.scores[i];
			if( !el ) break;
			// Pair game need only one part of score
			this.#setScore( el, scores[i] )
		}
	}

	#setScore( el, str ) {
		if( !el ) return;
		let [ score, timer ] = str.split( '#' );
		if( score[0]==='=' ) {	// same table
			score = score.slice( 1 );
		}
		let good = 0;
		if( score[0]?.match( /[!\?]/) ) {
			// good = score[0]==='!'? 1 : -1;
			score = score.slice(1);
		}
		if( timer==='w' ) good = 1;
		else if( timer==='l' ) good = -1;
		// let dual = score.match( /(\d+):(\d+)/ );
		// if( dual )
		// 	good = +dual[1] - (+dual[2]);
		// else
		// 	good = score[0]==='+'? 1 : ( score[0]==='-'? -1 : 0 );
		el.setContent( score );
		// Some games inverts score (like Dominoes)
		// if( this.#data.full?.scoreinverted ) good = !good;
		el.dataset.textstyle = good>0 && 'good' || good<0 && 'bad' || '';
	}

	#parse( o, minor ) {
		if( o==='NOTFOUND' ) {
			this.#closeMe();
			return;
		}
		this.#data[minor] = o;
		switch( minor ) {
			case 'tables':
			// case 'score':
				this.#score = o;
				let changed = false;
				for( let i = 0; i<o.length; i++ ) {
					let s = o[i],
						score = s.score;
					if( this.#putPlr( 'score', i, s.id ) ) changed = true;
				}
				if( this.#setBar( o.length? 'score' : 'seating' ) ) changed = true;
				if( changed ) this.#checkWaiting();
				break;

			case 'scorestr':
				this.#setScorestr( o );
				break;

			case 'seating':
				// Next round seating
				let [ _, round, seatstr ] = o.match( /(\d+)\:(.*)/ ),
					seating = seatstr.split( ',' );
				for( let i = 0; i<seating.length; i++ ) {
					let plr = seating[i];
					this.#putPlr( 'seating', i, plr );
				}
				this.#seatingRound = round;
				this.#checkWaiting();
				break;

			case 'players':
				// All team players. Show it in one line. Check who's visible every time
				let line = o.split( ',' ).map( x => x.split( ':' ) ),
					uins = line.map( x => x[0] );
				for( let p of line ) {
					if( p[0][0]==='+' ) {
						this.#hasPlaces = +p[0][1];
						continue;
					}
					this.#setPlayer( p[0], p[1] ?? null );
				}
				// Hide extracted members
				for( let [ k, plr ] of this.#allPlayers ) {
					if( !uins.includes( k ) ) {
						log( `Extracted player ${k} from participant ${this.team_id}` );
						plr.holder.hide();
						// delete this.#allPlayers[k];
						this.#needRelocate();
					}
				}
				this.#checkWaiting();
				break;
			case 'full':
				if( this.#withcaption )
					this.#totalElement?.$( '.name' ).setContent( this.#data.full?.name || '' );
				if( LOCALTEST )
					this.#totalElement?.$( '.name' ).setContent( 'Helpers Guys' );
		}
	}

	#currentDragOver = null;
	#dragInfo;
	#setCurrentDragOver( holder ) {
		if( holder===this.#currentDragOver ) return;
		if( this.#currentDragOver ) {
			let img = this.#currentDragOver.$( 'img' );
			if( img ) img.style.opacity = 'initial';
			this.#currentDragOver.style.outline = 'none';
		}
		this.#currentDragOver = holder;
		if( holder && holder!==this.#dragInfo.holder ) {
			let img = holder.$( 'img' );
			if( img ) img.style.opacity = '0.5';
			this.#currentDragOver.style.outline = '3px green solid';
			log( `Entered on place ${holder.dataset.place}` );
		}
	}

	#dragOver( e ) {
		let holder = e.target.closest( '[data-place]' );
		if( holder!==this.#currentDragOver ) return;
		if( holder )
			e.preventDefault();
	}

	#dragEnter( e ) {
		let holder = e.target.closest( '[data-place]' );
		if( holder===this.#currentDragOver ) return;
		this.#setCurrentDragOver( holder );
	}

	#dragLeave( e ) {
		if( e.target===this.#currentDragOver )
			this.#setCurrentDragOver();
	}

	#dragEnd() {
		// Check if we can put player from dragInfo to currentDragHolder
		if( this.#dragInfo )
			this.#dragInfo.element.style.filter = 'none';
		this.#setCurrentDragOver();
		this.#dragInfo = null;
		for( let el of this.$$( '.imagehead' ) )
			el.style.pointerEvents = 'initial';
	}

	#dragStart( e ) {
		let imagehead = e.target.closest( '.imagehead' );
		this.#dragInfo = {
			element: e.target,
			holder: imagehead?.parentElement,
			origin: e.target.dataset.origin,
			uin: e.target.dataset.origin.match( /user_(\d+)/)?.[1]
		};
		for( let el of this.$$( '.imagehead' ) )
			if( el!==imagehead ) el.style.pointerEvents = 'none';
		e.target.style.filter = 'grayscale(1) opacity(0.3)';
	}

	exchange( place, user ) {
		// 1. Store old one
		let bar = this.#bars[this.#currentBar],
			old = bar.players[place],
			oldplace = bar.players.indexOf( user );
		this.#putPlr( this.#currentBar, place, user );
		if( oldplace>=0 )
			this.#putPlr( this.#currentBar, oldplace, old );
		this.#checkWaiting();
	}

	async #drop() {
		if( this.#dragInfo && this.#currentDragOver ) {
			let place = this.#currentDragOver.dataset.place;
			this.exchange( place, this.#dragInfo.uin );
			// Need to lock drop place for other changing
			let old = this.#currentDragOver.$( 'data-origin' )?.dataset.origin.match( 'user_(\d+)' )?.[1];
			if( this.#currentBar==='score' ) {
				// Score view: send change request to server
				await elephCore.sendPlay( `type=event event=${this.#event_id} action=substitute teamid=${this.team_id} seatno=${place} user=${this.dragInfo.uin} olduser=${old}` );
				// if( !res?.ok ) return;
			} else if( this.#currentBar==='seating' ) {
				let s = [ ...this.#bars.seating.players];
				s[+place] = this.#dragInfo.uin;
				await API( `tour_setseating`, {
					tour_id: this.#event_id,
					participant_id: this.team_id,
					seating: s
				});
				// if( !res?.ok ) return;
			}
		}
	}

/*
	isValidDrop( place, user ) {
		// Checking valid drops:
		// 1. Score: only moving from reserve (not between tables)
		// 2. Never can left empty seats before users

	}
*/
}

customElements.define( 'neo-teambar', TeambarElement, {
	extends: 'div'
} );
