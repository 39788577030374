'use strict';

import './core.js';

class Socket {

}

// private
let url, lastSuccessUrl, lastFixedUrl,
	proxy = false, hosts = [], tryNo,
	timeLastSend, timeLastReceive,
	ws = null,
	prevsocket = null,
	reconnectTimer = null,
	nextCheck = null,
	reconnectSec = 1,
	waitingLogin,
	connections = 0;

function send( str, appendix ) {
	if( !ws || ws.readyState!==1 ) {
		log( 'SKIP>>> ' + str );
		return;
	}
	let fstr = str;
	if( fstr.slice( -1 )!=='\n' ) {
		// fstr += ' snt=' + (++sendcount);
		if( appendix ) fstr += ' ' + appendix;
		fstr += '\n';
	}
	log( '>>> ' + fstr );
	try {
		ws.send( fstr );
	} catch( e ) {
		log( 'Failed ' + JSON.stringify( e ) );
	}
	timeLastSend = Date.now();
	checkIdle( true );
}

function checkIdle( nosend ) {
	if( !nosend ) send( '' );
	if( nextCheck ) clearTimeout( nextCheck );
	nextCheck = setTimeout( checkIdle, 50000 );
}

function wsmessage( e ) {
	if( !ws ) return;
	if( ws!==e.target ) {
		return log( "Message from old socket?" );
	}
	timeLastReceive = Date.now();
	if( waitingLogin && e.data.includes( 'AUTH ' ) ) {
		// Пришла авторизация, сообщим что законнектились
		notifyConnected();
	}
	if( !proxy ) {
		fire( 'fromserver', e.data );
		delay( 'afterfromserver' );
	}
	maintimer();
}

function wsopen( e ) {
	log( "connected #" + (connections+1) + " to " + url );
	timeLastReceive = timeLastSend = Date.now();
	if( ws!==e.target ) return;
	// Подконнектились. Отправим информацию о себе
	if( proxy ) {
		ws.send( "proxymode\n" );
		return;
	}
	// Если есть строка авторизации, отправляем сначала её и дальше ждем ответа перед отправкой
	// дальнейших запросов (иначе можем не попасть на закрытые подписки)

	let tosend = { helo: 'GS ', lines: [] };
	fire( 'trysocketlogin', tosend );
	elephCore.isConnected = true;
	reconnectSec = 1;
	connections++;
	lastSuccessUrl = url;
	lastFixedUrl = url;
	checkIdle( true );
	if( window.socketLoginStr ) {
		send( window.socketLoginStr );
		waitingLogin = true;
		return;
	}
	notifyConnected();
}

function notifyConnected() {
	waitingLogin = false;
	eventStatus( 'connected', true );
	fire( 'connected' );
}

function wserror( e ) {
	// Задать реконнект через 5 сек
	if( !ws ) return;
	if( ws!==e.target ) {
		return log( "ERROR with old socket?" );
	}
	log( "ws error " + url );
	tryhost( 'next' );
	eventStatus( 'connected', false );
	disconnected();
}

function wsclose( e ) {
	// Соединение утеряно
	if( !ws ) return;
	if( ws!==e.target ) {
		if( ws===prevsocket ) return;
		return log( "CLOSE with old socket?" );
	}
	if( e.wasClean )
		log( "ws closed clean" );
	else
		log( "ws close abnormal err " + e.code + ", reason " + e.reason );
	// Попробуем сразу переключиться на другой хост, если он есть
	if( hosts ) {
		tryhost( 'next' );
	}
	disconnected();
}

function disconnected( retry ) {
	if( !ws ) {
		log( 'Disconnected ' + url + ' without socket. Will we reconnect?..' );
		return;
	}
	if( retry===undefined ) retry = true;
	timeLastSend = 0;
	prevsocket = ws;
	ws = null;
	if( elephCore.UNLOAD ) retry = false;
	elephCore.isConnected = false;
	waitingLogin = false;
	fire( 'disconnected' );
	if( retry && !document.hidden ) {
		// Если есть другие хосты на пробу, сначала опросим их, и только затем сделаем паузу
		if( lastFixedUrl && url!==lastFixedUrl ) {
			log( 'Fast try other hosts ' + url );
			setTimeout( reconnect, 50, "fast" );
			return;
		}
		// Нет других или все ответили отказом. В следующий раз будем начинать с начала
		tryhost( 0 );
		lastFixedUrl = url;
		log( "Try reconnect " + url + " in " + reconnectSec + "s..." );
		reconnectTimer = setTimeout( reconnect, reconnectSec * 1000, "reconnect" );
		reconnectSec *= 2;
		if( reconnectSec>10 ) reconnectSec = 10;
	} else {
		clearTimeout( reconnectTimer );
		reconnectTimer = null;
	}
}

function tryhost( no ) {
	if( !hosts ) return;
	if( no==='next' ) {
		no = (tryNo+1)%hosts.length;
		log( 'Switching to host ' + hosts[no] );
	}
	if( !hosts[no] ) return;

	url = hosts[no];
	tryNo = no;
	lastFixedUrl ||= url;
}

function reconnect( reason ) {
	if( elephCore.UNLOAD ) return;
	reconnectTimer = null;
	if( ws ) return;			// Уже коннектимся
	if( !navigator.onLine ) {
		return log( 'No reconnect. Offline. ' );
	}
 	if( !reason && (elephCore.paused || !document.hasFocus()) ) {
		return log( 'No timer reconnect because paused' );
	}
	log( `reconnect ${url}: ${reason}` );
	try {
		ws = new WebSocket( url, 'simpleplay' );
	} catch( e ) {
		log( 'Connection to ' + url + ' failed' );
	}
	if( !ws ) return;
	timeLastSend = Date.now();
	ws.onmessage = wsmessage;
	ws.onopen = wsopen;
	ws.onerror = wserror;
	ws.onclose = wsclose;
}

function beforeclosedoc() {
	elephCore.UNLOAD = true;
	disconnect( "Before close doc" );
}

window.dispatch( 'onpause', () => {
	// disconnect( 'Paused', false );
} );

window.dispatch( 'onresume', () => {
	if( !url ) return;
	if( reconnectTimer ) {
		clearTimeout( reconnectTimer );
		reconnectTimer = null;
		reconnectSec = 1;
	}
	if( ws ) {
		if( timeLastReceive && Date.now()-timeLastReceive > 1000*30 ) {
			disconnect( 'Resuming (no msg 30sec)', true );
		}
		return;
	}
	reconnect( "Resume" );
} );

function disconnect( reason, retry ) {
	if( !ws ) return;
	log( "Disconnect: " + reason );
	if( ws.readyState<=WebSocket.OPEN ) {
		elephCore.do( 'type=bye' );
//			send( "close" )
//            try {
		ws.close( 1000, reason );
//            } catch(e) {}
	}
	disconnected( retry );
}

/*
function closedoc() {
	log( "Close document" );
}
*/

function maintimer() {
	if( !ws ) return;

	// Если сокет не соединен
	if( ws.readyState===WebSocket.CONNECTING ) {
		// Если соединяется уже 10 секунд, рубим его (непонятная ошибка).
		if( Date.now()>timeLastSend + 10_000 )
			disconnect( "Connection timeout", true );
		return;
	}
	// Если от сервера не было ничего 5 минут,
	// оборвем соединение
	if( timeLastReceive && Date.now()>timeLastReceive + 1000 * 60 ) {
		disconnect( "no messages 1 minute", true );
	}
}

window.dispatch( 'ready', function() {
	let ssl = window.location.protocol==='https:',
		proto = ssl ? 'wss:' : 'ws:';
	let server = elephCore.params['server'],
		hostname, port;
	if( server ) {
		hostname = server;
	} else {
		// hostname = 'wss://www.' + DOMAIN + '/_connect';
		if( window.EXTERNALDOMAIN )
			hostname = `wss://${DOMAIN}/_connect`;
		else if( DOMAIN.includes( 'ru' ) )
			hosts = [ 'm', 'm1', 'm2', 'm3', 'm4' ].map( x => 'wss://' + x + '.' + DOMAIN );
		else
			hostname = `wss://www.${DOMAIN}/_connect`;
	}

	if( location.href.includes( 'localhost:' ) ) {
		hosts = null;
		hostname = 'ws://127.0.0.1:1338';
		// hostname = 'wss://www.fantgames.com/_connect';
		// hostname = 'wss://m1.gambler.ru';
		// hosts = [ 'no', 'a', 'm3', 'yes', 'c' ].map( x => 'wss://' + x + '.gambler.ru' );
	}

	url = hostname;
	tryhost( 0 );
	log( 'Initialized url ' + url );
	reconnect( 'init' );
	if( DEBUG===2 ) maintimer();
	// window.addEventListener( "unload", closedoc, false );
	window.addEventListener( "beforeunload", beforeclosedoc, false );
	//			if( DEBUG==2 ) Parser.parse_str( "<gs><about><club>100</club></about></gs>" )
} );

window.dispatch( 'toserver', send );
window.dispatch( 'maintimer', maintimer );

window.addEventListener( 'online', event => {
	log( 'Online event received' );
	reconnect( 'event online' );
});

window.WEBSOCKET = Socket;