import Card from './card.js';

window.cardResizeObserver ||= window.ResizeObserver && new ResizeObserver( entries => {
	for( let entry of entries )
		entry.target.resizeObserve( entry );
} );

const defaultSuitSort = 'shdc';

const LOGLEVEL = DEBUG;

//export default function( this.game, strid, opt ) {

function cmprange( c1, c2 ) {
	if( !c1 ) return 1;
	if( !c2 ) return -1;
	return c2.range - c1.range;
}

function isBad( number ) {
	return !Number.isFinite( number ) || Number.isNaN( number );
}

class Cardholder {
	#ordered = [];
	#needstriporder;
	#handpos;
	#multiLine;
	#pixelsBetweenSuits;
	#countClosed;
	#countVisible;
	#shadowed;
	#myRect; #lastRect;
	#myTop;
	#myLeft;
	#baseCardHeight; #baseCardWidth;
	#cardScale;
	#cardWidth;
	#cardHeight;
	#origin;
	#style = {};
	#recheckSizeTimeout;
	#ranges = new Array( 15 );
	#repeats = new Array( 5 );
	#onesuit = false;
	#hirange = 1;
	#lowrange = 15;
	#sort;
	#countSuits;
	#maxInSuit;
	#countInSuit;
	#presentSuits;
	#orderSuits;
	#subelem;
	#applyChangesBind = this.#applyChanges.bind( this );
	#onresizeBind = this.#onresize.bind( this );
	#setPositionsBind = this.#setPositions.bind( this );
	#lastUnified;
	#cardBody;
	#translateCorrection;
	#maxSetWidth;

	constructor( game, strid, opt ) {
		game.cards ||= Card.makePack( game );

		this.holder = document.createElement( 'div' );

		cardResizeObserver?.observe( this.holder );

		this.game = game;
		this.id = strid.toString();
		this.position = null;
		this.options = { ...opt };
		this.#countInSuit = {};
		this.#countVisible = 0;
		this.#countClosed = 0;
		this.#needstriporder = false;
		game.allCardHolders ||= new Set;
		game.allCardHolders.add( this );
		for( let k in opt ) this[k] = opt[k];
		this.#sort = {
			style: opt.sort,
			type: opt.sortType || 'suit'
		}
		this.#orderSuits = this.makeSuitSort();

		let cname = opt['className'] ? 'cardholder_' + opt['className'] : 'cardholder';
		if( opt['classes'] ) cname += ' ' + opt['classes'];
		this.holder.className = cname + ' empty';
		this.holder.dataset.cardholderid = this.id;
		this.holder.canDrop = function() {
			return !this.maxVisible || (this.#countVisible<this.maxVisible)
		};
		if( this.isHand )
			this.holder.dataset.maxcount = game.handCount;
		this.#lastUnified = [ 0, 0 ];

		this.holder.onDrop = ( card, z ) => {
			if( this.#ismine( card ) ) return;
			this.appendone( card, {
				z: z,
				pos: 'fillspot'
			} );
			this.changedContent( 'addone' );
		};

		dispatch( 'newdeck', e => {
			if( !this.#checkCardWidth( true ) ) return;
			this.changed( 'newdeck' );
		} );

		dispatch( 'recheckcardholder', () => {
			if( this.scale!=='holder' ) return;
			this.#verifyRect();
		} );

		dispatch( 'optionchanged', o => {
			if( o.name==='trumpleft' && +this.id>=0 && this.game.isbridge ) {
				this.#dosort();
				this.#setPositions();
			}
		} );

		this.holder.resizeObserve = entry => {
			let rect = this.sizeRect = entry.contentRect;

			if( !rect.width && !rect.height ) return;
			// if( LOCALTEST ) {
				// log( `ROBS ${JSON.stringify( this.sizeRect)}==${JSON.stringify(this.holder.getBoundingClientRect())}`)
			// }
			this.#onresize( 'observe' );
		}

		this.holder.cardHolder = this;
		game.addResizeListener( this.gameResize.bind( this ) );

		if( +this['scale'] ) game.cards.addResizeUnified( this.#resizeUnified.bind( this ) );
	}

	#checkRotate() {
		let ml = false;
		if( this.options.multiline || ( this.resizeIfSide && this.orientation==='side' && this.#ordered.length ) ) {
			// ml = this.game.cards['unifiedWidth']*8 < this.game.holder.clientWidth;
			ml = true;
		}
		// Narrow screen (portrait) and 13 card (bridge) forces multiline as well
		// ! Only for backcolor. Or if option is ON
		if( this.getDeck==='backcolor' && this.isHand && this.game.handCount===13 && narrowMedia.matches ) {
			ml = true;
		}
		if( this.#multiLine===ml ) return;
		this.#multiLine = ml;
		if( this.options.narrowScale )
			this.scale = narrowMedia.matches ? this.options.narrowScale : null;
		return this.#checkSize( 'checkRotate' );
	}

	get getDeck() {
		return this.game.cards?.deck || Card.deck;
	}

	get isDomino() {
		return this.game.isdomino;
	}

	get #cardsRotated() {
		return this.#multiLine && this.isDomino;
	}

	makeSuitSort( ls, bridge ) {
		if( this.isDomino ) return '0123456789ABCDEFGH';
		let
			// order = bridge? Card.deck.match( /backcolor$/ ) && 'cdhs' || defaultSuitSort : 'hcds',
			def = this.getDeck==='backcolor' ? 'shdc' : defaultSuitSort,
			order = bridge ? def : 'hcds',
			trumpno = ls && order.indexOf( ls ) || 0,
			res = [];
		if( trumpno<0 ) trumpno = 0;
		for( let n = 4; n--; ) res[n] = (order.indexOf( 'scdh'[n] ) + 4 - trumpno) % 4;
		return res;
	}

	checkEmpty() {
		this.holder.classList.toggle( 'empty', !this.#ordered.length && (this.isDomino || !this.#countClosed) );
	}

	get isInvisible() {
		return !this.#ordered.length && (!this.#countClosed || (this.isHand && this.game.isClosedHandsInvisible ));
	}

	#checkSize( reason ) {
		if( LOGLEVEL )
			log( `#checkSize ${this.id} (ordered=${this.#ordered.length} closed=${this.#countClosed} inv=${this.isInvisible}: ${reason}` );
		this.checkEmpty();
		if( this.scale==='holder' ) return;

		if( this.#countClosed ) {
			if( this.isDomino || this.isInvisible )
				return this.#setDivSize( 0, 0 );
			if( this.orientation==='side' ) {
				let h = this.game.cards['unifiedHeight'];
				if( !h ) return;
				let w = h * 3 / 4, cc = Math.min( 10, this.#countClosed );
				w += w * 0.025;
				let hh = h + h * (cc - 1) * 0.1829 + h * 0.025;
				// if( hh>h * 2 ) hh = h * 2;
				return this.#setDivSize( w, hh );
			} else {
				// if( !basecardHeight ) log( 'basecardHeight not ready for ' + this.id );
				return this.#setDivSize( undefined, this.#baseCardHeight );
			}
		}

		if( this.orientation==='side' && this.isDomino ) {
			return this.#setDivSize( this.game.cards.unifiedWidth * 2, undefined );
		}

		if( !this.#ordered.length && this.isPlayer /*&& this.orientation==='side'*/ )
			return this.#setDivSize( 0, 0 );

		let height;
		if( this.#multiLine ) {
			// Обычный мультистрочный
			let onew = /*this.#cardWidth ||*/ this.game.cards.unifiedWidth,
				// max = onew * (1 + (this.#maxInSuit - 1) * .6);
				max = onew * this.#maxInSuit;
			if( this.isHand ) {
				// В бридже ставим ширину 6 карт на пробу, в преферансе - 4
				// Лучше не уменьшать ширину контейнера во время игры, этого достаточно
				// Во время редактирования или не во время игры значения не имеет
				// let widefix = { 13: 6, 10: 4 }[game.handCount];
				if( max < this.#maxSetWidth && this.game.inprogress ) {
					max = this.#maxSetWidth;
				}
				// let widefix = { 13: 6, 10: 4 }[this.game.handCount];
				// if( widefix ) max = onew * widefix;
				// Если используются ненакладываемые карты (backcolor)
				// стараемся сделать размер холдера адаптивным по количеству карт
/*
				// Нельзя использовать #cardBody, так как он вычисляется позже,
				// на базе размеров, заданных здесь. Создается бесконечный цикл
				if( this.#cardBody ) {
					let width = onew + this.#cardBody.sepWidth * (this.#maxInSuit - 1);
					// if( this.getDeck==='backcolor' ) {
					max = width;
					// }
				}
*/
				const wmax = {
					side: { 10: 4, 13: 6 },
					center: { 10: 6, 13: 7 }
				}
					let widemax = ( wmax[this.orientation] || wmax.center )[this.game.handCount] * onew;
				if( max>widemax )
					max = widemax;
				let lines = 4;
				if( this.orientation!=='side' ) lines = this.#countSuits;
				height = lines * ( this.#cardHeight || this.game.cards.unifiedHeight );
			}
			return this.#setDivSize( max, height );
		}

		// В одну линию
		if( this['capacity'] )
			return this.#setDivSize( this.game.cards.unifiedWidth * this['capacity'], this.#baseCardHeight || this.game.cards['unifiedHeight'] );

		return this.#setDivSize( undefined, this.#baseCardHeight || this.game.cards['unifiedHeight'] );
	}

	gameResize() {
		// Game area resized. Update width/height
		if( !this.#checkSize() ) {
			// If #checkSize() returns true then resize will be called by observer
			this.#onresize( 'gameresize' );
		}
	}

	sizeControl( reason ) {
		return this.#onresize( reason || 'sizecontrol' );
	}

	#onresize( reason ) {
		// Если объект-родитель позиционируется абсолютно, то мы подстариваемся под его размеры, а затем
		// применяем его transform
		if( this.game.isPreview ) return;
		if( LOCALTEST ) log( `cardholder ${this.id} onresize ${reason}` );
		if( reason==='recheck' ) {
			if( this.#recheckSizeTimeout ) {
				clearTimeout( this.#recheckSizeTimeout );
				this.#recheckSizeTimeout = null;
			}
		}
		let newRect = this.holder.getBoundingClientRect(),
		// let newRect = reason==='observe' ? this.sizeRect : this.holder.getBoundingClientRect(),
			issame = this.#lastRect?.width===newRect.width && this.#lastRect?.height===newRect.height
				&& this.#lastRect.left===newRect.left && this.#lastRect.top===newRect.top;

		if( this.game.cards.unifiedWidth - this.#lastUnified[0] + this.game.cards.unifiedHeight - this.#lastUnified[1] ) issame = false;
		// Same rect, check card this.orientationif( reason==='pokerlayout' && LOCALTEST ) console.warn( 'Check poker layout' );
/*
	commented 02/09/24
		if( !this.#checkRotate() && issame ) {
			// log( `${this.id} same rect` );
			return;
		}
*/
		this.#lastUnified = [ this.game.cards.unifiedWidth, this.game.cards.unifiedHeight ];
		this.#myRect = null;
		this.setMinWidth();

/*
commented 02/09/24
		this.#checkSize( 'onresize' );
*/
		// resizeClosed();

		// let r = this.holder.getBoundingClientRect();
		// this.changed( 'resize:' + r.width + 'x' + r.height );

		this.#lastRect = newRect;
		delay( this.#putContentBind );

/*
		if( !LOCALTEST ) {
			// Через секунду проверим всё ли правильно получилось
			// В отладке стараемся добиться того, чтобы всегда сразу было правильно
			this.recheckSizeTimeout = setTimeout( () => {
				this.#onresize( 'recheck' )
			}, 1000 );
		}
*/
		return true;
	}

	setMinWidth() {
		if( +this.scale && this.orientation==='side' )
			this.holder.style.minWidth = this.game.cards.unifiedWidth + 'px';
	}

	setBaseCardSize() {
		let scale = this.calcScale();
		this.#baseCardWidth = this.game.cards['unifiedWidth'] * scale;
		this.#baseCardHeight = this.game.cards['unifiedHeight'] * scale;
		// log( this.id + ' baseCardSize: ' + baseCardWidth + 'x' + basecardHeight );
	}

	cmpsuit( c1, c2 ) {
		if( !c1 ) return 1;
		if( !c2 ) return -1;
		return (this.#orderSuits[c1.suit] - this.#orderSuits[c2.suit]) || (c2.range - c1.range);
	}

	#cmppokerBind = this.#cmppoker.bind( this );
	#cmppoker( c1, c2 ) {
		if( !c1 ) return 1;
		if( !c2 ) return -1;
		let tmp = this.#ranges[c2.range] - this.#ranges[c1.range];
		if( tmp ) return tmp;
		return c2.range - c1.range;
	}

	#checkranges() {
		for( let i = 15; i--; ) this.#ranges[i] = 0
		for( let i = 5; i--; ) this.#repeats[i] = 0
		this.#onesuit = true;
		this.#hirange = 1;
		this.#lowrange = 15;
		let lastsuit = -1;
		this.#ordered.forEach( v => {
			if( v && this.#ismine( v ) ) {
				const c = this.#ranges[v.range];
				if( c ) this.#repeats[c]--;
				this.#ranges[v.range]++;
				this.#repeats[c + 1]++;
				if( v.range>this.#hirange ) this.#hirange = v.range;
				if( v.range<this.#lowrange ) this.#lowrange = v.range;
				if( this.#onesuit && lastsuit>=0 && lastsuit!==v.suit ) this.#onesuit = false;
				lastsuit = v.suit
			}
		} )
	}

	sortpoker() {
		// Сначала посчитаем количество повторений
		this.#checkranges();
		this.#ordered.sort( this.#cmppokerBind )
	}

	getPokerComb() {
		// Вычисляем покерную комбинацию
		this.#checkranges();
		let c = Poker.HIGH,
			ranges = this.#ranges,
			repeats = this.#repeats;
		const straight = repeats[1]===5 && ((this.#hirange - this.#lowrange===4) ||
			(ranges[2] && ranges[3] && ranges[4] && ranges[5] && ranges[14]));

		if( straight && this.#onesuit ) {
			if( this.#hirange===14 ) c = Poker.ROYAL;
			else c = Poker.FS;
		} else if( repeats[4] ) c = Poker.FOUR;
		else if( repeats[3] && repeats[2] ) c = Poker.FULL;
		else if( this.#ordered.length===5 && this.#onesuit ) c = Poker.FLUSH;
		else if( straight ) c = Poker.STRAIGHT;
		else if( repeats[3] ) c = Poker.SET;
		else if( repeats[2]===2 ) c = Poker.TWOPAIRS;
		else if( repeats[2] ) c = Poker.PAIR;
		return { comb: c, range: this.#ordered[0] ? this.#ordered[0].range : 2 }
	};

	changedContent( reason ) {
		this.changed( reason );
		this.onChange?.( this );
	}

	#applyChanges() {
		if( cssMustWait( 'cards', this.#applyChangesBind ) ) {
			if( LOGLEVEL )
				log( `Apply ${this.id}: css` );
			return;
		}
		if( LOGLEVEL )
			log( `Apply ${this.id}: ${this.makeStr()}` );
		if( this.#needstriporder )
			this.#stripOrdered();
		if( this.#sort.style==='none' ) {
			// log( this.id + ' sorting default...' );
			for( let i = 0; i<this.#ordered.length; i++ )
				if( this.#ordered[i] )
					this.#ordered[i].style.zIndex = this.#ordered[i].dataset['z'] = i + 1;
		}
		if( this.#reviseSize( 'apply' ) ) {
			return;
		}
		delay( this.#putContentBind );
	}
/*
		if( this.#checkSize( 'apply' ) ) {
			// Later onresize will call everything
			return;
		}
*/
	#putContentBind = this.#putContent.bind( this );
	#putContent() {
		if( this.#setPositions()===false ) return;
		this.changeReason = '';
		this.checkBottomWidth();
		let wasopened = this.holder.classList.contains( 'opened' );
		this.holder.classList.toggle( 'opened', this.#ordered.length>0 );
		if( wasopened!==(this.#ordered.length>0) ) {
			this.#sendEvent( 'show', {
				value: this.#ordered.length>0
			} );
			this.getFrontSide()?.changed( 'showhide opposite' );
		}
		this.onchange?.( this );
	}

	#dosort( newSortType ) {
		// Пока просто задаем координаты всем объектам
		if( newSortType ) {
			if( newSortType==='range!sort' ) this.#sort.type = this.#sort.type==='suit' ? 'range' : 'suit';
			else if( newSortType==='range!poker!sort' ) this.#sort.type = this.#sort.type==='suit' ? 'range' :
				(this.#sort.type==='range' ? 'poker' : 'suit');
			else this.#sort.type = newSortType;
		}
		switch( this.#sort.type ) {
			case 0:
				return;
			case 'range':
				this.#ordered.sort( cmprange );
				break;
			case 'suit':
				// В бридже применим сортировку "козырь слева", если задана настройка
				let bridge = this.game.isbridge,
					trumpleft = elephCore?.globalAttr.trumpleft || false,
					ss = bridge && +this.id>=0 && trumpleft && this.game.contract?.bid[0];
				this.#orderSuits = this.makeSuitSort( ss, bridge );
				this.#ordered.sort( this.cmpsuit.bind( this ) );
				for( let c of this.#ordered ) this.checkcardZ( c );
				break;
			case 'poker':
				this.sortpoker();
				break;
		}
	}

	#ismine( card ) {
		return card?.owner===this;
	}

	#stripOrdered() {
		// if( !needstriporder ) return;
		let neworder = [],
			suit = undefined, insuit = 0;
		this.#countSuits = this.#maxInSuit = 0;
		this.#countInSuit = {};
		let sc = this.#countInSuit;
		for( let i = 0; i<this.#ordered.length; i++ ) {
			let c = this.#ordered[i];
			if( c===null && !this.options.keepSpot ) continue;
			if( c && !this.#ismine( c ) /* || !c.visible */ ) continue;
			neworder.push( c );
			if( !c ) continue;
			let suit = c.suit;
			if( !sc[suit] ) {
				sc[suit] = 0;
				sc['scdh'[suit]] = 0;
				this.#countSuits++;
			}
			let insuit = sc[suit] + 1;
			sc[suit] = insuit;
			sc['scdh'[suit]] = insuit;
			if( insuit>this.#maxInSuit ) this.#maxInSuit = insuit;
		}
		this.#ordered = neworder;
		this.#needstriporder = false;
		this.str = this.makeStr();
	}

	#setPositions_line( pos, lineno, options ) {
		if( lineno>10 ) {
			if( LOCALTEST ) debugger;
			return;
		}
		// let test = this.id;
		if( !this.#ordered.length || this.#ordered.length===pos ) return;
		if( !this.#cardBody.height ) return; // No heights means dimensions are not ready
		let left = this.#cardBody.left,
			top = this.#cardBody.top,
			lastsuit,
			groupbysuit = !this.isDomino;
		// rotateDir = this.#cardBody.rotateDir;

		// Try to synchronize right and left hands if some suits are absent
		// presentsuits string contains dots where suit is in right or left hand
		let nextsuit = +(this.#presentSuits?.[lineno] || this.#orderSuits[lineno]);
		if( this.#presentSuits && this.#multiLine && groupbysuit && this.#ordered[pos].suit!==nextsuit ) {
			// Skip this line
			return pos;
		}

		let [cardw, cardh] = this.#cardsRotated ? [this.#getRotatedCardWidth, this.#getRotatedCardHeight || this.#cardWidth] : [this.#cardWidth, this.#cardHeight];

		if( lineno && this.countLines()>1 ) {
			let stepy = (this.#cardBody.height - cardh) / (this.countLines() - 1);
			if( stepy>cardh && this.isDomino ) stepy = cardh;
			if( this.#cardBody.minstepline && stepy>this.#cardBody.minstepline )
				stepy = this.#cardBody.minstepline;
			top += lineno * stepy;
		}

		let count = this.#ordered.length - pos,
			countBlocks = this.countLines();
		if( this.#multiLine ) {
			if( groupbysuit )
				count = this.#countInSuit[this.lineSuit?.[lineno] || this.#ordered[pos].suit]
			else
				count = 1;
			countBlocks = 1;
		}
		if( count===undefined )
			count = this.#ordered.length - pos;
		if( this.maxPerline>0 && count>this.maxPerline ) count = this.maxPerline;
		if( !count )
			return pos;

		let stepx = this.#cardBody.sepWidth;
		// Try unbalanced cards when side card
		if( this.#multiLine ) {
			if( stepx<this.#cardWidth && count*this.#cardWidth<=this.#myRect.width && LOCALTEST )
				stepx = this.#cardWidth;
		}

		let lineWidth = cardw + stepx * (count - 1) + this.#pixelsBetweenSuits * (countBlocks - 1);

		// if( this.#cardBody.superPosition==='max' )
		// 	lineWidth = this.#cardBody.stepPix*count;

		if( this.getalign==='r' ) {
			left = this.#cardBody.clientWidth - lineWidth;
			if( isNaN( left ) ) {
				left = this.#cardBody.clientWidth - cardw * count;
				bugReport( `Right cardholder: id=${this.id} lineWidth=${lineWidth} game=${this.game.gameInfo.type} this.#cardBody=${JSON.stringify( this.#cardBody )} count=${count} blocks=${countBlocks} cardw=${cardw} this.#cardWidth=${this.#cardWidth} pxlB=${this.#pixelsBetweenSuits}, newleft=${left}` );
				if( isNaN( left ) ) return;
			}
			// log( `Line ${lineno} left: ${left}, clientWidth=${this.#cardBody.clientWidth} stepPix=${this.#cardBody.stepPix}` );
		}
		// if( countBlocks>1 ) {
		// Можно ли показывать масти с разделениями
		// if( this.#cardWidth*countBlocks + this.#pixelsBetweenSuits*(countBlocks-1) + (count-countBlocks )
		// }

		let c = 0, i = pos, shift = (cardh - cardw) / 2;
		let shifty = -shift,
			superpos = this.#cardBody.superPosition;

		for( ; i<this.#ordered.length && c<count; i++ ) {
			// if( !this.#ordered[i].visible ) continue;
			let card = this.#ordered[i];
			if( card ) {
				let newsuit = lastsuit!==undefined && lastsuit!==card.suit;
				if( newsuit ) {
					if( this.#multiLine ) break;
					left += this.#cardBody.sepSuitWidth - this.#cardBody.sepWidth;
				}
				// resizeOneCard( card );
				// if( LOCALTEST )
				// 	log( `CH ${this.id} try animate ${card.str} originTop=${originTop} top=${top} lineno=${lineno} countLines=${this.countLines()} this.#cardHeight=${this.#cardHeight} this.orientation=${orientation} prsuits=${presentSuits} cardScale=${cardScale}` );
				if( !this.#cardScale )
					doAnimate( card, ((this.#origin.left + left) / this.#cardWidth * 100).toString() + '%',
						((this.#origin.top + top) / this.#cardHeight * 100).toString() + '%', options, this.#cardScale );
				else
					doAnimate( card, this.#origin.left + left, this.#origin.top + top, options, this.#cardScale );

				if( superpos && this.isHand ) {
					// "mark" detects last card in the row
					// last card in the row should keep visible suit itself (others can has no suit)
					let mark = !this.#ordered[i + 1] || this.#ordered[i + 1].suit!==card.suit,
						sp = superpos;
					if( mark && this.getDeck!=='backcolor' )
						sp = this.orientation==='central' ? 'hor' : 'top';
					card.setAttribute( 'superposition', sp || 'def' );
				} else
					card.removeAttribute( 'superposition' );
				card.classList.toggle( 'sidehand', this.isHand && this.#multiLine );
				if( this.#cardBody.superPosition==='max' && c===count - 1 && this.#multiLine )
					card.style.clipPath = `inset( 0 ${this.#cardWidth - this.#cardBody.stepPix}px 0 0 round 0 5px 5px 0 )`;
				else
					card.style.clipPath = '';
				// Not need to show the card. Only after animation
				// card.show();
				lastsuit = card.suit;
				if( this.isDomino ) {
					card.classList.toggle( 'rotated', this.#cardsRotated );
					// card.style.width = cardw + 'px';
					// card.style.height = cardh + 'px';
				}
			}
			left += stepx;
			this.#countVisible++;
			c++;
		}
		return i;
	}

	checkLayout() {
		this.#handpos = this.game.players[this.plno]?.position;
	}

	#checkCardWidth( force ) {
		if( force ) this.#cardWidth = undefined;
		// if( !this.#cardHeight ) setScale();
		if( this.#cardWidth || !this.#cardHeight ) {
			if( LOGLEVEL ) log( `checkcardwidth ${this.id}: cw(${this.#cardWidth})>0 || !ch(${this.#cardHeight})` );
			return;
		}
		let anycard = this.#ordered.find( x => !!x );
		if( !anycard ) {
			if( LOGLEVEL ) log( `checkcardwidth ${this.id}: no cards` );
			return;
		}
		let newval = this.#cardHeight * anycard.cardRatio;
		if( this.#cardWidth===newval ) {
			if( LOGLEVEL ) log( `checkcardwidth ${this.id}: same val ${newval}` );
			return;
		}
		this.#cardWidth = newval;
		if( LOGLEVEL )
			log( `checkcardwidth ${this.id}: OK ${this.#cardWidth}` );
		this.#translateCorrection = { x: 0, y: 0 };
		// Y coordinate
		if( this.#cardScale && !this.#multiLine ) {
			// translateXcorrection = -(this.#cardWidth * (1 / cardScale - 1) / 2);
			// translateYcorrection = -(this.#cardHeight * (1 / cardScale - 1) / 2);
		}
		this.#origin = {
			left: this.#myLeft + this.#translateCorrection.x,
			top: this.#myTop + this.#translateCorrection.y
		}

		// Suit split
		if( this.game.gameInfo.type==='domino' )
			this.#pixelsBetweenSuits = 0;
		else {
			this.#pixelsBetweenSuits = this.options.suitsplit || 0;
			if( this.#pixelsBetweenSuits===true )
				this.#pixelsBetweenSuits = this.#cardWidth * 0.15;
		}
		return true;
	}

	calcScale( h ) {
		if( !h ) h = this.game.cards.unifiedHeight;
		if( h===undefined ) return undefined;
		switch( this.scale ) {
			case 'half':
				let ch = this.holder.parentElement.clientHeight / 2;
				return ch / h;
			case 'holder':
				return this.#myRect?.height / h || this.holder.clientHeight / h;
		}
		return this.scale || 1;
	}

	#setScale() {
		if( LOGLEVEL ) log( `setScale ${this.id}:` );
		if( !this.#myRect.width ) {
			if( LOGLEVEL ) log( `setScale ${this.id}: myRect.width(${this.#myRect.width}) is null and myRect.height=${this.#myRect.width}` );
			return;
		}
		this.checkLayout();
		let lines = this.#multiLine ? 4 : 1;
		// Calculate maximum step for all object to fetch holder
		let cardScale = this.scale;
		if( cardScale ) {
			cardScale = this.calcScale();
			// if( this.#multiLine ) cardScale = cardScale * 2 / 3 ;
		} else {
			let h = this.#myRect.height / lines;	// 4 - count of suits, correct is game.cards.countSuits
			if( h<this.game.cards.unifiedHeight )
				cardScale = h / this.game.cards.unifiedHeight;
			if( !this.#multiLine ) {
				// Cards in line with between-suit separator
				let w = this.#myRect.width - (this.#countSuits - 1) * this.#pixelsBetweenSuits,
					wc = w / (this.#ordered.length || 1),
					xscale = wc / this.game.cards.unifiedWidth;
				// if( LOCALTEST ) xscale *= 2;
				if( xscale<1 ) {
					if( !cardScale || xscale<cardScale ) cardScale = xscale;
				}
			}
		}

		// if( self['cardScale'] ) cardScale = self['cardScale'];
		this.#cardHeight = this.game.cards.unifiedHeight * (cardScale || 1);
		this.#cardScale = cardScale;
		// this.#cardWidth = this.game.cards.unifiedWidth * (cardScale || 1);
		// Should check this.#cardHeight to avoid endless loop
		// if( this.#cardHeight )
		this.#checkCardWidth( true );
	}

	get #getRotatedCardHeight() {
		return this.game.cards.rotatedSize?.height * (this.#cardScale || 1) || this.#cardWidth;
	}

	get #getRotatedCardWidth() {
		return this.game.cards.rotatedSize?.width * (this.#cardScale || 1) || this.#cardHeight;
	}

	updateStyle( name, value ) {
		if( this.#style[name]===value ) return 0;
		this.#style[name] = value;
		this.holder.style[name] = (+value)>=0 ? (value + 'px') : '';
		return true;
	}

	#setDivSize( w, h ) {
		if( this.options.noresize ) return;
		if( LOGLEVEL )
			log( `#setDivSize ${this.id}: ${w}x${h}` );
		if( this.updateStyle( 'width', w ) + this.updateStyle( 'height', h ) ) {
			this.#myRect = null;
			// this.changed( 'setsize:' + w + 'x' + h );
			log( `setsize ${this.id}:` + w + 'x' + h );
			// Maybe we don't need this delay, because onresize observer will come
			delay( this.#putContentBind );
			this.#sendEvent( 'resize' );
			if( w > this.#maxSetWidth )
				this.#maxSetWidth = w;
			return true;
		}
	}

	#sendEvent( type, add ) {
		this.game.dispatchEvent( new CustomEvent( "cardholder", {
			detail: {
				type: type,
				plno: this.plno,
				...add
			}
		} ) );
	}

	#reviseSize() {
		this.setBaseCardSize();
		if( this.#checkRotate() ) return true;
		if( this.#checkSize( 'reviseSize' ) ) {
			// this.changed( 'newsize:' + this.#style['width'] + 'x' + this.#style['height'] );
			log( `newsize ${this.id}: ` + this.#style.width + 'x' + this.#style['height'] );
			return true;
		}
	}

	#setPositions( options ) {
		// let lastMax = maxInSuit;
		// self.id; // Keep it for correct breakpoints like self.id==='talon'
		this.lineSuit = null;
		if( cssMustWait( 'cards' ) ) {
			if( LOGLEVEL )
				log( `CSSwait ${this.id}` );
			return;
		}
		if( this.options.waituntilready ) {
			// No positioning cards without transform of parent holder (poker)
			if( LOGLEVEL )
				log( `CH ${this.id}: waituntilready` );
			return;
		}
		this.#stripOrdered();
		if( this.#isneedsort ) this.#dosort();

		// #checkRect calculates member #myRect
		this.#checkRect();

		this.#setScale();
		if( this.#ordered.length ) {
			// checkCardWidth();			// Still not defined
			if( !this.#cardWidth || !this.#myRect ) {
			// if( !this.#myRect ) {
				if( LOGLEVEL ) {
					log( `CH ${this.id}: cardWidth=${this.#cardWidth} || myrect=${!!this.#myRect} ` );
				}
				return;
			}
/*
commented 02/09/24
			if( this.#reviseSize() ) {
				if( LOGLEVEL ) log( `CH ${this.id} resized` );
				return false;
			}		// Resized, wait again
*/

			log_start( 'SetPositions ' + this.id + (this.#multiLine ? '[m]' : '') + ': ' + this.makeStr()
				+ '[' + this.#myLeft + ':' + this.#myTop + ']'
				+ '[' + this.#myRect.width + 'x' + this.#myRect.height + '][' + this.#cardWidth + 'x' + this.#cardHeight + '] . ' + this.changeReason );

			if( !this.#myRect ) {
				bugReport( 'Zero this.#myRect' );
				return;
			}
			/*if( lastMax!==maxInSuit ) */
			this.#countVisible = 0;

			// For right/left hands only
			this.checkPresentSuits();

			this.#checkCardBody();

			if( !this.#cardBody?.height ) {
				// Dimensions are not ready
				return;
			}

			for( let pos = 0, line = 0; pos<this.#ordered.length; line++ ) {
				pos = this.#setPositions_line( pos, line, options )
			}

			log_complete();

			this.checkBottomWidth();
		} else {
			this.#cardBody = null;
			this.holder.style.setProperty( '--shift', this.#countClosed && !this.isInvisible ? 0 : '50%' );
		}
		// Если карты занимают места меньше чем резервированная под них область, и это side,
		// то подвинем к нам блок аватаров
		if( this.#cardBody && this.orientation==='side' ) {
			// log( 'Shifting avatar ' + this.plno + ' to ' + this.#cardBody.top + 'px' );
			this.holder.style.setProperty( '--shift', this.#cardBody.top + 'px' );
		}

/*
		if( this.game.getpov===this.id ) {
			this.game.playArea.style.setProperty( '--bottomhand_height', this.holder.clientHeight + 'px' )
		}
*/

		/*
				if( avatarElement ) {
					let shift = 0;
					if( this.#cardBody && this.orientation==='side' )
						shift = this.#cardBody.top;

					if( shift>0 ) {
						log( 'Cardbody starts with ' + shift );
						avatarElement.style.transform = 'translate3d(0,' + shift + 'px, 0 )';
					} else {
						avatarElement.style.transform = '';
					}
				}
		*/
	}

	get getalign() {
		return this.options.align ||
			(this.#handpos==='left' && 'l') ||
			(this.#handpos==='right' && 'r') || 'c';
	}

	getFrontSide() {
		if( !this.game.isbridge || !(this.plno>=0) || this.orientation!=='side' ) return;
		return this.game.cardHolder[(this.plno + 2) % 4];
	}

	checkPresentSuits() {
		let oldcount = this.#presentSuits?.length;
		this.#presentSuits = null;
		// if( !this.#multiLine || !game.isbridge || !(this.plno>=0) || this.orientation!=='side' ) return;

		let front = this.getFrontSide();
		if( !front || !this.#ordered.length ) return;
		this.#presentSuits = '';
		for( let line = 0; line<4; line++ ) {
			let suit = this.#orderSuits[line];
			if( this.#countInSuit[suit] || front.countInSuit( suit ) ) {
				this.#presentSuits += suit;
			}
		}
		if( oldcount && oldcount!==this.#presentSuits.length )
			front.changed( 'front' );
	}

	countLines() {
		if( this.lines )
			return this.lines;
		return this.#multiLine ? (this.#presentSuits?.length || this.#countSuits) : 1;
	}

	#checkCardBody() {
		let left = 0, top = 0, allh = this.countLines() * this.#cardHeight,
			superPosition = false;

		let width = this.#myRect.width,
			height = this.#myRect.height,
			dir = '';

		// Попробуем не делать наложения по вертикали вообще
		// 04-08-2023
		// Совсем не делать наложения по вертикали не получается, т.к. некоторые карты
		// все же сильно больше чем допустимый размер
		// Боковый контейнеры не должны быть более 50% в высоту от родителя, поэтому вынуждены
		// отталкиваться от этого
		let holder = this.holder,
			maxheight = Math.max( height, holder.parentElement.clientHeight * .45 );
		holder.onHide = this.hide.bind( this );
		holder.onShow = this.#onShow.bind( this );
		// maxheight = height;
		if( allh>maxheight ) {
			allh = maxheight;
			if( this.#multiLine && this.#countSuits>1 && this.#cardHeight * 4>allh * 1.2 ) superPosition = 'v';
			// Наложение по вертикали как минимум на треть
		}
		/*
				if( !LOCALTEST ) {
					if( allh>height ) {
						if( this.#multiLine && countSuits>1 ) superPosition = 'v';
						allh = height;
						// Наложение по вертикали как минимум на треть
					}

					// Уровень наложения карт по вертикали 0.7 для portrait
					let overlap = this.#myRect.height>this.#myRect.width * 2 ? 0.7 : 0.6;
					if( this.#multiLine && allh>this.#cardHeight + this.#cardHeight * overlap * (countSuits - 1) ) {
						allh = this.#cardHeight + this.#cardHeight * overlap * (countSuits - 1);
						// allh = this.#cardHeight * overlap * countSuits;
					}
				}
		*/
		// Центрирование по вертикали
		if( allh<height ) {
			// Upper and down hands cling their side
			switch( this.#handpos ) {
				case 'top':
					break;		// keep on top
				case 'bottom':
					top = height - allh;
					break;
				default:
					// Если 3 игрока, то боковые карты держатся наверху,
					// если 4, то по центру
					let keeptop = this.isHand && this.game.maxPlayers===3;
					if( !keeptop )
						top += (height - allh) / 2;
					break;
			}
		}

		let count = this.#multiLine ? this.#maxInSuit : this.#ordered.length,
			countBlocks = this.#multiLine ? 1 : this.#countSuits,
			sepSuit = this.isHand && !this.#multiLine,
			steppix = this.#cardWidth; // - this.#cardWidth*.015;

		// Minimum step for some decks
		let minsteppix, minstepline;
		if( this.isHand && this.game.iscards ) {
			if( this.getDeck!=='backcolor' ) {
				minsteppix = this.#cardWidth * 3 / 4;
				minstepline = this.#cardHeight * 3 / 4;
				if( this.getDeck==='/atlas' ) {
					minsteppix = this.#cardWidth / 2;
					minstepline = this.#cardHeight / 2;
				}
				if( this.#multiLine )
					minsteppix = this.#cardWidth / 2;
			}
		}
		if( this.options.mincardstep )
			minsteppix = Math.max( minsteppix||0, this.#cardWidth*this.options.mincardstep );

		if( this.stepPercent ) {
			if( steppix * count>width * 100.01 ) {
				steppix = this.#cardWidth * this.stepPercent / 100;
				superPosition = 'h';
			}
		} else if( this.stepPercentParent ) {
			steppix = width * this.stepPercentParent / 100;
			superPosition = 'h';
		} else if( this.#multiLine && this.#maxInSuit>1 ) {
			// clientWidth = this.#cardWidth * 3;
			steppix = (width - this.#cardWidth) / (this.#maxInSuit - 1);
			// Мы делаем отступ одинаковым для всех карт. Но "выбитые зубы" нам ни к чему
			if( steppix>this.#cardWidth ) steppix = this.#cardWidth;
			// if( steppix>this.#cardWidth / 2 ) steppix = this.#cardWidth / 2;
			// if( steppix>this.#cardWidth ) steppix = this.#cardWidth;
			if( steppix<this.#cardWidth * 0.75 )
				superPosition = 'h';
		}

		// Steppix not more than minsteppix
		if( minsteppix && steppix>minsteppix ) steppix = minsteppix;

		// Check horizontal superposition
		if( steppix<this.#cardWidth * 0.8 )
			superPosition = 'h';

		// Разделять ли по мастям? Если свободно не помещаются в экран, то без разделения
		if( countBlocks>1 ) {
			if( this.#cardWidth * countBlocks + this.#pixelsBetweenSuits * (countBlocks - 1) + steppix * (count - countBlocks)>width ) {
				countBlocks = 1;
				sepSuit = false;
			}
		}

		let
			sepSuitCount = countBlocks - 1,
			sepCount = count - 1 - sepSuitCount,
			sepWidth = steppix,
			sepSuitWidth = sepSuitCount ? this.#pixelsBetweenSuits + this.#cardWidth : 0,
			myWidth = this.#cardWidth * countBlocks + steppix * (count - countBlocks) + this.#pixelsBetweenSuits * (countBlocks - 1);

		if( isBad( sepWidth ) ) {
			sepWidth = 0;
			bugReport( `Sepwidth NaN 0` );
		}

		if( !sepCount ) sepSuit = false;
		// myWidth = this.#cardWidth * countBlocks + steppix * (count - countBlocks) + this.#
		// this.#pixelsBetweenSuits * (countBlocks - 1);

		// if( LOCALTEST ) {
		// Установим максимальный равномерный интервал. Разделение мастей отменяем
		// Разделение мастей возможно, только если карты помещаются целиком
		// (или если видимая часть карт 48пикселей и более)
		// В бридже стараемся всегда растянуть карты по горизонтали.
		// Это не нужно делать, если ширина больше 20em (500px?)
		let pullCards = !minsteppix && (( this.game.isbridge && this.orientation==='side') || steppix<48 && !window.pointerFine);
		if( pullCards ) {
			// log( `Cardholder ${this.id} small steppix=${steppix}`)
			sepSuitWidth = sepWidth = steppix = Math.min( this.#cardWidth, width / count );
			if( isBad( sepWidth ) ) {
				log( `Sepwidth is NaN 1: this.#cardWidth=${this.#cardWidth} width=${width} count=${count}` );
				sepSuitWidth = sepWidth = steppix = 0;
				bugReport( 'Sepwidth is NaN 1' );
			}
			if( steppix<this.#cardWidth / 2 ) {
				superPosition = 'max';
			}
			sepSuit = false;
		}

		// steppix *=2 ;
		// pixelsBetweenSuits = 0;
		// this.#cardWidth *= 0.75;
		// }
/*
		if( isBad( sepWidth ) ) {
			sepWidth = 0;
			log( `Sepwidth is NaN 1` );
			bugReport( `Sepwidth is NaN 21` );
		}
*/

		if( !sepSuit ) {
			sepSuitWidth = sepWidth;
			myWidth = steppix * count;
			// myWidth = this.#cardWidth + steppix * (count - 1);
		}
		if( myWidth>width && !this.options.nooverlap && count>1 ) {
			if( (myWidth - width) / count>this.#cardWidth / 10 )
				superPosition = 'h';		// Наложение более 10% отмечаем, чтобы изменить изображение карт
			// Корректируем расстояния между картами. Сначала делаем наложение до 0.5 this.#cardWidth
			if( sepSuit )
				sepWidth = (width - (this.#cardWidth * countBlocks + this.#pixelsBetweenSuits * sepSuitCount)) / sepCount;
			else
				sepSuitWidth = sepWidth = count>1 ? (width - this.#cardWidth) / (count - 1) : 0;
			if( isBad( sepWidth ) ) {
				log( `Sepwidth is NaN 3: sepSuit=${sepSuit} width=${width} this.#cardWidth=${this.#cardWidth} countBlocks=${countBlocks} count=${count} sepCount=${sepCount} pixelsBetweenSuits=${this.#pixelsBetweenSuits} sepSuitCount=${sepSuitCount}` );
				bugReport( 'sepWidth is NaN 3' );
				sepSuitWidth = sepWidth = 0;
			}
			if( sepWidth<this.#cardWidth / 2 ) {
				sepWidth = this.#cardWidth / 2;
				sepSuitWidth = sepSuitCount ? (width - (this.#cardWidth + (sepCount * sepWidth))) / sepSuitCount : 0;
				if( sepSuitWidth<sepWidth ) {
					// Максимальное сжимание - одинаковое расстояние между всеми картами
					sepSuitWidth = sepWidth = (width - this.#cardWidth) / (count - 1);
					superPosition = 'max';
					if( isBad( sepWidth ) ) {
						log( `Sepwidth is NaN 4: width=${width} this.#cardWidth=${this.#cardWidth} count=${count}` );
						bugReport( 'sepWidth is NaN 4' );
						sepSuitWidth = sepWidth = cardwidth / 5;
					}
				}
			}
			if( /*orientation==='central' &&*/ sepWidth>this.#cardWidth * 0.7 )
				superPosition = null;
			myWidth = width;
		}
		if( isBad( sepWidth ) ) {
			sepWidth = 0;
			bugReport( `Sepwidth is NaN 2` );
		}
		this.realHandWidth = myWidth;

		if( this.maxPerline && count>this.maxPerline ) count = this.maxPerline;
		switch( this.getalign ) {
			case 'c':
				// Для центрирования необходимо вычислить стартовую позицию
				left += (width - myWidth) / 2;
				break;
			case 'r':
				// if( this.#multiLine ) {
				// 	Recalc count for this suit for correct positioning right hand
				// 	count = this.countInSuit[this.#ordered[pos].suit] || 1;
				// }
				left += width - myWidth;
				// log( `Cardholder right ${this.id}: width=${width}, mywidth=${myWidth}, left=${left}` );
				break;
		}

		this.#cardBody = {
			top: top, left: left, height: allh, superPosition: superPosition,
			clientWidth: width, clientHeight: height, myWidth: myWidth,
			sepWidth: sepWidth, sepSuitWidth: sepSuitWidth, stepPix: steppix,
			rotateDir: dir,
			minstepline: minstepline
		};
	}

	checkClosedPicture() {
		if( !this.#countClosed || this.isInvisible ) return;
		let pic = (this.orientation==='side' ? 'v' : 'h') + '.svg#' + (this.#countClosed<10 ? this.#countClosed : 10);
		// Не показываем карты, если игрока на этой позиции нет
		let img = `url( ${IMGEMBEDPATH}/svg/cardback/${pic} )`;
		(this.options.closedHolder || this.holder).style.backgroundImage = img;
	}

	setClosed( count ) {
		if( this.#countClosed===count ) return;
		// let prevClosed = this.#countClosed;
		this.#countClosed = count;
		this.realHandWidth = 0;
		// checkEmpty();
		if( !this.#countClosed ) {
			(this.options.closedHolder || this.holder).style.backgroundImage = null;
			this.holder.removeAttribute( 'data-card_closedback' );
			this.#myRect = null; 
			this.#checkSize( 'countClosed>0' );
			// reviseSize();
		} else {
			this.#maxInSuit = 0;
			this.holder.dataset.card_closedback = this.#countClosed.toString();
			this.checkClosedPicture();
			this.#checkSize( 'No closed' );
			// this.#reviseSize();
		}
		// changed();
	}

	#verifyRect() {
		if( !this.#myRect ) return true;
		let rect = this.getRect();
		if( !rect ) return true;
		if( rect.left!==this.#myRect.left || rect.top!==this.#myRect.top || this.#myRect.height!==rect.height || this.#myRect.width!==rect.width ) {
			this.#myRect = null;
			this.changed( 'verifyrect' );
		}
	}

	getRect() {
		// pick based on https://stackoverflow.com/questions/25553910/one-liner-to-take-some-properties-from-object-in-es-6/25554551#25554551
		let pick = ( { left, top, width, height } ) => ({ left, top, width, height });
		let r = pick( this.holder.getBoundingClientRect() );
		if( !r.top && !r.bottom && !r.left && !r.right ) return r;
		this.boundingRect = { ...r,
			right: r.left + r.width,
			bottom: r.top + r.height };
		this.game.cardsModule.updateInnerRect();
		if( this.movedTo ) {
			let mr = this.movedTo.getBoundingClientRect();
			switch( this.movedTo.dataset.position ) {
				case 'right':
					r.left = mr.right - r.width;
					break;
				case 'left':
					r.left = mr.left;
					break;
				case 'top':
					r.top = mr.top;
					break;
				case 'bottom':
					r.top = mr.bottom - r.height;
					break;
				default:
					r.left = (mr.left + mr.right) / 2 - r.width / 2;
					r.top = (mr.top + mr.bottom) / 2 - r.height / 2;
			}
		}

		return r;
	}

	#checkRect() {
		// Повторный вызов onresize, т.к. видимо успели неправильно
		// установиться размеры до полной загрузки CSS
		// grid layout chio

/*
Commented 04/09/24
		this.#onresize( 'checkrect' );
*/
		if( this.#myRect?.width ) return;
		// let holder = div;
		this.#myRect = this.getRect(); // holder.getBoundingClientRect();
		this.#myLeft = this.#myRect.left;
		this.#myTop = this.#myRect.top;
		let parentRect = (this.game.playCards || this.game.topPanel).getBoundingClientRect();
		this.#myLeft -= parentRect.left;
		this.#myTop -= parentRect.top;

		if( LOGLEVEL ) log( `checkRect ${this.id}: myrect.width=${this.#myRect.width}` );

		/*
						myLeft = myTop = 0;
						while( holder!==game.playCards ) {
							myLeft += holder.offsetLeft;
							myTop += holder.offsetTop;
							holder = holder.offsetParent;
						}
		*/
		// if( checkRectTimeout ) clearTimeout( checkRectTimeout );
		// checkRectTimeout = 	setTimeout( verifyRect, 500 );
	}

	clear() {
		// Clear previous cards
		if( !this.#ordered.length && !this.#countClosed ) return;
		// log( 'CH clear ' + this.id );
		this.str = null;
		this.movedTo = null;
		this.#countVisible = 0;
		this.#maxSetWidth = 0;
		for( let c of this.#ordered ) {
			if( !c ) continue;
			if( !this.#ismine( c ) ) {
				let owner = c.owner?.id;
				// Нет owner - возможно, карта спрятана анимацией
				// if( owner )
				// 	log( `I am ${this.id}. My card ${c.str} belongs to ${owner}` );
				continue;
			}
			this.game.cards.hide( c );
		}
		this.#ordered = [];
		if( this.#countClosed ) this.setClosed( 0 );
		this.closedCount?.hide();
	};

	makeStr() {
		let str = '';
		for( let i = 0; i<this.#ordered.length; i++ ) {
			str += this.#ordered[i] ? this.#ordered[i].str : '-';
		}
		return str;
	}

	setStr( set, ifInvisible, zIndex ) {
		if( set?.length>0 && (+set)>=0 ) {
			// Noted by number - closed cards
			this.clear();
			this.setClosed( +set );
			this.changedContent( 'closed' + set );
			// checkEmpty();
			return;
		}

		// this.str = set;
		if( !this.#ordered.length && !set && !this.#countClosed ) return false;

		this.setClosed( 0 );

		// Если в конце строки "?[N]", значит есть объект "закрытые карты". Со счетчиком или без
		let clo = /\?(\d*)$/.exec( set );
		if( clo ) {
			set = set.replace( /\?\d*$/, '' );
			this.closedCount ||= html( `<div class='column center display_none'
			 style='position: absolute; right: 0; top: 0; color: white; font-size: 1.2rem; font-weight: bold;
			 	justify-content: center;
			 	height: 50%; width: min(3em,35%); z-index: 100; background: url( ${IMGEMBEDPATH}/svg/cardback/h.svg#1 )'>
			 	</div>`, this.holder );
			this.closedCount.textContent = +clo[1]>1 && clo[1] || '';
			this.closedCount.show();
		} else
			this.closedCount?.hide();

		let added = [], hidden = 0,
			cadded = this.append( set, {
				onlyInvisible: ifInvisible,
				z: zIndex,
				array: added
			} );

		// Hide excess cards
		for( let i in this.#ordered ) {
			let card = this.#ordered[i];
			if( !this.#ismine( card ) ) continue;				// ??? Не надо ли удалить эту карту
			if( added.includes( card ) ) continue;
			this.game.cards.hide( card );
			this.#countVisible--;
			this.#needstriporder = true;
			this.changed( '-' + card.str );
			hidden++;
		}
		if( !cadded && !hidden ) return;		// No changes
		this.#ordered = added;

		return cadded;
	}

		// Проверить не забыл ли где-то при добавлении карты эту функцию
		// если что - нужно сделать это в stripOrdered, видимо
		// this.#checkSize();

	add( set, inv, z, pos ) {
		let res = 0;
		res = this.append( set, { z: z, pos: pos } );
		if( res ) this.changedContent( 'add' );
	}

	remove( card ) {
		if( !card ) return;
		let idx = this.#ordered.indexOf( card );
		if( idx!== -1 ) {
			// this.#ordered.splice( idx, 1 );
			if( card.owner===this ) {
				if( card.isVisible() ) this.#countVisible--;
				card.owner = null;
			}
			this.#needstriporder = true;
//		this.#ordered.splice( this.#ordered.indexOf( card ), 1 )
			this.changedContent( 'removeCB:' + card?.str || '' );
		}
	}

	addClass( className ) {
		for( let c of this.#ordered )
			c.classList.add( className );
	};

	append( param, options ) {
		if( !param || ( Array.isArray( param ) && !param.length )) return 0;
		if( typeof param==='string' ) {
			param = this.game.cards.getCards( param, this.prefix );
		}
		let added = 0;
		if( typeof param[Symbol.iterator]==='function' ) {	// isIterable() ?
			for( let card of param ) {
				added += this.appendone( card, options );
				options?.array?.push( card )
			}
		} else {
			added += this.appendone( param, options );
			options?.array?.push( param )
		}
		return added;
	};

	checkcardZ( card, zbase ) {
		if( !card ) return;
		let z = this.options.zAdd || 0;
		if( this.#sort.style==='none' ) {
		} else if( this.#sort.style )
			z += this.#orderSuits[card.suit] * 100 + 99 - card.range;
		else
			z += (zbase || 0) * 10 + this.#ordered.length + 1;
		// log( 'Card ' + card.str + ' z=' + z );
		card.style.zIndex = z;
		card.dataset.z = z;
	}

	appendone( card, options ) {
		// 12-13 закомментирована проверка card.visible,
		// так как карта может быть невидима на момент обработки, но быть
		// уже в другом холдере
		if( card && options?.onlyInvisible && /*card.isVisible() && */card.owner ) return false;
		let pos = options?.pos;
		if( pos>=0 ) {
			log( `--- add ${card.str} into ${pos}. now ${this.str} (${this.#ordered.length})` );
			if( this.#ordered[pos]===card ) return 0;
			if( this.#ordered[pos] ) {
				log( `Adding ${card.str} at position ${pos} (${this.#ordered[pos]}` );
				return 0;
			}
			let idx = this.#ordered.indexOf( card );
			if( idx=== -1 ) {
				while( this.#ordered.length<pos + 1 ) this.#ordered.push( null );
			} else {
				if( pos===idx ) return 0;
				this.#ordered[idx] = null;
			}
			this.#ordered[pos] = card;
		} else {
			if( this.#ismine( card ) ) return 0;
			if( card && pos==='fillspot' && this.options.keepSpot ) {
				// Втавка, или замена дырки
				pos = this.#ordered.indexOf( null );
				log( `--- filling spot place: ${pos}` );
				if( pos!== -1 ) this.#ordered[pos] = card;
				else this.#ordered.push( card );
			} else
				this.#ordered.push( card );
		}
		if( !card ) return 1;		// Добавление null
		const oldowner = card.isVisible() && card.owner || undefined;
		oldowner?.remove( card );
		card.owner = this;
		card.dataset.owner = this.id;
		card.style.filter = this.#shadowed ? 'grayscale(1)' : '';
		this.checkcardZ( card, options?.z );
		if( this.options.group )
			card.setAttribute( 'ownergroup', this.options.group );
		else
			card.removeAttribute( 'ownergroup' );

		this.#countVisible++;
		this.#needstriporder = true;
		this.changed( '+' + card.str );
		return 1;
		// smooth = false;
//			card.classList.add( 'card_appeared' )
// 				changed();
	}

	// Place only invisible cards to center of holder,
	// without replacing another cards.
	// For next moving
	placeToCenter( set, options ) {
		this.#checkRect();
		this.#setScale();
		let ch = this.#cardHeight || 0;
		/*
				if( !this.#cardHeight ) {
					log( 'Not ready to PlaceToCenter: ' + this.id );
					return;
				}
		*/
		if( options?.remove )
			options.hide = true;
		for( let card of set ) {
			if( !card ) continue;
			if( card.isVisible() && this.#ismine( card ) ) continue;
			let w = this.getCardWidth();
			if( options?.resizeTo ) {
				w = options.resizeTo.getCardWidth();
				// resizeTo.resizeOneCard( card );
			}
			let left = this.#myLeft + this.#myRect.width / 2 - w / 2;
			let top = this.#myTop + this.#myRect.height / 2 - ch / 2;
			if( !this.#ordered.length && this.#countClosed>1 ) {
				if( this.position==='left' ) left += this.#cardWidth || this.#style['width'];
				if( this.position==='right' ) left -= this.#cardWidth || this.#style['width'];
				if( this.position==='bottom' ) top -= ch || this.#style['height'];
			}
			if( options?.remove )
				card.owner?.remove( card );
			log( `CH ${this.id} places to center ${card.str}: this.#myRect=${JSON.stringify( this.#myRect )} myTop=${this.#myTop} this.#cardHeight(ch)=${this.#cardHeight}(${ch})` );
			doAnimate( card, left, top, options );
		}
	};

	setSub( txt ) {
		if( txt ) {
			this.#subelem ||= construct( 'span.cardholder_sub', this.holder );
			this.#subelem.textContent = txt;
			this.#subelem.style.display = 'block';
		} else if( this.#subelem ) this.#subelem.style.display = 'none';
	};

	changed( reason ) {
		this.changeReason += ' ' + (reason || '???');
		if( delay( this.#applyChangesBind, this.id ) ) {
			if( LOGLEVEL )
				log( `Changed ${this.id}: ${this.makeStr?.()} (${reason})` );
		}
	}

	get #isneedsort() {
		if( this.#sort.style==='always' ) return true;
		if( this.#sort.style==='full' ) return this.#isfilled;
		return false;
	}

	countInSuit( suit ) {
		return this.#countInSuit[ suit ];
	}

	get #isfilled() {
		return this.#countVisible===this.maxVisible;
	}

	get countVisible() {
		return this.#countVisible;
	}

	getCards() {
		return this.#ordered;
	}

	get count() {
		return this.#ordered.length || this.#countClosed;
	};

	get countClosed() {
		return this.#countClosed;
	}

	voidRect() {
		this.#myRect = null;
	}

	getCardWidth() {
		return this.#cardWidth || this.game.cards.unifiedWidth;
	}

	resort( newsorttype ) {
		this.#dosort( newsorttype );
		this.changed( 'resort' );
	}

	getDebugStr() {
		let str = this.str,
			err = '';
		for( let card of this.#ordered )
			if( card.owner!==this ) err += ` ${card.str}:${card.dataset.owner}`;
		return str + (err || ' OK');
	}

	onlayout( p, o ) {
		this.position = p;
		this.orientation = o;
		this.checkClosedPicture();
		this.setMinWidth();
		this.#reviseSize();
		this.#myRect = null;
		this.avatarElement ||= this.holder.$( '.play_avatar' );
		this.changed( 'layout' );
	}

	find( str ) {
		for( let c of this.#ordered )
			if( c.str===str ) return c;
	}

	has( set ) {
		for( let c of set )
			if( !this.#ordered.includes( c ) ) return false;
		return true;
	}

	moveTo( o, options ) {
		this.movedTo = o?.holder || o;
		this.#myRect = null;
		if( options==='virtual' )
			this.#setPositions( options );
		else
			this.changed( 'moveto' );
	};

	get isHand() {
		return +this.id>=0;
	}

	get isPlayer() {
		return this.id>=0;
	}

	isOpened( num ) {
		return this.#ordered.length>(num || 0);
	}

	hide() {
		// White hided new added card will be shown (to fix)
		for( let o of this.#ordered )
			o.hide();
	}

	show() {
		for( let o of this.#ordered ) o.show();
	}

	#onShow() {
		this.changed( 'onshow' );
	}

	shadow( val ) {
		if( this.#shadowed===val ) return;
		this.#shadowed = val ?? true;
		for( let o of this.#ordered )
			o.style.filter = this.#shadowed ? 'grayscale(1)' : '';
	}

	toggleClass( className, value ) {
		if( this.holder.classList.contains( className )===value ) return;
		this.holder.classList.toggle( className, value );
		delay( this.#onresizeBind );
	}

	checkBottomWidth() {
		if( !(+this.id>=0) || this.position!=='bottom' ) return;
		// Check if my hand is wide and opened (then make last trick smaller)
		if( this.game.setAttr( 'bottomwide', this.realHandWidth>this.holder.parentElement.clientWidth * .5 ) )
			setTimeout( () => fire( 'recheckcardholder' ), 200 );
	}

	#resizeUnified( w, h ) {
		this.setBaseCardSize();
		let scale = this.calcScale( h );
		// Resize 				if( self['scale'] )
		w = w * (scale || 1);
		h = h * (scale || 1);
		if( this.capacity ) {
			w *= this.capacity; // + this.#pixelsBetweenSuits;
		}
		this.setMinWidth();
		if( this.#reviseSize() )
			this.changed( 'scale' );
	}
}

export default Cardholder;