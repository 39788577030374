/*
    Invitation links management
    Reading,keeping, checking, using
 */
import User from './user.js';

let allInvites = new Map,
	askSet = new Set;

// delete localStorage.invitationcodes;
// delete localStorage.invitationlinks;

async function makeInvitationLinks() {
	let session = GETparams.get( 'inv' ),
		localstr = localStorage.invitations_ask,
		invitations_received = JSON.parse( localStorage.invitations_received || null ) || {},
		sessionlink;

	if( !session ) {
		// Проверим на формат ?<invcode>
		if( location.search.length > 10 && location.search[1]!=='d' && !location.search.includes( '=' ) )
			session = location.search.slice( 1 );
	}
	if( !session ) {
		if( location.pathname.includes( '-inv-' ) )
			session = location.pathname.split( '-inv-' )[1];
	}

	if( location.search.startsWith( '?+' ) )
		session = location.search.slice( 2 );

	if( localstr ) askSet = new Set( JSON.parse( localstr ) );

	if( session ) {
		askSet.add( session );
		invitationsKnown.add( session );
		invitations_received[session] = Date.now();
	}

	// Удалим приглашения, у которых не указана дата или прошло более суток
	for( let id of invitationsKnown ) {
		let received = +invitations_received[id];
		if( !received || Date.now() > received + 60*60*24*1000  ) invitationsKnown.delete( id );
	}

	log( 'inv: check links codes: ' + JSON.stringify( [...invitationsKnown] ) );
	if( !invitationsKnown.size ) return;

	let res = await API( '/invitationcode_check', {
		codes: [...invitationsKnown]
	}, 'internal' );
	if( !res && LOCALTEST )
		res = { ok: true, invitations: [...invitationsKnown].map( x => { return { code: x, origin: 'tour_' + x, sender: '112' } } ) };

	if( !res?.ok ) return;

	invitationsKnown = new Set;
	let newask = new Set;
	for( let o of res.invitations ) {
		if( o.sender===UIN ) continue;
		if( o.accepted ) continue;			// Зачем приходит в ответе уже принятое приглашение?
		allInvites.set( o.origin, o );
		invitationsKnown.add( o.code );
		if( askSet.has( o.code ) ) newask.add( o.code );
		if( o.code===session ) sessionlink = o;
	}
	askSet = newask;

	for( let k in invitations_received )
		if( !invitationsKnown.has( k ) ) delete invitations_received[k];
	log( 'inv: step, sessionlink ' + JSON.stringify( sessionlink ) );

	if( sessionlink ) {
		// Something changed
		localStorage.invitations_known = JSON.stringify( [...invitationsKnown] );
		localStorage.invitations_ask = JSON.stringify( [...askSet] );
		localStorage.invitations_received = JSON.stringify( invitations_received );
	}

	// После получения всех приглашений начинаем открывать их начиная с полученного в этой сессии
	if( sessionlink ) {
		showInvitation( sessionlink );
	} else {
		showNextInvitation();
	}
}

async function showNextInvitation() {
	log( `inv: shownext ${JSON.stringify( [...allInvites] )}, asked ${[...askSet]}`  );
	for( let [_,inv] of allInvites ) {
		if( inv.showed ) continue;
		if( !askSet.has( inv.code ) ) continue;
		// await cssInject( 'bigwindow' );
		showInvitation( inv );
		break;
	}
}

let mainwin;
function invhide( e ) {
	if( e===undefined || e==='no' ) return showNextInvitation();
}

async function showInvitation( inv ) {
	// Показываем окно с приглашением и призывом принять его
	await cssInject( 'bigwindow' );
	if( !mainwin ) {
		mainwin = makeBigWindow(
			`<div class='column'>
					</div>` );
		mainwin.onHide = invhide;
	}
	// if( LOCALTEST && inv.origin==='team_' + inv.code ) inv.origin = 'team_16198';
	let origin = inv.origin.split( '_' );

	log( 'inv: start showing invitation ' + JSON.stringify( inv ) );
	await User.whenloaded( [ inv.sender, inv.origin ] );
	log( 'inv: users loaded' );
	let sender = User.set( inv.sender );
	let str = '',
		invstr = origin[0]==='tour'? '{invitestoplay}' : '{invites} {join}';
	if( sender?.getPicture ) {
		// Полноценный приглашатель
		str = fillPlayerHTML( sender, {
			style: 'font-size: 1rem',
			fullname: true,
			notextblock: true,
			undername: invstr
		} )
	} else if( sender?.getShowName ) {
		str = `<span class='control' data-origin='${sender.itemid}' style="margin-bottom: 1rem">`;
		// ${sender.picture? fillMagicIMG( sender.picture, 24 ) : ''}`;
		str += sender.fio? `${sender.fio} (${sender.getShowName}) ` : `${sender.getShowName}`;
		str += invstr + '</span>';
	} else {
		str = "<span style='font-size: 150%; margin-bottom: 1em;'>{Youareinvited}</span>";
	}

	// if( !sender.officialName )
	// 	str = `<span style="margin-bottom: 1rem">${sender.getShowName} {invites} {join}</span>`;

	// str += '<span style="margin: 1em 0">{Invites}</span>';

	str += `${fillPlayerHTML( inv.origin, {
		style: 'margin-top: 1.5rem',
		notextblock: true,
		size: 96
	} )}`;

	// У команды кнопки "присоединиться", у турнира "зарегистрироваться"
	let txt = '{Join}';
	if( origin[0]==='tour' ) txt = '{Register}';
	str += `<button style='margin: 1.5rem 0 0 0' class='default rem2' data-closeselect='accept'>${txt}</button>
			<span class='control rem2' style='margin-top: 1rem' data-closeselect='no'>{Nothanx}</span>`;

	mainwin.$( '.column' ).html( str );

	// await Promise.all( [ User.whenloaded( inv.sender ), User.whenloaded( inv.origin ) ] );
	inv.showed = true;
	let res = await mainwin.promiseShow();
	if( res==='accept' ) {
		// Авторизован ли?
		await window.checkAuth();
	}
	dontaskInvitation( inv, res==='accept' || res==='no' );
	switch( res ) {
		case 'accept':
			// Присоединение к команде/турниру
			if( origin[0]==='tour' && window.WEBSOCKET && !window.LOCALTEST ) {
				window.elephCore.do( `type=register invitationcode=${inv.code} tour=${origin[1]} ` );
				return;
			}
			API( '/invitationcode_accept', {
				origin: inv.origin,
				code: inv.code
			});

			let newlocation = inv.origin.replace( '_', '.' );
			switch( origin[0] ) {
				case 'team':
					newlocation = window.STATIC ? '/team/' + origin[1] : '/t.' + origin[1];
					break;
				case 'tour':
					newlocation = window.STATIC ? '/tour/' + origin[1] : '/' + origin[1];
					break;
			}
			goLocation( newlocation );
			return;

		case 'no':
			// Это приглашение удаляется
			return;
	}
}

function dontaskInvitation( inv, answered ) {
	askSet.delete( inv.code );
	if( answered )
		localStorage.invitations_ask = JSON.stringify( [...askSet] );
}

makeInvitationLinks();

window.getInvitationCode = origin => allInvites.get( origin )?.code;