export default class Watcher {
	constructor() {
		this.watchers = new Map;
	}

	watch( key, func ) {
		let w = this.watchers.get( key );
		if( !w ) {
			w = new Set;
			this.watchers.set( key, w );
		}
		w.add( func );
	}

	call( key, data ) {
		let w = this.watchers.get( key );
		if( w ) for( let f of w ) f( data, key );
	}

	delay( key, data ) {
		let w = this.watchers.get( key );
		if( w ) for( let f of w ) delay( () => f( data, key ) );
	}
}