import Table from './table.js';
// Match 2 tables initialization

export default class Match {
	constructor( game ) {
		let opts = {
			emptynames: 'sides',
			nordup: true,
			onsit: this.onsit.bind( this ),
			foundername: true,
			game: game.item,
			collecting: true,
		};
		this.game = game;
		this.tables = [new Table( opts ), new Table( opts )];
		this.players = [];
		this.tables[0].teamNumber = 1;
		this.tables[1].teamNumber = 2;

		game.module = 'match';
		game.playZone.classList.add( 'match' );
		game.playZone.appendChild( this.tables[0].holder );
		game.playZone.appendChild( this.tables[1].holder );
		this.button = construct( `button.mybutton.rem2 {Invite}`, game.playZone, this.click.bind( this ) );
		game.playZone.$( '.centeredbuttons' ).classList.add( 'display_none' );

		game.addRoute( {
			players: str => {
				let ar = str.split( ',' );
				this.players = ar;
				// if( ar.length<8 ) return;
				this.tables[0].setPlayers( ar.slice( 0, ar.length / 2 ).join( ',' ) );
				this.tables[1].setPlayers( ar.slice( ar.length / 2, ar.length ).join( ',' ) );
				this.checkButton();
			},
			team0: o => this.setTeam( 0, o ),
			team1: o => this.setTeam( 1, o ),
		} );
	}

	setTeam( no, o ) {
		this.tables[no].setCaption( o.name );
		this.tables[no].setImg( o.logo, 'team_' + o.tid );
	}

	get filled() {
		let c = this.players.reduce( ( acc, current ) => acc += (current!=='*'?1:0), 0 );
		return c===8;
	}

	checkButton() {
		this.button.setContent( this.filled? '{Start} ⚔️️️' : '{Invite}' );
	}

	onsit( e ) {
		this.game.sitinClick( e.place + (this.tables[1]===e.target ? this.players.length / 2 : 0) );
	}

	click( e ) {
		if( this.filled ) {
			// Запрос на начало матча
			return this.game.send( 'start' );
		} else {
			this.game.invite();
		}
	}
}
