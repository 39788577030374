let oneevent;

function prettyRes( res, mp ) {
	let str = Math.floor( res ),
		dec = res.toString().replace( /.*\./, '.' );
	if( !dec.includes( '.' ) ) dec = '';
	if( mp )
		str += `<span style="font-size: 70%">${mp}</span>`;
	else if( dec )
		str += `<span style="font-size: 70%">${dec}${mp || ''}</span>`;
	return str;
}

function numformat( num ) {
	if( num===undefined ) return '';
	if( +num>0 ) return '+' + num;
	return num;
}

function mpformat( num, mine ) {
	if( num===undefined ) return '';
	if( mine && +num===100 ) return '🟢';
	if( mine && !+num ) return '🔴'; // '⚪'; // '⚫';
	// Show with pretty style
	return prettyRes( num, '%' );
}

function impcolor( num ) {
	if( num===undefined ) return '';
	if( !isNaN( num ) ) {
		if( +num>0 ) return 'green';
		if( +num<0 ) return 'red';
		return 'darkgray';
	}
	if( num.slice?.( -1 )==='%' ) {
		let p = +num.slice( 0, -1 );
		return p>55 ? 'green' : (p<45 ? 'red' : 'darkgray');
	}
	return 'unset';
}

function mpcolor( num ) {
	if( !isNaN( num ) ) {
		if( +num>55 ) return 'green';
		if( +num<40 ) return 'red';
		return 'darkgray';
	}
	return 'unset';
}

export class Event {
	#protocolsPanel;
	#scoreBoardPanel;
	#watch;
	#solo;

	constructor( data ) {
		oneevent = this;
		this.init( data );
	}

	get #IMP() {
		return this.data.scoring==='IMP';
	}

	#invertScore( score ) {
		return this.#IMP ? -score : 100 - score;
	}

	init( data ) {
		this.#solo = data.solo;
		this.data = Object.assign( {}, data );
		// Fix boards bug (where 0 element is null)
		if( this.data.boards[0]===null ) this.data.boards.splice( 0, 1 );
		let imp = this.#IMP;
		this.impcolor = imp ? impcolor : mpcolor;
		this.impformat = imp ? numformat : mpformat;
		this.groupProtocols();
	}

	go( strid ) {
		let hash = strid.match( /#(.*)$/i )?.[1] || strid;
		this.goto( hash );
		// protono = loc[3];
		// this.#goBoard( +boardno - 1, { table: tableid } );
	}

	// Grouping protocols
	groupProtocols( board ) {
		if( !board ) {
			if( !this.data.boards ) return;
			for( let b of this.data.boards ) {
				if( !b ) continue;
				this.groupProtocols( b );
			}
			return;
		}
		if( !board.protocols ) return;
		let protocols = board.protocols.sort( ( x, y ) => y.score - x.score ),
			grouped = [];
		for( let p of protocols ) {
			let hash = p.score.toString();
			if( p.contract ) {
				if( p.declarer ) {
					p.pureContract = p.contract;
				} else {
					p.declarer = p.contract[0];
					p.pureContract = p.contract.slice( 1 );
				}

				let line = 'neswNESW'.indexOf( p.declarer ) % 2,
					hash = p.pureContract + line + p.tricks + p.score;
			}

			p.prettyContract = p.pureContract?.replaceAll( /[HSDCX]/g, pattern => {
				if( pattern==='X' ) return '❌';
				return emoSuits[pattern.toLowerCase()]
			} ) || '';

			p.hash = hash;
			grouped[hash] ||= {
				hash: hash,
				prettyContract: p.prettyContract,
				contract: p.pureContract,
				tricks: p.tricks,
				declarers: '',
				score: p.score,
				mscore: p.mscore,
				count: 0
			};
			grouped[hash].count++;
			if( !grouped[hash].declarers.includes( p.declarer ) )
				grouped[hash].declarers += p.declarer;
		}
		board.groupedProtocols =
			Object.values( grouped )
				.sort( ( x, y ) => y.score - x.score );
	}

	makeGroupedProtocolsSheet( protocols ) {
		if( !protocols ) return;
		let str = `<div class='sheet groupedprotocols'>
			<table class='collapse' style='text-align: left; font-family: monospace'>`;
		// Need to sort protocols before filling
		/*
				protocols.sort( ( a, b ) => {
					return a.score - b.score;
				})
		*/
		for( let p of protocols ) {
			let arrows = { S: '↓', W: '←', N: '↑', E: '→', NS: '↕', EW: '↔', SN: '↕', WE: '↔' }[p.declarers] || '';
			str += `<tr data-protocolhash='${p.hash}'><td style='font-size: 80%; opacity: 0.5'>${p.count>1 && p.count + 'x' || ''}</td>
				<td class='emoji'>${arrows}</td>
				<td class='emoji'>${p.prettyContract}</td>
				<td style='font-size: 1rem; color: var( --light_gray )'>${p.tricks ?? ''}</td>
				<td style='text-align: right; color: var( --light_gray )'>${numformat( p.score )}</td>
				<td style='_color: ${this.impcolor( p.mscore )}; text-align: right'>${this.impformat( p.mscore )}</td>
				</tr>`
		}
		str += '</table></div>';

		this.#protocolsPanel = makeBigWindow( {
			repeatid: 'board_protocols',
			html: str
		} );
		this.#protocolsPanel.onclick = this.protocolsClick.bind( this );
	}

	findProtoByHash( hash ) {
		if( !this.currentBoard?.protocols ) return;
		for( let p of this.currentBoard.protocols )
			if( p.hash===hash ) return p;
	}

	get #location() {
		let loc = `?q=${this.data.strid}#`;
		if( this.currentBoard ) loc += `b${this.currentBoard.no || this.currentBoard.boardno}`;
		if( this.#watch?.plrs ) loc += `w${this.#watch.plrs[0]}`;
		else if( this.currentProtocol )
			loc += `p${this.currentBoard.protocols.indexOf( this.currentProtocol )}`;
		return loc;
	}

	protocolsClick( e ) {
		let elhash = e.target.closest( '[data-protocolhash]' );
		if( !elhash ) return;
		let hash = elhash.dataset.protocolhash,
			p = this.findProtoByHash( hash );
		if( p ) {
			log( 'Switching to protocol ' + hash );
			this.currentProtocol = p;
			cardsSolo.setProtocol( {
				...p,
				players: this.#getPlayers( p.players )
			} );
			cardsSolo.setLocation( this.#location );
			e.target.closest( '.bigwindow' )?.hide();
			// Mark current protocol in groupedprotocols sheet
			let sheet = this.#protocolsPanel?.$( '.groupedprotocols' );
			if( sheet ) for( let el of sheet.$$( '[data-protocolhash]' ) )
				el.classList.toggle( 'selected', el.dataset.protocolhash===hash );
		}
	}

	#getPlayers( nums ) {
		if( !nums ) return;
		if( this.data.players )
			return nums.map( x => this.data.players[x] ).map( x => x.name || x );
		else
			return nums.map( x => x.name || x );
	}

	#sendBoard() {
		let soloParams = {
			...this.currentBoard,
			...this.currentProtocol,
			players: this.#getPlayers( this.currentProtocol?.players ),
			boards: this.data.boards,
			myEvent: this,
			mode: 'view',
			location: this.#location
		};
		if( this.#solo )
			this.#solo.init( soloParams );
		// else
		// 	soloGo( soloParams );
		this.#protocolsPanel?.hide();
		this.makeGroupedProtocolsSheet( this.currentBoard?.groupedProtocols );
	}

	#goBoard( no, options ) {
		// Open diagram for board and then try to show protocols
		let board = this.data.boards[+no];
		if( !board ) {
			toast( `Board ${no} not found` );
			board = this.data.boards[0];
			if( !board )
				return;
		}
		this.currentBoard = board;
		if( options?.table ) {
			// Locate protocol by ID
			this.currentProtocol = findBoardProtocol( board, options.table );
		} else if( options?.player ) {
			this.currentProtocol = findPlayerProtocol( board, options.player )
		} else {
			// Find protocol with the same players like current
			this.currentProtocol = findPlayerProtocol( board, this.currentProtocol?.players[0] )
		}
		// Drop #watch if protocol changed
		if( this.#watch?.plrs[0] ) {
			if( !this.currentProtocol?.players.includes( this.#watch.plrs[0] ) )
				this.#watch = null;
		}
		this.#sendBoard();
	}

	setSolo( solo ) {
		this.#solo = solo;
	}

	#getPairName( plrs ) {
		let names = this.#getPlayers( plrs ),
			scorename = `${names[0]} - ${names[1]}`;
		return scorename;
	}

	async #selectWatch() {
		if( !this.currentProtocol ) return;
		let tools = await import( './tools.js' ),
			prot = this.currentProtocol,
			select = [[prot.players[0], prot.players[2]], [prot.players[1], prot.players[3]]],
			items = [{ id: prot.players[0], title: this.#getPairName( select[0] ) },
				{ id: prot.players[1], title: this.#getPairName( select[1] ) }];

		let pair = await tools.selectItem( {
			title: '{Whoseresultsarewelookingat}',
			items: items,
		} );

		if( pair )
			this.#watch = {
				plrs: [ pair.id ]
			}

	}

	async roseClick() {
		if( !this.#watch ) {
			// TODO Select watching player/pair for pair/individual tournament
			await this.#selectWatch();
		}
		if( !this.#watch && !this.currentProtocol ) {
			// No selected players. Need to show full results
			return;
		}
		let plrs = this.#watch?.plrs ||
			[this.currentProtocol?.players[0], this.currentProtocol?.players[2]];
		if( !plrs ) {
			// No selected players. Need to show full results
			return;
		}

		// Show all boards of this event
		// if( !this.#scoreBoardPanel ) {
		// Fill scoreBoard for current player.
		// Pick protocols
		let protos = [], partners = new Set;
		for( let b of this.data.boards ) {
			let prot = findPlayerProtocol( b, plrs[0] );
			if( prot ) {
				if( plrs.length===1 && partners.size<=1 )
					partners.add( getPartner( prot, plrs[0] ) );
				protos.push( { ...prot, boardno: b.boardno } );
			}
		}
		if( plrs.length===1 && partners.size===1 )
			plrs.push( [...partners][0] );
		let scorename = this.#getPairName( plrs );
		// Serialize protocols
		let str = `<div class='sheet' style='overflow: scroll; max-height: 95vh'>
				<table class='collapse' style='text-align: right; border-spacing: 1em 0.1em; font-family: monospace'>
				<thead><tr><th colspan=10>${scorename || ''}</th></tr></thead>`;
		for( let prot of protos ) {
			let side = findPlayerPos( prot, plrs[0] ),
				inverted = side % 2===1 ? -1 : 1,
				score = +prot.score * inverted,
				mscore = inverted=== -1 ? this.#invertScore( +prot.mscore ) : +prot.mscore,
				tr = prot.tricks;
			tr = ({ '+1': '+', '-1': '-' }[tr] || tr) ?? '';
			str += `<tr class='clickable' data-closeselect='show.b${prot.boardno}w${plrs[0]}'><td style='font-size: 1rem; color: var(--light_gray)'>${prot.boardno}</td>
					<td class='emoji' style='text-align: left;'>${prot.prettyContract || ''}</td>
					<td style='font-size: 80%'>${tr}</td>
					<td style='font-size: 1rem; color: var(--light_gray)'>${score}</td>
					<td style='color: ${this.impcolor( mscore )}'>${this.impformat( mscore, true )}</td>
					</tr>`;
		}
		str += '</table></div>';
		this.#scoreBoardPanel = makeBigWindow( {
			repeatid: 'scoreboard_protocols',
			html: str
		} );
		// }
		let action = await this.#scoreBoardPanel.promiseShow();
		this.#act( action );
	}

	onclick( e ) {
		this.#act( e.target.dataset.action );
	}

	#act( action ) {
		if( !action ) return;
		if( action==='nextboard' ) {
			let idx = this.data.boards.indexOf( this.currentBoard );
			this.#goBoard( idx + 1 );
			return true;
		}
		if( action==='prevboard' ) {
			let idx = this.data.boards.indexOf( this.currentBoard );
			this.#goBoard( idx - 1 );
			return true;
		}
		if( action==='score' ) {
			this.#protocolsPanel.show();
			return true;
		}
		let parts = action.split( '.' );
		if( parts[0]==='show' ) {
			this.goto( parts[1] );
			return true;
		}
	}

	goto( str ) {
		let board, prot;
		this.#watch = null;
		if( +str>=0 ) {
			board = this.data.boards[+str-1];
		} else {
			for( let p of [...str.toLowerCase().matchAll( /(\w)(\d+)/g )] ) {
				switch( p[1] ) {
					case 'b':
						board = this.data.boards[+p[2]-1];
						break;
					case 'w':
						this.#watch = { plrs: [+p[2]] };
						prot = findPlayerProtocol( board, +p[2] );
						break;
					case 'p':
						prot = board?.protocols[+p[2]];
						break;
				}
			}
		}
		board ||= this.data.boards[0];
		if( board ) this.currentBoard = board;
		this.currentProtocol = prot;
		this.#sendBoard();
	}

	checkIcons( solo ) {
		let icons = solo.icons,
			idx = this.data.boards.indexOf( this.currentBoard );
		icons.prevBoard.show();
		icons.nextBoard.show();
		icons.prevBoard.classList.toggle( 'disabled', !(idx>0) );
		icons.nextBoard.classList.toggle( 'disabled', !(idx<this.data.boards.length - 1) );
		/*
				if( this.data.boards ) {
					icons.prevBoard.classList.remove( 'display_none' );
					icons.nextBoard.classList.remove( 'display_none' );
				}
		*/
		// Current protocol score
		let str = ``;
		let prot = this.currentProtocol;
		if( prot ) {
			let d = 'neswNESW'.indexOf( prot.declarer );
			str = (solo.vgame.game.arrowSymbol( d ) || '') + prot.prettyContract + (prot.tricks || '');
			if( !str ) {
				// No contract in protocol. Show score
				str = `NS ${+prot.score>0 ? '+' + prot.score : prot.score}`;
			}
			// str += `${numformat( prot.score )}<span style='color:${impcolor( prot.mscore )}'>${prot.mscore}</span>`;
		} else {
			if( this.currentBoard?.protocols ) {
				str = `${this.currentBoard.protocols.length} protocols`;
			}
		}
		icons.score.setContent( str ?? '' );
	}
}

export async function goEvent( data, strid, hash ) {
	if( oneevent )
		oneevent.init( data );
	else
		new Event( data );
	oneevent?.go( hash || data.innerId || strid );
	// oneevent?.go( strid );
}

function findBoardProtocol( board, tableid ) {
	if( !board ) return;
	for( let p of board.protocols ) {
		if( p.table_id===tableid ) return p;
	}
	return board.protocols[+tableid - 1];
}

function getPartner( protocol, player ) {
	let idx = protocol.players.indexOf( player );
	if( idx>=0 ) return protocol.players[(idx + 2) % 4];
}

function findPlayerProtocol( board, player ) {
	if( !board || !player ) return;
	for( let pro of board.protocols ) {
		if( pro.players.includes( player ) ) return pro;
	}
	return null;
}

function findPlayerPos( protocol, player ) {
	return protocol.players.indexOf( player );
	// return null;
}