"use strict";

window.Poker = {
	HIGH: 0,
	PAIR: 1,
	TWOPAIRS: 2,
	SET: 3,
	STRAIGHT: 4,
	FLUSH: 5,
	FULL: 6,
	FOUR: 7,
	FS: 8,
	ROYAL: 9
};

window.dominoContent = function( str, emptystr ) {
	if( str>='a' ) return (str.charCodeAt(0) - 'a'.charCodeAt(0) + 10).toString();
	return str?.replace( '0', emptystr || '' ) || '';
}

const
	suitsCard = 'scdh',
	suitsDomino = '0123456789abcdefghij',
	rangesCard = 'akqjt98765432',
	rangesDomino = suitsDomino,
	allPacks = new Set,
	module = {
		deck: elephCore?.globalAttr['deck'],
		deckNames: window.coreParams?.decks ||
			['simplecolor', 'simplegerman', 'backcolor', '/atlas', '/nicu/white', '/nicu/simple']
	};

export default module;

elephCore?.setAttr( 'deck', 'simplecolor', true );

if( !module.deckNames.includes( module.deck ) ) {
	if( module.deckNames.includes( '/'+module.deck ) ) module.deck = '/' + module.deck;
	else module.deck = window.coreParams?.defaultdeck || 'simplecolor';
	elephCore?.setAttr( 'deck', module.deck );
}
let defaultDeckRatio = getRatio( module.deck );

// Create card element
module.makeCard = ( str, deck, type ) => {
	let r = str[1].toLowerCase();
	if( r==='1' && type==='cards' ) r = 't';
	// var line = allpngsuits.indexOf( str[0] );
	let isDomino = type==='domino';
	let ranges = isDomino && rangesDomino || rangesCard;
	if( isDomino ) deck = 'domino_simple';
	if( type==='chio' ) deck = 'backcolor';
	let pos = ranges.indexOf( r );
	if( !ranges.includes( r ) ) {
		log( 'BAD CARD ' + str );
		return;
		// debugger;
	}
	let c = html( `<div class='fade solid_card suit nostroke' style='transition: none'></div>` );
	// if( !('animate' in c) )
	// 	c.style['transition'] = 'opacity 0.2s, transform 0.1s';
	c.range = 13 - pos + 1;
	let suits = type==='domino' && suitsDomino || suitsCard,
		suit = str[0];
	c.suit = suits.indexOf( suit );
	if( !isDomino )
		c.dataset.suit = suit;
	let sym = r.toUpperCase();
	if( sym==='T' ) sym = '10';
	c.setAttribute( 'range', sym );
	c.dataset.str = str;
	c.str = str;
	// if( deck ) {
	// 	c.setAttribute( 'fixeddeck', deck );
	// }
	// setSize( c );
	if( type ) c.type = type;
	elephCore?.track( c, 'deck', deck );

	setImage( c, deck || module.deck );
	if( type==='domino' ) {
		c.classList.add( 'column' );
		html( `<span style='color: var( --domclr_${str[0]} )'>${dominoContent( str[0], '&nbsp;' )}</span><span style='color: var( --domclr_${str[1]} )'>${dominoContent( str[1], '&nbsp;' )}</span>`, c );
	} else {
		let corner = html( `<div class='suit nostroke column center'>${sym}</div>`, c );
		corner.dataset.suit = suit;
		elephCore?.track( corner, 'deck', deck );
		let cornerb = construct( '.suit.bottom.nostroke', c );
		cornerb.dataset.suit = suit;
		elephCore?.track( cornerb, 'deck', deck );
	}
	// c.visible = false;
	c.owner = null;
	return c;
};

function getRatio( deck ) {
	// if( LOCALTEST ) return 0.5;
	if( deck.startsWith( 'domino_' ) ) return 0.6;
	if( deck==='backcolor' ) return 0.8;
	return deck==='/atlas' ? 2. / 3. : 14. / 19.;
}

function setImage( card, deck ) {
	// let fixed = card.getAttribute( 'fixeddeck' );
	let str = card.str;
	let r = str.slice( 1 );
	if( r==='t' ) r = '10';
	// card.setAttribute( 'deck', deck );
	let bg = deck, bgcolor= '', content = '', ratio = getRatio( deck );
	if( deck.startsWith( 'simple' ) || deck.endsWith( '_simple' ) ) bg = null;

	switch( deck ) {
		case 'domino_simple':
			let s = str[0];
			if( s==='0' ) s = '';
			content = `<div>${r}</div><div>${s}</div>`;
			break;

		case 'backcolor':
			content = r;
			// bg = LOCALTEST? 'svg/cards/simple.svg#' + r.replace( '10', '1' ).toUpperCase() : null;
			bg = `${IMGEMBEDPATH}/svg/suits/simplecard.svg#` + r.replace( '10', '1' ).toUpperCase();
			break;

		case 'simplecolor':
		case 'simplegerman':
			// content = r + '<br>' + htmlsuits[str[0]];
			// content = '<div class="range">' + r + '</div><div class="suit" suit="' + str[0] + '"></div>';
			bg = null;
			break;

		// case '/atlas':
		// 	bg = IMGPATH + '/svg/cards/atlas.svg#' + str[0] + r;
		// 	bgcolor = 'white';
		// 	break;

		default:
			bg = deck[0]==='/'? IMGPATH+'/' : '';
			if( deck==='/atlas' ) {
				bg += 'svg/cards/atlas.svg#' + str[0] + r.replace( '10', '1' );
				bgcolor = 'white';
			}
			else
				bg += 'svg/cards' + deck + '/' + str[0] + '_' + r + '.svg';
	}
	if( bg )
		card.style.backgroundImage = `url( ${bg} )`;
	else
		card.style.backgroundImage = '';
	card.style.backgroundColor = bgcolor;
	// card.innerHTML = content;
	if( card.cardRatio!==ratio ) {
		card.cardRatio = ratio;
		updateSize( card, card.pack );
		// setWidth( card );
	}
}

module.setFixedHeight = ( c, fh ) => {
	c.fixedHeight = fh;
	setHeight( c, fh );
	// c.style.height = fh + 'px';
	// setWidth( c );
};

/*
function setWidth( c ) {
	let h = c.fixedHeight || pack.unifiedHeight;
	if( !h ) return;
	c.style.width = c.cardRatio * h + 'px';
}
*/

function updateSize( c, pack ) {
	if( c.fixedHeight ) return;
	if( pack ) setHeight( c, pack.unifiedHeight );
}

function setHeight( c, h ) {
	if( !h ) return;
	let ratio = c.cardRatio,
		w = ratio * h;
	if( c.type==='domino' ) {
		// Set fixed size for parts of bone
		let h2 = h/2;
		c.firstElementChild.style.height = h2 + 'px';
		c.firstElementChild.style.width = w + 'px';
		c.lastElementChild.style.height = h2 + 'px';
		c.lastElementChild.style.width = w + 'px';
		h /= 2;
	} else {
		c.style.height = h + 'px';
		c.style.width = w + 'px';
	}
	c.style.fontSize = h + 'px'; // h/4 + 'px';
}

module.setDefaultDeck = newdeck => {
	if( module.deck===newdeck ) return;
	module.deck = newdeck;
	module.deckRatio = getRatio( newdeck );
	let cards = document.querySelectorAll( '.solid_card[data-track="deck"]' );
	for( let i = cards.length; i--; ) {
		let card = cards[i];
		card.dataset['deck'] = newdeck;
		setImage( card, newdeck );
		// setSize( card );
	}

	let codes = document.querySelectorAll( '.suitcode' );
	for( let i = codes.length; i--; ) {
		codes[i].dataset['deck'] = newdeck;
	}
	// Check card sizing
	for( let pack of allPacks ) pack.onresize();
};

// Creade pack for game module (cards set)
module.makePack = game => {
	class Pack {
		constructor( game ) {
			this.game = game;
			this.allSet = new Set;
			this.allcards = [];
			this.allTiles = [];
			this.resizeHolders = new Set;
		}

		get getBaseCardCount() {
			return this.game.handCount;
		}

		get myDeck() {
			if( this.game.isdomino ) return 'domino_simple';
			return module.deck;
		}

		get myRatio() {
			// Ratio for current deck
			return getRatio( this.myDeck );
		}

		getCard( str, prefix ) {
			str = str.toLowerCase();
			let tile = this.allcards;
			if( prefix ) {
				this.allTiles[prefix] ||= [];
				tile = this.allTiles[prefix];
			}
			if( tile[str] ) return tile[str];
			// if( +str>=0 ) return;
			let c = module.makeCard( str, undefined, this.game.gameInfo?.type || 'card' );
			if( !c ) {
				log( 'Cant create card ' + str );
				if( LOCALTEST ) toast( 'Wrong card ' + str );
				return null;
			}
			c.pack = this;
			// c.prefix = prefix;
			if( !c ) {
				bugReport( 'Card not created: ' + str );
				return c;
			}
			tile[str] = c;
			updateSize( c, this );
			// setSize( c );
			this.allSet.add( c );

			c.onclick = e => {
				// Do something on card but not move
				if( !this.game.isPlayer && !window.SOLO ) {
					let holder = c.owner?.holder;
					for( ; holder; holder = holder.parentElement )
						if( holder.onclick ) break;
					if( !holder ) return showSettings();
				}
				// Если это  не карта хода, передадим клик её холдеру
				if( !c.mydraggable && c.owner?.holder ) {
					if( c.owner.onclick )
						c.owner.onclick( e );
					else
						c.owner.holder.click();
				}
			};

			// Помещаем объект в игровой холдер
			(this.game.playCards||this.game.topPanel).appendChild( c );
			return c;
		}

		getCards( str, prefix ) {
			if( !str ) return;
			let res = [];
			// var cells = str.split( '_' );
			let suit;
			// Supports
			// PBN format: x.x.x.x
			// and custom format sXYZsXYsXZ..
			// underscore ("_") means EMPTY card

			let suits = this.game.suits || 'shdc';
			if( Array.isArray( str ) ) {
				for( let c of str ) {
					let card = this.getCard( c, prefix );
					if( card ) res.push( card );
				}
			} else if( str.includes( '.' ) ) {
				let ar = str.split( '.' );
				for( let s = 0; s<ar.length; s++ ) {
					let suit = suits[s];
					if( !suit ) bugReport( 'Zero suit ' + s );
					for( let c of ar[s] ) {
						if( c==='0' && !suits.includes( '0' ) ) continue; // Skip "10" (1 - already 10)
						let card = this.getCard( suit + c, prefix );
						if( card ) res.push( card );
					}
				}
			} else if( this.game.isdomino ) {
				if( str.length===1 ) {
					str = '0' + str;
				}
				let c = this.getCard( str, prefix );
				if( c ) res.push( c );
			} else {
				for( let i = 0; i<str.length; i++ ) {
					let s = str[i].toLowerCase();
					if( s==='_' || s===',' ) continue;
					if( suits.includes( s ) ) {
						suit = s;
						continue;
					}
					if( s==='0' ) {
						res.push( null );
						continue;
					}
					if( s==='?' ) {
						// Незавершенное добавление неизвестной карты, одна на prefix
						// должна быть одна на id cardholder, в сортировке всегда в конце
						// let c = pack.getCard( 'u' + prefix )
					}
					if( !suit ) {
						// bugReport( 'Zero suit 1 ' );
						continue;
					}
					let c = this.getCard( suit + s, prefix );
					if( c ) res.push( c )
				}
			}
			return res;
		}

		startMove( legalcards, legalholders ) {
			// legalcards is an array of cards
			// legalholders is an array of cardHolders where allowed to drop legalcards
			this.game.initMove();
			/*
			 for( let i in allcards ) {
			 let c = allcards[i];
			 if( !legalcards.has( c ) ) continue;
			 c.mydraggable = null;
			 }
			 */

			for( let c of legalcards ) {
				//			console.log( "Set drag for", legalcards[i] )
				//			legalcards[i].draggable = true
				this.game.makeDraggable( c, legalholders )
			}

			//		cardHolder.dropHolders = legalholders
			modules.dragMaster?.startMove( this.game.dragInfo );
		};

		hide( card ) {
			// if( LOCALTEST ) log( 'CARDhide ' + card.str );
			if( card.owner )
				card.dataset.lastowner = card.owner.id;
			delete card.dataset.owner;
			card.owner = null;
			card.lastTransform = null;
			card.onScreen = null;
			card.waitAnimation = null;
			card.hide();
		}

		addResizeUnified( f ) {
			this.resizeHolders.add( f );
			if( this.unifiedHeight ) f( this.unifiedWidth, this.unifiedHeight );
		}

		setDefaultSizes( entry ) {
			let height = entry?.contentRect?.height || this.game.playZone.clientHeight,
				width = entry?.contentRect?.width || this.game.playZone.clientWidth;
			if( !height ) return;

			let hc = this.getBaseCardCount,
				hfit = module.deck==='backcolor'? 9 : 7;

			// Для бриджа в режиме телефона (соотношение narrowportrait)
			// все 4 руки показываются пиктограммой, поэтому должно влезать 12 карт по вертикали
			if( hc===13 && width/height<=.75 ) {
				hfit = 12;
			}

			let h = Math.round( height / hfit );

			if( hc===13 && narrowMedia.matches ) {
				// Считаем, что нам нужно уместить только 10 карт
				hc = 10;
				if( this.game?.isonehandonly() ) {
					hc = 8;
					hfit = 8;
				}
			}

			let ratio = this.myRatio,
				deckname = this.myDeck;

			if( module.deck==='backcolor' ) {
				if( !hc ) return false;
				// В портретном режиме всегда пиктограммный стиль
				// По горизонтали разложим по максимуму
				let hcount = Math.max( hc, hfit ) + 2,
					w = width / hcount;
				h = w / ratio;
				if( h * hfit>height ) {
					h = height / hfit;
				}
			} else {
				let w = h * ratio;
				hc ||= 10;
				if( width<w*hc ) {
					w = width / hc;
					h = w / ratio;
					// h = height / hfit;
				}
			}
			if( module.deck==='/atlas' ) {
				h *= 1.3;
			} else if( module.deck!=='backcolor' ) {
				// h *= 1.15;
			}
			// 7 by vert and at least 10.5 by hor (pref)
			let w = h * ratio;
			if( this.unifiedWidth===w && this.unifiedHeight===h ) return;
			// if( w===unifiedWidht && h===pack.unifiedHeight ) return;
			this.unifiedHeight = h;
			this.unifiedWidth = w;
			log( `Set pack size: ${w} x ${h}` );
			if( this.game.isdomino ) {
				this.rotatedSize = { width: w*2, height: h/2 };
			}
			this.game?.playArea.style.setProperty( '--cardheight', this.unifiedHeight + 'px' );
			return true;
			// let allw = game.playZone.clientWidth;
			// if( unifiedWidth*10.5>allw ) unifiedHeight = Math.round( game.playZone.clientHeight / 8 );
		}

		checkBaseSize() {
			if( this.unifiedHeight && this.unifiedWidth ) return;
			this.onresize();
		}

		delayCheckBaseSize() {
			delay( this.checkBaseSize.bind( this ) );
		}

		onresize( entry ) {
			if( !this.setDefaultSizes( entry ) ) return;
			// log( 'New card size ' + unifiedWidth + 'x' + unifiedHeight + ' of ' + game.playZone.clientHeight );
			/*
							let arr = document.querySelectorAll( '.solid_card:not([fixedsize])' );
							for( let i=arr.length; i--; )
								updateSize( arr[i] );
			*/
			for( let card of this.allSet ) updateSize( card, this );

			for( let f of this.resizeHolders ) f( this.unifiedWidth, this.unifiedHeight );

			for( let o of this.game.playZone.$$( '[data-sizebycard]' ) ) {
				let [w,h] = o.dataset.sizebycard.split('x' );
				if( +w ) o.style.width = ( (+w) * this.unifiedWidth ) + 'px';
				if( +h ) o.style.height = ( (+h) * this.unifiedHeight ) + 'px';
			}
		}
	}

	let pack = new Pack( game );
	allPacks.add( pack );
	pack.setDefaultSizes();

	return pack;
};

