"use strict";

cssInject( 'edit' );
log( 'gametools loaded' );

let leaveWin;
export async function wantsLeave( mayescape ) {
	if( !mayescape ) {
		if( !(await askConfirm( '{Leavetable}?' )) ) return;
		return 'leave';
	}
	if( !leaveWin )
		leaveWin = makeBigWindow( `
		<div class='vertical_form'>
		<button name='leave' data-closeselect='leave'>{Leavetable}</button>
		<button name='escape' class='alert' data-closeselect='escape'>{Complete}</button>
		<button name='cancel' data-closeselect='cancel'>{Cancel}</button>
		</div>` );
	leaveWin.show();
	return new Promise( resolve => {
		leaveWin.$( '[name="leave"]' ).onclick = () => resolve( 'leave' );
		leaveWin.$( '[name="escape"]' ).onclick = async () => {
			if( !(await askConfirm( '{Escapegametimeout}' )) ) return;
			resolve( 'escape' );
		};
		leaveWin.show();
	});

}