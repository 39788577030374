/***
 * Bidding box for bridge, preferance, belote, king, 1000 games
 */
let overlapChecked = false;

function setEnabled( h, e ) {
	if( e )
		h.removeAttribute( 'disabled' );
	else
		h.setAttribute( 'disabled', '' );
}

class King {
	constructor( bidbox ) {
		this.bidbox = bidbox;
		this.holder = construct( '.display_none.bidbox_king.column', bidbox.holder,
			this.click.bind( this ) );

		this.holder.innerHTML = localize( `
			<div class='grid display_none negative' style='grid-template-columns: 1fr 1fr; align-items: stretch;'>
			 <span class='title' style='grid-area: 1/1/auto/span 2'>{Negativegames}</span>
			 <span class='bid control' data-action='tricks'>{tricks}</span><span class='bid control' data-action='hearts'>❤️</span>
			 <span class='bid control' data-action='boys'>👦{boys}</span><span class='bid control' data-action='girls'>👧{girls}</span>
			 <span class='bid control' data-action='king'>👑{_king}️</span><span class='bid control' data-action='last2'>{last2tricks}</span>
			 <span class='bid control' data-action='eralash' style='grid-area: 5/1/auto/span 2'>🥣{Eralash}</span>
			</div>
			<span class='title' style='margin-top: 0.1em'>{Positivegames}</span>
			<div class='column'>
				<div class='flexline spacearound plussuits display_none' style='padding: 0.2em; font-size: 1.5rem'>
				 <!-- <span class='clickable bid' data-suit='s'>♠</span>
				 <span class='clickable bid' data-suit='c'>♣</span>
				 <span class='clickable bid' data-suit='d'>♦</span>
				 <span class='clickable bid' data-suit='h'>♥</span> -->
				 
				 <span class='likeabutton suit' data-suit='s'></span>
				 <span class='likeabutton suit' data-suit='c'></span>
				 <span class='likeabutton suit' data-suit='d'></span>
				 <span class='likeabutton suit' data-suit='h'></span>

				</div>
				<span class='bid control display_none' data-action='bylast'>{bylastcard}</span>
			</div>
			<div class='grid display_none plusgames' style='grid-template-columns: 1fr 1fr'>
			 <span class='bid control' data-action='plus_tricks'>{tricks}</span><span class='bid control' data-action='plus_hearts'>❤️</span>
			 <span class='bid control' data-action='plus_boys'>👦{boys}</span><span class='bid control' data-action='plus_girls'>👧{girls}</span>
			 <span class='bid control' data-action='plus_king'>👑{_king}️</span><span class='bid control' data-action='plus_last2'>{last2tricks}</span>
			 <span class='bid control' data-action='plus_eralash' style='grid-area: 4/1/auto/span 2'>🥣{Eralash}</span>
			</div>
			</div>` );
	}

	parse() {
		let p = this.bidbox.params,
			plusgames = p.otbiv==='games';
		this.holder.$( '.plusgames' ).makeVisible( plusgames );
		this.holder.$( '.plussuits' ).makeVisible( !plusgames );
		this.holder.$( '.negative' ).makeVisible( !!p.zakaz );
		this.holder.$( '[data-action="bylast"]' ).makeVisible( !p.zakaz );
		if( p.mayplay ) {
			let mp = p.mayplay.split( ' ' );
			for( let o of this.holder.$$( '[data-action]' ) ) {
				setEnabled( o, mp.includes( o.dataset.action.replace( 'plus_', '+' ) ) );
			}
			for( let o of this.holder.$$( '[data-suit]' ) ) {
				setEnabled( o, mp.includes( '+tricks' ) );
			}
		}
	}

	click( e ) {
		let t = e.target;
		if( !t ) return;

		let action = t.dataset.action || t.dataset.suit;
		if( !action ) return;

		this.bidbox.confirm( t.dataset.suit || t.textContent, { code: action, suit: t.dataset.suit } );
	}
}

class Bazar {
	constructor( bidbox ) {
		this.bidbox = bidbox;
		this.holder = construct( '.dispay_none.bidbox_bazar.column', bidbox.holder,
			this.click.bind( this ) );
		this.levels = [0, 0];			// levels withot/with kapo
		this.holder.innerHTML = localize( `
			<span class='top fade flexline spacearound'>
				<span class='clickable' data-suit='s'>♠</span>
				<span class='clickable' data-suit='c'>♣</span>
				<span class='clickable' data-suit='d'>♦</span>
				<span class='clickable' data-suit='h'>♥</span>
				<span class='clickable' data-suit='n'>{nt}</span>
				<span class='clickable' data-suit='a'>{alltrumps_}</span>
			</span>
			<div class='middle flexline'>
				<span class='fade' data-action='dec'>-</span>
				<button class='fade suitcode' data-action='bid'></button>
				<span class='fade' data-action='inc'>+</span>
			</div>
			<div class='bottom flexline'>
				<span class='fade suitcode bid' data-code='double'>X</span>
				<label class='fade' data-action='kapo'><input data-action='kapo' type='checkbox'>{kapo}</label>
				<span class='suitcode bid' data-code='pass' data-action='pass'>{pass}</span>
			</div>
			` );
		this.bid = this.holder.$( '[data-action="bid"]' );
		this.dec = this.holder.$( '[data-action="dec"]' );
		this.inc = this.holder.$( '[data-action="inc"]' );
		this.kapocheck = this.holder.$( 'input[data-action="kapo"]' );
		this.kapoholder = this.holder.$( 'label[data-action="kapo"]' );
		this.top = this.holder.$( '.top' );
		this.middle = this.holder.$( '.middle' );
		this.double = this.holder.$( '[data-code="double"]' );
	}

	clear( params ) {
		if( !params ) params = {};
		this.params = params;
		this.nobid = params.nobid;
		this.suit = '';
		this.kapo = this.mustkapo = params.kapo ? 1 : 0;
		this.kapocheck.checked = this.mustkapo;
		this.levels = [params.minlevel || 8, 0];
		this.minlevels = [8, 0];
		if( params.minlevel ) this.minlevels[this.mustkapo] = params.minlevel;
		this.confirm = null;

		const doubles = { X: 'double', XX: 'redouble' }, double = params.double;
		if( doubles[double] ) {
			this.double.setContent( double );
			this.double.dataset['code'] = doubles[double];
		}
		this.double.classList.toggle( 'visible', !!params.double );
		this.top.classList.toggle( 'visible', !this.nobid );

		// Дальнейший код закомментирован, так как правила ББ - можно менять масть после трех пасов
		// если масть изменена, торговля продолжается
		// если так, то даже ставить послений контракт по-умолчанию нехорошо, так как
		// при изменении масти необходимо увеличивать уровень и добавляет путаницу
		if( LOCALTEST ) {
			// if( params.onlylevel )
			// 	this.level = params.onlylevel;

			if( params.onlysuit )
				this.suit = params.onlysuit;
		}

	}

	update() {
		let minplaylevels = [ (this.suit==='a' ? 20 : 8), 0 ];
		this.levels[0] = Math.max( this.minlevels[0], this.levels[0], minplaylevels[0] );
		this.levels[1] = Math.max( this.minlevels[1], this.levels[1] );
		let showbid, kapo = this.kapo,
			incdecvis = true, code = this.suit,
			mainbidvisible = true;
		if( this.confirm ) {
			const codes = { double: 'X', redouble: 'XX' };
			showbid = codes[this.confirm] || this.confirm;
			incdecvis = false;
			code = this.confirm;
			this.bid.dataset.suit = '';
		} else if( this.nobid ) {
			incdecvis = false;
			mainbidvisible = false;
		} else {
			if( kapo ) {
				showbid = '{kapo}';
				if( this.levels[1] ) showbid += '+' + this.levels[1];
			} else {
				showbid = this.levels[0].toString();
			}
			if( this.suit ) showbid += htmlsuits[this.suit];
			this.bid.dataset['suit'] = this.suit;
		}
		code? this.bid.dataset['code'] = code : this.bid.removeAttribute( 'data-code' );
		this.bid.setContent( showbid );
		this.dec.classList.toggle( 'visible', incdecvis && this.levels[kapo]>this.minlevels[kapo]
			&& this.levels[kapo]>minplaylevels[kapo] );
		this.inc.classList.toggle( 'visible', incdecvis );
		this.kapoholder.classList.toggle( 'visible', !this.confirm && !this.nobid && !this.mustkapo );
		this.bid.classList.toggle( 'visible', mainbidvisible );
		// this.middle.classList.toggle( 'visible', this.confirmPass );
	}

	click( e ) {
		let t = e.target;
		if( !t ) return;

		let action = t.dataset.action || t.dataset.code, confirm = this.confirm;
		this.confirm = null;
		switch( action ) {
			case 'dec':
				this.levels[this.kapo]--;
				break;
			case 'inc':
				this.levels[this.kapo]++;
				break;
			case 'double':
				this.confirm = 'double';
				break;
			case 'redouble':
				this.confirm = 'redouble';
				break;
			case 'kapo':
				if( this.mustkapo ) return;
				this.kapo = this.kapocheck.checked ? 1 : 0;
				break;
			case 'bid':
				if( confirm ) {
					this.bidbox.bid( confirm, 'bazarconfirm' );
					return;
				}
				if( !this.suit ) return;
				let b = this.suit;
				if( this.kapo ) b += '!';
				if( this.levels[this.kapo] ) b += this.levels[this.kapo];
				this.bidbox.bid( b, 'bazarbid' );
				break;
			case 'pass':
				if( confirm==='pass' || ( !confirm && !this.suit ) ) {
					this.bidbox.bid( 'pass', 'bazarpass' );
					return;
				}
				this.confirm = 'pass';
		}
		let suit = t.dataset['suit'];
		if( suit && !t.dataset['action'] ) {
			this.suit = suit;
			if( this.params.onlysuit && this.params.onlylevel===this.level )
				this.level = +this.level + 1;
		}

		this.update();
	}
}

export default class Bidbox {
	#explainKeyBinded = this.explainKey.bind( this );
	#emptyBid;

	constructor( game ) {
		this.game = game;
		this.game.bidbox = this;
		this.container = construct( '.display_none.column.bidbox_container' );
		// if( game.isbridge )
		// 	this.container.classList.add( 'phone_fullwide' );
		this.holder = html( `<div class='window bidbox' style='gap: 5px; background: var( --light_white )'></div>`, this.container );
		this.params = {};
		this.suitOrder = "scdhn";
		this.selectLevelSuit = [{ current: null, count: 0 }, { current: null, count: 0 }];
		this.bigBids = new Set;
		this.buttonsLevel = [];
		this.suitButtons = [];
		this.numberButtons = [];
		this.allSuitButtons = {};
		this.disabled = new Set;
		this.extras = {};
		this.extrasVisible = false;
		this.firstBid = {};
		this.showLines = 3;
		this.minLevel = 1;
		this.gameMaxLevel = 5;
		this.maxLevel = 5;
		this.showAllLines = false;
		this.bidLines = [];
		this.holderBig = construct( '.display_none.flexline.spacearound.bigbids', this.holder );
		// holderBig = construct( '.fade.clickable.suitcode.bidbox_bigbid' ),
		this.holderLevels = construct( '.display_none.bidbox_levels.flexline.spaceevenly' );
		this.holderSuits = construct( '.display_none.bidbox_suits' );
		this.holderLines = html( `<div class='display_none bidbox_lines'
			style='position: relative; width:  100%; 
		    display: grid; grid-template-columns: repeat( 5, 1fr );
    		z-index: 10; max-height: 75%; overflow: hidden auto'>
			</div>`, this.holder );
		this.holderNumbers = construct( '.display_none.bidbox_numbers.nooverlapcheck', this.holder );
		// linesDec = construct( '.fade.graybutton.modificator -', modLine, holderLines ),
		this.holderExtra = html( `<div class='display_none bidbox_extra flexline spacebetween nowrap' style='gap: 1em; font-size: 125%; z-index: 5; order: 5'></div>`, this.holder );
		this.modificator = construct( '.display_none.control.modificator ↓ ↓ ↓', this.holderExtra, this.modLine.bind( this ) );
		// this.modificator = construct( '.display_none.control.modificator ↓ ↓ ↓', this.holder, this.modLine.bind( this ) );
		this.holderShare = html( `<button class='display_none bidbox_share' style='order: 10; align-self: center'>{Share}</button>`, this.holder, this.share.bind( this ) );
		this.bazar;

		// holderBig.onclick = e => this.bid( e, bigBid );

		for( let i = 6; i<=10; i++ ) {
			let lvl = construct( '.likeabutton.fade.bidbox_level', this.holderLevels );
			lvl.textContent = i.toString();
			lvl.level = i;
			lvl.onclick = this.select.bind( this );
			lvl.bid = i.toString();
			this.buttonsLevel[i] = lvl;
			// holderLevels.appendChild( lvl );
		}

		let order = 1;
		for( let s in htmlsuits ) {
			let suit = construct( `.likeabutton.fade.suit.bidbox_suit `, this.holderSuits );
			if( s==='n' ) suit.setContent( htmlsuits[s] );
			if( s==='a' ) suit.classList.add( 'display_none' );
			suit.onclick = e => this.select( e, 1 );
			suit.bid = s;
			// suit.suit = s;
			suit.style.order = order++;
			suit.dataset.suit = s;
			suit.suitno = this.suitOrder.indexOf( s );
			this.allSuitButtons[s] = suit;
			// holderSuits.appendChild( suit );
		}

		this.addExtra( 'miser' );
		this.addExtra( 'miserbp' );
		this.addExtra( 'remiz' );
		this.addExtra( 'redeal' );
		this.addExtra( 'double' );
		this.addExtra( 'redouble' );
		this.#emptyBid = html( `<div class='display_none'></div>`, this.holderExtra );
		this.addExtra( 'pass' );
		this.addExtra( 'doreturn' );

		this.holder.appendChild( this.holderLevels );
		this.holder.appendChild( this.holderSuits );

		// Simple box

		// Confirmation box
		this.holderConfirm = construct( '.display_none.bidbox_confirm', this.confirmClick.bind( this ), this.holder );
		this.holderConfirmYes = createappend( 'likeabutton clickable suitcode bidbox_bid bidbody', this.holderConfirm );
		this.holderConfirmNo = construct( '.likeabutton.clickable.suitcode.bidbox_bid {No}', this.holderConfirm );
		this.holderConfirmWarn = construct( '.display_none.warning {Shortsuit}', this.holderConfirm );

		this.show( false );
		this.game.addMoveElement( this.container );
	}

	addExtra( code ) {
		let el = construct( '.likeabutton.display_none.clickable.suitcode.bidbox_bid', this.extraClick.bind( this ) );
		if( code ) {
			this.game.setContract( el, code );
			// el.code = code;
			this.extras[code] = el;
			/*
								lang.setText( el, cap || ( '{' + code + '}' ) );
								el.setAttribute( 'code', code );
			*/
		}
		this.holderExtra.appendChild( el );
		return el;
	}

	explainChange( e ) {
		let value = this.holderExplainText.value;
		// ♠♥♦♣
		this.holderExplainText.value = value.replace( /!([SHDCshdc])$/, ( _, s ) => {
			let n = 'SHDC'.indexOf( s.toUpperCase() ),
				str = [ '♠️', '❤️', '🔶', '♣️' ],
				sum = str[n];
			return sum;
		} );
	}

	isLinesVisible() {
		return this.type==='bridge' && !this.needConfirm;
	}

	checkConfirmVisibility() {
		this.holderConfirm.makeVisible( this.needConfirm && !this.useexplain );
		if( this.useexplain ) this.holderExplain.makeVisible( this.needConfirm );
		if( this.needConfirm && this.useexplain )
			window.addEventListener( 'keydown', this.#explainKeyBinded );
		else
			window.removeEventListener( 'keydown', this.#explainKeyBinded );
		this.game.keyCaptured = this.needConfirm ? this.holderExplainText : null;
	}

	checkBazar() {
		if( !this.bazar )
			this.bazar = new Bazar( this );
		this.bazar.clear( this.params );
		this.bazar.update();
	}

	checkVisibility() {
		let f = this.type==='pref' && (this.subType==='bandit' || this.subType==='final') && !this.bigBids.size,
			t1000 = this.type==='1000';

		this.holderBig.makeVisible( this.bigBids.size && !this.needConfirm );
		this.holderLevels.makeVisible( f && !this.needConfirm );
		this.holderSuits.makeVisible( f && !this.needConfirm );
		this.holderLines.makeVisible( this.isLinesVisible() );
		this.modificator.makeVisible( this.isLinesVisible() && !this.showAllLines );
		this.holderExtra.makeVisible( this.extras && !this.needConfirm );
		this.holderNumbers.makeVisible(  t1000 && !this.needConfirm );
		if( this.type==='bazar' ) this.checkBazar();
		this.bazar?.holder.makeVisible( this.type==='bazar' );
		if( this.type==='king' ) {
			this.king.parse();
			this.king.holder.makeVisible( !this.needConfirm );
		}

		if( this.type==='updown' ) {
			this.updownParse();
		}
		this.holderUpdown?.makeVisible( this.type==='updown' );

		this.checkConfirmVisibility();
	}

	get useexplain() {
		return this.type==='bridge';
	}

	hide() {
		this.show( false );
	}

	async show( v ) {
		if( this.container.isVisible()===v ) {
			if( v )
				this.#checkPale();
			return;
		}		// Nothing changed
		log( v? 'Showing bidbox...' : 'Hiding bidbox...' );
		if( v ) {
			await cssInject( 'cards' );
			log( 'Showing bidbox cssInject completed...' );
		}
		this.container.makeVisible( v );
		// В играх с несколькими кругами торговли привяжем/отвяжем от себя показ торговли
		// (бридж, преферанс, белот-базар)
		if( this.game?.isbridge || this.game?.ispref || this.game?.isbazar ) {
			let auction = this.game.ext.auction_center,
				newparent = v? this.container : this.game.getTopPanel;
				// newparent = v? this.holder : this.game.playZone;
			if( auction && auction.holder.parentElement!==newparent )
				newparent.appendChild( auction.holder );
			// this.game.playZone.appendChild( auction.holder );
		}
		if( v )
			this.#checkPale();
		if( !v ) {
			for( let el of this.game.playArea.$$( '.pale' ) )
				el.classList.remove( 'pale' );
		}

		if( v ) {
			let check = checkOverlap?.( true, this.container );
			if( check && !overlapChecked ) {
				if( Math.random()>0.95 )
					bugReport( 'Bidbox overlapping' );
				overlapChecked = true;
			}
		}

		let mp = this.game.getMyPlace();
		if( (this.params.plno??mp)===mp )
			this.game.checkArrow( mp );
	}

	#checkPale() {
		if( this.params.playfor>=0 ) {
			// Gray all cards of other players
			let plno = this.params.playfor.toString();
			for( let el of this.game.playArea.$$( `.solid_card` ))
				el.classList.toggle( 'pale', el.dataset.owner!==plno );
			for( let el of this.game.playArea.$$( `.play_nick` ) )
				el.classList.toggle( 'pale', el.dataset.plno!==plno );
		}
	}

	checkSelected() {
		let suit = this.selectLevelSuit[1].current && this.selectLevelSuit[1].current['bid'],
			isshort = suit && this.maxLevels?.[suit]<10;
		if( !this.selectLevelSuit[0].current || !this.selectLevelSuit[1].current ) {
			// Check for automove
			if( this.selectLevelSuit[1].current ) {
				let suit = this.selectLevelSuit[1].current.dataset.suit;
				if( this.maxLevels?.[suit]===this.firstBid.level ) {
					this.confirm( suit + this.firstBid.level, {
						warnshort: this.isshort
					} );
					return;
				}
			}
			return;
		}
		// Check if its legal
		if( this.firstBid && this.selectLevelSuit[0].current.level<this.firstBid.level ) return;
		// if( firstBid.level==selectLevelSuit[1].current.level && selectLevelSuit[0].current )
		// Confirm selected
		let code = suit + this.selectLevelSuit[0].current.bid;
		this.confirm( code, {
			warnshort: this.isshort
		} );
	}

	checkLevels() {
		// Check which levels are possible for current suit
		let s = this.selectLevelSuit[1].current;
		for( let l in this.buttonsLevel ) {
			let e = (!this.firstBid || l>=this.firstBid.level) && (!this.lastLevel || l<=this.lastLevel);
			let h = this.buttonsLevel[l];
			setEnabled( h, e );
			let dis = this.disabled.has( l ) || (s && this.disabled.has( s.suit + l ));
			h.makeVisible( !dis );
		}
	}

	checkSuits() {
		// Check which suits are possible at current level
		if( this.type!=='pref' ) return;
		let l = this.selectLevelSuit[0].current,
			level = l?.level;
		if( FANTGAMES ) log( 'Checking suits for level ' + level );
		for( let s = 0; s<5; s++ ) {
			let suit = this.suitOrder[s];
			let e = level && (this.firstBid ? (level>this.firstBid.level || (level===this.firstBid.level && s>=this.firstBid.suitno)) : true);
			if( this.maxLevels ) {
				let m = this.maxLevels[suit];
				if( !m || l?.level>m ) {
					// log( `Disable ${level}:${suit} because m=${m} and ${l?.level}` )
					e = false;
				}
			}
			if( e && (this.disabled.has( suit ) || (l && this.disabled.has( suit + l.level ))) ) {
				// log( `Disable ${level}:${suit} because disabled=${JSON.stringify([...this.disabled])}` )
				e = false;
			}
			if( !l ) e = false;			// Без уровня нет мастей
			this.suitButtons[s].makeVisible( e );
			// setEnabled( suitButtons[s], e );
			if( !e && this.selectLevelSuit[1].current===this.suitButtons[s] ) this.deselect( 1 );
		}
	}

	addBigBid( code ) {
		if( !code ) return;
		let el = construct( '.bid.suitcode.clickable', this.holderBig, this.bid.bind( this ) );
		this.bigBids.add( el );
		this.game.setContract( el, code, 'compact' );
		// holderBig.classList.toggle( 'visible', bigBid );
	}

	checkExplainBid() {
		this.holderConfirmYes.classList.toggle( 'alert', this.holderExplainAlert?.checked || false );
	}

	confirm( e, p ) {
		let text = e;
		if( e instanceof Event ) text = e.currentTarget.dataset.code;
		let code = p?.code || text;
		if( this.params.skipexplain ) {
			this.bid( code, 'confirm' );
			return;
		}
		this.needConfirm = code;
		this.game.setContract( this.holderConfirmYes, text );
		this.game.setContract( this.holderExplainOk, text );
		// game.setContract( holderExplainBid, code );
		this.holderConfirmWarn.makeVisible( !!p?.warnshort );
		if( this.holderExplain ) {
			this.holderExplainText.value = '';
			this.holderExplainAlert.checked = false;

			if( !('ontouchstart' in window) )
				setTimeout( () => {
					this.holderExplainText.focus()
				}, 100 );
		}
		this.holderConfirm.dataset.code = code;
		// this.holderConfirmYes.dataset.suit = p.suit || '';
		this.checkExplainBid();
		this.checkVisibility();
		this.checkConfirmVisibility();
	}

	cancelExplain() {
		this.needConfirm = null;
		this.checkVisibility();
	}

	alertChange( e ) {
		bugReport( 'alertChange ' + JSON.stringify( e ) );
	}

	explainKey( e ) {
		if( e.target.mytouch || e.target.dataset.mytouch ) return;
		if( e.key==='Escape' ) {
			this.cancelExplain();
			e.stopPropagation();
			return false;
		}
		if( e.key===' ' && !this.holderExplainText.value ) {
			let ch = this.holderExplainAlert.checked;
			// log( 'invert alert from ' + ch );
			this.holderExplainAlert.checked = !ch;
			e.stopPropagation();
			return false;
		}
		if( e.key==='Enter' ) {
			if( !this.needConfirm ) return;
			this.explainClick( 'ok' );
			e.stopPropagation();
			return false;
		}
		this.holderExplainText.focus();
	}

	explainClick( e ) {
		let ct = e.currentTarget;
		if( e==='ok' || (ct && ct.dataset.value==='ok') ) {
			// Do bid
			let str = '';
			if( this.holderExplainAlert.checked ) str += '!';
			str += this.needConfirm;
			if( this.holderExplainText.value ) str += ' ' + this.holderExplainText.value;
			this.bid( str, 'explain' );
			this.needConfirm = null;
			setTimeout( this.cancelExplain.bind( this ), 200 );
		} else if( ct?.dataset.value==='cancel' ) {
			this.cancelExplain();
		}
	}

	confirmClick( e ) {
		let el = e.target, code;
		for( ; el && el!==e.currentTarget; el = el.parentElement ) {
			code = el.dataset.code;
			if( code ) break;
		}
		let sendCode = this.needConfirm || code;
		this.needConfirm = null;
		if( code ) {
			this.bid( sendCode, 'confirmclick' );
			setTimeout( () => this.holderConfirm.hide(), 200 );
		} else {
			// Cancel selected bid, need to drop selections
			this.holderConfirm.hide();
			this.deselect( 0 );
			this.deselect( 1 );
			this.checkSuits();
			this.checkVisibility();
		}
	}

	extraClick( e ) {
		let code = e.target?.dataset.code /* || defaultBid */;
		if( !code ) {
			// bugReport( 'Try to bid NULL??' );
			return;
		}
		// if( ( code==='pass' && !this.params['confirmpass'] ) || code==='doreturn' ) {
		if( code==='pass' || code==='doreturn' ) {
			this.bid( code, 'extra' );
			return;
		}
		this.confirm( code );
	}

	deselect( idx ) {
		let sel = this.selectLevelSuit[idx],
			curr = sel.current;
		if( !curr ) return null;
		if( sel.count===1 && sel.current===sel.one ) return null;
		curr.removeAttribute( 'selected' );
		curr.parentElement.removeAttribute( 'childselected' );
		sel.current = null;
		return curr;
	}

	select( e, idx ) {
		let el = e.currentTarget || e;
		if( idx===undefined ) idx = this.buttonsLevel.includes( el ) ? 0 : 1;
		if( !el ) return;
		let sel = this.selectLevelSuit[idx];

		// Если уже выбрано, ничего не делаем
		// if( sel.count===1 && selectLevelSuit[idx].current===el ) return;
		if( el===this.deselect( idx ) ) {
			// Нажатие сняло selected. Если это уровень, уберем масти?
			if( idx===0 ) this.checkSuits();
			return;
		}
		if( sel.current!==el ) {
			el.setAttribute( 'selected', true );
			el.parentElement.setAttribute( 'childselected', true );
			this.selectLevelSuit[idx].current = el;
		}

		this.checkSuits();

		// Если не нужно выбирать масть для этого уровня, сразу в подтверждение
		if( el.level && this.isAllowed( el.level ) ) {
			this.confirm( el.level.toString() );
			return;
		}

		this.checkLevels();
		this.checkSelected();
	}

	bid( e, reason ) {
		let action = e;
		if( typeof e==='object' )
			action = e.currentTarget?.dataset.code || e.target.dataset.code;
		if( !action ) return;
		if( action==='null' ) {
			// Catch unknown bug with. Installed 19/01/24.
			// Watch it and delete
			// Seems to be fixed 14/02/24
			if( !window.cordova ) {
				log( `Bid event ${this.game.gameInfo?.id} (${reason}): ${JSON.stringify( e )}` );
				log( 'Event: ' + JSON.stringify( e ) );
				if( !window.nullBidReported ) {
					bugReport( 'Bid null' );
					window.nullBidReported = true;
				}
			}
			return;
		}
		this.show( false );
		this.game.sendMove( 'bid ' + action );
		this.bazar?.clear();
	}

	parseExtra( str ) {
		let ar = str?.split( ',' );
		ar = ar?.map( v => v==='*' && 'double' || v==='**' && 'redouble' || v );
		for( let code in this.extras ) {
			this.extras[code].makeVisible( ar?.includes( code ) || false );
		}
		this.extrasVisible = ar?.length>0 || false;
		if( this.game.isbridge ) {
			this.#emptyBid.makeVisible( ar.length===1 );
		}
	}

	setType( o ) {
		let t = o['type'];
		if( this.type!==t ) {
			this.type = t;
			this.suitOrder = this.type==='bridge' ? 'cdhsn' : 'scdhn';
			for( let s = 0; s<5; s++ )
				this.suitButtons[s] = this.allSuitButtons[this.suitOrder[s]];
		}
		this.subType = o.subtype;
		this.game.holder.setAttribute( 'bidboxsize', (this.type==='pref' && this.subType==='auction') ? 'small' : 'big' );
		// if( type==='1000' ) extras['remiz'].setContent( '{remiz1000}' );
		// holderConfirmExplain.classList.toggle( 'visible', type==='bridge' );
		this.holder.dataset.type = t;

		if( this.useexplain && !this.holderExplain ) {
			// Explain box
			this.holderExplain = construct( '.display_none.bidbox_explain', this.holder );
			this.holderExplainLabel = construct( ' Alert', 'label', this.holderExplain );
			this.holderExplainAlert = construct( '.alertbox[type=checkbox][tabindex=-1]', 'input', this.holderExplainLabel );
			this.holderExplainText = construct( '.explain[placeholder={Comment}]', 'input', this.holderExplain );
			this.holderExplainOk = construct( '.likeabutton.clickable.bidbox_bid.suitcode.default[data-value=ok] Ok', this.holderExplain, this.explainClick.bind( this ) );
			this.holderExplainCancel = construct( '.cancel[data-value=cancel] {Cancel}', this.holderExplain, this.explainClick.bind( this ) );

			this.holderExplainText.oninput = this.explainChange.bind( this );
			this.holderExplainAlert.onchange = this.checkExplainBid.bind( this );
		}

		if( this.type==='king' && !this.king ) {
			this.king = new King( this );
		}

		if( this.type==='updown' && !this.holderUpdown ) {
			this.holderUpdown = html( `<div class='display_none bidbox_levels flexline wrap spaceevenly''></div>`, this.holder, this.bid.bind( this ) );
			for( let i = 0; i<=17; i++ ) {
				let lvl = construct( `.likeabutton.display_none.bidbox_level[data-code=${i}] ${i}`,
					this.holderUpdown );
			}
		}
	}

	numberClick( e ) {
		let t = e.target;
		if( !t || !t.dataset ) return;
		let num = t.dataset['number'];
		this.bid( num, 'number' );
	}

	setNumbers( o ) {
		let nbtns = o['subtype']==='final' ? 0 : 3;
		if( o['min'] && o['type']==='1000' ) {
			let value = o['min'], max = o['max'] || value + 15,
				toval = max, k=0;
			if( o['subtype']!=='final' ) toval = value + 10;
			if( toval<120 ) toval = 120;
			for( ; value<=toval || k<3; k++, value += 5 ) {
				let btn = this.numberButtons[k];
				if( !btn )
					btn = this.numberButtons[k] = construct( '.display_none.bid.suitcode.clickable.visible', this.holderNumbers, this.numberClick.bind( this ) );

				btn.textContent = value;
				btn.dataset['number'] = value;
				btn.makeVisible( true );
				btn.classList.toggle( 'disabled', value>max );
			}
			for( ; this.numberButtons[k]; k++ )
				this.numberButtons[k].hide();
		}
	}

	fillLines() {
		if( !this.isLinesVisible() ) return;
		let idx = 0;
		for( let i = 0; i<this.showLines * 5; i++ ) {
			idx = this.minLevel * 5 + i;
			let is = i % 5, il = (i - is) / 5 + this.minLevel, code = this.suitOrder[is] + il;
			if( this.maxLevel && il>this.maxLevel ) {
				this.showAllLines = true;
				break;
			}
			if( !this.bidLines[idx] ) {
				this.bidLines[idx] = construct( '.display_none.visible.suitcode.likeabutton.clickable.flexline.center.nowrap', this.holderLines, this.confirm.bind( this ) );
				// bidLines[idx].dataset['idx'] = idx;
				this.bidLines[idx].style.order = idx;
				this.game.setContract( this.bidLines[idx], code );
			} else
				this.bidLines[idx].show();
			let e = il>this.firstBid.level || il===this.firstBid.level && is>=this.firstBid.suitno;
			if( this.only && !this.only.bids.includes( code ) ) e = false;
			setEnabled( this.bidLines[idx], e );
		}
		for( idx++; idx<(this.gameMaxLevel + 1) * 5; idx++ )
			if( this.bidLines[idx] ) this.bidLines[idx].hide();

		this.modificator.makeVisible( !this.showAllLines );
	}

	share( e ) {
		this.game.solo.share( 'onemove' );
	}

	modLine( e ) {
		this.showAllLines = true;
		this.showLines = 7;
		this.fillLines();
	}

	parseOnly() {
		this.only = null;
		if( !this.params['only'] ) return;
		this.only = {
			bids: params['only'].split( ',' ),
			minLevel: 0,
			maxLevel: 0
		};
		for( let x of this.only.bids ) {
			let lvl = +x[1];
			if( !lvl ) continue;
			if( !this.only.minLevel || lvl<this.only.minLevel ) this.only.minLevel = lvl;
			if( !this.only.maxLevel || lvl>this.only.maxLevel ) this.only.maxLevel = lvl;
		}
	}

	isAllowed( bid ) {
		return this.allow?.includes( bid.toString() ) || false;
	}

	parseDisable( str ) {
		this.disabled.clear();
		if( str ) {
			let a = str.split( ',' ), carr = {};
			for( let one of a ) {
				this.disabled.add( one );
				if( one.length>1 ) {
					if( !carr[one[0]] ) carr[one[0]] = 0;
					carr[one[0]]++;
					let l = one.slice( 1 )==='0' ? 10 : +one[1];
					if( !carr[l] ) carr[l] = 0;
					carr[l]++;
					if( l===10 ) this.disabled.add( one[0] + '10' );
				}
			}
			for( let k in carr ) {
				let c = carr[k];
				if( typeof k==='number' && c===5 ) this.disabled.add( k );
				else if( c===5 && !this.isAllowed( k ) )
					this.disabled.add( k );
			}
		}
	}

	setMinMaxLevel() {
		this.gameMaxLevel = this.maxLevel = this.type==='bridge' ? 7 : 10; // 7 - showLines + 1;
		this.minLevel = this.firstBid ? (this.firstBid.level<this.maxLevel ? this.firstBid.level : this.maxLevel) : 0;
		if( this.only ) {
			if( this.only.minLevel>minLevel ) minLevel = this.only.minLevel;
			if( this.only.maxLevel<maxLevel ) maxLevel = this.only.maxLevel;
		}
		if( this.maxLevel - this.minLevel<3 ) {
			this.showAllLines = true;
			this.showLines = this.maxLevel - this.minLevel + 1;
		}
	}

	parse( o ) {
		if( !o ) {
			// if( LOCALTEST ) {
				// Одиночки
				// this.parse( { type: 'pref', subtype: 'final', spec: 'miser,', disable: 's6,s7,s8,s9,s0,c6,c7,c8,c9,c0,d6,d7,d8,d9,d0,h6,h7,h8,h9,h0,n6,n7,n8,n9,n0', allow: '9,10,' } );
				// this.parse( { type: 'pref', subtype: 'final', spec: '', disable: 's6,s7,s8,s9,s0,c6,c7,c8,c9,c0,d6,d7,d8,d9,d0,h6,h7,h8,h9,h0,n6,n7,n8,n9,n0', allow: '10,', } );
				// this.parse( { type: 'pref', subtype: 'final', spec: 'miser,', disable: 's7,c7,c8,c9,c0,d7,d8,d9,d0,h7,h8,h9,h0,n7,n8,n9,n0', allow: '6s,6c,6d,6h,6n,8,9,10,', } );
				// Группа
				// this.parse( { type: 'pref', subtype: 'final', spec: 'doreturn,', disable: 's6,s7,s8,s9,c6,c7,c8,c9,d6,d7,d8,d9,h6,h7,h8,h9,n6,n7,n8,n9', } );
				// this.parse( { type: 'pref', subtype: 'final', spec: '', disable: 's6,s7,s8,s9,s0,c6,c7,c8,c9,c0,d7,d8,d9,d0,h7,h8,h9,h0,n6,n7,n8,n9,n0', allow: '6d,6h,', });

				// Группа
				// this.parse( { type: 'pref', subtype: 'final', spec: 'miser,', disable: 'c7,c8,c9,c0,d7,d8,d9,d0,h7,h8,h9,h0,n7,n8,n9,n0', allow: '6s,6c,6d,6h,6n,7,8,9,10,', } );
				// this.parse( { type: 'pref', subtype: 'final', spec: 'doreturn,', disable: 's6,s8,s9,s0,c6,c8,c9,c0,d6,d8,d9,d0,h6,h8,h9,h0,n6,n8,n9,n0', } );
				// this.parse( { type: 'pref', subtype: 'final', spec: 'miser,', disable: 's7,c7,c8,c9,c0,d7,d8,d9,d0,h7,h8,h9,h0,n7,n8,n9,n0', allow: '6s,6c,6d,6h,6n,8,9,10,', } );
				// return;
			// }
			this.show( false );
			return;
		}
		this.params = o;
		this.setType( o );
		this.setNumbers( o );
/*
		this.deselect( 0 );
		this.deselect( 1 );
*/
		// В случае появления bidbox bubble у игрока plno должен исчезать
		this.allow = o.allow?.split( ',' );
		this.parseDisable( o['disable'] );
		this.parseOnly();
		for( let s of 'scdhn' )
			this.allSuitButtons[s].makeVisible( !this.disabled.has( s ) );

		this.needConfirm = null;
		this.firstLevel = o['first'] && (+o['first'].slice( 1 ) || 10);
		this.lastLevel = o['last'] && +o['last'].slice( 1 );
		this.bigBids.clear();
		while( this.holderBig.firstChild ) this.holderBig.removeChild( this.holderBig.firstChild );
		if( o.bids ) {
			for( let b of o.bids ) this.addBigBid( b );
		} else {
			this.addBigBid( (this.subType==='auction' || o['first']===o['last']) && o['first'] );
		}
		// maxSuitLevels = o['maxsuitlevels'] && o['maxsuitlevels'].split( ',' ).map( Number );
		this.maxLevels = o.maxlevels;
		this.checkVisibility();
		this.firstBid = this.firstLevel ? { level: this.firstLevel, suitno: o['first'] && this.suitOrder.indexOf( o['first'][0] ) } : null;

		let one;
		this.selectLevelSuit[0].count = 0;
		this.selectLevelSuit[0].one = null;
		for( let l in this.buttonsLevel ) {
			let e = this.firstLevel && this.lastLevel ? l>=this.firstLevel && l<=this.lastLevel : true;
			let h = this.buttonsLevel[l];
			setEnabled( h, e );
			let vis = !this.disabled.has( l ) || this.isAllowed( l );
			h.makeVisible( vis );
			if( e && vis ) {
				one = h;
				this.selectLevelSuit[0].count++;
			}
		}
		this.deselect( 0 );
		this.deselect( 1 );
		if( this.selectLevelSuit[0].count===1 ) {
			this.selectLevelSuit[0].one = one;
			this.select( one, 0 );
		}

		this.checkSuits();

		this.parseExtra( o['spec'] );

		this.showAllLines = false;
		this.showLines = 2;
		this.setMinMaxLevel();
		for( let i = 5; i<this.minLevel * 5; i++ ) {
			this.bidLines[i]?.hide();
		}
		this.fillLines();
		this.show( true );
		this.game.wantsAction( 'bid' );

		// Hide talon from center of screen.
		// Сохраним его на экране, если он был "брошен" (преферанс) и находится у игрока
		if( this.subType==='final' && this.game.talon ) {
			if( !(+this.game.talon.movedTo?.cardHolder?.id>=0) )
				this.game.talon?.clear();
		}

		// this.holderShare.makeVisible( this.game.solo?.cansharenew || false );

		// Hide my bubble and my bid to keep bidbox clear
		this.game.checkMyBubbleAndBid();
	}

	updownParse() {
		let p = this.params;
		for( let el=this.holderUpdown.firstElementChild; el; el = el.nextElementSibling ) {
			let lvl = +el.dataset.code;
			el.makeVisible( lvl<=p.cardscount );
			el.classList.toggle( 'disabled', p.except.includes( lvl ) );
		}
		this.holderUpdown.show();
	}
}

