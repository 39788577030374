import Subscribe from "./subscribe.js";

export class Touricon {
	constructor( item, parent ) {
		this.id = item.split( 'event_' )[1] || item;
		this.holder = construct( '.control.display_none', parent, this.click.bind( this ) );
		this.holder.innerHTML = `
					<div class='title'>🛡️${this.id}</div>
					<div class='fade counter'></div>
					<div class='doreg'></div>
					<div class='bet'></div>
					<div class='display_none lock'></div>
					<div data-field='timecontrol' class='hideempty'></div>
					<div class='image'></div>`;

		this.sub = Subscribe.add( item, {
			phase: s => {
				this.holder.makeVisible( s!=='finished' );
			}
		} );
	}

	click() {
		fire( 'goroom', this.item );
	}

	static checkIcons( keys, map, parent ) {
		for( let k of keys ) {
			if( map.has( k ) ) continue;
			map.set( k, new Touricon( k, parent ) );
		}
	}
}

