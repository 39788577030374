"use strict";

import Cardholder from './cardholder.js';

export default class Chipoker {
	constructor( game ) {
		log( 'CHIpoker constructor' );
		this.game = game;
		game.setWideModel();
		elephCore?.track( game.playArea, 'deck', 'backcolor' );
		game.module = 'chipoker';
		this.boxes = new Array( 4 );
		this.hand = new Array( 4 );
		this.moveBox = [];
		this.marker = [];
		this.moveCount = 0;
		this.movePlno = undefined;
		this.moveHand;
		// this.buttons.move, buttons.auto, buttons.cancel,
		this.moveAction = null;
		this.ON = false;
		this.showMoveID = 0;
		this.legalCards = null;
		this.onKeep = 0;
		this.moveCards = [];

		this.panel = construct( '.chio.cardspanel.abs100' );
		// panel.style.display = 'none';

		// game.addResizeListener( this.onresize.bind( this ) );

		this.createHolders();

		this.buttonsHolder = html( `<div class='column spacebetween rem' style='position: absolute; left: 103%; height: 100%; top: 0'></div>` );
		this.buttons = {
			auto: construct( 'button.fade.control.chio_autobutton {Auto}', this.buttonsHolder, this.doauto.bind( this ) ),
			cancel: construct( 'button.fade.control.chio_cancelbutton {Cancel}', this.buttonsHolder, this.docancel.bind( this ) ),
			move: construct( 'button.default.fade.control.mybutton.chio_domovebutton {Done}', this.buttonsHolder, this.domove.bind( this ) ),
			sort: html( `<button class='fade control rem' style='position: absolute; right: 103%; top: 0'>SORT</button>`, this.dosort.bind( this ) )
		}
		
		this.infoLittle = construct( '.fade.yellow_moveinfo {Yourmove}', this.panel );

		game.playZone.appendChild( this.panel );

		game.playZone.appendChild( game.moveControls );

		game.dragInfo.onCancelDrag = this.onCancelDrag.bind( this );
		game.dragInfo.onDrop = this.onDrop.bind( this );
		game.dragInfo.onDrag = this.onDrag.bind( this );
		game.dragInfo.onStartDragging = this.onStartDragging.bind( this );
		game.dragInfo.noGoalClick = false;

		this._route = {
			game: this.clearall.bind( this ),
			chiomove: this.routeChiomove.bind( this ),
			chiomovecards: this.setChiomovecards.bind( this ),
			chiomarkers: this.routeChiomarkers.bind( this ),
			chiohand_0: this.setCombs.bind( this, 0 ),
			chiohand_1: this.setCombs.bind( this, 1 ),
			chiohand_2: this.setCombs.bind( this, 2 ),
		};

		game.init = () => this.moveCards = [];
		game.setTopPanel( this.panel );
		game.addRoute( this );
	}

	createHolders() {
		// Проверяем компоненты для игровых рук
		if( !this.lines ) return;
		for( let i = 0; i<4; i++ ) {
			// var mainid = 'chio_hand_' + i;
			let mh = construct( '.chio_hand.display_none.column.lines' + this.lines );
			this.hand[i] = mh;
			this.game.requireLayout( i, mh );
			this.boxes[i] = new Array( 3 );
			// 3 бокса комбинаций
			for( let j = 0; j<this.lines; j++ ) {
				let short = j===this.lines-1,
					ch = this.boxes[i][j] = new Cardholder( this.game, i + '_' + j, {
					plno: i,
					maxVisible: short ? 3 : 5,
					sort: 'always',
					sortType: 'poker',
					scale: 'holder',
					align: 'l',
					stepPercentParent: short ? 34 : 20.3,
					onchange: this.onchange.bind( this ),
					classes: 'chio_oneholder ' + (short?'line3':'line5')
				} );
				ch.combno = j;
				mh.appendChild( ch.holder );
			}
			this.marker[i] = construct( '.chio_hand_marker.hideempty', mh );

			// Создаем компоненты для хода (откуда раскладывать)
			this.moveBox[i] = new Cardholder( this.game, 'movebox_' + i, {
				sort: 'always',
				sortType: 'suit',
				align: 'c',
				// scale: 'holder',
				classes: 'chio_oneholder chio_moveholder fade'
			} );
			mh.appendChild( this.moveBox[i].holder );

			let plr = this.game.checkPlayer( i );
			mh.appendChild( plr.elavatar );
			mh.appendChild( plr.elsitin );
			mh.appendChild( plr.elnick );
			mh.appendChild( plr.elbubble );
			plr.elavatar.classList.add( 'column', 'centered', 'gap01em' );
			plr.eltimer.classList.remove( 'fade' );
//			mh.appendChild( Core.Players[i].eltimer )
			this.panel.appendChild( mh );
		}
		this.game.checkResize( 500 );
	}

/*
	onresize() {
		var p = /!* mainArea || *!/ this.panel.parentElement;
		if( !p ) return;
		// var ph = p.clientHeight;
		// var pw = p.clientWidth;
		let hh = p.clientHeight / 3,	// Треть высоты на панель
			hw = hh * 1.25;
		if( !hh ) return;
		for( var i = 4; i--; ) {
			this.hand[i].style.width = hw + 'px';
			this.hand[i].style.height = hh + 'px';
		}
	}
*/

	dosort() {
		this.moveBox[this.onHand].resort( 'range!poker!sort' );
	}

	domove() {
		this.buttons.move.hide();
		if( !this.candomove() ) return;
		// Проверим корректность хода

		/*					if( moveAction=='nextblock' || !Socket.initialized ) {
								// Берем к обработке следующий XML блок
								Parser.parse_next();
								return
							} */
		this.moveBox[this.onHand].holder.hide();
		this.parse_off();			// Отключим контроли
		let mover = this.movePlno || this.game.myPlace;
		if( this.game.players[mover] ) {
			this.game.players[mover].stopTimer();
			this.game.players[mover].hideArrow();
		}
		this.game.sendMove( 'done' );
	}

	docancel() {
		// Отмена хода: все карты хода переходят в moveBox
		if( !this.ON ) return;
		this.moveBox[this.onHand].add( this.legalCards );
		this.checkMoveControls();
		this.droplittleinfo( true );
		this.game.sendMove( 'drop *' );
	}

	doauto() {
		// Автоход
		if( !this.ON ) return;
		this.buttons.auto.hide();
		this.droplittleinfo( true );
		this.game.sendMove( 'auto' );
	}

	setCombs( handno, str, key, secret ) {
		let boxes = this.boxes[handno];
		if( !boxes ) return;
		let plno = +secret;
		if( isNaN( plno ) ) {
			if( this.game.getMyPlace()===handno ) {
				// My hand will come with secret key
				return;
			}
		}
		let combs = str.split( ';' );
		for( let i = 0; i<combs.length; i++ ) {
			if( !boxes[i] ) continue;
			boxes[i].setStr( combs[i] );
			for( let c of boxes[i].getCards() ) {
				if( !(this.chioMovecards?.has( c )) )
					c.classList.add( 'pinned' );
			}
		}

		if( this.ON ) this.checkMoveControls()
	}

	/*
					function checkmarker( plno ) {
						// Проверим на то не стала ли наша рука мертвой
					}
	*/

	getbonus( cholder ) {
		if( !('combno' in cholder) ) return;
		let pkr = cholder.getPokerComb();
		// if( LOCALTEST ) pkr = { comb: Poker.FULL, range: 10 };
		if( cholder.combno==2 ) {
			if( pkr.comb==Poker.PAIR && pkr.range>=6 ) return pkr.range - 6 + 1;
			if( pkr.comb==Poker.SET ) return pkr.range - 2 + 10;
		} else {
			var b = 0;
			switch( pkr.comb ) {
				case Poker.ROYAL:
					b = 25;
					break;
				case Poker.FS:
					b = 15;
					break;
				case Poker.FOUR:
					b = 10;
					break;
				case Poker.FULL:
					b = 6;
					break;
				case Poker.FLUSH:
					b = 4;
					break;
				case Poker.STRAIGHT:
					b = 2;
					break
			}
			if( cholder.combno===1 ) b *= 2;
			return b
		}
	}

	 checksub( cholder ) {
		let b = this.getbonus( cholder );
//		if( b ) log( "Bonus for "+cholder.plno+"."+cholder.combno+" is "+b)
		cholder.setSub( b ? "+" + b : null )
	}

	onchange( cholder ) {
		this.checksub( cholder )
	}

	parse_off() {
		this.ON = false;
		this.moveAction = 0;
		this.buttons.move.hide();
		this.buttons.cancel.hide();
		this.game.initMove();
		this.hideMoveControls();
		this.droplittleinfo( true );
	}

	onDrop( card, holder ) {
		holder = holder.cardHolder || holder;
		if( !holder ) return;
		this.checkMoveControls();
		this.game.sendMove( card.str + ":" + holder.id )
	};

	onCancelDrag( card, startOwner ) {
		if( !card ) return;
		if( startOwner ) startOwner.add( card );
		this.checkMoveControls();
		return false;
	};

	onStartDragging( card ) {
		if( card.owner ) card.owner.remove( card );
	}

	onDrag( holder ) {
		this.disableMoveControls();
		if( this.showMoveID ) {
			this.droplittleinfo( true )
		}
	};

	async route_move( omove ) {
		if( !omove ) {
			// Stop moving
			this.setChiomovecards();
			this.parse_off();
			return;
		}

		// Через секунду Покажем исчезающий ВАШ ХОД, и будем показывать его каждые 20 сек
		// пока не начнет ходить
		if( this.showMoveID ) clearTimeout( this.showMoveID );
		this.showMoveID = setTimeout( this.showyourmove.bind( this ), 1000 );

		// Добавим кнопки к нашему контейнеру, если они не там
		let mychair = this.game.getMyPlace();
		if( mychair>=0 ) {
			// this.hand[mychair].appendChild( this.buttons.cancel );
			// this.hand[mychair].appendChild( this.buttons.auto );
			// this.hand[mychair].appendChild( this.buttons.move );
			this.hand[mychair].appendChild( this.buttonsHolder );
			this.hand[mychair].appendChild( this.buttons.sort )
		}
		this.buttons.sort.makeVisible( this.legalCards?.length>5 );

		let module = await import( './dragmaster_old.js' );
		let o = omove['on'];
		this.onKeep = o['keep'];
		let onHand = o['hand'];
		this.moveAction = o['action'];
		if( onHand<0 || onHand>=4 ) return;
		this.ON = true;
		this.onHand = onHand;
		let legalboxes = [ this.moveBox[onHand] ];
		for( let b of this.boxes[onHand] )
			legalboxes.push( b );
		// [this.boxes[onHand][0], this.boxes[onHand][1], this.boxes[onHand][2], ];
		let cards = o['cards'];
		this.setChiomovecards( cards );
		this.setMyMove( cards );
		this.legalCards = this.game.cards.getCards( cards );
		this.game.makeDraggable( this.legalCards, legalboxes );
		let params = {};
		this.game.startMove( params );
		this.game.wantsAction( 'move' );
		this.checkMoveControls();
	};

	hidelittleinfo() {
		this.infoLittle.hide();
		this.showMoveID = setTimeout( this.droplittleinfo.bind( this ), 3000 );
	}

	droplittleinfo( nomore ) {
		this.infoLittle.hide();
		if( nomore ) {
			if( this.showMoveID ) clearTimeout( this.showMoveID );
			this.showMoveID = 0
		} else
			this.showMoveID = setTimeout( this.showyourmove.bind( this ), 20000 )
	}

	showyourmove() {
		this.showMoveID = 0;
		this.infoLittle.show();
		requestAnimationFrame( this.hidelittleinfo.bind( this ) )
	}

	setMoveCount( place, count ) {
		this.moveCount = count;
		let box = this.moveBox[place],
			percents = 20 * count,
			step = count ? 100 / count : 10,
			h = box.holder;
		if( count>6 ) {
			h.style.height = '61%';
			if( LOCALTEST ) {
				box.lines = 2;
				box.maxPerline = 8;
			}
			percents /= 1.1;
			step = 100 / 8;
		} else {
			h.style.height = '';
			box.lines = 1
		}
		h.style.width = `min( ${percents}%, 100vw )`;
		h.style.left = (50 - percents / 2) + '%';
		// if( LOCALTEST )
		// 	box.stepPercentParent = step;
	}

	setMove( place, count, str ) {
		//					movePlno = +o.pos;
//		h.setAttribute( "count", moveCount )
//		h.style.width = (emptycard.clientWidth+1)*moveCount + 'px'
		log( `chioSetMove: ${place}/${count} ${str}` );
		for( let k=this.moveBox.length; k--; ) if( k!==place ) {
			if( k===this.game.getMyPlace() ) continue;
			this.moveBox[k].clear();
			this.moveBox[k].holder.hide();
		}
		this.moveHand = place;
		// Над нижним боксом и под остальными боксами
		let box = this.moveBox[place],
			h = box.holder;
		if( this.moveHand===this.game.getpov ) {
			h.style.top = '';
			h.style.bottom = '102%'
		} else {
			h.style.bottom = '';
			h.style.top = '102%'
		}
		this.hand[this.moveHand].appendChild( h );
		box.voidRect();
		if( !count ) count = str.split( ',' ).length;		// Подсчет количества карт хода. TODO: card.toArray()
		this.setMoveCount( place, count );
		if( str || place!==this.game.getMyPlace() ) {
			// Странный вызов, нужен ли он для наших ходов?
			log( `Setting move str ${place}: [${str}]` );
			// true - keepold, сохранить карты в других холдерах (если их уже перетащили пока ходили)
			let added = box.setStr( str, true );
			log( `Added ${added} cards` );
			// Проверка, что все карты видны. Плавающая ошибка, карты хода оказываются невидимы
			for( let c of box.getCards() )
				c.show();
		}
		this.moveCards = box.getCards().slice();
		h.show();
	}

	setMyMove( str ) {
		this.setMove( this.game.getMyPlace(), 0, str );
	}

	routeChiomarkers( o ) {
		const syms = { x: '❌' };
		let ar = o?.split( ',' ) || [];
		for( let k in this.moveBox ) {
			let t = ar[k];
			if( t && t[0]==='(' && k!==this.game.myPlace ) t = '';
			this.marker[k].textContent = syms[t] || t || '';
		}
	}

	setChiomovecards( o ) {
		if( this.chioMovecards )
			for( let c of this.chioMovecards ) c.classList.add( 'pinned' );
		let cards = this.game.cards.getCards( o );
		if( cards ) for( let c of cards ) c.classList.remove( 'pinned' );
		this.chioMovecards = new Set( cards );
	}

	routeChiomove( o ) {
		// if( dom.getAttribute( 'skiptoplayer' )==1 && game.getMyPlace()>=0 ) return;
		if( !o ) {
			for( let o of this.moveBox ) {
				o.holder.hide();
				o.clear();
			}
			return;
		}
		// TODO: в cardHolder должна быть реализована функция
		// setSize( totalcount, maxperline )
		let parts = o.split( ';' ),
			desc = parts[0].split( ',' ),
			place = +desc[0];
		// Для игрока chiomove лучше не обрабатывать, чтобы не произошла отправка
		// сначала .move { on: } с картами, а затем chiomove с неизвестными картами хода
		if( this.game.getMyPlace()===place ) return;
		this.setMove( place, +desc[1], parts[1] );
	};

	hideMoveControls() {
		this.buttons.move.hide();
		this.buttons.sort.hide()
		this.buttons.cancel.hide()
		this.buttons.auto.hide()
		// this.game.inButton.style.display = null;
	}

	disableMoveControls() {
		for( let k in this.buttons ) this.buttons[k].disabled = true;
		// this.game.inButton.style.display = null;
	}

	candomove() {
		return this.ON && this.moveBox[this.onHand].countVisible===this.onKeep;
	}

	checkMoveControls() {
		if( !this.ON ) return;
		let left = this.moveBox[this.onHand].countVisible,
			allow = left===this.onKeep;
		this.buttons.move.show();
		this.buttons.move.disabled = !allow;
		// this.buttons.cancel.makeVisible( left<this.moveCount );
		this.buttons.cancel.show();
		this.buttons.cancel.disabled = left===this.moveCount;
		// this.buttons.auto.makeVisible( left>0 && left>this.onKeep );
		this.buttons.auto.show();
		this.buttons.auto.disabled = left<=this.onKeep;
		this.buttons.sort.makeVisible( this.legalCards?.length>6 );
		this.buttons.sort.disabled = left<=1;
	}

	route_dealstates( str ) {
		var d = str.split( ',' );
		for( let k in d )
			this.marker[k].dataset.now = d[k];
	}

	clearall() {
		this.boxes.forEach( box => box.forEach( comb => {
			comb.clear();
			comb.setSub( null );
		} ) );
		// this.marker?.forEach( m => m.setAttribute( 'state', '' ) );
		for( let k in this.moveBox ) {
			this.marker[k].textContent = '';
			this.moveBox[k].clear();
			this.moveBox[k].holder.hide();
		}
		if( this.game.gameInfo.id )
			this.setLines( this.game.gameInfo.id.includes( '_4' )? 4 : 3 );
		for( let p=4; p--; )
			this.hand[p].makeVisible( p<this.game.maxPlayers );
	}

	setLines( l ) {
		if( this.lines===l ) return;
		this.lines = l;
		this.createHolders();
	}

	route_event( str ) {
		if( str==='newdeal' || str==='newgame' )
			this.clearall();
	}
}
