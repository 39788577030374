/***
 * Game ratings information
 */

function littleInfo( game ) {
	let self = {
			holder: construct( '.fade.lightborder.littleinfo_box' ),
			icon: construct( '.display_none.grayhover.invertdark.info_icon.visible @{Players}' )
		},
		data,
		lastStr;

	let convs = construct( '.shortconventions', self.holder ),
		ratings = construct( '.ratings_table', self.holder );

	// End of constructor
	game.addRoute(
		{
			conventions: o => {
				convs.innerHTML = localize( o ).replace( 'DEMO', currency( 'DEMO' ) );;
			}
		} );

	function rebuild() {
		if( !data || !game.bypos ) return;
		let str = '';
		for( let p of ['top', 'left', 'right', 'bottom'] ) {
			let pos = game.bypos[p];
			if( pos===undefined ) continue;
			str += '<tr><td>' + (p==='left' && '&larr;' || '') + '</td>';
			// str += '<tr><td>'+(rlarrows[p]||'')+shifts[p] + '</td>';
			let plr = game.getPlayer( pos );
			if( !plr ) continue;
			if( plr.user ) {
				str += `<td>${fillMagicIMG(plr.user.getPicture||plr.user.id,24,{ type: 'avatar' }, " class='avatar'")}</td><td>${plr.user.getShowName}</td>
					<td class="rating">${data[pos]}</td>`;
			} else {
				if( !plr.sitavail ) continue;
				str += '<td></td><td colspan="10" class="button">{Seatopen}</td>';
			}
			str += '<td>' + (p==='right' && '&rarr;' || '') + '</td>';
			str += '</tr>';
		}

		if( str===lastStr ) return;
		lastStr = str;
		let html = '<table><tr><th colspan="4"></th><th></th></tr>' + str + '</table>';
		ratings.innerHTML = localize( html );
	}

	game.addRoute( {
		ratings: o => {
			// Format: [ r0, r1, r2, .., rN ]
			// Each element is Number (rating value) or Object holding different information
			data = o;
			rebuild();
		},
	} );

	game.installLittle( 'info', self, true );
	game.onlayout.add( rebuild );

	return self;
}

export default littleInfo;
