// Yandex advertizing. Only gambler.ru
import Timer from "./timer.js";

let ownAds;

(function main() {
	if( !LOCALTEST && !GAMBLERRU ) return;
	if( !DEBUG && !UIN ) return;		// Unathorized
	// if( window.cordova ) return;		// No yandex ads in app

	// Не нужно вообще грузить яндекс, если авторизован и платный
	if( window.NOADUIN===UIN ) return;
	if( !LOCALTEST && window.myself?.pays ) return;			// Плательщикам не показываем

	html( `
		<!-- Yandex.RTB -->
		<script>window.yaContextCb=window.yaContextCb||[]</script>
		<script src="https://yandex.ru/ads/system/context.js" async></script>`, document.head );

	import( 'https://yandex.ru/ads/system/context.js' );
	if( sessionStorage.showingAd ) {
		sessionStorage.showingAd = false;
		setTimeout( showBanner, 2000 );
	} else
		setTimeout( showBanner, 12000 );
})();

let nextOwnTime;
function getOwn() {
	if( !window.allAds ) return;
	// Reshowing our ads every 2 hours
	if( !ownAds ) {
		if( !nextOwnTime || nextOwnTime>=Date.now() ) {
			log( 'Renewing own ads' );
			ownAds = window.allAds ? [...window.allAds] : [];
			nextOwnTime = Date.now() + 2*60*60*1000;
		}
	}
	if( !ownAds ) return;
	for( ; ownAds.length; ) {
		let ad = ownAds.pop();
		if( !ad ) break;
		if( ad.tilldate ) {
			let till = new Date( ad.tilldate );
			if( Date.now() >= till.getTime() ) continue;		// Outdated
		}
		if( ad.fromdate ) {
			let from = new Date( ad.fromdate );
			if( Date.now() < from.getTime() ) continue;		// Not ready
		}
		if( ad.language && window.language!==ad.language ) {
			if( !LOCALTEST ) continue;
		}
		if( ad.countries && !ad.countries.includes( elephCore.auth?.country ) ) return;
		// Substitue
		if( ad.random ) {
			let str = ad.html;
			for( let [k, v] of Object.entries( ad.random ) ) {
				let sval = v[Math.floor( Math.random() * v.length )];
				str = str.replaceAll( `$${k}`, sval );
			}
			ad.html = str;
		}
		return ad;
	}
	ownAds = null;
}

let win, nextShowTime, lastShowTime,
	elClose, elTimer, timer, elAd, elMainbutton,
	time_ad, time_timer, time_hide;
async function showBanner( params ) {
	// if( LOCALTEST ) return;
	if( window.NOADUIN===UIN ) return;
	// Если последний раз менее 2мин назад, не показываем
	if( !LOCALTEST && ( window.myself?.pays || window.myself?.premium || window.myself?.info?.premium ) ) return;			// Плательщикам не показываем
	if( !params?.force && Date.now() < nextShowTime ) return;
	if( !LOCALTEST && !UIN ) return;			// Неавторизованным не показываем
	if( window.STATIC || window.INFRAME ) return;					// Не в сфере пока не показываем
	// На телефоне пока тоже не показываем
	if( narrowPortraitMedia.matches ) {
		log( 'Showbanner mobile' );
		return;
	}
	if( $( '.bigwindow.visible' ) ) return;				// Открыто окно, которое нежелательно закрывать рекламой (авторизация)
	log( 'showBanner 1. Myself=' + JSON.stringify( window.myself ) );

	// Также не показываем, если осталось меньше минуты в той игре, которую он играет (просьба шашистов)
	let game = Swiper.currentGame;
	if( !params?.force && game ) {
		// В некоторых играх рекламы быть не должно (например, обучение)
		if( game.isSolo ) {
			log( 'Solo games (protocols) no ads' );
			return;
		}
		if( game.gameInfo.noads ) {
			log( 'ad: canceled by game' );
			return;
		}

		// В арендных играх реклама не показывается
		let plno = game.getMyPlace(),
			timer = plno>=0 && game.players[plno]?.timer || undefined,
			left = timer?.getValue();
		if( /*plno>=0 &&*/ game.gameInfo.rent>0 ) {
			log( 'ad: rented game. No ads' );
			return;
		}
		if( game.inprogress && left && left<60000 && !timer.addonSec ) {
			log( 'Detected time left: ' + left );
			return;
		}
	}

	win ||= makeBigWindow( {
		id: 'ad',
		html: `<div class='column' style='max-width: 80vw; max-height: 100vh; min-height: 80vh'>
				<div class='bigcontainer column center' style='position: relative; height: 100%; width: 100%; flex-grow: 2; overflow: hidden; max-height: 90vh;'>
				</div>
				 <div class='column center' style='padding: 1em 0 0 0'>
				    <button class='display_none visible column center default' data-action='turnoff' style='padding: 0.5em 1em;'><span>{Disableads}</span>
				    	<span style='font-size: 80%; color: lightgray; white-space: normal'>{Anysubscriptiondisablesad}</span>
					</button>
				    <div class='column center' style='min-height: 1.3em; margin-top: 0.5em; z-index: 1000'>
						<span class='display_none clickable' data-closeselect='cancel'>{Close}</span>
						<span style='color: gray' class='flexline center display_none visible' data-timer=1><span>{Hideadsafter}</span><div is='neo-timer' data-countdown='10s'></div></span>
					</div>
				 </div>
			  </div>`
	});
	win.$( '[data-action]' ).onclick ||= e => {
		if( e.target.closest( '[data-action]' )?.dataset.action==='turnoff' ) {
			// Deactivate ads
			if( !LOCALTEST && !UIN ) return;
			shopping( {
				ids: 'disableads;club;premium',
				reason: 'disableads',
				title: '{Disableads}',
				game: game?.gameInfo?.chainid
			} );
		}
	};
	if( win.isVisible() ) return;

	elClose ||= win.$( '[data-closeselect="cancel"]' );
	elTimer ||= win.$( '[data-timer]' );
	elMainbutton ||= win.$( 'button[data-action="turnoff"]' );
	elAd ||= win.$( '.bigcontainer' );
	elClose.hide();
	delete elClose.dataset.closebyescape;
	elTimer.show();
	// Check if we have our unshowed ads
	let own = getOwn();
	if( own ) {
		elAd.html( `<div id='ad_own' class='column center' style='text-align: left; gap: 1em; max-width: 20em'>${own.html}</div>` );
	} else {
		elAd.html( `<div id='ad_render' style='display: block !important; position: absolute; top:0; left:0; width:100%;height: 100%; overflow: hidden auto'>
						<div style='position: absolute; left: 0; top: 0; width: 100%; 
							text-align: center; padding-top: 3em;'>
							{Annoyingadsloading}...
						</div>
				</div>` );
	}
	elMainbutton.makeVisible( !own );
	timer ||= new Timer(  {
		stopwatch: false,
		visibility: 'runningdown',
		parent: elTimer
	} );
	let period = params?.maxperiod || 10000;
	if( LOCALTEST ) period = 1000;
	timer.setDestination( Date.now() + period, { noserver: true } );
	timer.setDir( -1 );

	if( !own )
		time_ad = setTimeout( pushAd, 200 );
/*
	time_hide = setTimeout( () => {
		win.hide()
	}, 1000*60 ); // через минуту реклама пропадет
*/
	time_timer = setTimeout( () => {
			// Показываем кнопочку "закрыть"
			elTimer.hide();
			elClose.show();
			elClose.dataset.closebyescape = 1;
			win.classList.remove( 'transparent' );
			sessionStorage.showingAd = false;
		}, period - 500 );

	lastShowTime = Date.now();
	nextShowTime = Date.now() + 60000 * 20;		// Через 20 минут следующий показ

	log( 'showBanner show' );
	win.classList.add( 'transparent' );
	let res = await win.promiseShow();
	stopAd();
	if( res==='turnoff' ) {
		// Go and buy cheapest subscription or Premium
		if( !UIN ) return;
		shopping( {
			ids: 'disableads;club;premium',
			reason: '{Disableads}',
			game: game?.gameInfo?.chainid
		} );
	}
	setTimeout( showBanner, nextShowTime - Date.now() + 1000 );
}

function pushAd() {
	if( !win.isVisible() ) return;			// Окно невидимо
	if( !window.yaContextCb ) {
		return;   	// Еще не готово
	}

	window.yaContextCb.push( ()=>{
		Ya.Context.AdvManager.render( {
			renderTo: 'ad_render',
			blockId: 'R-A-438116-2',
			onError: data => {
				log( 'yaerror ' + JSON.stringify( data ) );
			}
		})
	});
}

dispatch( 'onresume', () => {
	// Следующий показ возможен через минуту
	// if( DEBUG ) return;		// Не показываем при переключении
	if( win?.isVisible() ) return;
	let m1 = Date.now() + 60000;
	if( !nextShowTime || nextShowTime>m1 ) nextShowTime = m1;
	if( LOCALTEST || DEBUG ) nextShowTime = Date.now();
	showBanner();
} );

function stopAd() {
	if( !win ) return;
	win?.hide();

	clearTimeout( time_ad );
	time_hide && clearTimeout( time_hide );
	clearTimeout( time_timer );
	time_ad = time_hide = time_timer = null;
	win.ontransitionend = () => { elAd.html( '' ); win.ontransitionend = null; }
	// elAd.html( '' );
	window.yaContextCb = [];				// Сбрасываем все функции рендеров
}

dispatch( 'checkads', () => {
	if( !LOCALTEST && ( window.myself?.pays || window.NOADUIN===UIN ) ) {
		if( win ) {
			toast( 'No more ads. Thanks for your support' );
			stopAd();
		}
	}
} );

window.gameStartCheckBanner = params => {
	log( 'Gamestart checkbanner' );
	// if( !LOCALTEST && ( Date.now() - lastShowTime < ( params?.mininterval || 60000 ) ) ) return; // (меньше минуты назад был последний баннер)
	if( Date.now() - lastShowTime < ( params?.mininterval || 60000 ) ) return; // (меньше минуты назад был последний баннер)
	setTimeout( () => showBanner( params ), 500 );
};

window.testBanner = () => showBanner( { force: true } );