import Subscribe from './subscribe.js';

const nesw = ['', 'n', 'ns', 'sew', 'nesw'];

let map = new Map;

export default class Vgame {
	constructor( options ) {
		let id = options.id;
		if( map.has( id ) ) {
			// Found this vgame. Should activate
			return map.get( id );
		}
		map.set( id, this );
		this.routes = new Set;
		this.id = this.item = id;
		this.options = options;
		this.history = [];
		this.frames = [];
		this.solo = options.solo;
		this.setGame( options.game || options.type );
		let idar = id.split( '_' );
		this.uniq = idar[0]==='game' && idar[1]; // || id;
		if( !this.options.nosubscription && !this.options.setproto ) {
			this.subscribe();
		}
	}

	async subscribe() {
		if( this.uniq?.[0]==='g' ) {
			// Authorization and server confirmation required
			// If no authorization for 2sec, cancel game
			await Promise.race( [ checkAuth(), sleep( 2000 ) ] );
			let ask = UIN && await elephCore.do( 'type=askcome item=' + this.item );
			if( !ask?.allow ) {
				log( 'Not allowed to open game ' + this.uniq );
				window.tab?.close( this.module );
				this.release();
				return;
			}
		}
		this.sub = Subscribe.add( this.item, this.parser.bind( this ) );
		this.routes2sub();
	}

	gotv() {
		if( this.item.startsWith( 'tv-' ) ) return;
		this.item = 'tv-' + this.id;
		this.sub = Subscribe.add( this.item, this.parser.bind( this ) );
		this.routes2sub();
	}

	static isValidType( type ) {
		return (['game', 'bg', 'gammon', 'board', 'chipoker', 'cards', 'domino', 'match', 'poker'].includes( type ));
	}

	routes2sub() {
		for( let f of this.routes ) this.sub?.addFunc( f );
	}

	addParser( func, comment ) {
		this.routes.add( func );
		this.sub?.addFunc( func, comment );
	}

	route( data ) {
		if( !data ) return;
		if( typeof data==='string' ) {
			for( let line of data.split( '\n' ) ) {
				if( !line ) continue;
				let [_, minor, data] = line.match( /^\s*(\S*)\s?(.*)/ );
				if( !data ) data = '';
				Subscribe.route( this.routes, {
					minor: minor,
					data: Subscribe.unserialize5( data ) || data,
				} );
			}
		} else {
			for( let k in data )
				Subscribe.route( this.routes, {
					minor: k,
					data: data[k]
				} );
		}
	}

/*
	route( params ) {
		Subscribe.route( this.routes, params );
	}
*/

	setGame( gamestr ) {
		if( !gamestr ) return;
		this.chainId = gamestr;
		this.gameChain = gamestr.split( '-' );
		let type = this.gameChain[0];
		if( /chess|draughts|ugolki/.test( type ) ) type = 'board';
		this.setType( type );
	}

	async setProto( params ) {
		if( !params ) return;
		let p = params;
		if( typeof p === 'string' ) {
			let up = new URLSearchParams( p );
			p = {};
			for( let [key, val] of up ) p[key] = val;
		}

		let n = nesw[this.game.maxPlayers],
			har = [];
		if( p.plrs ) {
			har = p.plrs.split( ',' );
		} else {
			for( let i = this.game.maxPlayers; i--; ) {
				let h = p['h' + i];
				if( !h ) h = p[n[i] + 'n'];
				if( !h && !har.length ) continue;
				h ||= '';
				if( !h.includes( ':' ) ) h = ':' + h;
				har[i] = h;
			}
		}
		// let handline = '';
		/*
						for( let i = 0; i<game.maxPlayers; i++ ) {
							handline += i + '_hand ' + this.hands[i].PBN + '\n';
						}
		*/
		this.route( `players ${har.join( ',' )}\n
											game { chainid: '${p.game || this.options.game}', viewproto: true, title: '${p.title || ''}' }\n
											matchscore ${p['scoreBar'] || ''}` );
		if( p.bets )
			this.route( `bets ${p.bets}` );
		if( p.cash )
			this.route( `cash ${p.cash}` );
		if( p.btn )
			this.route( `dealerbutton ${p.btn}` );
		if( p.bb )
			this.route( `blinds { big: ${p.bb} }` );
		let proto = p.p;
		if( proto && this.game.parseProto ) {
			await this.game.parseProto( proto, p );
			this.game.protocol.goLast();
		}
	}

	async prepareReplay() {
		this.game.viewProtocol = true;
		// this.game.leaveButton?.classList.add( 'display_none' ) && this.game.leaveButton.hide();
		this.game.protocol ||= new ( await import( './gameplay.js' ) ).Gameplay( this.game );

		this.setProto( this.options.setproto );
	}

	async setType( type, onready ) {
		if( type==='chio' ) type = 'chipoker';
		if( !type || type===this.type ) return;
		if( !Vgame.isValidType( type ) ) return;

		this.type = type;

		let promises = [import( './game.js' )],
			mod = window.promiseGameModule?.( type );
		if( type!=='game' ) promises.push( mod );

		// Запросим подключение модуля. И когда он подгрузится продолжим
		let [game, module] = await Promise.all( promises );
		 	// .then( ( [game, module] ) => {
				// Отсылаем на сервер запрос, чтобы показался этот стол
				// Если стол будет открыт вместо другого открытого, укажем
		if( this.DONE ) {
			// Already done! (game not found at start)
			window.Swiper?.checkCurrent();
			toast( '{Gamenotfound}' );
			return;
		}
		if( !this.game ) {
			this.game = new game.default( this );
			this.game.solo = this.solo;
			if( this.options.onclick ) this.game.playArea.addEventListener( 'click', this.options.onclick );
			// else if( type==='game' ) m.withoutModule = true;
		}
		let g = this.game;

		if( !g.vgame )
			g.initialize( this );

		// 22-10-21 эта строка перенесена под инициализацию
		if( module ) {
			new module.default( g );
		}

		if( !g.obsolete ) {
			this.ready = true;
			onready?.( g );
			// this.options.ongameready?.( this.game );
			if( this.isReplay ) this.prepareReplay();
			this.options.onready?.( g );
			if( this.options.autoshow ) {
				this.show();
				closeBigWindows();
			}
/*
			if( this.options.proto ) {
				(await import( './gameplay.js' ) ).makeProtogame( this, this.options.proto );
			}
*/
			// if( this.options.proto && this.game.parseProto )
			// 	this.game.parseProto( this.options.proto );
		} else {
			log( 'Vgame: New game not found (already obsolete)' );
		}
			// } );
	}

	static all() {
		return map.values();
	}

	static find( item ) {
		let vg = map.get( item );
		if( vg && vg.id!==item ) {
			bugReport( 'Failed findGame ' + item + '. Found ' + vg.id );
			map.delete( item );
			return null;
		}
		return vg;
	}

	static findWithRobots() {
		for( let [_, vg] of map ) {
			if( vg.game && vg.game.gameInfo['withrobots'] ) return vg;
		}
	}

	static findGame( item ) {
		let vg = Vgame.find( item );
		return vg && vg.game;
	}

	static async set( item, type, onready ) {
		if( type && !this.isValidType( type ) ) return null;
		let vg = Vgame.find( item );
		if( vg ) return vg;
		vg = new Vgame( { id: item } );
		if( type ) await vg.setType( type, onready );
		return vg;
	}

	static remove( item ) {

	}

	release() {
		this.DONE = true;
		map.delete( this.id );
		this.sub?.release();
		this.game?.done();
		if( window.Swiper ) Swiper.checkCurrent();
	}

	setModule( module ) {
		this.module = module;
		// if( this.options['autoshow'] && window.tab )
		// 	window.tab.show( module, 'goschedule' );
	}

	get isReplay() {
		return this.options.setproto || this.options.replay;
	}

	async show() {
		// По-умолчанию запихиваем в свайпер, если не указано иное
		if( !this.game ) return;
		if( this.options.bigwindow ) {
			if( !this.game.holder.parentElement ) {
				let win = makeBigWindow();
				win.classList.add( 'protobigwindow' );
				win.appendChild( this.game.holder );
			}
			this.game.holder.parentElement.show();
			return;
		}
		(await import( './swiper.js' )).default.act( this.game.holder );
	}

	parser( data, minor ) {
		// log( 'Test parse: ' + minor + '=' + data );
		if( !minor ) {
			if( data==='DENIED' ) {
				log( 'Game Denied.' );
				this.sub?.release();
				this.sub = null;
				if( elephCore?.plays[this.item] ) {
					log( 'I play here. Wait 3sec and resubscribe...' );
					setTimeout( () => {
						if( this.sub ) return;
						log( 'Resubscribing ' + this.item );
						this.sub = Subscribe.add( this.item, this.parser.bind( this ) );
						this.routes2sub();
					}, 3000 );
					return;
				}
				// Сначала пробуем askcome (если мы авторизованы)
				// при положительном ответе на askcome, надо снова осуществить подписку через некоторое время
				// TODO:
				log( 'Trying tv...' );
				return this.gotv();
			}
			if( data==='LEAVED' ) {
				window.tab?.close( this.module );
				this.release();
				return;
			}
		}
		if( window.TESTER ) {
			// Store all this game to replay
			if( minor==='initialized' )
				this.frames = [ this.history.length ];
			else if( minor==='event' && data==='endofframe' )
				this.frames.push( this.history.length );
			else this.history.push( [ minor, data ] );
		}
		switch( minor ) {
			case 'info':
				if( data['group'] ) {
					this.group = data['group'];
					this.path = 'reger_' + data['group'] + '.mygames';
					let set = Subscribe.get( this.path ) || new Set;
					set.add( this );
					Subscribe.set( this.path, set );
				}
				break;

			case 'phase':
				if( data['id']==='ended' ) {
					// Removing this game
					if( this.group ) {
						let set = Subscribe.get( this.path );
						if( set.delete( this ) )
							Subscribe.set( this.path, set );
					}
				}
				break;

			case 'DONE':
				window.tab?.close( this.module );
				this.release();
				break;
		}
	}
}

window.elephVgame = Vgame;