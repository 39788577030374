/**
 * JavaScript wrapper for libdds, the bridge double dummy solver.
 *
 * To use:
 *
 *   <script>
 *   var Module = {};
 *   </script>
 *   <script src="out.js"></script>
 *   <script src="dds.js"></script>
 */
// First add old school translated script
window.Module = {};

// navigator.hardwareConcurrency to detect core count (for Workers)

/*
let script = document.createElement( 'script' );
script.src = 'js/dds/out.js';
document.head.appendChild( script );

*/

// Cross-origin workers
// https://github.com/CezaryDanielNowak/CrossOriginWorker
const type = 'application/javascript';
const getCrossOriginWorkerURL = (originalWorkerUrl, _options = {}) => {

	const options = {
		skipSameOrigin: true,
		useBlob: true,

		..._options,
	};

	if (!originalWorkerUrl.includes('://') || window.cordova || originalWorkerUrl.includes(window.location.origin)) {
		// The same origin - Worker will run fine
		return Promise.resolve(originalWorkerUrl);
	}

	return new Promise((resolve, reject) =>
		fetch(originalWorkerUrl)
			.then((res) => res.text())
			.then((codeString) => {
				let workerPath = new URL(originalWorkerUrl).href.split('/');
				workerPath.pop();

				const importScriptsFix = `const _importScripts = importScripts;
const _fixImports = (url) => new URL(url, '${workerPath.join('/') + '/'}').href;
importScripts = (...urls) => _importScripts(...urls.map(_fixImports));`;

				let finalURL = `data:${type},` + encodeURIComponent(importScriptsFix + codeString);

				if (options.useBlob) {
					finalURL = URL.createObjectURL(
						new Blob([`importScripts("${finalURL}")`], { type })
					)
				}

				resolve(finalURL);
			})
			.catch(reject)
	);
};

let cssPath = document.querySelector( 'link[href*=".css"]' )?.href,
	n = cssPath.lastIndexOf( '/css/' );

let waiting = new Map,
	path = cssPath.slice( 0, n ) + '/js/dds/dds_worker.js',
	// path = document.scripts[document.scripts.length - 1].src.replace( /\/[^\/]*$/, '/dds/dds_worker.js' ),
	url = await getCrossOriginWorkerURL( path ),
	// worker = new Worker( url, { type: 'module' } ),
	worker = new Worker( url ),
	globalUniq = 0;

export function request( params, callback ) {
	if( !worker ) return;
	log( 'dds request: ' + JSON.stringify( params ) );
	let cache = { ...params };
	delete cache.requestid;
	let cachekey = 'ddscache_' + encodeURI( JSON.stringify( cache ) ),
		cached = cachekey && sessionStorage[cachekey];
	// Check session storage
	if( LOCALTEST ) {
		log( 'LOCALTEST SKIP CACHE FOR DDS!!!' );
		cached = null;
	}
	if( cached ) return { ...JSON.parse( cached ), requestid: params.requestid };
	return new Promise( resolve => {
		// if( params.action==='calculate' )
		// 	checkCalculateHolder();
		let uniq = ++globalUniq;
		worker.postMessage( {
			uniq: uniq,
			cachekey: cachekey,
			...params
		} );
		waiting.set( uniq, {
			resolve: resolve,
			params: params,
			callback: callback
		} );
	} );
}

if( worker ) {
	worker.onmessage = e => {
		// log( 'Message from ddsworker ' + JSON.stringify( e.data ) );
		let wait = waiting.get( e.data.uniq );
		if( e.data.progress ) {
			checkCalculateHolder( e.data );
			wait?.callback?.( e.data.progress, wait.params );
			return;
		}
		waiting.delete( e.data.uniq );
		// Store to cache
		if( e.data.origin.cachekey ) sessionStorage[e.data.origin.cachekey] = JSON.stringify( e.data );
		wait?.resolve?.( e.data );
	}
}

let holder, calcData;
function checkCalculateHolder( data ) {
	calcData = data;
	holder ||= html( `<div class='neo-fixed-stat column center display_none' 
		style='position: fixed; z-index: 100000; right: 0; bottom: 0; padding: 0.5em 1em; border-radius: 5px;
		background: rgba( 0,0,0,0.5 ); color: white; min-width: 4em;
		transition: transform 0.2s; cursor: pointer'>
		  <div class='percent importantsize' style='font-family: monospace'></div>
		  <span class='display_none emoji short visible' style='font-size: 0.8rem; color: #bbb'></span>
		  <div class='display_none full column' style='background: black; min-width: 10em'>
		  <div class='fullbody column' style='background: white; color: black'></div>
		  <button name='viewtask'>Details...</button>
		  <button class='display_none' name='pause' style='transition: filter 0.2s'>⏸︎ Pause</button>
		  <button class='display_none' name='close' >Close</button>
		  </div>
		</div>`, document.body, progressClick );
	let best = data?.progress.beststr || '...',
		full = '',
		contractLevel = +data?.origin?.contract?.[0],
		columns = contractLevel? 3 : 2;
	if( data ) {
		let columns = data.result.cards? 3 : 2;
		if( data.origin.distribution.desc )
			full += `<span style='font-size: 1rem'>${data.origin.distribution.desc}</span>`;
		full += `<span style='font-size: 1rem'>${data.progress.boards} boards in ${Number.parseFloat( data.progress.elapsed.toFixed(0) )}s</span>`;
		if( data.progress.filtered )
			full += `<span style='font-size: 1rem'>${data.progress.filtered} filtered</span>`;
		if( data.result.cards ) {
			full += `<div style='display: grid; grid-template-columns: repeat( ${columns}, minmax(auto, auto) )'>`;
			full += showResultCards( data.result );
			full += `</div>`;
		} else {
			// let cnt = data.result.contracts;
			full += showResultContracts( data.result );
		}
	}

	let state = data? data.progress.percent + '%' : '0%';
	if( data.state ) state += ' ' + data.state;
	holder.$( '.percent' ).setContent( state );
	holder.$( '.short' ).setContent( best );
	holder.$( '.fullbody' ).setContent( full );

	let pb = holder.$( '[name="pause"]' );
	pb.disabled = data.state==='finished';
	pb.setContent( data.state==='pause'? '⏵︎ Resume' : '⏸︎ Pause' );
	pb.makeVisible( data.state!=='finished' );
	holder.$( '[name="close"]' ).makeVisible( !!data.state );
	holder.show();
}

function showResultCards( data ) {
	let ar = Object.entries( data.cards );
	ar = ar.sort( ( x, y ) => {
		let p1 = x[1].reached,
			p2 = y[1].reached;
		if( p1>=0 && p2>=0 && p1!==p2 ) return p2 - p1;
		return y[1].tricks - x[1].tricks;
	} );
	let str = ar.reduce( ( acc, x ) => {
		let str = `<span><span class='emoji'>${htmlsuits[x[0][0].toLowerCase()]}</span><span>${x[0][1].replace( 'T', '10' )}</span></span>`;
		if( x[1].reached ) {
			let cnt = x[1].reached,
				percent = cnt? Number.parseFloat((cnt*100/data.boards).toFixed(1)) + '%' : '';
			str += `<span style='color: green'>${percent}</span>`;
		}
		str += `<span>${x[1].tricks.toFixed( 2 ).toString()}</span>`;
		return acc + str;
	}, '' );

	return str;
}

function showResContract( result, contract, minpercent ) {
	let trump = contract[0],
		level = +contract[1],
		res = {},
		countmin = 0;
	minpercent ??= 20;
	for( let contract of result.contracts ) {
		if( contract.trump!==trump ) continue;
		let reached = contract.reached[level],
			percent = reached * 100 / result.boards;
		if( percent<minpercent ) continue;
		res[contract.declarer] = percent;
		countmin++;
	}
	if( !countmin ) return '';
	let str = `<tr><td class='emoji'>${level}${htmlsuits[trump]}</td>`;
	for( let contract of result.contracts ) {
		let c = res[contract.declarer];
		if( c ) {
			// let reached = c.reached[level],
			// 	percent = ( reached * 100 / result.boards ).toFixed( 1 );
			str += `<td>${c.toFixed(1)}%</td>`;
		} else
			str += '<td></td>';
	}
	str += `</tr>`;
	return str;
}

function showResultContracts( result ) {
	let suitsSet = new Set;
	for( let contract of result.contracts )
		suitsSet.add( contract.trump );
	let str = `<table>`;

	let contractsstr = '';
	for( let contract of [ 'n3', 'n6', 'n7', 'h4', 's4', 'c5', 'd5', 'h6', 's6', 'c6', 'd6' ] ) {
		contractsstr += showResContract( result, contract );
	}
	if( contractsstr ) {
		str += `<tr><thead><th></th>
			${result.contracts.map( x => '<th style="text-align:left">' + 'NESW'[x.declarer] + '</th>' ).join('')}</tr></thead>
			<tbody>${contractsstr}</tbody>`;

	}
	str += `<thead><tr><th colspan='5'>Tricks</th></tr><tr><th></th>
		${result.contracts.map( x => '<th style="text-align:left">' + 'NESW'[x.declarer] + '</th>' ).join('')}</tr></thead><tbody>`;
	let ddtricks = Array( 4 ).fill( {} );
	for( let contract of result.contracts ) {
		ddtricks[contract.declarer][contract.trump] = contract.tricks;
	}
	for( let suit of suitsSet ) {
		str += `<tr><td class='emoji'>${htmlsuits[suit]}</td>`;
		for( let contract of result.contracts ) {
			let tricks = ddtricks[contract.declarer][suit],
				tr = tricks>=7? tricks.toFixed(1) : '';
			str += `<td>${tr}</td>`;
		}
	}

	str += `</tbody></table>`;
	return str;
}

function progressClick( e ) {
	if( e.target.name==='pause' ) {
		worker.postMessage( {
			action: calcData?.state==='pause'? 'resumecalculate' : 'pausecalculate'
		} );
		e.target.disabled = true;
		return;
	}
	if( e.target.name==='close' ) {
		if( !calcData ) return;
		if( !calcData.state ) return;
		if( calcData.state!=='finished' ) {
			worker.postMessage( {
				action: calcData?.state==='pause' ? 'stopcalculate' : 'pausecalculate'
			} );
		}
		holder.hide();
		return;
	}
	if( e.target.name==='viewtask' ) {
		// Open distribution view
		import( './distribution.js' ).then( dmod => {
			let distribution = calcData.origin.distribution;
			dmod.setupDistribution( distribution, {
				button: '{Change}',
				changeonly: true
			} ).then( res => {
				if( res ) {
					// Check changes and
					// Restart calculation with new parameters if needed
					if( JSON.stringify( res )!==JSON.stringify( distribution ) ) {
						request( {
							...calcData.origin,
							distribution: res,
							restart: true
						} );
					}
				}
			});
		})
	}
	holder.$( '.short' ).toggleVisible();
	holder.$( '.full' ).toggleVisible();
	holder.style.background = holder.$( '.full' ).isVisible()? 'black' : 'rgba(0,0,0,0.5)';
}

export async function initCalculate( params ) {
	// let type = params.calculate || 'lead';
	// Type of action detects from side of contract relating to anchor (known) hand
	let
		// anchor = 'nesw'.split( '' ).map( x => [ x, params[x] ] ).sort( ( x,y ) => (y[1]?.length||0)-(x[1]?.length||0) )[0][0],
		// anchorno = 'nesw'.indexOf( anchor ),
		type = params.calctype || ( params.declarer>=0 && params.next%2!==params.declarer%2? 'card' : 'chance' );
	params.desc = 'Lead against ' + params.contract;
	if( type==='chance' )
		params.desc =`{Contractprobability}`;
	let dmod = await import( './distribution.js' );
	let dist = await dmod.setupDistribution( params, {
		button: 'Calculate',
	} );

	if( !dist ) return;
	let leader = type==='card'? params.next : (params.declarer+1)%4;
	request( {
		action: 'calculate',
		calculate: type,
		contract: params.contract,
		contracts: params.contracts,
		trump: params.trump,
		declarer: params.declarer,
		leader: leader,
		known: params.known,
		knownHands: params.knownHands,
		distribution: dist
	}, params.callback )
}