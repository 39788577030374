
window.setCoreParams(   {
	id: 'gamb',
	domain: 'gambler.ru',
	promo: {
		createclub: true
	},
	tableviewstart: '9',
	storelocation: true,
	language: 'ru',
	roomAlias: {
		9: '',
		3: 'bridge', 8: 'belote', 279: 'durak',
		1: 'board', 6: 'checkers', 61: 'chess',
		32: 'bg', 7: 'bg#friends',
		38: 'bg#narde', 52: 'bg#backgammon',
		34: 'sandbox',
		20: 'pref', 48: 'pref#common', 4: 'pref#friends',
		39: 'pref#fants', 56: 'pref#skak'
	}
} );

if( window.location.href.match( /gambler\.ru|gamblergames\.com/ ) )
	coreParams.roomAlias['9'] = 'home';

// Custom modules
window.promiseModule = modname => {
	if( modname==='chat' ) return import( './chat.js' );
	return Promise.resolve();
};

// All game modules start now
window.promiseGameModule = type => {
	if( type==='game' ) return import( './game.js' );
	if( type==='cards' ) return import( './cards.js' );
	if( type==='domino' ) return import( './domino.js' );
	if( type==='board' ) return import( './board.js' );
	if( type==='match' ) return import( './match.js' );
	if( type==='chio' || type==='chipoker' ) return import( './chipoker.js' );
	if( type==='bg' || type==='gammon' ) return import( './gammon.js' );
	if( type==='poker' ) return import( './poker.js' );
	return null;
};

window.promiseGameExtend = type => {
	if( type==='pool' ) return import( './pool.js' );
	if( type==='claim' ) return import( './claim.js' );
	if( type==='bidbox' ) return import( './bidbox.js' );
	if( type==='auction' ) return import( './auction.js' );
	if( type==='handrecord' ) return import( './handrecord.js' );
	if( type==='littleinfo' ) return import( './littleinfo.js' );
};

import( './lang/ru_module.js' );
import( './support.js' );		// Support functions (necessary)
// import( './socket.js' );		// Play via inet
import( './auth.js' );			// Standard authorization
import( './core.js' );
import( './manager.js' );
import( './room.js' );
// import( './reger.js' );
// import( './chat.js' );

if( !window.cordova && 'serviceWorker' in navigator ) {

	navigator.serviceWorker.getRegistrations().then( function( registrations ) {
		for( let registration of registrations ) {
			registration.unregister()
		}
	} );
}

if( document.location.pathname.endsWith( '/register' ) ) {
	import( './auth.js' ).then( module => {
		module.loginForm.goregister();
	} );
}


