// Universal bridge format parser. External module
import { Event } from './event.js';

export function parser( txt ) {
	let boards = parse_pbn( txt );

	if( !boards ) return;

	let event = new Event( {
		boards: boards
	} );
	return event;
}

function parsepbnscore( value, declarer ) {
	let ar = value.split( ' ' );
	if( ar[0]==='NS' ) return +ar[1];
	if( ar[0]==='EW' ) return -ar[1];
	if( +ar[0]>=0 ) {
		// Score of declarer!
		return 'EW'.includes( declarer )? -ar[0] : +ar[0];
	}
	return 0;
}

function parse_pbn( txt ) {
	// [Dealer "N"]
	// 	[Vulnerable "None"]
	// [Deal "N:.J94..Q 4.KT7.. .Q86.J. .A5.5.4"]
	let boardSet = [],
		lastBoard,
		lastGame,
		blocks = txt.split( /\r?\n\r?\n/ ),
		res = {};
	for( let block of blocks ) {
		// Parse one pbn game
		if( !block ) continue;
		let m = block.split( /^(\[.*?\s\".*\"\])$/gm );
		if( !m ) return;
		let game = {};
		for( let i = 0; i<m.length; i++ ) {
			let head = m[i].match( /^\[(.*?)\s\"(.*)\"\]$/ );
			if( !head ) continue;
			i++;
			let body = m[i].trim(),
				cmd = head[1].toLowerCase(),
				value = head[2];
			if( value==='#' ) {
				// Previous value repeating
				value = lastGame?.[cmd];
			}
			game[cmd] = value;
			switch( cmd ) {
				case 'deal':
					// if( b.n ) continue; // Skip repeatitions at the moment
					let [_, f, cards] = value.match( /(\w)\:(.*)/ ),
						first = 'NESW'.indexOf( f.toUpperCase() ),
						ar = cards.split( ' ' );
					for( let i = 0; i<ar.length; i++ )
						game['nesw'[(i + first) % 4]] = ar[i];
					break;
				case 'auction':
					game.auction = `${value}:${body.replaceAll( /\s+/g, ',' )}`;
					break;

				// res.dealer = txt.match( /^\[Dealer \"(\w)\"\]/ )?.[1].toLowerCase();
				// res.vuln = txt.match( /^\[Vulnerable \"(\w+)\"\]/ )?.[1].toLowerCase();
			}
		}
		if( game.score )
			game.score = parsepbnscore( game.score, game.declarer );
		game.players = [ game.north, game.east, game.south, game.west ];
		if( game.board ) game.boardno = +game.board;
		if( lastGame && game.deal===lastGame.deal && game.dealer===lastGame.dealer ) {
			// Same board, only add protocol
		} else {
			lastBoard = {
				n: game.n,
				e: game.e,
				s: game.s,
				w: game.w,
				boardno: game.boardno,
				dealer: game.dealer,
				vulnerable: game.vulnerable,
				protocols: [],
				boardset: boardSet
			}
			boardSet.push( lastBoard );
		}
		game.board = lastBoard;
		lastBoard.protocols.push( game );
		lastGame = game;
	}

	return boardSet;
}

export function toPBN( solo ) {
	let str = solo.fullPBN;

	let win = makeBigWindow( {
		repeatid: 'pbnexport',
		title: '{Export} PBN',
		html: `<pre style='user-select: text; font-size: 1rem; text-align: left; margin: 0 0.5em;'>${str}</pre style='user-select: text; font-size: 1rem; text-align: left; margin: 0 0.5em;'>`
	});
	win.show();
}
