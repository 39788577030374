export default class Gamecards {
	constructor( game ) {
		this.game = game;

		let route = {};

		// Функции парсинга будут выполняться под основным классом game
		this.openHand = [];
		for( let i=10; i--; )
			route[i+'_cardhand'] = this.handParser.bind( this );

		game.addRoute( route );
	}

	handParser( str, key, secret ) {
		// if( secret && +secret!==this.game.myPlace ) return;
		if( secret==='kibi' && !this.game.watchKibi ) {
			this.game.checkKibiAll( true );
		}
		let plno = +key;
		if( isNaN( plno ) ) {
			plno = -1;
			if( key[0]>='0' && key[0]<='9' ) {
				plno = key[0] - '0';
				key = key.slice( 2 );
			}
		} else {
			key = '';
		}
		if( plno<0 ) return;
		let hand = this.game.cardHolder[plno];
		if( !hand ) return; // Gate sends excess line
		let conditional = false, wasOpened = hand.isOpened();
		if( str[0]==='?' ) {
			conditional = true;
			str = str.slice( 1 );
		}
		let myhand = secret && secret!=='s';
		if( !myhand ) {
			this.openHand[plno] = { cards: str, cond: conditional };
			if( this.game.myPlace===plno ) {
				// log( 'Skip this hand' );
				return;
			}
		}
		let op = this.openHand[plno];
		if( myhand && op && (!str || !op.cond) ) str = op.cards;	// For secret field empty value is "no own value, use common"
		hand.setStr( str );
		if( !wasOpened && hand.isOpened( 10 ) && hand.position==='top' ) {
			// Открылись верхние карты, не надо ли убрать littleInfo
			if( !mediaSuperWide.matches ) this.game.hideLittle();
		}
		this.checkOpenedHands();
	}

	checkOpenedHands() {
		let opened = 0;
		for( let i=4; i--; )
			if( this.game.cardHolder[i]?.isOpened() ) opened++;
		let tightly = opened>2 && ( this.game.isbridge || this.game.ispref );
		this.game.setTightly( tightly );
	}
}