//
// Self-playing simplest bridge module
// just for elementary trainig
//

// сделал сразу и для матча и для командника, получилось такое:
// $API /tour?r=get_match&tourid=667080&matchid=65626945
// $API /export?r=get_match&tourid=667080&matchid=65626945

class BridgeTrainer {
	constructor( game, data ) {
		data = { 'S': 'AKQ' };
		this.game = game;
		this.data = data;
		if( data['S'] ) game.parse( '2_cardhand', data['S'], null, 2 );
	}
}