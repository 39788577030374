"use strict";

import './auth.js';

let Core = elephCore,
	Auth = window.modules.Auth;
/*
	head = document.getElementsByTagName( 'head' )[0],
	link = document.createElement( 'link' );
link.type = 'text/css';
link.rel = 'stylesheet';
link.href = 'css/vk.css';
head.appendChild( link );
*/

function checkLogin() {
	if( window.socketLoginStr ) return;
	// Dont do login request while another logged user is active
	if( Auth.failedLoginTypes?.has( 'vk' ) ) return;

	Auth.fetch( {
		type: 'vk',
		auth_key: Core.getParams['auth_key'],
		api_id: Core.getParams['api_id'],
		user_id: Core.getParams['user_id'],
		viewer_id: Core.getParams['viewer_id'],
		access_token: Core.getParams['access_token']
	}).then( json => {
		log( 'VK auth: ' + JSON.stringify( json ) );
	} );
}

Core.login = checkLogin;
// dispatch( 'checklogin', checkLogin );

dispatch( 'invite', ( event ) => {
	if( !window['VK'] ) return;
	VK.callMethod( 'showInviteBox' );
} );

function checkVK() {
	if( !window.VK ) {
		log( 'No VK found, try again in 1sec!' );
		setTimeout( 1000, checkVK );
		return;
	}
	VK.init( () => {
		// VK.callMethod( 'resizeWindow', '100%', 0 );
		checkLogin();
	} );
}

// Load the SDK asynchronously
(function( d, s, id ) {
	let js, fjs = d.getElementsByTagName( s )[0];
	if( d.getElementById( id ) ) return;
	js = d.createElement( s );
	js.id = id;
	js.src = Core.params['apptype']==='vkmob' ? '//vk.com/js/api/mobile_sdk.js' : '//vk.com/js/api/xd_connection.js?2';
	fjs.parentNode.insertBefore( js, fjs );
	setTimeout( checkVK, 2000 );
	log( 'VK Injected' );
}( document, 'script', 'vk-jssdk' ));

