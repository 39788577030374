cssInject( 'purchase' );

let win, registered, refreshed;

const PREMIUM_ID = window.IOS? 'prem' : 'premium',
	prods = [ 'fants_10', 'fants_23k', 'fants_25k', 'fants_100k', 'fants_500k', 'fants_600k', 'fants_1000k' ];

if( !window.FANTGAMES ) prods.push( PREMIUM_ID );

export async function buy( params ) {
	if( !window.CdvPurchase ) {
		log( 'No inapp purchase module. No shopping' );
		return;
	}
	await initialize();

	log( `Lets buy: ${JSON.stringify(params)}` );

	let tobuy = new Set;
	if( params.ids==='fants' || params.deposit ) {
		for( let o of prods ) {
			if( o.includes( 'fants' ) ) tobuy.add( o );
		}
		if( TESTER )
			tobuy.add( 'fants_10' );
	}
	if( params.ids?.includes?.( 'premium' ) )
		tobuy.add( PREMIUM_ID );

	selectProduct( tobuy, params );
}

async function finishPurchaseConsumable( product ) {
	if( !window.UIN ) return;

	log( 'Purchase validate ' + JSON.stringify( product ) );
	let resp = await API( '/validatepurchase', product );
	log( 'Purchase validation result ' + JSON.stringify( resp ) );
	if( resp?.consume ) {
		product.finish();
	}
}

let pause = 500;
async function selectProduct( products, params ) {
	if( !products ) return;
	const { store, ProductType, Platform } = CdvPurchase;
	await initialize();
	if( !win ) {
		win = makeBigWindow( {
			id: 'purchase'
		} );
		win.onclick = buyClick;
	}
	let str = '';
	if( params.title )
		str += `<span class='title'>${params.title}</span>`;

	let pc = 0;
	for( let p of products ) {
		// Если покупаем фанты, и этой покупки недостаточно, не показываем её
		if( params.needfants ) {
			let mine = elephCore?.myfants?.value || 0,
				sum = p.split( '_' )[1].replace( 'k', 1000 );
			if( sum+mine < params.needfants ) {
				log( `${p} is not sufficient for ${params.needfants}` );
				continue;
			}
		}
		let prod = store.get( p, Platform.TEST );
		if( !prod ) continue;
		log( `Checking prod ${prod.id}. canPurchase=${prod.canPurchase}` );
		if( !prod || !prod.canPurchase ) continue;			// Не найден
		pc++;
		let img = 'premium',
			title = prod.title,
			size = 64;
		if( p.includes( 'fants_' ) ) {
			img = 'chest';
			title = p.split( '_' )[1];
			let val = +(title.replace( ',', '' ).replace( 'k', '000' ));
			size = 48;
			if( val<100000 ) size = 32;
			if( val>=1000000 ) size = 64;
		}
		str += `
			<div class='sheet control column' style='align-items: start' data-product='${p}' data-price='${prod.price}'>
			  <div class='imagehead' style='margin-top: 0.5em'>
			     ${fillMagicIMG( img, size )}
			     <div> 
			        <span class='title'>${title}</span>
			        <span class='sheet'>${prod.price}</span>
			     </div>
			  </div> 
			</div>`;
	}
	if( !pc ) {
		// Не готовы предметы. Ждем три раза
		if( pause>4000 ) return;
		setTimeout( () => selectProduct( products, params ), pause );
		pause *= 2;
		return;
	}

	win.innerHTML = localize( `<div class='column'>${str}</div>` );
	win.show();
}

function buyClick( e ) {
	let t = e.target;
	while( t && !t.dataset['product'] ) t = t.parentElement;
	if( !t ) return;
	const { store, ProductType, Platform } = CdvPurchase;
	win.hide();
	let prod = t.dataset['product'],
		price = t.dataset['price'],
		method = prod.includes( 'premium' ) ? 'subscribe' : 'buy';
	log( 'Purhase order: ' + method + ' ' + prod );
	// Call purchase
	let res = store.order( store.get( prod ) )
		.error( err => {
			toast( 'Purchase error ' + JSON.stringify( err ) );
			log( 'Purchase error ' + JSON.stringify( err ) );
		});
	// log( 'Purchase order res: ' + JSON.stringify( res ) );
}

async function refresh() {
	// if( !registered ) register( true );
	refreshed = false;
	await store.refresh();
	refreshed = 1;
}

let inistate;
async function initialize() {
	if( inistate==='ready' ) return;
	if( !window.UIN ) {
		await checkAuth();
	}

	const { store, ProductType, Platform } = CdvPurchase;
	store.log.error = window.log;
	store.log.debug = window.log;
	store.verbosity = 4; // DEBUG
	for( let o of prods ) {
		if( o==='fants_10' && !window.TESTER ) continue;
		let res = store.register( {
			type: o.includes( 'fants' ) ? CdvPurchase.CONSUMABLE : CdvPurchase.PAID_SUBSCRIPTION,
			id: o,
			platform: Platform.TEST
		} );
		log( 'Purchase reg: ' + JSON.stringify( res ) );
	}

	store.when( 'consumable' )
		.approved( finishPurchaseConsumable );

	await store.initialize( [Platform.TEST] );
	inistate = 'ready';
}
