"use strict";

import {selectUser} from "./usersview.js";

export {selectUser as selectUser};

function gosheet( value ) {
	let tab;
	if( this.tabParams?.tabs ) {
		// Проверим, есть ли такая вкладка
		tab = this.tabParams.tabs[+value];
		if( !tab ) for( let k in this.tabParams.tabs ) {
			if( this.tabParams.tabs[k].id===value ) {
				value = k;
				tab = this.tabParams.tabs[k];
			}
		}
		if( !tab ) return false;
	}
	let sheets = this.$( '.slidesheets' ),
		back = this.$( '.back' );
	if( sheets ) {
		sheets.dataset.activesheet = value;
		// sheets.style.transform = +value ? `translate3D( -${+value * 100}%, 0, 0 )` : '';
		for( let el of sheets.children )
			el.makeVisible( el.dataset.sheet===value );
	}
	back?.makeVisible( value!=='0' );
	this.$( '.title' ).setContent( tab?.title || this.tabParams?.title || '' );
	return true;
}

function tabClick( e ) {
/*
	if( e.target?.dataset.gosheet ) {
		// Переключаемся на нужную вкладку
		e.currentTarget?.gosheet( e.target?.dataset.gosheet );
	}
*/
}

export async function askMenu( def, params ) {
	// Попробуем вызвать более стандартный edit
	let win;
	if( def.id ) win = $( '#' + def.id );
	if( !win ) {
		let str =
			`<div class='column'>
			<span style='font-size: 1.5rem; margin: 0.5em;' class='title'>${def.title || ''}</span>
			`;
		for( let o of def.items ) {
			let [a,b] = typeof o==='object'? o : [ o, '{' + o.capitalize() + '}' ];
			str += `<button
				class='display_none visible' 
				style='${a.slice( -1 )==='!' && 'color: red; margin-top: 0.5em;' || ''} text-align: left' data-closeselect='${a}'>${b}</button>`;
		}
		win = makeBigWindow( {
			html: str,
			...def
		} );
		win.id = def.id;
	}
	if( params?.visible ) {
		let a = params.visible.split( ' ' );
		for( let o of win.$$( '[data-closeselect]' ) )
			o.makeVisible( a.includes( o.dataset.closeselect ) );
	}
	return win.promiseShow();
}

function makeTabsWindow( params ) {
	cssInject( 'edit' );
	let back = params?.back ? '<span class="fade back" data-gosheet="0">⬅️</span>' : '',
		str =
			`<div class='tabwindow ${params?.mainclassname || ''}'>
			${back}
			<div class='title'>${params?.title || ''}</div>
			<div class='slidescreen'>
			<div class='slidesheets'>`;

	let tabs = params?.tabs;
	if( tabs ) {
		for( let k = 0; k<tabs.length; k++ ) {
			let tab = tabs[k],
				tstr = typeof tab==='object' ? tab.html : tab;
			str += `<div class='column centered fade' data-sheet='${tab.id || k}' data-title='${tab.title || params.title || ''}'><div class='column'>${tstr}</div></div>`;
		}
	}
	str += `</div>
			</div>
			<div class='tabicons flexline nowrap'></div>
			</div>`;
	let win = makeBigWindow( str );
	if( params?.id ) win.id = params.id;
	// win.gosheet = gosheet.bind( win );
	win.tabParams = params;
	// win.addEventListener( 'click', tabClick );
	return win;
}

let gameSelect, lastParams, selgameResolve;
export function getGamePhrase( id ) {
	return `{${(gameGroups[id]?.phrase || id).capitalize()}}`;
}

const gameGroups = {
		bridge: {
			emo: '⚓',
		},
		pref: {
			emo: '🐎',
			phrase: 'prefgame'
		},
		bg: {
			emo: '🎲',
			phrase: 'gammongame'
		},
		poker: {
			emo: '🤠'
		},
		chipoker: {
			emo: '🐉',
			phrase: 'chipoker'
		},
		chess: {
			emo: '♟️'
		},
		draughts: {
			emo: '⚪'
		},
		belot: {
			emo: '🌗'
		},
		deberc: {
			emo: '🌗'
		},
		king: {
			emo: '👑',
			phrase: 'king_game'
		},
		durak: {
			emo: '🃏'
		},
		g1000: {
			emo: '🛢️',
			phrase: 'game1000'
		},
		domino: {
			emo: '🀄'
		},
		updown: {
			emo: '↕️'
		}
	},
	tabs = [
		`
		 <button style='text-align: left' data-gosheet='chess'>♟️ {Chess}</button>
		 <button style='text-align: left' data-gosheet='draughts'>⚪ {Draughts}</button>
		 <button style='text-align: left' data-gosheet='pref'>🐎 {Prefgame}</button>
		 <button style='text-align: left' data-gosheet='bg'>🎲 {Gammongame}</button>
		 <button style='text-align: left' data-closeselect='bridge'>⚓ {Bridge}</button>
		 <button style='text-align: left' data-gosheet='sandbox'>👑 {King_game}/{Game1000}/{Updown}</button>
		 <button style='text-align: left' data-gosheet='durak'>🃏 {Durakgame}</button>
		 <button style='text-align: left' data-gosheet='belot'>🃏 {Belot}</button>
		 <button style='text-align: left' data-gosheet='domino'>🀄 {Domino}</button>
		 <button style='text-align: left' data-closeselect='poker'> {Poker}</button>`,
		{
			title: '🐎 {Prefgame}',
			id: 'pref',
			html:
				`<button data-closeselect='pref'>{Prefgame}</button>
				 <button data-closeselect='skak'>🏇 {Skak}</button>`
		},

		{
			title: '🎲 {Gammongame}',
			id: 'bg',
			html: `<button data-closeselect='bg-short'>{_Sbg}</button>
				 <button data-closeselect='bg-long'>{_Lbg}</button>
				 <button data-closeselect='bg-long_nard'>{_Nlbg}</button>
				 <button data-closeselect='bg-short_nack'>{_Ng_}</button>`
		},

		{
			title: '♟️ {Chess}',
			id: 'chess',
			html:
				`<button data-closeselect='chess-classic'>{Standard} (10{min})</button>
				 <button data-closeselect='chess-blitz'>{Blitz} ({upto} 5{min})</button>
				 <button data-closeselect='chess-bullet'>{Superblitz} ({upto} 2{min})</button>
				 <button data-closeselect='chess-giveaway'>{Giveaway}</button>`
		},
		{
			title: '⚪ {Draughts}',
			id: 'draughts',
			html:
				`<button data-closeselect='draughts-russian'>{Draughts}</button>
				 <button data-closeselect='draughts-100'>💯 {Ch100}</button>
				 <button data-closeselect='draughts-giveaway'>{Giveaway}</button>
				 <button data-closeselect='draughts-brasil'>🇧🇷 {Brasil}</button>
				 <button data-closeselect='draughts-checkers'>{Checkersgame}</button>
				 <button data-closeselect='draughts-pool'>{Poolcheckers}</button>
				 `
		},
		{
			title: '👑️ {Game1000}/{King_game}/{Updown}',
			id: 'sandbox',
			html:
				`<button data-closeselect='g1000'>🛢 {Game1000}</button>
				 <button data-closeselect='king'>👑 {King_game}</button>
				 <button data-closeselect='king-zakaz'>👑 {_Kingz}</button>
				 <button data-closeselect='updown'>↕️ {Updown}</button>`
		},
		{
			title: '🃏 {Durakgame}',
			id: 'durak',
			html:
				`<button data-closeselect='durak'>{Podkid}</button>
				 <button data-closeselect='durak-perevod'>{Perevodnoy}</button>
				 <button data-closeselect='durak-pairs'>{Podkid} 👥</button>
 				 <button data-closeselect='durak-perevod_pairs'>{Perevodnoy} 👥</button>`
		},
		{
			title: '🃏 {Belot}',
			id: 'belot',
			html:
				`<button data-closeselect='belot'>{Belot}</button>
				 <button data-closeselect='belot4'>{Belot} 2x2</button>`
		},
		{
			title: '🀄 {Domino}',
			id: 'domino',
			html:
				`<button data-closeselect='domino-classic'>{Domino}</button>
				 <button data-closeselect='domino-classic_pair'>{Domino} 👥</button>
				 <button data-closeselect='domino-5'>5️⃣ {Muggins}</button>
 				 <button data-closeselect='domino-5_pair'>5️⃣ {Muggins} 👥</button>`
		}
	];

export async function selectgame( params ) {
	log( 'Selecting game' );
	await cssInject( 'tools' );
	// Select exact game from the selected list
	let games = typeof params==='string' && params || params?.games || params?.hall;
	if( games==='checkers' ) games = 'draughts';
	if( games?.includes( '-' ) ) return games;
	if( !gameSelect ) {
		gameSelect = makeTabsWindow( {
			title: '{Selectgame}',
			id: 'selectgame',
			mainclassname: 'winselectgame',
			back: true,
			tabs: tabs
		} );
		gameSelect.addEventListener( 'click', e => {
			let gm = e.target?.dataset.closeselect;
			if( !gm ) return;
			log( 'Selgame click ' + e.target?.dataset.closeselect );
			// gameSelect.hide();
			if( lastParams ) {
				// Что делать с выбранной игрой
			}
			let res = selgameResolve;
			selgameResolve = null;
			res?.( gm );
		} );
	}
	gameSelect.$( '.title' ).setContent( params?.title || '{Selectgame}' );
	lastParams = params;
	let argames = games && (typeof games==='string' && games.split( /\s|,/ ) || games),
		cv = 0, last;
	for( let h of gameSelect.querySelectorAll( '[data-sheet="0"] button' ) ) {
		let k = h.dataset.gosheet || h.dataset.closeselect,
			v = !argames || argames.includes( k );
		// if( !h ) continue;
		if( v ) {
			last = k;
			cv++;
		}
		h.style.display = v ? 'initial' : 'none';
	}
	// Есть ли выбор
	if( !cv ) {
		// Ни одной вкладки нет
		return;
	}
	let noback = false;
	if( cv===1 ) {
		// Единственный выбор, сразу переходим в нужный раздел
		noback = true;
		if( !gameSelect.goSheet( last ) ) {
			if( gameSelect.$( `[data-closeselect='${games}']` ) ) {
				// Эта игра может быть выбрана
				return games;
			}
			return;
		}
	}
	gameSelect.goSheet( '0' );
	gameSelect.classList.toggle( 'noback', noback );
	gameSelect.show();
	selgameResolve = null;

	return new Promise( async resolve => {
		// await cssInject( 'edit' );
		selgameResolve = resolve;
	} );
}

// GameSet
function datatypeSetContent( type, value ) {
	if( !value ) return '';
	switch( type ) {
		case 'gameset':
			return value.toLowerCase().split( ' ' ).map( x => gameGroups[x]?.emo || '' ).join( '' );
	}
	return value;
}

let winSelectGames, gameSetParams;

export async function selectgameset( params ) {
	// Select exact game from the selected list
	if( !winSelectGames ) {
		let str = `
			<div class='column' style='gap: 0.5em'>
			<span class='title importantsize'>{Games}</span>
			<div class='grid' style='gap: 0.2em; overflow-y: scroll; justify-items: start'>`;

		for( let id in gameGroups ) {
			let g = gameGroups[id];

			if( typeof g==='string' ) g = gameGroups[id] = { emo: g };
			if( window.legalGames && !legalGames.includes( id ) ) continue;
			str += `
					<label data-game='${id}' class='flexline display_none'><input type='checkbox' data-emo='${g.emo}' data-value='${id}' />
					<span class='grayunchecked'>${g.emo} {${capitalize( g.phrase || id )}}</span></label>
				`;
		}

		str += `</div><button class='default importantsize' data-closeselect='ok' style='grid-area: auto; justify-self: center'>Ok</button></div>`;
		winSelectGames = makeBigWindow( str );

		winSelectGames.onclick = e => {
			if( e.target.dataset.closeselect==='ok' ) {
				// Copy selected values to object
				let check = [...winSelectGames.querySelectorAll( '*:checked' )],
					value = check.map( x => x.dataset.value ).join( ' ' ),
					emos = check.map( x => x.dataset.emo || '' ).join( '' );

				gameSetParams.target.dataset.value = gameSetParams.target.value = value;

				let t = gameSetParams?.target;
				if( t ) {
					t.dataset.value = value;
					if( t.tagName==='INPUT' )
						t.value = value;
					else
						t.setContent( emos || '{All}' );
					// Search for onchange function
					for( let up=t; up; up = up.parentElement )
						if( up.onchange ) {
							up.onchange( t );
							break;
						}
				}
			}
		};
	}
	params ||= {};
	gameSetParams = params;
	let val = params.value || params.target.dataset.value,
		disabled = params.diabled || params.target?.dataset.disabled?.split( ' ' ),
		allgames = params.target.dataset.allgames?.split( ' ' ),
		arval = val?.split( /\s|,/ ),
		allchecked = !arval || arval.length===0;
	for( let k in gameGroups ) {
		let f = gameGroups[k];
		let el = winSelectGames.$( `[data-value='${k}']` );
		if( !el ) continue;
		el.checked = allchecked || arval.includes( k ) || val?.includes( f.emo ) || false;
		if( disabled?.includes( k ) ) el.disabled = true;
		winSelectGames.$( `label[data-game='${k}']` ).makeVisible( !allgames || allgames.includes( k ) );
	}
	winSelectGames.show();
}

export async function selectMultiple( element ) {
	// Select exact game from the selected list
	let values = element.dataset.values.split( ' ' ),
		nowvalue = element.dataset.value?.split( ' ' ) || [],
		all = !element.dataset.value && element.dataset.emptyall;
	let str = `
			<div class='vertical_form leftlabels'>
			<span class='title'></span>`;

	for( let v of values )
		str += `<label><input type='checkbox' data-value='${v}' ${(all || nowvalue.includes( v )) && 'checked' || ''}/>${v}</label>`;

	str += `<button class='default importantsize' data-closeselect='ok'>Ok</button></div>`;

	let win = makeBigWindow( str );

	let res = await win.promiseShow();
	if( res!=='ok' ) return;
	let checkelements = [...win.querySelectorAll( '*:checked' )],
		check = checkelements.map( x => x.dataset.value ),
		value = check.join( ' ' ),
		cap = check.length===values.length && '{All}' || !check.length && '{No}' || `${check.length}/${values.length}`;
	if( check.length && check.length <= 3 ) cap = check.join( '; ' );
	element.dataset.value = value;
	element.setContent( cap );
	return value;
}

export async function selectRange( element ) {
	// Select exact game from the selected list
	let min = element.dataset.min,
		max = element.dataset.max,
		postfix = element.dataset.postfix,
		nowvalue = element.dataset.value?.replaceAll( /\s/g, '' ).split( '..' ) || [];
	if( nowvalue.length<2 ) nowvalue = [ min, max ];
	// postfix = postfix? ` data-after='${postfix}'` : '';

	let win = makeBigWindow( {
		repeatid: 'win_editrange',
		title: element.dataset.title,
		html: `
			<div class='vertical_form leftedit' style='min-width: 12em; padding: 10px 20px 0 20px'>
			<label>{Min}<span><input size=5 style='max-width: 8em; text-align: right;' type="number" type='number' inputmode='numeric' step="0.01" name='min' min='0' max='100000' value='${nowvalue[0] ?? min}'>${postfix}</span></label>
			<label>{Max}<span><input size=5 style='max-width: 8em; text-align: right;' type="number" type='number' inputmode='numeric' step="0.01" name='max' min='0' max='100000' value='${nowvalue[1] ?? max}'>${postfix}</span></label>
			<button class='default importantsize' style='margin-top: 1em' data-closeselect='ok'>Ok</button></div>`
	} );

	let res = await win.promiseShow();
	if( res!=='ok' ) return;
	let newmin = win.$( 'input[name="min"]' ).value,
		newmax = win.$( 'input[name="max"]' ).value,
		value = `${newmin} .. ${newmax}`;
	element.dataset.value = value;
	element.setContent( `${newmin} .. ${newmax}` );
	return value;
}

export function editElement( element ) {
	if( element.dataset.datatype==='gameset' ) {
		selectgameset( {
			target: element,
		} )
	} else if( element.dataset.datatype==='selectmultiple' ) {
		selectMultiple( element );
	} else if( element.dataset.datatype==='selectrange' ) {
		selectRange( element );
	} else if( element.dataset.datatype==='datetime-local' ) {
		selectDatetimelocal( element );
	}
}

/*
let shareWin;
export async function share( el ) {
	let url = el.dataset.shareurl || location.href;

	if( navigator.share ) {
		try {
			let res = navigator.share( {
				title: el.dataset.sharetitle,
				text: el.dataset.share,
				url: url
			} );
			if( res ) return res;
		} catch( e ) {
			log( 'Share failed ' + JSON.stringify( e ) );
		}
	}

	function copied( success ) {
		shareWin.querySelector( '.comment' ).classList.toggle( 'visible', success );
		copyButton.classList.toggle( 'visible', !success );
	}

	let inviteInput, copyButton;

	function docopy() {
		if( navigator.clipboard ) {
			navigator.clipboard.writeText( url ).then( function() {
					copied( true );
				}, function() {
					copied( false )
				}
			);
		} else {
			inviteInput.select();
			let success = document.execCommand( 'copy' )!==false;
			if( success ) copied();
		}

		// Firefox COPY CLIPBOARD troubles
		inviteInput.select();
		copied( document.execCommand( 'copy' )!==false );
	}


	// В браузере компьютера открываем окно, где можно поделиться
	if( !shareWin ) {
		shareWin = makeBigWindow( `
					<div class="vertical_form">
					<span>{Givelinktoinvite}</span>
					<input size='30' readonly/>
					<button class="control display_none" data-closeselect="copy">{Copytoclipboard}</button>
					<span class="comment display_none">{Wealreadycopiedittoclipboard}</span>
					</div>` );

		shareWin.$( 'button' ).onclick = docopy;
	}
	shareWin.$( 'span' ).setContent( el.dataset.sharetitle || '{Sharelinkwithfriends}');
	inviteInput = shareWin.$( 'input' );
	copyButton = shareWin.$( 'button' );
	inviteInput.value = url;
	shareWin.show();
	docopy();
}

*/
/*
Select from list of items
 */
let selectWin;

export function selectFrom( params ) {
	if( !selectWin ) {
		selectWin = makeBigWindow();
		selectWin.onclick
	}
	let str = "<div style='padding: 1em' class='column'>";
	for( let item of params.items ) {
		let options = params.doselect ? {
			extra: `data-closeselect='${item.id}'`
		} : null;
		str += fillPlayerHTML( item, options );
	}
	str += '</div>';
	if( params.url )
		str += `<button style='margin-top: 0.5em' data-openlocalbrowser='${params.url}'>{Browser}</button>`;
	selectWin.html( `<div style='padding: 0.1em' class='column'>${str}</div>` );

	if( params.doselect )
		return selectWin.promiseShow();
	selectWin.show();
}

let forms = new Set;

const valueNames = {
		phrases: `yes no default standard comfort teaching auto pairs individual swiss roundrobin16`.split( /\s/ ),
		tgames: { M2: '{Match}/2', M4: '{Match}/4' }
	},
	fieldTypes = {
		number: ['boards', 'boardtime', 'nplayers']
	},
	fieldNames = {
		stake: '{Bet}',
		mtype: '{Match}',
		glen: '{Gamelength}',
		tgames: '{Games} {inround}',
		boardcount: '{Totalboards}',
		boards: '{Totalboards}', boardtime: '{Minutesperboard}',
		fsbrmb: 'MasterPoints RU',
		prefplayers: '{Preferably}',
		paymodel: '{Entryfee}',
		preflength: '{Pool}',
		bandittype: '{Type}',
		bpgames: '{Misere}/9 {NW}',
		startpos: '{Arrangement}',
		goal: '{Goal}',
		mp: '{Players}',
		open: '{Opened} {table}',
		BB: 'BB',
		currsymbol: '{Currency}'
	};

class Editform {
	#orderList;
	#onchangemap;

	constructor() {
		this.big = makeBigWindow( {
			id: 'editform',
			closeable: true
		} );
		this.big.onchange = this.onchange.bind( this );
		this.big.onclick = this.click.bind( this );
		forms.add( this );
	}

	onchange( e ) {
		let p,
			element = e.target || e;
		if( this.createparams.__onchange ) {
			p ||= collectParams( element );
			this.createparams.__onchange( p, element, this );
		}
		this.options.onchange?.( element );
		// Условные поля (меняют значение и видимость при изменениях)
		for( let [ el, obj ] of this.visibleif ) {
			p ||= collectParams( element );
			el.makeVisible( obj.__visibleif( p, obj, el ) );
		}
	}

	goSheet( sheet ) {
		this.options.onvalidate?.( this.big );
		if( !sheet ) sheet = '0';
		if( !(+sheet>=0) ) sheet = this.allSheets.indexOf( sheet );
		if( sheet<0 ) sheet = 0;
		// this.big.$( '.slidesheets' ).dataset['activesheet'] = sheet;
		// this.big.$( '.slidesheets' ).style.transform = `translate3D( -${100 * (+sheet)}%, 0, 0 )`;
		for( let el of this.big.querySelectorAll( 'button[data-gosheet]' ) ) {
			let sel = /*el.disabled = */el.dataset['gosheet']===sheet;
			el.classList.toggle( 'selected', sel );
		}
		for( let el of this.big.$$( '.slidesheet' ) )
			el.makeVisible( el.dataset.sheet===sheet );
	}

	click( e ) {
/*
		let gosheet = e.target.dataset.gosheet || (e.target.tagName==='LABEL' && e.target.firstElementChild.dataset.gosheet );
		if( gosheet ) {
			e.stopPropagation();
			this.goSheet( gosheet );
		}
*/
	}

	async edit( params, options ) {
		this.createparams = params;
		options = this.options = options || {};
		this.stored = options.values;
		this.localid = this.options.id || this.createparams.localid;
		if( !this.stored && this.localid ) {
			this.stored = JSON.parse( localStorage['editform_' + this.localid] || null );
		}

		this.sheetStr = '';
		this.sheets = 0;
		this.sheetStrings = [];
		this.allSheets = [ 'main' ];
		this.#orderList = null;
		if( this.options.storeid ) {
			let storedorder = localStorage['bigselectsort_' + this.options.storeid];
			// if( storedorder ) this.#orderList = JSON.parse( storedorder );
		}

		this.visibleif = new Map;
		this.#onchangemap = new Map;
		this.fillTab( params, options, 0 );

		let ok = this.options.okbutton && `<button class='default importantsize' data-closeselect='ok' 
			style='grid-area: auto; justify-self: center; align-self: center; margin-top: 0.5em; order: 1000'>${this.options?.okbutton || 'OK'}</button>` || '',
			str = `<div class='_solidgrid _gridone column' style='max-width: 25em; min-width: ${options.minWidth || '10em'};'>
					<div class='slidescreen'>
					 <div class='slidesheets flexline nowrap' >
					  ${this.sheetStrings.join( '' )}
					 </div>
					</div>
					${ok}					
					</div>`;

		this.big.html( str );

		// Для исправления ошибки стартового отображения нескольких tabs, только сейчас проставляем им translate3d
/*
		requestAnimationFrame( () => {
			for( let el of this.big.$$( '[data-sheet]' ) ) {
				el.style.transform = `translate3D( ${100 * (+el.dataset.sheet)}%, 0, 0 )`;
			}
		} );
*/

		let map = new Map;
		for( let [ name, object ] of this.visibleif ) {
			map.set( this.big.$( `[data-labelfor="${name}"]` ), object );
		}
		this.visibleif = map;

		this.onchange( this.big );
		await cssInject( 'edit' );

		// if( options.startsheet )
		// this.goSheet( options.startsheet || '0' );
		this.big.goSheet( options.startsheet || '0' );

		options.onvalidate?.( this.big );
		setTimeout( () => this.big.$( '.slidesheets' ).style.justifyContent = 'flex-start', 300 );
		let res = await this.big.promiseShow();
		if( res==='cancel' || res==='close' ) return;
		let hidden = {}, visible = {};
		for( let el of this.big.$$( '[name], [data-name]' ) ) {
			let value = el.dataset.value ?? el.value;
			if( el.type==='checkbox' ) value = +el.checked;
			if( value===undefined ) continue;
			let name = el.name || el.dataset.name;
			if( name.startsWith( '__' ) || name.includes( '.__' ) ) continue;
			(el.type==='hidden'? hidden : visible)[name] = value;
			if( this.options.resultNames && this.optionsNames )
				this.options.resultNames[name] = this.optionsNames[name]?.[value];
		}
		let json = { ...hidden , ...visible };
		// Сохраним последние значения для этого ID
		log( 'Completed editform ' + JSON.stringify( json ) );
		if( this.localid )
			localStorage['editform_' + this.localid] = JSON.stringify( json );
		json.submit = res;
		if( this.options.storeid ) {
			this.#orderList ||= {};
			this.#orderList[res] = ( this.#orderList[res] || 0) + 1;
			// localStorage['bigselectsort_' + this.options.storeid] = JSON.stringify( this.#orderList );
		}
		return json;
	}

	fillOptions( param, name, defval ) {
		if( !param ) return '';
		let p = param.options || (!param.type && param),
			str = '',
			alldisabled = '';
		for( let k in p ) {
			let v = p[k];
			if( Array.isArray( v ) ) [k, v] = v;
			else k = v;
			if( k==='demo' && this.options.nodemo ) continue;
			let disabled = alldisabled;
			if( typeof k==='string' && k[0]==='?' ) {
				k = k.slice( 1 );
				// if( this.isDemo ) disabled = 'disabled'; // 'data-demodisable="1"';
				if( v[0]==='?' ) v = v.slice( 1 );
			}
			if( typeof k==='string' && k[0]==='!' ) {
				k = k.slice( 1 );
				if( v[0]==='!' ) v = v.slice( 1 );
				// if( this.isDemo ) alldisabled = 'disabled';
			}
			if( v && valueNames[name]?.[v] )
				v = valueNames[name][v];
			else if( v && valueNames.phrases.includes( v ) )
				v = '{' + v + '}';
			let def = (defval!==undefined ? defval?.toString()===k.toString()
				: param.default===k) && 'selected' || '';
			if( param.type==='input' )
				str += `<option ${def} ${disabled} value='${v}' />`;
			else
				str += `<option ${def} ${disabled} value='${k}'>${v}</option>`;
			if( this.options.resultNames )
				(( this.optionsNames ||= {} )[name] ||= {})[k] = v;
		}
		return str;
	}

	fillTab( params, options, deep, prevsheet ) {
		if( !params ) return this.sheets;
		let fieldno = 1, sheetno = this.sheets,
			back = deep ? `<img class='control w32 grayhover' data-gosheet='${prevsheet}' src='${IMGPATH}/svg/icons/ic_arrow_back_black_24px.svg' >` : '',
			info = params.__info? `<span style='order:9;flex-grow:2'>&nbsp;</span>
				<span class='emoji pointer icon w32 grayhover' title='${options?.title||''}' data-popupinfo='${encodeURIComponent(params.__info)}' 
				style='order:10; background-image: url(${IMGEMBEDPATH}/svg/icons/question_circle.svg)'>️</span>` : '',
			// info = params.__info? `<img class='control w32 grayhover righttop' title='${options?.title||''}' data-popupinfo='${encodeURIComponent(params.__info)}' src='${IMGPATH}/svg/icons/ic_info_outline_black_24px.svg'>` : '',
			pic = options.picture && fillMagicIMG( options.picture, 24 ) || '',
			title = `
				<div class='column' style='grid-area: title'>
				<span style='padding: 5px 0 0.5em 0; grid-area: title; border-bottom: solid gray 1px; border-radius: 0' class='nowrap title flexline'>${back}${info}${pic}<span style='overflow: hidden; text-overflow: ellipsis; ${options.picture && 'padding-left: 0.3em;'||''}'>${options?.title || '&nbsp;'}</span></span>
				</div>`,
			str = `<div class="fade slidesheet leftedit" data-sheet='${this.sheets}'
			data-sheetprefix='${options.prefix||''}'
			style='display: grid; grid-template: "title" 2.5em "body" calc( 100% - 2.5em ) / 100%;
				gap: 0.5em; padding: 0; overflow: none; order: ${this.sheets}; 
				_position: relative; max-height: 100%; position: relative'>
			${title}`;
			// <div style='border-bottom: 1px solid gray; margin: 0;'></div>`;

		if( !deep )
			str += `<span class='control grayhover icon righttop invertdark	' 
			style='right: 0; top: 0; width: 2rem; height: 2rem;
			background-image: url(${IMGEMBEDPATH}/svg/icons/highlight_off_black_24dp.svg)' data-closeselect='close'></span>`;

		str += `
			<div class='scrollcontainer' style='grid-area: body'>
			${options.subtitle_html||''}
			<div class='vertical_form leftedit'>`;
		this.sheets++;
		// Составим сначала новый массив, содержащий только актуальные для заполнения элементы
		let onlyparams = [];
		for( let k in params ) {
			if( k==='localid' || k[0]==='_title' || k[0].startsWith( '__' ) ) continue;
			if( this.options.disable?.includes( k ) ) continue;
			if( params[k].__hidden ) continue;
			onlyparams.push( k );
		}
		// Из-за ошибки браузера, необходимо правильно отсортировать вкладки
		onlyparams.sort( ( a, b ) => {
			if( !isNaN( +params[a].order ) && !isNaN( +params[b].order ) ) return +params[a].order - (+params[b].order);
			return 0;
		});
		for( let k of onlyparams ) {
			str += this.fillNew( k, params[k], {
				deep: deep,
				fieldno: fieldno,
				sheetno: sheetno,
				prefix: options.prefix || '',
				onlyone: onlyparams.length===1
			} );
			fieldno++;
		}
/*
		if( !deep ) {
			if( this.options.okbutton ) {
				str += `<button class='default' data-closeselect='ok' style='align-self: center; margin-top: 0.5em; order: 1000'>${this.options?.okbutton || 'OK'}</button>`;
			}
		}
*/
		str += '</div></div></div>';
		this.sheetStrings[sheetno] = str;
		this.sheetStr += str;
		return sheetno;
	}

	getLabel( name ) {
		return this.big.$( `label[data-labelfor='${name}']` );
	}

	isEditable( name ) {
		if( !this.options.noteditable ) return true;
		let block = this.options.noteditable.includes( name ) ||
			this.options.noteditable.includes( name.split('.')[0] );
		return !block;
	}

	fillNew( key, o, options ) {
		let str = '';
		if( key.startsWith( '__' ) ) return str;
		let hidden = key[0]==='_', comment = !key || key[0]==='*';
		if( hidden ) key = key.slice( 1 );
		let spl = key.split( '#' ),
			ttl = spl[1], placeholder = spl[2];
		key = comment ? 'comment' : spl[0];
		let name = key;
		if( options.prefix ) name = options.prefix + '.' + key;
		if( o.__name ) name = o.__name;
		if( hidden ) o = { type: 'hidden', default: o };
		else if( typeof o==='boolean' ) o = { type: 'checkbox', default: o };
		else if( typeof o==='number' ) o = { type: 'input', isnumber: true, default: o };
		else if( typeof o==='string' ) {
			if( comment ) o = { type: 'comment', default: o };
			else o = { type: 'input', default: o };
		}
		if( !o ) return str;
		if( ttl ) o.title = ttl;
		if( placeholder ) o.placeholder = placeholder;
		if( o.ifonlyset && !this.createparams[o.ifonlyset] ) return str;
		let def = o.default,
			// deftype = typeof def==='boolean'? 'checkbox' : 'select',
			otype = o.type || o._type || o.__type || ( typeof def==='boolean' && 'checkbox' ),
			type = otype || 'select',
			isnumber = o.isnumber || type==='int' || fieldTypes.number.includes( key ) || ( o.type!=='numeric' && (o.min || o.max) && true) || false;
		// Возьмем последние значения
		if( this.stored && typeof this.stored==='object' ) {
			if( name in this.stored ) def = this.stored[name];
			else if( o.__alias && this.stored[o.__alias] )
				def = this.stored[o.__alias];
		}

		let optionsstr = !isnumber && this.fillOptions( o, key, def ),
			order = `style='order: ${o.order || options.fieldno * 10}'`,
			tab = o.type==='tab' || ( ( o.__caption || o.__title ) && !otype && !o.options );
		// log( `FNEW: ${name} = ${def}` );
		let title = o.__caption || o.__title || o._title || o.title || fieldNames[key] || '{' + capitalize( key ) + '}',
			title_text = title;
		if( isnumber ) type = 'input';
		if( o.__visibleif ) this.visibleif.set( name, o );

		if( options.onlyone && type==='input' && !isnumber ) {
			o.placeholder ||= title;
			title = o.title = '';
		}
		if( o.__info && !tab ) {
			title = `<span class='flexline'><span>${title}</span>
				<span class='emoji icon w24 grayhover pointer' data-popupinfo='${o.__info}' 
				style='padding-left: 0.5em; background-image: url(${IMGEMBEDPATH}/svg/icons/question_circle.svg)'></span>
				</span>`;
		}
		// Special self-editable objects
		order += ` data-labelfor='${name}' class='${o.__fade?'fade':'display_none'} ${o.__hide?'':'visible'}' `;
		if( o.type==='info' ) {
			str += `<label ${order}><pre style='color: var(--light_gray)'>${o.text}</pre></label>`;
		} else if( o.type==='multiple' ) {
			// Преобразуем в подвкладку
			let cap = def;
			if( o.emptyall && !def ) {
				cap = "{All}";
				def = '';
			}
			str += `<label ${order}><span>${title}</span><span ${o.emptyall ? 'data-emptyall=1' : ''} data-editable='1' data-value='${def || ''}' data-datatype='selectmultiple' data-values='${o.values.join( ' ' )}' data-name='${name}'>${cap}</span></label>`;
		} else if( o.type==='text' ) {
			str += `<label class='flexline' ${order}><span>${title}</span><textarea name='${name}' rows='${o.rows || 3}' data-editable='1' data-title='${title_text}' 
			 	data-name='${name}' placeholder='${o.__placeholder||""}'
			 	style='margin-left: 2em; flex-grow: 1'>${def || ''}</textarea></label>`;
		} else if( o.type==='range' ) {
			if( !def ) def = `${o.min} .. ${o.max}`;
			str += `<label ${order}><span>${title}</span><span data-editable='1' data-postfix='${o.__postfix||''}' data-title='${title_text}' data-value='${def || ''}' data-datatype='selectrange'
			 	data-min='${o.min}' data-max='${o.max}' data-name='${name}'>${def || ''}</span></label>`;
		} else if( tab ) {
			// Подвкладка
			this.allSheets.push( name );
			let tabno = this.fillTab( o.fields || o, {
				prefix: name,
				title: o.__title || title
			}, options.deep + 1, options.sheetno );
			str += `<label class='clickable display_none visible' data-gosheet='${tabno}' ${order}><span>${title} ▶</span><span data-gosheet='${tabno}' data-sheetdata='${key}'></span></label>`;
		} else if( o.datatype ) {
			// В зависимости от datatype показываемое значение может быть разным
			str += `<label ${order}>${title}<span data-editable='1' data-value='${def || ''}' data-disabled='${o.disabled||''}' data-datatype='${o.datatype}' data-name='${name}'>${datatypeSetContent( o.datatype, def ) || '{Select}'}</span></label>`;
		} else if( o.type==='hidden' ) {
			str += `<input type='hidden' name='${name}' value='${def}' />`;
		} else if( o.type==='comment' ) {
			str += `<label ${order}><pre data-name='${name}'>${o.default || ''}</pre></label>`;
		} else if( !this.isEditable( name ) ) {
			str += `<label ${order}><span data-name='${name}'>${def}</span></label>`;
		} else if( type==='bigselect' ) {
			// Кнопки выбора действий. По нажатию на кнопку форма закрывается
			str += `<div class='column vscroll' style='gap: .5em; margin: 0 1em'>`;
			// Detect all populars (should be in start of list)
			// also TODO possible use order value
			let lastpopular;
			for( let b of o.values ) if( b.popular ) lastpopular = b;

			for( let b of o.values ) {
				if( b.length ) b = {
					action: b[0],
					name: b[1] || `{${b[0].capitalize()}}`,
					picture: b[0]
				};
				let order = this.#orderList?.[b.action],
					orderstyle = order? 'order: ' + order : '';
				str += fillPlayerHTML( b, {
					type: 'none',
					classes: 'grayhover centertext',
					extra: `data-closeselect='${b.action}'`,
					style: orderstyle,
				} );
				if( lastpopular===b )
					str += `<span style='border-bottom: 1px solid gray; '></span>`;
			}
			str += '</div>';
		} else {
			let editable = true;
			str += `<label ${order} ${editable ? '' : 'disabled'}><span>${title} </span>`;
			/*
					if( typeof o==='boolean' ) {
						type = 'checkbox';
						def = o;
					}
			*/
			if( type==='checkbox' ) {
				str += `<input type='checkbox' ${def && 'checked' || ''} name='${name}'></input>`;
			} else if( type==='url' ) {
				str += `<input name='${name}' type='url' placeholder='https://example.com' pattern='https://.*' value='${def||''}'></input>`;
			} else if( type==='input' || type==='numeric' ) {
				// Editable
				str += `<input name='${name}' style='padding: 0.1em 0.3em; max-width: 10em; text-align: right'`;
				if( isnumber ) str += ` type='number' inputmode='numeric'`;
				else if( o.numeric || o.type==='numeric' ) str += " inputmode='numeric'";
				if( o.__pattern ) str += ` pattern='${o.__pattern}'`;
				let min = o.min ?? o.__min,
					max = o.max ?? o.__max;
				if( min!==undefined ) str += ` min=${min}`;
				if( max!==undefined ) str += ` max=${max}`;
				if( o.size || o.__size || max )
					str += ` size='${o.size || o.__size || max.toString().length}'`;
				if( o.maxlength || o.__maxlength )
					str += ` maxlength='${o.maxlength||o.__maxlength}'`;
				if( o.placeholder || o.__placeholder )
					str += ` placeholder='${o.placeholder || o.__placeholder}'`;
				let uniqid;
				if( optionsstr ) {
					uniqid = generateUUID();
					str += ` size='5' list='${uniqid}'`; // ${field}'`; ?? what is "field"?
				}
				if( def )
					str += ` value='${Editform.getValue( o, def )}'`;
				str += '>';
				if( uniqid ) {
					str += `<datalist id='${uniqid}'>${optionsstr}</datalist>`;
				}
			} else {
				str += `<select name='${name}'>${optionsstr}</select>`;
			}
			str += '</label>';
		}
		return str;
	}

	static getValue( p, key ) {
		if( !p || !p.options ) return key;
		for( let k in p.options ) {
			let v = p.options[k];
			if( Array.isArray( v ) ) [k, v] = v;
			if( k.toString()===key.toString() ) return v;
		}
		return key;
	}

	static getfreeform() {
		for( let o of forms )
			if( !o.big.isVisible() ) return o;
	}
}

export async function edit( params, options ) {
	// Optimize bigselect with no choice
	if( params?.action?.type==='bigselect' && params.action.values.length===1 ) {
		return {
			submit: params.action.values[0].action || params.action.values[0]
		}
	}
	let form = Editform.getfreeform() || new Editform;
	return await form.edit( params, options );
}

export async function editapi( el ) {
	let title, origin = el;
	if( el instanceof HTMLElement ) {
		title = el.dataset.title;
		origin = el.dataset.edit;
	}
	let res = {
		ok: true,
		title: 'TEST!',
		editparams: {
			words: ''
		}
/*
		editparams: {
			open: false,
			'': 'Комментарий',
			'text#что сказать': 'text',
			'test##Placholder': '',
			number: 10
		}
*/
	};
	if( origin!=='TEST' )
		res = await API( '/edit_start', { origin: origin }, 'internal' );
	if( !res?.ok ) return;
	let eparams = await edit( res.editparams, {
		title: title || res.title || '{Editing}',
		okbutton: res.okbutton || '{Edit}'
	} );
	if( !eparams ) return;
	await API( '/edit_done', {
		origin: origin,
		params: eparams
	} );
}

export async function select( definition, options ) {
	let res = await edit( {
		action: {
			type: 'bigselect',
			values: definition
		}
	}, options );
	return res?.submit;
}

export async function selectItem( opt ) {
	let deep = 0,
		leaf = { title: opt.title, picture: opt.picture, items: opt.items },
		tree = [];
	let shopwin = makeBigWindow( {
		id: 'selectitem_window',
		title: '{Select}'
	} );
	for( ;; ) {
		let str = '',
			k = 0,
			items = new Map;
		for( let l of ( leaf.items || [ 'fants' ] ) ) {
			let id = (l.id ?? k).toString();
			items.set( id, l );
			str += fillPlayerHTML( l, {
				size: 64,
				control: true,
				dataset: {
					closeselect: id
				}
			} );
			k++;
		}
		shopwin.setWinBody( {
			title: leaf.title,
			picture: leaf.picture,
			html: str
		});
		let shopres = await shopwin.promiseShow();
		let l = items.get( shopres );
		if( !l ) {
			// Выходим на уровень выше
			leaf = tree.pop();
			if( !leaf ) return;
		} else {
			// Выбран предмет, теперь выберем подвиды (если есть)
			let sub = l.selectors || l.items;
			if( !sub ) return l;
			tree.push( leaf );
			leaf = { title: l.name, picture: l.picture, items: sub };
		}
	}
}

export async function selectDatetimelocal( element ) {
	log( 'tz ' + new Date().getTimezoneOffset() );
	let date = null,
		offset = new Date().getTimezoneOffset()*60000,
		unixtime = +element.dataset.unixtime || +sessionStorage.lastDatetimelocal,
		min = element.dataset.min? `min='${new Date( element.dataset.min * 1000 - offset ).toISOString().slice( 0, 16 )}'` : '',
		max = element.dataset.max? `max='${new Date( element.dataset.max * 1000 - offset ).toISOString().slice( 0, 16 )}'` : '';
	if( unixtime )
		date = new Date( unixtime * 1000 - offset );
	let value = date && !isNaN( date )? date.toISOString().slice( 0, 16 ) : '';
	log( 'Value is ' + value );

	let win = makeBigWindow( {
		repeatid: 'win_selectdatetimelocal',
		title: element?.dataset.title || '{Time}',
		html: `<input type='datetime-local' value='${value}' ${min} ${max} style='margin: 0.5em 1em;'>
				<button class='default importantsize' data-closeselect='ok'>OK</button>`
	});
	let input = win.$( 'input' );
/*
	input.onchange = e => {
		closeTopBigwindow( e.target, e.target.value );
	};
*/
	setTimeout(  () => {
		try {
			win.$( 'input' ).showPicker?.();
		} catch( e ) {}
	}, 500 );
	win.promiseShow().then( res => {
		if( res==='cancel' ) return;
		let input = win.$( 'input' ),
			val = input.value,
			d = new Date( val );
		if( !input.validity.valid || isNaN( d ) ) {
			toast( '{Invalid} {format}' );
			return;
		}
		element.setContent( '⏰' ); // d.toLocaleString()
		element.dataset.unixtime = d.getTime()/1000;
		sessionStorage.lastDatetimelocal = d.getTime()/1000;
		if( element.datetime ) element.datetime = d.toISOString();
		if( element.dataset.onchangeapi && input.validity.valid )
			API( element.dataset.onchangeapi, {
				origin: element.closest( '[data-origin]' )?.dataset.origin,
				name: element.name || element.dataset.name,
				value: val
			}, 'internal' );
	});
	// win.$( 'input' ).showPicker?.()
}

window.modules.tools = {
	edit: edit,
	select: select,
	askMenu: askMenu,
	selectgame: selectgame,
	selectgameset: selectgameset,
	selectdatetimelocal: selectDatetimelocal,
	selectItem: selectItem
};