// Одноклассники
// ok.ru

let Core = elephCore,
	Auth = window.modules.Auth;

function checkLogin() {
	if( window.socketLoginStr ) return;
	// Dont do login request while another logged user is active
	if( Auth.failedLoginTypes && Auth.failedLoginTypes.has( 'ok' ) ) return;

	let str = 'type=ok';

	for( let k in Core.getParams ) {
		str += '&' + k + '=' + Core.getParams[k];
	}
	Auth.fetch( str );
}

Core.login = checkLogin;

function check() {
	if( !window.FAPI ) {
		log( 'No OK found, try again in 1sec!' );
		setTimeout( 1000, check );
		return;
	}
	log( 'OK try init' );
	let rParams = FAPI.Util.getRequestParameters();
	FAPI.init( rParams["api_server"], rParams["apiconnection"],
		checkLogin,
		( error ) => {
			log( 'OK INIT error: ' + JSON.stringify( error ) );
		} );
}

// Load the SDK asynchronously
(function( d, s, id ) {
	let js, fjs = d.getElementsByTagName( s )[0];
	if( d.getElementById( id ) ) return;
	js = d.createElement( s );
	js.id = id;
	js.src = "//api.ok.ru/js/fapi5.js";
	fjs.parentNode.insertBefore( js, fjs );
	setTimeout( check, 2000 );
}( document, 'script', 'ok-jssdk' ));
