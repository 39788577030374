window.STORAGE = 'https://storage.playelephant.com';

const MAGICID_LENGTH = 32;

HTMLImageElement.prototype.setMagic = function( id, origin ) {
	// if( DEBUG ) log( 'setMagic ' + id );
	let options;
	if( origin && typeof origin==='object' )
		options = origin;
	else
		options = { origin: origin };
	let size = options.size || +this.width || 48,
		type = this.dataset['magictype'],
		sizes = [ 48, 96, 256 ], path,
		magicid = id?.length===MAGICID_LENGTH && id,
		fullPath;

	if( magicid ) {
		// Path to picture
		sizes = type==='avatar'? [ 48, 96, 128, 256 ] : [ 48, 128, 256, 512 ];
		let suff = type==='avatar'? 'avatar' : 'preview';
		path = `${STORAGE}/images/${suff}`;
	} else {
		if( id==='S' || id==='-1' ) {
			path = `${IMGPATH}/icons`;
			id = FANTGAMES? 'diplo' : 'slonr';
		} else if( id?.includes('/') ) {
			fullPath = `${IMGEMBEDPATH}${id}`;
			id = null;
		} else if( +id /*|| type==='avatar'*/ ) {
			path = `${IMGPATH}/photo`;
			if( FANTGAMES || NEOBRIDGE )
				id = null;
		} else {
			path = `${IMGPATH}/icons`;
		}
	}
	let ws = 256;
	for( let w of sizes ) if( w>=size ) { ws = w; break; }
	let ws2 = ws;
	for( let w of sizes ) if( w>ws ) { ws2 = w; if( ws2>=ws*2 ) break; }
	this.dataset.value = this.dataset.magicid = magicid || '';
	if( options.origin ) this.dataset.origin = options.origin;
	if( id ) {
		this.src = `${path}/${ws}/${id}`;
		this.srcset = `${path}/${ws}/${id} 1x, ${path}/${ws2}/${id} 2x`;
	} else {
		this.removeAttribute( 'srcset' );
		if( this.dataset.editable ) {
			this.src = `${IMGPATH}/svg/icons/photo_camera-24px.svg`;
		} else if( fullPath )
			this.src = fullPath;
		else {
			if( options.origin?.includes( 'user' ) )
				this.src = NEOBRIDGE || FANTGAMES? `${IMGPATH}/svg/icons/ic_person_outline_black_18px.svg` : `${IMGPATH}/icons/64/sviterok.png`;
			else
				this.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
		}
	}
	if( id || path ) this.dataset.ownimg = 'yes';
	else delete this.dataset.ownimg;
};

window.fillMagicIMG = ( id, size, options, add ) => {
	if( typeof size==='object' ) {
		options = size;
		size = options.size;
	}
	let str = `<img alt='' width='${size}' height='${size}'`,
		type = options?.type,
		ext = [ 'ios', 'max' ].includes( navigator.userAgentData?.platform ) && '.png' || '',
		own = false;
	if( type==='avatar' && options?.origin && !options.origin.startsWith?.( 'user_' ) )
		type = 'logo';
	// Следующая строка не дает открывать каталог /photo на новых серверах
	if( +id && ( FANTGAMES || NEOBRIDGE ) ) id = null;
	if( id ) {
		id = id.toString();
		if( id.startsWith( 'https:/' ) ) {
			// Задан собственный путь к картинке
			str += ` data-magicid src='${id}'`;
		} else if( id.includes( '/' ) ) {
			// Задан собственный путь к картинке
			str += ` data-magicid src='${IMGEMBEDPATH}${id}'`;
		} else {
			let sizes = [48, 96, 256], path = `${IMGPATH}/photo`, magicid = '',
				ws, ws2;
			if( id.length===MAGICID_LENGTH ) {
				if( !type ) type = 'logo';
				let dir = type==='logo' && 'preview' || type;
				sizes = type==='avatar' ? [48, 96, 128, 256] : [48, 128, 256, 512, 1024];
				path = `${STORAGE}/images/${dir}`;
				magicid = id;
				own = true;
			} else if( !+id ) {
				sizes = [24, 48, 64, 96, 128, 256, 512];
				path = `${IMGPATH}/icons`;		// Просто иконка
				ws = ws2 = 96;
				own = true;
			}
			if( !ws ) {
				ws = 48;
				for( let w of sizes ) if( w>=size ) {
					ws = w;
					break;
				}
				ws2 = ws;
				for( let w of sizes ) if( w>ws ) {
					ws2 = w;
					if( ws2>=ws * 2 ) break;
				}
			}
			str += ` src='${path}/${ws}/${id}${ext}' data-magicid='${magicid}'
			srcset='${path}/${ws}/${id}${ext}, ${path}/${ws2}/${id}${ext} 2x'`;
		}
	} else {
		str += ` data-magicid`;
		if( options?.editable )
			str += ` src='${IMGEMBEDPATH}/svg/icons/photo_camera-24px.svg'`;
		else if( options?.defimg )
			str += ` src='${IMGPATH}/icons/svg/${options.defimg}.svg'`;
		else if( options?.origin?.startsWith('tour_') )
			str += ` src='${IMGPATH}/icons/svg/romansoldier.svg'`;
		else if( type==='avatar' )
			str += ` src='${IMGPATH}/svg/icons/ic_person_outline_black_18px.svg'`;
	}
	if( type ) str += ` data-magictype='${type}'`;
	if( own ) str += ` data-ownimg='yes'`;
	if( options && ( 'draggable' in options ) )
		str += ` draggable='${options.draggable? 'true' : 'false'}'`;
	if( options?.origin )
		str += ` data-origin='${options.origin}'`;
	// Классы
	// let classes = options?.classes || '';
	let classes = options?.nozoom? 'nozoom' : 'zoomable';
	if( options?.classes ) classes += ' ' + options.classes;
	if( classes.trim() ) str += ` class='${classes}'`;
	if( options?.title )
		str += ` title='${options.title}'`;

	if( !add ) add = options?.add;
	if( add ) str += ' ' + add;
	if( options?.add ) str += ' ' + options.add;
	if( options?.editable ) str += ` data-editable='${options.editable}'`;
	if( options?.noinfo ) str += ' data-noinfo="1"';
	// Styles
	let styles = options?.style || '';
	if( options?.stylesize )
		styles += `width:${options.stylesize};height:${options.stylesize};`;
	if( type==='logo' && +size<512 && id?.length===MAGICID_LENGTH ) styles += 'border-radius: 10%;';
	if( styles ) str += `style='${styles}'`;
	str += '>';
	return str;
};

