// Game player. Keeps some logic for the game and supports
// Примеры 	// ?view=bg:long.b63-24-18-18-15.64-24-18-18-14.51-15-10-24-23.65-14-8-24-19.65-24-18-18-13.21-24-23-19-17.32-13-10-24-22.55-24-19-19-14-14-9-23-18.43-23-19-10-7.66-17-11-24-18-9-3-11-5.54-22-18-24-19.42-24-20-18-16.22-24-22-18-16-16-14-7-5.43-16-13-13-9.42-24-22-22-18.55-18-13-24-19-13-8-8-3.52-19-14-24-22.41-5-1-24-23.64-24-18-14-10.22-20-18-18-16-16-14-23-21.43-10-6-22-19.41-24-20-14-13.53-19-14-14-11.41-24-20-20-19.54-24-19-18-14.63-24-21-21-15.43-14-10-19-16.53-24-19-19-16.51-10-5-24-23.52-19-14-3-1.63-24-18-22-19.64-24-20-20-14.55-24-19-19-14-19-14-23-18.62-14-8-13-11.52-6-1-24-22.61-24-18-15-14.41-14-10-18-17.66-14-8-24-18-21-15-20-14.53-10-5-11-8.21-18-16-19-18.65-18-12-12-7.31-18-15-15-14.32-24-22-22-19.21-18-16-16-15.62-7-1-14-12.62-16-14-14-8.63-10-7-7-1.22-14-12-11-9-14-12-16-14.41-8-4-5-4.21-15-14-14-12.31-4-1-12-11.53-8-3-14-11.61-11-5-5-4.31-11-8-12-11.43-5-1-4-1.31-12-9-9-8.63-19-16-16-10.62-9-3-11-9.22-10-8-8-6-6-4-19-17.61-9-3-8-7.31-17-14-5-4.31-12-9-8-7.21-14-12-12-11.51-8-3-8-7.51-22-17-11-10.51-8-3-9-8.52-17-12-12-10.53-8-3-15-12.65-10-4-10-5.21-9-7-12-11.51-18-17-17-12.51-7-6-11-6.53-12-7-7-4.61-14-8-7-6.44-16-12-12-8-8-4-5-1.42-8-4-6-4.52-17-12-12-10.53-7-4-6-1.43-14-10-10-7.65-7-2-6-0.54-10-5-7-3.52-4-0-2-0.65-5-0-4-0.41-4-0-1-0.31-3-0-1-0.63-4-0-3-0.64-4-0-4-0.55-3-0-3-0-3-0-3-0.51-4-0-1-0.53-3-0-3-0.64-4-0-4-0.61-1-0-1-0
//
import Vgame from "./vgame.js";

cssInject( 'protocol' );

export class Gameplay {
	#frames;
	#currentFrame;
	#column;
	#line;
	#markedLog;
	#partialLog;
	#animateUntil;
	#nextAnimationTime;

	constructor( game ) {
		this.#frames = [];
		this.#currentFrame = -1;
		this.#column = 0;
		this.#line = 0;
		this.game = game;

		// <span class='emoji rem2 control' data-action='backskip'>⏮️️️</span>&nbsp;
		// <span disabled class='emoji rem2 control' data-action='backmove'>◀️️</span>&nbsp;
		this.opPanel = html(
			`<div class='oppanel display_none flexline center' style='padding: 0.3em 0'>
			<span class='emoji rem2 control' data-action='playpause' title='Play/pause (Space)'>▶️️️</span>&nbsp;
			<input type='range' class='display_none visible' min=0 max=1 value=100 class='rem1 control'>️</input>&nbsp;
			<span class='fade sheet hideempty control' style='padding: 0.1em 0.5em; font-size: 1.3rem' data-action='backward' title='Move backward (Arrow left)'>←</span> 
			<span class='sheet fadeempty' style='min-width: 3em; text-align: center; padding: 0.1em 0.5em' data-action='framename'>️</span>
			<span class='fade sheet hideempty control' style='padding: 0.1em 0.5em; font-size: 1.3rem' data-action='forward' title='Move forward (Arrow right)'>→</span> 
		</div>`, game.controlZone, this.click.bind( this ) );

		this.opSlider = this.opPanel.$( 'input[type="range"]' );
		this.opSlider.oninput = this.sliderClick.bind( this );
		this.playpause = this.opPanel.$( '[data-action="playpause"]' );
		this.frameName = this.opPanel.$( '[data-action="framename"]' );
		this.forward = this.opPanel.$( '[data-action="forward"]' );
		this.backward = this.opPanel.$( '[data-action="backward"]' );

		this.log = html(
			`<div class='fade protocol lightborder' style='overflow: auto; display: grid; grid-template-columns: 1fr 2fr 2fr; align-content: start'></div>`
		);
		this.log.addEventListener( 'click', this.logClick.bind( this ) );
		addEventListener( 'keydown', this.keyDown.bind( this ) );
		// this.logTable = this.log.$( 'table' );
		this.#line = this.#column = 0;
		this.game.installLittle( 'log', {
			holder: this.log,
			icon: construct( '.control.grayhover.invertdark.protocol_icon @{Protocol}' )
		}, true );

		game.addRoute( {
			placechanged: this.placechanged.bind( this )
		} );

		this.placechanged();
	}

	checkSlider() {
		let max = this.#frames.length - 1;
		this.opSlider.max = max;
		// if( !self.replay )
		this.opSlider.value = this.#currentFrame;
		let fr = this.#frames[this.#currentFrame];
		// nfr = this.#frames[this.#currentFrame+1];
		this.frameName.textContent = fr?.name || '';
		// this.forward.textContent = nfr?.name || '';
		if( this.#markedLog ) delete this.#markedLog.dataset.marked;
		this.#partialLog = true;
		let ml = fr?.logElement;
		for( let c = this.#currentFrame; c<this.#frames.length && !ml; c++ ) {
			let f = this.#frames[c];
			if( !f ) break;
			ml = f.logElement;
		}
		this.#markedLog = ml;
		this.#partialLog = fr?.logElement!==ml;
		if( ml ) {
			ml.dataset.marked = this.#partialLog ? 'partial' : 'complete';
			ml.scrollIntoView( { behavior: 'smooth', block: 'nearest' } );
		}
		this.forward.makeVisible( this.#currentFrame<max );
		this.backward.makeVisible( this.#currentFrame>0 );
	}

	goLast() {
		this.goFrame( this.#frames.length - 1 );
		// if( this.game.protocol.count>10 )
			this.game.vgame.route( 'inforecommend log' );
	}

	get animatingNow() {
		return this.nextFrameTimer;
	}

	goFrame( no, animated ) {
		if( !(no>=0) ) return;
		if( no>=this.#frames.length ) no = this.#frames.length;
		if( no<0 ) no = 0;
		if( this.#currentFrame===no ) return;
		let fr = this.#frames[no];
		if( !fr ) return;
		if( animated!=='no' && (no===this.#currentFrame + 1 || animated) ) {
			this.#animateUntil = no;
			if( this.animatingNow ) return;
			this.playNext( true );
			return;
		}
		if( fr.snapshot ) {
			let notransition = this.#currentFrame!==no + 1;
			if( notransition ) {
				document.body.classList.add( 'notransition' );
				setTimeout( () => document.body.classList.remove( 'notransition' ), 200 );
			}
			this.game.vgame.route( fr.snapshot );
		} else {
			// Нет snapshot, необходимо откатиться назад на последний фрейм, где он есть, и проиграть всё оттуда
			let fromno = no - 1;
			for( ; fromno>=0; fromno-- )
				if( this.#frames[fromno]?.snapshot ) break;
			if( fromno<0 ) return;		// Нет ни одной позиции со снапшотом
			// for( ; fromno<=no; fromno++ ) {
			for( ; fromno<=no; fromno++ )
				this.playFrame( this.#frames[fromno] );
		}
		this.#currentFrame = no;
		this.checkSlider();
		this.stop();
		setHash( 'frame_' + no );
	}

	click( e ) {
		if( e.target.dataset.action==='playpause' ) return this.playPause();
		if( e.target.dataset.action==='forward' ) return this.nextLog();
		if( e.target.dataset.action==='backward' ) return this.prevLog( true );
		if( e.target.dataset.action==='framename' ) return this.game.toggleLittle( 'log' );
	}

	sliderClick( e ) {
		if( this.game.isplayer ) return;
		let value = this.opSlider.value;
		log( 'Slider ' + value );
		this.stop();
		this.goFrame( +value );
		/*
				Subscribe.route( routes, [ 'event', 'newdeal' ] );
				let frameidx = Math.floor( self.vgame.frames.length * value ),
					idx = self.vgame.frames[frameidx];
				if( idx===undefined || self.opSlider.value===self.opSlider.max ) idx = self.vgame.history.length;
				// Replay from vgame
				for( let p=0; p<idx; p++ ) {
					let ar = self.vgame.history[p];
					log( `** ${ar[0]} ${ar[1]}` );
					Subscribe.route( routes, ar );
				}

				self.replay = value<1;
				playZone.classList.toggle( 'replay', self.replay );
		*/
		// playZone.$( '.replayicon' ).makeVisible( !self.replay );
	}

	placechanged() {
		let vis = this.game.isSolo || !this.game.isPlayer;
		this.opPanel.makeVisible( vis );
		this.game.controlZone.makeVisible( vis );
	}

	addLog( plno, log ) {
		// Добавляет запись в лог, по которой будет открываться последний добавленный фрейм
		let frame = this.lastFrame;
		if( !log ) log = frame.name || '';
		let str = '';
		try {
			for( let found = 0; !found; ) {
				if( this.#column===0 ) str += `<span class='moveno'>${++this.#line}</span>`;
				if( this.#column===plno || this.game.maxPlayers>=plno ) found = 1;
				str += `<span data-frameno='${this.#frames.length - 1}' data-column='${plno}'>${found ? log : ''}</span>`;
				this.#column = (this.#column + 1) % this.game.maxPlayers;
			}
		} catch( e ) {
			log( e );
		}

		this.log.insertAdjacentHTML( 'beforeend', str );
		let el = this.log.lastElementChild;
		if( this.lastLog ) this.lastLog.nextLog = el;
		el.prevLog = this.lastLog;
		this.lastLog = el;
		frame.logElement = el;
	}

	get count() {
		return this.log.children.length;
	}

	get lastFrame() {
		return this.#frames[this.#frames.length - 1];
	}

	addFrame( frame ) {
		frame.game = this.game;
		frame.frameno = this.#frames.length;
		this.#frames.push( frame );
		this.checkSlider();
		frame.play && log( 'Added frame ' + JSON.stringify( frame.play ) );
		if( this.#currentFrame===this.#frames.length - 2 ) this.goFrame( this.#currentFrame + 1 );
		if( frame.snapshot==='make' )
			this.checkSnapshot( frame );
	}

	checkPlay( playnow ) {
		if( this.playing===playnow ) return;
		this.playing = playnow;
		this.playpause.textContent = playnow ? '⏸️' : '▶️';
	}

	playPause() {
		this.nextFrameTimer ? this.stop() : this.playNext( true );
	}

	stop() {
		if( this.nextFrameTimer ) clearTimeout( this.nextFrameTimer );
		this.nextFrameTimer = null;
		this.#animateUntil = null;
		this.checkPlay( false );
	}

	playNext( animateNext ) {
		if( !this.#frames.length ) return;
		if( (this.#currentFrame + 1)>=this.#frames.length )
			this.#currentFrame = 0;
		else
			this.#currentFrame++;
		let frame = this.#frames[this.#currentFrame];
		if( !frame ) {
			log( `#currentFrame=${this.#currentFrame} this.#frames.length=${this.#frames.length}` );
			bugReport( 'Bad frame in protocol' );
			return;
		}
		this.playFrame( frame );
		this.checkSlider();

		this.checkPlay( animateNext );

		let until = this.#animateUntil || this.#frames.length - 1;
		if( animateNext && this.#currentFrame<until ) {
			this.nextFrameTimer = setTimeout( () => this.playNext( true ), frame.duration || 1000 );
		} else this.stop();
	}

	playFrame( frame ) {
		if( !frame ) return;
		if( frame.play ) {
			// log( '[PLAY] ' + frame.play );
			this.game.vgame.route( frame.play );

			// После открутки попробуем сохранить важнейшее в снапшот для мгновенного восстановления
			this.checkSnapshot( frame );
		} else if( frame.snapshot )
			this.game.vgame.route( frame.snapshot );
	}

	checkSnapshot( frame ) {
		if( !frame.snapshot || frame.snapshot==='make' ) {
			frame.snapshot = {};
			(this.game.makeSnapshot || this.game.baseSnapshot)( frame.snapshot );
		}
	}

	logClick( e ) {
		let frameno = e.target.dataset.frameno;
		this.goFrame( +frameno );
	}

	checkAnimationAction( val ) {
		let res = Date.now()<this.#nextAnimationTime ? 'no' : true;
		this.#nextAnimationTime = Date.now() + (val || 1000);
		return res;
	}

	nextLog( slow ) {
		if( slow ) return this.playNext();
		let animate = this.checkAnimationAction();
		if( !this.#markedLog ) return;
		if( this.#frames[this.#animateUntil]?.logElement ) {
			// Stop animation and run immediately to next
			let from = this.#frames[this.#animateUntil].logElement,
				target = from.nextLog;
			if( target ) {
				this.stop();
				return this.goFrame( +target.dataset.frameno, 'no' );
			}
			return;
		}
		if( this.#frames[this.#currentFrame].logElement!==this.#markedLog ) return this.goFrame( +this.#markedLog.dataset.frameno, animate );
		let next = this.#markedLog.nextLog;
		if( next ) this.goFrame( +next.dataset.frameno, animate );
		// for( let el = this.#markedLog.nextElementSibling; el; el = el.nextElementSibling ) {
		// 	if( el.dataset.frameno ) return this.goFrame( +el.dataset.frameno, true );
		// }
	}

	prevLog( slow ) {
		this.checkAnimationAction( 500 );
		if( slow ) {
			if( this.#currentFrame )
				return this.goFrame( this.#currentFrame - 1 );
		}
		if( !this.#markedLog ) return;
		for( let el = this.#markedLog.previousElementSibling; el; el = el.previousElementSibling ) {
			if( el.dataset.frameno ) return this.goFrame( +el.dataset.frameno );
		}
	}

	upLog() {
		this.checkAnimationAction( 500 );

		if( !this.#markedLog ) return;
		let column = this.#markedLog.dataset.column;
		for( let el = this.#markedLog.previousElementSibling; el; el = el.previousElementSibling ) {
			if( el.dataset.column===column ) return this.goFrame( +el.dataset.frameno );
		}
		// Некуда идти, проскроллируем так, чтобы виден был верх
		this.log.firstElementChild.scrollIntoView( { behavior: 'smooth', block: 'center' } );
	}

	downLog() {
		if( !this.#markedLog ) return;
		let column = this.#markedLog.dataset.column;
		for( let el = this.#markedLog.nextElementSibling; el; el = el.nextElementSibling ) {
			if( el.dataset.column===column ) return this.goFrame( +el.dataset.frameno );
		}
		this.log.lastElementChild.scrollIntoView( { behavior: 'smooth', block: 'center' } );
	}

	keyDown( e ) {
		if( this.game.isActive &&
			(!['INPUT', 'TEXTAREA'].includes( e.target.tagName ) || e.target===this.opSlider) ) {
			if( e.key.startsWith( 'Arrow' ) ) e.preventDefault();
			if( e.key==='ArrowRight' ) return this.nextLog( e.ctrlKey || e.metaKey );
			if( e.key==='ArrowLeft' ) return this.prevLog( e.ctrlKey || e.metaKey );
			if( e.key==='ArrowUp' ) return this.upLog();
			if( e.key==='ArrowDown' ) return this.downLog();
			if( e.key===' ' ) return this.playPause();
		}
	}
}

export default function set( game ) {
	if( !game.protocol )
		game.protocol = new Gameplay( game );
}

export function makeProtogame( params ) {
	let p = params;
	if( params instanceof URLSearchParams ) {
		p = {};
		for( let [key, val] of params )
			p[key] = val;
	}
	let gameid = p.game;
	return new Vgame( {
		id: 'game_proto',
		game: gameid,
		replay: true,
		nosubscription: true,
		autoshow: true,
		bigwindow: params.bigwindow,
		setproto: p
	} );
}

/*
addEventListener( 'keydown', () => {

});*/
