let dropDownMenu, menuPanel;

document.addEventListener( 'mousedown', down );
document.addEventListener( 'touchstart', down );

function down( e ) {
	if( !dropDownMenu?.isVisible() ) return;
	if( e.target.closest( '.dropdown' ) ) return;
	hide();
	e.stopPropagation();
	e.preventDefault();
}

function hide() {
	menuPanel.hide();
}

export async function dropDown( str, event, params ) {
	if( !menuPanel ) {
		menuPanel = makeBigWindow( {
			id: 'dropdownpanel',
			html: `<div class='dropdown column sheet'
		style='position: absolute; top: 100%; z-index: 100000; border: 1px solid var(--light_gray); 
		padding: 0.5em 1em; gap: 0.5em; text-align: left;'></div>`,
			closeonclick: true
		} );
		menuPanel.addEventListener( 'click', click, true );
		menuPanel.addEventListener( 'contextmenu', click, true );
	}
	dropDownMenu ||= menuPanel.$( '.dropdown' );
/*
	dropDownMenu ||= html( `<div class='dropdown display_none column sheet'
		style='position: absolute; top: 100%; z-index: 100000; border: 1px solid var(--light_gray); 
		padding: 0.5em 1em; gap: 0.5em'></div>`, document.body, click );
*/
	dropDownMenu.html( str );
	menuPanel.dataset.byorigin = params?.origin || '';
	for( let el of dropDownMenu.children )
		el.classList.add( 'grayhover' );
	// Меню должно выпадать там, где кликнули мышкой
	let style = {
		left: event.pageX,
		top: event.pageY
	};
	if( params?.target ) {
		let r = params.target.getBoundingClientRect();
		style = {
			right: document.body.clientWidth - r.right,
			top: r.y + r.height
		};
	}
	dropDownMenu.style.left = isNaN(style.left)? 'initial' : style.left + 'px';
	dropDownMenu.style.right = isNaN(style.right)? 'initial' : style.right + 'px';
	dropDownMenu.style.top = style.top + 'px';
	menuPanel.show();
}

function click( e ) {
	hide();
}