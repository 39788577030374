
let history = [];

function cleanHistory() {
	history = history.filter( x => x.timestamp > Date.now()-1000*60 );
}

const originsPos = {
		bottom: { origin: { x: 0.5, y: 1 }, angle: 90, gravity: 0 },
		top: { origin: { x: 0.5, y: 0 }, angle: -90, gravity: 0 },
		right: { origin: { x: 1, y: 0.5 }, angle: 180, gravity: 0 },
		left: { origin: { x: 0, y: 0.5 }, angle: 0, gravity: 0 }
};

const effects = {
}

function getParams( options ) {
	if( !options ) return {};
	if( options.code==='puzzledone' ) return {
		repeat: 20,
		particleCount: 30,
		startVelocity: 15,
		spread: 360,
		gravity: 0.5,
		ticks: 100,
		origin: {
			x: Math.random(),
				// since they fall down, start a bit higher than random
			y: Math.random() - 0.2
		}
	}

	if( effects[options.code] ) return effects[options.code];
	let game = options.game,
		plno = options.plno;
	if( !game || !(plno>=0) ) return {};
	// Transform player position to x/y percents
	let avt = game.players[plno].elavatar,
		rect = avt.getBoundingClientRect(),
		pos = originsPos[game.getPositionOf( plno )];
	if( window.visualViewport ) pos.origin = {
		x: (rect.x + rect.width/2) / window.visualViewport.width,
		y: (rect.y + rect.height/2 )/ window.visualViewport.height
	}
	return pos;
}

window.makeCool = async function( options ) {
	// Check if this effect already done in this deal
	if( !window.confetti ) return;
	try {
		cleanHistory();
		if( options ) {
			if( !options.force ) {
				if( history.find( x => x.game===options.game && x.plno===options.plno && x.dealno===options.dealno && x.code===options.code ) ) {
					log( 'Effect repetition' );
					return;
				}
				history.push( {
					timestamp: Date.now(),
					...options
				} );
			}
		}
		let params = getParams( options );
		if( !params ) {
			return;
		}
		let dimension = params.angle===0 || params.angle===180 ? document.body.clientWidth : document.body.clientHeight;
		if( options?.code==='GLP' ) {
			confetti.luck ||= confetti.shapeFromText( { text: '🍀', scalar: 3 } );
			confetti( {
				shapes: [confetti.luck],
				scalar: 3,
				spread: 25,
				ticks: 100,
				startVelocity: dimension / 12,
				...params
			} );
		} else {
			let repeat = params.repeat || 1;
			for( ; repeat--; ) {
				confetti( getParams( options ) );
				if( repeat ) await sleep( 250 );
			}
		}
	} catch( e ) {
		log( `Confetti failed with reason ${e.name}: ${e.message}` );
	}
}