import Subscribe from './subscribe.js';

let map = new Map, types = new Map;

export default class Invitation {

	static find( id ) {
		return map.get( id );
	}

	static set( o ) {
		let inv = Invitation.find( o.id );
		if( !inv ) inv = new Invitation( o );
		inv.update( o );
		return inv;
	}

	remove() {
		map.delete( this.id );
		if( this.holder ) this.holder.parentElement.removeChild( this.holder );
		let typeset = types.get( this.type );
		typeset && typeset.delete( this );
		// this.checktypeList.add( inv.type );
	}

	static drop( id ) {
		let inv = map.get( id );
		inv && inv.remove();
	}

	static getOfType( type ) {
		return types.get( type );
	}

	static findAccepted( type ) {
		let invs = types.get( type );
		if( !invs ) return;
		for( let inv of invs ) {
			if( inv.state==='accepted' ) return inv;
		}
	}

	constructor( o ) {
		this.id = o.id;
		map.set( o.id, this );
		let ar = o.id.split('.');
		this.type = ar.slice( 0, 2 ).join('.');
		this.by = ar[2];
		this.mine = this.by===UIN;

		this.sender = o.sender;
		this.recipient = o.recipient;
		this.data = o.data;
		let oftype = types.get( this.type );
		if( !oftype ) {
			oftype = new Set;
			types.set( this.type, oftype )
		}
		oftype.add( this );
		// this.to = o.to;

		if( this.by!==window.UIN ) {
			let parent = document.querySelector( '.invitations' );
			if( parent ) {
				this.holder = construct( `.display_none.visible.invitation.sheet[data-hideonrequest=1]`, parent, this.click.bind( this ) );
				this.holder.dataset.origin = this.id;
				this.holder.dataset.request = 'replyinvite';
				let str = '';
				// if( this.data.logo )
				// 	str += `<img height=48 width=48 src='${STORAGE}/images/preview/48/${this.data.logo}'>`;
				let usender = User.set( this.sender.uin, {
					showname: this.sender.name,
					picture: this.sender.picture || this.sender.magicid
				}  );
				str += fillPlayerHTML( usender, {
					text:
						`<div><span class='subtitle'>{invites}</span><span>${this.data.title}</span></div>
						<div class='buttons'><button class='default' data-action='accept'>{Accept}</button><span data-name='close' data-action='cancel'>X</span></div>`
				} );
				this.holder.innerHTML = str;
			}
		}
	}

	click( e ) {
		let act = e.target.dataset.action;
		if( act ) {
			e.stopPropagation();
			window.elephCore.do( `type=invite id=${this.id} action=${act}` );
			if( act==='cancel' ) {
				return this.holder.hide();
			}
		} else {
			log( 'Invite click with no act' );
		}
		if( !elephCore || elephCore.location!==this.data.id.toString() ) {
			fire( 'goroom', this.data.id );
			e.stopPropagation();
		}
		return false;
	}
	update( o ) {
		// this.data = o;
		this.accepted = o.state==='accepted';
		this.time = o.time;
		if( this.holder ) {
			this.holder.classList.toggle( 'accepted', this.accepted );
			// this.elOk.classList.toggle( 'visible', !this.accepted );
			// this.elText.textContent = localize( this.accepted ? '{registered}' : '{invitesinapair}' );
		}
	}
}

window.setInvitations = o => {
	for( let [,one] of map ) one.obsolete = true;
	for( let one of o ) {
		let inv = Invitation.set( one );
		if( inv ) inv.obsolete = false;
	}
	for( let [,v] of map )
		if( v.obsolete || v.accepted ) v.remove();

	fire( 'invitationschanged' );
};

Subscribe.add( '_me', ( o, minor ) => {
	if( minor!=='invites' ) return;
	// if( LOCALTEST ) o = [{"iid":165,"id":"tourpair.818155.2.2","f":"2::Олег7","sender":{"uin":2,"nick":"Олег7","name":"Олег Дроздюк"},"from":"2","fromnick":"Олег7","t":"2::Олег7","recipient":{"uin":2,"nick":"Олег7","name":"Олег Дроздюк"},"to":"2","data":{"id":"818155","title":"20:30. Парный турнир"},"tourid":818155,"time":1611595800,"name":"20:30. Парный турнир","game":"PREF","room":818155},{"iid":165,"id":"tourpair.818155.1361118.1361118","f":"1361118::Олег7","sender":{"uin":1361118,"nick":"Олег7","name":"Олег Дроздюк"},"from":"1361118","fromnick":"Олег7","t":"1361118::Олег7","recipient":{"uin":1361118,"nick":"Олег7","name":"Олег Дроздюк"},"to":"1361118","data":{"id":"818155","title":"20:30. Парный турнир"},"tourid":818155,"time":1611595800,"name":"20:30. Парный турнир","game":"PREF","room":818155}];
	setInvitations( o );
} );

window.Invitation = Invitation;