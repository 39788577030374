'use strict';
import './core.js';
import './swiper.js';

window.setCoreParams(   {
	id: 'gamb',
	tableviewstart: 'room_9',
	promo: {
		createclub: true
	},
	appid: 'ru.gambler.alpha',
	appstoreid: 'https://apps.apple.com/us/app/id1493351186'
} );

// Custom modules
window.promiseModule = modname => {
	if( modname==='chat' ) return import( './chat.js' );
	if( modname==='html2canvas' ) return import( /* webpackExclude: '../require/html2canvas.min.js' */ '../require/html2canvas.min.js' );
	return Promise.resolve();
};

// All game modules start now
window.promiseGameModule = type => {
	if( type==='game' ) return import( './game.js' );
	if( type==='cards' ) return import( './cards.js' );
	if( type==='domino' ) return import( './domino.js' );
	if( type==='board' ) return import( './board.js' );
	if( type==='match' ) return import( './match.js' );
	if( type==='chio' || type==='chipoker' ) return import( './chipoker.js' );
	if( type==='poker' ) return import( './poker.js' );
	if( type==='bg' || type==='gammon' ) return import( './gammon.js' );
	return null;
};

window.promiseGameExtend = type => {
	if( type==='pool' ) return import( './pool.js' );
	if( type==='claim' ) return import( './claim.js' );
	if( type==='bidbox' ) return import( './bidbox.js' );
	if( type==='auction' ) return import( './auction.js' );
	if( type==='handrecord' ) return import( './handrecord.js' );
	if( type==='littleinfo' ) return import( './littleinfo.js' );
};

import( './auth.js' );			// Standard authorization
import( './core.js' );
import( './room.js' );
import( './chat.js' );

if( window.location.pathname==='/register' ) {
	window.coreParams.startAction = 'register';
}

if ('serviceWorker' in navigator) {

	navigator.serviceWorker.getRegistrations().then( function( registrations ) {
		for( let registration of registrations ) {
			registration.unregister()
		}
	} );
};

window.addEventListener( 'load', () => {
	if( !window.INFRAME && !window.ADMIN && !window.cordova && !LOCALTEST )
		import( './yandex_ad.js' );
});

window.allAds = [
	{
		id: 'risingprice',
		tilldate: '2023-12-27',
		language: 'ru',
		regions: 'ru',
		random: {
			takebutton: [ 'Беру!', 'Не упускать же', 'Купить сейчас' ]
		},
		html: `<h2>Повышение цен</h2>
			<span>27 декабря все рублевые цены в нашем магазине вырастут. 
			Купив Премиум сегодня, вы сохраните для себя нынешнюю цену и после 27го. 
			Мы не изменяем стоимость подписки, пока она активна.</span>
			<button default data-buy='all' data-closeselect='shopping'>$takebutton</button>`
	},
	{
		id: 'nomoreads',
		random: {
			buybutton: [ '{Youveconvincedme}', '{Removeads}', '{Disableads}', '{Buy} {now}' ]
		},
		html: `<h2>{Annoying_ads}</h2>
			<span>{Any_subscription_disables_ads_fulltext}</span>
			<button default data-buy='disableads;premium;club' data-closeselect='shopping'>$buybutton</button>`
	}
]
