// import './core.js';

cssInject( 'reger' );

const bets = {
	pref: [ 1, 2, 3, 4, 5, 7, 10, 20, 50, 100 ],
	chio: [ 0.1, 0.2, 0.3, 0.5, 1, 2, 5, 10 ],
	skak: [ 0.5, 1, 1.5, 2, 2.5, 3, 5, 7, 10 ],
	'pref-kz': [ 0.5, 1, 1.5, 2, 2.5, 3, 5, 7, 10 ],
	'pref-bandit': [ 0.5, 1, 1.5, 2 ],
	'pref-hussar': [ 0.5, 1, 1.5, 2, 3, 5, 10 ]
};

const
	pokercustom = {
		_pokermoney: 'cash',
		BB: [ 1, 2, 5, 10, 20, 50, 100, 200 ],
		pokerrule: {
			__title: '{Game}',
			type: 'select',
			options: [ ['TEXAS', '{Texas}'], ['OMAHA4', '{Omaha}-4'], ['OMAHA5', '{Omaha}-5'],
				['OMAHA4_HILOW', '{Omaha}-4 Hi/Lo'], ['HOLDEM6', '6+ {short}'], [ 'DEALERCHOICE', '{Dealerchoice}' ] ]
		},
		limit: [ [ 'FL', 'L - limit' ], [ 'HPL', 'HPL - Half-pot Limit' ], [ 'PL', 'PL - Pot Limit' ], [ 'NL', 'NL - No Limit' ] ],
		twoflops: false,
		stack: { default: 'std', options: [ [ 'cap', '{Short} 20-40 BB' ], [ 'std', '{Standard} 50-100 BB' ], [ 'deep', '{Deep} 100-250 BB' ] ] },
		mp: { default: '10', options: [ '1x1', '6', '8', '10' ] },
		__onchange: ( params, changedel, editform ) => {
			// При изменении параметра. Если поменялся pokerrule, надо изменить доступность других полей
			if( changedel && changedel.name!=='pokerrule' ) return;
			let dchoice = params.pokerrule==='DEALERCHOICE';
			for( let f of [ 'limit', 'twoflops' ] )
				editform.getLabel( f ).toggleAttribute( 'disabled', dchoice );
			let lmp = editform.getLabel( 'mp' ),
				option10 = lmp.$( 'option[value="10"]' );
			option10.disabled = dchoice;
			if( dchoice && option10.selected ) {
				lmp.$( 'option[value="8"]' ).selected = true;
				option10.selected = false;
			}
		},
		__shorttitle: ( p, names ) => {
			if( p.pokerrule==='DEALERCHOICE' )
				return names.pokerrule;
			else
				return p.limit + ' ' + names.pokerrule;
		}
	},
	pokerstt = {
		_pokermoney: 'tour',
		// BB: [ 1, 2, 5, 10, 20, 50, 100, 200 ],
		pokerrule: {
			__title: '{Game}',
			type: 'select',
			options: [ ['TEXAS', '{Texas}'], ['OMAHA4', '{Omaha}-4'], ['OMAHA5', '{Omaha}-5'],
				['OMAHA4_HILOW', '{Omaha}-4 Hi/Lo'], ['HOLDEM6', '6+ {short}'], [ 'DEALERCHOICE', '{Dealerchoice}' ] ]
		},
		limit: [ [ 'FL', 'L - limit' ], [ 'HPL', 'HPL - Half-pot Limit' ], [ 'PL', 'PL - Pot Limit' ], [ 'NL', 'NL - No Limit' ] ],
		twoflops: false,
		mp: { default: '6', options: [ '1x1', '6', '8', '10' ] },
		__onchange: ( params, changedel, editform ) => {
			// При изменении параметра. Если поменялся pokerrule, надо изменить доступность других полей
			if( changedel && changedel.name!=='pokerrule' ) return;
			let dchoice = params.pokerrule==='DEALERCHOICE';
			for( let f of [ 'limit', 'twoflops' ] )
				editform.getLabel( f ).toggleAttribute( 'disabled', dchoice );
			let lmp = editform.getLabel( 'mp' ),
				option10 = lmp.$( 'option[value="10"]' );
			option10.disabled = dchoice;
			if( dchoice && option10.selected ) {
				lmp.$( 'option[value="8"]' ).selected = true;
				option10.selected = false;
			}
		},
		__shorttitle: ( p, names ) => {
			return p.limit + ' ' + names.pokerrule
		}
	},
	timerdelay = {
		timerdelay: {
			__title: `⏲️{Timingmethod}`,
			default: '',
			options: [ ['', ''], ['fisher', '{Fisher}'], [ 'simple', '{Simpledelay}'], ['bronstein', '{Bronstein}'] ]
		},
	},
	bgcustom = {
		mtype: [[ '1', '1 {game}'], [ '3', '{to} 3'], ['5', '{to} 5'], ['7', '{to} 7'], ['9', '{to} 9'], ['11', '{to} 11'] ],
		time: [['4+1 min 4m', '4+1'], ['5+2 min 5m', '5+2'], ['10+1', '10+1'], ['14+2', '14+2'], ['18+2', '18+2']],
		...timerdelay,
		rent: { type: 'comment', order: 1000 }
	},
	longcustom = {
		mtype: [ [ '1', '1 {game}'], [ '2', '{to} 2'], ['3', '{to} 3'], ['4', '{to} 4'], ['5', '{to} 5']],
		time: [['4+1 min 4m', '4+1'], ['5+2 min 5m', '5+2'], ['10+1', '10+1'], ['14+2', '14+2'], ['18+2', '18+2']],
		rent: { type: 'comment', order: 1000 }
	},
	longdrawcustom = {
		mtype: [ [ 'G2', '2' ], [ 'G4', '4' ], [ 'G6', '6' ], [ 'G8', '8' ] ],
		_bgdraw: true,
		time: [['4+1 min 4m', '4+1'], ['5+2 min 5m', '5+2'], ['10+1', '10+1'], ['14+2', '14+2'], ['18+2', '18+2']],
		rent: { type: 'comment', order: 1000 }
	},
	nardecustom = {
		mtype: [[ '3', '{to} 3' ], ['4', '{to} 4'], ['5', '{to} 5'], ['7', '{to} 7'], ['9', '{to} 9'], ['11', '{to} 11'] ],
		time: [['4+1 min 4m', '4+1'], ['5+2 min 5m', '5+2'], ['10+1', '10+1'], ['14+2', '14+2'], ['18+2', '18+2']],
		rent: { type: 'comment', order: 1000 }
	},
	deberccustom = {
		time: [ [ '4+1 min 4m', '4+1'], ['5+2 min 5m', '5+2'], ['10+1', '10+1']],
		cube: false,
		mars: true,
		rent: { type: 'comment', order: 1000 }
	},
	deberc301custom = {
		time: [['4+1 min 4m', '4+1'], ['5+2 min 5m', '5+2'], ['10+1', '10+1']],
		rent: { type: 'comment', order: 1000 }
	},
	hypercustom = {
		mtype: [[ '5', '{to} 5'], ['7', '{to} 7'], ['9', '{to} 9'] ],
		time: [['4+1 min 4m', '4+1'], ['5+2 min 5m', '5+2'], ['10+1', '10+1']],
		rent: { type: 'comment', order: 1000 }
	},
	chiocustom = {
		localid: 'chiocustom',
		mp: [['2', '👤2'], ['3', '👤3']],
		rent: { type: 'comment', order: 1000 }
	},
	prefcustom = {
		localid: 'prefcustom',
		_game: 'pref',
		mp: [['3', '👤👤👤'], ['4', '👤👤👤👤']],
		type: [ ['piter','{Piter}'], ['sochi', '{Sochi}' ], [ 'rostov', '{Rostov}' ] ],
		preflength: { min: 10, max: 50, default: 30 },
		// preflength: [27, 30, ['D36', '{Deals}: 36']],
		prefkaski: false,
		whist10: false,
		bpgames: true,
		remiz3: true,
		rent: { type: 'comment', order: 1000 },
		__shorttitle: ( p, names ) => {
			return `${names.type}/${p.preflength}`
		}
	}, hussarcustom = {
		localid: 'hussarcustom',
		_game: 'pref-hussar',
		type: ['piter', 'sochi', 'rostov'],
		preflength: [27, 30, 50, 75, 100, ['D36', '{Deals}: 36']],
		bpgames: true,
		rent: { type: 'comment', order: 1000 }
	}, prefkz = {
		localid: 'prefkz',
		_game: 'pref-kz',
		mp: [['3', '👤3'], ['4', '👤4']],
		time: [ [ '3m addon 5m', '{Standard} (3+5)' ], [ '2m addon 5m', '{Middle} (2+5)' ], [ '1m addon 5m', '{Fast} (1+5)' ] ],
		// preflength: [ 160, 120 ],
		rent: { type: 'comment', order: 1000 }
	}, prefmini = {
		localid: 'prefmini',
		mp: [['3', '👤3'], ['4', '👤4']],
		_game: 'pref',
		type: ['piter', 'sochi', 'rostov'],
		preflength: [20, 30, 40, ['D36', '{Deals}: 36'], ['demo', '{Demo}']],
		time: [ [ '3m addon 5m', '{Standard} (3+5)' ], [ '1m addon 5m', '{Fast} (1+5)' ] ],
		rent: { type: 'comment', order: 1000 }
	}, skakcustom = {
		localid: 'skakcustom',
		_game: 'skak',
		mp: { default: '4', options: [['3', '👤3'], ['4', '👤4']] },
		time: [ [ '3m addon 5m', '{Standard} (3+5)' ], [ '2m addon 5m', '{Middle} (2+5)' ], [ '1m addon 5m', '{Fast} (1+5)' ] ],
		rent: { type: 'comment', order: 1000 }
	}, prefbandit = {
		localid: 'prefbandit',
		mp: [['2', '👤2'], ['3', '👤3']],
		_game: 'bandit',
		bandittype: [['short', '{Short} (10 {brboards})'], ['full', '{Big} (30 {brboards})']],
		rent: { type: 'comment', order: 1000 }
	}, boardcustom = {
		mtype: [[ '1', '1 {game}' ], ['M2', '{Match}/2'], ['M4', '{Match}/4']],
		time: {
			default: '2+2',
			options: ['1+0', '1+1', '2+0', '2+1', '2+2', '3+0', '3+1', '3+2', '4+2', '5+0', '5+3', '5+5',
				'6+0', '7+0', '7+5', '8+5', '10+0', '10+2', ['0+2', '⚡0+2']]
		},
		...timerdelay,
		mycolor: [['white', '⚪'], ['black', '⚫']],
		swapcolor: true
	}, cornerscustom = {
		...boardcustom,
		standing: {
			__title: '{Position}',
			options: [ '3x3', '3x4', [ 'triangle', '{Triangle}' ] ]
		}
	};

	// Двойной подсад только для клубных настроек
	if( FANTGAMES || LOCALTEST ) {
		prefcustom.whistrdoubled = false;
		prefcustom.longwhist = false;
		prefbandit.banditselectwhist = {
			__caption: 'Whist type select',
			__type: 'checkbox'
		}
		// bgcustom.faircontrol = false;
	}

let allregers = new Map,
	lastHall = localStorage.lastregerhall,
	showedSet = new Set,
	// typePhrases = { friends: '{Withfriends}', rated: '{Quickgame}', tourney: '{Tourney}' },
	myregers = new Set,
	setregers = new Set,
	halls = {},
	// selectPlayType = makeBigWindow(),
	selectPlayMain = makeBigWindow( {
		closeonclick: 'empty',
		fullsize: true
	} ),
	// windowType = construct( '.vertical_form.normal.selectplaytype', selectPlayType ),
	windowSelect = construct( '.selectplay', selectPlayMain ),
	// windowSelectClose = construct( '.closebutton.righttop[data-closeframe=1]', windowSelect ),
	selectreger = construct( '.display_none.selectreger', windowSelect ),
	selectgame = construct( '.display_none.selectgame', windowSelect ),
	selectHallHolder,
	selectTypeHolder = construct( '.selecttype', windowSelect, selectType ),
	regersNoPlay = createcontent( 'cantplaygames', 'Not available yet', windowSelect ),
	selectedHall,
	storedHall = localStorage.playhall,
	playType = localStorage.playtype;
if( !['friends', 'rated'].includes( playType ) ) playType = 'rated';

if( playType )
	if( !window.coreParams?.onehall )
		selectHallHolder = construct( '.selecthall', windowSelect );

const gameInfo = {
	sbg: {
		game: 'bg.BG.SBG',
		icon: 'bg',
		hallid: 'bg',
		phrase: '_Sbg_'
	},
	lbg: {
		game: 'bg.BG.LBG',
		icon: 'bg',
		hallid: 'bg',
		phrase: '_Lbg_'
	},
	nlbg: {
		game: 'bg.BG.NLBGG',
		icon: 'bg',
		hallid: 'bg',
		phrase: '_Nlbg'
	},
	ch100: {
		game: 'board.DRAUGHTSGAME100.CH100',
		icon: 'wcheckers',
		hallid: 'draughts',
		phrase: 'ch100_'
	},
	brasil: {
		game: 'board.DRAUGHTSGAME.BRASIL',
		icon: 'wcheckers',
		hallid: 'draughts',
		phrase: 'brasil_'
	},
	poolcheckers: {
		game: 'board.DRAUGHTSGAME.POOLCHECKERS',
		icon: 'wcheckers',
		hallid: 'draughts',
	},
	poddavki: {
		game: 'board.DRAUGHTSGAME.PODDAVKI',
		icon: 'wcheckers',
		hallid: 'draughts',
	},
	chess: {
		game: 'board.CHESSGAME.CHESS',
		icon: 'wknight',
		hallid: 'chess'
	},
	bridge: {
		game: 'cards.BRIDGE.BRIDGE',
		icon: 'bridge',
		hallid: 'bridge'
	},
	pref: {
		game: 'cards.PREF.PREF',
		hallid: 'pref'
	},
	skak: { game: 'cards.PREF.SKAK', icon: 'pref_horse' },
	pref_kz: { game: 'cards.PREF.PREF_KZ' },
	pref_hardpiter: { game: 'cards.PREF.PREF_HARDPITER' }
};

// Класс холла
class Hall {
	constructor( id ) {
		this.id = id;
		this.button = construct( '.display_none.visible.control.hallbutton.icon', hallclick, selectHallHolder );
		this.icon = id.replace( 'draughts', 'checkers' );		// Замена иконки на привычную шашку.
		// При этом реально иконка тоже заменяется (24/03/23), поэтому позже можно будет убрать replace
		this.count = '';
		this.countPlay = 0;
		this.regers = new Set;
		this.createGameSelect = [];
		this.createTourSelect = [];
		this.createTeamGameSelect = [];
		halls[id] = this;
		this.button.dataset.hallid = id;
		this.button.hall = this;
		this.button.style.backgroundImage = 'url( ' + window.IMGPATH + '/icons/hires/' + this.icon + ' )';
		if( id===storedHall || (!selectedHall && !storedHall) ) selectHall( this );
	}
}

const gameRegers = new Map;

// Экземляр блока регистрации REGER
class Reger {
	constructor( id, params ) {
		if( typeof id==='object' ) {
			params = id;
			id = params.id;
		}
		this.id = id;
		let createparams = this.createparams = params || {};
		this.title = this.createparams._title || this.createparams.title || '';
		let game = createparams.game,
			gi = gameInfo[game];
		if( !id && game )
			// gameRegers.set( game + '#' + this.title.replace( /[{}]/g, '' ), this );
			gameRegers.set( game, this );

		if( game?.includes( 'chi' ) )
			createparams.hallid = params.hallid = 'chipoker';
		if( createparams.hallid==='checkers' ) createparams.hallid = 'draughts';

		if( !createparams.hallid && gi ) {
			for( let k in gi ) {
				createparams[k] = gi[k];
			}
		}
		createparams.hallid ||= createparams.game?.split( '.' )[1].toLowerCase();
		if( window.coreParams.onehall && createparams && createparams['hallid']!==window.coreParams.onehall ) return;
		this.holder = construct( `.display_none.reger.grayhover[data-reger=${id}]`, this.click.bind( this ) );
		this.holder.addEventListener( 'contextmenu', this.contextmenu.bind( this ), true );
		this.gamedef = [];
		this.count = '';
		this.showed = false;
		this.reged = false;

		this.holder.innerHTML = `
					<div class='fade counter small'></div>
					<div class='doreg'></div>
					<div class='display_none lock'></div>
					<div data-field='timecontrol' class='hideempty'></div>
					<div class='circle flexline center' 
						style='width: max( 96px, var(--bigavatar) ); height: max( 96px, var(--bigavatar) ); '>
						<div class='display_none bet betfants nowrap hideempty'></div>
						<img class='image' />
					</div>
					<div class='title'></div>
					`;

		this.timer = new elephTimer( {
			parent: this.holder.$( '.circle' ),
			visibility: 'always',
			yellow: 60000
		} );
		this.dataFields = [];

		let a = this.holder.querySelectorAll( '[data-field]' );
		for( let el of a )
			this.dataFields[el.dataset['field']] = el;

		this.params = {};

		this.setHall( createparams?.hallid || createparams?.hallicon );

		if( createparams ) this.update( createparams );

		dispatch( 'loggedin', this.checkLocked.bind( this ) );

		elephSubscribe.add( '_me', this.checkLocked.bind( this ) );

		setregers.add( this );
	}

	subscribe( val ) {
		if( !this.id ) return;				// Friend game
		if( val===undefined )
			val = this.reged || (this.showed && playType==='rated' && selectPlayMain.classList.contains( 'visible' ));
		if( this.reged ) val = true;
		if( val ) {
			if( !this.subId ) this.subId = elephSubscribe.add( 'reger_' + this.id, this.parser.bind( this ) );
			this.timer.resume();
		} else {
			if( this.subId ) elephSubscribe.delete( this.subId );
			this.timer.sleep();
			this.subId = null;
		}
	};

	show( value ) {
		if( value && this.showed || !value && !this.showed ) return;
		this.showed = !!value;
		this.holder.classList.toggle( 'visible', this.showed );
		if( this.showed ) log( 'Show ' + this.id + ': ' + this.params.title );
		this.showed ? showedSet.add( this ) : showedSet.delete( this );
	}

	checkShow() {
		this.show( this.hall===selectedHall && this.active && this.order!== -1 );
		this.subscribe();
	}

	reg( r ) {
		if( r===this.reged ) return;
		this.reged = r;
		this.holder.classList.toggle( 'registered', this.reged );
		if( this.reged ) {
			myregers.add( this.id );
		} else
			myregers.delete( this.id );
		this.subscribe();
	};

	getMyFee() {
		if( !this.fee ) return;
		if( this.fee.freemium && elephCore.isHallPayer( this.hallid ) ) return;
		if( this.fee.fants && MYFANTS?.value>=this.fee.fants ) return;
		return this.fee;
	}

	async contextmenu( e ) {
		// Open customizing window
		if( !(+this.id>0) ) return;
		e.stopPropagation();
		e.preventDefault();
		if( !this.options ) {
			this.options = await elephCore.sendPlay( `type=getregeroptions reger=${this.id}` );
		}
		let set = await elephCore.sendPlay( `type=getmyregersettings reger=${this.id}` ),
			mine = set.reply?.[this.id];
		if( mine ) this.mysettings = mine.split( ' ' ).reduce( ( acc, cur ) => {
			let ar = cur.split( '=' );
			acc[ar[0]] = ar[1];
			return acc;
		}, {} );
		let mod = await import( './tools.js' );
		let res = await mod.edit( this.options, {
			picture: this.createparams.icon,
			title: this.title,
			values: this.mysettings,
			okbutton: '{Store}',
		});
		if( res ) {
			let str = '';
			for( let k in res ) {
				if( k!=='submit' ) str += ` ${k}=${res[k]}`;
			}
			await elephCore.sendPlay( `type=setmyregersettings reger=${this.id} data="${encodeURIComponent( str )}"` );
		}
	}

	async click( params ) {
		let team = params?.team,
			room_id = params?.room_id;
		if( this.locked ) return;
		let isdemoclub = team?.isDemoclub;
		if( !isdemoclub ) await waitAuth( 'complete' );
		// Проверим, достаточно ли средств на оплату
		let fee = this.getMyFee();
		if( fee ) {
			// Не можем начать игру без докупки
			await (await import( './pay.js' )).askBuy( {
				what: fee,
				picture: this.params.icon,
				title: this.params.title,
				descr: this.params.descr
			} );
			return;
		}
		if( !this.id ) {
			// Выбор игры с друзьями
			if( this.params.withrobots ) {
				// Только один стол возможен, нет ли его уже у нас?
				let vg = elephVgame?.findWithRobots();
				if( vg ) {
					closeBigWindows();
					return vg.show();
				}
			}
			let str = `type=friendgame data="game=${this.gamedef.slice( -1 )} room=${room_id||''}`,
				custom = this.createparams.custom,
				stt = custom?._pokermoney==='tour';
			custom ||= {};
			// if( !this.id && !('open' in custom) && PLAYELEPHANT ) custom.open = false;
			let betlist, serv;
			// Если игра внутри команды, узнаем допустимые ставки
			if( team ) {
				custom.rent ||= { type: 'comment', order: 1000 };
				let r = `team=${team.numericid} game=${this.gamedef.slice( -1 )}`;
				if( stt ) r += ' stt=1';
				if( UIN )
					serv = await elephCore.sendPlay( `type=getbetlist data="${encodeURIComponent( r )}"` );
				else
					serv = { bets: '10;20;50;100' };		// Democlub, demobets
				if( serv.bets ) betlist = serv.bets.split( ';' ).filter( x => !!x );
			}

			if( !custom.localid ) custom = Object.assign( { localid: 'reger_' + this.title }, custom );
			this.createparams.custom = custom;
			if( team ) {
				custom = this.teamParams?.get( team );
				if( !custom ) {
					this.teamParams ||= new Map;
					let b = betlist ||
						bets[this.gamedef.slice(-1)[0].toLowerCase()]
						|| bets[this.gamedef[1]?.toLowerCase()];
					custom = Object.assign( {
						_team: team.numericid
					}, this.createparams.custom );
					if( b ) {
						if( custom.BB )
							custom.BB = {
								options: b.map( x => [ x, `${team.currency||''} ${x/2} / ${x}` ]),
								order: -100
							};
						else if( stt )
							custom.buyin = {
								options: b.map( x => [ x*100,  x*100 + ' ' + (team.currency||'') ] ),
								order: -100
							};
						else
							custom.stake = {
								options: b.map( x => [ x,  x + ' ' + (team.currency||'') ] ),
								order: -100
							};
					}
					// if( custom.BB )
					this.teamParams.set( team, custom );
					// if( serv ) custom.rent = serv.rent;
				}
			}
			let eparams, resultNames = {};
			if( custom ) {
				let options = {
					id: team? custom.localid + '_' + team.id : custom.localid,
					title: this.title || custom.title,
					subtitle_html: team && fillPlayerHTML( team, {
						style: 'align-self: center;',
						showbalance: true } ),
					picture: this.params.icon,
					okbutton: '{Create}',
					disable: team? [ 'open' ] : null,
					nodemo: !!team,
					resultNames: resultNames,
					minWidth: '13em'
				};
				// Fair control allowed inside the clubs or at Fantgames
				let gammon = this.createparams.hallid==='bg';
				if( gammon && ( FANTGAMES || params.team || LOCALTEST ) )
					custom.faircontrol = {
						__type: 'checkbox',
						__fade: true,
						default: false
					};
				else
					delete custom.faircontrol;
				if( ( custom.rent || serv?.clubrent || serv?.innerrent ) && !isdemoclub ) {
					custom.payforeveryone ??= {
						default: false,
						__type: 'checkbox',
						__hide: true
					};
					options.onchange = async target => {
						// Запросим цену аренды на базе данных, которые соберем из окна
						let j = collectParams( target, { serverstr: true } ),
							str = j.serverstr,
							twin = getBigWindow( target ),
							every = twin?.$( `[data-labelfor='payforeveryone']` ),
							fair = twin?.$( `[data-labelfor='faircontrol']` );
						fair?.makeVisible( j.mtype!=='1' );
						if( !j.game ) str += ` game='${this.gamedef.slice( -1 )}'`;
						let res = await elephCore.sendPlay( `type=checkrent data="${encodeURIComponent( str )}"` );
						every?.hide();
						if( res?.rent ) {
							let r = res.rent.toString(),
								add = '';
							if( r[0]==='?' && elephCore.isPremium() ) {
								r = '0';
								add = " ({premium})";
							} else if( res.youforfree ) {
								r = '0';
							}

							twin?.$( '[data-name="rent"]' )?.setContent( `{Rent}: <span class='fants'>${r.replaceAll( '?', '' )}</span>${add}` );
							every?.show();
						} else {
							let str = '';
							if( res?.clubtrial ) {
								str += `<span title='Normal mode: inclub rent=${res.innerrent||0}, clubrent: ${res.clubrent||0}💰'>🟢{Trialperiod}</span>`;
							} else {
								if( res?.innerrent ) {
									str += `<span class='money'>${showBalance( res.innerrent, team )}</span>`;
								}
								if( res?.clubrent ) {
									let r = res.clubrent.toString();
									if( str ) str += '<br>';
									str += `🟡{Club}: <span class='fants'>${r.replace( '?', '' )}</span>`;
									if( res.dealrent ) str += '/{board}';
									str += ' /👤';
								}
								if( str ) str = '{Rent} ' + str;
							}
							twin?.$( '[data-name="rent"]' )?.setContent( str );
						}
					}
				}
				eparams = await (await import( './tools.js' ))
					.edit( custom, options );

				if( !eparams ) {
					// Canceled selection;
					return;
				}
				log( 'Selected params ' + JSON.stringify( eparams ) );
				for( let k in eparams ) {
					str += ` ${k}='${eparams[k]}'`;
				}
			}
			if( this.params.params ) str += ' ' + this.params.params;
			str += '"';
			if( isdemoclub && !UIN ) {
				// Попросим временный гостевой UIN для проб
				await modules.Auth.checkAuthGuest( {
					force: true
				});
				// toast( '{Newgame}. {Authorizationrequired}' );
				// return checkAuth( 'complete' );
			}
			elephCore.sendPlay( str );
			if( eparams ) {
				let title = custom.__shorttitle?.( eparams, resultNames );
				if( !title ) {
					title = this.createparams.title;
					if( eparams.mtype && +eparams.mtype!==1 )
						title += ' ' + custom.mtype.reduce( ( acc, x ) => { acc[x[0]] = x[1]; return acc; }, {} )
							[eparams.mtype];
				}
				localStorage[`last_newgame_${team?.numericid}`] = JSON.stringify( {
					serveraction: str,
					picture: this.createparams.icon || this.createparams.hallid,
					title: title,
					subtitle: resultNames.stake || resultNames.BB || ''
				} );
			}
			return;
		}
		if( !this.canPlay ) return;
		if( this.params.noguests && elephCore.auth.guest ) {
			// Go authorization
			elephCore.login();
			return;
		}
		let onstr = '', oldcount = this.count;
		if( !this.params.instant && !this.params.creategame ) {
			this.reg( !this.reged );
			this.holder.classList.add( 'tempreg' );
			// setTimeout( () => this.reg( Core.myRegers.has( this.id ) ), 2000 );
			let a = this.count.split( '+' );
			if( !a[0] ) this.setCount( this.reged ? '1' : '' );
			else {
				let s = a[0] + '+' + (+a[1] + 1 || 1);
				if( !this.reged ) {
					if( !a[1] && +a[0] ) s = a[0] - 1 || '';
					else s = a[0] + '+' + (+a[1] - 1 || 0);
				}
				this.setCount( s );
			}
			onstr = ' on=' + (this.reged ? 1 : 0);
		}
		// if( LOCALTEST ) await sleep( 500 );

		let json = await Promise.any( [elephCore.sendPlay( 'type=reg reger=' + this.id + onstr ), sleep( 2000 )] );
		if( !json || json.error ) {
			this.reg( false );
			if( json && 'count' in json ) this.setCount( json.count );
			else this.setCount( oldcount );
			// toast( json.error );
		}
	}

	setCount( c ) {
		c = c && c!=='0' && c?.toString() || '';
		if( this.count===c ) return;
		this.count = c;
		let counter = this.holder.$( ".counter" );
		counter.textContent = '👤' + this.count;
		counter.makeVisible( !!this.count );
	};

	parser( data, minor ) {
		if( minor==='info' || minor==='full' )
			this.update( data );
		else if( minor==='c' ) {
			if( data[0]==='+' || data[0]==='-' ) {
				this.reg( data[0]==='+' );
				data = data.slice( 1 );
			}
			this.setCount( data );
			// }
		} else if( minor==='timestart' ) {
			// Set timer to start
			let num = +data;
			this.timer.setDestination( num );
		}
	}

	setHall( h ) {
		if( !h ) return;
		if( h==='checkers' ) h = 'draughts';
		if( this.gamedef.includes( 'chio' ) ) {
			h = 'chipoker';
		}
		if( this.hallid===h ) return;
		if( window.coreParams.onehall && this.createparams?.hallid!==h ) return;
		// log( `Reger set hall ${h}` );
		this.hallid = h;
		this.hall = halls[h];
		if( !this.hall ) this.hall = new Hall( h );
		if( this.id ) {
			// lasthallholder = hallregers[h][0];
			this.hall.regers.add( this.holder );
		}
		if( !this.id || this.createparams.creategame ) {
			let object = {
					action: this.id || this.createparams.game + '#' + this.title.replace( /[{}]/g, '' ),
					picture: this.createparams.icon || this.hall.icon,
					title: this.title,
					onlyclubs: this.createparams.onlyclubs
				};
			if( !this.createparams.teamonly )
				this.hall.createGameSelect.push( object );
			if( !this.id && !+this.action )
				this.hall.createTeamGameSelect.push( object );

			if( !this.createparams.notour && !this.id )
				this.hall.createTourSelect.push( object );
		}
		delay( checkHallButtons );
		delay( checkShow );
	}

	update( o ) {
		if( !o || o==='NOTFOUND' ) return;
		// return;
		if( !o.game ) {
			log( 'BAD REGER: ' + JSON.stringify( o ) );
			return;
		}
		this.params = o;
		this.active = o['active'] || !this.id;
		if( !this.order ) this.order = 0;
		if( 'order' in o ) this.order = o.order;
		this.gamedef = o.game.split( '?' )[0].split( '.' );
		this.canPlay = elephCore.checkCanPlay( this.gamedef[0] );
		if( !this.canPlay ) this.active = false;
		this.holder.$( '.title' ).setContent( o.title );
		this.setHall( o.hallid || o.hallicon );
		// lang.setText( eltitle, o.title );
		if( o.icon || o.hallid ) {
			// this.holder.$( 'div.image' ).style.backgroundImage = 'url( ' + window.IMGPATH + '/icons/hires/' + (o.icon || o.hallid) + ' )';
			this.holder.$( 'img.image' ).src = window.IMGPATH + '/icons/hires/' + (o.icon || o.hallid);
		}
		for( let k in this.dataFields )
			if( o[k] ) this.dataFields[k].setContent( o[k] );
		// let h = o.hallid,
		// 	lasthallholder;
		let bet = o.bet || o.fbet || o.rent || '',
			hideifpayer = bet[0]==='?' && o.hallid || '',
			elbet = this.holder.$( '.bet' ),
			tbet = bet.replace( '?', '' );
		if( bet && mayReview() ) this.order = -1;	// Прячем этот автоподбор
		elbet.dataset.hideifpayer = hideifpayer || '';
		elbet.makeVisible( !hideifpayer || !elephCore.isHallPayer( o.hallid ) );
		if( tbet )
			elbet.setContent( `${tbet}${hideifpayer?' {or} 💎':''}` );
		else
			elbet.textContent = '';

		this.fee = bet && {
			freemium: bet[0]==='?',
			fants: +bet.replace( '?', '' ) || +o.forstart
		};

		this.accessloot = o['accessloot'];
		this.checkLocked();
		this.checkOrder();
		// holder.classList.toggle( 'disabled', +o['bet']>0 || o['accessloot'] );
		this.setHall( o.hallid );
		if( this.id && !this.params.creategame ) {
			// Автоматический подбор
			if( this.hall ) {
				this.hall.count++;
				if( this.canPlay ) this.hall.countPlay++;
			}
			// if( o['active'] ) {
			// Core.subscribe( 'reger.' + id );
			// if( lasthallholder )
			// 	selectreger.insertBefore( holder, lasthallholder );
			// else
			selectreger.appendChild( this.holder );
			// }
		} else {
			selectgame.appendChild( this.holder );
		}
		this.checkShow();
	}

	checkLocked() {
		let l = DEBUG ? 0 : !!this.accessloot;
		if( !l && this.params['noguests'] && elephCore.auth.guest ) l = true;
		// if( !l && this.bet>0 ) {
		// l = (+Subscribe.get( '_me.fants' ) || 0)<bet;
		// }
		if( this.locked===l ) return;
		this.locked = l;
		this.holder.classList.toggle( 'locked', this.locked );
		this.checkOrder();
	}

	checkOrder() {
		this.holder.style.order =
			this.bet ? this.bet + (this.locked && 1000000 || 0) : this.order;
	}
}


selectreger.innerHTML = '<div style="order: 999999; height: 10px; width: 100%"></div>';

let friendsButton = construct( 'button.selectable._checkable[data-playtype=friends] {Withfriends}', /*selectType*/ playFriends, selectTypeHolder );

let teachButton = construct( 'button.display_none[data-action=teach] 🧙{Teaching}', createTeaching, selectTypeHolder );
teachButton.style.order = '10';

// construct( 'button.selectable.checkable[data-playtype=rated] {Quickgame}', selectType, selectTypeHolder );

/*
if( !FANTGAMES && (!window.cordova || Core.premium || !inapppurchaseOnly) && UIN!=='1396791' /!* AppReview *!/ ) {
	construct( 'button[data-playtype=tourney] 🛡️{Create} {tourney}', createTour, selectTypeHolder );
}
*/
// construct( '.graybutton[data-playtype=friends] {Withfriends}', selectType, windowType );
// construct( '.graybutton[data-playtype=rated] {Withall}', selectType, windowType );
// construct( '.graybutton[disabled][data-playtype=tourney] {Tourney}', selectType, windowType );

selectPlayMain.onHide = subscribeShowed;

function createChio( hall ) {
	create( {
		game: 'chio.CHIO.CHIPOKER-PINE',
		custom: chiocustom,
		icon: 'pineapple',
		hallid: hall,
		title: '{Pine}'
	} );
	create( {
		game: 'chio.CHIO.CHIPOKER-PINE7',
		custom: chiocustom,
		icon: 'pineapple',
		hallid: hall,
		title: '2-7'
	} );
	create( {
		game: 'chio.CHIO.CHIPOKER-4PINE',
		custom: chiocustom,
		icon: 'pineapple',
		hallid: hall,
		title: '{Big}'
	} );
}

let domino_custom = {
		mp: [['2', '👤👤'], ['3', '👤👤👤'], ['4', '👤👤👤👤'] ],
		glen: [101, 201, 301, 501],
		time: [['game 6m', '6{min}'], ['game 8m', '8{min}'], ['game 10m', '10{min}']],
	},
	domino5_custom = {
		...domino_custom,
		glen: [ 100, 250, 500 ],
		div5: {
			__title: '{Fullrecord}',
			default: false
		}
	},
	domino_anycustom = {
		mp: [['2', '👤👤'], ['3', '👤👤👤'], ['4', '👤👤👤👤'] ],
		dominosize: {
			type: 'select',
			__title: '{Type}',
			default: 6,
			options: [3, 6, 9, 12, 15, 18],
		},
		glen: [101, 201, 301, 501],
		time: [['game 6m', '6{min}'], ['game 8m', '8{min}'], ['game 10m', '10{min}']]
	};

create( { game: 'DOMINO-CLASSIC', custom: domino_anycustom, icon: 'domino', hallid: 'domino', title: '{Domino}' } );
create( { game: 'DOMINO-5', custom: domino5_custom, icon: 'domino', hallid: 'domino', title: '{Muggins}' } );
create( { game: 'DOMINO-CLASSIC_pair', custom: { ...domino_anycustom, mp: { default: 4, type: 'hidden' } }, icon: 'domino', hallid: 'domino', title: '{Domino} 2x2' } );
create( { game: 'DOMINO-5_pair', custom: { ...domino5_custom, mp: { default: 4, type: 'hidden' } }, icon: 'domino', hallid: 'domino', title: '{Muggins} 2x2' } );


// Friend game buttons
if( FANTGAMES ) {
	createChio( 'chipoker' );
	create( {
		game: 'cards.PREFGAME.PREF',
		custom: prefcustom,
		icon: 'pref',
		hallid: 'pref',
		title: '{Prefgame}'
	} );
	create( {
		game: 'cards.PREFGAME.SKAK',
		icon: 'pref_horse',
		hallid: 'pref',
		custom: skakcustom,
		title: '{Skak_}'
	} );
	create( { game: 'cards.PREFGAME.PREF-KZ', icon: 'pref', custom: prefkz, hallid: 'pref', title: '{Pref_kz}' } );

	// create( { game: 'board.CHESSGAME.CHESS', icon: 'wknight', hallid: 'chess', title: '{Chess}' } );
	// create( { game: 'board.CHESSGAME.CHESSGIVE', icon: 'wknight', hallid: 'chess', title: '{Giveaway}' } );

	if( !FANTGAMES || +UIN<600000 ) {
		create( {
			game: 'cards.PREFGAME.PREF-BANDIT',
			icon: 'pref_pistol',
			hallid: 'pref',
			custom: prefbandit,
			title: '{Bandit}'
		} );
	}
	create( {
		game: 'cards.PREFGAME.PREF-HUSSAR',
		icon: 'prefpool2',
		hallid: 'pref',
		custom: hussarcustom,
		title: '{_Hussar}'
	} );
} else {
	/*
		create( {
			game: 'cards.PREFGAME.PREF',
			custom: prefmini,
			params: 'mp=3', icon: 'prefpool3', hallid: 'pref', title: '{Prefgame} 👤3'
		} );

	*/
	create( {
		game: 'cards.PREFGAME.PREF',
		custom: prefmini,
		icon: 'pref', hallid: 'pref', title: '{Prefgame}'
	} );

	/*
		create( { game: 'cards.PREFGAME.PREF', params: 'mp=3 preftype=piter length=25', icon: 'prefpool3', hallid: 'pref', title: '{Piter}/25' } );
		create( { game: 'cards.PREFGAME.PREF', params: 'mp=3 preftype=piter length=40', icon: 'prefpool3', hallid: 'pref', title: '{Piter}/40' } );
		create( { game: 'cards.PREFGAME.PREF', params: 'mp=4 preftype=piter length=25', icon: 'prefpool4', hallid: 'pref', title: '{Piter}/25' } );
	*/
	/*
		create( {
			game: 'cards.PREFGAME.PREF',
			custom: hardcustom,
			params: 'mp=4 preftype=piter length=40 kaski=yes pprize=yes',
			icon: 'prefpool4', hallid: 'pref', title: '{Piter}/!*'
		} );

	*/
	create( {
		game: 'cards.PREFGAME.SKAK',
		custom: skakcustom,
		params: '',
		icon: 'pref_horse', hallid: 'pref', title: '{Skak_}'
	} );

	create( {
		game: 'cards.PREFGAME.BANDIT',
		icon: 'pref_pistol',
		hallid: 'pref',
		custom: prefbandit,
		title: '{Bandit}'
	} );

	/*
		create( {
			game: 'cards.PREFGAME.BANDIT',
			params: 'mp=3',
			icon: 'pref_pistol',
			hallid: 'pref',
			custom: prefbandit,
			title: '{Bandit} 👤3'
		} );
	*/
	/*
		if( LOCALTEST ) {
			create( {
				active: 1,
				game: 'cards.PREFGAME.PREF',
				params: 'mp=3 robogame=1 preftype=piter',
				icon: 'robot',
				withrobots: 1,
				hallid: 'pref',
				title: '{Withrobots}'
			} );
		}
	*/

	create( {
		active: 1,
		game: 'DURAK',
		params: 'mp=2',
		icon: 'jesterhat',
		// withrobots: 1,
		hallid: 'durak',
		title: '{Podkid}'
	} );

	createChio( 'poker' );

	create( { active: 1, game: 'DURAKP', params: 'mp=2', icon: 'jesterhat',/* withrobots: 1, */ hallid: 'durak', title: '{Perevodnoy}' } );
	create( { active: 1, game: 'DURAK4', params: 'mp=4', icon: 'jesterhat',/* withrobots: 1, */ hallid: 'durak', title: '{Podkid} 👥' } );
	create( { active: 1, game: 'DURAK4_P', params: 'mp=4', icon: 'jesterhat',/* withrobots: 1, */ hallid: 'durak', title: '{Perevodnoy} 👥' } );

	/*
		if( DEBUG )
			create( {
				game: 'cards.BRIDGE.BRIDGE',
				params: 'mp=1 justbid=yes',
				solo: 'demo',
				icon: 'bridge',
				hallid: 'bridge',
				title: '{Auction} {teaching}'
			} );

	*/

	create( {
		game: 'cards.G1000.G1000',
		params: 'glen=1001 golden=no remiz=minus flank=0',
		icon: 'barrel',
		hallid: GAMBLERRU? 'sandbox' : '1000',
		title: '{Game1000}'
	} );

	let king_custom = {
		mp: [['3', '👤👤👤'], ['4', '👤4']],
		time: [['game 6m', '6{min}'], ['game 8m', '8{min}'], ['game 10m', '10{min}']],
		boys: [ [ 'both', '{King_boysall}' ], [ 'jacks', '{King_boysjacks}' ] ],
		eralash: true
	}, kingz_custom = {
		mp: [['3', '👤3'], ['4', '👤4']],
		time: [['game 13m', '13{min}'], ['game 8m', '8{min}'], ['game 10m', '10{min}']],
		boys: [ [ 'both', '{King_boysall}' ], [ 'jacks', '{King_boysjacks}' ] ],
		eralash: true
	};

	if( !FANTGAMES ) {
		create( {
			game: 'BRIDGE',
			params: 'tourgroup=brimps',
			// custom: king_custom,
			// icon: 'bridge',
			hallid: 'bridge',
			title: 'IMP'
		} );
	}

	create( {
		game: 'KING',
		custom: king_custom,
		icon: 'king',
		hallid: GAMBLERRU? 'sandbox' : 'king',
		title: '{King_game}'
	} );

	create( {
		game: 'KING-ZAKAZ',
		custom: kingz_custom,
		icon: 'king',
		hallid: GAMBLERRU? 'sandbox' : 'king',
		title: '{_Kingz}'
	} );

	let hearts_custom = {
		// mp: [['3', '👤3'], ['4', '👤4']],
		time: [['1 addon 2', '1{min}'], ['2 addon 2', '2{min}'], ['3 addon 2', '3{min}'] ],
	};
	create( {
		game: 'HEARTS',
		custom: hearts_custom,
		icon: 'twohearts',
		hallid: GAMBLERRU? 'sandbox' : 'hearts',
		title: '{_Hearts}'
	} );

	let updown_custom = {
		mp: [ [ '2', '👤2'], ['3', '👤3'], ['4', '👤4'] ],
		cards: [ 32, 52 ],
		time: [ [ 'game 5', '5{min}'], [ 'game 7', '7{min}'], [ 'game 9', '9{min}'] ],
	};
	create( {
		game: 'cards.updown.updown',
		custom: updown_custom,
		icon: 'simpleladder',
		hallid: GAMBLERRU? 'sandbox' : 'updown',
		title: '{Updown}'
	} );

	// create(  { game: 'cards.asker', params: 'collect=match', icon: 'bridge', hallid: 'bridge', title: '{Match}', order: 20 } );
	// create( { game: 'cards.bridge.bridge', params: 'tourgroup=brimps', icon: 'bridge', hallid: 'bridge', title: '{Imps}' } );
}

create( { game: 'board.DRAUGHTSGAME.DRAUGHTS-RUSSIAN', custom: boardcustom, icon: 'wcheckers', hallid: 'draughts', title: '{Draughts}' } );
create( { game: 'board.DRAUGHTSGAME.BRASIL', custom: boardcustom, icon: 'wcheckers', hallid: 'draughts', title: '{Brasil}' } );
create( { game: 'board.DRAUGHTSGAME.CH100', custom: boardcustom, icon: 'wcheckers', hallid: 'draughts', title: '{Draughts} 100' } );
create( { game: 'board.DRAUGHTSGAME.PODDAVKI', custom: boardcustom, icon: 'wcheckers', hallid: 'draughts', title: '{Poddavki_}' } );
create( { game: 'board.CHESSGAME.CHESS', custom: boardcustom, icon: 'wknight', hallid: 'chess', title: '{Chess}' } );
create( { game: 'board.CHESSGAME.CHESSGIVE', custom: boardcustom, icon: 'wknight', hallid: 'chess', title: '{Giveaway}' } );
create( { game: 'board.XO.RENFREE', icon: 'board', hallid: 'draughts', title: '{Fiveinarow}' } );
create( { game: 'board.XO.UGOLKI', custom: cornerscustom, icon: 'board', hallid: 'draughts', title: '{Ugolki}' } );
create( { game: 'board.XO.OTHELLO', icon: 'board', hallid: 'draughts', title: '{Othello}' } );

if( !window.NEOBRIDGE ) {
	create( { game: 'bg-short', icon: 'bg', hallid: 'bg', custom: bgcustom, title: '{_Sbg_}' } );
	create( { game: 'bg-long', icon: 'bg', hallid: 'bg', custom: longcustom, title: '{_Lbg_}' } );
	create( { game: 'bg-short_hyper', hallid: 'bg', custom: hypercustom, title: '{Hyper}' } );
	create( { game: 'bg-short_nack', hallid: 'bg', custom: bgcustom, title: '{_Ng_}' } );
	create( { game: 'bg-long_crazy', hallid: 'bg', custom: longcustom, title: '{_Crazybg_}' } );
	create( { game: 'bg-long_nard', hallid: 'bg', custom: nardecustom, title: '{Nardegammon}' } );

	if( !FANTGAMES ) {
		create( { game: 'bg-long?draw', icon: 'bg', hallid: 'bg', custom: longdrawcustom, notour: true, onlyclubs: [ 16808 ], title: '{_Lbg_} {withadraw}' } );
	}

	create( { game: 'belot-2', hallid: 'belot', title: '{Belot}' } );
	// create( { game: 'belot-4', hallid: 'belot', title: '{Belot} 2x2' } );
	if( FANTGAMES ) create( { game: 'belot-4', hallid: 'belot', title: '{Belot} 2x2' } );
	create( { game: 'deb-2', hallid: 'belot', custom: deberccustom, title: '{Deberc}' } );
	create( { game: 'deb-301', hallid: 'belot', custom: deberc301custom, title: '{Deberc}-301' } );
	create( { game: 'deb-udar', hallid: 'belot', title: '{Deberc_udar}' } );
}
// document.body.appendChild( windowSelect );

if( FANTGAMES || LOCALTEST ) {
	create( {
		teamonly: !LOCALTEST,
		game: 'poker',
		custom: pokercustom,
		icon: 'poker',
		hallid: 'poker',
		title: '{Cashgame}',
	} );

	if( LOCALTEST ) {
		create( {
			game: 'burkozel',
			icon: 'goat',
			hallid: 'goat',
			title: '{Burkozel}'
		} );
	}
}

window.modules.subscribe.addParser( 'reply_reg', ( o ) => {
	let r = allregers.get( o['id'] );
	if( !r ) return;
	r.setCount( o['count'] );
	r.reg( false );
	if( o.error ) {
		// Show the reason by the simple messager
		elephCore.simpleMessage( r.holder, o.error );
	}
} );

function updateone( s, minor ) {
	// Define one reger
	if( minor==='NOTFOUND' || minor==='DONE' || !minor )
		return;
	set( minor, s );
}

dispatch( 'regerschanged', regersChanged );

function regersChanged() {
	let copy = new Set( myregers );
	for( let [r] of elephCore.myRegers ) {
		if( myregers.has( r ) ) {
			copy.delete( r );
			continue;
		}
		let reger = set( r );
		reger?.reg( true );
	}
	for( let k of copy ) {
		allregers.get( k ).reg( false );
	}
}

// Subscribe.add( 'registrar', updateone );
window.modules.subscribe.add( 'regerdefine', updateone );

function subscribeShowed() {
	let dosub = playType==='rated' && selectPlayMain.classList.contains( 'visible' );
	for( let r of showedSet ) {
		r.subscribe( dosub );
	}
}

function checkHallButtons() {
	for( let hall of Object.values( halls ) ) {
		if( !hall ) continue;
		hall.button.makeVisible( hall.regers.size || hall.createGameSelect.length );
	}
}

export default function selectPlay() {
	if( cssMustWait( 'reger', selectPlay ) ) return;
	// selectPlayType.hide();
	// Check visible hall buttons
	checkHallButtons();
	selectPlayMain.show();
	checkShow();
	subscribeShowed();
}

export function playHall( hall ) {
	changeHall( hall );
	selectPlay( 'select' );
}

function changeHall( hall ) {
	selectHall( halls[lastHall || hall] );
}

dispatch( 'hallchanged', hall => {
	if( halls[hall] && !selectPlayMain.isVisible() )
		lastHall = hall;
// 		changeHall( hall );
});

export async function selectGame( hall, params ) {
	if( LOCALTEST && !params?.team && hall==='poker' ) hall = 'chipoker'; // testing chio
	params ||= {};
	let action = params.action || 'newgame';
	let popular = [], lastgame,
		lastgame_json = localStorage[`last_${action}_${params.team?.numericid}`];
	if( lastgame_json ) {
		try {
			lastgame = JSON.parse( lastgame_json );
			if( lastgame )
				popular.push( {
					title: lastgame.title,
					subtitle: lastgame.subtitle,
					picture: lastgame.picture,
					action: 'lastgame',
					popular: true
				} );
		} catch( e ) {
		}
	}
	if( !hall )
		hall = Object.values( halls );
	else if( typeof hall==='string' )
		hall = hall.split( ' ' ).map( x => halls[x.toLowerCase().replace( 'checkers', 'draughts' ) ] );
	// else
	// 	hall = hall.id && [hall.id] || [...hall];
	if( LOCALTEST ) hall.push?.( halls['poker'] );
	if( hall.length===1 )
		hall = hall[0];
	let mod = await import( './tools.js' );
	if( !hall ) return;
	let title = action==='newtour'? '{New} {tournament}' : '{Newgame}';
	let field = params.team? 'createTeamGameSelect' : 'createGameSelect';
	if( action==='newtour' ) field = 'createTourSelect';
	if( hall.length>1 ) {
		// Сначала выбор холла
		// Отфильтруем холлы, в которых нет выбираемых игр
		hall = hall.filter( x => !!x );
		if( action==='newgame' )
			hall = hall.filter( x => x?.[field].length>0  );
		let hid = await mod.edit( {
			action: {
				type: 'bigselect',
				values: popular.concat( hall.map( x => [ x.id ] ) )
			}
		}, {
			id: 'regerselecthall_' + params.team?.id,
			picture: params.team?.picture,
			title: title,
			storeid: 'friendgame-' + (params.team?.itemid || '')
		} );
		if( !hid ) return;
		hid = hid.submit;
		if( hid==='cancel' ) return;
		if( hid==='lastgame' ) {
			log( 'reger: last game selected' );
			if( !UIN && params.team?.isDemoclub ) {
				// Попросим временный гостевой UIN для проб
				await modules.Auth.checkAuthGuest( {
					force: true
				});
			} else {
				await waitAuth();
			}
			elephCore.sendPlay( lastgame.serveraction );
			return;
		}
		hall = halls[hid];
	}
	// log( `Log hall ${hall.id} against check: ${JSON.stringify( hall.createGameSelect )}` );
	let values = [...hall[field]]; // action==='newtour'? [ ...hall.createTourSelect ] : [ ...hall.createGameSelect ];
	// if( params.team && action!=='newtour' ) {
		// values = values.filter( x => !+x.action );
		// values = values.concat( hall.createTeamGameSelect );
	// }
	if( !values ) return;
	if( params.team )
		values = values.filter( x => !x.onlyclubs || x.onlyclubs.includes( params.team.numericid ) );
	else
		values = values.filter( x => !x.onlyclubs );

/*
	if( !mayReview() && ( !team || team.isHelper ) )
		values.push(  {
			disabled: !(team?.isCaptain ),
			title: '{Createtourney}',
			action: 'createtournament',
			picture: '/svg/icons/tour_black_24dp.svg'
		} );
*/
	let res = hall.id;
	if( values.length ) {
		let editres = await mod.edit( {
			action: {
				type: 'bigselect',
				values: values
			}
		}, {
			picture: params.team?.picture,
			title: title
		} );
		if( !editres ) return;
		res = editres.submit;
	}
	if( res ) {
/*
		if( res==='createtournament' ) {
			let tmod = await import( './touredit.js' );
			tmod.create( {
				hall: hall.id,
				tid: params.team?.numericid,
				team_id: team?.numericid
			});
			return;
		}
*/
		let game = res.match( /(.*\.)?(.*)\#.*/ )?.[2] || res;

		if( action==='select' ) return game;

		if( action==='newtour' ) {
			(await import( './touredit.js' )).create( {
				game: game,
				team_id: params.team?.numericid,
				noauth: params.team?.isDemoclub
			} );
			return;
		}
		let reger = allregers.get( res )
			|| gameRegers.get( res.split( '#' )[0] );
		reger?.click( params );
	}
}

function hallclick( e ) {
	let hall = e.target && e.target.hall || e;
	if( selectHall( hall ) ) {
		storedHall = hall.icon;
		localStorage.playhall = storedHall;
	}
}

function selectHall( hall ) {
	if( !hall || selectedHall===hall ) return false;
	localStorage.lastregerhall = lastHall = hall.id;
	if( selectedHall ) selectedHall.button.classList.remove( 'selected' );
	selectedHall = hall;
	selectedHall.button.classList.add( 'selected' );
	checkShow();
	checkButtons();
	return true;
}

async function createTeaching() {
	// Создать учебный стол. Если доступен гроссмейстер, то и его
	// teachButton.hide();

	/*
		Этот закомментированный код позволяет выбрать игру с гроссмейстером.. для гроссмейстеров
		let json = await Core.sendPlay( 'type=newteaching hall=' + selectedHall.id );
		if( json.buttons ) {
			// Список доступных игр массивом
			let btn = await selectByWindow( json );
			Core.sendPlay( `type=reg reger=${btn} on=1` );
		}
	*/
	let game = await (await import( './tools.js' )).selectgame( {
		games: 'draughts chess'
	} );
	if( !game ) return;
	let str = `type=lecture data="game=${game}"`;
	elephCore.sendPlay( str );
}

/*
async function createTour() {
	selectPlayMain.hide();
	(await import( './touredit.js' )).create( {
		games: selectedHall.id
	} );
}
*/

function selectType( e ) {
	let el = e.target;
	if( !el ) return;
	let type = el.dataset['playtype'];
	if( !['friends', 'rated'].includes( type ) ) return;
	playType = type;
	localStorage.playtype = playType;
	selectPlay( 'select' );
	subscribeShowed();
	checkButtons();
}

function checkButtons() {
	let hset = new Set;
	for( let reger of setregers ) {
		let sh = reger.active && (reger.id && playType==='rated' || !reger.id && playType==='friends');
		if( sh ) hset.add( reger.hallid );
	}

	let ar = windowSelect.querySelectorAll( '[data-playtype]' );
	for( let o of ar ) {
		o.classList.toggle( 'checked', o.dataset['playtype']===playType );
		o.classList.toggle( 'selected', o.dataset['playtype']===playType );
	}
}

function checkShow( vis ) {
	if( selectedHall )
		regersNoPlay.makeVisible( selectedHall.countPlay<selectedHall.count );
	selectreger.makeVisible( playType==='rated' );
	selectgame.makeVisible( playType==='friends' );
	teachButton.makeVisible( LOCALTEST && ['draughts'].includes( selectedHall?.id ) );
	friendsButton.disabled = !halls[lastHall]?.createGameSelect?.length;
	if( vis===undefined ) vis = selectPlayMain.classList.contains( 'visible' );

	for( let reger of setregers ) {
		let sh = vis && reger.active && reger.hall===selectedHall;
		reger.checkShow( sh );
	}
}

function set( id, param ) {
	let reger = allregers.get( id );
	if( !reger ) allregers.set( id, reger = create( id, param ) );
	if( reger && param )
		reger.update( param );
	return reger;
}

regersChanged();

function create( id, params ) {
	if( typeof id==='object' ) {
		params = id;
		id = 0;
	}
	return new Reger( id, params );
}

function playFriends( e ) {
	// Выберем игру для игры с друзьями
	// if( !LOCALTEST && !DEBUG ) return selectType( e );
	e.stopPropagation();
	selectPlayMain.hide();
	selectGame( selectedHall );
}

let bridgeMatchReger,
	bridgeMatchCustom = {
		localid: 'bridgematchcustom',
		_game: 'bridge',
		_collect: 'match',
		title: { default: '', size: 10, type: 'input' },
		scoring: ['IMP', 'Patton', 'Board-a-match'],
		boardcount: [ 8, 16, 20, 24, 32, 48, 64 ],
		resit: true,
		spectators: true,
		results: false
	};

export function playBridgeMatch() {
	bridgeMatchReger ||= create( {
		game: 'bridge.bridge',
		custom: bridgeMatchCustom,
		icon: 'swords',
		hallid: 'bridge',
		_title: '{Bridge}, {match}'
	} );

	bridgeMatchReger.click();
}

[ 'pref', 'bg', 'board', 'belot', 'bridge', 'sandbox', 'domino', 'durak', 'poker', 'chipoker', 'chess', 'draughts', 'king', '1000', 'updown' ]
	.forEach( x => halls[x] ||= new Hall( x ) );

