let dragMaster;

function onDropBoard( element, data, dragInfo ) {
	let fig = element.fig,
		c = data.cellNumber;
	if( !fig || c===undefined ) return;
	if( dragInfo.game.board.figs[c]===fig ) return;			// Фигура уже здесь
	let from = fig.cellNumber;
	dragInfo.game.board.movefig( from, c, null, false );

	dragInfo.game.send( 'modpos', from + '-' + c );
}

function movenextfig( dragInfo ) {
	let fl = dragInfo.figList;
	dragInfo.nextFig = fl[(fl.indexOf( dragInfo.nextFig )+1)%fl.length];
}

function onClickBoard( e, dragInfo ) {
	let t = e.target,
		fig = t.classList.contains( 'fig' ) && t,
		cell = t.classList.contains( 'cell' ) && t;

	if( fig ) {
		let cn = fig.fig.cellNumber;
		if( cn<0 ) {
			log( 'Permutation. Fantom fig on click' );
			if( LOCALTEST ) debugger;
			fig.fig.hide();
			e.stopPropagation();
			return;
		}
		if( cn===dragInfo.lastCell ) {
			// Перед этим кликали на эту же клетку. Не удаляем, а изменяем фигуру
			// fig.fig.invertColor();
			if( dragInfo.nextFig===dragInfo.firstFig ) {
				dragInfo.game.board.movefig( cn, -1 );
				dragInfo.game.send( 'modpos', cn + '-' );
				dragInfo.lastFig = dragInfo.nextFig;
				log( 'Permutation: cycle remove fig at ' + dragInfo.lastCell );
			} else {
				log( `Permutation: nextfig ${dragInfo.nextFig} at ${dragInfo.lastCell}` );
				fig.fig.setSrc( dragInfo.nextFig );
				dragInfo.game.send( 'modpos', cn + '-' + fig.fig.str );
				dragInfo.lastFig = dragInfo.nextFig;
				movenextfig( dragInfo );
			}
		} else {
			dragInfo.lastCell = cn;
			dragInfo.lastFig = dragInfo.nextFig = dragInfo.firstFig = fig.fig.symbol;
			dragInfo.game.board.movefig( cn, -1 );
			dragInfo.game.send( 'modpos', cn + '-' );
			log( 'Permutation: remove fig at ' + dragInfo.lastCell );
		}
		e.stopPropagation();
	} else if( cell ) {
		dragInfo.lastFig ||= dragInfo.figList[0];
		if( cell.cellNumber!==dragInfo.lastCell )
			dragInfo.firstFig = dragInfo.lastFig;
		let f = dragInfo.game.board.setfigat( cell.cellNumber, dragInfo.lastFig );
		if( !f ) {
			let str = `permutation: no fig ${dragInfo.lastFig} at ${cell.cellNumber}`;
			log( str );
			bugReport( str );
		} else {
			dragInfo.lastCell = cell.cellNumber;
			// Add this figure to dragmove
			dragMaster.makeDraggable( dragInfo, f.holder );
			log( 'Permutation: add fig at ' + cell.cellNumber );
			dragInfo.game.send( 'modpos', cell.cellNumber + '-' + f.str );
			dragInfo.nextFig = dragInfo.lastFig;
			movenextfig( dragInfo );
		}
		e.stopPropagation();
	}
}

export async function figures( board, filter, figselect ) {
	dragMaster = (await import( './dragmaster_old.js' )).default;
	board.dragSetpos ||= {
		game: board.game,
		nomarks: true,
		figList: figselect
	};
	let dragInfo = board.dragSetpos;

	let holders = [];
	if( filter==='2n' ) {
		let w = Math.sqrt( board.cells.length );
		for( let l=0; l<w; l++ )
			for( let x=l%2; x<w; x+=2 )
				holders.push( board.cells[l*w+x] );
	}  else
		for( let cell of board.cells ) holders.push( cell );

	dragInfo.allDropHolders = holders;
	dragInfo.onDrop = onDropBoard;
	dragInfo.onClick = onClickBoard;

	for( let k in board.figs ) {
		let f = board.figs[k];
		if( !f ) continue;
		dragMaster.makeDraggable( dragInfo, f.holder );
	}
	dragMaster.startMove( dragInfo, { permutation: true } );
}