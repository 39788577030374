const muteParams = {
	id: 'muteparams',
	title: '{Chat} {settings}',
	items: [
		// ['1h', '🔇 1{short_hours}'],
		// ['4h', '🔇 4{short_hours}'],
		['mute', '🔇 {Mutebells}'],
		['unmute', '🔈 {Notifications}' ],
		['clear', '🧹 {Clearhistory}'],
		['delete', '🗑️ {Delete}'],
		['block!', '🚫 {Block}']
	]
};

// cssInject( 'chat' );

let dbRequest,
	dbChat,
	heads = new Map,
	all = new Map,
	floatOrder = -2,
	lastUpdateTime = 0;

if( !window.SOLO ) dbRequest = indexedDB.open( "chat", 3 );

if( dbRequest ) {
	dbRequest.onsuccess = () => {
		dbChat = dbRequest.result;
		loadMychats();
	};

	dbRequest.onupgradeneeded = event => {
		let d = event.target.result;

		d.onerror = () => {
			window.log && log( "Error creating chat db" );
		};
		window.log && log( "Creating store chat.messages" );
		if( event.oldVersion>0 ) {
			try {
				d.deleteObjectStore( "messages" );
				d.deleteObjectStore( "heads" );
			} catch( e ) {}
		}
		let messages = d.createObjectStore( "messages", { keyPath: [ 'chatid', 'no' ] } );
		messages.createIndex( "chatid", 'chatid' );
		d.createObjectStore( 'heads' ).createIndex( 'me', 'me' );
	};
}

let lastRefresh = {};
async function apiRefresh() {
	// Загрузим чаты через API. Для каждого  UIN не чаще раз в минуту
	if( !window.UIN ) return;
	if( lastRefresh.uin===UIN ) return;
	lastRefresh = {
		uin: UIN,
		time: Date.now()
	};

	let res = await API( '/chat_getall', {
		fromtime: lastUpdateTime
	}, 'internal' );
	if( !res ) return;
	if( res.error ) {
		// if( LOCALTEST ) debugger;
		log( 'Failed getall chat request: ' + res.error );
		return;
	}
	// sessionStorage
	// {"ok":true,"chats":[{"chatid":"112-769902","messages":"1","unread":"1","lastmessage":"tets"}}
	Chat.unreadchats = 0;
	for( let h of res.chats ) {
		let chat = Chat.updateHead( h );
		if( !chat ) continue;
		if( h.unread ) Chat.unreadchats++;
		chat.storeParam();
		if( chat.isVisible() || chat.isSystem )
			chat.apiLoadMessages();
	}
	// Установим счетчики allunread
	for( let el of $$( '.allunreadcount' ) )
		el.dataset.badge = Chat.unreadchats || '';
}

function loadMychats() {
	if( !window.UIN || !dbChat || (+UIN)<0 ) return;
	log( 'Loading all my chats' );
	let transHead;
	try {
		transHead = dbChat.transaction( 'heads', 'readonly' ).objectStore( 'heads' ).index( 'me' );
	} catch( e ) {}
	if( transHead?.getAll ) transHead.getAll( UIN ).onsuccess = e => {
		// Отсортируем по count
		for( let o of e.target.result ) {
			if( o.me!==window.UIN ) {
				// Дублирующая проверка, не должно быть чатов от другого игрока
				if( LOCALTEST ) debugger;
				continue;
			}
			if( !o.messages ) continue;	// Возможно, ошибочный чат
			let chat = Chat.updateHead( o );
			// Если база появилась позже самого чата, загрузим сообщения
			if( chat && !chat.dbload ) {
				log( `chat ${chat.id} will try load local` );
				chat.loadMessages();
			}

		}
		log( 'Chat local loaded chats: ' + e.target.result.length );
		apiRefresh();
	};
	// LOCALTEST && Chat.set( 'team_16198', 'LOCALTEST' );
}

dispatch( 'loggedout', () => {
	// Разлогинились
	for( let [id,chat] of all ) {
		chat.remove();
	}
	all.clear();
	heads.clear();
	lastUpdateTime = 0;
	for( let el of $$( '.allunreadcount' ) ) {
		el.dataset.ids = '';
		el.dataset.badge = '';
		el.hide();
	}
	// Почистим таблицы при разлогинивании
	try {
		if( dbChat ) {
			dbChat.transaction( 'heads', 'readwrite' ).objectStore( 'heads' ).clear();
			dbChat.transaction( 'messages', 'readwrite' ).objectStore( 'messages' ).clear();
			log( 'Local messages db cleaned completely' );
		}
	} catch( e ) {
		log( 'ERROR msgcleaner: ' + JSON.stringify( e ) );
	}
});

dispatch( 'loggedin', () => {
	// При авторизации пытаемся загрузить свои чаты
	loadMychats();
} );


// Обновим все чаты, если это было достаточно давно

export class Chat {
	constructor( params, reason ) {
		if( typeof params==='string' ) {
			this.id = params;
			params = {};
		} else {
			this.id = params.id || params.chatid;
			this.game = params.game;
		}
		this.params = params;
		log( `Chat creating ${(reason || '')}: ${this.id}` );
		all.set( this.id, this );
		this.head = heads.get( this.id );
		if( !this.head ) {
			this.head = { me: UIN, chatid: this.id, unread: 0, messages: 0 };
			heads.set( this.id, this.head );
		}
		let fadeclass = this.game ? 'fade' : 'display_none';
		this.holder = html( `<div class='${fadeclass} hidewhenminifyed phone_fullwide chat_area ${params.title && '.named' || ''} ${params.classname || ''}'
			style='overflow-y: auto'></div>` );
		this.holder.object = this;
		this.holder.dataset.chatid = this.id;
		this.lastAddedNo = 0;

		if( this.game )
			this.icon = this.badgeButton = construct( '.grayhover.invertdark.game_navigation.chatbutton.badge.chat_icon @{Chat}' );

		this.allMessages = [];
		this.msgnumber = -1;
		this.seeds = new Map;
		this.serverSeeds = new Map;
		this.ids = new Set;
		let title = this.title = params.title || 'Chat',
			showtitle = '',
			canclose = !this.game; // (params.classname?.includes('room_chat'));
		if( ( !params.notitle && !params.title || ( +this.id || this.id.startsWith( 'team_' ) ) ) ) {
			// Заголовок - информация об игроке
			title = fillPlayerHTML( modules.user?.set( this.id ) || this.id, {
				size: params.size
			} );
			showtitle = 'visible';
		}
		if( canclose ) showtitle = 'visible';

		this.holder.innerHTML = localize( `
					<div class='little_head flexline spacebetween chat_head display_none ${showtitle}'>
					<span class='title flexline display_none visible'>${title}</span>
					<span class='title_chat display_none'>{Chat}</span>
					<div class='icons flexline'>
					<span class='control padding grayhover display_none' data-action='menu'></span>
					<span class='control grayhover closebutton icon display_none ${canclose&&'visible'||'' }' style='width: 2rem; height: 2rem' data-action='close'></span>
					</div>
					</div>
					<div class='chat_text'></div>
					<div class='display_none chat_selectors column' style='position: absolute; right: 0; bottom: 2em'></div>
					<div class='flexline centered inputfield display_none visible'>
					 <span class='recipient display_none'></span>
					 <input name='message' class='chat_input backwhitelight' inputmode='latin_prose' placeholder='...'/>
					</div>` );

		this.inputField = this.holder.$( '.inputfield' );
		this.recipient = this.inputField.$( '.recipient' );
		this.recipient.onclick = this.recipientClick.bind( this );

		this.holder.onShow = this.onShow.bind( this );
		// this.holder.onHide = this.onHide.bind( this );

		this.input = this.holder.$( 'INPUT' );
		this.text = this.holder.$( '.chat_text' );
		this.holder.$( '.chat_head' ).onclick = this.headClick.bind( this );
		// this.holder.$( '.closebutton' ).onclick = () => this.holder.hide();
		this.input.onkeydown = this.keydown.bind( this );
		this.input.mytouch = true;			// Self-controlled mouse events
		let blurTimeout;
		this.input.onfocus = async () => {
			window.chatInputFocused = this.input;
			log( 'chatInputFocus' );
			blurTimeout && clearTimeout( blurTimeout );
			blurTimeout = null;
			if( this.game?.chatBottom )
				document.body.classList.add( 'inputfocused' );
			if( !this.game?.isPlayer && !this.params.noauth ) {
				await checkAuth();
				this.input.focus();
			}
			window.showBugButton( false );
			this.checkSelectorsVisible();
		};
		this.input.onblur = () => {
			log( 'chatInputBlur' );
			window.chatInputFocused = null;
			window.showBugButton( true );
			setTimeout( () => this.checkSelectorsVisible(), 100 );
			blurTimeout = setTimeout(  () => document.body.classList.remove( 'inputfocused' ), 200 );
		}
		this.input.oninput = this.checkSelectorsVisible.bind( this );
		this.checkInputPattern();
		// dispatch( 'connected', () => this.delayScroll( 'connected', 1 ) );
		dispatch( 'loggedin', this.checkInputPattern.bind( this ) );
		dispatch( 'loggedout', this.checkInputPattern.bind( this ) );

		this.router = {
			message: o => {
				this.parse_message( o );
			}
		};

		// Подписка через core
		// if( !LOCALTEST )
		// 	window.modules.subscribe?.addParser( '_me', this.router );

		this.selectors = this.holder.$( '.chat_selectors' );
		this.selectors.onclick = this.selectorsClick.bind( this );

		this.checkInit( 'constructor' );

		if( this.game ) {
			this.checkSelectors();
			this.game.installLittle( 'chat', this, true );
			this.game.addRoute( {
				game: this.checkSelectors.bind( this ),
				placechanged: this.checkSelectors.bind( this )
			} );

			if( this.game.chatAlways ) this.checkAlwaysVisible();
		}

		if( this.isPrivateOrTeam ) {
			this.menuButton = this.holder.$( '[data-action="menu"]' );
			this.menuButton.show();
			this.checkMenuButton();
		}

		this.setParent( params.parent || params.game?.chatParent || invisibleBody() );

		this.checkUnread();

		if( params.readOnly ) this.setReadOnly( true );

		if( params.show ) this.show();
	}

	checkMenuButton() {
		if( !this.menuButton ) return;
		let speaker = this.isMuted? '🔇' : '⚙️',
			blocked = this.isBlocked;
		if( blocked ) speaker = '🚫';
		this.menuButton.setContent( speaker );

		if( blocked )
			// Если я заблокировал чат, то я же могу его разблокировать
			this.unblockButton ||= html( "<span class='control display_none grayhover' style='font-size: 1.5rem; position: absolute; bottom: 0; left: 0; right: 0'>{Unblock}</span>", this.holder, this.unmute.bind( this ) );
		this.unblockButton?.makeVisible( blocked );
		this.inputField.makeVisible( !blocked );
	}

	static getBychatid( chatid ) {
		return all.get( chatid?.toString() );
	}

	static set( params, reason ) {
		let chat = this.getBychatid( params.id || id ) || new Chat( params, reason );
		return chat;
	}

	static putChat( chatid, parent ) {
		// log( 'chat putchat ' + chatid );
		let chat = this.getBychatid( chatid ) || new Chat( { id: chatid, parent: parent }, 'putchat' );
		chat.setParent( parent );
		chat.show();
	}

	static checkContacts() {
		for( let [k,c] of all )
			c.checkInContacts();
	}

	setParent( parent ) {
		if( this.holder.parentElement===parent ) return;
		parent.appendChild( this.holder );
		if( parent.dataset.chathead==='no' )
			this.holder.$( '.chat_head' ).hide();
	}

	checkInputPattern() {
		let plays = this.game?.isPlayer && this.game.isPlayer;
		this.input.placeholder = (UIN || plays) ? '...' : localize( '{Youarenotloggedin}' );
	}


//  === Private methods
	async keydown( e ) {
		/*
						if ( e.key === 'Escape' ) {
							// ESC
							if ( !input.value.length ) input.blur();
							return
						}
		*/
		if( e.key===' ' && this.autoShowed==='Space' && !this.input.value.length ) {
			// Double-space: show-hide chat
			delay( () => this.game.littleHideIfShowed( 'chat' ) );
			e.preventDefault();
			return;
		}

		if( e.key==='Backspace' && this.recipientName && !this.input.value.length ) {
			// Remove recipient by Backspace
			this.setRecipient();
			e.preventDefault();
			return;
		}

		if( e.key==='ArrowUp' && e.metaKey ) {
			if( !this.input.value ) {
				this.input.value = this.lastEnteredMessage || '';
				setTimeout( () => this.input.selectionStart = this.input.selectionEnd = this.input.value.length, 0 );
			}
			return;
		}

		if( e.key!=='Enter' ) return;
		e.stopPropagation();
		let text = this.input.value;
		if( !text.length ) return;
		if( this.input.value==='cls' ) {
			log( 'chat cls ' + this.id );
			this.text.innerHTML = '';
			this.input.value = '';
			return;
		}
		this.lastEnteredMessage = this.input.value;
		if( LOCALTEST && text.startsWith( '$$' ) ) {
			modules.subscribe.parse( text.slice( 2 ) );
			this.input.value = '';
			return;
		}
		// Enter pressed
		if( !window.UIN ) {
			log( 'CHAT: no sending because no Core.auth.server' );
			return elephCore?.login();
		}
		let recip = this.recipientName || this.defaultRecipient || this.id;
		if( recip ) {
			let text = this.input.value, seed = fastUUID(),
				str = `type=chat recipient="${recip}" seed='${seed}' data="${encodeURIComponent( text )}"`,
				j = {
					chatid: this.id,
					recipient: recip,
					senderseed: seed,
					text: text
				};
			if( this.game ) str += ` item=${this.game.item}`;
			log( 'chat: ' + text );		// Декодированный текст
			// Send using server: old server or not private
			let core = elephCore && ( !this.isPrivate || window.GAMBLERRU ),
				msgElement;
			if( core )
				elephCore.do( str );
			// Временно отобразим это сообщение у себя
			if( this.id ) {
				// let uin = elephCore?.auth.uid || UIN;
				// type = [ 'rho', 'lho', 'opp'
				msgElement = await this.parse_message(
					{
						// id: '',
						chatid: this.id,
						type: recip || undefined,
						pos: this.game?.myplace,
						seed: seed,
						sender: UIN,
						text: text
					} );
			}
			if( !core )
				API( '/chat_sendmessage', j, 'internal' ).then( res => {
					if( res?.ok && msgElement ) msgElement.dataset.no = res.no;
				})

		}
			// else if( this.channel )
		// 	Core.toserver( 'chat channel="' + this.channel + '" data="' + encodeURIComponent( this.input.value ) + '"' );
		else if( this.game ) {
			this.game.send( 'chat', this.input.value );
		}
		// else
		// 	Core.toserver( 'chat', encodeURIComponent( this.input.value ) );
		this.input.value = '';
		// Если чат за столом, убираем
		if( this.game )
			this.input.blur();
		if( this.autoShowed && this.autoShowed!=='Space' ) {
			setTimeout( () => this.game.littleHideIfShowed( 'chat' ), 500 );
		}
		return false;
	}

	checkSelectors() {
		if( this.game.isbridge && this.game.isPlayer ) {
			let str = '';
			if( !this.game.gameInfo.nochatopps )
				str += `<button data-recipient='opps'>↔</button>
					<button data-recipient='lho'>👤←</button>
					<button data-recipient='rho'>→👤</button>`;
			this.selectors.innerHTML = `${str}<button data-recipient='td' style='color: red'>TD</button>`;
		}  else
			this.selectors.innerHTML = '';
	}

	setRecipient( r, name ) {
		if( this.isPrivateOrTeam ) return;		// В приватных и командных чатах нет получателей
		this.recipientName = r;
		name = { rho: 'rho→', lho: '←lho' }[name] || name;
		this.recipientText = name;
		if( r ) {
			this.recipient.textContent = name;
			this.recipient.show();
		} else {
			// this.recipient.textContent = '';
			this.recipient.hide();
		}
		this.checkSelectorsVisible();
	}

	checkSelectorsVisible() {
		this.selectors.makeVisible( !this.recipientName &&
			this.input===document.activeElement &&
			this.input.value.length===0 );
	}

	recipientClick( e ) {
		e.stopPropagation();
		this.setRecipient();
	}

	selectorsClick( e ) {
		e.stopPropagation();
		let t = e.target, recipient = t.dataset.recipient;
		if( this.recipientName!==recipient ) {
			this.setRecipient( recipient, t.textContent );
		} else
			this.setRecipient();
		this.focus( 'selector click' );
	}

	storeParam() {
		if( !this.id || !UIN ) return;
		if( this.id==='room' || this.game ) {
			sessionStorage['chatparam_' + this.id] = JSON.stringify( { messages: this.head.messages, lastreadno: this.head.lastreadno } );
		} else {
			log( `Chat storing [ ${UIN}, ${this.id} ]=${JSON.stringify(this.head)}` );
			let request = dbChat?.transaction( 'heads', 'readwrite' )
				.objectStore( 'heads' )
				.put( this.head, [ UIN, this.id ] );
		}
	}

	async tryLoad() {
		this.clear();
		if( !this.id ) return;
		if( this.id==='room' || this.game ) {
			let params = sessionStorage['chatparam_' + this.id];
			log( 'Loadchat ' + this.id + '. params=' + params );
			if( params ) {
				let o = JSON.parse( params );
				this.head.messages = o.messages;
				this.head.lastreadno = o.lastreadno;
				log( 'Chat storage. read/msgs: ' + this.head.lastreadno + '/' + this.head.messages + '. mz=' + (this.game && this.game.maximized) );
				this.checkUnread();
			}
			let saved = sessionStorage['chat_' + this.id];
			this.allMessages = saved && JSON.parse( saved ) || [];
			// if( LOCALTEST ) this.allMessages = [{"id":1611658180001,"type":null,"chatid":"room_818312","from":"1435097:школа2","f":"1435097::школа2","author":{"uin":"1435097","name":"школа2"},"text":"бан владиславу228","mine":false}];
			let tostrip = [];
			for( let m of this.allMessages ) {
				if( this.parse_message( m, true )==='skipped' ) {
					tostrip.push( m );
				}
			}
			if( tostrip.length ) {
				log( 'Stripped chat lines: ' + tostrip.length );
				for( let s of tostrip ) {
					let idx = this.allMessages.indexOf( s );
					if( idx>=0 )
						this.allMessages.splice( idx, 1 );
				}
				// Сохраняем не более 20 сообщений
				sessionStorage.setItem( 'chat_' + this.id, JSON.stringify( this.allMessages.slice( -20 ) ) );
			}
		} else {
			// Сначала попытаемся загрузить первые сообщения из базы
			await this.loadMessages();
		}

		this.delayScroll( 'onload' );
	}

	delayScroll( reason, tm ) {
		// ВНИМАНИЕ! если убрать проверку isVisible, то при загрузке страницы с игрой,
		// при вызове обработчика onTimeout, если в чате много сообщений и необходим
		// скроллинг, может проскроллироваться вся страница вверх, оставив пустой блок внизу
		// Это критическая ошибка, играть после этого невозможно. Видимо, ошибка браузера
		// isVisible - подавление
		if( !this.text.isVisible() ) return;
		setTimeout( () => {
			log( 'DELAYED Scroll Chat to view: ' + reason );
			this.text.lastChild?.scrollIntoView( false );
		}, tm || 500 );
	}

//	=== Public methods
	/*
				self.setChannel = function( ch ) {
					if ( channel === ch ) return;
					channel = ch
				};
	*/

	setReadOnly( val ) {
		if( this.readOnly===val ) return;
		this.readOnly = val;
		this.holder.classList.toggle( 'readonly', val );
		this.inputField.makeVisible( !val )
	}

	parse_message( o, loading ) {
		log( `parse_message ${loading?'LOADING':''}: ${JSON.stringify( o )}` )
		// await import( './user.js' );
		// window.textChat = (window.tetChats||'') + JSON.stringify( o ) + '\n';
		if( !o.text && !o.data && !loading ) {
			// Уведомление о системном сообщении. Необходимо загрузить очередь системных сообщений
			this.apiLoadMessages( 'System new message', {
				force: true,
				fromno: o.no
			} );
			return;
		}
		if( !o.loading && !o.time ) o.time = Math.round( Date.now()/1000 );
		if( o.chatid?.includes( '-' ) ) {
			o.chatid = (o.chatid.split( '-' ).reduce( ( sum, x ) => sum + (+x), 0 ) - (+UIN)).toString();
		}
		if( o.no ) {
			if( o.no < this.lastAddedNo ) {
				if( this.missedno?.has( o.no ) ) {
					log( `Chat ${this.id} restoring missed no ${o.no}` );
					this.missedno.delete( o.no );
				}
				// Переотправлено старое сообщение. Пока нет ни редактирования, ни удаления, ничего не делаем
				// log( 'chat Skipping 1' );
				return;
			}
			let lastno = this.head.lastreceivedno || 0;
			if( o.no>this.head.lastreceivedno ) {
				log( `Chat ${this.id} missed no ${lastno}..${o.no-1}` );
				for( let no=lastno+1; no<o.no; no++ ) {
					// Пропущенные сообщения
					this.missedno ||= new Set;
					this.missedno.add( no );
					this.firstMissedNo ||= no;
				}
				this.head.lastreceivedno = o.no;
			}
			if( this.head.messages<o.no ) this.head.messages = o.no;
			this.lastAddedNo = o.no;
		}
		if( o.serverseed ) {
			// Пришла установка номера на сообщение, которое может уже быть в чате. Если оно есть,
			// не показываем его снова, но сохраним с номером
			let msg = this.serverSeeds.get( o.serverseed );
			if( msg ) {
				if( o.no ) {
					msg.no = o.no;
					this.storeMessage( msg );
				}
				// log( 'chat Skipping 2' );
				return;
			} else {
				if( o.text ) this.serverSeeds.set( o.serverseed, o );
			}
			// Если это обновление номера, в любом случае не идем дальше
			if( !o.text ) {
				if( LOCALTEST ) debugger;
				// log( 'chat Skipping notext' );
				return;
			}
		}
		if( o.seed ) {
			let msg = this.seeds.get( o.seed );
			if( msg?.author?.id===UIN ) {
				if( o.serverseed )
					this.serverSeeds.set( o.serverseed, msg )

				// Это сообщение уже есть в нашем чате. Это моё сообщение, которое я добавил,
				// чтобы ускорить визуальное ощущение от чата
				// значит было добавлено для быстрого отображения нашего сообщения
				// Сохраним его с номером
				// if( !msg.no && o.no ) {}
/*
				if( o.id ) {
					this.storeMessage( o );
					this.ids.add( o.id );
				}
*/
				// log( 'chat skipping 3' );
				return;
			}
		}

		// Сохраним оригинал
		let origin = Object.assign( {}, o ),
			returnElement;
		// Проверим, не совпадает ли по тексту с предыдущим, и проигнорируем, если не приват
		const pos = o.pos;
		let msg = o.text,
			simply = false, noChat = false,
			place = -1,
			msgauthor;

		if( o.f ) {
			let ar = o.f.split( ':', 3 );
			o.author = msgauthor = { id: ar[0], avatarid: ar[1], nick: ar[2] };
			o.sender = ar[0];
		} else if( o.sender ) {
			o.author = msgauthor = { id: o.sender };
		}

		msgauthor ||= { id: 0, avatarid: 'S' };
		let fromrobot = msgauthor.nick==='R';
		let sender = o.sender = msgauthor.id,
			mine = sender && UIN===sender,
			user = window.User?.set( sender ),
			senderName = user?.getShowName || o.sendername || (msgauthor.nick || msgauthor.name) || '';

		if( o.data ) {
			if( o.data.type==='notifychat' ) {
				if( o.messagetime < Date.now()/1000 - 60*60*24*3 ) return;		// Устарело
				log( 'Notify chat ' + JSON.stringify( o ) );
				if( o.chatid!=='1' ) return;
				let loadchat = Chat.getBychatid( o.data.chat_id );
				if( loadchat ) {
					loadchat?.apiLoadMessages( 'notifychat', { messageno: o.data.messageno } );
				}
				return;
			}
			if( o.data.type==='transferrequest' ) {
				o.data.initiator = msgauthor.id;
				o.parse_mode = 'html';
				o.text = msg = this.fillTransferRequestText( o );
			}
		}
		// Если у нас определенный chatid, то показываем только его.
		// Пока что это касается только числовых
		if( +this.id && o.chatid ) {
			if( o.chatid!==this.id ) {
				// log( 'chat skipping 4' );
				return;
			} // && !o.chatid.split( '-' ).includes( this.id ) ) return;
		}

		if( o.no && this.lastMessage?.no && o.no<=this.lastMessage.no ) {
			log( 'Skip old message' );
			// Skip old or duplicated messages
			return;
		}

		if( !msg ) {
			log( 'Empty chat message: ' + JSON.stringify( o ) );
			return;
		}

		// Стандартные замены (совместимость с j77)
		// <request <body>TITLE</>
		if( +msgauthor.id<113 ) {
			msg = msg
				.replace( /<request (.*)>(.*)<\/>/i, '<a href="$1">$2</a>' )
				.replace( '$wwwhost', CLIENTHOST )
				.replace( 'https:///', CLIENTHOST + '/' )
				.replace( 'buypremium', CLIENTHOST + '/shop/premium' );
			if( window.ALTERDOMAINREGEXP ) msg = msg.replace( ALTERDOMAINREGEXP, DOMAIN );
		}
		let ar = msg.match( /^{([^{}]*)}$/ );

		// Стандартные фразы показываем на аватаре
		if( ar ) {
			let code = ar[1].toLowerCase();
			if( 'hello hi glp typ thankyou thanks hellogoodluck'.split( ' ' ).includes( code ) ) {
				simply = true;
				if( code==='glp' ) msg = '🍀 ' + msg;
				else if( code==='hello' ) msg = '👋 ' + msg;
				else if( code==='hellogoodluck' ) msg = '👋🍀 ' + msg;
				if( fromrobot ) noChat = true;
				// if( 'glp typ'.includes( code ) || fromrobot ) noChat = true;
			}
		}
		if( o.t ) {
			let ar = o.t.split( ':', 3 );
			o.receiver = ar[0];
			o.recipient = { id: ar[0], nick: ar[2] };
		}
		if( this.lastMessage && o.author?.id===this.lastMessage.author?.id
			&& o.text===this.lastMessage.text && /*o.type!=='priv' &&*/ o.pos===this.lastMessage.pos ) {
			// log( 'chat skipped repeat' );
			// Зафиксируем этому сообщению serverseed
			if( o.serverseed )
				this.serverSeeds.set( o.serverseed, this.lastMessage )
			return 'skipped';
		}
		if( elephCore?.isMute( o.author?.id ) ) {
			// log( 'chat skipped mute' );
			return 'muted';
		}

		// В официальных играх вместо ника надо показать имя
		if( this.game && sender ) {
			place = this.game.getPlace( sender );
			if( place>=0 ) senderName = this.game.players[place].showName;
			if( o.pos===undefined ) o.pos = place;
		}
		if( sender==='-1' ) {
			msg = localize( msg );
			msg = msg.replace( /<user>\d+:(.+?)<\/user>/g, '$1' )
				.replace( /<request.*?>.*?<\/.*?>/g, '' );
		}
		// if( !o.recipient : { uin: o.receiver };
		o.mine = mine;
		let myplace = this.game?.myplace;

		if( !this.isPrivateOrTeam ) {
			if( myplace>=0 ) {
				if( o.toplaces && !o.toplaces.includes( myplace ) ) return;
				if( "opps kibi rho lho priv room".includes( o.type ) ) {
					if( myplace!==pos ) {
						if( o.type==='opps' && pos % 2===myplace % 2 ) return;
						if( o.type==='lho' && myplace!==(pos + 1) % this.game.maxPlayers ) return;
						if( o.type==='rho' && myplace!==(pos - 1 + this.game.maxPlayers) % this.game.maxPlayers ) return;
						// if( !mine && ['rho', 'lho', 'opps'].includes( o.type ) ) o.type = 'opp';
					}
					// Если не мой приват (на случай сбоя)
					// if( o.type==='priv' && !mine && o.recipient?.id!==UIN ) return;
				}
			}
		}

		// Добавление сообщения в чат
		if( !noChat ) {
			// Добавим получателя в список, чтобы знать, что на него можно "пожаловаться"
			Chat.allSenders.add( msgauthor.id );
			// Возможно, сообщение следует добавлять не в конец
			let newholder, insertAfter;
			if( +o.id && +o.id<this.lastHolder?.firstid ) {
				// Сообщение должно попасть где-то посередине (выше последнего блока)
				insertAfter = this.lastHolder;
				for( ; insertAfter; insertAfter = insertAfter.previousSibling ) {
					if( +o.id>insertAfter.firstid ) break;
				}
				// Элемент необходимо вставить после holder
				newholder = true;
			} else {
				newholder = !this.lastMessages || this.lastId!==sender || o.pos!==this.lastMessage?.pos;
			}
			if( newholder ) {
				// Нужно ли добавить строчку с днем
				if( LOCALTEST && !o.time ) o.time = Date.now()/1000;
				if( o.time ) {
					let dn = ( new Date( o.time*1000 ) ).toLocaleDateString();
					if( dn!==this.lastDayTitle ) {
						this.lastDayTitle = dn;
						let dttl = dn;
						if( dn===TODAY ) dttl = "{Today}";
						else if( dn===YESTERDAY ) dttl = "{Yesterday}";
						let e = construct( `.listdaytitle ${dttl}`, this.text );
						if( dn!==dttl ) e.dataset.daystring = dn;
					}
				}

				this.lastHolder = construct( '.message_holder' /* + ( mine? ' mine' : '' ) */ );
				this.lastHolder.firstid = +o.id;
				// let shownick = !mine && (pos===undefined || pos<0);
				// if( game && game.maxPlayers===2 ) shownick = false;

				// if( !mine )
				// Add avatar to another player message
				log( `CHAT ${sender} (${senderName}): ${msg}` );
				const author = construct( '.message_author' ),
					avt = construct( 'img.message_avatar[width=32][height=32][data-magictype=avatar]' );
				// avt.style.backgroundImage = User.getAvatarBackgroundImage( senderId,
				// 	this.game && this.game.getDefaultAvatarName( pos ), senderName );
				avt.setMagicUser( user );
				author.appendChild( avt );
				this.lastHolder.appendChild( author );

				this.lastMessages = createappend( 'messages', this.lastHolder );

				let friend = sender && elephCore?.isFriend( sender ) && 'friend' || '',
					src = `<span class='fade message_nick visible ${pos>=0 ? 'player' : ''} ${mine ? 'me' : ''} ${friend}' data-name='user_${sender}.showname'></span>`;
				const s = html( src );
				let arr = this.game?.maxPlayers>=2 && this.game.arrowSymbol(pos) || '';
				if( o.type==='td' ) arr += '🔴 ';
				s.textContent = arr + (mine ? localize( '{You}' ) : senderName);
				this.lastMessages.appendChild( s );
				// lastHolder.appendChild( s )

				this.lastHolder.appendChild( this.lastMessages );
			}
			const block = construct( '.fade.message_body.visible', this.messageClick.bind( this ) );
			if( o.time ) block.dataset.after = timerHHMM( o.time*1000 );
			if( o.type===this.defaultType ) o.type = null;
			if( o.type ) o.type = o.type.toLowerCase();
			if( o.no ) block.dataset.no = o.no;
			returnElement = block;
			// if( LOCALTEST ) o.type = 'td';
			if( !this.isPrivateOrTeam ) {
				if( "opps kibi rho lho priv room".includes( o.type ) ) {
					if( this.game && this.game.myPlace>=0 && this.game.myPlace!==pos ) {
						// if( o.type==='lho' && this.game.myPlace!==(pos + 1) % this.game.maxPlayers ) return;
						// if( o.type==='rho' && this.game.myPlace!==(pos - 1 + this.game.maxPlayers) % this.game.maxPlayers ) return;
						if( !mine && ['rho', 'lho', 'opps'].includes( o.type ) ) o.type = 'opp';
					}
					let destName = o.type==='kibi' ? '{watchers}' : o.type;
					if( o.type==='priv' && o.mine )
						destName = o.recipient.nick;
					const dest = construct( '.message_dest ' + destName, block );
				}
			}
			if( o.type ) block.dataset.type = o.type;
			block.source = o;
			const t = construct( `.message_text[data-no=${o.no||''}]`, block );
			// if( o.type==='kibi' ) msg = lang.translate( '[{watchers}]: ' ) + msg;
			// if( o.type==='opps' ) msg = lang.translate( '[OPPS]: ' ) + msg;
			if( o.html || o.parse_mode==='html' )
				t.html( o.html || msg );
			else
				t.setChat( msg, msgauthor );
			this.lastMessages.appendChild( block );
			if( !this.lastHolder.parentElement ) {
				if( insertAfter )
					insertAfter.insertAdjacentElement( 'afterend', this.lastHolder );
				else
					this.text.appendChild( this.lastHolder );
			}
			this.lastId = sender;

			// add new message to stored messages
			if( !loading && this.id ) {
				requestAnimationFrame( () => {
					// log( 'Scrolling chat on adding' );
					this.text.lastChild?.scrollIntoView( {
						block: "end",
						inline: "nearest",
						behavior: 'smooth'
					} );
					this.delayScroll( 'onadd' );
				} );
				this.allMessages.push( o );
				sessionStorage['chat_' + this.id] = JSON.stringify( this.allMessages.slice( -20 ) );
				this.storeMessage( origin );

				// When chat window is invisible we have to show badge
				if( mine || ( !o.readed && !mine && !this.isVisible() ) || (this.game && !this.game.maximized) ) {
					if( !mine ) this.head.messages = Math.max( o.no, this.head.messages );
					this.checkUnread();
					this.storeParam();
				} else if( !mine && this.isVisible() ) {
					// Пришел чат, который мы сразу же видим. Остальным мгновенно сообщим
					// да и в сервер сразу
					this.readed();
				}
			}

			if( o.id ) this.ids.add( o.id );
			if( o.seed ) this.seeds.set( o.seed, o );

			this.lastMessage = o;
			Object.assign( this.head, {
				lastmessage: this.lastMessage?.text || '',
				lastmessagetime: this.lastMessage?.time || Math.round( Date.now()/1000 )
			} );
			// if( LOCALTEST ) simply = true;
		}
		// Независимо от того пойдет ли сообщение в историю чата, покажем его у игрока
		if( !loading && simply && place>=0 )
			this.game.playerChat( place, msg );

		return returnElement;
	}

	fillTransferRequestText( o ) {
		let club = User.setTeam( o.data.clubmoney ),
			receiver = User.set( o.data.receiver ),
			mine = o.sender===UIN,
			msg = `<span>{Transferrequest}. {Club} ${fillPlayerHTML( club, { nopicture: true } ) }. ${showBalance( o.data.amount, club.currency )} {for} 
					${fillPlayerHTML( receiver, { nopicture: true } )}`;
		if( !o.state ) {
			if( !mine ) {
				let rdata = { messagepath: `${o.chatid}/${o.no}`, ...o.data };
				msg += `. <button data-execute='team.transferRequestReceived' ${toDatasetString( rdata )}>{Open}</button>`;
				if( !this.isMessageReaded( o.no ) ) {
					import( './team.js' ).then( mod => mod.transferRequestReceived( rdata, 'delay' ) );
				}
				this.readed( o.no );
			} else {
				msg += `. <button data-execute='chat.changeMessageState' data-newstate='cancel' data-chat_id='${this.chatid}' data-messageno='${o.messageno}'>{Cancel}</button>`;
			}
		} else if( o.state==='accept' )
			msg += '. {Done}';
		else if( o.state==='reject' )
			msg += '. {Rejected}';
		else if( o.state==='cancel' )
			msg += '. {Cancelled}';
		return msg;
	}

	loadMessages( count ) {
		if( !this.id || !dbChat ) return;
		log( `Try local loadchat [${this.id}]`);
		try {
			this.dbload ||= dbChat.transaction( 'messages', 'readonly' ).objectStore( 'messages' );
		} catch( e ) {
			log( 'Local loadchat failed to get messages transaction for ' + this.id );
		}
		if( !this.dbload ) return;
		this.loadindex ||= this.dbload.index( 'chatid' );
		this.loadrequest ||= this.loadindex.openCursor( this.id, 'prev' );
		this.toinsert ||= [];
		this.toload = ( this.toload || 0 ) + ( count || 20 );
		if( !this.loadrequest ) return;
		this.loadingLocal = true;
		let latest_time = this.head.ttl && ( Date.now()/1000 - this.head.ttl );
		this.loadrequest.onsuccess = () => {
			let cursor = this.loadrequest.result;
			if( !cursor ) {
				this.loadrequest = null;
				return this.insertDelayed();
			}
			if( latest_time && cursor.value.time < latest_time ) {
				// Do not load earlier
				this.loadrequest = null;
				this.insertDelayed();
				return;
			}
			if( cursor.value.text ) {
				this.toinsert?.unshift( cursor.value );
				this.toload--;
				if( this.toload<=0 ) {
					// Загрузили столько, сколько хотели
					this.loadrequest = null;
					this.insertDelayed();
					return;
				}
			}
			cursor.continue();
		}
	}

	async insertDelayed() {
		log( 'Insert local ' + this.id + ': ' + this.toinsert.length );
		this.loadingLocal = false;
		if( !this.toinsert ) return;
		// Загрузка из локального хранилища. Берем только подряд идущие номера, причем не более чем на 3 отличающиеся от последнего
		let lastno;
		for( let o of this.toinsert ) {
			if( lastno && o.no && o.no>lastno+1 ) break;
			lastno = o.no;
			this.parse_message( o, true );
		}
		// log( `Loaded ${ this.toinsert.length} for chat ${this.id}` );
		this.toinsert = null;
		// После загрузки локальной догружаем из базы
		if( this.isVisible() || this.isSystem || this.loadApiAfterLoadLocal ) this.apiLoadMessages();
		this.loadApiAfterLoadLocal = false;
	}

	storeMessage( o ) {
		if( !dbChat ) return;
		// Пока не сохраняем сообщения из игровых столов (только в session)
		if( this.game || !this.id || this.id==='room' ) return;
		if( !o.no ) return;				// Не сохраняем сообщения без номера
		if( !o.text?.trim() && !o.data ) return;		// Не сохраняем пустышки
		o.chatid ||= this.id;

		try {
			return dbChat.transaction( [ 'messages' ], "readwrite" ).objectStore( 'messages' ).put( o );
		} catch( e ) {
			log( 'Failed chat store ' + JSON.stringify( e ) );
		}
	}

	getOppAlias( uin ) {
		let user = window.User?.get( uin );
		if( !user ) return;
		let g = this.game, pos = g.myplace, mp = g.maxPlayers;
		if( g.getUser( (pos + 1) % mp )===user ) return 'lho';
		if( g.getUser( (pos - 1 + mp) % mp )===user ) return 'rho';
		return;
	}

	messageClick( e ) {
		let t = e.target,
			source = t.source || t.parentElement.source;
		if( source && ['priv', 'opp', 'lho', 'rho'].includes( source.type ) ) {
			e.stopPropagation();
			let receiver = source.mine ? source.recipient : source.author,
				oppname = receiver ? null : source.type;
			// В парных играх приватов быть не должно, обращаемся как LHO/RHO
			if( receiver && this.game && this.game.isPlayer && this.game.isbridge ) {
				oppname = this.getOppAlias( receiver.id );
			}
			this.setRecipient( oppname || receiver.id, oppname || receiver.nick || receiver.name );
			this.focus( 'message click' );
		}
	}

	subscribeItem( item ) {
		if( this.sub && this.sub.sub.name===item ) return;
		if( this.sub ) this.sub.release();
		if( item )
			this.sub = window.modules.subscribe?.add( item, this.router );
		else
			this.sub = null;
	}

	getHelloStr() {
		if( !this.channel ) return '';
		let str = '\nCHAT_SUBSCRIBE channel="' + this.channel + '"';
		if( this.msgnumber>=0 ) str += " lastno=" + this.msgnumber;
		return str;
	};

	clear() {
		// We may keep different chat text-blocks
		log( 'Chat clear ' + this.id );
		this.text.innerHTML = ''
	};

	setId( newid ) {
		if( this.id===newid ) return;
		this.id = newid;
		this.checkInit( 'newid_' + newid );
	};

	checkInit( reason ) {
		log( 'Chat ' + this.id + ' checkInit ' + reason );
		if( this.initialized ) return;
		if( !this.id ) return;
		if( this.game && !this.game.maxPlayers ) return;
		this.initialized = true;
		this.tryLoad();
	}

	isMessageReaded( msgno ) {
		return msgno<=this.head.lastreadno;
	}

	readed( msgno ) {
		msgno ||= this.head.messages;
		// if( this.isMessageReaded( msgno ) ) return;
		// this.lastReadedApiNo = msgno;

		if( elephCore ) {
			let text = `chat { type: 'read', chatid: '${this.id}', messages: ${msgno}, unread: ${this.head.messages-msgno} }`;
			elephCore.do( `type=sendme data="${encodeURIComponent( text )}"` );
		}

		if( this.isPrivateOrTeam ) {
			API( '/chat_read', {
				chatid: this.id,
				lastreadno: msgno,
				messages: this.head.messages
			}, 'internal' );

			if( msgno===this.head.messages ) {
				Chat.updateAllUnread();
			}
		}
	}

	checkUnread( api ) {
		log( `checkUnread ${this.id}: ${JSON.stringify(this.head)}` );
		// if( !this.head.unread ) return;
		let v = this.isVisible() && (!this.game || this.game.maximized);
		if( v ) this.head.lastreadno = this.head.messages;
		this.checkInContacts();
		let unread = this.head.messages - this.head.lastreadno;
		if( this.lastBadge===unread ) return;
		for( let badge of $$( `.unreadcounter[data-forchatid='${this.id}']` ) ) {
			badge.dataset.badge = unread || '';
			if( unread && !this.lastBadge )
				badge.style.order = --floatOrder;
		}
		this.lastBadge = unread;
		this.badgeButton && ( this.badgeButton.dataset.badge = unread || '' );
		// Отправим на сервер сколько непрочитанных
		if( api ) {
			this.readed();
		}
	}

	focus( e ) {
		let reason = typeof e==='string'? e : '';
		log( 'Get chat focus ' + reason );
		this.input.focus();
		// if( !this.input.value.length && e instanceof Event && e.type==='keydown' )
		// 	this.input.value = e.key;
	}

	async toggle() {
		await cssInject( 'chat' );
		this.holder.toggleVisible();
	}

	#waitingCSS;
	async show() {
		this.visible = true;
		this.#waitingCSS = true;
		await cssInject( 'chat' );
		this.#waitingCSS = false;
		this.holder.show();
		this.focus( 'onshow' );
	}

	hide() {
		this.visible = false;
		this.holder.hide();
	}

	initiatedWithKey( code ) {
		if( /\S/.test( code ) && !this.input.value ) this.input.value = code;
	}

	async apiLoadMessages( reason, params ) {
		if( !dbChat ) return;				// Если не поддерживается локальная база, не работаем
		if( params?.messageno<=this.head.lastreadno ) return;		// Уже прочитано
		if( this.loadingLocal ) {
			log( 'chat loadApi: waiting for loadingLocal' );
			this.loadApiAfterLoadLocal = true;
			return;
		}		// Идет загрузка локальная
		if( params?.force ) {
			log( `Chat ${this.id} skip api load: ${this.head.messages}<=${this.lastAddedNo}?` );
			if( !this.isPrivateOrTeam || (!this.firstMissedNo && this.head.messages<=this.lastAddedNo) ) return;
		}
		if( this.waitingApi ) return;
		this.waitingApi = true;
		log( `Chat ${this.id} api loading` );
		let j = await API( '/chat_loadmessages', {
			chatid: this.id,
			fromno: params?.fromno ||
				Math.min( this.firstMissedNo, Math.max( this.lastAddedNo + 1, this.head.messages - 20 ) || 0 ),
			reason: reason,
			headmessages: this.head.messages
		}, 'internal' );
		this.waitingApi = false;
		if( j?.messages?.length ) {
			for( let m of j.messages ) {
				// Сразу сохраним сообщение в локальной базе
				this.storeMessage( m );
				this.parse_message( m, true );
			}
			this.delayScroll( 'apiloadmessages' );
		}
	}

	#afterShowCall = this.#afterShow.bind( this );

	onShow( auto ) {
		// this.#afterShowCall = this.#afterShow.bind( this );
		this.autoShowed = auto;
		if( this.#waitingCSS ) return;
		delay( this.#afterShowCall );
	}

	async #afterShow() {
		// log( 'Scrolling chat on show' );
		await cssInject( 'chat' );
		this.text.lastChild?.scrollIntoView( {
			block: "end",
			inline: "nearest",
			behavior: 'auto'
		} );
		this.delayScroll( 'onshow' );
		// Mark all messages as read
		this.checkUnread( true );
		this.storeParam();
		// refresh messages on show
		this.apiLoadMessages( 'onshow' );

		this.checkMenuButton();
		fire( 'originopened', 'chat_' + this.id );

/*
		if( FANTGAMES ) {
			setTimeout( () => {
				this.input.readonly = 1;
				this.input.focus();
				setTimeout( () => this.input.readyonly = false, 200 );
			}, 200 );
		}
*/
	};

	onMaximize() {
			// log( 'Scrolling chat on maximize' );
		this.text.lastChild?.scrollIntoView( false );
		this.checkUnread();
		// Shall we open chat on maximizing? Still no
		// if( !unread ) return;
		// if( !visible ) self.toggleVisible();
	};

	async checkAlwaysVisible() {
		if( !this.game ) return;
		let val = this.game.chatAlways;
		this.holder.classList.toggle( 'always', val );
		this.holder.classList.toggle( 'fade', !val );
		this.icon.classList.toggle( 'display_none', val );
		if( this.game.chatBottom ) return;		// Если чат отдельный, он управляется классом visible
		let vis = val || this.game.lastLittle===this || this.hasFocus();
		if( vis ) await cssInject( 'chat' );
		this.holder.makeVisible( vis );	// Почему не show()??
	}

	async headClick( e ) {
		if( e.target.dataset.action==='close' ) {
			// Если это в bigwindow, то убираем его
			this.close();
			return;
		}
		if( e.target.dataset.action==='menu' ) {
			let isfriend = elephCore?.isFriend( this.id ),
				isteam = this.id.startsWith( 'team_' ),
				mod = await import( './tools.js' ),
				action = await mod.askMenu(
				muteParams,
				{
					visible: `clear ${+this.id&&!this.isBlocked?'block!':''} ${this.isMuted?'unmute':'mute'} ${isfriend?'':'delete'}`
				});
			switch( action ) {
				case 'cancel': return;
				case 'delete':
					// Удаление чата не ведет даже к его очищению, он просто не должен быть виден
					// до следующего сообщения
					if( await askConfirm( `${this.title}. {Delete}?` ) )
						this.delete();
					return;

				case 'clear':
					if( !(await askConfirm( `{Clearhistory}?` )) ) return;
					API( '/chat_clear', {
						chatid: this.id,
						lastno: -1 // this.head.lastreceivedno
					} );
					this.removeMessages();
					return;
				case 'block!':
					// Предложим удалить чат и замутить игрока
					if( !(await askConfirm( `🚫 {Clearchatandblockuser} ${modules.user?.get( this.id )?.getShowName || ''}?` ) ) ) return;
					// Подтверждено удаление чата
					elephCore?.do( `type=mute data=${this.id}` );
					API( '/chat_block', {
						chatid: this.id
					} );
					this.removeMessages();
					// Удалим из контактов, если это игрок
					this.delete();
					elephCore?.myMutes.add( this.id );
					this.checkMenuButton();
					return;
				case 'unmute':
					this.unmute();
					return;

				default:
					// Без уведомлений некоторое время
					API( '/chat_setmute', {
						chat_id: this.id,
						mute: action
					}, 'internal' );
					this.head.mute = true;
					this.checkMenuButton();
					return;
			}
		}
		// Get information about chat
		if( +this.id ) {
			// User
			(await import( './userinfo.js')).default( this.id );
		}
	}

	unmute() {
		if( this.isMuted ) {
			// Unmute now
			if( this.isBlocked )
				elephCore?.do( `type=unmute data=${this.id}` );
			API( '/chat_setmute', {
				chat_id: this.id,
				mute: false
			}, 'internal' );
			this.head.mute = false;
			elephCore?.myMutes.delete( this.id );
			this.checkMenuButton();
		}
	}

	hasFocus() {
		return document.activeElement===this.input;
	}

	get unread() { return this.head.messages - this.head.lastreadno; }

	get hasUnread() {
		return this.unread>0;
	}

	get isEmpty() {
		return this.input.value.length===0;
	}

	get isSystem() {
		return this.id==='1';
	}

	isVisible() {
		return this.holder.isVisibleTotal();
	}

	get isBlocked() {
		return +this.id && window.elephCore?.isMute( this.id );
	}

	get isMuted() {
		return this.head.mute || this.isBlocked;
	}

	get isPrivate() {
		return +this.id;
	}

	get isPrivateOrTeam() {
		return +this.id || this.id.startsWith( 'team_' );
	}

	get isTeam() {
		return this.id.startsWith( 'team_' );
	}

	get hasMessages() {
		return !!this.lastMessage;
	}

	static isexist( chatid ) {
		return heads.has( chatid ) || all.get( chatid )?.hasMessages || false;
	}

	static updateHead( o, storelocal ) {
		o.messages ||= 0;
		o.lastreadno ||= 0;
		o.ttl = +o.ttl;
		if( 'unread' in o ) o.lastreadno = o.messages - o.unread;
		o.lastreceivedno ||= 0;
		let head = heads.get( o.chatid );
		log( `Updatehead ${JSON.stringify(o)}. Local ${JSON.stringify(head)}` );
		if( head?.messages>o.messages ) {
			// Если из базы пришла устаревшая информация
			log( `chat ${o.chatid} failed to update. head.messages ${head.messages}>${o.messages}` );
			return;
		}
		if( head?.lastmessagetime>lastUpdateTime )
			lastUpdateTime = head.lastmessagetime;
		if( !head ) heads.set( o.chatid, ( head = {} ) );
		Object.assign( head, o );
		let chat = all.get( o.chatid ),
			hasunread = o.lastreadno >= o.messages;
		if( !chat && !o.chatid.startsWith( 'team_' ) && !hasunread && o.lastmessagetime<( (Date.now()/1000)-60*60*24*30 ) ) {
			// Если сообщений нет месяц, прекращаем создавать объект
			log( `chat ${o.chatid} has no new messages. Skip loading` );
			// chat?.checkInContacts();
			return;
		}
			// && !LOCALTEST ) return;
		// if( !o.unread ) return;
		// Create chat
		if( chat ) {
			chat.checkUnread();
		}
		else
			chat = new Chat( o.chatid, 'updateHead' );

		if( storelocal )
			chat.storeParam();

		// Если есть непрочитанные сообщения, добавим этот чат в контакты, даже в случае, если "игрок не в клубе"
		// или если чаты за 2 дня
		chat.checkInContacts();

		return chat;
	}

	checkInContacts() {
		if( !modules.usersview?.contacts || !this.isPrivateOrTeam ) return;
		let showall = modules.usersview.contacts.options.showall;
		if( this.head.lastreadno < this.head.messages ||
			( !this.head.deleted && ( showall || this.head.lastmessagetime*1000>Date.now()-24*60*60*1000 ) ) ) {
			log( `Chat. Adding [${this.id}] to contacts` );
			modules.usersview.contacts.addChat( this );
			if( this.head.deleted ) delete this.head.deleted;
		}
	}

	static routeMyself( o ) {
		if( o.type==='read' ) {
			let head = heads.get( o.chatid );
			if( !head ) return;
			// Считаем, что в другом чате всё прочитано
			if( head.lastreadno >= head.messages ) return;	// Уже и так прочли
			head.lastreadno = head.messages;
			// if( head.unread<0 ) head.unread = 0;
			let chat = all.get( o.chatid );
			chat?.checkUnread();
		}
	}

	delete() {
		this.head.deleted = true;
		this.storeParam();
		if( this.isPrivateOrTeam ) modules.usersview?.contacts?.removeChat( this );
		this.close();
	}

	removeMessages() {
		this.text.innerHTML = '';
		this.lastHolder = this.lastBadge = this.lastMessage = this.lastMessages = this.lastId = null;
		// Удалим всё в локальной базе
		try {
			log( 'Removing chat messages ' + this.id );
			let tr = dbChat.transaction( 'messages', 'readwrite' ).objectStore( 'messages' ),
				removeindex = tr.index( 'chatid' ),
				request = removeindex.openCursor( this.id );
			request.onsuccess = function() {
				let cursor = request.result;
				if( !cursor ) return;
				log( 'Removing chat message ' + cursor.value.no );
				cursor.delete();
				cursor.continue();
			}
		} catch( e ) {
			log( 'Chat failed to remove messages ' + JSON.stringify( e ) );
		}
	}

	checkTitle( title ) {
		if( title==='default' ) {
			this.holder.$( '.title' ).show();
			this.holder.$( '.title_chat' ).hide();
			return;
		}
		this.holder.$( '.title' ).hide();
		this.holder.$( '.title_chat' ).show();
	}

	close() {
		let bw = this.holder;
		for( ; bw; bw = bw.parentElement ) if( bw.classList.contains( 'bigwindow' ) ) break;
		bw? bw.hide() : this.holder.hide();
	}

	remove() {
		// Удаление чата
		if( this.holder.parentElement )
			this.holder.parentElement.removeChild( this.holder );
		// this.holder = null;
		this.lastHolder = this.lastBadge = this.lastMessage = this.lastMessages = this.lastId = null;
	}

	static updateAllUnread() {
		Chat.unreadchats = 0;
		let str = '';
		for( let [_,head] of heads ) {
			let unread = head.messages - head.lastreadno;
			if( unread ) {
				Chat.unreadchats++;
				str += ' ' + head.chatid;
			}
		}
		// Установим счетчики allunread
		for( let el of $$( '.allunreadcount' ) ) {
			el.dataset.badge = Chat.unreadchats || '';
			el.dataset.ids = str;
		}
	}
}

Chat.allSenders = new Set;

export function message( o ) {
	// Если соответствующий чат открыт, отдаем ему на обработку.
	// если нет, увеличиваем счетчики непрочитанных
	let chatid = o.chatid;
	if( chatid.includes( '-' ) )
		o.chatid = chatid = (chatid.split( '-' ).reduce( ( sum, x ) => sum + (+x), 0 ) - (+UIN)).toString();
	let chat = Chat.getBychatid( chatid );
	if( !chat )
		chat = new Chat( chatid, 'message' );
	chat.parse_message( o );
	chat.checkInContacts();
}

export async function changeMessageState( params ) {
	let chat = Chat.getBychatid( params.chat_id );
	if( !chat || !params?.messageno ) {
		log( `Not found chat/no in changeMessageState ${JSON.stringify( params )}` );
		return;
	}
	let res = await API( '/chat_setmessagestate', {
		chat_id: params.chat_id,
		messageno: params.messageno,
		newstate: params.newstate
	}, 'internal' );
	if( res.ok ) {
		// Отредактируем сообщение
		// toast( params.message || 'ok' );
	}
}

export default Chat;
window.modules.chat = Chat;
