function onePlayer( id, visible ) {
	if( !id ) return '';
	if( id.startsWith( 'user_' ) ) {
		// Try to show only friends and grandmasters
		if( !elephCore?.isFriend( id ) ) return '';
	}
	return fillPlayerHTML( id, {
		classes: visible? '' : 'display_none',
		size: 48,
		notext: true, // id.startsWith( 'user_' ),
		textonpicture: true,
		nofio: true,
		noinfo: true,
		nozoom: true
	} );
}

class ParticipantsElement extends HTMLDivElement {
	static observedAttributes = ['data-event'];
	#event_id; #sub; #phase;
	#parseBind = this.#parse.bind( this );
	constructor() {
		super();
		this.classList.add( 'flexline', 'wrap', 'hideempty', 'display_none', 'visible' );
		this.style.cssText = `gap: 5px; margin: 5px; order: 1; font-size: 1rem; align-content: start; `
			+ this.style.cssText;

		this.#resubscribe();

		let observer = new IntersectionObserver( this.#intersect.bind( this ), {
		} );
		observer.observe( this );
	}

	#intersect( entries, observer ) {
		if( !this.#event_id ) return;
		if( LOCALTEST )
			console.warn( `participants intersects: v=${entries[0].isVisible} i=${entries[0].isIntersecting}` );
		let visible = entries[0].isIntersecting;
		if( visible )
			this.#makeSubscribe();
		else
			elephSubscribe.delete( this.#sub );
	}

	#makeSubscribe() {
		this.#sub ||= elephSubscribe.add( `event_${this.#event_id}`,
			this.#parseBind );
	}

	#resubscribe() {
		let event = this.dataset.event;
		if( this.#event_id===event ) return;
		if( this.#sub ) elephSubscribe.delete( this.#sub );
		this.#sub = null;
		this.html( '' );
		this.#event_id = event;
		if( event ) {
			this.#makeSubscribe();
			this.show();
		}
		else {
			this.hide();
		}
	}

	#checkNoneTeam( holder ) {
		holder.makeVisible( this.#phase==='reg' || holder.dataset.state==='play' )
	}

	#checkNoneTeams() {
		for( let holder of this.$$( '.imagehead' ) )
			this.#checkNoneTeam( holder );
	}

	#parse( o, minor ) {
		if( o==='NOTFOUND' ) {
			// Drop subscription and hide
			this.dataset.event = '';
			return;
		}
		if( !minor ) return;
		if( minor==='phase' ) {
			this.#phase = o;
			this.#checkNoneTeams();
			// Hide when finished
			if( this.#phase==='finished' ) this.removeAttribute( 'event' );
			return;
		}
		if( minor.startsWith( 'participant_' ) ) {
			// Team tournament, lets show registered teams
			let id = minor.split( '_' )[1];
			if( !o.team_id ) {
				// Pair tournament. One picture for two players
				this.#setParticipant( `pair_${id}`, o );
			}
			else
				this.#setParticipant( `team_${id}`, o );
			return;
		}
		if( minor==='placing' ) {
			let pp = o.split( ' ' );
			for( let p=0; p<pp.length; p++ ) {
				for( let id of pp[p].split( ',' ) ) {
					let h = this.$( `.imagehead[data-participant='${id}']` );
					if( h )
						h.style.order = p;
				}
			}
		}
	}

/*
	#bindClick = this.#click.bind( this );
	#click( e ) {
		// Click on team participant invokes teambar for this team

	}

*/
	#setParticipant( id, data ) {
		if( !id ) return;
		let h = this.$( `[data-participant='${id}'],.imagehead[data-origin='${id}']` );
		if( !h && data?.state==='out' ) return;
		if( !h ) {
			let line = '';
			if( id.startsWith( 'pair_' ) )
				line = `<div data-participant='${id}' class='display_none'>${data.uins.split( ',' ).reduce( ( sum, x ) => sum + onePlayer(x, true), '')}</div>`;
			else
				line = onePlayer( id );

			h = html( line, this );
			if( !h ) {
				log( 'Line ' + line );
				bugReport( 'Participant ' + line );
				return;
			}
			h.dataset.participant = data?.id || id;
			h.style.cursor = 'pointer';
			// h.onclick = this.#bindClick;
		}
		if( data )
			h.dataset.state = data.state;
		if( data?.state==='out' ) {
			h.parentElement.removeChild( h );
			return;
		}
		this.#checkNoneTeam( h );
	}

	attributeChangedCallback( name, oldvalue, newvalue ) {
		this.#resubscribe();
	}

	disconnectedCallback() {
		elephSubscribe.delete( this.#sub );
		this.html( '' );
	}

}

customElements.define( 'neo-participants', ParticipantsElement, {
	extends: 'div'
} );
