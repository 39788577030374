/***
 * Claim for all trick games
 */

export default class Claim {
	#centered;
	#checkOverlapBind = this.checkOverlap.bind( this );
	constructor( game ) {
			// lastStr, minTricks, maxTricks, offer,
		// lastOffer, lastOfferDeal,
		this.game = game;
		game.claim = this;
		this.tricks = [];
		this.covered = true;

		this.holder = html( `<div class='display_none hidewhenminifyed claimbox claimposition' style='transition: transform .2s'></div>` );
		this.cover = construct( 'button.flexline.graybutton.fade.cover.center {Claim}', this.holder, this.coverClick.bind( this ) );
		let subholder = this.subholder = construct( '.fade.flexline', this.holder );
		this.decreaseButton = construct( '.fade.clickable.decrease ⊖', subholder, this.modifyOffer.bind( this ) );
		this.offerButton = construct( '.likeabutton.goodbad.clickable.blackonwhite.claim_offer.flexline.center', subholder, this.offerClick.bind( this ) );
		this.increaseButton = construct( '.fade.clickable.increase ⊕', subholder, this.modifyOffer.bind( this ) );
		if( this.game.isbridge ) {
			subholder.style.flexFlow = 'row-reverse';
			this.decreaseButton.classList.add( 'display_none' );
			this.increaseButton.classList.add( 'display_none' );
		}
		if( game.isbridge ) subholder.classList.add( 'display_none' );
		this.panel = html( `<div class='fade sheet column claimpanel'>
			  <span class='title'>{Claim}: {totalnumberoftricks}</span>
			  <div class='flexline levels'></div>
			  <div class='flexline buttons'>
			   <div class='claim graybutton goodbad'>{Claim}</div>
			   <button class='cancel noborder'>{Cancel}</button>
			  </div>
			</div>`, this.panelClick.bind( this ) );
		this.panelButton = this.panel.$( '.claim' );
		this.panelLevels = [];
		if( game.isbridge && !narrowPortraitMedia.matches )
			game.playZone.appendChild( this.holder );
		else
			game.addMoveElement( this.holder );
		game.addMoveElement( this.panel );
		game.playArea.addEventListener( 'cardholder', this.#checkOverlapBind );
		game.addResizeListener( this.#checkOverlapBind );
	}

	modifyOffer( e ) {
		let inc = e;
		if( typeof e==='object' )
			inc = e.target.classList.contains( 'increase' )? 1 : -1;
		log( 'claim: modifier ' + inc );
		let nt = this.offer + inc;
		if( nt<this.minTricks || nt>this.maxTricks ) return this.checkButtons();
		this.makeOffer( nt, null, true );
	}

	checkClaimPanel() {
		// Корректируем содержимое клейм-панели, показывая-убирая нужные кнопки
	}

	checkOverlap( e ) {
		// Отодвинем claimbox от правой панели карт, если она есть и карты открыты
		let rightno = this.game.bypos.right,
			leftno = this.game.bypos.left,
			right = this.game.cardHolder?.[rightno],
			left = this.game.cardHolder?.[leftno];
		if( e instanceof Event && e.type==='cardholder' && ![leftno,rightno].includes( e.detail.plno ) )
			return;
		if( this.holder.offsetParent===null ) return;
		// Если половина holder ниже карт, не двигаем

		let tr = 'none';
		if( right?.isOpened() ) {
			// Based on idea that holder uses position: absolute; right: 0;
			let r = this.holder.parentElement.getBoundingClientRect(),
				my = this.holder.getBoundingClientRect(),
				rh = right.holder.getBoundingClientRect(),
				shift = rh.left-r.right;
			if( shift>0 ) shift = 0;
			if( (my.top+my.bottom)/2 > rh.bottom ) shift = 0;
			if( shift && left && !left.isOpened() ) {
				let lh = left.holder.getBoundingClientRect();
				shift = lh.right - r.right + my.width;
				// Под правым не помещаемся, а левый закрыт. Поехали влево
			}
			tr = `translateX( ${shift}px )`;
		}
		for( let el of this.game.playArea.$$( '.movecontrols>.claimposition' ) )
			el.style.transform = tr;
/*

		if( this.#centered ) return;
		let r = this.holder.getBoundingClientRect(),
			over = false;
		for( let i=this.game.maxPlayers; !over && i--; ) {
			let cards = this.game.cardHolder[i].holder;
			if( cards.offsetParent===null ) continue;
			let cr = cards.getBoundingClientRect();
			over = !( r.left>cr.right || r.right<cr.left || r.top>cr.bottom || r.bottom<cr.top );
		}
		if( over ) {
			this.holder.style.right = 'initial';
			this.#centered = true;
		}
*/
	}

	panelClick( e ) {
		e.stopPropagation();
		let t = e.target;
		if( t.classList.contains( 'cancel' ) ) {
			this.panel.hide();
			this.holder.show();
			return;
		}
		if( t.classList.contains( 'claim' ) ) {
			// Выполняем claim, предложенный на кнопке
			this.panel.hide();
			this.game.send( 'claim', this.panelOffer.toString() );
			return;
		}
		if( this.panelLevels.includes( t ) ) {
			// Установим клэйм на кнопку
			this.setPanelOffer( t.textContent );
		}
	}

	setPanelOffer( l ) {
		if( l===undefined ) l = this.maxTricks;
		if( this.panelOffer===l ) return;
		if( this.panelOffer>=0 ) this.panelLevels[this.panelOffer].classList.remove( 'selected' );
		l = +l;
		if( l<this.minTricks ) l = this.minTricks;
		if( l>this.maxTricks ) l = this.maxTricks;
		let text = l, clevel = this.game.contract.level;
		if( clevel ) {
			let diff = l - clevel;
			text = diff>0? '+' + diff : (diff<0)? diff : '{contractmaking}';
		}
		let prefix = l===this.maxTricks? '{Allmine}: ' : '{Claim}: ';
		this.panelButton.setContent( prefix + text );
		this.panelButton.dataset['textstyle'] = this.tricks[l]==='?'? 'bad' : 'good';
		this.panelOffer = l;
		this.panelLevels[l].classList.add( 'selected' );
	}

	panelClaim() {
		this.holder.hide();
		this.panel.show();
		this.game.centerAuction.holder.hide();
	}

	get newclaim() { return this.game.isbridge /*|| this.game.ispref*/; }

	async coverClick() {
		if( this.newclaim ) {
			this.panelClaim();
			return;
		}
		if( !this.covered ) return;
		log( 'Uncover claim' );
		this.covered = false;
		this.cover.hide();
		await sleep( 500 );
		this.subholder.show();
	}

	offerClick() {
		if( this.offer===undefined ) return;
		this.game.send( 'claim', this.offer.toString() );
	};

	checkButtons() {
		if( !this.decreaseButton ) return;
		this.decreaseButton.makeVisible( this.offer>this.minTricks );
		this.increaseButton.makeVisible( this.offer<this.maxTricks );
	}

	makeOffer( o, str, showbuttons ) {
		if( !str ) str = o.toString();
		let content = str;
		if( +str===this.maxTricks /*&& this.game.isbridge)*/ ) content = '{all}';
		this.offerButton.setContent( content );
		this.offer = o;
		if( typeof(o)==='number' ) {
			this.lastOfferDeal = this.game.dealInfo.number;
			this.lastOffer = o;
			this.offerButton.dataset.textstyle = this.tricks[+o]==='?'? 'bad' : 'good';
			log( 'claim: keep ' + this.lastOffer + ' of deal ' + this.lastOfferDeal );
		} else {
			this.covered = false;
			this.offerButton.dataset.textstyle = '';
			this.panel.hide();
		}
		this.offerButton.dataset.number = typeof (o)==='number' ? 1 : 0;
		if( showbuttons )
			this.checkButtons();
		else {
			this.increaseButton.hide();
			this.decreaseButton.hide();
		}
		this.checkCover();
		this.#centered = false;
		this.holder.style.right = '0';
		this.holder.show();
		setTimeout( this.#checkOverlapBind, 100 );
	}

	checkCover() {
		let coverme = this.covered && this.offer!=='cancel';
		this.cover.makeVisible( coverme );
		this.subholder?.makeVisible( !coverme );
	}

	checkPanelLevels() {
		// if( !this.game.isbridge ) return;
		if( !this.panelLevels[0] ) {
			let parent = this.panel.$( '.levels' );
			for( let l = 0; l<=13; l++ ) {
				this.panelLevels[l] = construct( '.display_none.goodbadborder.graybutton ' + l, parent )
			}
		}
		let min = this.maxTricks - 8;
		if( min<this.minTricks ) min = this.minTricks;
		for( let l = 0; l<=13; l++ ) {
			let v = l>=min && l<=this.maxTricks,
				h = this.panelLevels[l];
			if( !v ) {
				h.hide();
				continue;
			}
			h.dataset['textstyle'] = this.tricks[l]==='?'? 'bad' : 'good';
			h.show();
		}
	}

	parse( str ) {
		if( str===this.lastStr ) return;
		this.lastStr = str;
		if( str==='rest' || str==='*' || str==='all' ) {
			this.makeOffer( 'all', '{allmine}' );
		} else if( str==='showtoplayer' ) {
			this.makeOffer( 'showtoplayer', '{showmyhand}' );
		} else if( str==='giverest' ) {
			this.makeOffer( 'nomore', '{igive}' );
		} else if( str==='cancel' ) {
			this.makeOffer( 'cancel', '{cancel}' );
		} else if( str ) {
			let res = /^(\d{1,2})(.*)/.exec( str );
			this.minTricks = +res[1];
			// Если мы на розыгрыше, первое предложение - беру все
			let left = res[2],
				findMax = this.game.isbridge && this.game.contract && this.game.contract.declarer===this.game.myPlace;
			for( let i = 0; i<left.length; i++ ) {
				let t = this.minTricks + i;
				this.maxTricks = t;
				this.tricks[t] = left[i];
				if( left[i]==='!' || ( findMax && left[i]==='.' ) ) {
					// log( 'default offer is ' + t );
					this.offer = t;
				}
			}
			this.checkPanelLevels();

			if( !this.offer ) this.offer = this.minTricks;
			if( this.lastOffer!==undefined && this.lastOfferDeal===this.game.dealInfo.number ) {
				// Keep the last selected offer, and correct it
				log( 'claim: switch to stored ' + this.lastOffer + ' of deal ' + this.lastOfferDeal );
				this.offer = this.lastOffer;
				if( this.offer<this.minTricks ) this.offer = this.minTricks;
				if( this.offer>this.maxTricks ) this.offer = this.maxTricks;

				if( this.panelOffer<this.minTricks ) this.setPanelOffer( this.minTricks );
				if( this.panelOffer>this.maxTricks ) this.setPanelOffer( this.maxTricks );
			} else {
				// Новый клейм - прячем, убираем панель
				if( this.newclaim ) {
					this.covered = true;
					this.setPanelOffer();
					this.panel.hide();
				}
			}
			if( this.game.isbridge ) this.covered = true;
			this.makeOffer( this.offer, null, this.maxTricks>this.minTricks );
		} else {
			this.offer = this.minTricks = this.maxTricks = undefined;
			this.holder.hide();
			this.panel.hide();
		}
		this.game.needCheckObjects();
	};
}
