"use strict";

void cssInject( 'edit' );
log( 'Touredit module loaded' );

const valueNames = {
	phrases: ['yes', 'no', 'default', 'standard', 'comfort', 'teaching', 'auto', 'pairs', 'individual', 'swiss', 'roundrobin16'],
	tgames: { M2: '{Match}/2', M4: '{Match}/4' },
	preftype: '{Type}'
},
	tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds

const fieldTypes = {
		number: ['boards', 'boardtime', 'nplayers']
	},
	fieldNames = {
		mtype: '{Match}',
		tgames: '{Games} {inround}',
		boards: '{Totalboards}', boardtime: '{Minutesperboard}',
		fsbrmb: 'MasterPoints RU',
		prefplayers: '{Preferably}',
		paymodel: '{Entryfee}',
		preftype: '{Type}',
		startpos: '{Arrangement}',
		movement: '{System}',
		goal: '{Goal}',
		noselfreg: 'No manual registration'
	};

const boardset = {
	time: {
		default: '2+2',
		options: ['1+0', '1+1', '2+0', '2+1', '2+2', '3+0', '3+1', '3+2', '4+2', '5+0', '5+3', '5+5',
			'6+0', '7+0', '7+5', '8+5', '10+0', '10+2', '20+10', ['0+2', '⚡0+2']]
	},
	tgames: ['!1', 2, 'M2', 4, 'M4'],
	movement: ['auto', '?swiss', '?roundrobin16', ['?playoff', '{playoff}']],
	rounds: [
		'!auto', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15
	]
},
	tourParams = {
	entry: {
		paymodel: {
			free: [
				['free', '{free}'],
				['subsription', '{subscription}']
				// [ 5000, '💰5,000'], [10000, '💰10,000']
			],
			paid: [
				['demo', '{free}'],
				['each1000', '{free}'],
				['freegtd1m', '{free}'],
				['subscription', '{subscription}'],
				// ['paid100k','💎{premium} GTD 100K'],
			],
			premium: [
				['each1000', '{free}'],
				['freegtd1m', '{free} + GTD 1M'],
				// ['paid100k','💎{premium} GTD 100K'],
			]
		},
		buyin: ['5500', '11000', '27500', '55000', '110000']
	},
	access: {
		// open: [ [ '', '{no}' ], [ 1, '{opened}' ] ],
		gender: [['', '{any}'], ['m', '♂️{men}'], ['w', '♀{women}']],
		fixfio: false,
		checkkey: [[0, '{no}'], [1, '🔑{yes}']],
	},
	teams: {
		nplayers: { min: 2, max: 20, default: 4 },
		minonline: { min: 0, max: 10, default: 0 },
		criticaltimeouts: [[ '', '{default}'], [0, '{No}'], 1, 2, 3, 4],
		manualseating: [['1', '{yes}'], [0, '{no}']],
		substitutes: [[0, '{no}'], ['1', '{yes}']],
		// minonline:
	},
	options: {
		registrationperiod: [['', '{now}'], ['1d', '1 {day}'], ['1h', '1 {hour}']],
		latereg: {
			default: 1,
			options: ['no', [1, '{tround} 1'], [2, '{tround} 2'], [3, '{tround} 3'], [4, '{tround} 4'], ['always', '{always}']],
			__title: '{Lateregistration}',
			__disableif: 'common.movement=playoff'
		},
		kibi: [['all', '{yes}'], [0, '{no}']],
		skiproundscore: [['', '{auto}'], '50%', '60%', '75%', '100%'],
		distribution: [['', '{default}'], 'goulash']
	},
	robot: {
		autoreplace: [
			['', '{no}'], ['1m', '1m inact'], ['90s', '1.5m inact'], ['2m', '2m inact']
		],
		invite: { min: 0, max: 100, default: 0 },
		parity: [[0, '{no}'], [1, '{yes}']],
	},

	bg: {
		phrase: 'Backgammon',
		emo: '🎲',
		movement: [['!playoff', '{playoff}'], 'auto', 'swiss', '?roundrobin16'],
		mtype: [[1, '1 {game}'], [3, '{to} 3'], ['?5', '{to} 5'], ['?7', '{to} 7'], ['?9', '{to} 9']],
		timecontrol: {
			type: 'hidden',
			__name: 'conventions.timecontrol',
			default: 'match'
		},
		time: {
			default: '5+1',
			options: ['4+1', '4+2', '4+3', ['5+1', '5m+1s'], '5+2', '5+3']
		},
		rounds: [
			'auto', 1, 5, '?7', '?8', '?9', '?10'
		]
	},

	domino: {
		phrase: 'Domino',
		emo: '🀄',
		// movement: ['auto', 'swiss', ['?playoff', '{playoff}'], '?roundrobin16'],
		/*
				time: {
					default: '5+1',
					options: ['4+1', '4+2', '4+3', [ '5+1', '5m+1s' ], '5+2', '5+3' ]
				},
		*/
		goal: [
			'101', ['8d', '8 {brboards}']
		],
		rounds: [
			'auto', 1, 5, '?7', '?8', '?9', '?10'
		]
	},

	pref: {
		phrase: 'pref',
		common: {
			preftype: [['piter', '{piter}'], ['sochi', '{sochi}'], ['rostov', '{rostov}'], ['vmk', '{VMK}']],
			rounds: [
				'!3', 4, 5, ['3+f', '3 + {final}'], ['4+f', '4 + {final}'], ['?knockout', '🥊{knockout}']
			],
			boardsperround: ['!24', 36, 48],
			prefplayers: [['!3', '3👤/{table}'], [4, '4👤/{table}']],
			dealtimeall: { type: 'hidden', default: '100s' }
		},
		conventions: {
			remiz3: ['', 'yes', 'no'],
			prefkaski: ['', 'yes', 'no'],
			whist10: ['', 'yes', 'no'],
			longwhist: ['', 'yes', 'no'],
		}
	},

	pref_skak: {
		phrase: 'races',
		common: {
			rounds: {
				default: '3',
				options: [
					['2+f', '2 + {final}'], 3, ['3+f', '3 + {final}']
				]
			}
		},
		conventions: {}
	},

	pref_kz: {
		common: {
			rounds: {
				default: '3', options: [
					['2+f', '2 + {final}'], 3, ['3+f', '3 + {final}']
				]
			}
		}
	},

	pref_hardpiter: {
		rounds: {
			default: '3', options: [
				['2+f', '2 + {final}'], 3, ['3+f', '3 + {final}']
			]
		}
	},

	updown: {
		phrase: 'Updown',
		// players: [ [2, '2/{table}'], [3, '3/{table}'], [4, '4/{table}'] ],
		time: { type: 'hidden', default: '6m' },
		_minus: [ [ 'tricks', '{tricks}'], [ 'contract', '{contract}' ] ],
		_deck: [ 32, 52],
		rounds: [ '!auto', 1, 2, 3, 4, 5, 6 ]
	},

	king: {
		phrase: '_king',
		rounds: [ '!auto', 1, 2, 3, 4, 5, 6 ]
	},

	g1000: {
		phrase: 'game1000',
		rounds: [ '!auto', 1, 2, 3, 4, 5, 6 ]
	},

	durak: {
		phrase: 'durakgame',
		common: {
			mtype: [[1, '1 {game}'], ['?G2', '2 {games_}'], ['?2', '{to} 2'], ['?3', '{to} 3'], ['?4', '{to} 4'], ['?5', '{to} 5']],
			timecontrol: {
				type: 'hidden',
				__name: 'conventions.timecontrol',
				default: 'game'
			},
			time: {
				default: '2+1',
				options: ['1+0', '1+1', '2+0', '2+1', '2+2', '3+0', '3+1', 5]
			},
			rounds: [
				'!auto', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15
			]
		},
		conventions: {
			showtilescount: true
		}
	},

	draughts: {
		...boardset,
		conventions: {
			startpos: {
				type: 'input'
			}
		}
	},

	'domino-classic_pair': {
		phrase: '{Domino} 2x2'
	},

	bg_short: {
		base: 'bg',
		phrase: 'Backgammon'
	},

	bg_long: {
		base: 'bg',
		phrase: '_Lbg'
	},

	bg_long_nard: {
		base: 'bg',
		phrase: 'Nardegammon'
	},

	bg_short_nack: {
		base: 'bg',
		phrase: 'Nackgammon'
	},

	draughts_russian: {
		base: 'draughts',
		phrase: 'draughts',
		debut: ['!no', ['ek', 'Kondrachenko (772)'],
			['idf', 'IDF (745)'], ['idf_w', 'IDF W (150)'],
			['firstmove', '{Firstmove} (7)'],
			['EQUAL', '{Equal} (43)'],
			['equal_new', '{Equal}+ (101)'],
			['FMJD', 'FMJD (736)'], ['FMJD_A', 'FMJD-A (496)'], ['FMJD_B', 'FMJD-B (94)'], ['FMJD_C', 'FMJD-C (144)']]
	},

	draughts_checkers: {
		// base: 'draughts',
		debut: ['no', ['idf', 'IDF (745)'], ['idf_w', 'IDF W (149)'],
			['firstmove', '{Firstmove} (7)']]
	},

	draughts_pool: {
		// base: 'draughts',
		debut: ['no', ['idf', 'IDF (745)'], ['idf_w', 'IDF W (149)'],
			['firstmove', '{Firstmove} (7)']]
	},

	draughts_brasil: {
		// base: 'draughts',
		debut: ['no', ['idf-brasil', 'IDF (736)'], ['firstmove', '{Firstmove} (7)']]
	},

	// Chinese open poker
	chipoker: {
		phrase: '{Chipoker}'
	},

	belot: {
		phrase: '{Belot}'
	},

	deb: {
		phrase: '{Deberc}',
		timecontrol: {
			type: 'hidden',
			__name: 'conventions.timecontrol',
			default: 'match'
		},

	},

	poker: {
		common: {
			bounty: {
				default: 'progressive',
				options: [ 'no', [ 'progressive', '{Progressive}' ] ],
			},
			game: {
				__title: '{Game}',
				options: [['POKER-TEXAS', '{Texas}'], ['POKER-OMAHA', '{Omaha}-4'], ['POKER-OMAHA5', '{Omaha}-5'],
					['POKER-OMAHA_LOW', '{Omaha}-4 Hi/Lo'], ['POKER-HOLDEM6', '6+ {short}'], ['POKER-DEALERCHOICE', '{Dealerchoice}']]
			},
			limit: {
				__name: 'conventions.limit',
				default: 'NL',
				options: [['FL', 'L - limit'], ['HPL', 'HPL - Half-pot Limit'], ['PL', 'PL - Pot Limit'], ['NL', 'NL - No Limit']],
			},
			secondflop: {
				__title: '{Twoflops}',
				__name: 'conventions.secondflop',
				type: 'checkbox'
			},
			maxplayers: {
				__title: '{Players}',
				default: '6',
				options: [['2', '1x1'], [ '6', '👤6' ], [ '8', '👤8' ], [ '10', '👤10' ]]
			},
			speed: {
				options: [['normal', '{Standard}'], ['turbo', '{Turbo}'], ['hyper', '{Hyper}']]
			}
		}
	},

	// Шахматы
	chess: {
		base: 'draughts',
		time: {
			default: '10',
			options: [['10', '10{min}'], '7+5', '7+2', '5+5', '5+3']
		}
	},

	chess_blitz: {
		base: 'draughts',
		time: {
			default: '5',
			options: [['!5', '5{min}'], '4+1', '3+3', '3+0', '2+2']
		}
	},
	chess_bullet: {
		base: 'draughts',
		time: {
			default: '2',
			options: [['2', '2{min}'], '1+1', '1+0']
		}
	},

	bridge: {
		// type: [ 'Pairs', 'Individual' ],
		scoring: [['MP', '%'], 'IMP'],
		movement: [['swiss', '{Swiss}'], ['swiss2i', '{Swiss}+2D'], ['mitchell2w', '{Mitchell} 2w']],
		boards: { type: 'input', default: 18, min: 2, max: 1000 /*, options: [ 12, 14, 15, 16, 18, 20, 21, 24 ] */ },
		boardsperround: ['auto', 1, 2, 3, 4],
		boardtime: {
			type: 'input',
			default: '7',
			min: 4,
			max: 20,
			options: [['7m', '7'], ['10m', '10'], ['12m', '12']/*, [ 0, 'No limit' ]*/],
		},
		timermode: {
			options: [['round', '{tround}'], ['pair', '{forpair}']],
			default: window.NEOBRIDGE && 'round' || 'pair'
		},
		/*
				dealtime: {
					default: 3.5,
					options: [ ['3.5m', '3.5m {Standard}'], '5m', ['6m', '6m {Teaching}']/!*, [ 0, 'No limit' ]*!/],
				},
		*/
		fsbrmb: {
			type: 'checkbox',
			default: false,
			ifonlyset: 'fsbr'
		}
	}
};

tourParams.renfree = boardset;
tourParams.ugolki = boardset;
tourParams.othello = boardset;

if( window.ADMIN || LOCALTEST )
	tourParams.entry.paymodel.paid.push( ['freetest', 'TEST'] );

// tourParams.entry.paymodel.premium.push( ['freetest', 'TEST'] );

export class Touredit {
	constructor( createparams ) {
		let game = createparams.gamepath || createparams.game,
			tid = createparams.team_id || createparams.team?.tid;
		if( tid ) {
			this.team = User.set( 'team_' + tid );
			User.whenloaded( tid )?.then( this.teamloaded.bind( this ) );
		}

		this.open = createparams.opened || createparams.open || createparams.access?.open;
		this.tourid = createparams.tourid;
		this.pairs = createparams.pairs || createparams.type==='pairs';
		this.type = createparams.type;
		this.model = createparams.model;
		this.startedWithDemo = this.model.id==='demo';
		this.allowedmodels = createparams.allowedmodels;
		this.notEditable = createparams.noteditable || [];
		if( this.tourid )
			this.notEditable.push( 'entry.paymodel' );
		this.editable = createparams.editable ||
			(this.tourid ? ['time', 'rounds'] : null);
		log( 'TEdit team: ' + JSON.stringify( this.team ) + ', tour ' + this.tourid );
		let gameroot = game.split( '-' )[0],
			p = tourParams[game] || tourParams[game.replace( '-', '_' )] || tourParams[gameroot];
		if( !p ) {
			toast( `Sorry, tournaments of this game (${game}) is not ready at the moment` );
			return;
		}
		if( !p.base && game.includes( '-' ) && tourParams[gameroot]!==p )
			p.base = gameroot;
		if( !p ) {
			log( 'Not found tourparams for game ' + game );
			return;
		}
		this.gameParams = p;
		log( 'Editstart ' + JSON.stringify( createparams ) );
		if( p.base ) {
			let pr = Object.assign( {}, tourParams[p.base] );
			Object.assign( pr, p );
			p = pr;
		}
		this.createparams = createparams;
		log( 'CREATEPARAMS ' + JSON.stringify( this.createparams ) );
		this.params = p;
		this.game = this.params.game || game;
		Touredit.big ||= makeBigWindow( {
			id: 'touredit'
		});
		this.big = Touredit.big;
		this.big.onclick = this.click.bind( this );
		this.big.onchange = this.#onchange.bind( this );
		let phrase = createparams.gamename || this.params.phrase || game,
			addstr =
				(this.team ? `<span class='subtitle' data-origin='${this.team.id}.showname'>${this.team.getShowName || ''}</span>` : ''),
			d = new Date( Date.now() + 30 * 60 * 1000 - tzoffset );
		if( !phrase.includes( '{' ) ) phrase = '{' + phrase + '}';
		// if( !addstr && LOCALTEST )
		// 	addstr = `<div class='teamname'>Тестовое название команды и что будет если очень длинное</div>`;
		d.setMinutes( d.getMinutes() - d.getMinutes() % 5 );
		if( createparams.common?.startunixtime )
			d.setTime( +createparams.common.startunixtime * 1000 - tzoffset );

		let img = fillMagicIMG( createparams.common?.logo
			|| this.team?.logo || this.game.split( '-' )[0], 64, {
			type: 'logo',
			editable: 'always'
			// origin: this.tourid && 'tour_' + this.tourid || undefined
		}, "data-name='logo'" );
		let tword = '{Tournament}';
		if( this.isTeams ) tword = '{Teamed}';

		this.str = `<div class='leftedit column' style='width: 20em'>
			<span class='title'><span class='tourword hideinportrait'>${tword}</span>🛡${phrase}</span>
			${addstr}
			<div class='slidescreen'><div class='slidesheets flexline nowrap'>
			<div class='vertical_form leftedit active fade' data-sheet='0'>
			<div class='imagehead'>${img}
			<input placeholder="{Title}" type='text' maxlength='80' name="title" style='min-width: 20px' value="${createparams.common?.title || ''}"></div>`;

		this.iconStr = `</div>
			<div class='tabicons bottom flexline spaceevenly nowrap'>
			<button class='tab emoji' data-gosheet=0>🛡️</button>`;

		this.sheets = 1;

		let timeEditable = this.isEditable( 'time', 'common' );
		this.str += '<label>';
		if( timeEditable )
			this.str += `<input name="starttime" type="${timeEditable ? 'datetime-local' : 'hidden'}" value="${d.toISOString().slice( 0, 16 )}">`;
		else
			this.str += d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
		this.str += '</label>';

		// Взнос в турнир
		if( FANTGAMES && this.team ) {
			const vals = [0.1, 0.5, 1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 1500, 2000, 5000, 10000, 20000, 50000, 100000];
			this.fillNew( 'common', 'buyin', {
				// type: 'number',
				default: '100+10',
				// max: 100000,
				// min: 0,
				options: vals.map( x => [ `${x}+${x/10}`, `${x} +${x/10} ${this.team.currency||''}` ] )
			} );
		}

		this.fill( 'type', '{Type}' );
		if( this.isTeams ) {
			this.fill( 'teams.nplayers', '{Players}', true );
		} else {
			// this.fill( 'scoring', '{Scoring}' );
		}
		// this.fill( 'movement', '{Movement}' );
		// this.fill( 'boards', '{Totalboards}' );
		// this.fill( 'boardsperround', '{Boardsperround}' );

		// Parse dealtime to fill boardtime
		if( !this.createparams.boardtime ) {
			let dt = this.createparams.dealtime,
				total = dt?.startsWith( 'total' );
			if( dt ) {
				dt = dt.replace( 'total', '' );
				this.createparams.boardtime = dt;
			}
			this.createparams.timermode = dt && !total;
		}
		// this.fill( 'boardtime', '{Minutesperboard}' );
		// this.fill( 'timermode', '{Timermode}' );
		/*
				if( NEOBRIDGE || LOCAL || createparams.tourtime ) {
					this.fill( 'totaldealtime', '{Fordeal} ({total})' );
				} else {
					this.fill( 'dealtime', '{Fordeal} {forpair}' );
				}
		*/
		// if( FANTGAMES || LOCALTEST )
		// 	this.fill( 'entry.buyin', '{Buyin}' );

		this.fillGroup( 'common', this.gameParams.common || this.gameParams );

		if( createparams.fsbr )
			this.fill( 'fsbrmb', 'MasterPoints RU' );

		this.str += `</div>`;

		// Дополнительные настройки
		let str = '';

		str += '<fieldset><legend>{Scoring}</legend>';
		str += this.fillstr( 'options.skiproundscore', '{Roundskip}' );
		str += '</fieldset>';
		// str += this.fillstr( 'options.latereg', '{Lateregistration}' );
		str += this.fillNewStr( 'options', 'latereg', tourParams.options.latereg );

		// Если турнир задается на время большее чем 3 дня вперед, параметр "начало регистрации"
		// log( 'Startdifftime ' + (d.getTime()-Date.now()) );
		if( !FANTGAMES && !NEOBRIDGE ) {
			if( d.getTime() - Date.now()>60 * 60 * 24 * 3 * 1000 )
				str += this.fillstr( 'options.registrationperiod', '{Registrationstart}' );
		}
		if( this.game==='bridge' ) {
			str += this.fillstr( 'options.kibi', '{Kibitzers}' );
			str += this.fillstr( 'options.distribution', '{Distribution}' );
		}

		if( this.isTeams ) {
			str += '<fieldset><legend>🧑‍🤝‍🧑{Teams}</legend>';
			str += this.fillstr( 'teams.minonline', 'Min online (at start)' );
			str += this.fillstr( 'teams.manualseating', '{Seating}' );
			str += this.fillstr( 'teams.inroundreplacements', '{Inroundreplacements}' );
			str += '</fieldset>';
		}

		// if( !FANTGAMES && !NEOBRIDGE )
		// 	str += this.fillstr( 'teams.criticaltimeouts', '{Criticaltours}' );

		this.fillTab( 'options', str, '⚙️', true );

		// Manual registration
		let pword = this.isTeams ? '{Teams}' : '{Participants}',
			canregown = this.team?.official || UIN==='440258' || LOCALTEST;

		// Правила допуска в турнир. В открытом турнире отсутствуют
		if( this.model?.id!=='openfree' ) {
			// this.iconStr += `<button class='tab emoji players display_none grayscale' data-demodisable=1 data-gosheet=2>🧑</button>`;
			let str = ''; // str += `<div class='vertical_form leftedit' data-sheet='2'>`;
			// if( this.team ) this.fill( 'access.open', '{Opened}' );
			if( !window.NEOBRIDGE && !FANTGAMES ) str += this.fillstr( 'access.fixfio', '{Lastname}' );
			if( !FANTGAMES && !this.pairs && !this.isTeams ) str += this.fillstr( 'access.gender', '{Gender}' );
			// this.fill( 'access.checkkey', '{Key}' );
			if( this.team || this.isTeams ) {
				str += this.fillstr( 'access.allowedteams', '{Teams}', true );
			}

			if( !this.isTeams )
				str += this.fillstr( 'access.allowedplayers', '{Players}', true );

			if( canregown || window.ADMIN ) {
				// Запрет на регистрацию игроков
				str += this.fillNewStr( 'registration', 'noselfreg', {
					type: 'checkbox',
					default: createparams.registraton?.noselfreg || false,
					editable: true
				} );
			}

			// Robots
			if( ['bridge', 'pref', 'skak'].includes( this.game ) ) {
				str += '<fieldset><legend>🤖Robot</legend>';
				// Пока отключим использование робота в турнире
				/*
							if( this.game==='bridge' ) {
								this.fill( 'robot.autoreplace', '{Replacewitharobot}' );
								this.fill( 'robot.parity', 'Add robot for parity' );
							}
				*/
				if( this.createparams.robots && !this.isTeams && (window.ADMIN || window.TESTER || LOCALTEST) )
					str += this.fillstr( 'robot.invite', 'Robots' );
				str += '</fieldset>';
			}
			// this.str += `</div>`;
			this.fillTab( 'players', str, '🧑', true );
		}

		// this.iconStr += `<button class='tab emoji regular grayscale' data-demodisable=1 data-gosheet=3>⏰️</button>`;
		// Regular timetable
		// this.str += `<div class='vertical_form leftedit' data-sheet='3'>`;
		// Regular tournaments
		let regular = this.createparams.regular,
			on = regular?.on;
		if( this.canRegular || on ) {
			let tz = regular?.timezone || TIMEZONE,
				mytz = tz===TIMEZONE,
				str = `
		<label class="regular"><input name="regular.on" type="checkbox" ${on ? 'checked' : ''}/>{Regular}</label>
		<fieldset class="regular">
		<div class='flexline nowrap start'>
		<input type="time" name="regular.time" class="1rem" value='${regular?.time || ''}'/>
		<input readonly name="regular.timezone" tabindex=-1 value='${tz}' style='border:none; margin-left: 0.5em;' ${mytz ? '' : ' class="attention"'}  />
		</div>
		<div class='flexline spacearound'>`;
			let wd = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
				wp = ['mon', 'tues', 'wednes', 'thurs', 'fri', 'satur', 'sun'];
			for( let k in wd ) {
				let d = 'day' + k;
				str += `<label class='column'><input type='checkbox' name='regular.${d}' ${regular && regular[d] && ' checked ' || ''}/>{${wp[k]}day_short}</label>`;
			}
			str += '</div></fieldset>';

			this.fillTab( 'regular', str, '⏰️', true );
		}

		// Conventions
		this.fillTab( 'conventions', this.params.conventions, '🧶' );

		if( !FANTGAMES ) {
			// entry options
			this.str += `<div class='vertical_form leftedit fade' data-tab='entry' data-sheet='${this.sheets}'>`;
			// this.fillNew( 'entry', 'paymodel', tourParams.entry.paymodel[this.createparams.paygame || 'free'] );
			// let s = this.paymodel && `{Paymodel_${this.paymodel}}. {Paymodel_${this.paymodel}_full}`;
			this.str += `<textarea readonly='readonly' placeholder='{Description}' rows='7'></textarea>
				<button class='display_none ${this.isEditable( 'entry.paymodel' ) ? 'visible' : ''}' name='entry.paymodel' data-value='${this.model?.id || ''}'>{Change}</button>
				</div>`;
			// this.iconStr += `<button class='tab emoji' data-gosheet=${this.sheets}>ℹ️</button>`;
			this.sheets++;
		}

		if( canregown ) {
			this.fillTab( 'reg',
				`<textarea placeholder='${pword}' name='reglist' rows='10'></textarea>`, 'Reg', true );
		}

		this.str += '</div>';

		this.str += `${this.iconStr}</div>
			<div class='infoline flexline spacebetween' style='background: #f0f0f0; color: gray; padding: 0.5em'>
				<span class='clickable' data-name='paymodel'>${this.paymodelStr()}</span>
				<span title='{Approximate}' data-name='approximatetime'>${this.approxtimeStr}</span>
			</div>
			<div class='buttons flexline spacebetween'>
				<button class="mybutton mainbutton default">{${this.tourid ? 'Change' : 'Create'}}</button>
				<button class="" data-closeselect='cancel'>{Cancel}</button>
			</div>`;
		this.str += `</div>`;

		this.big.html( this.str );
		// this.big.$( 'input[name="title"]' ).pattern = '^[А-Яа-яёЁäöüÄÖÜ\\w\\-\\.,\\s\\@\\$\\*\\(\\)\\?\\"]*$';
		this.big.goSheet();
		this.#onchange();
		this.checkMainButton();

		this.paymodelChanged();

		this.big.show();
	}

	static prepareEdit() {
		Touredit.big ||= makeBigWindow( {
			id: 'touredit'
		});
		Touredit.big.html( `<div style='width: 20em; height: 20em'></div>` );
		Touredit.big.$( 'div' ).setSpinner( true );
		Touredit.big.show();
	}

	static cancelEdit() {
		Touredit.big?.hide();
	}

	teamloaded() {
		// Обновим валюту в buyin
		if( !this.big ) return;
		for( let o of this.big.$$( 'select[name="common.buyin"] option' ) )
			o.textContent = o.value.replace( '+', ' +' ) + ' ' + this.team.currency;
	}

	paymodelStr( model ) {
		model ||= this.model;
		if( !model ) return '';
		if( model.id.includes( 'demo' ) ) return '<span style="color: green; font-weight: bold">{DEMO}</span>';
		// if( this.model.id==='club' ) return '{Club}';
		if( model.id.includes( 'clubpro' ) )
			return '<span style="color: green; font-weight: bold">Pro</span>';
		// Далее надо показать сколько платить за каждого игрока
		let str = ''; // model.id;
		// if( model.id.startsWith( 'club' ) ) str = '{Club}';
		if( model.each ) {
			if( model.each_subscription )
				str += `💰${model.each}/${model.each_subscription}`;
			else
				str += `💰${model.each}/👤`;
		}
		return str;
	}

	get approxtimeStr() {
		let time = this.approximateTime;
		if( !time ) return '🕒';
		let all = (time - time%60)/60, m = all%60, h = (all-m)/60;
		return '🕒 ' + (h?`${h}h`:'') + (m?` ${m}m`:'');
	}

	async paymodelInfo( title ) {
		if( !this.model ) return;
		let info = 'Biginfo_tournament_paymodel_';
		if( this.isDemo ) info += 'demo';
		else {
			if( this.model.clubpays ) info += 'clubpays_';
			else if( this.model.onepays ) info += 'onepays_';
			if( this.model.each && this.model.maxfants )
				info += 'eachmax';
			else if( this.model.maxfants ) info += 'max';
			else if( this.model.each ) info += 'each';
		}

		let line = `{${info},${this.model.needfants||this.model.maxfants||''},${this.model.each??''},${this.model.each_subscription??''}}`,
			buttons = `<button default class='rem2' data-closeselect='gotit'>{Gotit}</button>`;

		// Кнопки переключения на другие модели
		let am = {};
		if( this.allowedmodels ) {
			for( let model of this.allowedmodels ) {
				if( model.id===this.model.id ) continue;
				if( model.id.includes( 'demo' ) ) {
					// На демо можно переключиться, только если с ДЕМО мы стартовали и страницу не обновляли
					if( !this.startedWithDemo ) continue;
					continue;
				}
				am[model.id] = model;
				if( this.isDemo ) {
					buttons += `<button class='default rem2' data-closeselect='upgrademodel-${model.id}'>{Demomodecompletion}</button>`;
					break;
				} else
					buttons += `<button class='default rem2' data-closeselect='upgrademodel-${model.id}'>${this.paymodelStr( model )}</button>`;
			}
		}
		this.modelWin = makeBigWindow( {
			repeatid: 'tournament_paymodel',
			title: title || '{Paymentforthetournament}',
			html: `<div class='column'><pre style='padding: 0 1em'>${line}</pre>${buttons}</div>`
		} );
		let res = await this.modelWin.promiseShow();
		if( res.startsWith( 'upgrademodel-' ) ) {
			let id = res.split( '-' )[1],
				mod = am[id];
			if( !mod || mod.id===this.model.id ) return;
			this.model = mod;
			// this.holder.$( '[data-name="paymodel"]' ).setContent( '' );
			this.#onchange();
			this.paymodelChanged();
		}
	}

	async selectPayModel() {
		// if( !this.paidGame ) return;
		if( !this.open ) return;
		let paymodels = this.createparams.paymodels;
		if( !paymodels ) {
			if( this.team ) {
				if( this.isOpen )
					paymodels = ['freegtd1m', 'subscription'];
				else
					paymodels = ['demo', 'each1000', 'subscription'];
			} else
				paymodels = ['demo', 'max20', 'max100'];
		}
		// if( !paymodels.find( x => x.startsWith( 'each' ) ) ) paymodels.push( 'each3000' );
		paymodels = new Set( paymodels );
		// Пока добавляем руками специальный доступ
		// if( LOCALTEST ) paymodels.add( 'each3000' );
		// if( !this.team ) paymodels.add( 'each1000' );
		// if( this.isOpen ) paymodels.add( 'freegtd1m' );

		let newmodel = await selectPaymodel( paymodels, this.model?.id );
		let changepaymodel = this.big.$( '[name="entry.paymodel"]' );
		changepaymodel.dataset.value = newmodel;
		// Можно ли менять paymodel?
		changepaymodel.makeVisible( canChangePaymodel( newmodel, paymodels ) );
		// Describe entry
		this.big.$( '[data-tab="entry"] textarea' ).value = localize( `{Paymodel_${newmodel}}. {Paymodel_${newmodel}_full}` );
		// ( this.readValue( 'entry.paymodel' ) ) + '}' );
		let isdemo = this.isDemo,
			tword = this.isTeams ? '{Teamed}' : '{Tournament}';
		if( isdemo ) tword = '{Mini}-{tournament}';
		if( this.isOpen ) tword = '{Opened}';
		this.big.$( '.tourword' ).setContent( tword );
		for( let x of this.big.$$( '[data-demodisable]' ) ) {
			x.disabled = isdemo;
			x.makeVisible( !isdemo );
		}
		this.big.$( 'label.regular' )?.classList.toggle( 'disabled', !this.canRegular );
		this.checkMainButton();
		this.big.show();
	}

	get isDemo() {
		return this.model?.id==='demo';
	}

	get isClubdemo() {
		return this.model?.id==='free_demo';
	}

	get isTeams() {
		return this.type==='teams';
	}

	get paidGame() {
		return this.createparams.paygame==='paid' || this.createparams.paygame==='premium';
	}

	static #getValue( p, key ) {
		if( !p || !p.options ) return key;
		for( let k in p.options ) {
			let v = p.options[k];
			if( Array.isArray( v ) ) [k, v] = v;
			if( k.toString()===key.toString() ) return v;
		}
		return key;
	}

	static #fillOptions( param, name, defval ) {
		if( !param ) return '';
		let p = param.options || (!param.type && param),
			str = '',
			alldisabled = '',
			allnodemo = false;
		for( let k in p ) {
			let v = p[k];
			if( Array.isArray( v ) ) [k, v] = v;
			else k = v;
			let disabled = alldisabled, nodemo = allnodemo;
			if( typeof k==='string' && k[0]==='?' ) {
				k = k.slice( 1 );
				// if( this.isDemo ) disabled = 'disabled'; // 'data-demodisable="1"';
				nodemo = true;
				if( v[0]==='?' ) v = v.slice( 1 );
			}
			if( typeof k==='string' && k[0]==='!' ) {
				k = k.slice( 1 );
				if( v[0]==='!' ) v = v.slice( 1 );
				// if( this.isDemo ) alldisabled = 'disabled';
				allnodemo = true;
			}
			if( valueNames[name]?.[v] )
				v = valueNames[name][v];
			else if( valueNames.phrases.includes( v ) )
				v = '{' + v + '}';
			let def = (defval!==undefined ? defval?.toString()===k.toString()
				: param.default===k) && 'selected' || '';
			if( param.type==='input' )
				str += `<option ${def} ${disabled} ${nodemo && 'data-nodemo=1' || '' } value='${v}' />`;
			else
				str += `<option ${def} ${disabled} ${nodemo && 'data-nodemo=1' || '' } value='${k}'>${v}</option>`;
		}
		return str;
	}

	isEditable( key, group ) {
		log( `isEditable ${group}.${key}` );
		if( this.notEditable.includes( key ) || this.notEditable.includes( group + '.' + key )
			|| this.notEditable.includes( group ) ) return false;
		if( group==='access' || group==='options' || !this.editable ) return true;
		return this.editable.includes( key ) || this.editable.includes( group + '.*' );
	}

	fill( field, title, manual ) {
		this.str += this.fillstr( field, title, manual );
	}

	fillstr( field, title, manual ) {
		let [groupid, key] = field.includes( '.' ) ? field.split( '.' ) : [null, field],
			group = tourParams[groupid] || this.params,
			p = group[key];
		groupid ||= 'common';
		if( p===undefined && !manual ) return '';
		let def = this.createparams[groupid]?.[key] ?? p?.default,
			name = p?.name || field,
			str = '';

		let type = p && (p.type || 'select') || 'input',
			isnumber = fieldTypes.number.includes( key ) || (p && (p.min || p.max) && true) || false,
			optionsstr = !isnumber && Touredit.#fillOptions( p, field, def );
		title ||= '';
		if( isnumber ) type = 'input';
		let editable = this.isEditable( key, groupid );
		str += `<label ${editable ? '' : 'disabled'}  data-labelfor='${field}'><span>${title}</span>`;
		if( typeof p==='boolean' ) {
			type = 'checkbox';
			def = p;
		}
		// if( this.createparams[groupid] && ( key in this.createparams[groupid] ) )
		// 	def = this.createparams[groupid][key];
		if( type==='checkbox' ) {
			str += `<input type='checkbox' ${def ? 'checked ' : ''} name='${field}'></input>`;
		} else if( manual || type==='input' ) {
			// Editable
			str += `<input name='${name}'`;
			if( isnumber ) str += ` type='number' inputmode='numeric'`;
			if( p && 'min' in p ) str += ` min=${p.min}`;
			if( p && 'max' in p ) str += ` max=${p.max} size=${p.max.toString().length}`;
			if( optionsstr )
				str += ` size='5' list='list_${field}'`;
			if( def )
				str += ` value='${Touredit.#getValue( p, def )}'`;
			str += '>';
			if( optionsstr ) {
				str += `<datalist id='list_${field}'>${optionsstr}</datalist>`;
			}
		} else {
			str += `<select name='${name}'>${optionsstr}</select>`;
		}
		str += '</label>';
		return str;
	}

	fillTab( groupid, o, emo, hideindemo ) {
		if( typeof o==='object' && !Object.keys(o).length ) return;
		this.iconStr += `<button class='display_none visible emoji grayscale tab ${groupid}' ${hideindemo && 'data-demodisable=1' || ''} data-gosheet=${this.sheets}>${emo}</button>`;
		if( o ) {
			this.str += `<div class='vertical_form leftedit fade' data-sheet='${this.sheets}'>`;
			if( typeof o==='object' )
				this.fillGroup( groupid, o );
			else
				this.str += o;
			this.str += '</div>';
		}
		this.sheets++;
	}

	fillGroup( groupid, o ) {
		let all = { ...tourParams[o.base], ...o };
		// if( o.base )
		// 	for( let k in tourParams[o.base] )
		// 		all[k] = tourParams[o.base][k];
		// for( let k in o ) all[k] = o[k];
		for( let k in all ) {
			if( k==='conventions' ) continue;
			if( k[0]==='_' && groupid==='common' ) {
				this.fillNew( 'conventions', k.slice( 1 ), all[k] );
			} else
				this.fillNew( groupid, k, all[k] );
		}
	}

	fillNewStr( groupid, key, o ) {
		if( o===undefined ) return;
		if( o.__name ) [ groupid, key ] = o.__name.split( '.' );
		key = key.toLowerCase();
		let name = groupid + '.' + key;
		if( ['base', 'phrase', 'emo'].includes( key ) ) return '';
		if( this.createparams.match && ['rounds', 'scoring'].includes( key ) ) return ''; // Пока не задаем число раундов в матче
		if( o.ifonlyset && !this.createparams[o.ifonlyset] ) return '';
		let createdef = this.createparams[groupid],
			def = createdef?.[key] ?? o.default,
			type = o.type || 'select',
			isnumber = type==='number' || fieldTypes.number.includes( key ) || ((o.min || o.max) && true) || false,
			optionsstr = !isnumber && Touredit.#fillOptions( o, key, def ),
			str = '';
		log( `FNEW: ${JSON.stringify( createdef )}` );
		log( `FNEW: ${groupid}.${key} = ${def}` );
		let title = o.__title || fieldNames[key] || '{' + capitalize( key ) + '}';
		if( isnumber ) type = 'input';
		if( o.type==='hidden' ) {
			return `<input type='hidden' name='${name}' value='${def}' />`;
		}
		let editable = o.editable || groupid==='access' || groupid==='options' || !this.editable
			|| this.editable.includes( key ) || this.editable.includes( groupid + '.*' ),
			datadisable = o.__disableif && `data-disableif='${o.__disableif}'` || '';

		str += `<label ${editable ? '' : 'disabled'} data-labelfor='${name}' ${datadisable}><span>${title}</span>`;
		if( typeof o==='boolean' ) {
			type = 'checkbox';
			def = o;
		}
		if( type==='checkbox' ) {
			str += `<input type='checkbox' ${def && 'checked'} name='${name}'></input>`;
		} else if( /*manual ||*/ type==='input' ) {
			// Editable
			str += `<input name='${name}'`;
			if( isnumber ) str += ` type='number' inputmode='numeric'`;
			if( o && 'min' in o ) str += ` min=${o.min}`;
			if( o && 'max' in o ) str += ` max=${o.max} size=${o.max.toString().length}`;
			if( optionsstr )
				str += ` size='5' list='list_${field}'`;
			if( o.default )
				str += ` value='${Touredit.#getValue( o, def )}'`;
			str += '>';
			if( optionsstr ) {
				str += `<datalist id='list_${key}'>${optionsstr}</datalist>`;
			}
		} else {
			str += `<select name='${name}'>${optionsstr}</select>`;
		}
		str += '</label>';
		return str;
	}

	fillNew( groupid, key, o ) {
		this.str += this.fillNewStr( groupid, key, o );
	}

/*
	goSheet( sheet ) {
		if( !sheet ) sheet = '0';
		// this.big.$( '.slidesheets' ).dataset['activesheet'] = sheet;
		// this.big.$( '.slidesheets' ).style.transform = `translateX( -${100 * (+sheet)}% )`;
		for( let el of this.big.querySelectorAll( 'button[data-gosheet]' ) ) {
			let sel = /!*el.disabled = *!/el.dataset['gosheet']===sheet;
			el.classList.toggle( 'selected', sel );
		}
		// for( let el of this.big.$$( '.slidesheets>*' ) )
		// 	el.makeVisible( el.dataset.sheet===sheet );
		this.big.$( '.slidesheets' ).scrollIntoView( {
			block: "start",
			inline: "nearest",
			behavior: 'auto'
		} );

	}
*/

	readValue( name ) {
		let o = this.big.$( `select[name="${name}"]` );
		return o?.value;
	}

	get isOpen() {
		return !!this.open;
	}

	calcPrice() {
		if( this.tourid ) return 0;
		if( FANTGAMES ) return 0;
		this.GTD = 0;
		this.fee = 0;
		// if( !this.paidGame ) return 0;
		if( this.createparams.paid ) return 0;
		if( this.createparams.match ) return 10000;		// Матч пока всегда 10К
		// if( this.team && !this.isOpen ) return 0;
		let paymodel = this.model?.id, // .readValue( "entry.paymodel" ),
			sum = 0;
		if( paymodel==='founderpays' ) sum = 300000;
		else if( paymodel==='each1000' ) sum = 50000;
		else if( paymodel==='each3000' ) sum = 100000;
		else if( paymodel==='freegtd1m' ) {
			sum = 1000000;
			this.GTD = 1000000;
		} else if( paymodel==='3000gtd100k' ) {
			this.GTD = 100000;
			sum = 106000;
			this.fee = 3000;
		}		// GTD 100K
		return sum;
	}

	checkMainButton() {
		if( this.editable && !this.editable.length )
			return this.big.$( '.mainbutton' ).classList.add( 'display_none' );
		if( !this.paidGame ) return;		// Игра не платная, ничего не меняется
		if( this.tourid ) return;			// турнир уже создан
		let //price = this.calcPrice(),
			str = '{Create}';
		// if( price ) str += ` 💰${numberWithCommas(price)}`
		this.big.$( '.mainbutton' ).setContent( str );
	}

	async #onchange( e ) {
		let t = e?.target;
		let doapi = true;
		if( this.isDemo ) {
			let notavail;
			// Если в демо режиме пытается выбрать не демо-опцию, предложим переключиться
			if( t?.tagName==='SELECT' && t.options[t.selectedIndex].dataset.nodemo ) {
				t.setCustomValidity( localize( '{Demo}' ) );
				notavail = true;
			}
			// Если в демо режиме ставит турнир позже чем через час, предложим переключиться
			if( t?.name==='starttime' && !window.ADMIN ) {
				let newdate = new Date( t.value ),
					twohours = Date.now() + 60*60*2*1000;
				if( newdate.getTime() > twohours ) {
					notavail = true;
					t.value = new Date( twohours - tzoffset ).toISOString().slice( 0, 16 );
				}
			}
			if( notavail ) {
				// t.reportValidity();
				this.paymodelInfo( '{Notavailableindemo}' );
				doapi = false;
			}
		}
		if( t && t.tagName!=='SELECT' && t.type!=='checkbox' && !t.name.startsWith( 'conventions' ) ) return;
		// Refill objects
		// let p = this.readValue( "access.entry" ) || 'free',
		// 	pt = { paid: '💎', paid100k: '💎' }[p] || ( +p>0 && '💰' ) || '';
		let pt = '';
		if( this.readValue( 'access.fixfio' )==='1' ) pt += '✅';
		// if( this.big.$( 'select[name="access.checkkey"]' ).value==='1' ) pt += '🔑️';
		if( !this.pairs && !this.isTeams ) {
			let gender = this.readValue( "access.gender" );
			if( gender ) pt += { m: '♂️', w: '♀️' }[gender[0]];
		}
		this.big.$( 'button[data-gosheet="2"]' ).setContent( '🧑' + pt );
		this.big.$( '.title' ).dataset['after'] = pt;
		this.checkMainButton();

		let regcb = this.big.$( '[name="regular.on"]' );
		if( this.canRegular && regcb ) {
			let regf = this.big.$( 'fieldset.regular' ),
				regt = this.big.$( '[name="regular.time"]' ),
				// regtz = this.big.$( '[name="regular.timezone"]' ),
				regv = regcb.checked;
			regf.disabled = !regv;
			if( regv && !regt.value ) {
				let v = this.getField( 'starttime' ),
					d = new Date( v );
				log( 'TIME ' + d.getHours() + ':' + d.getMinutes() );
				regt.value = d.toLocaleTimeString(); // d.getHours() + ':' + d.getMinutes(); // '09:00';
				this.big.$( `[name='regular.day${(d.getDay() + 6) % 7}']` ).checked = true;
			}
			this.big.$( 'button.regular' ).classList.toggle( 'grayscale', !(regv && regt.value) );
		}

		if( this.params.conventions ) {
			let convchanged = false;
			for( let k in this.params.conventions )
				if( this.getField( 'conventions.' + k ) ) convchanged = true;
			this.big.$( 'button.conventions' )?.classList.toggle( 'grayscale', !convchanged );
		}

		let plrs = this.big.$( '[name="access.fixfio"]' )?.checked || this.getField( 'access.gender' ) ||
			this.getField( 'access.allowedteams' ) || this.getField( 'access.allowedplayers' );
		this.big.$( 'button.players' )?.classList.toggle( 'grayscale', !plrs );

		// Recheck approximate tournament time
		let json = this.collectParams();

		// Gray some fields depending from others
		let mov = json.common.movement,
			faderounds = mov && ![ 'swiss', 'auto' ].includes( mov ) || false;
		if( mov==='auto' && this.isDemo ) faderounds = true;
		this.big.$( '[data-labelfor="common.rounds"]' )?.classList.toggle( 'faded', faderounds );

		// Automatic fading
		let allstr = ' ';
		for( let k in json ) {
			let obj = json[k];
			if( typeof obj!=='object' ) continue;
			for( let kk in obj )
				allstr += `${k}.${kk}=${obj[kk]} `;
		}
		for( let el of this.big.$$( '[data-disableif]' ) )
			el.classList.toggle( 'faded', allstr.includes( el.dataset.disableif ) );


		if( doapi ) {
			json.checkonly = true;
			let checkres = await API( '/createtournament', json, 'internal' );
			if( checkres.allowedmodels ) this.allowedmodels = checkres.allowedmodels;
			if( checkres.model && checkres.model.id!==this.model?.id ) {
				this.model = checkres.model;
				this.paymodelChanged();
			}
			if( checkres.approximate_time ) {
				this.approximateTime = checkres.approximate_time;
				this.big.$( '[data-name="approximatetime"]' ).setContent( this.approxtimeStr );
			}
			log( 'Approximate ' + JSON.stringify( checkres ) );
		}
	}

	paymodelChanged() {
		this.big.$( '[data-name="paymodel"]' ).setContent( this.paymodelStr() );
		let demo = this.isDemo || this.isClubdemo;
		for( let o of this.big.$$( '[data-demodisable]' ) ) {
			o.makeVisible( !demo );
			// x.makeVisible( !isdemo );
		}
		for( let o of this.big.$$( 'select' ) ) {
			o.setCustomValidity( '' );
			o.reportValidity();
		}

		for( let o of this.big.$$( 'option[data-nodemo]' ) ) {
			let ar = o.textContent.split( '🔒' ),
				s = ar[1] || ar[0];
			o.textContent = demo? '🔒' + s : s;
		}
		this.big.classList.toggle( 'demotour', this.isDemo );
	}

	getField( name ) {
		return this.big.$( ` [name='${name}']` )?.value;
	}

	get canRegular() {
		if( this.isDemo ) return false;
		if( this.model?.id==='free_demo' ) return false;
		// if( this.paymodel?.includes( 'each' ) ) return false;
		if( LOCALTEST ) return true;
		if( this.notEditable?.includes( 'regular' ) ) return false;
		if( this.editable ) return this.editable.includes( 'regular' );
		if( this.team ) return true;
		return LOCALTEST || this.model?.id.startsWith( 'each' );
	}

	collectParams() {
		let json = {
				game: this.game,
				tourid: this.tourid,
				price: this.calcPrice(),
				gtd: this.GTD,
				model_id: this.model?.id,
				common: {},
				conventions: {}
			};
		if( this.fee ) json.fee = this.fee;
		if( this.open ) json.open = 1;
		log( 'Click team: ' + JSON.stringify( this.team ) );
		if( this.team )
			json.team_id = this.team.numericid;
		if( this.type )
			json.type = this.type;
		// json.encoded = true;
		for( let el of this.big.querySelectorAll( '[name],[data-name]' ) ) {
			let v = el.dataset.value ?? el.value ?? el.dataset.magicid;
			if( el.type==='checkbox' ) v = el.checked;
			if( v===undefined ) continue;
			let key = el.name || el.dataset.name,
				field;
			if( key.includes( '.' ) ) {
				[field, key] = key.split( '.' );
				if( field==='description' ) continue;
			}
			if( field ) {
				json[field] ||= {};
				json[field][key] = v;
			} else {
				// json[key] = v;
				if( key[0]==='_' )
					json.conventions[key.slice( 1 )] = v;
				else
					json.common[key] = v;
			}
		}
		// Коррекция времени начала
		if( json.common.starttime ) {
			let d = new Date( json.common.starttime ).getTime();
			json.common.startunixtime = /*json.startunixtime =*/ d / 1000;
		}
		return json;
	}

	async click( e ) {
		if( e.target.closest( '[data-name]' )?.dataset.name==='paymodel' ) {
			this.paymodelInfo();
			return;
		}
		if( e.target.tagName!=='BUTTON' ) return;
		// if( e.target.dataset.gosheet ) return this.goSheet( e.target.dataset.gosheet );
		if( e.target.name==='entry.paymodel' ) {
			// Изменение модели
			this.selectPayModel();
			return;
		}
		// Seems to press CREATE/CHANGE
		if( !e.target.classList.contains( 'mainbutton' ) ) return;
		// В демо турнире все выбранные значения не должны быть no-demo
		if( this.isDemo ) {
			for( let s of this.big.$$( 'select' ) ) {
				let opt = s.options[s.selectedIndex];
				if( opt.dataset.nodemo )
					return this.paymodelInfo( '{Notavailableindemo}' );
			}
		}
		let price = this.calcPrice();
		if( !this.tourid && price ) {
			// Проверим есть ли достаточно
			let str = '{Ask_needfantstocreatetournament}<br><br>';
			if( window.elephCore?.myfants )
				str += `{Youhave} <span class='fants'>${elephCore.myfants.value}</span>. `;
			str += `{Necessary} <span class='fants'>${price}</span>`;
			if( window.elephCore?.myfants.value<price ) {
				str += '<br><br>{Doyouwanttobuyitnow}';
			}
			if( !(await askConfirm( str )) ) return;
			if( elephCore?.myfants.value<price )
				return shopping( {
					ids: 'fants',
					reason: 'tourcreate',
					title: '{New} {tournament}',
					needfants: price
				} );
		}
		let json = this.collectParams();
		if( DEBUG ) log( 'TOUREDIT ' + JSON.stringify( json ) );

		// Если я - основатель, сохраним настройки
		if( !this.createparams.founder || this.createparams.founder===UIN ) {
			let sname = 'lastcreatetournament_' + this.createparams.game;
			localStorage[sname] = JSON.stringify( json );
		}

		let options;
		if( FANTGAMES && this.team )
			options = {
				needfants_title: `{Fg_tourcreate_needfants_title,${this.model.each},${this.model.min||''}}`
			};
		// в Демо-клубе выдаем сообщение, что для создания реального турнира нужно сначала
		// создать реальный клуб
		if( this.team?.isDemoclub ) {
			let h = await import( './hint.js' );
			h.default.showBig( {
				id: 'demotournament_ownclub',
				type: 'big',
				priority: 'high',
				title: '{Democlub}',
				biginfo: `{Biginfo_runtournamentsinyourclub}`,
				button: {
					text: '{Create}',
					action: () => {
						this.big.hide();
						import( './team.js' ).then( mod => mod.createClub() );
					}
				}
			} );
			return;
		}
		let jres = await API( '/createtournament', json, 'internal', options );
		log( 'Createtournament result ' + JSON.stringify( jres ) );
		if( !jres ) return;
		if( jres.error ) return toast( jres.error );
		if( !jres.tourid ) return toast( '{Error}' );
		this.big.hide();
		let stime = json.common.startunixtime;
		let leftmins = stime && (stime * 1000 - Date.now()) / 60000;
		// if( leftmins>30 ) {
		toast( '{Success}. {Startsin} ' + Math.round( leftmins ) + '{min}' );
		// "location.reload" occurs "Illegal invokation" if used in setTimeout
		// if( !window.WEBSOCKET ) setTimeout( () => location.reload(), 3000 );
		setTimeout( () => goLocation( jres.location || ( 'T' + jres.tourid ) ), 3000 );
		// Перебрасывать в турнирный зал надо, если до турнира менее часа.
		// Иначе это действие выполнять не обязательно
		// if( DEBUG )
		// 	localStorage['lastcreatetournament'] = JSON.stringify( json );
	}

	static async edit( tourid ) {
		Touredit.prepareEdit();
		let jres = await API( '/startedittour', {
			action: 'edit',
			tourid: tourid
		}, 'internal' );
		log( `/startedittour: ${JSON.stringify( jres )}` );
		if( jres?.error ) {
			if( jres.backupurl )
				localBrowser( jres.backupurl );
			// else if( window.ADMIN )
			// 	localBrowser( `/tour/${tourid}/refery` );
		}
		if( !jres || jres.error ) {
			Touredit.cancelEdit();
			return;
		}
		return new Touredit( jres );
	}

	static async create( params ) {
		if( typeof params==='string' )
			params = { game: params };
		let json = {
			action: 'new',
			game: params.game // ?.replace( /(^.*-)/, '' )
		};
		// if( tid ) json.tid = tid;
		for( let key of ['team_id', 'tid', 'type', 'clone', 'initial', 'paymodel', 'opened', 'open'] )
			if( params[key] ) json[key] = params[key];
		// if( params.type ) json.type = params.type;
		// if( params.clone ) json.clone = params.clone;
		if( !params.noauth ) await checkAuth();

		let jres = await API( '/startedittour', json, 'internal' );
		log( `Create /startedittour: ${JSON.stringify( jres )}` );
		if( !jres || jres.error ) {
			if( jres?.tourid ) goLocation( 'T' + jres.tourid );
			else if( !params.target && params.tid ) goLocation( +params.tid && `team_${params.tid}` || params.tid );
			// Перекидывание на страничку команды, если создание турнира не удалось.
			// Это может быть нужно, если запрос осуществлен через deep link в приложении. В случае неудачи нужно
			// в любом случае открыть страничку клуба
			return;
		}
		if( typeof jres!=='object' || !jres.game ) {
			return toast( 'Internal error' );
		}

		let sname = 'lastcreatetournament_' + params.game;
		if( DEBUG && localStorage[sname] ) {
			try {
				let j = JSON.parse( localStorage[sname] );
				log( 'Stored: ' + j );
				if( j && j.common && !jres.common )
					jres.common = j.common;
			} catch( e ) {
				log( 'Error parsing stored ' + localStorage[sname] );
				delete localStorage[sname];
			}
		}

		// Если турнир открытый, значит от всегда платный
		if( jres.paid ) jres.paymodel = jres.paid;
		if( !jres.paymodel ) {
			if( jres.paymodels ) {
				jres.paymodel = await selectPaymodel( jres.paymodels );
				if( jres.paymodel==='cancel' ) return;
			} else
				jres.paymodel = jres.paid===true && 'pro' || jres.paid || 'demo';
		}

		// Возможно, необходимо сначала выбрать модель
		// if( this.team && !this.paidGame && this.isDemo )

		return new Touredit( jres );
	}
}

export async function create( params ) {
	if( typeof params==='string' )
		params = { game: params };
	// Если статичный сайт, то авторизация потребует перезагрузки страницы, поэтому авторизуемся сразу
	if( window.STATIC && !params.noauth ) await checkAuth();
	params.tid ||= params.team_id;
	if( !params.game ) {
		// if( LOCALTEST ) params.games = 'bg draughts';
		// let mod = await import( './tools.js' );
		let mod = await import( './tools.js' );
		params.game = await mod.selectgame( params );
		log( 'Tour create game selected ' + params.game );
	}
	// params.game = params.game.replace( /(^.*-)/, '' );
	Touredit.create( params );
}

export function clone( id ) {
	void Touredit.create( { clone: id } );
}

export default function edit( p ) {
	void Touredit.edit( p.tourid );
}

// Выбор модели платности
const canbechanged = {
	subscription: 'each1000 each3000 gtd1m',
	each1000: ' ',
	each3000: ' ',
	demo: 'each1000 each3000 gtd1m'
};

function canChangePaymodel( model, models ) {
	if( !models || !model ) return true;
	if( models.length===1 ) return false;		// Неясно из чего выбирать
	if( canbechanged[model] ) {
		for( let m of models )
			if( canbechanged[model].includes( m ) ) return true;
		return false;
	}
	return true;
}

let winSelectPaymodel;

export function selectPaymodel( models, current ) {
	// Select exact game from the selected list
	// Только один выбор ?
	if( !models ) models = ['each1000', 'subscription'];
	if( !current && models?.length===1 ) return models[0];

	if( !winSelectPaymodel ) {
		winSelectPaymodel = makeBigWindow( {
			closeonclick: true
		}, `<div class='column leftlabels'>
			<span class='title'>{Participationfee}</span>
			<button class='display_none withcomment' data-closeselect='demo'><span>🗡️ {Paymodel_demo}</span><span class='subtitle'>{Paymodel_demo_full}</span></button>
			<button class='display_none withcomment' data-closeselect='max20'><span>⚔️ {Paymodel_max20}</span><span class='fontsmaller subtitle'>{Paymodel_max20_full}</span></button>
			<button class='display_none withcomment' data-closeselect='max100'><span>️🛡️ {Paymodel_max100}</span><span class='fontsmaller subtitle'>{Paymodel_max100_full}</span></button>
			<button class='display_none withcomment' data-closeselect='max500'><span>️🎄 {Paymodel_max500}</span><span class='fontsmaller subtitle'>{Paymodel_max500_full}</span></button>
			<button class='display_none withcomment' data-closeselect='each1000'><span>🤺 {Paymodel_each1000}</span><span class='fontsmaller subtitle'>{Paymodel_each1000_full}</span></button>
			<button class='display_none withcomment' data-closeselect='each3000'><span>🤺 {Paymodel_each3000}</span><span class='fontsmaller subtitle'>{Paymodel_each3000_full}</span></button>
			<button class='display_none withcomment' data-closeselect='freegtd1m'><span>🏆 {Paymodel_freegtd1m}</span><span class='subtitle'>{Paymodel_freegtd1m_full}</span></button>
			<button class='display_none withcomment' data-closeselect='subscription'><span>💎 {Subscription}</span><span class='subtitle'>{Paymodel_subscription_full}</span></button>
			` );
		/*
				<hr>
					<button disabled className='withcomment' data-closeselect='buyin10000'><span>💰 {Paymodel_buyin10000}</span><span
						className='subtitle'>{Paymodel_buyin10000_full}</span></button>
		*/
	}
	// let legal;
	if( models ) {
		// legal = new Set;
		for( let o of winSelectPaymodel.$$( '[data-closeselect]' ) ) {
			let v = models.includes( o.dataset.closeselect );
			o.makeVisible( v );
			// if( v ) legal.add( o );
		}
	}
	winSelectPaymodel.$( '[data-closeselect="demo"]' ).disabled = !!current;
	return winSelectPaymodel.promiseShow();
}