"use strict";

let self = {},
	activeMoves = new Set,
	dragIt, dragParent, dragging,
	dragStart,
	dragStartOwner, dragStartTransform,
	presscount,
	nodrag,
	dropholders,
	/* onlymoveelement, */ dragoverelement,
	mouseoffset = {}, dragoffset = {}, lastpos = {};  // , storepos = {},
//	/* @type {!number} */ var countmovefigs = 0

let touchy = 'ontouchstart' in document.documentElement;
document.addEventListener( touchy && 'touchstart' || 'mousedown', ontouchstart_old, true );
document.addEventListener( touchy && 'touchend' || 'mouseup', touchend, true );

function restoreposition() {
	if( dragStartTransform )
		elementAnimate( dragIt, [ null, dragStartTransform ], { duration: 50, fill: 'forwards' } );
	// dragIt.style.transform = dragTransform;

	//		dragIt.style.left	= storepos.left
	//		dragIt.style.top	= storepos.top
}

function start( o, e, type ) {
	if( !o || !o.mydraggable ) return false;
	let dragInfo = o.mydraggable;
	dragIt = o;
	presscount = 0;
	nodrag = false;
	dragging = false;
	dragStartOwner = o.owner;
	//		storepos.left	= dragIt.style.left
	//		storepos.top	= dragIt.style.top
	let parentrect = dragIt.parentElement.getBoundingClientRect(),
		myrect = dragIt.getBoundingClientRect();
	dragoffset.left = myrect.left - parentrect.left;
	dragoffset.top = myrect.top - parentrect.top;
	dragoffset.rect = dragIt.getBoundingClientRect();
	if( e ) {
		let p = e.touches?.[0] || e;
		mouseoffset.x = p.clientX;
		mouseoffset.y = p.clientY;
		// mouseoffset.l = mouseoffset.x - dragoffset.rect.left;
		// mouseoffset.t = mouseoffset.y - dragoffset.rect.top;
		mouseoffset.l = mouseoffset.x - dragoffset.left;
		mouseoffset.t = mouseoffset.y - dragoffset.top;
		lastpos.x = p.clientX;
		lastpos.y = p.clientY;
		// log( `New touch ${lastpos.x}/${lastpos.y}`);
	} else
		nodrag = true;
	dragInfo.onDrag?.( dragIt );
	dropholders = [];
	o.classList.add( 'dragged' );
	o.setAttribute( 'dragged', type );
	// dragTransform = o.style.transform;
	dragStart = {
		styleTransform: o.style.transform
	}
	dragStartTransform = null;
	if( o.lastTransform ) {
		dragStartTransform = {};
		Object.assign( dragStartTransform, o.lastTransform );
	}

	dragParent = o.myParent || o.parentElement;
	if( !dragInfo.nomarks ) dragParent.classList.add( 'draggedfromhere' );
	if( o.dropHolders ) {
		o.dropHolders.forEach( function( d ) {
			let d1 = Array.isArray( d ) && d[0] || d;
			let d2 = d1.holder || d1;
			if( !d2 ) {
				bugReport( 'Drag d2' );
			} else {
				dropholders.push( d2 );
				d2.classList.add( 'legalmove' );
				if( dragInfo.onlyonemove )
					marklegalmove( d2 );
			}
		} );
	}
	document.addEventListener( 'mousemove', touchmove, true );
	document.ontouchmove = touchmove;
	// log( 'Touchmove captured' );
	return true;
}

function end( reason ) {
	if( !dragIt ) return;

	// If this is only one available figure to move, keep mark
	let drag = dragIt.mydraggable;
	if( drag?.drags.size===1 && drag.dragParams.type!=='card' ) {
		log( "dropped, but keep mark" );
		return false;
	}

	log( 'End dragging: ' + reason );
	setdragover( null );
	dragIt.classList.remove( 'dragged' );
	dragIt.removeAttribute( 'dragged' );
	if( dragParent ) dragParent.classList.remove( 'draggedfromhere' );
	dragIt = null;
	dragging = false;
	// document.onmousemove = null;
	document.ontouchmove = null;
	// log( 'Touchmove released' );
	dropholders.forEach( function( o ) {
		o.classList.remove( 'legalmove' );
	} );
}

function toggleSelect( t ) {
	let dragInfo = t.mydraggable;
	if( !dragInfo.drags.has( t ) ) {
		bugReport( 'Not draggable t' );
		return;
	}
	let now = dragInfo.selected?.has( t );
	let sel = !now;
	if( !dragInfo.selected ) dragInfo.selected = new Set;
	if( sel ) {
		dragInfo.selected.add( t );
		t.classList.add( 'marked' );
	} else {
		dragInfo.selected.delete( t );
		t.classList.remove( 'marked' );
	}
	if( dragInfo.onSelect )
		dragInfo.onSelect( dragInfo.selected );
}

function deselectAll( dragInfo ) {
	if( !dragInfo.selected ) return;
	for( let o of dragInfo.selected ) {
		o.classList.remove( "marked" );
	}
	dragInfo.selected.clear();
}

function ontouchstart_old( e ) {
	if( e.touches?.length>1 ) return log( 'Skip touches: ' + e.touches.length ); // only =1 touch
	if( e.button===2 ) return log( 'Skip touch button 2' );					// Правая кнопка мыши - не к нам
	let t = e.target;
	if( t.mytouch || t.dataset.mytouch ) return log( 'my touch target' );		// Объект типа чата. Нажатия на нем не относятся к ходам
	if( !t.mydraggable && t.parentElement?.classList.contains( 'fig' ) ) t = t.parentElement;
	let dragInfo = t.mydraggable;
	for( let tt=t; tt && !dragInfo; tt = tt.parentElement )
		dragInfo = tt.mydraggable;

	if( !dragIt ) {
		if( !t || !dragInfo ) {
			// log( `Click on element ${e.target.tagName}#${e.target.id}.${e.className} content=${e.target.textContent}` );
			// Проверим, не попадает ли под мышь какой-то объект, по которому можно кликнуть
			let em = e.touches?.[0] || e;
			let x = em.clientX, y = em.clientY;
			for( let o of $$( '.mydraggable' ) ) {
				r = o.getBoundingClientRect();
				if( x>=r.left && x<=r.right && y>=r.top && y<r.bottom ) {
					// log( `Possible click on ${o.tagName}#${o.id}.${o.className} zindex=${o.style.zIndex} content=${o.textContent}` );
					bugReport( 'Missed drag click' );
				}
			}
			// log( 'No draginfo or target' );
			return;
		}
		if( !dragInfo.active ) return log( 'Draginfo is not active (?)' );

		if( dragInfo.onlyGoals?.has( t ) ) {
			// Делаем ход в эту клетку
			trymove( dragInfo.onlyGoals?.get( t ), t, dragInfo.dragParams.moveZ );
		}
	}

	e.preventDefault();
	e.stopPropagation();
	if( dragIt ) {
		// If success fin, then done
		if( fin( e, 'newtouch' ) ) return;
		// else try to start new drag
	}

	if( dragInfo?.dragParams.fastmove && t.dropHolders?.length===1 ) {
		// Если настройка "подтверждение хода", то карту сначала только помечаем,
		// убирая другую помеченную. Либо ходим, если помечена
		if( dragInfo?.type==='card' && elephCore.globalAttr['confirmcardmove'] && dragInfo.drags.size>1 ) {
			if( !dragInfo.selected || !dragInfo.selected.has( t ) ) {
				deselectAll( dragInfo );
				toggleSelect( t );
				return;
			}
		}
		window.lastClickHandledTime = Date.now();
		trymove( t, t.dropHolders[0], dragInfo.dragParams['moveZ'] );
		// Move done, no more dragging
		self.clear( dragInfo );
		return false;
		// AUTOMOVE
	}

	if( dragInfo?.dragParams['selectonly'] ) {
		// Select/deselect one
		toggleSelect( t );
		return;
	}

	start( t, e, 'drag' );
	return false;
}

function touchmove( e ) {
	if( !dragIt || nodrag ) return;
	let touch = e.touches && e.touches[0] || e;
	if( dragging ) {
		/*			if( e.button && e.button!=0 ) {
		 // Сбой, кнопка уже не нажата, сбрасываем
		 end()
		 return false
		 } */
	} else {
		if( Math.abs( touch.clientX - lastpos.x )<5 && Math.abs( touch.clientY - lastpos.y )<5 ) return false;
		// log( `Drag touch ${e.clientX}/${e.clientY}`);
		log( "Starting drag with " + (Math.abs( touch.clientX - lastpos.x )) + " : " + Math.abs( touch.clientY - lastpos.y ) );
		dragging = true;
		// if( !dragIt.mydraggable ) {
			// Abnormal situation, nothing to drag?
		// }
		dragIt.mydraggable?.onStartDragging?.( dragIt );
		//			dragIt.classList.add( 'dragging' )
	}
	// Начнем перетаскивание
	e.preventDefault();
	e.stopPropagation();
	drag( touch );
	return false;
}

function drag( touch ) {
	lastpos.x = touch.clientX;
	lastpos.y = touch.clientY;
	let sx = touch.clientX - mouseoffset.x;
	let sy = touch.clientY - mouseoffset.y;
	let dragParams = dragIt.mydraggable?.dragParams;
	if( dragParams?.inverse ) {
		sx = -sx;
		sy = -sy;
	}
	// let base = dragTransform;
	// Таскаем только карты
	if( dragStartTransform ) {
		// let newstr = dragStartTransform.transform
		// 	+ ` translate3d(${sx}px,${sy}px,0)`;
		// log( newstr );
		// elementAnimate( dragIt, [ null, { transform: newstr } ], { duration: 10, fill: 'forwards' } );
		doAnimate( dragIt, touch.clientX-mouseoffset.l,
				touch.clientY - mouseoffset.t,
			null, dragStartOwner?.cardScale );

	} else {
		// Add transform line to source transform
		dragIt.style.transform = `translate( ${touch.clientX-mouseoffset.x}px, ${touch.clientY - mouseoffset.y}px ) ` + dragStart.styleTransform;
	}
	setdragover( finddroptarget( touch.clientX, touch.clientY ) );
}

function setdragover( o ) {
	if( Array.isArray( o ) ) o = o[0];
	o = o?.holder || o;
	if( dragoverelement===o ) return;
	dragoverelement?.classList.remove( 'dragover' );
	dragoverelement = o;
	dragoverelement?.classList.add( 'dragover' )
}

function touchend( e ) {
	if( e.touches?.length>1 ) return;

	if( dragging ) {
		log( 'Draggins. Touchend event received' );
		// Keep mark should work only when not moved
		fin( e, 'touchend', false );
		return false;
	}

	document.ontouchmove = null;

	// Проверим, нет ли обработчика onClick. Если он есть, не используем ход клик-клик
	// в первую очередь для режима перестановок позиции
	if( e.target.mydraggable?.onClick ) {
		end( 'onClick 1' );
		e.target.mydraggable.onClick( e, e.target.mydraggable );
		return;
	}

	// Проверим, не клик ли это на globalDropHolders текущих активных ходов
	let dragInfo;
	for( let di of activeMoves ) {
		let idx = di.allDropHolders?.indexOf( e.target );
		if( idx>=0 ) dragInfo = di;
	}
	if( dragInfo?.onClick ) {
		end( 'onClick 2' );
		dragInfo.onClick( e, dragInfo );
		return;
	}

	// Захватим "кликом" фигуру. Для хода клик-клик
	if( !dragIt ) return;
	nodrag = true;
	dragIt.setAttribute( 'dragged', 'click' );
	// log( "Drag-clicked" );
	// document.onmousemove = null;
	// log( 'Touchmove released' );
	//		return fin( e.touches[0] )
}

self.doMove = function ( element, dro ) {
	if( !element?.dropHolders?.includes( dro ) ) return;
	return trymove( element, dro );
}

function trymove( element, dro, z ) {
	// if( !element ) return false;
	let dragInfo = element.mydraggable;
	if( dragInfo?.canDrop && !dragInfo.canDrop( element, dro ) ) {
		return false;
	}
	if( z===undefined ) z = dragInfo.dragParams.moveZ;
	// Холдер может не принимать, если он переполнен
	if( Array.isArray( dro ) ) dro = dro[0];
	if( dro.canDrop && !dro.canDrop( element ) ) return false;

	// Succesfull drop here
	// log( 'Drop with z=' + z );
	element.lastTransform ||= 'wait';
	dro.onDrop?.( element, z );
	dragInfo.onDrop?.( element, dro, dragInfo );
	return true;
}

function finddroptarget( x, y ) {
	let holders = dragIt.dropHolders || dragIt.mydraggable?.allDropHolders;
	if( !holders ) return;
	for( let i in holders ) {
		let dro = holders[i],
			dropo = Array.isArray( dro ) ? dro[0] : dro,
			h = dropo.holder || dropo,
			r = h.getBoundingClientRect();
		if( dro===dragParent ) continue;
		if( x>=r.left && x<=r.right && y>=r.top && y<r.bottom ) return h;
	}
}

function fin( e, reason, keepmark ) {
	e.stopPropagation();
	if( !dragIt ) return false;
	// Return if this is the same touch
	e = e.touches?.[0] || e;
	let x = e.clientX, y = e.clientY;
	if( !e.clientX ) {
		x = lastpos.x;
		y = lastpos.y;
	}
	let over = finddroptarget( x, y );
	if( over && dragIt.parentElement!==over ) {
		if( trymove( dragIt, over ) ) {
			log( "Finishing drag with move" );
			end( 'domove' );
			return true;
		}
	}

	// If dragging restore figure position
	if( dragging ) {
		restoreposition();
	}
	// Мы уже не таскам объект мышкой. Сбросить здесь этот флаг
	// необходимо для ситуации, когда ход только один, и мы его продолжим
	dragging = false;
	nodrag = true;
	cancel( reason );
	if( !keepmark ) end( reason );
	return false;
}

function cancel( reason ) {
	dragIt?.mydraggable?.onCancelDrag?.( dragIt, dragStartOwner );
}

function mouseenterleave( e ) {
	let t = e.target;
	if( !t.dropHolders ) {
		log( 'Error mouseenter' );
		t.onmouseenter = t.onmouseleave = null;
		return false;
	}
	if( t.dragInfo?.onlyonemove ) return;  // И так помечен
	let d = t.dropHolders[0];
	d = Array.isArray( d ) ? d[0] : d;
	let on = e.type==='mouseenter';
	marklegalmove( t.dragInfo || t.mydraggable, on ? d : null );
}

// ---------------- each game indep functions -----------------
function stopmove( dragInfo ) {
	if( !activeMoves.has( dragInfo ) ) return;
	activeMoves.delete( dragInfo );
	if( dragIt?.mydraggable===dragInfo ) {
		cancel( 'stopmove' );
		end( 'stopmove' );
	}
	dragInfo.onlymoveelement?.classList.remove( 'onlymoveto' );
	dragInfo.dragoverelement?.classList.remove( 'dragover' );
	dragInfo.onlymoveelement = null;
	dragInfo.dragoverelement = null;
	dragInfo.active = false;
	if( dragInfo.onlyGoals )
		for( let [o, _] of dragInfo.onlyGoals ) {
			o.classList.remove( 'onlygoal' );
			o.moveelement = null;
			o.removeEventListener( 'click', goalClick );
		}
	dragInfo.onlyGoals = null; //?.clear(); // Объекты-ячейки, в которые возможен единственный ход
	log( `Stopmove` );
}

function setGoalFor( element, goal ) {
	goal.moveelement = element;
	goal.classList.add( 'onlygoal' );
	if( !goal.onclick )
		goal.addEventListener( 'click', goalClick );
}

function goalClick( e ) {
	let t = e.target?.closest( '.onlygoal' );
	if( t ) {
		let holder = t.moveelement,
			dragInfo = holder?.mydraggable;
		if( !dragInfo ) return false;
		if( trymove( holder, holder.dropHolders[0], dragInfo.dragParams['moveZ'] ) ) {
			self.clear( )
		}
		// Only possible move, do it
	}
}

function marklegalmove( dragInfo, cell ) {
	if( typeof cell==='string' ) return;
	if( dragInfo.onlymoveelement===cell ) return;
	dragInfo.onlymoveelement?.classList.remove( 'onlymoveto' );
	cell?.classList.add( 'onlymoveto' );
	dragInfo.onlymoveelement = cell;
}

self.makeDraggable = ( dragInfo, element, holders ) => {
	if( typeof element[Symbol.iterator]==='function' ) {
		for( let el of element ) self.makeDraggable( dragInfo, el, holders );
		return;
	}
	element.mydraggable = dragInfo;
	element.dropHolders = holders;
	if( !dragInfo.drags ) dragInfo.drags = new Set;
	dragInfo.drags.add( element );
	element.setAttribute( 'draggable_old', true );
	// log( 'Drag card: ' + element.range + element.suit );
	if( element.dropHolders?.length===1 ) {
		// Only 1 move
		element.onmouseenter = element.onmouseleave = mouseenterleave;
	}
};

self.clear = dragInfo => {
	if( !dragInfo ) return;
	stopmove( dragInfo );

	if( dragInfo.drags ) {
		for( let o of dragInfo.drags ) {
			o.dropHolders = null;
			o.mydraggable = null;
			o.removeAttribute( 'draggable_old' );
			o.onmouseenter = o.onmouseleave = null;
		}
		dragInfo.drags.clear();
	}

	deselectAll( dragInfo );
};

let onceReported;
function checkOverlapReport( dragInfo ) {
	return;
/*
	if( !dragInfo.drops ) return;
	if( onceReported ) return;
	let str = '';
	for( let o of( dragInfo.drops ) ) {
		let rect = o.getBoundingClientRect(),
			el = document.elementFromPoint( rect.x + rect.width/2, rect.y + rect.height/2 );
		if( el!==o ) {
			str += `Cell ${o.dataset.notation} overlapped by `;
			if( !el )
				str += 'NONE!';
			else
				str += `${el.mydraggable?' DRAGGABLE':''} ${el.tagName}#${el.id}.${el.className}; dataset: ${JSON.stringify(el.dataset)}\n`;
		}
	}
	if( str ) {
		bugReport( str );
		onceReported = true;
	}
*/
}

self.startMove = ( dragInfo, params ) => {
	if( params ) dragInfo.dragParams = params;
	activeMoves.add( dragInfo );
	dragInfo.active = true;
	dragInfo.onlyonemove = false;
	deselectAll( dragInfo );
	dragInfo.cb?.dragStartMove?.( dragInfo );
	log( 'Dragmove start: drags.size=' + dragInfo.drags?.size + ', params=' + JSON.stringify( params ) );
	// for( let f of dragInfo.drags ) {
	// 	log( JSON5.stringify( f.style ) );
	// }
	if( !dragInfo.dragParams.permutation && dragInfo.drags?.size===1 ) {
		let f = [...dragInfo.drags][0];
		if( Array.isArray( f ) ) f = f[0];
		// var e = f.holder || f;
		if( f?.dropHolders.length===1 ) dragInfo.onlyonemove = true;
		// Auto start move if we have destination choise
		if( f?.dropHolders.length>1 ) {
			start( f, null, 'click' );
			return;
		}
	}

	// Найдем единственные доступные ячейки для хода
	dragInfo.onlyGoals = null;
	dragInfo.drops = null;
	if( !dragInfo.dragParams.permutation ) {
		let set1 = new Map, drops = new Set;
		for( let d of dragInfo.drags ) {
			if( !d.dropHolders || typeof d.dropHolders==='string' ) continue;
			for( let dh of d.dropHolders ) {
				// if( typeof dh==='object' ) dh = dh[0];
				// Changed 13/04/24
				if( Array.isArray( dh ) ) dh = dh[0];
				if( !dh ) continue;
				if( drops.has( dh ) ) {
					set1.delete( dh );
					continue;
				}
				drops.add( dh );
				set1.set( dh, d );
/*
				if( used.has( dh ) ) continue;
				if( set1.get( dh ) ) {
					used.add( dh );
					set1.delete( dh );
					continue;
				}
*/
			}
		}
		dragInfo.drops = drops;
		if( !dragInfo.noGoalClick ) {
			dragInfo.onlyGoals = set1;
			for( let [o, what] of set1 ) {
				// Добавим класс, чтобы выделялось при подведении мышкой
				setGoalFor( what, o );
				dragInfo.cb?.dragSetOnlyGoal?.( o, what );
			}
		}

		// В отладке отправим автопетицию, если какие-то ячейки закрыты
		if( TESTER )
			checkOverlapReport( dragInfo );
	}
};

modules.dragMaster = self;
export default self;
