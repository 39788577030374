//
// Форма приглашения куда-либо с возможностью отправки в messenger, копирования ссылки
//
let win, params;

function docopy( url ) {
	if( !navigator.clipboard ) return;
	navigator.clipboard.writeText( url )?.then( function() {
			toast( '{Copied}' );
		}, function( err ) {
			log( 'Copying to clipboard failed ' + JSON.stringify( err ) );
		} );
}

function click( e ) {
	let txt = params.text || '';
	if( params.url )
		txt += (txt? ', ' : '') + params.url;
	let val = e.target.dataset.value || e.target.parentElement.dataset.value,
		url1 = params.url.includes( 'inv=' )? params.url : params.url.replace( '?', '-inv-' );
	log( `Invite share ${val} ${JSON.stringify( params )}` );
	if( params.hint ) window.done?.( params.hint );
	switch( val ) {
		case 'reset':
			e.stopPropagation();
			(async function() {
				if( !(await askConfirm( '{Revokethisinvitation}' ) ) ) return;
				let res = await API( '/invitationcode_reset', {
					origin: params.origin
				} );
				if( res?.ok ) {
					if( !params.code ) return win.hide();
					params.url = params.url.replace( params.code, res.code );
					params.code = res.code;
					win.$('.link').textContent = params.url;
					toast( '✅️OK' );
				}
			})();
			return;
		case 'share':
			navigator.share( params ).then((packageNames) => {
				if (packageNames.length > 0) {
					log("Shared successfully with activity", packageNames[0]);
				} else {
					log("Share was aborted");
				}
			}).catch((err) => {
				error("Share failed:", err.message);
			});
			return;
		case 'copy':
			docopy( params.url );
			return;
		case 'whatsapp':
			window.open( `whatsapp://send?text=${txt}`, '_system','location=yes' );
			return;
		case 'viber':
			window.open( `viber://forward?text=${txt}`, '_system','location=yes' );
			return;
		case 'telegram':
			window.open( `tg://msg_url?url=${encodeURIComponent(params.url)}&text=${encodeURIComponent(params.text)}`,'_system','location=yes' );
			return;
		case 'facebook':
			let fbstr = `https://www.facebook.com/sharer.php?display=page&u=${encodeURIComponent( url1 )}&t=${encodeURIComponent(params.text)}&redirect_url=${location}`;
			log( 'invite share ' + fbstr );
			if( window.cordova )
				window.open( 'fb://faceweb?fhref=' + encodeURIComponent( fbstr.replace( 'www.', 'm.' ) ),
					'_system','location=yes' );
			else
				inappBrowser( fbstr );
			return;
	}
}

export function invite( options ) {
	// Если в приглашении указан team, то приглашать можно только членов команды.
	if( options.team && !options.team.isDemoclub ) {
		return selectTeamMembers( options.team, {
			multiselect: true,
			...options
		} );
		return;
	}
	// Подготовим к приглашению
	options.text = localize( options.text );
	if( options.title ) options.title = localize( options.title );
	options.url ||= location.href;

	// В cordova используем плагин https://www.npmjs.com/package/cordova-plugin-x-socialsharing#3-installation
	if( window.plugins?.socialsharing ) {
		window.plugins.socialsharing.shareWithOptions( {
			message: options.text,
			url: options.url,
			chooserTitle: options.title,
		}, function( result ) {
				log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
				log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
		},
		function( msg ) {
			log( "Sharing failed with message: " + msg );
		} );
		return;
	}
	if( !win ) {
		win = makeBigWindow( {
			closeonclick: 'empty'
		},
	`<div class='invitation_form vertical_form' style='max-width: min(100vw,25em)'>
		<span class='title' style='font-size: 1.1rem'></span>
		<div class='flexline nowrap'>
		<span class='link' style='font-size: 60%; background: var( --light_eee ); 
			padding: 0.5em 1em; border-radius: 5px; max-width: 90%; overflow: hidden; text-overflow: ellipsis'>
		</span>
		<span class='display_none grayhover invertdark w32 icon' data-api='invitationcode_reset' data-value='reset' style='background-image: url(${IMGEMBEDPATH}/svg/icons/refresh_black_24dp.svg)'></span>
		</div>
		<div class='buttons flexline center' style='column-gap: 10px; margin-top: 1em'>
		  <span class='clickable display_none grayhover' data-value='copy'><img width=48 height=48 class='invertdark' src='${IMGEMBEDPATH}/svg/icons/content_copy_black_24dp.svg'></span>
		  <span class='clickable' data-value='whatsapp'><img width=48 height=48 src='${IMGPATH}/svg/logo/whatsapp.svg'></span>
		  <span class='clickable' data-value='viber'><img width=48 height=48 src='${IMGPATH}/svg/logo/viber.svg'></span>
		  <span class='clickable' data-value='telegram'><img width=48 height=48 src='${IMGPATH}/svg/logo/telegram.svg'></span>
		  <span class='clickable' data-value='facebook'><img width=48 height=48 src='${IMGPATH}/svg/logo/facebook.svg'></span>
		  <span class='clickable display_none grayhover' data-value='share'><img width=48 height=48 class='invertdark' src='${IMGEMBEDPATH}/svg/icons/share_black_24dp.svg'></span>
		</div><span class='small display_none'>{Theycannotjoinwithoutinvitation}</span>
		<span style='font-size: 1rem; color: var( --light_gray )'>{Invite_help}</span>` );
		win.$( '[data-value="copy"]').makeVisible( !!navigator.clipboard );
		win.$( '[data-value="share"]').makeVisible( !!navigator.share );
		win.$( '[data-value="reset"]').makeVisible( !!options.origin );
		win.onclick = click;
	}
	win.dataset.byorigin = options.byorigin || '';
	if( win.isVisible() ) return; // Если окно приглашения уже показано, не обновляем

	win.$( '.title' ).setContent( options.text || options.title );
	let link = win.$( '.link' );
	link.setContent( options.url || '' );
	link.dataset.toclipboard = '*';
	win.dataset.url = options.url;
	win.$( '.small' ).makeVisible( options.needcode || false );
	params = options;
	win.show();
}

async function selectTeamMembers( team, options ) {
	options ||= {};
	let Usersview = (await import( './usersview.js' )).Usersview;
	let [win, usersView] = Usersview.makeSelectWindow( {
		// team_id: team.numericid,
		id: (options.prefix || 'members') + '_' + team.id,
		picture: team.getPicture,
		title: options.title || '{Players}',
		keep: true,
		withme: false,
		selectText: '{Invite}',
		...options
		// multiselect: options.multiselect
	} );
	win.dataset.byorigin = options.byorigin || '';
	usersView.clearSelection();
	usersView.setSkip( options.skip );

/*
	async function refill() {
		let res = await API( 'team_getuserlist', {
			team_id: team.numericid
		}, 'internal' );

		// Получили список игроков, заполним usersView, если эта форма еще актуальна
		if( res?.ok ) {
			res = res.result;
			for( let o of res.users ) if( o.comment ) o.undername = o.comment;
			usersView.setUsers( res.users, {
				popular: res.popular,
				mode: 'graceful'
			} );
		} else if( LOCALTEST ) {
			let serv = await elephCore.do( `type=getteammembers team=${team.numericid}` );
			if( serv ) {
				usersView.setUsers( serv.users.map( x => {
					return {
						id: x.toString(),
						undername: 'comment'
					}
				} ), {
					mode: 'graceful'
				} );
			}
		} else {
			if( LOCALTEST ) toast( 'Sorry, something goes wrong. Please try again later' );
			return;
		}
	}

*/
	usersView.updateTeamMembers( team, options.apiOptions );

	let res = await win.promiseShow();

	if( res==='doselect' ) {
/*
		API( '/team_invitemembers', {
			team_id: team.numericid,
			invitelist: usersView.selected.values(),
			prefix: options.prefix
		}, 'internal' );
*/
		// TODO: фича - показ приглашенных. Вернем список user, которых мы пригласили
		let list = new Set( [...usersView.selected].map( x => User.get( x ) ) );
		return list;
	}
}

function makeSpeech( user, message ) {
	let str = `<div style='position: relative; min-height: 120px; padding-top: 1em'>
		<div style='background: #eee; color: black; font-size: max(1rem, 80%); 
		margin: 0 0 0 70px; padding: 0.5em 1em 0.5em 60px; text-align: left; border-radius: 10px;'>${message}</div>
		<div style='position: absolute; top: 0;'>`;
	str += fillPlayerHTML( user, {
		size: 128,
		nofio: true,
		notext: true,
		bottomname: true
		// _message: '{Invitetoplay} ' + o.title
	} );
	str += '</div></div>';

	return str;
}

export async function inviteGame( game ) {
	let o = game.gameInfo;
	game.inviteOffered = true;
	game.inviteWindow ||= makeBigWindow( {
		simple: true,
		head: 'standard',
		parent: game.playArea
	});
	let str = `<div class='column center buttonnomargin' style='margin: 0 0.5em 1em; gap: 0.7em; font-size: 1.5rem'><div>
	${makeSpeech( game.founder, '{Invitetoplay} ' + o.title ) 
		|| '{Founder} {invitestoplay}'}</div>
					<button class='default' data-closeselect='accept' style='margin: 0 0 0'>{Acceptinvitation}</button>
					<span data-closeselect='reject' style='font-size: max(1rem, 80%)'>{Nothanx}</span>
					<span data-closeselect='never' class='control' style='color: gray; font-size: 1rem'>🚫 {Donotacceptinvitations} {from} ${game.founder?.getShowName || '{founder}'}</span>
					</div>`;
	game.inviteWindow.setWinBody( {
		title: game.team?.getShowName || '{Invitation}',
		picture: game.team?.getPicture,
		html: str
	} );
	let ans = await game.inviteWindow.promiseShow(),
		place = game.getMyPlace( true );

	switch( ans ) {
		case 'accept':
			// Sit onto reserved place
			game.send( 'sit', place );
			break;
		case 'reject':
			game.send( 'invitereply_reject', place );
			break;
		case 'never':
			game.send( 'invitereply_never', game.founder?.id );
			break;
	}
}