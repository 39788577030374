"use strict";

let self = {},
	lastnotes = {},
	notified = 0;

dispatch( 'onresume', () => {
	if( !('Notification' in window) ) return;
	/*			if( notified && Notification.permission==='default' ) {
					Notification.requestPermission();
				} */
	for( let k in lastnotes ) {
		log( 'Clearing last notification' );
		lastnotes[k].close();
	}
	lastnotes = {};
} );

function donotify( game, action ) {
	// if( !document.hidden ) return;								// Не всплываем, если и так активны
	let phrase = { 'move': '{Yourmove}', 'start': '{Startgame}' }[action];
	try {
		let notification = new Notification( localize( phrase ), {
			body: localize( game.gameInfo.title ),
			tag: action,
			lang: window.language || 'en',
			icon: window.IMGPATH + '/icons/128/' + game.gameInfo.icon
		} );
		notification.onclick = e => {
			if( lastnotes[action]===e.target ) delete lastnotes[action];
			e.target.close();
			focus();
			game.floatme();
		};
		lastnotes[action] = notification;
	} catch( e ) {
		log( 'new Notification failed' );
	}
}

self.notify = ( game, action ) => {
	if( !document.hidden ) return;
	notified++;
	if( !('Notification' in window) ) return;
	if( Notification.permission==='denied' ) return;
	if( Notification.permission==='granted' ) return donotify( game, action );

	// if( !document.hidden ) return;
	let prom = Notification.requestPermission();
	if( (typeof prom==='object') && ('then' in prom) ) {
		prom.then( result => {
			// log( result );
			if( result==='granted' ) {
				donotify( game, action );
				let icon = window.IMGPATH + '/icons/128/' + game.gameInfo.icon;
				let note = new Notification( "Thank you", {
					body: 'We will notify you only when action is needed',
					tag: 'move',
					icon: icon
				} );
				setTimeout( () => {
					note.close()
				}, 3000 );
				note.onclick = ( e ) => e.target.close();
			}
		} );
	}
};
export default self;

