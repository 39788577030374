"use strict";

let mainTimer;

export default class Timer {
	left = 0;
	destination = 0;
	destWhenShow = 0;
	alarm = false;
	dir;

	constructor( param ) {
		this.options = param && typeof param==='object' && param || {};
		this.holder = this.options.noholder? null : construct( '.display_none.timer.hideempty', this.options.parent );
		// this.holder = this.options.noholder? null : construct( '.fade.timer.hideempty', this.options.parent );
		this.game = this.options.game;
		this.game?.attachObject( this );
		// this.destination = 0;
		// this.left = 0;
		// this.destWhenShow = 0;
		// this.dir = undefined;
		// this.alarm = false;

		if( this.options.destination ) {
			this.setDestination( this.options.destination );
		}
//				this.timerID, stopWatch,
//				marked;
	}

	route_game( o ) {
		if( !this.holder ) return;
		let after = '';
		if( this.game?.gameInfo.timer ) {
			let timer = this.game?.gameInfo.timer,
				delay = timer.delay;
			if( timer.hourglass )
				after = '⌛';
			else if( delay==='bronstein' ) {
				after = '🧙';
			}
		}
		this.holder.dataset.after = after;
	}
	// if( self.options['visibility']==='always' ) show();

	setMain() {
		mainTimer = this;
	}

	set( time, dir ) {
		return this.setDestination( time, {
			dir: dir
		} );
	}

	setDestination( time, opts ) {
		if( !+time ) {
			this.destination = 0;
			this.setDir( 0 );
			return;
		}
		time = checkMS( time );
		if( !opts?.noserver ) time += window.TIMESERVERDIFF;
		let dir = opts?.dir;
		if( dir==='auto' || dir===undefined ) dir = (time && time>Date.now()? -1 : 1) || 0;
		dir = this.calcDir( dir );
		if( this.destination===time && this.dir===dir ) return;
		this.lowTimeAlert = 0;
		this.destination = time;
		this.left = 0;
		this.setDir( dir );
		this.check();
		// setDir( dir!==undefined && dir || -1 );
		// calcTimeout();
	};

	sleep() {
		this.setDir( 0 );
	}

	resume() {
		if( this.destination && this.destination>Date.now() ) this.setDir( -1 );
		this.check();
	}

	hide() {
		this.setDir( 0 );
		this.holder.textContent = '';
	}

	setLeft( value ) {
		this.destination = 0;
		this.left = value;
		this.setDir( 0 );
		this.setCaption();
		this.check();
	}

	setAlarm( value ) {
		if( this.alarm===value ) return;
		this.alarm = value;
		if( value )
			this.holder.setAttribute( 'alarm', true );
		else
			this.holder.removeAttribute( 'alarm' );
	}

	setOptions( opts ) {
		for( let k in opts )
			this.options[k] = opts[k];
		this.checkVisible();
	};

	getValue( addon ) {
		let r = this.left;
		if( this.destination && this.dir===-1 ) r = this.destination - Date.now();
		else if( this.destination && this.dir===1 ) r = Date.now() - this.destination;
		if( addon && this.addonSec ) r += this.addonSec*1000;
		return r;
	}

	attach( h ) {
		log( 'Attaching to ' + h ) ;
		if( this.holder===h ) return;
		this.holder = h;
		this.lastStr = null;
		this.setCaption();
	}

	setCaption() {
		// В значении для показа - время без таймбанка.
		// Однако, если оно нулевое, то показываем таймбанк. Он будет виден, только если таймер стоит
		let value = this.getValue() || +this.addonSec*1000 || 0,
			sw = this.options.stopwatch || ( this.options.stopwatch===undefined && value<5000 * 60 );
		if( this.stopWatch!==sw ) {
			this.stopWatch = sw;
			this.holder?.classList.toggle( 'stopwatch', sw );
		}
		let str = timerHHMMSS( value, !this.stopWatch, value<=1000 * 60 * 3 );
		if( this.lastStr===str ) return;
		if( Math.floor(value/60000)!==Math.floor( this.lastValue/60000 ) ) this.callBack();
		this.lastStr = str;
		this.lastValue = value;
		if( this.holder ) this.holder.textContent = str;
		this.checkClass( value );
	}

	check() {
		// Every second callback for timer changing
		if( this.dir ) {
			this.setCaption();
			let dn = Date.now();
			if( this.dir<0 && dn>=this.destination ) {
				this.setDir( 0 );
			}
			// goto next time step
			this.calcTimeout();
			if( !this.isVisible() ) {
				// log( 'Going to show: ' + ( destWhenShow-dn ) );
				if( this.destWhenShow && dn>=this.destWhenShow ) this.show();
				if( this.ismyplace ) this.checkVisible();
			}

			if( this.lowTimeAlert && dn>=this.lowTimeAlert && !this.game?.isSolo ) {
				let sound = document.hidden? 'beep' : 'notify';
				playSound( sound, true );
				// lowTimeAudio.play();
				this.lowTimeAlert = 0;
			}
		}
	}

	setLowTimeAlert( ms ) {
		let dn = Date.now();
		if( dn>this.destination-10000 ) return;	// Осталось меньше 10 секунд, поздно пикать
		if( ms===undefined ) ms = Math.min( this.destination - 10000, dn + 30000 );
		if( ms===undefined ) ms = 0;
		if( dn>ms ) return;		// Уже прошел момент
		this.lowTimeAlert = ms;
	}

	show( v ) {
		if( v===undefined ) v = true;
		if( this.holder?.makeVisible( v ) )
			this.callBack();
	}

	callBack() {
		if( !this.onchange || this.callbackid ) return;
		this.callbackid = requestAnimationFrame( this.doCallBack.bind( this ) );
	}

	doCallBack() {
		// log( 'Do timer call back' );
		this.callbackid = 0;
		if( this.onchange ) this.onchange( this );
	}

	get ismyplace() {
		return this.game && this.options.place===this.game.myPlace
	}

	isVisible() {
		return this.holder?.isVisible();
	}

	checkVisible() {
		if( !this.holder ) return;
		let v = this.options.visibility,
			gi = this.game?.gameInfo,
			showmine = gi && ( gi.showmytimer || ( gi.tour && !gi.tour.offline ) ),
			l = this.getValue();
		if( l && l<120000 ) showmine = true;
		if( v?.startsWith( 'running' ) && !this.dir ) return this.show( false );
		if( showmine && this.ismyplace ) v = 'always';
		if( v==='always' || ( v==='running' && this.isRunning ) || ( v==='runningdown' && this.dir===-1 ) ) return this.show( true );
		// if( DEBUG ) return this.show( true );
		if( !this.dir ) return this.show( !this.addonSec && this.left>0 && this.left<60000 );
		if( this.destWhenShow ) return this.show( Date.now()>=this.destWhenShow );
		// Игровые таймеры следуют строго правилам видимости
		if( this.game ) this.show( false );
		else this.show( true );
	}

	checkClass( value ) {
		// Check marked state (yellow background)
		if( !this.holder ) return;
		let m = this.options['mark']==='yes' || this.options['mark']!=='no' && value<1000 * 60 * 60;
		if( m!==this.marked ) this.holder.classList.toggle( 'marked', m );
		this.marked = m;
		let ro = this.options['rung'];
		if( !ro ) return;
		let rung = '';
		for( let k in ro ) {
			if( value<=ro[k] ) rung = k;
		}
		this.holder.setAttribute( 'rung', rung );
	}

	get isRunning() {
		return this.dir===1 || this.dir===-1;
	}

	calcDir( dir ) {
		if( dir==='+' ) return 1;
		if( dir==='-' ) return -1;
		if( dir!==1 && dir!== -1 ) return 0;
		return dir;
	}

	setDir( newdir ) {
		newdir = this.calcDir( newdir );
		if( this.dir===newdir ) return false;
		this.dir = newdir;
		this.destWhenShow = 0;
		if( this.dir<0 ) {
			if( this.options['visibility']==='always' || this.runningAddon )
				this.destWhenShow = Date.now();
			else if( this.game ) {
				// Таймер игрока появляется только через 30 секунд работы в АД и турнире, 60 сек - дружеская игра
				// либо если это уже резерв
				if( !this.addonSec && this.destination ) {
					let interval = (this.game.gameInfo.reger || this.game.gameInfo.tour)? 30000 : 60000;
					this.destWhenShow = Math.min( Date.now() + interval, this.destination - 60000 );
					if( LOCALTEST && isNaN( this.destWhenShow ) ) debugger;
					// log( 'show timer at: ' + this.destWhenShow + '( ' + (this.destWhenShow - Date.now()) + ' )' );
				}
			}
		}
		// fixnow();
		this.holder?.classList.toggle( 'running', this.dir!==0 );
		this.checkVisible();
	}

	calcTimeout() {
		if( this.timerID ) {
			clearTimeout( this.timerID );
			this.timerID = null;
		}
		if( !this.dir ) {
			return;
		}
		// Таймер идет. Установим его до ближайшей круглой секунды
		let leftms = this.getValue(),
			roundsec = Math.floor( leftms / 1000 ),
			dist = leftms - roundsec * 1000;
		if( dist<100 ) dist += 1000;
		this.timerID = setTimeout( this.check.bind( this ), dist );
	}
}

window.neoTimer = Timer;

window.elephTimer = Timer;

function parseTimeStr( str ) {
	let m = str.match( /(\d+m)?(\d+s)?/ );
	if( !m ) return 0;
	return m[1]?.slice(0,-1)*60000 || 0 + m[2]?.slice( 0,-1 )*1000 || 0;
}

class TimerElement extends HTMLSpanElement {
	static observedAttributes = [ 'data-to' ];
	constructor() {
		super();

		this.timer = new Timer( { parent: this } );

		this.#setData();
	}

	attributeChangedCallback( name, oldvalue, newvalue ) {
		this.#setData();
	}

	#setData() {
		if( this.dataset.visibility )
			this.timer.options.visibility = this.dataset.visibility;
		this.timer.options.stopwatch = this.dataset.stopwatch;

		if( this.dataset.countdown ) {
			this.timer.setLeft( parseTimeStr( this.dataset.countdown ) );
		} else if( this.dataset.to ) {
			this.timer.setDestination( this.dataset.to );
		}
	}
}

customElements.define( 'neo-timer', TimerElement, {
	extends: 'span'
} );

class TimerProgress extends HTMLProgressElement {
	static observedAttributes = ['data-to','data-from','data-stopped'];
	#intervalId; #start;
	constructor() {
		super();
		this.style.transition = '5s';
		this.classList.add( 'display_none', 'visible' );

		this.#setData();
	}

	#check() {
		this.value = this.max - ( +Date.now() - this.#start );
		if( this.value <= 0 ) {
			this.value = 0;
			this.#stop();
			this.hide();
		}
	}

	#setData() {
		this.#stop();

		if( this.dataset.to ) {
			this.#start = this.dataset.from ? checkMS( this.dataset.from ) + TIMESERVERDIFF : Date.now();
			this.max = checkMS( this.dataset.to ) + TIMESERVERDIFF - this.#start;
		}

		if( this.dataset.to && !+this.dataset.stopped ) {
			this.show();
			this.#intervalId = setInterval( this.#check.bind( this ), 20 ); // (this.max - this.min) / 100 );
		}

	}

	#stop() {
		if( !this.#intervalId ) return;
		clearInterval( this.#intervalId );
		this.#intervalId = 0;
	}

	attributeChangedCallback( name, oldvalue, newvalue ) {
		this.#setData();
	}

	disconnectedCallback() {
		this.#stop();
	}
};

customElements.define( 'neo-progresstimer', TimerProgress, {
	extends: 'progress'
} );


