cssInject( 'gross' );

let winGross, game;
function showGrossInfo( _game ) {
	game = _game;
	if( !winGross ) {
		winGross = makeBigWindow( {
			closeonclick: true
		} );
		winGross.onclick = grossClick;
	}
	winGross.html( `<div class='column' style='text-align: left; align-items: start; width: 30em'>
		<div class='grossimage'></div>
		<div><span data-name="gross_first"></span> {gross_invitesinfo}</div>
		<hr>
		<span>{Price}:</span>
		<span>{Gross_grosswon}: <span class='fants' data-name="gross_rent"></span></span>
		<span>{Draw}: <span class='fants' data-name="gross_draw"></span></span>
		<span>{Gross_challengerwon}: <span class='fants' data-name="gross_defeat"></span></span>
		<hr>
		<span>🟢 {Rule} <span style='font-weight: bold' data-name="gross_minmoves"></span>. {Gross_rulemivemoves}</span>
		<div style='align-self: stretch' class='flexline spacebetween'>
		<span class='queue' data-origin='game_${game.AAA}_queueinfo'></span>
		<button class='rem2 display_none visible' data-name='wantplay' data-origin='game_${game.AAA}_wantplay'>{Iwanttochallenge}</button>
		<button class='display_none' data-name='cancelplay' data-origin='game_${game.AAA}_cancelplay'>{Cancel}</button>
		</div>
		</div>` );
	let gross = game.gameInfo.gross,
		user = window.User?.set( gross.uin );
	if( user ) {
		user.setShowName( gross.fio );
	}
	winGross.$( '.grossimage' ).innerHTML = fillPlayerHTML( user, {
		undername: `<span><span style='font-weight: bold'>${gross.rank}</span>. {rank_${gross.rank}}</span>`
	});
	for( let o of winGross.$$( '[data-name^="gross_"]' ) ) {
		o.textContent = gross[o.dataset.name.replace( 'gross_', '' )] || '';
	}
	// Необходимо правильно задать data-origin
	// Выглядит так, что проще всего пересоздать полностью объект-окно из HTML
	waitinglist( game );
/*
	let que = winGross.$( '.queue' ),
		wl = game.waitingList,
		qc = wl?.length && wl.split( ';' ).length;
	que.setContent( qc? '{Queue}: ' + qc : ' ' );
*/
	winGross.show();
}

async function grossClick( e ) {
	let t = e.target;
	if( t.tagName==='BUTTON' && t.dataset.name ) {
		await window.modules.Auth?.checkAuth();
		t.hide();
		let jres = await game.send( t.dataset.name );
		if( jres.error ) t.show();
	}
}

let rentInfo, rentGame;
function showRentInfo( game ) {
	log( 'Showrentinfo' );
	rentGame = game;
	if( !rentInfo ) {
		rentInfo = makeBigWindow( {
			closeonclick: true
		}, `<div>{Rent}</div>` );
		rentInfo.onclick = e => {
			if( e.target.dataset.action==='payforall' ) {
				let checkednow = e.target.classList.contains( 'checked' );
				e.target.classList.toggle( 'checked', !checkednow );
				rentGame.send( 'payrentforall', checkednow? '0' : '1' );
				rentInfo.hide();
			}
		}
	}

	let canpayall = game.gameState==='notstarted' && game.isFounder && !game.gameInfo.gross,
		payall = canpayall && game.myRent() > game.gameInfo.rent?.value && 'checked' || '';
	rentInfo.html( `<div class="vertical_form">
		<span class="title">{Rent}</span>
		<span>{Rent}: <span data-origin='game_${game.AAA}_myrent' class="fants">${game.myRent()}</span>/{game}</span>
		<span>{Youhave}: <span class='fants'>${window.MYFANTS?.value || 0}</span></span>
		${canpayall? `<button class='checkable ${payall}' data-action='payforall'>{Payforeveryone}</button>` : '' }
		<button data-buy='fants' class='default'>{Buy} {fants}</button>
		</div>` );
	rentInfo.show();
}

export function rentClick( game ) {
	if( game.gameInfo.gross ) {
		// Показать информацию о гроссе
			showGrossInfo( game );
		return;
	}
	showRentInfo( game );
}

export function waitinglist( game, list ) {
	if( !game ) game = this;
	if( list!==undefined ) game.waitingList = list;
	else list = game.waitingList;
	if( list===undefined ) return;

	if( !game.waitinglistFunc ) {
		game.waitinglistFunc = waitinglist.bind( game );
		game.addRoute( { placechanged: () => game.waitinglistFunc() } );
		for( let o of game.playArea.$$( "button[data-name='wantplay']") )
			o.onclick = grossClick;
	}

	let ar = list.trim() && list.trim().split( ';' ),
		qc = ar?.length,
		str = qc? '{Queue}: ' + qc : (game.getUser(1)? '' : '{Waiting}'),
		mypos = ar?.indexOf( UIN ),
		mine = mypos>=0;
	if( mine ) {
		str = `{Queue}: #${mypos+1}`;
		if( qc>mypos+1 ) str += `/${qc}`;
	}

	// Если в очереди до 5 человек покажем их emo
	if( qc>0 && qc<=5 ) {
		if( mypos<0 )
			str = '👤'.repeat( qc );
		else
			str = '👤'.repeat( mypos ) + '🧑' + '👤'.repeat( qc-mypos-1 );
		str = '{Queue}: ' + str;
	}

	for( let o of document.body.$$( `[data-origin='game_${game.AAA}_queueinfo']` ) )
		o.setContent( str );

	for( let o of document.body.$$( `[data-origin='game_${game.AAA}_wantplay']` ) )
		o.makeVisible( !mine && !game.isPlayer );

	for( let o of document.body.$$( `[data-origin='game_${game.AAA}_cancelplay']` ) )
		o.makeVisible( mine );
}

export function grosshistory( game, o ) {
	// Покажем победы и поражения гроссмейстера
	if( !game.grossInfo )
		game.grossInfo = game.playArea.$( '.grossinfo' );
	let gro = game.grossInfo;
	if( !gro ) return;
	let gh = gro.$( '.history' );
	let str = '';
	if( o ) {
		for( let g of o ) {
			let sym = [ '⚪','🟢','🔴' ][g.winner+1],
				title = [ '{Draw}', '{Grandmaster}', '{Challenger}' ][g.winner+1];
			str += `<span class='emoji' title='${title}'>${sym}</span>`;
		}
	} else {
		// Session is not started
		str = '{Grandmaster} {invites}';
	}
	gh.setContent( str );
}