
import './support.js';
import './user.js';

window.legalGames = [ 'pref', 'bg', 'poker', 'chipoker', 'belot', 'chess', 'draughts' ];
window.FANTGAMES = true;

window.setCoreParams(  {
	id: 'fg',
	easypay: true,
	promo: {
		createclub: true
	},
	braintreeTokenization: 'production_38jp96x9_r6jtnzykpy645pcx',
	domain: 'fantgames.com',
	tableviewstart: 'room_9',
	storelocation: true,
	noplaybutton: true,
	notranslit: true,
	onesignalappid: '9805c589-08a3-4857-9cce-2509a306d780',
	apkpath: 'https://www.fantgames.com/apk/fantgames.apk',
	apkconfig: 'https://www.fantgames.com/apk/fantgames.json',
	roomAlias: 	{
		9: '',
	}
} );

// Custom modules
window.promiseModule = modname => {
	if( modname==='chat' ) return import( './mods/chat.js' );
	if( modname==='html2canvas' ) return import( '../require/html2canvas.min.js' );
	return Promise.resolve();
};

// All game modules start now
window.promiseGameModule = type => {
	if( type==='game' ) return import( './mods/game.js' );
	if( type==='cards' ) return import( './mods/cards.js' );
	if( type==='domino' ) return import( './mods/domino.js' );
	if( type==='board' ) return import( './mods/board.js' );
	if( type==='chio' || type==='chipoker' ) return import( './mods/chipoker.js' );
	if( type==='match' ) return import( './mods/match.js' );
	if( type==='bg' || type==='gammon' ) return import( './mods/gammon.js' );
	if( type==='poker' ) return import( './mods/poker.js' );
	return null;
};

window.promiseGameExtend = type => {
	if( type==='pool' ) return import( './pool.js' );
	if( type==='claim' ) return import( './mods/claim.js' );
	if( type==='bidbox' ) return import( './mods/bidbox.js' );
	if( type==='auction' ) return import( './mods/auction.js' );
	if( type==='handrecord' ) return import( './mods/handrecord.js' );
	if( type==='littleinfo' ) return import( './mods/littleinfo.js' );
};

// import( './lang/ru_module.js' );
import( './mods/auth.js' );			// Standard authorization
import( './manager.js' );
import( './room.js' );
// import( './reger.js' );
// import( './socket.js' );
import( './mods/chat.js' );

if ('serviceWorker' in navigator) {
	navigator.serviceWorker.getRegistrations().then( function( registrations ) {
		for( let registration of registrations ) {
			registration.unregister()
		}
	} );
}

/*
window.landHTML = `
	<div id='land' class='display_none'
		style='text-align: center; gap: 1em;
		position: fixed; top: 0; width: 100vw; height: 100%;
		background: var( --light_white ); overflow-y: auto'>
	<style>
		.landicon {
			width: max( 64px, 13vmin );
			height: max( 64px, 13vmin );
		}
	</style>
	<div class='column center'>
		<div class='column center'
			style='position: relative; width: 100%; padding: 6em 0 1em; overflow: hidden;
				justify-content: space-evenly;
				box-sizing: border-box; max-height: 60vh; min-height: 50vmin; max-height: 60vmax;'>
			<video autoplay muted playsinline
				style='position: absolute; top: 50%; left: 50%; z-index: -1; min-height: 100%; object-fit: contain;
				  transform: translate( -50%, -50% ); height: max( 100%, calc( 100vw / 1920 * 1080 ) )'>
				<source src='https://s.fantgames.com/fgland/fallingcards1.mp4' type='video/mp4' />
			</video>
			<span style='font-size: max( 1.5rem, 6vmin ); font-weight: bold; color: black;
				max-width: calc( 100% - 1em )'>{Createandmanageowngameclub}</span>
			<button class='default importantsize' data-execute='team.createClub'
				style='_font-size: 2rem; padding: 0.3em 0.6em; margin: 1em; background: #0052B1;
					_animation: getstartedbtn 8s forwards linear '>{Getstarted}</button>
		</div>
		<div class='' style='width: 100%; max-width: max( 40em, 75vw ); display: grid; grid-template-columns: repeat( auto-fit, minmax( 13em, 1fr ) );
			justify-items: center; row-gap: 5vmin; margin-top: 1em'>
			<div class='column center'  style='max-width: 12em'>
				<img class='landicon' src='https://s.fantgames.com/fgland/cards_with_a_hat.svg' alt='{Games}'>
				<span>{Poker}, {chess}, {gammongame}, {andmanymore}</span>
			</div>
			<div class='column center' style='max-width: 12em'>
				<img class='landicon' src='https://s.fantgames.com/fgland/coins.svg' alt='Club money'>
				<span>{Clubmoney}</span>
			</div>
			<div class='column center' style='max-width: 12em'>
				<img class='landicon' src='https://s.fantgames.com/fgland/pedestal_3.svg' alt='Tournaments'>
				<span>{Tourneys}</span>
			</div>
			<div class='column center' style='max-width: 12em'>
				<img class='landicon' src='https://s.fantgames.com/fgland/yourdomain.svg' alt='Your domain name'>
				<span>{Connectyourdomain}</span>
			</div>
			<div class='column center' style='max-width: 12em'>
				<img class='landicon' src='https://s.fantgames.com/fgland/automatic_2.svg' alt='We calculate'>
				<span>{Youplaywecalculate}</span>
			</div>
			<div class='column center' style='max-width: 12em'>
				<img class='landicon' src='https://s.fantgames.com/fgland/shield_3.svg' alt='{Security}'>
				<span>{Completeprivacyandsecurity}</span>
			</div>
			<!-- <PRE style='margin: 0'>{Fg_biginfo_myclub}</PRE> -->
		</div>
		<div class='column center'>
			<button data-execute='golocation.demo'
				style='border: none; padding: 0.3em 0.6em; margin: 1em 0; background: #0052b1; color: white'>{Demo}-{club}</button>
		</div>
	</div>
	</div>`;
*/

document.head?.insertAdjacentHTML("beforeend",
	`<style>
	  [data-location="/"] button.unlogged { 
	  	background: white; 
	  	color: #0052B1;
	  	border-color: #0052B1; 
	  	min-height: initial; 
	  }
	  [data-location="/"] .head .username {
	  	 font-family: Times New Roman;
	  	 font-style: italic;
	  }
	</style>` );
